import React, {useCallback} from 'react';
import {oktaAuth} from 'services/auth.service';
import {Security} from '@okta/okta-react';
import {useHistory} from 'react-router-dom';
import {toRelativeUrl} from '@okta/okta-auth-js';

function SecurityProvider(props) {
  const {children} = props;

  const history = useHistory();
  const restoreOriginalUri = useCallback(
    (_oktaAuth, originalUri) => {
      history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    },
    [history],
  );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
}

export default SecurityProvider;
