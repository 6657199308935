import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'lib/i18n';
import {oktaAuth} from 'services/auth.service';
import {statusToLoading} from 'adapter/loading.adapter';
import {useMemo} from 'react';
import {ToNumber, ToString, ToZipCode} from 'lib/utils';
import {getBorrowerBirthDate} from 'lib/borrower';
import BorrowerProfileServiceApi from 'api/borrower-profiles/borrower-profile-api';
import {
  getBorrowerProfileQueryKey,
  saveBorrowerProfileInfoMutationKey,
  savedBorrowerProfilesDeleteMutationKey,
  savedBorrowerProfilesQueryKey,
  savedIllustrationsQueryKey,
  saveExistingBorrowerProfileMutationKey,
} from 'constants/query';
import {queryClient} from 'lib/query';
import {defaultsDeep, isFunction} from 'lodash';
import {DEFAULT_EXISTING_MORTGAGE} from 'reducers/illustrator.reducer';

/**
 *
 *
 *
 */

export function useSaveBorrowerInfoMutation(props = {}) {
  const {onSuccess} = props;
  const {mutate, mutateAsync, status} = useMutation({
    mutationKey: [saveBorrowerProfileInfoMutationKey],
    mutationFn({BorrowerProfile, IsSaved = true}) {
      let liens = BorrowerProfile.Liens;
      let firstLien = BorrowerProfile?.FirstLien;
      let otherLiens = BorrowerProfile?.OtherLiens;
      if (BorrowerProfile.IsThere2nd === 'yes' && BorrowerProfile.OtherLiens !== null) {
        liens = null;
        otherLiens = ToNumber(BorrowerProfile.OtherLiens);
      } else {
        liens = ToNumber(BorrowerProfile.Liens);
      }
      if (BorrowerProfile?.BorrowerProfileId) {
        /**
         *
         * The "Borrower Profile" exists.
         *
         * The "Borrower Profile" will be updated.
         *
         */

        return BorrowerProfileServiceApi.updateBorrowerProfile({
          BorrowerProfileId: BorrowerProfile.BorrowerProfileId,
          NumberOfUnits: BorrowerProfile.NumberOfUnits,
          IsSaved,
          FirstName: BorrowerProfile.FirstName,
          LastName: BorrowerProfile.LastName,
          DateOfBirth: BorrowerProfile.DateOfBirth,
          Email: BorrowerProfile.Email,
          Address: BorrowerProfile.Address,
          zip: BorrowerProfile.Zip,
          city: BorrowerProfile.city,
          State: BorrowerProfile.State,
          county: BorrowerProfile.county,
          ValueOfProperty: ToNumber(BorrowerProfile.ValueOfProperty),
          Liens: liens,
          ExistingMortgage: defaultsDeep({...BorrowerProfile.ExistingMortgage}, {...DEFAULT_EXISTING_MORTGAGE}),
          FirstLien: firstLien,
          OtherLiens: otherLiens,
          IsThere2nd: BorrowerProfile.IsThere2nd,
        });
      } else {
        /**
         *
         * The "Borrower Profile" does not exists.
         *
         * A new "Borrower Profile" will be created.
         *
         */

        return BorrowerProfileServiceApi.saveBorrowerProfile({
          IsSaved,
          NumberOfUnits: BorrowerProfile.NumberOfUnits,
          FirstName: BorrowerProfile.FirstName,
          LastName: BorrowerProfile.LastName,
          DateOfBirth: BorrowerProfile.DateOfBirth,
          Email: BorrowerProfile.Email,
          Address: BorrowerProfile.Address,
          zip: BorrowerProfile.Zip,
          city: BorrowerProfile.city,
          State: BorrowerProfile.State,
          county: BorrowerProfile.county,
          ValueOfProperty: ToNumber(BorrowerProfile.ValueOfProperty),
          Liens: liens,
          ExistingMortgage: defaultsDeep({...BorrowerProfile.ExistingMortgage}, {...DEFAULT_EXISTING_MORTGAGE}),
          FirstLien: firstLien,
          OtherLiens: otherLiens,
          IsThere2nd: BorrowerProfile.IsThere2nd,
        });
      }
    },
    async onSuccess(response, data, ...r) {
      const mutatedResponse = response
        ? {
            ...response,
            data: {
              ...response.data,
              Zip: ToZipCode(response.data?.Zip),
              IsThere2nd: data.BorrowerProfile?.IsThere2nd,
            },
          }
        : response;

      await queryClient.setQueryData(
        [getBorrowerProfileQueryKey, mutatedResponse?.data?.BorrowerProfileId],
        mutatedResponse,
      );
      await queryClient.invalidateQueries(savedBorrowerProfilesQueryKey);
      await queryClient.invalidateQueries(savedIllustrationsQueryKey);

      return isFunction(onSuccess) ? onSuccess(mutatedResponse, data, ...r) : undefined;
    },
  });

  const loading = statusToLoading(status);

  return {mutate, mutateAsync, loading};
}

export function useSaveExistingBorrowerInfoMutation() {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  return useMutation(
    [saveExistingBorrowerProfileMutationKey],
    (BorrowerProfileId) => BorrowerProfileServiceApi.saveBorrowerProfile({BorrowerProfileId}),
    {
      onSuccess() {
        enqueueSnackbar(t('illustratorTranslation:illustrator borrower info.form message.success'), {
          variant: 'success',
        });
      },
      onError() {
        enqueueSnackbar(t('illustratorTranslation:illustrator borrower info.form message.error'), {variant: 'error'});
      },
    },
  );
}

export function useSavedBorrowerProfilesQuery() {
  const {
    data: savedBorrowerProfileData,
    status,
    isFetching,
  } = useQuery(savedBorrowerProfilesQueryKey, async () => {
    const authState = await oktaAuth.authStateManager.getAuthState();
    let BrokerId = undefined;

    if (authState.isAuthenticated) {
      const user = await oktaAuth.token.getUserInfo();
      BrokerId = user.sub;
    }

    return BorrowerProfileServiceApi.getSavedBorrowerProfiles(BrokerId);
  });

  const savedBorrowerProfiles = useMemo(() => {
    const data = savedBorrowerProfileData || [];

    return data.map((BorrowerProfile) => ({
      id: BorrowerProfile.BorrowerProfileId,
      BorrowerProfileId: BorrowerProfile.BorrowerProfileId,
      FirstName: ToString(BorrowerProfile.FirstName),
      LastName: ToString(BorrowerProfile.LastName),
      Age: ToNumber(BorrowerProfile.Age),
      DateOfBirth: getBorrowerBirthDate(BorrowerProfile.DateOfBirth),
      propertyAddress: ToString(BorrowerProfile.Address),
      Zip: ToZipCode(BorrowerProfile.Zip),
      State: ToString(BorrowerProfile.State),
      ValueOfProperty: ToNumber(BorrowerProfile.ValueOfProperty),
      Liens: ToNumber(BorrowerProfile.Liens),
      DateSaved: BorrowerProfile.DateSaved,
    }));
  }, [savedBorrowerProfileData]);
  const loading = statusToLoading(status, isFetching);

  return {data: savedBorrowerProfiles, loading};
}

export function useSavedBorrowerProfilesDeleteMutation() {
  const queryClient = useQueryClient();

  const {mutate, status} = useMutation({
    mutationKey: [savedBorrowerProfilesDeleteMutationKey],
    mutationFn(BorrowerProfileIds) {
      return BorrowerProfileServiceApi.deleteSavedBorrowerProfiles(BorrowerProfileIds);
    },
    onSuccess() {
      return queryClient.invalidateQueries(savedBorrowerProfilesQueryKey);
    },
  });

  const loading = statusToLoading(status);

  return {mutate, loading};
}
