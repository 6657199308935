import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, DialogActions} from '@material-ui/core';
import {Trans, useTranslation} from 'lib/i18n';
import Button from 'components/common/button/Button';
import DialogTitle from 'components/common/dialog-title/DialogTitle';
import {useModalClose} from 'hooks/react-hook-form';
import {useOwnFormContext} from 'contexts/form-context';
import {withProductComparisonList} from 'hooks/product-comparison';
import {withProducts} from 'components/products';
import {isLoaded} from 'adapter/loading.adapter';
import {filterProductByRoute} from 'lib/product';
import {API_ROUTES} from 'constants/illustrator';
import {useProductsContext} from 'contexts/products-context';
import {usePrintModal} from 'hooks/print';

function EquityModal(props) {
  const {products} = useProductsContext();
  const [state, setState] = useState({open: false});
  const {t} = useTranslation();

  const enabled = useMemo(() => !!t('consumer cash up front section.modal negative equity check.description'), [t]);

  const handleClose = useCallback(() => {
    setState({open: false});
  }, [setState]);

  const {extraArguments: {loadingProduct: loading} = {}} = useOwnFormContext();

  const [hecmProductCashAtClose, homeSafeProductCashAtClose] = [
    products.find(filterProductByRoute(API_ROUTES.ANNUAL))?.calculation?.CashAtClose,
    products.find(filterProductByRoute(API_ROUTES.TIER))?.calculation?.CashAtClose,
  ];

  useEffect(() => {
    if (enabled && isLoaded({loading}) && (hecmProductCashAtClose >= 0 || homeSafeProductCashAtClose >= 0)) {
      setState({open: true});
    }
  }, [enabled, setState, loading, hecmProductCashAtClose, homeSafeProductCashAtClose]);

  usePrintModal({setState, handleClose, defaultValues: {open: true}});

  const {onClose} = useModalClose({disableBackdropClick: true, onClose: handleClose});

  return (
    <Dialog open={state.open} onClose={onClose} aria-labelledby="equity-title" aria-describedby="equity-description">
      <DialogTitle id="equity-title" onClose={handleClose} severity="warning">
        <Trans i18nKey="consumer cash up front section.modal negative equity check.description" />
      </DialogTitle>

      <DialogActions>
        <Button onClick={handleClose} btnStyle="bgElectric">
          <Trans i18nKey="consumer cash up front section.modal negative equity check.button" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EquityModal.propTypes = {};

export default withProducts(withProductComparisonList(EquityModal));
