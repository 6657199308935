import {ToInt, ToNumber} from './utils';

export function valueToInteger(num) {
  return ToInt(num);
}

export function roundToNearest(num) {
  const val = ToNumber(num);

  return Math.round(val);
}
