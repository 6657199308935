import React, {useCallback, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import Input from 'components/common/form/input/Input';
import useStyles from 'pages/illustrator-borrower/property/components/PropertyAddressFormElementStyles';
import {useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import {useDeepMemo} from 'hooks/utils';

function PropertyAddressFormElement(props) {
  const {propertyStates = []} = props;

  const classes = useStyles();

  const {setValue} = useFormContext();
  const illustratorBorrower = useSelector(getSelectedIllustratorBorrower);

  const defaultBorrowerValue = useDeepMemo(
    () => ({
      Address: illustratorBorrower?.property?.propertyAddress,
      Address2: illustratorBorrower?.property?.propertyAddress2,
      City: illustratorBorrower?.property?.propertyCity,
      State: illustratorBorrower?.property?.propertyState,
      ZipCode: illustratorBorrower?.property?.propertyZipCode,
    }),
    [illustratorBorrower],
  );

  const [defaultValue, setDefaultValue] = useState(defaultBorrowerValue);

  // the external `onSelectOption` event handler for SmartyAutoComplete component
  const selectOptionHandler = useCallback(
    (event, xcelerateAddress) => {
      setValue('propertyAddress', xcelerateAddress?.Address);
      setValue('propertyAddress2', xcelerateAddress?.Address2);
      setValue('propertyCity', xcelerateAddress?.City);
      setValue('propertyState', xcelerateAddress?.State);
      setValue('propertyZipCode', xcelerateAddress?.ZipCode);
      setDefaultValue(xcelerateAddress);
    },
    [setValue],
  );

  // the external `onClear` event handler for SmartyAutoComplete component
  const clearHandler = useCallback(() => {
    setValue('propertyAddress', '');
    setValue('propertyAddress2', '');
    setValue('propertyCity', '');
    setValue('propertyState', '');
    setValue('propertyZipCode', '');
  }, [setValue]);

  const onFilterOptions = useCallback(
    (address) => {
      return propertyStates.find((state) => state.value === address.State);
    },
    [propertyStates],
  );

  return (
    <Input
      name="propertyAddress"
      type="address"
      variant="filled"
      className={classes.customInputElement}
      onChangeCommitted={selectOptionHandler}
      onClear={clearHandler}
      onFilterOptions={onFilterOptions}
      defaultValue={defaultValue}
    />
  );
}

export default PropertyAddressFormElement;
