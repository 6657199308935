import React from 'react';
import MortgageChartSection from 'pages/illustrator-hybrid-page/components/mortgage-chart/MortgageChartSection';
import CurrentMortgageSection from 'pages/illustrator-hybrid-page/components/current-mortgage-section/CurrentMortgageSection';
import NewMortgageSection from 'pages/illustrator-hybrid-page/components/new-mortgage-section/NewMortgageSection';
import ScatterChartSection from 'pages/illustrator-hybrid-page/components/scatter-chart/ScatterChartSection';
import IntriguedSection from 'pages/illustrator-hybrid-page/components/intrigued-section/IntriguedSection';
import RetireFreedomSection from 'pages/illustrator-hybrid-page/components/retire-freedom-section/RetireFreedomSection';
import PieChartSection from 'pages/illustrator-hybrid-page/components/pie-chart/PieChartSection';
import ConsumerCashUpFrontSection from 'pages/illustrator-hybrid-page/components/consumer-cash-up-front-section/ConsumerCashUpFrontSection';
import LineChartSection from 'pages/illustrator-hybrid-page/components/line-chart/LineChartSection';
import WowSection from 'pages/illustrator-hybrid-page/components/wow-section/WowSection';
import OkaySection from 'pages/illustrator-hybrid-page/components/okay-section/OkaySection';
import AreaChartSection from 'pages/illustrator-hybrid-page/components/area-chart/AreaChartSection';
import AboutEquitySection from 'pages/illustrator-hybrid-page/components/about-equity-section/AboutEquitySection';
import {firstChartSectionId} from 'constants/hybrid';

MultipleCharts.propTypes = {};

function MultipleCharts(props) {
  return (
    <>
      <MortgageChartSection id={firstChartSectionId}>
        <CurrentMortgageSection />
      </MortgageChartSection>
      <MortgageChartSection>
        <NewMortgageSection />
      </MortgageChartSection>

      <ScatterChartSection>
        <IntriguedSection />
      </ScatterChartSection>
      <ScatterChartSection>
        <RetireFreedomSection />
      </ScatterChartSection>

      <PieChartSection>
        <ConsumerCashUpFrontSection />
      </PieChartSection>

      <LineChartSection>
        <WowSection />
      </LineChartSection>
      <LineChartSection>
        <OkaySection />
      </LineChartSection>

      <AreaChartSection>
        <AboutEquitySection />
      </AreaChartSection>
    </>
  );
}

export default MultipleCharts;
