import {BORROWER_PREFIX} from 'constants/borrower';
import yup from 'lib/validation/index';

/**
 *
 * `Observations` section validation schema for the illustrator-borrower flow
 *
 */

function ObservationsValidation(props = {}) {
  const {prefix = BORROWER_PREFIX, enabled = true, demographicInformationSource = ''} = props;

  if (!enabled) return {};

  return {
    // ethnicity "toggle"
    [`${prefix}ObservedEthnicity`]: yup.string().when('demographicInformationSource', {
      is: demographicInformationSource,
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    // sex "toggle"
    [`${prefix}ObservedSex`]: yup.string().when('demographicInformationSource', {
      is: demographicInformationSource,
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    // race "toggle"
    [`${prefix}ObservedRace`]: yup.string().when('demographicInformationSource', {
      is: demographicInformationSource,
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
  };
}

export default ObservationsValidation;
