import React, {useCallback} from 'react';
import {getSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import {useSelector} from 'react-redux';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {withProducts} from 'components/products';
import {withProduct} from 'components/products/product-hoc';
import {productCalculationQueryKey} from 'constants/query';
import PropertyForm from 'pages/illustrator-borrower/property/components/PropertyForm';
import StepElement from 'pages/illustrator-borrower/components/step-element/StepElement';
import {useTranslation} from 'lib/i18n';

/**
 *
 * The "combined property" page.
 *
 */

function Property(props) {
  const {t} = useTranslation();

  return (
    <StepElement
      id="property"
      data-testid="property"
      title={t('illustratorTranslation:illustrator borrower property info.title')}
    >
      <PropertyForm />
    </StepElement>
  );
}

Property.propTypes = {};

Property.defaultValues = {};

const ProductProperty = withProduct(Property);

function PropertyProduct(props) {
  const {ApiRoute} = useSelector(getSelectedIllustratorBorrower);

  const isSelectedProduct = useCallback(
    (product) => {
      return ApiRoute ? product?.ApiRoute === ApiRoute : true;
    },
    [ApiRoute],
  );

  return (
    <ProductProperty
      {...props}
      isSelectedProduct={isSelectedProduct}
      queryKey={[productCalculationQueryKey, ApiRoute]}
    />
  );
}

const ProductsProperty = withProducts(PropertyProduct, {
  productFeatureKey: PRODUCT_FEATURE_KEY.APPLICATION_PRODUCT_DDL,
});

export default ProductsProperty;
