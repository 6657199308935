import React from 'react';
import {Chip, Tooltip} from '@material-ui/core';
import useStyles from './CustomTooltipStyles';
import {formatCurrency} from 'lib/currency';
import {isFunction} from 'lodash';

function CustomTooltip({active, payload}) {
  const classes = useStyles();

  if (active) {
    const value = isFunction(payload?.[0]?.payload?.tooltip)
      ? payload[0].payload.tooltip(payload[0].value)
      : payload[0].value;

    return (
      <Tooltip title={payload[0].payload.label}>
        <Chip className={classes.root} label={formatCurrency(value)} />
      </Tooltip>
    );
  }

  return null;
}

export default CustomTooltip;
