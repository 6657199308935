import React, {useCallback, useMemo} from 'react';
import useStyles from 'pages/illustrator/components/product-printout-page/ProductPDFPrintoutPageStyles';
import {useStyleContentEffect} from 'hooks/style-content';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import Title from 'components/common/typeography/title/Title';
import PaymentBreakdown from './components/payment-breakdown/PaymentBreakdown';
import PropTypes from 'prop-types';
import {Trans, useTranslation} from 'lib/i18n';
import {isLoaded} from 'adapter/loading.adapter';
import {usePDFPage} from 'hooks/print';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {useGetBorrowerNotesQuery} from 'query/borrower/get-borrower-notes';
import ProductPDFPrintoutChart from 'pages/product-pdf-printout-chart/ProductPDFPrintoutChart';
import ProductPDFPrintoutCashFlowDifference from 'pages/product-pdf-printout-cash-flow-difference/ProductPDFPrintoutCashFlowDifference';
import {API_ROUTES} from 'constants/illustrator';
import Form from 'components/common/form/Form';
import AmortizationTable from 'pages/illustrator/components/illustration/components/illustrator-brakedown/components/amortization-table/AmortizationTable';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';
import {useSelector} from 'react-redux';
import Disclaimer from 'components/common/disclaimer/Disclaimer';
import {withProducts} from 'components/products';
import {useProductsContext} from 'contexts/products-context';
import Button from 'components/common/button/Button';
import {withProduct} from 'components/products/product-hoc';
import IllustrationContextProvider from 'contexts/illustration-context';
import {productCalculationQueryKey} from 'constants/query';
import {themePDF} from 'lib/mui-config';
import {withThemeProvider} from 'providers/ThemeProviders';
import {noop} from 'lodash';
import {useSetProductResult} from 'query/products';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import {useAddIllustration} from 'hooks/illustrator';
import ProductFeaturesWrapper from 'components/common/features-wrapper/ProductFeaturesWrapper';

/**
 *
 * "Illustrator V2 PDF Printout" page
 *
 * This component "is" or "should be" compatible with all the products.
 *
 */

function PDFPrintoutPage(props) {
  const {loading} = useProductsContext();
  const {
    product: {
      calculation: {
        HybridResult: {MonthlySaved = undefined, NewMonthlyPayment = undefined} = {},
        BorrowerProfile: {ExistingMortgage: {MortgagePayment = undefined} = {}} = {},
      } = {},
      ApiRoute: ProductApiRoute,
      ProductName,
      ProductId,
    } = {},
  } = props;

  const illustration = useSelector(getSelectedIllustration);

  const {setProductResponse} = useSetProductResult();
  const {addIllustration} = useAddIllustration();
  const {CalculationId, loading: loadingPDF} = usePDFPage({
    withHistory: false,
    id: illustration.CalculationId,
    enabled: isLoaded({loading}),
    async onSuccess(data) {
      if (illustration.CalculationId !== data.CalculationId) addIllustration(setProductResponse({data}));
    },
  });

  const classes = useStyles();
  const {t} = useTranslation();

  useStyleContentEffect(classes.content);

  const {productQuery: {[illustration.ApiRoute]: {enabled} = {}} = {}} = useProductsContext();

  const isPageVisible = useMemo(() => {
    if (typeof enabled === 'undefined') return false;
    else if (!isLoaded({loading: loadingPDF})) return false;

    return true;
  }, [enabled, loadingPDF]);

  const {data: note} = useGetBorrowerNotesQuery({CalculationId, enabled: true});

  /**
   *
   * The right side "Broker Notes" & "Cash flow difference" should :
   *
   * Main rules :
   *
   *    - render if there are "notes" available
   *    or
   *    - render only for the "EquityAvail" or "hybrid" product
   *
   * Sub rules:
   *
   *    - render the "Cash flow diff" section only when the existing mortgage information is available
   *
   */

  const showCashFlowDifference = useMemo(() => {
    // if the "Cash flow diff" data is available return TRUE, else return FALSE
    return MonthlySaved && NewMonthlyPayment && MortgagePayment;
  }, [MonthlySaved, NewMonthlyPayment, MortgagePayment]);

  const showRightSide = useMemo(() => {
    /**
     *
     * render the right side only if :
     *
     *  - the current product is "hybrid" a.k.a. "EquityAvail"
     *    &
     *      - there are some notes available
     *        or
     *      - the cash flow difference data is available
     *
     */
    if (ProductApiRoute === API_ROUTES.HYBRID && (note || showCashFlowDifference)) return true;

    return !!note;
  }, [note, ProductApiRoute, showCashFlowDifference]);

  /**
   *
   * The "Payment Breakdown" component renders on 7 columns by default.
   *
   * If the right side is not available, the "Payment Breakdown" component should render on 12 columns.
   *
   */
  const paymentBreakdownBreakpoint = useMemo(() => (showRightSide ? 7 : 12), [showRightSide]);

  if (!isPageVisible) return null;

  return (
    <Form defaultValues={illustration} onSubmit={noop}>
      <Button type="submit" style={{display: 'none'}} />
      {/* main container / start */}
      {enabled && (
        <Container className={clsx(classes.root, classes.fixedContainer)} spacing={2}>
          {/* header / start */}
          <Item xs={12} className={classes.forPrint} noMargin>
            <Grid className={classes.m2}>
              <Title className="strong" variant="h5">
                {t('equityavailPrintoutTranslation:title')}
              </Title>
              <Title className={clsx(classes.ProductNameTitle, 'strong')} variant="h3">
                {ProductName}
              </Title>
            </Grid>
          </Item>
          {/* header / stop */}
          {/* content ( payment breakdown + broker notes ) / start */}
          <Item xs={12} className={classes.forPrint} noMargin>
            <Container className={clsx(classes.fixedContainer, classes.paymentBreakdownContainer)}>
              {/* left side / start */}
              <PaymentBreakdown
                isAnimationActive={false}
                xs={paymentBreakdownBreakpoint}
                md={paymentBreakdownBreakpoint}
                classNameListElements={classes.paymentBreakdownListElement}
                childrenContainerClassName={classes.paymentBreakdownLoaderContainer}
                noMargin
              />
              {/* left side / stop */}
              {/* right side / start */}
              {showRightSide ? (
                // The corresponding size of this breakpoint is paymentBreakdownBreakpoint
                <Item xs={5} noMargin>
                  {/* broker notes / start */}
                  <Paper
                    variant="outlined"
                    square
                    className={clsx(classes.paper, classes.m2, {[classes.hidden]: !note})}
                  >
                    <Container className={classes.noWrap} spacing={2}>
                      <Item className={classes.avatarContainer}>
                        <Avatar className={classes.avatarIcon}>W</Avatar>
                      </Item>
                      <Item>
                        <Title variant="h5" className={classes.paymentBreakdown}>
                          {t('equityavailPrintoutTranslation:broker notes')}
                        </Title>
                      </Item>
                    </Container>
                    <Grid className={classes.brokerNotesDescription}>{note?.Notes}</Grid>
                  </Paper>
                  {/* broker notes / stop */}
                  {/* cash flow difference / start */}
                  {showCashFlowDifference ? (
                    <ProductPDFPrintoutCashFlowDifference
                      className={clsx({
                        [classes.hidden]: ProductApiRoute !== API_ROUTES.HYBRID,
                        [classes.widthMarginTop]: showRightSide,
                      })}
                    />
                  ) : null}
                  {/* cash flow difference / stop */}
                </Item>
              ) : null}
              {/* right side / stop */}
            </Container>
          </Item>
          {/* content ( payment breakdown + broker notes ) / end */}

          {/* content ( "Lifetime Model" chart ) / start */}
          <ProductFeaturesWrapper
            Component={ProductPDFPrintoutChart}
            ProductFeatureKey={PRODUCT_FEATURE_KEY.AMORTIZATION_TABLE}
            ProductId={ProductId}
            className={classes.forPrint}
            noMargin
          />
          {/* content ( chart ) / stop */}

          <ProductFeaturesWrapper
            Component={Item}
            ProductFeatureKey={PRODUCT_FEATURE_KEY.AMORTIZATION_TABLE}
            ProductId={ProductId}
            xs={12}
            className={clsx(classes.forPrint)}
            noMargin
            noPadding
          >
            <Container className={classes.fixedContainer}>
              <Item xs={12} noMargin>
                <AmortizationTable
                  showProductDetailsModal={false}
                  showBreakdownDescription={false}
                  forcePrint
                  titleClassName={classes.printoutPDFAmortizationTable}
                />
              </Item>
            </Container>
          </ProductFeaturesWrapper>

          <Item xs={12} className={classes.forPrint} noMargin>
            <Disclaimer>
              <Trans i18nKey="illustratorTranslation:illustration.disclaimer" />
            </Disclaimer>
          </Item>
        </Container>
      )}
      {/* main container / stop */}
    </Form>
  );
}

PDFPrintoutPage.propTypes = {
  isAnimationActive: PropTypes.func,
};

const ProductPDFPrintout = withProduct(PDFPrintoutPage);

function IllustrationProductPDFPrintout(props) {
  const illustration = useSelector(getSelectedIllustration);

  const isSelectedProduct = useCallback(
    (product) => {
      return illustration.ApiRoute ? product?.ApiRoute === illustration.ApiRoute : true;
    },
    [illustration.ApiRoute],
  );

  return (
    <IllustrationContextProvider defaultState={illustration}>
      <ProductPDFPrintout
        {...props}
        isSelectedProduct={isSelectedProduct}
        queryKey={[productCalculationQueryKey, illustration.ApiRoute]}
      />
    </IllustrationContextProvider>
  );
}

const ProductsPDFPrintout = withProducts(IllustrationProductPDFPrintout, {
  productFeatureKey: PRODUCT_FEATURE_KEY.ILLUSTRATION_DDL,
});

export default withThemeProvider(ProductsPDFPrintout, {theme: themePDF});
