import React from 'react';
import Item from 'components/common/layout/item/Item';
import Input from 'components/common/form/input/Input';
import {useIsJumboRate} from 'hooks/jumbo-rate';
import NumberOfUnits from 'components/common/inputs/NumberOfUnits';

const JumboRateInputsItem = React.forwardRef(function JumboRateInputsItemRender(props = {}) {
  const {
    children,
    show = true,
    showAddress = show,
    showNumberOfUnits = show,
    selectVariant = 'outlined',
    ...rest
  } = props;
  const isJumboRate = useIsJumboRate();

  if (!isJumboRate) return null;

  return (
    <>
      {showAddress && (
        <Item {...rest}>
          <Input name="BorrowerProfile.Address" />
        </Item>
      )}
      {showNumberOfUnits && (
        <Item {...rest}>
          <NumberOfUnits variant={selectVariant} />
        </Item>
      )}

      {children}
    </>
  );
});

export default JumboRateInputsItem;
