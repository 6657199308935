import {makeStyles} from '@material-ui/core/styles';
import {isTabletAppQuery} from '../../../../lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    backgroundContainer: {
      backgroundColor: theme.palette.common.white,
    },
    illustratorTutorialButton: {
      position: 'fixed',
      bottom: 50,
      right: 100,
    },
    illustratorContainer: {
      position: 'relative',
    },
    readyToShare: {
      textAlign: 'center',
    },
    productComparisonBoxContainer: {
      width: '100%',
      position: 'relative',
    },
    alignLeftMenu: {
      justifyContent: 'flex-start',
    },
    alignRightMenu: {
      marginLeft: 'auto',
    },
    borrowerDetailsContainer: {
      width: '100%',
      margin: 'auto',
      [isTabletAppQuery(theme)]: {
        padding: theme.spacing(0, 1),
      },
    },
    calculationWarningContainers: {
      marginBottom: `${theme.spacing(5)}px !important`,
    },
    hide: {
      visibility: 'hidden',
    },
    changeButton: {
      color: theme.palette.primary.dark,
      textTransform: 'none',
      fontSize: `${theme.spacing(2.5)}px !important`,
    },
    productComparisonProductCards: {
      justifyContent: 'space-evenly !important',
    },
  }),
  {index: 1},
);

export default useStyles;
