import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    detailsContainer: {
      textAlign: 'left',
    },
    detailsTitleContainer: {
      padding: theme.spacing(2),
    },
    detailsTitleItem: {
      padding: `${theme.spacing(1, 0)} !important`,
      margin: 0,
      '& p': {
        fontWeight: 500,
      },
    },
    detailsTableCel: {
      borderBottom: 'unset',
      '& p': {
        fontWeight: 500,
      },
    },
    detailsDescriptionCel: {
      paddingTop: theme.spacing(1),
    },
    detailsListTableCel: {
      width: '50%',
      verticalAlign: 'top',
      padding: 0,
    },
    detailsList: {
      listStyle: 'disc',
      paddingLeft: theme.spacing(2),
      height: '100%',
    },
    detailsListElement: {
      display: 'list-item',
    },
    elementListTitle: {
      display: 'flex',
      margin: theme.spacing(0),
      padding: `${theme.spacing(0, 1.5)} !important`,
    },

    hide: {
      visibility: 'hidden',
    },
  }),
  {index: 1},
);

export default useStyles;
