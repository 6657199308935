import {useProductContext} from 'contexts/product-context';
import {API_ROUTES} from 'constants/illustrator';
import yup from 'lib/validation';
import {BORROWER_PREFIX} from 'constants/borrower';

function YupValidationSchemaGovernmentRegulation1003(props = {}) {
  const {prefix = BORROWER_PREFIX} = props;

  const {product} = useProductContext();

  if (product?.ApiRoute !== API_ROUTES.HYBRID) {
    return {};
  }

  return {
    // outstanding judgement
    [`${prefix}OutstandingJudgement`]: yup.string(),
    // declared bankruptcy
    [`${prefix}DeclaredBankruptcy`]: yup.string(),
    // declared foreclosure
    [`${prefix}DeclaredForeclosure`]: yup.string(),
    // lawsuit party
    [`${prefix}LawsuitParty`]: yup.string(),
    // loan obligation
    [`${prefix}LoanObligation`]: yup.string(),
    // loan delinquency
    [`${prefix}LoanDelinquency`]: yup.string(),
    // alimony obligation
    [`${prefix}AlimonyObligation`]: yup.string(),
    // down payment borrower
    [`${prefix}DownPaymentBorrowed`]: yup.string(),
    // note endorser
    [`${prefix}NoteEndorser`]: yup.string(),
    // product : HomeSafe 2nd : has existing FHA insured loan
    [`${prefix}HasExistingFHAInsuredLoan`]: yup.string(),
    // product : HomeSafe 2nd : existing FHA insured loan creditor name
    [`${prefix}ExistingFHAInsuredLoanCreditorName`]: yup.string().when(`${prefix}HasExistingFHAInsuredLoan`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    // U.S. citizen
    [`${prefix}UsCitizen`]: yup.string(),
    // resident alien
    [`${prefix}ResidentAlien`]: yup.string(),
    // non resident alien
    [`${prefix}NonResidentAlien`]: yup.string(),
    // property occupation
    [`${prefix}PropertyOccupation`]: yup.string().required(), // toggle
    // property ownership interest
    [`${prefix}PropertyOwnershipInterest`]: yup.string().required(), // toggle
    // property ownership interest: property type
    [`${prefix}PropertyOwnershipInterestPropertyType`]: yup.string().when(`${prefix}PropertyOwnershipInterest`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    // property ownership interest : title hold
    [`${prefix}PropertyOwnershipInterestTitleHold`]: yup.string().when(`${prefix}PropertyOwnershipInterest`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
  };
}

export default YupValidationSchemaGovernmentRegulation1003;
