import React, {useMemo} from 'react';

import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import Title from 'components/common/typeography/title/Title';
import {useTranslation} from 'lib/i18n';

/**
 *
 * page dedicated components
 *
 */
import useStyles from './DemographicInfoSexFormSectionStyles';
import InputOptions from 'components/common/form/input/InputOptions';
import DemographicInfoErrors from 'pages/illustrator-borrower/interview-and-demographic-info/components/DemographicInfoErrors';
import yup from 'lib/validation';
import {useDemographicInformationOptions} from 'hooks/link-application/borrower-info/demographic-information-source-options';
import SexValidation from 'lib/validation/illustrator-borrower/sex-validation';

/**
 *
 * ***** SECTION #2 *****
 *
 * DemographicInfo form "Sex" section
 *
 *  Options :
 *
 *    Female
 *    Male
 *    Do not wish to provide this information
 *
 */

function DemographicInfoSexFormSection(props) {
  const {
    prefix = '', // !!! the prefix prop is required !!!
  } = props;

  const classes = useStyles();
  const {t} = useTranslation();

  const prefixForSectionHeader = useMemo(() => {
    return t(`translation:common.${prefix.toLowerCase()}`);
  }, [prefix, t]);

  const {defaultOption} = useDemographicInformationOptions();
  const sexValidationSchema = useMemo(
    () =>
      yup.object(
        SexValidation({
          prefix,
          demographicInformationSource: defaultOption,
        }),
      ),
    [defaultOption, prefix],
  );

  return (
    <Container>
      {/* section title / start */}
      <Item xs={12}>
        <Title variant="h3">
          {prefixForSectionHeader} {t('illustratorTranslation:demographic information.sex')}
        </Title>
      </Item>
      {/* section title / stop */}

      <Item xs={1} />
      {/* contents / start */}
      <Item xs={10}>
        <DemographicInfoErrors prefix={`${prefix}Sex`} validationSchema={sexValidationSchema} />

        <InputOptions
          options={t('illustratorBorrowerTranslation:sex.options', {
            returnObjects: true,
            prefix: prefix,
          })}
          className={classes.labels}
          itemProps={{
            className: classes.checkboxItem,
          }}
          containerProps={{
            ...t('illustratorBorrowerTranslation:sex.containerProps', {returnObjects: true}),
            className: classes.checkboxContainer,
          }}
          itemContainerProps={{
            className: classes.checkboxItemContainer,
          }}
        />
      </Item>
      {/* contents / stop */}
      <Item xs={1} />
    </Container>
  );
}

DemographicInfoSexFormSection.propTypes = {};

export default DemographicInfoSexFormSection;
