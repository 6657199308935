import React, {useCallback, useMemo, useState} from 'react';
import Item from 'components/common/layout/item/Item';
import {Trans, useTranslation} from 'lib/i18n';
import Button from 'components/common/button/Button';
import Title from 'components/common/typeography/title/Title';
import {Box} from '@mui/material';
import {toBoolean} from 'lib/utils';
import {uniqBy} from 'lodash';
import {Alert, AlertTitle} from '@material-ui/lab';
import {prefetchPaymentTypeQuery} from 'query/payment-types';
import {LOADING_STATUS} from 'constants/loading';
import {isPending} from 'adapter/loading.adapter';

/**
 *
 * @param {Object} props
 * @param {string} props.className
 * @param {number} props.xs
 * @param {number} props.md
 * @param {string} props.multipleClassName
 * @param {boolean} props.hideNumberOfUnits
 * @param {boolean} props.hide
 * @return {JSX.Element|null}
 * @constructor
 */
function ShortToCloseQuestions(props) {
  const {xs = 12, md = 3, className, nextStep, scenario} = props;

  const {t} = useTranslation();
  const [loading, setLoading] = useState(LOADING_STATUS.IDLE);
  const [answers, setAnswers] = useState([]);
  const [errors, setErrors] = useState([]);

  const questions = useMemo(
    () => t(`illustratorTranslation:short to close.${scenario?.Key}.modal.questions`, {returnObjects: true}) || [],
    [scenario?.Key, t],
  );

  const onClick = useCallback(
    (event) => {
      setAnswers((answers) => {
        answers.unshift({
          answer: toBoolean(event.currentTarget.dataset.value),
          id: event.currentTarget.dataset.id,
        });

        return uniqBy(answers, 'id');
      });
    },
    [setAnswers],
  );

  const getButtonStyle = useCallback(
    (questionId, value) => {
      const answer = answers.find((answer) => answer.id === questionId);
      const error = errors.find((error) => error.id === questionId);

      if (answer) {
        if (value && answer.answer) return 'bgElectric';
        if (!value && !answer.answer) return 'bgElectric';
      } else if (error) return 'bgError';

      return 'whitePrimary';
    },
    [answers, errors],
  );

  const analyzeAnswers = useCallback(async () => {
    await setLoading(LOADING_STATUS.PENDING);
    if (questions.length === answers.length && !answers.find((a) => !a.answer)) {
      await prefetchPaymentTypeQuery();

      await setLoading(LOADING_STATUS.LOADED);

      return nextStep();
    } else {
      await setErrors(
        uniqBy(
          questions.filter((q) => !answers.find((a) => a.id === q.id)?.answer).map((q) => ({id: q.id})),
          'id',
        ),
      );

      await setLoading(LOADING_STATUS.NOT_LOADED);
    }
  }, [questions, answers, nextStep]);

  const isPaymentsPending = useMemo(() => isPending({loading}), [loading]);

  return (
    <>
      {questions.map((question) => (
        <Item key={question.id} xs={xs} md={md} className={className}>
          <Title variant="h5">{question.title}</Title>
          <Box display="flex" justifyContent="space-between">
            <Button
              style={{width: 'auto'}}
              btnStyle={getButtonStyle(question.id, true)}
              buttonStyling={{lg: true}}
              data-id={question.id}
              data-value={true}
              onClick={onClick}
            >
              {t('translation:common.yes')}
            </Button>
            <Button
              style={{width: 'auto'}}
              btnStyle={getButtonStyle(question.id, false)}
              buttonStyling={{lg: true}}
              data-id={question.id}
              data-value={false}
              onClick={onClick}
            >
              {t('translation:common.no')}
            </Button>
          </Box>
        </Item>
      ))}

      <Box marginTop={2}>
        {answers.length && errors.length ? (
          <Alert severity="warning">
            <AlertTitle>
              {t(`illustratorTranslation:short to close.${scenario?.Key}.modal.application rejected`)}
            </AlertTitle>
          </Alert>
        ) : null}
      </Box>

      <Box display="flex" justifyContent="center" marginTop={1}>
        <Button
          onClick={analyzeAnswers}
          btnStyle="bgElectric"
          buttonStyling={{md: true, noMargin: true}}
          disabled={answers.length !== questions.length || isPaymentsPending}
          showProgress={isPaymentsPending}
        >
          <Trans i18nKey={`illustratorTranslation:short to close.${scenario?.Key}.modal.button submit`} />
        </Button>
      </Box>
    </>
  );
}

export default ShortToCloseQuestions;
