import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import AmortizationTableAdvancedOptionsRow from 'components/illustrator/amortization-table-advanced-options-row/AmortizationTableAdvancedOptionsRow';
import 'react-data-grid/dist/react-data-grid.css';
import ReactDataGrid, {TextEditor} from 'react-data-grid';

import {formatCurrency, formatPercent} from 'lib/currency';
import useStyles from 'components/illustrator/amortization-table-advanced-options/AmortizationTableAdvancedOptionsStyles';
import Input from 'components/common/form/input/Input';
import {Box} from '@material-ui/core';
import clsx from 'clsx';
import CustomFormatter from '../../common/custom-formatter/CustomFormatter';
import {useTranslation} from 'lib/i18n';
import {advancedOptionsName} from 'constants/illustrator';
import {ToArray} from 'lib/utils';
import LinearLoader from 'components/common/loader/LinearLoader';
import {useProductContext} from 'contexts/product-context';
import {omit} from 'lodash';
import QuickFillPrintTable from 'components/illustrator/amortization-table-advanced-options/components/quick-fill-print-table/QuickFillPrintTable';
import Text from 'components/common/typeography/text/Text';

function headerRenderer({column}) {
  return <p>{column.name}</p>;
}

function getColumns({additionalAdvancedOptionHeader, handleAutoFill, hideAnnualPayments, t}) {
  let initialColumns = [
    {
      key: 'year',
      group: 0,
    },
    {
      key: 'age',
      group: 0,
    },
    {
      key: 'homeAppreciation',
      group: 1,
      editable: true,
      editor: TextEditor,
      formatter: (props) => formatPercent(props.row.homeAppreciation),
      minWidth: 100,
    },
    {
      key: 'annualPayment',
      group: 1,
      editable: true,
      editor: TextEditor,
      formatter: (props) => formatCurrency(props.row.annualPayment),
      minWidth: 100,
    },
    {
      key: 'monthlyPayment',
      group: 1,
      editable: true,
      editor: TextEditor,
      formatter: (props) => formatCurrency(props.row.monthlyPayment),
    },
    ...ToArray(
      additionalAdvancedOptionHeader
        ?.filter((v) => (typeof v.condition === 'boolean' ? v.condition : true))
        .map((option) => ({
          ...option,
          editor: option.editable ? TextEditor : undefined,
        })),
    ),
    {
      key: 'homeValueYearEnd',
      group: 2,
      formatter: (props) => formatCurrency(props.row.homeValueYearEnd),
      minWidth: 100,
      listData: 'homeValueYearStart',
    },
    {
      key: 'loanBalanceValueYearEnd',
      group: 2,
      formatter: (props) => formatCurrency(props.row.loanBalanceValueYearEnd),
      minWidth: 100,
      listData: 'loanBalanceValueYearStart',
    },
    {
      key: 'netEquityValue',
      group: 2,
      formatter: (props) => formatCurrency(props.row.netEquityValue),
      minWidth: 100,
      listData: 'netEquityStartValue',
    },
  ].map((col, index, array) => ({
    ...col,
    width: col.width,
    maxWidth: col.maxWidth,
    minWidth: col.minWidth,
    name: t(`illustratorTranslation:illustration.advanced options.${col.key}`),
    listDataName: col.listData ? t(`illustratorTranslation:illustration.advanced options.${col.listData}`) : undefined,
    headerRenderer,
    headerCellClass: !isNaN(col?.group)
      ? clsx('grouped-col', `grouped-col-${col.group}`, col.group !== array?.[index + 1]?.group ? 'last' : '')
      : undefined,
    formatter: col?.formatter
      ? (props) => (
          <CustomFormatter autoFill={handleAutoFill} {...props}>
            {col.formatter(props) ?? null}
          </CustomFormatter>
        )
      : undefined,
  }));

  if (hideAnnualPayments) {
    let filteredColumns = initialColumns.filter(function (col) {
      return col.key !== 'annualPayment';
    });
    return filteredColumns;
  }

  return initialColumns;
}

function useAmortizationTableAdvancedOptions(props) {
  const {forceActive = false} = props;
  const {product, loadingProduct: loading} = useProductContext();
  const {
    control,
    setValue,
    triggerSubmit,
    formState: {dirtyFields},
  } = useFormContext();

  const {fields} = useFieldArray({control, name: advancedOptionsName});

  const showSaveButton = !!dirtyFields[advancedOptionsName];

  return {
    product,
    isAdvancedOptionsActive: forceActive ? forceActive : product?.calculation?.showAdvancedOptions,
    fields,
    name: advancedOptionsName,
    setValue,
    triggerSubmit,
    loading,
    showSaveButton,
  };
}

function AmortizationTableAdvancedOptions(props) {
  const containerRef = useRef(null);
  const [selectedRows, setSelectedRows] = useState(() => new Set());

  const {className, dataGridClassName, showLoader = false, defaultColumnOptions} = props;
  const {isAdvancedOptionsActive, fields, name, triggerSubmit, setValue, loading} =
    useAmortizationTableAdvancedOptions(props);

  const {productDetails: {additionalAdvancedOptionHeader, hideAnnualPayments, advancedOptionsDisclaimer} = {}} =
    useProductContext();

  const {t} = useTranslation();

  const handleChanged = useCallback(
    (fields) =>
      setValue(
        name,
        fields.map((field) => omit(field, 'id')),
        {shouldDirty: true},
      ),
    [setValue, name],
  );

  const handleAutoFill = useCallback(
    async (columnKey, value, rowIdx) => {
      await handleChanged(
        fields.map((field, index) => {
          let data = {...field};
          if (index >= rowIdx) data[columnKey] = value;

          return data;
        }),
      );

      triggerSubmit();
    },
    [fields, handleChanged, triggerSubmit],
  );

  const handleFill = useCallback(({columnKey, sourceRow, targetRows}) => {
    return targetRows.map((row) => ({
      ...row,
      [columnKey]: sourceRow[columnKey],
    }));
  }, []);

  const columns = useMemo(
    () => getColumns({additionalAdvancedOptionHeader, handleAutoFill, hideAnnualPayments, t}),
    [additionalAdvancedOptionHeader, handleAutoFill, hideAnnualPayments, t],
  );

  const classes = useStyles({
    collWidth: (containerRef?.current?.offsetWidth || 0) / (columns?.length || 1),
  });

  return (
    <>
      <LinearLoader showLoader={showLoader} loading={loading}>
        <Box
          className={clsx(classes.rootContainer, className, {[classes.rootContainerActive]: isAdvancedOptionsActive})}
          ref={containerRef}
        >
          <QuickFillPrintTable className={classes.tableDataContainer} columns={columns} fields={fields} />

          {advancedOptionsDisclaimer && <Text className={classes.disclaimerText}>{advancedOptionsDisclaimer}</Text>}

          <ReactDataGrid
            className={clsx(classes.dataGridContainer, dataGridClassName)}
            defaultColumnOptions={{resizable: true, ...defaultColumnOptions}}
            rowHeight={55}
            headerRowHeight={100}
            columns={columns}
            onFill={handleFill}
            rows={fields}
            rowKeyGetter={(field) => field.id}
            selectedRows={selectedRows}
            onSelectedRowsChange={setSelectedRows}
            onRowsChange={handleChanged}
            rowRenderer={AmortizationTableAdvancedOptionsRow}
          />

          {fields.map((field, index) => (
            <React.Fragment key={field.id}>
              {columns.map((column) => (
                <Input
                  key={`${name}[${index}].${column.key}`}
                  name={`${name}[${index}].${column.key}`}
                  type="hidden"
                  defaultValue={field[column.key] ?? column.defaultValue}
                />
              ))}
            </React.Fragment>
          ))}
        </Box>
      </LinearLoader>
    </>
  );
}

AmortizationTableAdvancedOptions.propTypes = {};

export default React.memo(AmortizationTableAdvancedOptions);
