import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    headers: {
      color: theme.palette.primary.dark,
    },
    labels: {
      color: theme.palette.primary.dark,
      fontSize: theme.spacing(1.75),
      lineHeight: 1.43,
      fontWeight: 'bold',
      fontFamily:
        'Montserrat,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    },

    dcb: {
      position: 'absolute',
      top: '10px !important',
      right: '10px !important',
      padding: 0,
    },
    np: {
      padding: '0px !important',
    },
    m_1_0_1_0: {
      margin: theme.spacing(1, 0),
    },
    m_2_0_0_0: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    m_2_0_1_0: {
      margin: theme.spacing(2, 0, 1, 0),
    },
    m_4_0_0_0: {
      margin: theme.spacing(4, 0, 0, 0),
    },
    withdrawalMethod: {
      width: '100%',
      '& .MuiButton-label': {
        fontSize: theme.spacing(1.6),
      },
    },
    submitButton: {
      margin: theme.spacing(4, 0, 0, 0),
      width: '100%',
    },
    saveProfileButton: {
      margin: theme.spacing(4, 0, 0, 0),
      width: '100%',
    },
    hideButton: {
      margin: theme.spacing(1, 0, 0, 0),
      width: '100%',
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      border: '1px solid #eee',
    },

    maxWidth: {
      maxWidth: '100%',
    },
    checkBox: {
      '& .MuiButtonBase-root': {
        width: '10%',
      },
    },
  }),
  {index: 1},
);

export default useStyles;
