import {useProductValidation} from 'hooks/validator';

export function useHecmValidation() {
  const {addProductValidation} = useProductValidation({
    wrapProductData: (calculation, product) => ({
      State: calculation.State,
      productMetric: calculation.HecmMetric,
      productAvailableStates: product.ProductAvailableStates,
    }),
  });

  return {addProductValidation};
}
