import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    verticalAlignTop: {
      verticalAlign: 'top',
    },
    customSwitchElementContainer: {
      '& .MuiFormGroup-root': {
        display: 'block',
      },
    },
  }),
  {index: 1},
);

export default useStyles;
