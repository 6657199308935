import React, {useState} from 'react';
import ContentSection from 'pages/illustrator-hybrid-page/components/content-section/ContentSection';
import PieChart from 'pages/illustrator-hybrid-page/components/pie-chart/PieChart';
import {Trans} from 'lib/i18n';
import {usePrintingValue} from 'hooks/utils';

function PieChartSection(props) {
  const {children} = props;

  const [chartData, setChartData] = useState(undefined);

  const margin = usePrintingValue(undefined, {top: 30, right: 30, left: 120, bottom: 30});

  return (
    <ContentSection
      chart={<PieChart SimplifiedCashToBorrower={chartData} margin={margin} />}
      chartProps={{
        chartTitle: <Trans i18nKey="pie chart section.chart title" />,
        chartDisclaimer: <Trans i18nKey="pie chart section.disclaimer" />,
      }}
    >
      {React.Children.map(children, (child) => React.cloneElement(child, {setChartData}))}
    </ContentSection>
  );
}

PieChartSection.propTypes = {};

export default PieChartSection;
