import React, {useCallback, useMemo} from 'react';
import Item from 'components/common/layout/item/Item';
import {useTranslation} from 'lib/i18n';
import Container from 'components/common/layout/container/Container';
import Button from 'components/common/button/Button';
import Form from 'components/common/form/Form';
import yup from 'lib/validation';
import {getSelectedIllustratorBorrower, updateSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import {useDispatch, useSelector} from 'react-redux';
import {useStepWizardContext} from 'contexts/step-wizard';
import StepElement from 'pages/illustrator-borrower/components/step-element/StepElement';
import CreditAuthorizationQuestions from './components/CreditAuthorizationQuestions';

const validationSchema = yup.object({
  authorization: yup.string().required(),
  creditAuthorizationDate: yup
    .string()
    .matches(/\d{2}\/\d{2}\/\d{4}/)
    .when('authorization', {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
  report: yup.string().required(),
  reportReferenceId: yup
    .string()
    .trim()
    .when('report', {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
});

function CreditAuthorization(props) {
  const {nextStep} = useStepWizardContext();

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const illustratorBorrower = useSelector(getSelectedIllustratorBorrower);

  const onSubmitFormHandler = useCallback(
    async (data) => {
      await dispatch(
        updateSelectedIllustratorBorrower({
          creditAuthorization: data,
        }),
      );

      /**
       *
       * the next step should be : income
       *
       */

      return nextStep();
    },
    [nextStep, dispatch],
  );

  const defaultValues = useMemo(() => {
    return illustratorBorrower?.creditAuthorization;
  }, [illustratorBorrower]);

  return (
    <StepElement
      id="creditAuthorization"
      data-testid="credit-authorization"
      title={t('illustratorTranslation:illustrator borrower credit authorization.title')}
    >
      <Container
        component={Form}
        onSubmit={onSubmitFormHandler}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
      >
        <Item xs={0} md={2} lg={3} />
        <Item xs={12} md={8} lg={6}>
          <Container>
            <Item xs={12}>
              <CreditAuthorizationQuestions />
            </Item>

            <Item xs={12}>
              <Button
                type="submit"
                buttonStyling={{lg: true}}
                btnStyle="bgElectric"
                data-testid="goToNextOriginBorrowerInfo"
              >
                {t('translation:common.next')}
              </Button>
            </Item>
          </Container>
        </Item>
        <Item xs={0} md={2} lg={3} />
      </Container>
    </StepElement>
  );
}

CreditAuthorization.propTypes = {};

CreditAuthorization.defaultValues = {};

export default CreditAuthorization;
