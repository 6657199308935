import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    selectedListItem: {
      backgroundColor: theme.palette.primary.main,
    },
    lightBlue: {
      '& span': {
        color: theme.palette.primary.main + ' !important',
      },
    },
    listItem: {
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    sublistItem: {
      '&:last-child': {
        display: 'flex !important',
      },
    },
    arrow: {
      fill: theme.palette.white.main,
    },
    navigationItemIcon: {
      color: 'white',
      fontSize: theme.spacing(3.5),

      margin: theme.spacing(1, 'auto', 1, 0.25),
    },

    navigationItem: {
      '& span': {
        color: theme.palette.white.main,
        textTransform: 'uppercase',
        fontSize: '1rem',
        fontWeight: 'bold',
        paddingLeft: '4px',
      },
    },
  }),
  {index: 1},
);

export default useStyles;
