import React, {useCallback} from 'react';
import {useTranslation} from 'lib/i18n';
import IconButton from 'components/common/button/IconButton';
import {Typography} from '@material-ui/core';
import {ROUTES} from 'constants/routes';
import {useHistory} from 'react-router-dom';

function CalculatorButton(props) {
  const {t} = useTranslation();
  const history = useHistory();

  const goToCalculator = useCallback(() => history.push(ROUTES.ILLUSTRATOR_ROOT_PAGE), [history]);

  return (
    <IconButton
      colorVariant="white"
      icon={['fas', 'calculator']}
      data-testid="calculator-button"
      onClick={goToCalculator}
    >
      <Typography variant="subtitle2">{t('appHeaderTranslation:calculator')}</Typography>
    </IconButton>
  );
}

export default CalculatorButton;
