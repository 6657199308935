import React, {useCallback, useEffect, useMemo} from 'react';
import Form from 'components/common/form/Form';
import {ToNumber} from 'lib/utils';
import Container from 'components/common/layout/container/Container';
import {useDispatch, useSelector} from 'react-redux';
import {
  getSelectedIllustration,
  removeSelectedIllustration,
  updateSelectedIllustration,
} from 'reducers/illustrator.reducer';
import {withProductComparison} from 'hooks/product-comparison';
import {omit} from 'lodash';
import {useDateOfBirthValidation} from 'hooks/validator';
import {BorrowerProfilePropertyValidation, dollarLimitedField} from 'lib/validation/validation';
import yup from 'lib/validation';
import Button from 'components/common/button/Button';
import {useProductContext} from 'contexts/product-context';
import HybridAutoSubmit from 'pages/illustrator-hybrid-page/components/wizard/hybrid-wizard/components/hybrid-auto-submit/HybridAutoSubmit';
import {useIsEnabledProduct} from 'query/products/product-enabled';
import LinearLoader from 'components/common/loader/LinearLoader';
import FormContextProvider from 'contexts/form-context';
import EquityModal from 'pages/illustrator-hybrid-page/components/consumer-cash-up-front-section/components/equity-modal/EquityModal';
import {isLoaded, isPending} from 'adapter/loading.adapter';
import {API_ROUTES} from 'constants/illustrator';
import {useTranslation} from 'lib/i18n';
import {useActionsContext} from 'contexts/actions-context';
import {generateSafePath} from 'lib/react-router-dom';
import {usePrintContext} from 'contexts/print-context';
import {ActionIcon} from 'hooks/illustrator-form';

EquityAvailForm.propTypes = {};

function useEquityAvailForm() {
  const {BorrowerProfile: {Zip} = {}, ApiRoute} = useSelector(getSelectedIllustration);

  const DateOfBirthValidation = useDateOfBirthValidation({
    Zip,
    ApiRoute,
  });

  const validationSchema = useMemo(
    () =>
      BorrowerProfilePropertyValidation({
        BorrowerProfile: {
          DateOfBirth: DateOfBirthValidation,
          ExistingMortgage: yup.object({
            MortgagePayment: dollarLimitedField({dollarLimitMax: 99999999}),
            YearsRemaining: yup
              .number()
              .min(10)
              .nullable(true)
              // eslint-disable-next-line no-self-compare
              .transform((_, val) => (val === val && val ? ToNumber(val) : null)),
          }),
        },
      }),
    [DateOfBirthValidation],
  );

  return {validationSchema};
}

function EquityAvailForm(props) {
  const {
    children,
    goToFirstError,
    printPdf,
    className,
    actionButtonsClassName,
    mutateAsync,
    loadingProduct,
    childrenContainerClassName,
    pdfRoute,
  } = props;

  const {
    product,
    product: {
      calculation: {
        CalculationId,
        BorrowerProfile: {Age, ExistingMortgage: {MortgagePayment, ExistingMortgageRemainingBalance = 0} = {}} = {},
        HybridResult: {MonthlySaved, LifetimeSaved, NewMonthlyPayment} = {},
        SimplifiedCashToBorrower,
        CashAtClose,
        advancedOptions,
        HybridMetric: {CouponRateReverse} = {},
        startLoanValue,
        YearsRemaining,
        LifetimePaid,
        Rate,
      } = {},
    } = {},
  } = useProductContext();

  const dispatch = useDispatch();
  const illustration = useSelector(getSelectedIllustration);

  const onSubmit = useCallback(
    (data) => {
      dispatch(updateSelectedIllustration(omit(data, 'ApiRoute')));

      if (product) {
        return mutateAsync({product});
      }

      return undefined;
    },
    [dispatch, product, mutateAsync],
  );

  const {validationSchema} = useEquityAvailForm();

  const {isEnabled} = useIsEnabledProduct();

  const {t} = useTranslation();
  const doReset = useCallback(async () => {
    window.scrollTo({top: 0, behavior: 'smooth'});

    dispatch(removeSelectedIllustration());
  }, [dispatch]);

  const actions = useMemo(
    () => [
      {
        icon: (
          <ActionIcon
            size="lg"
            icon={['fas', 'redo']}
            title={t('illustratorTranslation:illustration.quick fill.submit button')}
          />
        ),
        name: t('translation:common.reset button'),
        onClick: doReset,
      },

      {
        icon: <ActionIcon size="lg" icon={['fas', 'print']} pulse={isPending(printPdf)} />,
        name: t('translation:common.print button'),
        onClick: () =>
          printPdf.handlePDF({src: generateSafePath(pdfRoute, {CalculationId: illustration.CalculationId})}),
        disabled: !illustration.CalculationId || isPending({loading: printPdf.loading}),
      },
    ],
    [doReset, illustration.CalculationId, pdfRoute, printPdf, t],
  );

  const {printing} = usePrintContext();
  const {setActionsContext, resetActionsContext} = useActionsContext();

  useEffect(() => {
    setActionsContext({actions, className: actionButtonsClassName});

    return () => {
      resetActionsContext();
    };
  }, [actionButtonsClassName, actions, resetActionsContext, setActionsContext]);

  return (
    <LinearLoader
      loading={loadingProduct}
      childrenContainerClassName={childrenContainerClassName}
      fixedLoader
      disableScroll
    >
      <FormContextProvider
        reset={CalculationId}
        defaultState={{
          NewMonthlyPayment,
          MortgagePayment,
          MonthlySaved,
          LifetimeSaved,
          Age,
          futureAge: ToNumber(Age) + 30,
          SimplifiedCashToBorrower,
          advancedOptions,
          CouponRateReverse,
          startLoanValue,
          CashAtClose,
          YearsRemaining,
          ExistingMortgageRemainingBalance,
          LifetimePaid,
          Rate,
        }}
        extraArguments={{
          loadingProduct,
        }}
      >
        <Container
          component={Form}
          className={className}
          defaultValues={illustration}
          onSubmit={onSubmit}
          onError={goToFirstError}
          validationSchema={validationSchema}
        >
          <HybridAutoSubmit
            enabled={product && isEnabled(product)}
            loading={loadingProduct}
            validationSchema={validationSchema}
          />
          {!printing && (
            <EquityModal
              enabled={isLoaded({loading: loadingProduct})}
              shouldSetComparisonResponse
              withBorrowerProfile
              ApiRoute={API_ROUTES.HYBRID}
            />
          )}

          {children}

          <Button type="submit" style={{display: 'none'}} />
        </Container>
      </FormContextProvider>
    </LinearLoader>
  );
}

export default withProductComparison(EquityAvailForm);
