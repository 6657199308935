import {useCallback} from 'react';
import {useProductsContext} from 'contexts/products-context';

export function useIllustrationProduct() {
  const {products} = useProductsContext();

  const getProduct = useCallback(
    (illustration) => {
      return products?.find((product) => product.ApiRoute === illustration.ApiRoute);
    },
    [products],
  );

  return {getProduct};
}
