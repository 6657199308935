import React, {useCallback, useState} from 'react';
import Button from 'components/common/button/Button';
import {useTranslation} from 'lib/i18n';
import {useLogin} from 'components/app-header/components/login-button/LoginButton';
import {useAuthenticated} from 'hooks/authenticate';
import Dialog from 'components/common/dialog/Dialog';
import Title from 'components/common/typeography/title/Title';
import clsx from 'clsx';

function LoginModal(props) {
  const {children} = props;

  const {isAuthenticated} = useAuthenticated();
  const [open, setOpen] = useState(false);

  const {t} = useTranslation();
  const {doLogin} = useLogin();

  const onClickCapture = useCallback(
    (event) => {
      if (!isAuthenticated) {
        event.stopPropagation();
        setOpen(true);
      }
    },
    [setOpen, isAuthenticated],
  );

  const handleStay = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleLogin = useCallback(() => {
    setOpen(false);
    doLogin();
  }, [doLogin, setOpen]);

  return (
    <>
      {children && React.cloneElement(children, {onClickCapture})}

      <Dialog
        onClose={handleStay}
        aria-labelledby="dialog-title"
        open={open}
        dialogHeaderProps={{
          children: (
            <Title variant="h4" className={clsx('strong')}>
              {t('loginModalTranslation:title')}
            </Title>
          ),
        }}
        dialogActionsProps={{
          children: (
            <>
              <Button onClick={handleStay}>{t('loginModalTranslation:button close')}</Button>
              <Button onClick={handleLogin} btnStyle="bgElectric" autoFocus>
                {t('loginModalTranslation:button submit')}
              </Button>
            </>
          ),
        }}
      >
        <Title variant="h6">{t('loginModalTranslation:description')}</Title>
      </Dialog>
    </>
  );
}

LoginModal.propTypes = {};

export function withLoginModal(Component) {
  /**
   * @param {Object} props
   */
  return function (props) {
    const {withLoginModal = false, ...rest} = props;

    if (withLoginModal) {
      return (
        <LoginModal>
          <Component {...rest} />
        </LoginModal>
      );
    }

    return <Component {...props} />;
  };
}

export default LoginModal;
