import React from 'react';
import Slide from '@material-ui/core/Slide';
import useStyles from './modal-error-styles';
import Title from 'components/common/typeography/title/Title';
import clsx from 'clsx';
import Button from 'components/common/button/Button';
import {useTranslation} from 'lib/i18n';
import Dialog from 'components/common/dialog/Dialog';
import {useModalClose} from 'hooks/react-hook-form';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 *
 * This components handles a error response.
 *
 */

export default function ModalError(props) {
  const {open = false, errorMessage = '', onClose: doClose} = props;

  const classes = useStyles();
  const {t} = useTranslation();

  const {onClose} = useModalClose({disableBackdropClick: true, onClose: doClose});

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
      dialogHeaderProps={{
        doClose: onClose,
      }}
      dialogActionsProps={{
        className: classes.mt_2_0_0_0,
        children: (
          <Button onClick={onClose} btnStyle="bgElectric" buttonStyling={{lg: true}}>
            {t('common.close')}
          </Button>
        ),
      }}
    >
      <Title variant="h5" className={clsx(classes.textAlignCenter, classes.fontWeight400)}>
        {errorMessage}
      </Title>
    </Dialog>
  );
}
