import React from 'react';
import {chunk} from 'lodash';
import Title from 'components/common/typeography/title/Title';
import List from 'components/common/list/List';
import Text from 'components/common/typeography/text/Text';
import useStyles from './StateListStyles';
import {Box} from '@material-ui/core';
import Item from 'components/common/layout/item/Item';
import LinearLoader from 'components/common/loader/LinearLoader';
import {useUSStates} from 'hooks/us-states';

function StateList(props) {
  const {product, tooltipContent} = props;
  const classes = useStyles();

  const {data: usStates, loading} = useUSStates();

  return (
    <Box className={classes.container}>
      <Title variant="h6" colorVariant="secondary">
        {product.ProductName}
      </Title>

      <Box>
        <LinearLoader childrenContainerClassName={classes.listContainer} loading={loading}>
          {chunk(product.ProductAvailableStates, 9)?.map((ProductAvailableStates, index) => (
            <Item key={index}>
              <List className={classes.StateListContainer}>
                {ProductAvailableStates?.map((availableState, index) => {
                  const State = usStates.find((State) => State.Abbreviation === availableState.State);

                  const isSelectedState = State?.Name === tooltipContent;

                  return (
                    <React.Fragment key={index}>
                      <Text
                        variant="subtitle1"
                        colorVariant={isSelectedState ? 'secondary' : 'primaryDark'}
                        fontWeight={isSelectedState && 'bold'}
                      >
                        {State?.Name}
                      </Text>
                    </React.Fragment>
                  );
                })}
              </List>
            </Item>
          ))}
        </LinearLoader>
      </Box>
    </Box>
  );
}

StateList.propTypes = {};

export default StateList;
