import {useVersionExposeQuery} from 'query/version';
import {theme} from 'lib/mui-config';
import {Trans, useTranslation} from 'lib/i18n';
import {useLoadedEnabledProductFeatures} from 'hooks/product-features';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {compare} from 'compare-versions';
import React, {useCallback, useState} from 'react';
import Dialog from '../dialog/Dialog';
import SubProviders from 'providers/SubProviders';
import Title from '../typeography/title/Title';
import Text from '../typeography/text/Text';
import Button from '../button/Button';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {getApprovedVersion, getAppVersion, setAppVersion} from 'reducers/version.reducer';
import {useEffectOnce} from 'react-use';

const DEFAULT_VERSION = '0.0.0';

function VersionControl(props) {
  const {t} = useTranslation();

  const store = useStore();
  const storageVersion = useSelector(getAppVersion);

  const {isEnabled} = useLoadedEnabledProductFeatures(PRODUCT_FEATURE_KEY.VERSION_CONTROL);
  const [open, setOpen] = useState(false);

  useEffectOnce(() =>
    console.log(
      ...t('versionTranslation:console message', {
        version: storageVersion,
        returnObjects: true,
        color: theme.palette.primary.dark,
      }),
    ),
  );

  const {data: version = DEFAULT_VERSION} = useVersionExposeQuery({
    enabled: isEnabled,
    onSuccess(version = DEFAULT_VERSION) {
      const approvedVersion = getApprovedVersion(version)(store.getState());

      if (!approvedVersion && compare(storageVersion, version, '<')) {
        setOpen(true);
      }
    },
  });

  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const skipVersion = useCallback(() => {
    dispatch(setAppVersion(version));

    closeModal();
  }, [closeModal, dispatch, version]);

  const updateApp = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      dialogHeaderProps={{
        children: (
          <Title variant="h4">
            <Trans i18nKey="versionTranslation:outdated title" />
          </Title>
        ),
      }}
      dialogActionsProps={{
        children: (
          <>
            <Button btnStyle="bgElectric" onClick={updateApp}>
              <Trans i18nKey="versionTranslation:outdated button submit" />
            </Button>
          </>
        ),
      }}
    >
      <Text>
        <Trans i18nKey="versionTranslation:outdated message" />
      </Text>
    </Dialog>
  );
}

VersionControl.propTypes = {};

export default function VersionControlRender(props) {
  return (
    <SubProviders defaultNS="versionTranslation">
      <VersionControl {...props} />
    </SubProviders>
  );
}
