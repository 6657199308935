import React from 'react';
import {useDialogStateMutation} from 'hooks/dialog';
import {useFormContext} from 'react-hook-form';

DialogStateElement.propTypes = {};

function DialogStateElement(props) {
  const {setState} = props;

  const {formState: {dirtyFields} = {}} = useFormContext();

  useDialogStateMutation({setState, buttonNextDisabled: !Object.keys(dirtyFields).length, buttonNextProgress: false});

  return null;
}

export default DialogStateElement;
