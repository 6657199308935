import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'lib/i18n';
import {isPending} from 'adapter/loading.adapter';
import {useEnabledProductFeatures} from 'hooks/product-features';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import IllustrationDialogShare from 'components/illustrator/borrower-notes-modal/components/illustration-dialog-share/IllustrationDialogShare';
import BorrowerNotesIllustrationsSelectDialog from 'components/illustrator/borrower-notes-modal/components/borrower-notes-illustrations-select-dialog/BorrowerNotesIllustrationsSelectDialog';
import {useShareIllustration} from 'hooks/borrower';
import {useModalClose} from 'hooks/react-hook-form';
import Dialog from 'components/common/dialog/Dialog';
import Title from 'components/common/typeography/title/Title';
import clsx from 'clsx';
import Button from 'components/common/button/Button';
import StepWizard from 'components/common/step-wizard/StepWizard';
import {useIllustrationPDFPrint} from 'hooks/print';
import {useProductContext} from 'contexts/product-context';
import useStyles from './BorrowerNotesModalStyles';
import {trackEvent} from '../../../services/analytics.service';
import {SCENARIO_CATEGORIES, SCENARIO_ACTIONS} from '../../../constants/analytic-events';

export function useBorrowerNotesModal(props) {
  const {isEnabled: isEnabledBorrowerNotesAddModal} = useEnabledProductFeatures(
    PRODUCT_FEATURE_KEY.BORROWER_NOTES_ADD_MODAL,
  );
  const {illustrations} = useShareIllustration();

  const {product: {calculation: {CalculationId} = {}} = {}} = useProductContext();
  const isBorrowerNotesEnabled = useMemo(() => isEnabledBorrowerNotesAddModal, [isEnabledBorrowerNotesAddModal]);
  const printPdf = useIllustrationPDFPrint(CalculationId, {});

  const [state, setState] = useState({
    open: false,
    illustrationsToShare: [],

    initialStep: 2,

    title: '',
    formId: '',
    buttonNext: '',
    buttonCancel: '',
    buttonNextDisabled: false,
    buttonNextProgress: false,
    isDownloadPdf: props.isDownloadPdf,
    multipleProductsSelected: false,
  });

  const doOpen = useCallback(() => {
    if (isBorrowerNotesEnabled) {
      return setState((oldState) => ({
        ...oldState,
        initialStep: illustrations?.length > 1 ? 1 : 2,
        open: true,
        illustrationsToShare: illustrations,
        multipleProductsSelected: illustrations?.length > 1,
      }));
    }

    return printPdf.handlePDF();
  }, [setState, isBorrowerNotesEnabled, printPdf, illustrations]);

  const doClose = useCallback(() => {
    setState((oldState) => ({...oldState, open: false}));
  }, [setState]);

  const loading = isPending(printPdf);

  const trackCancelEvent = useCallback(() => {
    if (state.isDownloadPdf) {
      return trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_DOWNLOAD_CANCEL);
    } else {
      return trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_SHARE_ILLUSTRATION_CANCEL);
    }
  }, [state.isDownloadPdf]);

  const trackCloseEvent = useCallback(() => {
    if (state.isDownloadPdf) {
      return trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_DOWNLOAD_CLOSE);
    } else {
      return trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_SHARE_ILLUSTRATION_CLOSE);
    }
  }, [state.isDownloadPdf]);

  return {
    state,
    doOpen,
    doClose,
    loading,
    setState,
    trackCancelEvent,
    trackCloseEvent,
  };
}

function BorrowerNotesModal(props = {}) {
  // props
  const {state, doOpen, doClose, loading, setState, trackCancelEvent, trackCloseEvent} = useBorrowerNotesModal(props);

  const {children = () => null} = props;

  const {onClose} = useModalClose({disableBackdropClick: true, onClose: doClose});
  const {t} = useTranslation();

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={state.open}
        onClose={onClose}
        classes={{paper: classes.transitionOverflow}}
        dialogHeaderProps={{
          doClose: () => {
            trackCloseEvent();
            onClose();
          },
          children: (
            <Title variant="h4" className={clsx('strong')}>
              {t(state.title)}
            </Title>
          ),
        }}
        dialogActionsProps={{
          className: classes.modalBorrowerNotesDialogActions,
          children: (
            <>
              {state.buttonCancel && (
                <Button
                  onClick={() => {
                    trackCancelEvent();
                    onClose();
                  }}
                  buttonStyling={{sm: true, noMargin: true}}
                  btnStyle="primary"
                >
                  {t(state.buttonCancel)}
                </Button>
              )}

              {state.buttonNext && (
                <Button
                  form={state.formId}
                  type="submit"
                  buttonStyling={{sm: true, noMargin: true}}
                  disabled={state.buttonNextDisabled}
                  showProgress={state.buttonNextProgress}
                  btnStyle="bgElectric"
                >
                  {t(state.buttonNext)}
                </Button>
              )}
            </>
          ),
        }}
      >
        <StepWizard initialStep={state.initialStep}>
          <IllustrationDialogShare state={state} setState={setState} />

          <BorrowerNotesIllustrationsSelectDialog state={state} doClose={doClose} setState={setState} />
        </StepWizard>
      </Dialog>
      {children({doOpen, loading})}
    </>
  );
}

BorrowerNotesModal.propTypes = {};

BorrowerNotesModal.defaultValues = {
  note: '',
};

export default BorrowerNotesModal;
