import React, {useCallback, useState} from 'react';
import {useProductFeatures} from 'hooks/product-features';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import ProductSelection from 'pages/illustrator-borrower/product-selection/ProductSelection';
import {useProductsContext} from 'contexts/products-context';
import {withProducts} from 'components/products';
import {useBorrowerProducts} from 'hooks/borrower-profile';
import {useDispatch, useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower, updateSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import {useEffectOnce} from 'react-use';
import {useIllustratorBorrowerWizardConfig} from 'pages/illustrator-borrower/illustrator-borrower-wizard/IllustratorBorrowerWizardConfig';
import {useLocation} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'lib/i18n';

const RENDER_STATE = {
  PRODUCT_SELECTION: 'product-selection',
  CHILDREN: 'children',
};

function IllustratorBorrowerWizardProductSelectionComp(props) {
  const {children, renderState, nextStep, onSingleProduct, headerComp} = props;
  const {products} = useProductsContext();
  const {isProductFeatureKeyEnabled} = useProductFeatures();

  useEffectOnce(() => {
    onSingleProduct(products);
  });

  if (
    renderState === RENDER_STATE.PRODUCT_SELECTION &&
    isProductFeatureKeyEnabled(PRODUCT_FEATURE_KEY.BORROW_PRODUCT_SELECTION)
  ) {
    return <ProductSelection nextStep={nextStep} headerComp={headerComp} />;
  }

  return children;
}

const IllustratorBorrowerWizardProductSelectionProducts = withProducts(IllustratorBorrowerWizardProductSelectionComp);

const SINGLE_PRODUCT = 1;
export default function IllustratorBorrowerWizardProductSelection(props) {
  const {canGoNext, ...rest} = props;
  const {select} = useBorrowerProducts();

  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const [renderState, setRenderState] = useState(RENDER_STATE.PRODUCT_SELECTION);

  const {getConfig} = useIllustratorBorrowerWizardConfig();

  const nextStep = useCallback(
    (ApiRoute) => {
      if (!getConfig(ApiRoute)?.steps?.length) {
        return enqueueSnackbar(t('illustratorBorrowerConfigTranslation:no product set'), {variant: 'warning'});
      }

      setRenderState(RENDER_STATE.CHILDREN);
    },
    [getConfig, enqueueSnackbar, t],
  );

  const {ApiRoute} = useSelector(getSelectedIllustratorBorrower);
  const {hash} = useLocation();

  useEffectOnce(() => {
    if (hash && ApiRoute) {
      nextStep(ApiRoute);
    }
  });

  const dispatch = useDispatch();

  const onSingleProduct = useCallback(
    async (data = []) => {
      if (data?.length === SINGLE_PRODUCT) {
        const ApiRoute = data[SINGLE_PRODUCT - 1].ApiRoute;
        await dispatch(
          updateSelectedIllustratorBorrower({
            ApiRoute,
          }),
        );

        nextStep(ApiRoute);
      }
    },
    [dispatch, nextStep],
  );

  return (
    <IllustratorBorrowerWizardProductSelectionProducts
      select={select}
      renderState={renderState}
      onSuccess={onSingleProduct}
      onSingleProduct={onSingleProduct}
      nextStep={nextStep}
      {...rest}
    />
  );
}
