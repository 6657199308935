import React, {useMemo} from 'react';
import {Text} from 'recharts';
import {ToString} from 'lib/utils';
import {useRotateYearChartAxis} from 'hooks/utils';

function YearChartAxisTick(props) {
  const {x, y, textAnchor, verticalAnchor, payload} = props;

  const {rotateX, rotateY, rotateAngle} = useRotateYearChartAxis(props);

  const value = useMemo(() => ToString(payload.value).split(',')[0], [payload.value]);

  return (
    <Text x={x + rotateX} y={y + rotateY} angle={rotateAngle} textAnchor={textAnchor} verticalAnchor={verticalAnchor}>
      {value}
    </Text>
  );
}

YearChartAxisTick.propTypes = {};

export default YearChartAxisTick;
