import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    scaleButton: {
      transition: 'transform 1s',
      transform: 'scale(1)',
      '&:hover': {
        transform: 'scale(1.25)',
      },
    },
    speedDialActions: {
      [isMobileAppQuery(theme)]: {
        position: 'absolute',
        margin: `${theme.spacing(2, 0, 0, 0)} !important`,
      },
    },
    speedDialExtendedIcon: {
      marginRight: theme.spacing(2),
    },
    speedDialFab: {},
    speedDialRoot: {
      alignItems: 'flex-end',
      marginRight: theme.spacing(5),
      [isMobileAppQuery(theme)]: {
        marginRight: theme.spacing(2),
      },
    },
    speedDial: {
      position: 'fixed',
      bottom: 50,
      right: 0,
      zIndex: theme.zIndex.drawer + 3,

      [isMobileAppQuery(theme)]: {
        bottom: `calc(100vh - 95px)`,
        right: 0,
      },
      display: theme.forPrint('none'),
    },
    noActions: {
      display: 'none',
    },
  }),
  {index: 1},
);

export default useStyles;
