import React, {useCallback, useState} from 'react';
import IllustrationTab from './IllustrationTab';
import {MenuItem, Popover} from '@material-ui/core';
import {Trans} from 'lib/i18n';
import Text from 'components/common/typeography/text/Text';
import NewIllustrationModal from 'pages/illustrator/components/new-illustration-modal/NewIllustrationModal';
import {ROUTES} from 'constants/routes';
import {useHistory} from 'react-router-dom';
import {addOneIllustrationAndRemove} from 'reducers/illustrator.reducer';
import {useDispatch} from 'react-redux';
import {trackEvent} from '../../../../../../../../services/analytics.service';
import {SCENARIO_ACTIONS, SCENARIO_CATEGORIES} from '../../../../../../../../constants/analytic-events';

const IllustrationTabDropDown = React.forwardRef(function IllustrationTabDropDownRender(props, ref) {
  const {onClick, setHasSubmitted, ...rest} = props;

  const [state, setState] = useState({anchorEl: null});

  const resetHasSubmitted = useCallback(() => {
    setHasSubmitted(false);
  }, [setHasSubmitted]);

  const handleClick = useCallback(
    (event) => {
      event.persist();
      event.stopPropagation();

      setState((oldState) => ({
        ...oldState,
        anchorEl: event.target,
      }));
    },
    [setState],
  );

  const handleClose = useCallback(
    (event) => {
      if (event) event.stopPropagation();

      setState((oldState) => ({
        ...oldState,
        anchorEl: null,
      }));
    },
    [setState],
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const onNewBorrower = useCallback(() => {
    handleClose();
    dispatch(addOneIllustrationAndRemove());

    return history.push(ROUTES.ILLUSTRATOR_ROOT_PAGE);
  }, [dispatch, history, handleClose]);

  return (
    <>
      <IllustrationTab {...rest} onClick={handleClick} />

      <NewIllustrationModal
        onOpen={() => {
          trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_ADDITION);
          handleClose();
        }}
      >
        {({openModal}) => (
          <Popover
            open={!!state.anchorEl}
            anchorEl={state.anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {/* the "New Illustration for same borrower" button / start */}
            <MenuItem onClick={openModal}>
              <Text>
                <Trans i18nKey="illustratorTranslation:illustration.new illustration" />
              </Text>
            </MenuItem>
            {/* the "New Illustration for same borrower" button / stop */}
            {/* the "New Borrower details" button / start */}
            <MenuItem
              onClick={() => {
                resetHasSubmitted();
                onNewBorrower();
              }}
            >
              <Text>
                <Trans i18nKey="illustratorTranslation:illustration.new borrower" />
              </Text>
            </MenuItem>
          </Popover>
        )}
      </NewIllustrationModal>
    </>
  );
});

export default React.memo(IllustrationTabDropDown);
