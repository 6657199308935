import {useTranslation} from 'lib/i18n';
import {useCallback, useMemo} from 'react';

export function useBooleanToString() {
  const {t} = useTranslation();

  const values = useMemo(() => t('translation:common.boolean', {returnObjects: true}), [t]);
  const booleanToString = useCallback((value) => values[value], [values]);

  return {booleanToString};
}
