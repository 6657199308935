import React, { useMemo, useEffect, useState } from 'react';

/* material ui components */
import Paper from '@material-ui/core/Paper';

/* components */
import Title from 'components/common/typeography/title/Title';

/* custom styles */
import useStyles from './PaymentBreakdownStyles';

/* subcomponents */
import clsx from 'clsx';
import {useTheme} from '@material-ui/core';

import SmallPieChartWithHorizontalLegend from './components/small-pie-chart-with-horizontal-legend/SmallPieChartWithHorizontalLegend';
import {useTranslation} from 'lib/i18n';
import LinearLoader from 'components/common/loader/LinearLoader';
import {ToNumber} from 'lib/utils';
import withWidth from 'components/common/with-width';
import ListXMainElements from './components/list-x-mainElements-y-subElements/ListXMainElements';
import SmallPieChart from './components/small-pie-chart/SmallPieChart';
import {useProductContext} from 'contexts/product-context';
import {useIllustrationContext} from 'contexts/illustration-context';
import {isPending} from 'adapter/loading.adapter';
import Item from 'components/common/layout/item/Item';
import Container from 'components/common/layout/container/Container';
import {formatCurrency} from 'lib/currency';
import {usePrintContext} from 'contexts/print-context';
import {formatPaymentBreakdownValue} from 'lib/product';
import {ListItemText} from '@material-ui/core';
import {getLocalDateTime} from 'lib/utils';
import Text from 'components/common/typeography/text/Text';
import {Box} from '@material-ui/core';

function PaymentBreakdown(props) {
  const {
    xs = 7,
    md = 7,
    className,
    showTitle: showSummary = true,
    classNameListElements,
    isAnimationActive,
    noMargin,
    childrenContainerClassName,
  } = props;


  const {id, SimplifiedCashToBorrower = 0, InitialCashDraw = 0, RemainingEquity = 0, ClosingCost = 0, FirstLien = 0, OtherLiens = 0} = useIllustrationContext();
  const {loadingProduct, product, productDetails } = useProductContext();

  const isLoading = useMemo(() => isPending({loading: loadingProduct}), [loadingProduct]);
  const theme = useTheme();

  const classes = useStyles();
  const {t, cashLabel} = useTranslation();

  const cashToBorrowerColor = useMemo(() => {
    if(product.ApiRoute == "annual" || product.ApiRoute == "select" || product.ApiRoute == "tier" || product.ApiRoute == "second"){
      return InitialCashDraw < 0 ? theme.palette.secondary.main : theme.palette.secondary.dark;
    }
    else{
      return SimplifiedCashToBorrower < 0 ? theme.palette.secondary.main : theme.palette.secondary.dark;
    }

  }, [theme.palette.secondary.main, theme.palette.secondary.dark, InitialCashDraw, SimplifiedCashToBorrower]);

  const cashToBorrowerTitleColor = useMemo(() => {
    if(product.ApiRoute == "annual" || product.ApiRoute == "select" || product.ApiRoute == "tier" || product.ApiRoute == "second"){
      return InitialCashDraw < 0 ? "#c30505" : null;
    }
    else{
      return SimplifiedCashToBorrower < 0 ? "#c30505" : null;
    }
    
  }, [InitialCashDraw, SimplifiedCashToBorrower]);

  const isShortToClose = useMemo(() => {
    return SimplifiedCashToBorrower < 0 ? true : false;
  }, [SimplifiedCashToBorrower]);

  const data4pieChart = useMemo(() => {
  const CashToBorrowerInitialCashDraw = ToNumber(InitialCashDraw);
  const CashToBorrowerSimplifiedCashToBorrower = ToNumber(SimplifiedCashToBorrower);

  if (isLoading) return [];

  return [
    {
      label: t('illustratorTranslation:payment breakdown.first lien'),
      value: FirstLien,
      formatValue: formatCurrency(FirstLien),
      color: "red",
      hide: false,
    },
    {
      label: t('illustratorTranslation:payment breakdown.other liens'),
      value: OtherLiens,
      formatValue: formatCurrency(OtherLiens),
      color: "orange",
      hide: OtherLiens == null || OtherLiens === 0 || OtherLiens === '',
    },
    {
      label: cashLabel(isShortToClose ? 'illustratorTranslation:illustration.overall results.cash due at close' : 'illustratorTranslation:illustration.overall results.initial cash draw', { 
        cash: CashToBorrowerInitialCashDraw,
      }),
      value: CashToBorrowerInitialCashDraw,
      formatValue: formatPaymentBreakdownValue(CashToBorrowerInitialCashDraw),
      color: cashToBorrowerColor,
      titleColor: cashToBorrowerTitleColor,
      tooltip() {
        return CashToBorrowerInitialCashDraw;
      },
      hide: (product.ApiRoute == "annual" || product.ApiRoute == "select" ? false : true),
    },
    {
      label: cashLabel(isShortToClose ? 'illustratorTranslation:illustration.overall results.cash due at close' : 'illustratorTranslation:illustration.overall results.requested cash', { 
        cash: CashToBorrowerSimplifiedCashToBorrower,
      }),
      value: CashToBorrowerSimplifiedCashToBorrower,
      formatValue: formatPaymentBreakdownValue(CashToBorrowerSimplifiedCashToBorrower),
      color: cashToBorrowerColor,
      titleColor: cashToBorrowerTitleColor,
      tooltip() {
        return CashToBorrowerSimplifiedCashToBorrower;
      },
      hide: (product.ApiRoute == "fixed" ? false : true),
    },
    {
      label: cashLabel(isShortToClose ? 'illustratorTranslation:illustration.overall results.cash due at close' : 'illustratorTranslation:illustration.overall results.requested cash', { 
        cash: CashToBorrowerInitialCashDraw,
      }),
      value: CashToBorrowerInitialCashDraw,
      formatValue: formatPaymentBreakdownValue(CashToBorrowerInitialCashDraw),
      color: cashToBorrowerColor,
      titleColor: cashToBorrowerTitleColor,
      tooltip() {
        return CashToBorrowerInitialCashDraw;
      },
      hide: (product.ApiRoute == "tier" || product.ApiRoute == "second" ? false : true),
    },
    {
      label: t('illustratorTranslation:payment breakdown.closing cost'),
      value: ClosingCost,
      formatValue: formatCurrency(ClosingCost),
      color: theme.palette.neutral.light,
      hide: false,
    },
    {
      label: t('illustratorTranslation:payment breakdown.remaining equity'),
      value: RemainingEquity,
      formatValue: formatCurrency(RemainingEquity),
      color: theme.palette.primary.main,
      hide: false,
    },
  ].filter((element) => !element.hide);
  }, [
    SimplifiedCashToBorrower,
    ClosingCost,
    RemainingEquity,
    theme,
    cashToBorrowerColor,
    cashToBorrowerTitleColor,
    t,
    cashLabel,
    isLoading,
    FirstLien,
    OtherLiens,
  ]);

  const {printing} = usePrintContext();
  const pieChartBreakpoints = useMemo(() => (printing ? 5 : 12), [printing]);
  const legendBreakpoints = useMemo(() => (printing ? 7 : 12), [printing]);
  const formattedDate = getLocalDateTime(product?.calculation?.SysCreateDate, "MM/DD/YYYY, hh:mm A");

  // Due to the limitation of html2pdf.js, we are not able to downscale the SVG image through CSS style.
  // So we need to scale the image down to 50% of the original size dynamically.
  const [scaledImageSrc, setScaledImageSrc] = useState('');
  useEffect(() => {
    const img = new Image();
    img.src = productDetails.img;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set the canvas dimensions to 50% of the original image dimensions
      const scale = 0.50;
      canvas.width = img.width * scale;
      canvas.height = img.height * scale;

      // Draw the scaled image onto the canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Convert the canvas to a data URL and set it as the scaled image source
      const scaledSrc = canvas.toDataURL();
      setScaledImageSrc(scaledSrc);
    };
  }, [productDetails.img]);

  return (
    <Item xs={xs} md={md} className={className} noMargin={noMargin}>
      <LinearLoader loading={loadingProduct} childrenContainerClassName={childrenContainerClassName}>
        <Paper variant="outlined" square>
          <Container className={classes.container}>

            {/* summary */}
            {showSummary && (
              <Container className={clsx(classes.summaryContainer)}>
                <Box>
                <img id='summaryImage' src={scaledImageSrc} className={clsx(classes.summaryImage)} alt={product.ProductName}/>
                  {productDetails.imgText && (
                  <Text className={classes.productName}>
                    {productDetails.imgText}
                  </Text>
                  )}
                </Box>
                <Title variant="h4" className={clsx(classes.summaryText)}>Summary</Title>
                <div className={clsx(classes.summarySysCreateDate)}>{formattedDate}</div>
              </Container>
            )}

            {/* breakdown title */}
            <Item xs={12}>
                <ListItemText 
                  primary={t('illustratorTranslation:illustration breakdown.payment-breakdown.title')} 
                  className={clsx(classes.breakdownTitle)} 
                />
            </Item>

            {/* graph section / start */}
            <Item className={classes.graphSectionContainer} xs={12}>
              <Container>
                <Item xs={pieChartBreakpoints} sm={5} lg={3} className={clsx(classes.flexElement)}>
                  <SmallPieChart
                    className={classes.smPieChartElement}
                    key={id}
                    data={data4pieChart}
                    isAnimationActive={isAnimationActive}
                  />
                </Item>
                <Item
                  xs={legendBreakpoints}
                  sm={7}
                  lg={9}
                  className={clsx(classes.flexElement, classes.smPieChartItem)}
                >
                  <Container className={clsx(classes.smPieChart)}>
                    {data4pieChart.map((element) => (
                      <SmallPieChartWithHorizontalLegend
                        key={element.label}
                        className={clsx(classes.smPieChartLegend)}
                        title={element.label}
                        titleColor={element.titleColor}
                        color={element.color}
                        value={element.formatValue}
                        dataTestId={`product-printout-page-payment-breakdown-${element.label}`}
                      />
                    ))}

                     {/* Adding a dummy element for resolving last row alignment issue in flex. */}
                     <SmallPieChartWithHorizontalLegend
                        key='dummy'
                        className={clsx(classes.smPieChartLegendDummy)}
                      />
                  </Container>
                </Item>
              </Container>
            </Item>
            {/* graph section / stop */}

            {/* list section / start */}
            <Item xs={12} className={classNameListElements}>
              <ListXMainElements />
            </Item>
            {/* list section / stop */}
          </Container>
        </Paper>
      </LinearLoader>
    </Item>
  );
}

export default withWidth(PaymentBreakdown);
