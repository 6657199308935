import Axios from 'axios';
import Cookies from 'js-cookie';
import {oktaAuth} from 'services/auth.service';
import {timeOut} from 'lib/utils';

const version = 'v1';

export const axios = Axios.create({
  baseURL: `${window.env.REACT_APP_OKTA_API_URL}/${version}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const getOktaAuthState = async () => {
  let authState = oktaAuth.authStateManager?.getAuthState();

  if (authState?.isPending) {
    await timeOut(100);

    return getOktaAuthState();
  }

  return authState || {};
};

axios.interceptors.request.use(async ({signal, ...config}) => {
  let authState = await getOktaAuthState();

  let accessToken = '';

  if (authState.isAuthenticated) {
    accessToken = authState.accessToken?.accessToken;
  }

  if (!accessToken) {
    accessToken = Cookies.get('token');
  }

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (!config.cancelToken) {
    const source = Axios.CancelToken.source();

    if (signal) {
      if (signal.aborted) {
        throw new Error('aborted');
      }

      signal.addEventListener('abort', () => source.cancel());
    }

    config.cancelToken = source.token;
  }

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (Axios.isCancel(error)) {
      return Promise.resolve({data: undefined});
    }

    return Promise.reject(error);
  },
);
