import React from 'react';
import Item from 'components/common/layout/item/Item';
import {Box} from '@material-ui/core';
import Title from 'components/common/typeography/title/Title';
import {Trans} from 'lib/i18n';
import Dialog from 'components/common/dialog/Dialog';
import StepWizard from 'components/common/step-wizard/StepWizard';
import ShortToCloseQuestions from 'components/products/hybrid/short-to-close/ShortToCloseQuestions';
import ShortToCloseForm from 'components/products/hybrid/short-to-close/ShortToCloseForm';
import {useUpdateEffect} from 'react-use';

function ShortToCloseModal(props) {
  const {scenario} = props;
  const {show: openShowShortToCloseModal, close: closeShowShortToCloseModal} = props;

  useUpdateEffect(() => {
    if (!scenario?.completed) openShowShortToCloseModal();
  }, [scenario]);

  return (
    <>
      <Dialog
        disableRestoreFocus
        stepWizardClass
        open={openShowShortToCloseModal}
        onClose={closeShowShortToCloseModal}
        aria-labelledby="new-illustration-modal-title"
        maxWidth="sm"
        dialogHeaderProps={{
          doClose: closeShowShortToCloseModal,
          withPrevious: false,
          children: (
            <Box>
              <Item xs={12}>
                <Title variant="h4">
                  <Trans i18nKey={`illustratorTranslation:short to close.${scenario?.Key}.modal.title`} />
                </Title>
              </Item>
            </Box>
          ),
        }}
      >
        <StepWizard>
          <ShortToCloseQuestions md={12} scenario={scenario} />
          <ShortToCloseForm closeModal={closeShowShortToCloseModal} md={12} scenario={scenario} />
        </StepWizard>
      </Dialog>
    </>
  );
}

export default ShortToCloseModal;
