import {isFunction, toArray} from 'lodash';
import createLoadingAdapter from 'adapter/loading.adapter';
import {isNullVal} from 'lib/utils';
import {LOADING_STATUS} from 'constants/loading';

const listInitialState = {
  selectedId: null,
};

function createListAdapter({defaultValue = () => undefined, ...options}) {
  const adapter = createLoadingAdapter(options);

  function getInitialState(state) {
    return adapter.getInitialState({...listInitialState, ...state});
  }

  function addOne(state, action) {
    const payload = isFunction(defaultValue) ? defaultValue(action.payload) : action.payload || {};

    state.selectedId = options?.selectId?.(payload);

    return adapter.addOne(state, payload);
  }

  function updateOne(state, action) {
    if (action.payload.id === state.selectedId) {
      if (action.payload.changes.id && action.payload.changes.id !== state.selectedId) {
        state.selectedId = action.payload.changes.id;
      }
    }

    return adapter.updateOne(state, action);
  }

  function addMany(state, action) {
    const defVal = defaultValue?.();
    let payload = toArray(action.payload);

    if (!payload?.length && defVal) payload = [defVal];

    return adapter.addMany(state, payload);
  }

  function removeOne(state, action) {
    const payload = action.payload ?? undefined;

    const results = adapter.removeOne(state, payload);

    const entity = adapter.getSelectors().selectAll(state).find(Boolean);

    if (entity) state.selectedId = options?.selectId?.(entity);
    else state.selectedId = undefined;

    return results;
  }

  function removeAll(state) {
    state.selectedId = undefined;
    return adapter.removeAll(state);
  }

  function setSelectedId(state, action) {
    state.selectedId = action.payload;
  }

  function setSelectedIdIfNull(state, action) {
    if (isNullVal(state.selectedId)) state.selectedId = action.payload;
  }

  function updateSelected(state, action) {
    const result = adapter.updateOne(state, {id: state.selectedId, changes: action.payload});

    if (action.payload.id) result.selectedId = action.payload.id;

    return result;
  }

  function setSelectedByPayload(state, action) {
    const selectors = getSelectors();

    const entity = selectors
      .selectAll(state)
      ?.find((entity) => Object.keys(action.payload).find((key) => action.payload[key] === entity[key]));

    if (entity) state.selectedId = options.selectId?.(entity);
  }

  function removeSelectedId(state, action) {
    state.selectedId = undefined;
  }

  function removeBySelectedId(state, action) {
    const results = adapter.removeOne(state, state.selectedId);

    state.selectedId = undefined;

    return results;
  }

  function getSelectors() {
    const selectors = adapter.getSelectors();

    function getBySelectedId(state) {
      return selectors.selectById(state, state.selectedId) || {};
    }

    function getSelectedId(state) {
      return state.selectedId;
    }

    function getLoadingStatus(state) {
      const entities = selectors.selectAll(state);

      return selectors.getLoadingStatus(
        entities?.find((e) => selectors.getLoadingStatus(e) === LOADING_STATUS.PENDING) || state,
      );
    }

    return {...selectors, getLoadingStatus, getBySelectedId, getSelectedId};
  }

  return {
    ...adapter,
    addOne,
    addMany,
    updateOne,
    removeOne,
    removeAll,
    setSelectedId,
    setSelectedIdIfNull,
    updateSelected,
    getInitialState,
    setSelectedByPayload,
    removeSelectedId,
    removeBySelectedId,
    getSelectors,
  };
}

export default createListAdapter;
