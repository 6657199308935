import {useWatch} from 'react-hook-form';
import {useMemo} from 'react';

export function useWatchObject(props) {
  const {name} = props;

  const keysToWatch = useMemo(() => (Array.isArray(name) ? name : [name]), [name]);
  const data = useWatch({name: keysToWatch});

  return useMemo(() => {
    return keysToWatch.reduce((accumulator, key, index) => {
      return {...accumulator, [key]: data[index]};
    }, {});
  }, [data, keysToWatch]);
}
