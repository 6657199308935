import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    rootExpendableContainer: {
      display: 'flex',
      width: '100%',
    },
    paperContainer: (props) => ({
      position: 'absolute',
      left: props.offsetLeft,
      width: '20%',
      top: '90%',
      '& a': {
        textAlign: 'center',
      },
    }),
    sublistPaperContainer: (props) => ({
      position: 'fixed',
      top: 'unset',
      left: 'unset',
      width: 'auto',
    }),
    linkNoUnderline: {
      cursor: 'pointer',
      '& *': {
        textDecoration: 'none !important',
      },
      '&:hover': {
        '& *': {
          color: theme.palette.primary.main,
        },
      },
    },
    sublistLinkChild: {
      '& *': {
        marginLeft: 'auto',
      },
    },
    listItemNoUnderline: {
      '& a:hover': {
        color: theme.palette.primary.main,
      },
    },
  }),
  {index: 1},
);

export default useStyles;
