import React from 'react';
import {ProductContextProvider} from 'contexts/product-context';
import {useSelectHook} from 'components/products/select/SelectHook';
import {useCalculateSelectQuery} from 'query/products/home-safe/select';
import {useSelectOverallResults} from 'components/products/select/SelectOverallResults';
import {API_ROUTES} from 'constants/illustrator';
import {useProductWithRef} from 'hooks/product-ref';

/**
 *
 * The "Select" product, aka "HomeSafe Select", part of the "HomeSafe" products.
 *
 */

const Select = React.forwardRef(function SelectRender(props, ref) {
  const {product, setRef, ...rest} = props;

  const selectHook = useSelectHook({product});
  const selectQuery = useCalculateSelectQuery({product});
  const selectOverallResults = useSelectOverallResults({product});

  useProductWithRef({
    productDetails: selectHook,
    productResults: selectOverallResults,
    productQuery: selectQuery,
    ref,
    ApiRoute: API_ROUTES.SELECT,
  });

  return (
    <ProductContextProvider
      productDetails={selectHook}
      productResults={selectOverallResults}
      productQuery={selectQuery}
      product={product}
      {...rest}
    />
  );
});

export default Select;
