import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    itemContainer: {
      justifyContent: 'space-evenly',
    },
  }),
  {index: 1},
);

export default useStyles;
