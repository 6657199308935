import React from 'react';
import Text from 'components/common/typeography/text/Text';
import {formatCurrency, formatPercent} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import useStyles from './IllustrationExtraDetailsStyles';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import LinearLoader from 'components/common/loader/LinearLoader';

IllustrationExtraDetails.propTypes = {};

function IllustrationExtraDetails(props) {
  const {loading, className, illustration} = props;

  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <LinearLoader loading={loading}>
      <Container className={className} data-testid="illustration-extra-details">
        <Item className={classes.extraDetailsItemContainer} xs={6} sm={4}>
          <Text variant="subtitle1" className={classes.label}>
            {t('borrowerNotesModalTranslation:cash to borrower')}
          </Text>
          <Text variant="subtitle2" className={classes.value}>
            {formatCurrency(illustration?.SimplifiedCashToBorrower)}
          </Text>
        </Item>

        <Item className={classes.extraDetailsItemContainer} xs={6} sm={4}>
          <Text variant="subtitle1" className={classes.label}>
            {t('borrowerNotesModalTranslation:remaining equity')}
          </Text>
          <Text variant="subtitle2" className={classes.value}>
            {formatCurrency(illustration?.RemainingEquity)}
          </Text>
        </Item>

        <Item className={classes.extraDetailsItemContainer} xs={6} sm={4}>
          <Text variant="subtitle1" className={classes.label}>
            {t('borrowerNotesModalTranslation:interest rate')}
          </Text>
          <Text variant="subtitle2" className={classes.value}>
            {formatPercent(illustration?.TotalInitialRate)}
          </Text>
        </Item>
      </Container>
    </LinearLoader>
  );
}

IllustrationExtraDetails.propTypes = {};

export default IllustrationExtraDetails;
