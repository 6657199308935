import React, {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';

AutoSubmit.propTypes = {};

function AutoSubmit(props) {
  const {condition} = props;
  const {triggerSubmit} = useFormContext();

  useEffect(() => {
    if (condition && triggerSubmit) {
      triggerSubmit();
    }
  }, [condition, triggerSubmit]);

  return null;
}

export default AutoSubmit;
