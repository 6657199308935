import React from 'react';
import Container from 'components/common/layout/container/Container';
import Button from 'components/common/button/Button';
import {useTranslation} from 'lib/i18n';
import {useWatch} from 'react-hook-form';

InterviewAndDemographicInfoFormSubmit.propTypes = {};

function InterviewAndDemographicInfoFormSubmit(props) {
  const {t} = useTranslation();

  const demographicInformationSource = useWatch({name: 'demographicInformationSource'});

  return (
    <Container {...props} hide={!demographicInformationSource}>
      <Button btnStyle="bgElectric" type="submit" buttonStyling={{lg: true}} data-testid="goToNextOriginBorrowerInfo">
        {t('illustratorTranslation:illustrator borrower mailing address.submit button')}
      </Button>
    </Container>
  );
}

export default InterviewAndDemographicInfoFormSubmit;
