import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      textAlign: 'left',
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    stateListContainer: {
      flexDirection: 'column',
      margin: 'auto',
    },
  }),
  {index: 1},
);

export default useStyles;
