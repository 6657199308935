import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    illustrationShareBoxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    illustrationShareCheckboxElement: {},
  }),
  {index: 1},
);

export default useStyles;
