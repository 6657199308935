import React, {useCallback} from 'react';
import Input from 'components/common/form/input/Input';
import {useFormContext} from 'react-hook-form';

function useTaxesAndInsuranceReserve() {
  const {setValue} = useFormContext();

  const onChange = useCallback(() => {
    setValue('BorrowerProfileId', null, {shouldDirty: true, shouldTouch: true});
  }, [setValue]);

  return {onChange};
}

function TaxesAndInsuranceReserveInput(props) {
  const {onChange} = useTaxesAndInsuranceReserve();

  return (
    <>
      <Input
        {...props}
        name="BorrowerProfile.ExistingMortgage.TaxesAndInsuranceReserve"
        type="currency"
        onCustomChange={onChange}
      />

      <Input type="hidden" name="BorrowerProfileId" />
    </>
  );
}

export default TaxesAndInsuranceReserveInput;
