import {makeStyles} from '@material-ui/core/styles';
import {fontWeight600} from 'lib/mui-config';
import {isLgSizeAppQuery, isMobileAppQuery, isSmSizeAppQuery, isTabletAppQuery, isXlSizeAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    childrenContainerClassName: {
      display: 'flex',
      overflow: 'visible',
      [isTabletAppQuery(theme)]: {
        paddingLeft: `${theme.spacing(10)}px !important`,
      },
      [isMobileAppQuery(theme)]: {
        paddingLeft: `${theme.spacing(0)}px !important`,
      },

      '& .recharts-text': {
        ...theme.typography.body2,
        fill: theme.withPrint(theme.palette.primary.dark, theme.palette.common.black),
      },
    },
    lifetimeModelLabel: {
      '&.recharts-text': {
        ...theme.typography.body1,
        fill: theme.forPrint(theme.palette.primary.dark),
      },
      whiteSpace: 'pre-wrap',
      transformOrigin: theme.withPrint('-4rem 1rem', '-7rem 3rem'),
      fontWeight: fontWeight600,
      fontSize: '1rem',
      [isSmSizeAppQuery(theme)]: {
        transformOrigin: '-3rem 0rem',
      },
      [isMobileAppQuery(theme)]: {
        transformOrigin: '-5rem 1rem',
      },
    },
    lifetimeModelIllustrationLabel: {
      position: 'absolute',
      textAlign: 'center',
      width: theme.spacing(10),

      [isXlSizeAppQuery(theme)]: {
        transform: `rotate(-90deg)`,
      },
      [isLgSizeAppQuery(theme)]: {
        transform: `rotate(-90deg)`,
      },
      [isTabletAppQuery(theme)]: {
        transform: `rotate(-90deg)`,
      },
      [isSmSizeAppQuery(theme)]: {
        transform: `rotate(-90deg)`,
      },
      transformOrigin: 'top left',

      [isMobileAppQuery(theme)]: {
        display: 'none',
      },
      transform: theme.forPrint(`rotate(-90deg) translate(-400%, -110%)`),
    },
  }),
  {index: 1},
);

export default useStyles;
