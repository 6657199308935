import yup from 'lib/validation';

import {
  CONDO,
  PUD_ATTACHED,
  PUD_DETACHED,
  SITE_CONDOMINIUM,
  TOWNHOUSE,
} from 'hooks/link-application/property-taxes/property-type';

function YupHoaMonthlyFees(props) {
  const {enabled = false} = props;

  if (!!enabled === false) return {};

  return {
    hoaMonthlyFees: yup
      .string()
      .when('propertyType', {
        is: PUD_ATTACHED,
        then: (schema) => schema.required(),
      })
      .when('propertyType', {
        is: PUD_DETACHED,
        then: (schema) => schema.required(),
      })
      .when('propertyType', {
        is: CONDO,
        then: (schema) => schema.required(),
      })
      .when('propertyType', {
        is: SITE_CONDOMINIUM,
        then: (schema) => schema.required(),
      })
      .when('propertyType', {
        is: TOWNHOUSE,
        then: (schema) => schema.required(),
      }),
  };
}

export default YupHoaMonthlyFees;
