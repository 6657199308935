import {useQuery} from 'react-query';
import {productCalculationQueryKey} from 'constants/query';
import {CancelToken} from 'axios';
import CalculationsComparisonManager from 'api/calculations-comparison-manager';
import {isFunction} from 'lodash';
import {useSetProductResult} from 'query/products/index';
import {useProductContext} from 'contexts/product-context';
import {useProductGetData} from 'query/products/product-get-data';
import {useCallback, useMemo} from 'react';
import {useIsEnabledProduct} from 'query/products/product-enabled';
import {useStore} from 'react-redux';
import {getIllustrationById} from 'reducers/illustrator.reducer';

export function useProductCalculationQuery(props = {}) {
  const {onSuccess, formatData, onError, formatResponse, enabled: moduleEnabled = true, illustration} = props;

  const {product, product: {ApiRoute} = {}} = useProductContext();
  const store = useStore();

  const {isEnabled} = useIsEnabledProduct();
  const {getData} = useProductGetData({formatData, illustration});

  const {setProductResponse} = useSetProductResult({formatResponse});

  const enabled = useMemo(() => {
    if (!product) return false;
    if (!moduleEnabled) return false;
    if (!ApiRoute) return false;

    return isEnabled(product) ?? false;
  }, [moduleEnabled, ApiRoute, isEnabled, product]);

  const productsFilter = useCallback((product) => product.ApiRoute === ApiRoute, [ApiRoute]);

  return useQuery({
    enabled,
    queryKey: [productCalculationQueryKey, ApiRoute, illustration.id, illustration.BorrowerProfileId],
    queryFn() {
      const updatedIllustration = getIllustrationById(illustration.id)(store.getState());
      const source = CancelToken.source();

      const data = getData({ApiRoute}, {withBorrowerProfile: false}, updatedIllustration);

      const promise = CalculationsComparisonManager[ApiRoute](data, {cancelToken: source.token});

      promise.cancel = () => {
        source.cancel('Query was cancelled by React Query');
      };

      return promise;
    },
    async onSuccess(response) {
      const illustration = setProductResponse(response, {productsFilter});

      if (isFunction(onSuccess)) await onSuccess(response, illustration);
    },
    async onError(error) {
      let illustration;

      if (error?.response?.data?.Message) {
        illustration = setProductResponse(
          {data: {...product, CalculationWarnings: [{WarningMessage: error.response.data.Message}]}},
          {productsFilter},
        );
      }

      if (isFunction(onError)) onError(error, illustration);
    },
    select(response) {
      return response;
    },
  });
}
