import React, {useContext, useMemo} from 'react';

const productContext = {};
const ProductContext = React.createContext(productContext);

export function useProductContext(props = {}) {
  return useContext(ProductContext);
}

/**
 *
 * @param {object} props
 * @param {React.ReactNode|(()=>React.ReactNode)} props.children
 * @param {object} props.product
 * @param {?string} props.loading
 * @param {?object} props.extraArguments
 * @return {React.ReactNode}
 * @constructor
 */
export function ProductContextProvider(props = {}) {
  const {children, product, loading, loadingProduct, productDetails, productResults, productQuery} = props;

  const context = useMemo(
    () => ({
      productDetails,
      productResults,
      productQuery,
      product,
      loading,
      loadingProduct,
    }),
    [product, productDetails, productResults, productQuery, loading, loadingProduct],
  );

  return <ProductContext.Provider value={context}>{children}</ProductContext.Provider>;
}
