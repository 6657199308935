import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../TypeographyStyles';

const Text = React.forwardRef(function TextRender(props, ref) {
  const {children, colorVariant, className, fontWeight, ...rest} = props;
  const classes = useStyles();

  return (
    <Typography className={clsx(className, classes[colorVariant])} {...rest} ref={ref}>
      {children}
    </Typography>
  );
});

Text.defaultProps = {
  variant: 'body1',
  colorVariant: 'primaryDark',
};

Text.propTypes = {
  variant: PropTypes.oneOf([
    'h5',
    'h6',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'srOnly',
    'inherit',
    'subtitle1',
    'subtitle2',
  ]),
};

export default Text;
