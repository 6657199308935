import React, {useMemo} from 'react';
import {Grid as MuiGrid, GridProps} from '@material-ui/core';

import useStyles from './ItemStyles';
import clsx from 'clsx';
import withWidth from 'components/common/with-width';

/**
 *
 * @param props {GridProps & { hide:boolean}}
 * @return {JSX.Element}
 * @constructor
 */
function Item(props) {
  const {children, className, hide, spacing, noPadding, noMargin: moduleNoMargin, ...rest} = props;
  const classes = useStyles(props);

  const noMargin = useMemo(() => spacing === 0 || moduleNoMargin, [moduleNoMargin, spacing]);

  return (
    <MuiGrid
      className={clsx(classes.item, className, {
        [classes.hide]: hide,
        [classes.noMargin]: noMargin,
        [classes.noPadding]: noPadding,
      })}
      item
      {...rest}
    >
      {children}
    </MuiGrid>
  );
}

export default withWidth(Item);
