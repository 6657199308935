import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    faqContainer: {
      textAlign: 'left',
    },
    faqElement: {
      cursor: 'pointer',
    },
  }),
  {index: 1},
);

export default useStyles;
