import React, {useEffect, useState} from 'react';
import Button from 'components/common/button/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './AlertDialogSlideStyles';
import {ROUTES} from 'constants/routes';
import {useHistory} from 'react-router-dom';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {green, red} from '@material-ui/core/colors';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import Title from 'components/common/typeography/title/Title';
import clsx from 'clsx';
import {useTranslation} from 'lib/i18n';
import {useStartApplicationCallback} from 'hooks/start-application';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 *
 * This components deals with both, TRUE or FALSE/error responses
 *
 */

export default function AlertDialogSlide(props) {
  const {
    loanNumber = '',
    statusAlertDialogSlide = null, // boolean [ true or false ] or null
    errorDescription = '',
    uat = false,
  } = props;

  const history = useHistory();
  const {t} = useTranslation();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const doStartApplicationCallback = useStartApplicationCallback();
  const handleStartNewApplication = () => {
    /**
     *
     * Keep the user on the current flow
     *
     * START a new flow & redirect to the starting page
     *
     */

    setOpen(false);

    props.close();

    return doStartApplicationCallback();
  };

  const handleReturnToIllustration = () => {
    /**
     *
     * Redirect to the Illustrator Wizard flow starting page
     *
     */

    return history.push(ROUTES.ILLUSTRATOR_ROOT_PAGE);
  };

  const handleReturnToApplication = () => {
    // close the alert dialog slide
    setOpen(false);
    props.close();
    return null;
  };

  const [classGreenOrRed, setClassGreenOrRed] = useState('');

  useEffect(() => {
    if (typeof statusAlertDialogSlide === 'boolean' || uat) {
      setOpen(true);
      // statusAlertDialogSlide = [ true or false ]
      const className = statusAlertDialogSlide
        ? classes.alertDialogSlideContainerBorderGreen
        : classes.alertDialogSlideContainerBorderRed;
      setClassGreenOrRed(className);
    } else {
      setOpen(false);
    }
  }, [statusAlertDialogSlide, setOpen, setClassGreenOrRed, classes, uat]);

  if (statusAlertDialogSlide === null) return null;

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleReturnToApplication}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={clsx(classGreenOrRed, classes.alertDialogSlide)}
      >
        <DialogTitle id="alert-dialog-slide-title" className={classes.textAlignCenter}>
          {statusAlertDialogSlide === true ? (
            <CheckCircleOutlinedIcon style={{fontSize: 60, color: green[500]}} />
          ) : null}
          {statusAlertDialogSlide === false ? <CancelOutlinedIcon style={{fontSize: 60, color: red[500]}} /> : null}
          <CloseIcon onClick={handleReturnToApplication} className={classes.closeIcon} />
        </DialogTitle>
        <DialogContent>
          {statusAlertDialogSlide === true ? (
            <>
              <Title variant="h4" className={clsx(classes.textAlignCenter, classes.fontWeight400)}>
                {t('translation:justText.alert dialog slide.link pages.the loan was successfully created in link')}
              </Title>
              <Title variant="h5" className={clsx(classes.textAlignCenter, classes.mt_2_0_0_0, classes.fontWeight400)}>
                {t('translation:justText.alert dialog slide.link pages.loan number')} {loanNumber}
              </Title>
            </>
          ) : null}
          {statusAlertDialogSlide === false ? (
            <>
              {errorDescription === '' ? (
                <>
                  <Title variant="h4" className={clsx(classes.textAlignCenter, classes.fontWeight400)}>
                    {t('translation:justText.alert dialog slide.link pages.the loan creation failed in link')}
                  </Title>
                  <Title variant="h4" className={clsx(classes.textAlignCenter, classes.fontWeight400)}>
                    {t('translation:justText.alert dialog slide.link pages.contact sales support')}
                  </Title>
                </>
              ) : (
                <>
                  <Title variant="h4" className={clsx(classes.textAlignCenter, classes.fontWeight400)}>
                    {errorDescription}
                  </Title>
                </>
              )}
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Container className={classes.mb_1_0_0_0}>
            <Item xs={6} className={classes.textAlignCenter}>
              <Button
                className={classes.alertDialogButtonElement}
                btnStyle="bgElectric"
                buttonStyling={{lg: true}}
                onClick={handleStartNewApplication}
              >
                {t('translation:common.start new application')}
              </Button>
            </Item>
            <Item xs={6} className={classes.textAlignCenter}>
              {statusAlertDialogSlide === true ? (
                <Button
                  className={classes.alertDialogButtonElement}
                  btnStyle="bgElectric"
                  buttonStyling={{lg: true}}
                  onClick={handleReturnToIllustration}
                >
                  {t('translation:common.return to illustrator')}
                </Button>
              ) : null}
              {statusAlertDialogSlide === false ? (
                <Button
                  className={classes.alertDialogButtonElement}
                  btnStyle="bgElectric"
                  buttonStyling={{lg: true}}
                  onClick={handleReturnToApplication}
                >
                  {t('translation:common.return to application')}
                </Button>
              ) : null}
            </Item>
          </Container>
        </DialogActions>
      </Dialog>
    </>
  );
}
