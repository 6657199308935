import React from 'react';
import {Grid, GridProps} from '@material-ui/core';
import useStyles from './ContinerStyles';
import clsx from 'clsx';

/**
 *
 * @param props {GridProps<Grid>}
 * @return {JSX.Element}
 */
const Container = React.forwardRef(function ContainerRender(props, ref) {
  const {children, className, hide, ...rest} = props;

  const classes = useStyles();
  return (
    <Grid {...rest} className={clsx(className, {[classes.hide]: hide})} container ref={ref}>
      {children}
    </Grid>
  );
});

Container.defaultProps = {
  spacing: 3,
};

export default Container;
