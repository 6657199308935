import React from 'react';
import useStyles from './LoanPipelineDetailsPageStyles';
import {useLinkLoanDetailsQuery} from 'query/link-loan-details';
import Container from 'components/common/layout/container/Container';
import {Box} from '@material-ui/core';
import {useParams} from 'react-router-dom';
import {LOADING_STATUS} from 'constants/loading';
import Button from 'components/common/button/Button';
import {useHistory} from 'react-router-dom';

function LoanPipelineDetailsPage() {
  const {loanId} = useParams();
  const {data: linkLoanDetails, loading} = useLinkLoanDetailsQuery({loanId});
  const classes = useStyles();
  const history = useHistory();

  function handleBack() {
    return history.goBack();
  }

  return (
    <Container className={classes.root}>
      <Box className={classes.titleContainer}>
        {loading === LOADING_STATUS.PENDING ? (
          <div>Loading...</div>
        ) : (
          <div>
            <Button btnStyle="primary" onClick={handleBack}>
              Back
            </Button>
            <br />
            <h3>Borrowers</h3>
            {linkLoanDetails?.Borrowers?.map((borrower) => (
              <div>
                <div>Age: {borrower.Age}</div>
                <div>Cell Phone: {borrower.CellPhone}</div>
                <div>DOB: {borrower.DateOfBirth}</div>
                <div>Email: {borrower.Email}</div>
                <div>First Name: {borrower.FirstName}</div>
                <div>Home Phone: {borrower.HomePhone}</div>
                <div>SSN: ***-**-{borrower.Last4SSN}</div>
                <div>Last Name: {borrower.LastName}</div>
                <div>Address: {borrower.MailingAddress}</div>
                <div>City: {borrower.MailingCity}</div>
                <div>State: {borrower.MailingState}</div>
                <div>Zip: {borrower.MailingZip}</div>
              </div>
            ))}
            <h3>Financial Assessment</h3>
            <div>Credit: {linkLoanDetails?.FinancialAssessment?.Credit}</div>
            <div>Financial Assessment Result: {linkLoanDetails?.FinancialAssessment?.FinancialAssessmentResult}</div>
            <div>Income: {linkLoanDetails?.FinancialAssessment?.Income}</div>
            <div>LESA Result: {linkLoanDetails?.FinancialAssessment?.LESAResult}</div>
            <div>Property Charges: {linkLoanDetails?.FinancialAssessment?.PropertyCharges}</div>
            <h3>Important Dates</h3>
            <div>Appraisal Expiration: {linkLoanDetails?.ImportantDates?.AppraisalExpiration}</div>
            <div>Credit Expiration: {linkLoanDetails?.ImportantDates?.CreditExpiration}</div>
            <div>PLL Expiration: {linkLoanDetails?.ImportantDates?.PLLExpiration}</div>
            <div>Title Expiration: {linkLoanDetails?.ImportantDates?.TitleExpiration}</div>
            <h3>Loan Contacts</h3>
            <div>Account Executive: {linkLoanDetails?.LoanContacts?.AccountExecutive}</div>
            <div>Client Name: {linkLoanDetails?.LoanContacts?.ClientName}</div>
            <div>Closer: {linkLoanDetails?.LoanContacts?.Closer}</div>
            <div>Funder: {linkLoanDetails?.LoanContacts?.Funder}</div>
            <div>Loan Officer: {linkLoanDetails?.LoanContacts?.LoanOfficer}</div>
            <div>Processor: {linkLoanDetails?.LoanContacts?.Processor}</div>
            <div>Underwriter: {linkLoanDetails?.LoanContacts?.Underwriter}</div>
            <div>UW Support: {linkLoanDetails?.LoanContacts?.UWSupport}</div>
            <h3>Loan Info</h3>
            <div>Application Date: {linkLoanDetails?.LoanInfo?.ApplicationDate}</div>
            <div>Appraised Value: {linkLoanDetails?.LoanInfo?.AppraisedValue}</div>
            <div>Counseling Expiration Date: {linkLoanDetails?.LoanInfo?.CounselingExpirationDate}</div>
            <div>FHA Case Assigned Date: {linkLoanDetails?.LoanInfo?.FHACaseAssignedDate}</div>
            <div>FHA Case Number: {linkLoanDetails?.LoanInfo?.FHACaseNumber}</div>
            <div>Financing Principal Limit: {linkLoanDetails?.LoanInfo?.FinancingPrincipalLimit}</div>
            <div>
              Financing Principal Limit Lock Expiration:{' '}
              {linkLoanDetails?.LoanInfo?.FinancingPrincipalLimitLockExpiration}
            </div>
            <div>Max Claim Amount: {linkLoanDetails?.LoanInfo?.MaxClaimAmount}</div>
          </div>
        )}
      </Box>
    </Container>
  );
}

LoanPipelineDetailsPage.propTypes = {};

export default LoanPipelineDetailsPage;
