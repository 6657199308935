import {useGetProducts} from 'hooks/product';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {useMemo} from 'react';
import {useProductFeature} from 'hooks/product-features';

export function useIllustrationDDLOptions(props = {}) {
  const {defaultValue} = props;

  const {filterEnabledProduct} = useProductFeature();

  const {products} = useGetProducts({
    select: (response) => {
      return response.data.filter(filterEnabledProduct(PRODUCT_FEATURE_KEY.ILLUSTRATION_DDL));
    },
  });

  const ApiRoutes = useMemo(() => {
    return products?.map((product) => ({
      value: product.ApiRoute,
      label: product.ProductName,
    }));
  }, [products]);

  return {data: ApiRoutes, defaultValue};
}
