import React from 'react';
import {useContentQuery} from 'query/content';

function ContentManagementSystem(props) {
  useContentQuery();

  return null;
}

export default ContentManagementSystem;
