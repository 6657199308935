import {useCallback} from 'react';
import {isFunction} from 'lodash';

export function useYupResolver(props = {}) {
  const {validationSchema, onError, ...options} = props;

  const resolver = useCallback(
    (data) => {
      try {
        let values = data;

        if (validationSchema) {
          values = validationSchema.validateSync(data, options);
        }

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        if (isFunction(onError)) onError(errors);

        return {
          values: {},
          errors: errors.inner?.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                ...currentError,
              },
            }),
            {},
          ),
        };
      }
    },
    [options, onError, validationSchema],
  );

  return {resolver};
}
