import moment from 'lib/moment';

export const MAX_BORROWER_DATE = moment().subtract(62, 'years');
export const MAX_BORROWER_EQUITYAVAIL_DATE = moment().subtract(60, 'years');
export const MAX_BORROWER_HECM_DATE = moment().subtract(18, 'years');

export const MIN_BORROWER_AGE = 0;
export const MAX_BORROWER_AGE = 150;

export const DEFAULT_HOME_APPRECIATION = 4;

export const BORROWER_PREFIX = 'borrower';
export const BORROWER_FOR_PARENTHESIS = 'borrower';

export const CO_BORROWER_PREFIX = 'coBorrower';
export const CO_BORROWER_FOR_PARENTHESIS = 'co-borrower';
