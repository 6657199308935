import React from 'react';
import useStyles from './LogoStyles';
import {useTranslation} from 'lib/i18n';
import {Link} from '@material-ui/core';
import clsx from 'clsx';

function Logo(props) {
  const {img, href, className} = props;

  const {t} = useTranslation();

  const classes = useStyles();

  return (
    <Link data-testid="logo" href={href} className={classes.root}>
      <img
        data-testid="logo-img"
        className={clsx(classes.logoImg, className)}
        src={img}
        alt={t('appHeaderTranslation:logo alt')}
      />
    </Link>
  );
}

export default Logo;
