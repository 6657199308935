import React, {useCallback, useState} from 'react';
import Form from 'components/common/form/Form';
import {Trans} from 'lib/i18n';
import Item from 'components/common/layout/item/Item';
import Button from 'components/common/button/Button';
import Title from 'components/common/typeography/title/Title';
import Text from 'components/common/typeography/text/Text';
import yup from 'lib/validation';
import NewIllustrationFields from './components/new-illustration-fields/NewIllustrationFields';
import {useDispatch, useSelector} from 'react-redux';
import {addOneIllustration, cleanUpIllustration, getSelectedIllustration} from 'reducers/illustrator.reducer';
import {renderChildren} from 'lib/react';
import {isFunction} from 'lodash';
import Dialog from 'components/common/dialog/Dialog';
import {Box} from '@material-ui/core';
import {setBreakDownViewType} from 'reducers/settings.reducer';

const validationSchema = yup.object({
  ApiRoute: yup.string().required(),
  IllustrationName: yup.string().notRequired(),
});

export function useNewIllustrationModalProps(props = {}) {
  const {onOpen} = props;
  const [open, setOpen] = useState(false);
  const illustration = useSelector(getSelectedIllustration);
  const dispatch = useDispatch();

  const openModal = useCallback(() => {
    setOpen(true);
    if (isFunction(onOpen)) onOpen();
  }, [setOpen, onOpen]);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const handleSubmit = useCallback(() => {
    dispatch(setBreakDownViewType());

    handleClose();
  }, [dispatch, handleClose]);

  const handleSave = useCallback(
    (data) => {
      const newIllustration = {
        ...cleanUpIllustration(illustration),
        id: undefined,
        CalculationId: '',
        ApiRoute: data.ApiRoute,
        IllustrationName: data.IllustrationName,
      };

      dispatch(addOneIllustration(newIllustration));
    },
    [dispatch, illustration],
  );

  return {
    open,
    openModal,
    handleSubmit,
    handleClose,
    handleSave,
    validationSchema,
    defaultValues: {ApiRoute: illustration.ApiRoute},
  };
}

function NewIllustrationModal(props) {
  const {children} = props;
  const {handleClose, handleSubmit, open, openModal, handleSave, validationSchema, defaultValues} =
    useNewIllustrationModalProps(props);

  return (
    <>
      <Dialog
        onClose={handleClose}
        onSubmit={handleSubmit}
        aria-labelledby="new-illustration-modal-title"
        maxWidth="lg"
        open={open}
        dialogHeaderProps={{
          doClose: handleClose,
          withPrevious: false,
          children: (
            <Box>
              <Item xs={12}>
                <Title variant="h4">
                  <Trans i18nKey="illustratorTranslation:new illustration modal.title" />
                </Title>

                <Text>
                  <Trans i18nKey="illustratorTranslation:new illustration modal.description" />
                </Text>
              </Item>
            </Box>
          ),
        }}
        dialogActionsProps={{
          children: (
            <>
              <Button onClick={handleClose} buttonStyling={{md: true, noMargin: true}}>
                <Trans i18nKey="illustratorTranslation:new illustration modal.button cancel" />
              </Button>

              <Button
                form="new-illustration"
                type="submit"
                btnStyle="bgElectric"
                buttonStyling={{md: true, noMargin: true}}
              >
                <Trans i18nKey="illustratorTranslation:new illustration modal.button create" />
              </Button>
            </>
          ),
        }}
      >
        <Form
          id="new-illustration"
          defaultValues={defaultValues}
          onSubmit={handleSave}
          validationSchema={validationSchema}
        >
          <NewIllustrationFields />
        </Form>
      </Dialog>

      {renderChildren(children, {openModal})}
    </>
  );
}

NewIllustrationModal.propTypes = {};

export default NewIllustrationModal;
