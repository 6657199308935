import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {MenuItem as MuiMenuItem} from '@material-ui/core';
import Input from 'components/common/form/input/Input';
import useStyles from './SelectStyles';
import clsx from 'clsx';
import {isFunction} from 'lodash';

const Select = React.forwardRef(function SelectRender(props, ref) {
  const {
    options = [],
    className,
    onChange: onChangeHandler, // external handler
    MenuItem = MuiMenuItem,
    MenuItemProps,
    ...rest
  } = props;

  const classes = useStyles();

  const select = useMemo(() => !!options?.length, [options]);
  const disabled = useMemo(() => !options?.length, [options]);

  const onClickMenuItemHandler = useCallback(
    (data) => {
      return (event) => {
        if (isFunction(onChangeHandler)) onChangeHandler(event, data);
      };
    },
    [onChangeHandler],
  );

  return (
    <Input {...rest} select={select} disabled={disabled} className={clsx(className, classes.alignLeft)}>
      {options?.map((option) => (
        <MenuItem
          {...MenuItemProps}
          className={clsx(classes.alignRight, {[classes.hidden]: option.hidden})}
          key={option.value}
          value={option.value}
          selected={option.selected}
          disabled={option.disabled}
          hidden={option.hidden}
          onClick={onClickMenuItemHandler(option)}
        >
          {option.label}
        </MenuItem>
      ))}
    </Input>
  );
});

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default Select;
