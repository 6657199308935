import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';

const printContext = {};

const PrintContext = React.createContext(printContext);

export function usePrintHook(props = {}) {
  const {onBeforePrint, onAfterPrint} = props;

  useEffect(() => {
    window.addEventListener('beforeprint', onBeforePrint);

    return () => window.removeEventListener('beforeprint', onBeforePrint);
  }, [onBeforePrint]);

  useEffect(() => {
    window.addEventListener('afterprint', onAfterPrint);

    return () => window.removeEventListener('afterprint', onAfterPrint);
  }, [onAfterPrint]);
}

export function usePrintContext(props = {}) {
  const {animation: animationPrinting, value: printValue, printPageTitle} = props;
  const {printing, setState, ...rest} = useContext(PrintContext);

  useEffect(() => {
    setState((prevState) => ({...prevState, printPageTitle}));
  }, [printPageTitle, setState]);

  const animation = useMemo(() => (printing ? false : animationPrinting), [animationPrinting, printing]);
  const value = useMemo(() => (printing ? printValue?.print : printValue?.def), [printValue, printing]);

  return {printing, animation, value, setState, ...rest};
}

/**
 *
 * @param props {object}
 * @param props.extraArguments
 * @param props.defaultState
 * @param props.children
 * @return {JSX.Element}
 * @constructor
 */
export function PrintContextProvider(props = {}) {
  const location = useLocation();
  const {children, defaultState = !!location.pathname.match(/pdf/i)} = props;
  const [state, setState] = useState({index: 0, printPageTitle: ''});

  const printingRef = useRef(defaultState);

  const context = useMemo(
    () => ({
      ...state,
      printing: printingRef.current,
      setState,
    }),
    [state],
  );

  return <PrintContext.Provider value={context}>{children}</PrintContext.Provider>;
}
