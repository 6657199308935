import * as abaFormTableTranslation from './abaFormTableTranslation.json';
import * as additionalPropertiesFormTranslation from './additionalPropertiesFormTranslation.json';
import * as appHeaderTranslation from './appHeaderTranslation.json';
import * as appHeaderFarTranslation from './appHeaderFarTranslation.json';
import * as borrowerDetailsModalTranslation from './borrowerDetailsModalTranslation.json';
import * as borrowerNotesModalTranslation from './borrowerNotesModalTranslation.json';
import * as demographicInfoFormTranslation from './demographicInfoFormTranslation.json';
import * as emailIllustrationsTranslation from './emailIllustrationsTranslation.json';
import * as equityavailPrintoutTranslation from './equityavailPrintoutTranslation.json';
import * as equityavailTranslation from './equityavailTranslation.json';
import * as farEquityavailTranslation from './farEquityavailTranslation.json';
import * as fundAccessTranslation from './fundAccessTranslation.json';
import * as homePageTranslation from './homePageTranslation.json';
import * as illustrationShareModalTranslation from './illustrationShareModalTranslation.json';
import * as illustratorTranslation from './illustratorTranslation.json';
import * as illustratorBorrowerConfigTranslation from './illustratorBorrowerConfigTranslation.json';
import * as illustratorBorrowerTranslation from './illustratorBorrowerTranslation.json';
import * as incomeFormTableTranslation from './incomeFormTableTranslation.json';
import InputsTranslation from './InputsTranslation.js';
import * as loginModalTranslation from './loginModalTranslation.json';
import * as productAvailabilityTranslation from './productAvailabilityTranslation.json';
import * as savedBorrowerProfileTranslation from './savedBorrowerProfileTranslation.json';
import * as savedIllustrationTranslation from './savedIllustrationTranslation.json';
import * as smartyAutoCompleteTranslation from './smartyAutoCompleteTranslation.json';
import * as translation from './translation.json';
import * as versionTranslation from './versionTranslation.json';
import * as widgetsTranslation from './widgetsTranslation.json';
import * as illustratorBorrowerDeclarationTranslation from './illustratorBorrowerDeclarationTranslation.json';

const en = {
  abaFormTableTranslation,
  additionalPropertiesFormTranslation,
  appHeaderTranslation,
  appHeaderFarTranslation,
  borrowerDetailsModalTranslation,
  borrowerNotesModalTranslation,
  demographicInfoFormTranslation,
  emailIllustrationsTranslation,
  equityavailPrintoutTranslation,
  equityavailTranslation,
  farEquityavailTranslation,
  fundAccessTranslation,
  homePageTranslation,
  illustrationShareModalTranslation,
  illustratorTranslation,
  illustratorBorrowerConfigTranslation,
  illustratorBorrowerTranslation,
  incomeFormTableTranslation,
  ...InputsTranslation,
  loginModalTranslation,
  productAvailabilityTranslation,
  savedBorrowerProfileTranslation,
  savedIllustrationTranslation,
  smartyAutoCompleteTranslation,
  translation,
  versionTranslation,
  widgetsTranslation,
  illustratorBorrowerDeclarationTranslation,
};
export default en;
