import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    extraDetailsItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
    label: {
      fontSize: theme.spacing(1.25),
    },
    value: {
      fontSize: theme.spacing(1.75),
      marginTop: 'auto',
    },
  }),
  {index: 1},
);

export default useStyles;
