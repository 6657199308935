import React from 'react';
import useStyles from './ProfileMenuStyles';
import List from 'components/common/list/List';
import {Trans, useTranslation} from 'lib/i18n';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import {ROUTES} from 'constants/routes';

function ProfileMenu(props) {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <List
      className={clsx(classes.profileMenu, classes.container)}
      listItemClassName={classes.listItem}
      flexDirection="row"
      justifyContent="flex-end"
      data-testid="profile-menu"
    >
      <Link className={classes.itemElement} href={ROUTES.PROFILE}>
        <Trans i18nKey="appHeaderTranslation:profile" />
      </Link>
      <Link className={classes.itemElement} href={ROUTES.HELP}>
        <Trans i18nKey="appHeaderTranslation:help" />
      </Link>
      <span itemScope="itemscope" className={classes.itemElement}>
        <Trans i18nKey="appHeaderTranslation:phone" />
      </span>
    </List>
  );
}

ProfileMenu.propTypes = {};

export default ProfileMenu;
