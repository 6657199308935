import React, {useCallback, useContext, useState} from 'react';
import {cloneDeep, isFunction, merge} from 'lodash';

const elementContext = {};

const ElementContext = React.createContext(elementContext);

export function useElementContext(props = {}) {
  return useContext(ElementContext);
}

/**
 *
 * @param props {object}
 * @param props.extraArguments
 * @param props.defaultState
 * @param props.children
 * @return {JSX.Element}
 * @constructor
 */
export function ElementContextProvider(props = {}) {
  const {children, defaultState = elementContext, extraArguments} = props;
  const [value, setValue] = useState(defaultState);

  const setElementContext = useCallback((data) => setValue((value) => merge(cloneDeep(value), data)), [setValue]);
  const resetElementContext = useCallback(() => setValue(defaultState), [setValue, defaultState]);

  const context = {...value, extraArguments, setElementContext, resetElementContext};

  return (
    <ElementContext.Provider value={context}>
      {isFunction(children) ? children(context) : children}
    </ElementContext.Provider>
  );
}
