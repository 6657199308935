import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    moreAboutProductTabElement: {
      fontSize: `${theme.spacing(2)}px !important`,
      fontWeight: 500,
    },
    moreAboutProductTitleElement: {
      textAlign: 'left',
    },
    hide: {
      display: 'none',
    },
  }),
  {index: 1},
);

export default useStyles;
