import {createSelector, createSlice} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const versionSlice = createSlice({
  name: 'version',
  initialState: {
    appVersion: process.env.REACT_APP_VERSION_REVISION,
    approvedVersions: {},
  },
  reducers: {
    setAppVersion: (state, action) => {
      state.approvedVersions[action.payload] = true;
    },
  },
});

function selectSelf(state) {
  return state.version;
}

export const getApprovedVersion = (version) => createSelector(selectSelf, (state) => state.approvedVersions[version]);
export const getAppVersion = createSelector(selectSelf, (state) => state.appVersion);

export const {setAppVersion} = versionSlice.actions;

const persistConfig = {
  key: 'version',
  version: 1,
  storage,
  blacklist: ['appVersion'],
};

export default persistReducer(persistConfig, versionSlice.reducer);
