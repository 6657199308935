import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower, updateSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import useStyles from 'pages/illustrator-borrower/affiliated-business-arragements/AffiliatedBusinessArrangementsStyles';
import Container from 'components/common/layout/container/Container';
import Form from 'components/common/form/Form';
import Title from 'components/common/typeography/title/Title';
import Item from 'components/common/layout/item/Item';
import yup from 'lib/validation';
import AbaFormTable from 'pages/illustrator-borrower/affiliated-business-arragements/components/aba-form-table/AbaFormTable';
import clsx from 'clsx';
import {useTranslation} from 'lib/i18n';
import {useStepWizardContext} from 'contexts/step-wizard';
import StepElement from 'pages/illustrator-borrower/components/step-element/StepElement';

/**
 *
 * the "Affiliated Business Arrangements" page
 *
 * abaFormList : [
 *
 *    0 : {
 *
 *      providerName : 'string value',
 *
 *      settlementService : 'string value',
 *
 *      estCost : 'string value as number',
 *
 *      natureOfRelation : 'string value',
 *
 *    }
 *
 *    1 : {
 *
 *      providerName : 'string value',
 *
 *      settlementService : 'string value',
 *
 *      estCost : 'string value as number',
 *
 *      natureOfRelation : 'string value',
 *
 *    }
 *
 * ]
 *
 */

const validationSchema = yup.object().shape({
  abaFormList: yup.array().of(
    yup.object().shape({
      providerName: yup.string().max(200),
      settlementService: yup.string().max(200),
      estCost: yup.string(),
      natureOfRelation: yup.string().max(200),
    }),
  ),
});

function AffiliatedBusinessArrangements(props) {
  const {nextStep} = useStepWizardContext();

  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const illustratorBorrower = useSelector(getSelectedIllustratorBorrower);

  const onSubmitFormHandler = useCallback(
    async (data) => {
      if (data?.abaFormList.length) {
        /**
         *
         * data sanitation
         *
         */

        // we need the default data format
        let validData = {
          abaFormList: [
            {
              providerName: '',
              settlementService: '',
              estCost: '',
              natureOfRelation: '',
            },
          ],
        };

        // filter the data
        let vdf = data.abaFormList.filter(
          (el) =>
            el.providerName !== '' || el.settlementService !== '' || el.estCost !== '' || el.natureOfRelation !== '',
        );

        if (vdf.length) {
          // store the new data
          validData.abaFormList = vdf;
        }

        await dispatch(
          updateSelectedIllustratorBorrower({
            affiliatedBusinessArrangements: validData,
          }),
        );
      }

      /**
       *
       * the next step should be : broker transaction question
       *
       * the "broker transaction question" and "broker transactions" are a group
       * if one page is active, both pages are active
       * if one page is inactive, both pages are inactive, so they will both be skipped
       *
       */

      return nextStep();
    },
    [nextStep, dispatch],
  );

  return (
    <StepElement
      id="affiliatedBusinessArrangements"
      title={t('illustratorTranslation:illustrator borrower affiliated business arrangements.title')}
      data-testid="affiliated-business-arrangements"
    >
      <Container
        component={Form}
        onSubmit={onSubmitFormHandler}
        defaultValues={illustratorBorrower?.affiliatedBusinessArrangements}
        validationSchema={validationSchema}
      >
        <Item xs={12} className={clsx(classes.subtitleItem)}>
          <Title variant="subtitle1">
            {t('illustratorTranslation:illustrator borrower affiliated business arrangements.subTitle')}
          </Title>
        </Item>

        <AbaFormTable />
      </Container>
    </StepElement>
  );
}

AffiliatedBusinessArrangements.propTypes = {};

AffiliatedBusinessArrangements.defaultValues = {};

export default AffiliatedBusinessArrangements;
