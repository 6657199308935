import React from 'react';
import {Typography} from '@material-ui/core';
import {useTranslation} from 'lib/i18n';
import IconButton from 'components/common/button/IconButton';
import {useLogout} from 'hooks/logout';

function LogoutButton(props) {
  const {t} = useTranslation();
  const {doLogout} = useLogout();

  return (
    <IconButton
      colorVariant="primary"
      size="lg"
      icon={['far', 'user']}
      data-testid="logout-button"
      onClick={doLogout}
      {...props}
    >
      <Typography variant="subtitle2">{t('appHeaderTranslation:logout')}</Typography>
    </IconButton>
  );
}

export default LogoutButton;
