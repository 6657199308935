import {useMemo} from 'react';
import {formatCurrency} from 'lib/currency';
import {useTranslation} from 'lib/i18n';

export function useForwardRefiOverallResults(props = {}) {
  const {
    product: {
      calculation: {SimplifiedCashToBorrower, InitialLoanBalance, HybridResult: {NewMonthlyPayment} = {}} = {},
    } = {},
  } = props;
  const {t} = useTranslation();

  const overallResults = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.remaining cash to borrower'),
        value: formatCurrency(SimplifiedCashToBorrower),
        id: 'remaining-cash-to-borrower',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.initial loan balance'),
        value: formatCurrency(InitialLoanBalance),
        id: 'initial-loan-balance',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.future payment amount'),
        value: formatCurrency(NewMonthlyPayment),
        id: 'future-payment-amount',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.payment term'),
        value: t('illustratorTranslation:illustration.overall results.equity avail years'),
        id: 'payment-term',
      },
    ],
    [SimplifiedCashToBorrower, InitialLoanBalance, NewMonthlyPayment, t],
  );
  const overallResultsSecond = useMemo(() => [], []);

  return {
    overallResults,
    overallResultsSecond,
  };
}
