import {createEntityAdapter} from '@reduxjs/toolkit';
import {LOADING_STATUS} from 'constants/loading';

const loadingInitialState = {
  loading: LOADING_STATUS.IDLE,
};

export function statusToLoading(status, isFetching) {
  if (isFetching) return LOADING_STATUS.PENDING;
  else if (status === 'idle') return LOADING_STATUS.IDLE;
  else if (status === 'success') return LOADING_STATUS.LOADED;
  else if (status === 'error') return LOADING_STATUS.NOT_LOADED;
  else if (status === 'loading') return LOADING_STATUS.PENDING;

  return LOADING_STATUS.IDLE;
}

export function isPending(state) {
  return state?.loading === LOADING_STATUS.PENDING;
}

export function isLoaded(state) {
  return state?.loading === LOADING_STATUS.LOADED;
}

export function isIdle(state) {
  return state?.loading === LOADING_STATUS.IDLE;
}

export function isNotLoaded(state) {
  return state?.loading === LOADING_STATUS.NOT_LOADED;
}

/**
 *
 * DOCS : Create Entity Adapter
 *
 * https://redux-toolkit.js.org/api/createEntityAdapter
 *
 */

function createLoadingAdapter(options) {
  const adapter = createEntityAdapter(options);

  function getInitialState(state) {
    return adapter.getInitialState({...loadingInitialState, ...state});
  }

  function setLoadingStatus(state, loading = LOADING_STATUS.IDLE) {
    state.loading = loading;
  }

  function getSelectors(opts) {
    const selectors = adapter.getSelectors(opts);

    function getLoadingStatus(state) {
      return state.loading;
    }

    return {...selectors, getLoadingStatus, isPending, isLoaded, isIdle, isNotLoaded};
  }

  return {...adapter, getInitialState, setLoadingStatus, getSelectors};
}

export default createLoadingAdapter;
