import React, {useContext} from 'react';

const illustrationContext = {};

const IllustrationContext = React.createContext(illustrationContext);

export function useIllustrationContext(props = {}) {
  return useContext(IllustrationContext);
}

/**
 *
 * @param props {object}
 * @param props.extraArguments
 * @param props.defaultState
 * @param props.children {React.ReactNode}
 * @return {JSX.Element}
 * @constructor
 */
function IllustrationContextProvider(props = {}) {
  const {children, defaultState = illustrationContext} = props;

  return <IllustrationContext.Provider value={defaultState}>{children}</IllustrationContext.Provider>;
}

export default React.memo(IllustrationContextProvider);
