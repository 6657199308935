import React, {useCallback} from 'react';
import {useVisible} from 'hooks/utils';
import {useTranslation} from 'lib/i18n';
import ContentContainer from 'pages/illustrator-hybrid-page/components/content-section/components/ContentContainer';
import {alpha, useTheme} from '@material-ui/core';
import {useSpace} from 'hooks/print';
import {useOwnFormContext} from 'contexts/form-context';

function IntriguedSection(props) {
  const {y, setChartData, showLegend, setChartTitle, setChartDisclaimer} = props;
  const {ExistingMortgageRemainingBalance} = useOwnFormContext();
  const theme = useTheme();

  const {space} = useSpace({forceSpace: !showLegend});

  const {t} = useTranslation();

  const onEnterViewport = useCallback(() => {
    const chartData = [];

    if (ExistingMortgageRemainingBalance) {
      chartData.push({
        name: t('scatter chart section.chart label.MortgagePayment', {space}),
        y: ExistingMortgageRemainingBalance,
        fill: alpha(theme.palette.primary.dark, 0.6),
      });
    }

    setChartData(chartData);
    setChartTitle(t('intrigued section.chart title'));
    setChartDisclaimer(t('intrigued section.chart disclaimer'));
  }, [setChartData, ExistingMortgageRemainingBalance, space, theme, setChartTitle, setChartDisclaimer, t]);

  const {visibleNodeRef} = useVisible({threshold: 0.75, onEnterViewport});

  return (
    <ContentContainer
      ref={visibleNodeRef}
      style={{y}}
      title={t('intrigued section.title')}
      description={t('intrigued section.description')}
      disclaimer={t('intrigued section.disclaimer')}
    />
  );
}

IntriguedSection.propTypes = {};

export default IntriguedSection;
