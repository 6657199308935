import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    smallPieChartRootElementItem: {
      margin: theme.spacing(1, 0),
      flex: '1 1 180px',
    },
    smallPieChartBoxContainer: {
      display: 'flex',
      flexDirection: 'column',

      height: '100%',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    smallPieChartTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing('auto', 0),
    },
    smallPieChartTitleElement: {
      marginLeft: theme.spacing(2),
    },
    smallPieChartValueElement: {
      fontSize: theme.withPrint(theme.spacing(2.5), theme.spacing(3.5)),
    },
    smallPieChartLoading: {
      visibility: 'hidden',
    },
    smallPieChart: {
      width: theme.spacing(2),
      minWidth: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: '50%',
    },
    smallPieChartLegendContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  {index: 1},
);

export default useStyles;
