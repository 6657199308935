import React from 'react';
import useNumberOfUnitsOptions from 'hooks/link-application/property-taxes/number-of-units';
import InRangeSelect from 'components/common/form/select/InRangeSelect';

function NumberOfUnits(props) {
  const numberOfUnits = useNumberOfUnitsOptions();

  return <InRangeSelect name="BorrowerProfile.NumberOfUnits" defaultValue="" options={numberOfUnits} {...props} />;
}

export default NumberOfUnits;
