import {useQuery} from 'react-query';
import {productsComparisonQueryKey} from 'constants/query';
import CalculationsServiceApi from 'api/calculations-api';
import {useCallback, useMemo} from 'react';
import {isFunction} from 'lodash';
import {useSetProductResult} from 'query/products';
import {useDateOfBirthValidation} from 'hooks/validator';
import {useDispatch} from 'react-redux';
import {setScenarios, removeScenarios} from 'reducers/settings.reducer';

function productIdMap(product) {
  return product?.ProductId;
}

function productFeatureFilter(productFeatureKey, options = {}) {
  const {parse = (enabled) => enabled} = options;

  return function productKeyFind(product) {
    return parse(
      product?.ProductFeatures?.find((productFeature) => {
        return productFeature?.ProductFeatureKey === productFeatureKey;
      })?.Enabled,
    );
  };
}

function useProductFeatureKeyFilter(props = {}) {
  const {products = [], productFeatureKey} = props;

  const includedProducts = useMemo(
    () => products.filter(productFeatureFilter(productFeatureKey)).map(productIdMap),
    [productFeatureKey, products],
  );

  const excludedProducts = useMemo(
    () =>
      products
        .filter(
          productFeatureFilter(productFeatureKey, {
            parse(enabled) {
              return !enabled;
            },
          }),
        )
        .map(productIdMap),
    [productFeatureKey, products],
  );

  const enabled = useMemo(
    () => includedProducts?.length > 0 || excludedProducts?.length > 0,
    [includedProducts, excludedProducts],
  );

  return {products: includedProducts, excludedProducts, enabled};
}

export function useGetCalculationComparisonQuery(props = {}) {
  const {
    onSuccess: onComparisonSuccess,
    enabled: moduleEnabled = true,
    products = [],
    shouldSetComparisonResponse = true,
    productFeatureKey,
    ApiRoute,
    withBorrowerProfile = true,
  } = props;

  const {getData, setProductResponse} = useSetProductResult();
  const dispatch = useDispatch();

  const onSuccess = useCallback(
    async (data) => {
      const productsToSet = [];

      if (data.Calculations?.length) {
        productsToSet.push(...data.Calculations);
      }
      if (data.Errors?.length) {
        productsToSet.push(
          ...data.Errors.map((error) => ({
            ProductId: products.find((product) => product.ApiRoute === error.Calculation)?.ProductId,
            CalculationId: '',
            BorrowerProfileId: '',
            Errors: [{ErrorMessage: error.Message}],
          })),
        );
      }

      if (!data.Scenarios?.length) {
        dispatch(removeScenarios(data.Scenarios));
      }

      if (data.Scenarios?.length) {
        dispatch(setScenarios(data.Scenarios));
      }

      let productResponse = undefined;
      if (shouldSetComparisonResponse) productResponse = await setProductResponse(productsToSet, {ApiRoute});

      if (isFunction(onComparisonSuccess)) onComparisonSuccess(data, productsToSet, productResponse);
    },
    [shouldSetComparisonResponse, setProductResponse, ApiRoute, onComparisonSuccess, products, dispatch],
  );

  const data = useMemo(() => getData(undefined, {withBorrowerProfile}), [getData, withBorrowerProfile]);

  const {enabled: includedExcludedEnabled, ...includedExcludedProducts} = useProductFeatureKeyFilter({
    products,
    productFeatureKey,
  });

  const dateOfBirthValidation = useDateOfBirthValidation({
    Zip: data?.BorrowerProfile?.Zip,
    ApiRoute,
  });

  const enabled = useMemo(() => {
    if (!moduleEnabled) return false;
    if (!data?.BorrowerProfileId) {
      try {
        dateOfBirthValidation.validateSync(data?.BorrowerProfile?.DateOfBirth);
      } catch (e) {
        return false;
      }
    }

    return includedExcludedEnabled;
  }, [dateOfBirthValidation, moduleEnabled, data, includedExcludedEnabled]);

  return useQuery({
    queryKey: [productsComparisonQueryKey, data, productFeatureKey, includedExcludedProducts],
    queryFn() {
      return CalculationsServiceApi.getProductComparison({...data, ...includedExcludedProducts});
    },
    enabled,
    onSuccess,
  });
}
