import React from 'react';
import collapseMenuActive from 'assets/images/collapse-menu-active.png';
import IconButton from '@material-ui/core/IconButton';
import {useTranslation} from 'lib/i18n';

OpenMenuButton.propTypes = {};

function OpenMenuButton(props) {
  const {imgClassName, ...rest} = props;
  const {t} = useTranslation();

  return (
    <IconButton color="secondary" {...rest}>
      <img
        data-testid="menu-active-img"
        className={imgClassName}
        src={collapseMenuActive}
        alt={t('translation:common.open')}
      />
    </IconButton>
  );
}

export default React.memo(OpenMenuButton);
