import {axios} from 'api/api';

export default class ValidationsApi {
  static baseUrl = '/validations';

  static validateApiKey({url, apiKey}, config) {
    return axios.post(`${this.baseUrl}/apiKey`, {apiKey}, config);
  }

  static getDateOfBirthValidation({ApiRoute, Zip}, config = {}) {
    return axios.get(`${this.baseUrl}/dob`, {params: {ApiRoute, Zip}, ...config});
  }

  static postDateOfBirthValidation({DateOfBirth, ApiRoute, Zip}, config = {}) {
    return axios.post(`${this.baseUrl}/dob`, {ApiRoute, Zip, DateOfBirth}, config);
  }

  static postJumboRateValidation({Zip}, config = {}) {
    return axios.post(`${this.baseUrl}/jumboRate`, {}, {...config, params: {zipCode: Zip}});
  }
}
