import {ToNumber, ToNumberOrDefault} from 'lib/utils';
import {useCallback} from 'react';
import {useHecmValidation} from 'query/products/hecm/hecm-validation';
import {useHECMFixedRatesQuery} from 'query/products/hecm/hecmFixedRates';
import {omit} from 'lodash';

export function useCalculateFixedQuery(props) {
  const {defaultRate} = useHECMFixedRatesQuery();

  const addAdditionalData = useCallback(
    (data, illustration) => {
      return {
        FixedRate: ToNumber(data.FixedRate, defaultRate?.FixedRate),
        Rate: ToNumberOrDefault(illustration.FixedRate, defaultRate?.FixedRate),
        Type: 'Fixed',
      };
    },
    [defaultRate],
  );

  const addAdditionalResults = useCallback(
    (data) => {
      return {
        HecmMetric: data.HecmMetric,
        FixedRate: ToNumber(data.FixedRate, defaultRate?.FixedRate),
        TotalInitialRate: ToNumber(data.Rate),
        showLearnMore: false,
        hideModelImpactButton: false,
      };
    },
    [defaultRate?.FixedRate],
  );

  const {addProductValidation} = useHecmValidation();

  const advancedOptionsMap = useCallback((d) => omit(d, 'locYearEnd'), []);

  return {
    enabled: !!defaultRate,
    addAdditionalData,
    addAdditionalResults,
    addProductValidation,
    advancedOptionsMap,
  };
}
