import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    borrowerDetailsPaper: {
      height: '100%',
      textAlign: 'center',
    },
    borrowerDetailsContainer: {
      marginBottom: theme.spacing(0),
    },
    borrowerDetailsButtonContainer: {
      marginTop: '0 !important',
      paddingTop: '0 !important',
      marginBottom: '0 !important',
      paddingBottom: '0 !important',
    },
  }),
  {index: 1},
);

export default useStyles;
