import React from 'react';
import {useTranslation} from 'lib/i18n';
import BorrowerInfoForm from 'pages/illustrator-borrower/borrower-info/components/borrower-info-form/BorrowerInfoForm';
import StepElement from 'pages/illustrator-borrower/components/step-element/StepElement';

function BorrowerInfo(props) {
  const {prefix = 'borrower'} = props;
  const {t} = useTranslation();

  return (
    <StepElement
      id={`${prefix}-info`}
      data-testid={`${prefix}-info`}
      title={t(`illustratorTranslation:illustrator borrower info.title ${prefix}`)}
    >
      <BorrowerInfoForm prefix={prefix} />
    </StepElement>
  );
}

BorrowerInfo.propTypes = {};

BorrowerInfo.defaultValues = {};

export default BorrowerInfo;
