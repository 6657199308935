import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    loader: {
      width: '100%',
      marginRight: 'auto',
      display: theme.forPrint('none !important'),
    },
    fixedLoader: {
      position: 'fixed',
    },
    absoluteLoader: {
      position: 'absolute',
    },
    childrenContainer: {
      marginTop: theme.spacing(2),
      margin: theme.forPrint(theme.spacing(2)),
      opacity: theme.withPrint(1, '1 !important'),

      [isMobileAppQuery(theme)]: {
        marginTop: theme.spacing(1),
      },
    },
    childrenContainerLoading: {
      opacity: 0.3,
    },
  }),
  {index: 1},
);

export default useStyles;
