import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      fontSize: theme.spacing(2),
      fontWeight: 500,
    },
  }),
  {index: 1},
);

export default useStyles;
