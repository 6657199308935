import React, {useCallback, useEffect, useMemo} from 'react';
import {ALL_ILLUSTRATIONS} from 'constants/illustrator';
import Input from 'components/common/form/input/Input';
import {useTranslation} from 'lib/i18n';
import {useFormContext} from 'react-hook-form';
import {toBoolean} from 'lib/utils';

function SelectAllIllustrationsButton(props) {
  const {t} = useTranslation();

  const {watch, setValue} = useFormContext();
  const allKeys = watch();

  const keys = useMemo(() => Object.keys(allKeys).filter((key) => key !== ALL_ILLUSTRATIONS), [allKeys]);

  const values = useMemo(() => keys.map((key) => allKeys[key]), [keys, allKeys]);

  const allSelected = useMemo(() => keys.length === values.filter(Boolean).length, [keys, values]);

  useEffect(() => {
    setValue(ALL_ILLUSTRATIONS, allSelected, {shouldDirty: true});
  }, [allSelected, setValue]);

  // This is triggered before the actual change of the checkbox!
  const setAllIllustrations = useCallback(
    (event) => {
      // Assuming this, we can suppose that value will be changed to his negation
      const value = toBoolean(event.target.checked);

      keys.forEach((key) => setValue(key, value, {shouldDirty: true}));
    },
    [keys, setValue],
  );

  return (
    <Input
      name={ALL_ILLUSTRATIONS}
      type="checkbox"
      onChangeCapture={setAllIllustrations}
      label={t('illustrationShareModalTranslation:select all')}
    />
  );
}

SelectAllIllustrationsButton.propTypes = {};

export default SelectAllIllustrationsButton;
