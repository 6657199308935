import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      margin: 'auto',
    },
    logoImg: {
      marginLeft: 18,
      verticalAlign: 'middle',
      [isMobileAppQuery(theme)]: {
        maxHeight: 30,
      },
    },
    loginLogoImg: {
      maxHeight: 42,
    },
    logoutLogoImg: {
      maxHeight: 87,
    },
  }),
  {index: 1},
);

export default useStyles;
