import React, {useCallback, useRef} from 'react';
import Item from 'components/common/layout/item/Item';
import Container from 'components/common/layout/container/Container';
import Button from 'components/common/button/Button';
import {Trans, useTranslation} from 'lib/i18n';
import useStyles from './ProductDetailsStyles';
import clsx from 'clsx';
import LinearLoader from 'components/common/loader/LinearLoader';
import {useStartApplication} from 'hooks/start-application';
import ApiRoutesSelect from 'components/illustrator/api-routes-select/ApiRoutesSelect';
import AdditionalSteps from './components/additional-steps/AdditionalSteps';
import {disclaimerClassName} from 'constants/disclaimer';
import {ProductCalculationWarnings} from 'components/illustrator/calculation-warnings/CalculationWarnings';
import {EXCLUDE_ILLUSTRATOR_WARNING_MESSAGES} from 'constants/illustrator';
import {useProductContext} from 'contexts/product-context';
import {useIllustrationContext} from 'contexts/illustration-context';
import {isPending} from 'adapter/loading.adapter';
import {ROUTES} from 'constants/routes';
import LoginModal from 'components/app-header/components/login-modal/LoginModal';
import {useIllustratorProductChange} from 'hooks/illustrator';
import SlideOutModal from 'pages/slide-out-modal-page/slide-out-modal/SlideOutModal';
import SlideOutModalSubmitButton from 'pages/slide-out-modal-page/slide-out-modal/components/SlideOutModalSubmitButton';
import {trackEvent} from 'services/analytics.service';
import {SCENARIO_ACTIONS, SCENARIO_CATEGORIES, PRODUCT_CATEGORIES, PRODUCT_ACTIONS} from 'constants/analytic-events';

/**
 *
 * This component is used within the "Illustrator V2 #compare" page.
 *
 * It is used within the "Payment Breakdown" section, this is the left side of the page, this is the dynamic form.
 *
 */

function ProductDetails(props = {}) {
  const {className, withSpacing, setHasSubmitted, ...rest} = props;
  const classes = useStyles();
  const {product, loadingProduct} = useProductContext();

  const goToProductDisclaimer = useCallback(() => {
    const disclaimerContainer = document.getElementsByClassName(disclaimerClassName)?.[0];

    disclaimerContainer?.scrollIntoView({behavior: 'smooth', block: 'center'});
  }, []);

  const {onChange: onIllustratorProductChange} = useIllustratorProductChange();
  const {showStartApplicationButton, onStartApplicationButtonClick} = useStartApplication({setHasSubmitted});

  const calculationWarningsRef = useRef();

  const illustration = useIllustrationContext();

  const {t} = useTranslation();
  const borrowerProfile = product?.calculation?.BorrowerProfile ? product.calculation.BorrowerProfile : {};
  const enabled = product?.calculation?.BorrowerProfile?.BorrowerProfileId
    ? !!product.calculation.BorrowerProfile.BorrowerProfileId
    : false;

  return (
    <Item {...rest} className={clsx(classes.productDetailsItem, className)}>
      <LinearLoader>
        <Container className={clsx(classes.productDetailsContainer)}>
          <ProductCalculationWarnings
            ref={calculationWarningsRef}
            ApiRoute={product?.ApiRoute}
            exclude={EXCLUDE_ILLUSTRATOR_WARNING_MESSAGES}
            component={Item}
            componentProps={{
              xs: 12,
              className: clsx(classes.inputContainer, {
                [classes.inputContainerWarnings]: !calculationWarningsRef?.current?.disabled,
              }),
            }}
          />

          <Item xs={12} className={classes.inputContainer}>
            <ApiRoutesSelect
              name="ApiRoute"
              onChange={(event, option) => {
                trackEvent(PRODUCT_CATEGORIES[option.value], PRODUCT_ACTIONS.PROD_SELECT_PAYBREAK);
                onIllustratorProductChange(event, option);
              }}
            />
          </Item>

          {/** dynamic product options */}
          <AdditionalSteps className={classes.inputContainer} />

          <Item xs={12} className={classes.buttonsContainer}>
            {/** "UPDATE" button */}
            <Button
              className={classes.productDetailsButtonElement}
              type="submit"
              btnStyle="bgElectric"
              form={`illustration-${illustration.id}`}
              onClick={() => trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_UPDATE_PAY_BREAK_MIDDLE)}
              fullWidth
              disabled={isPending({loading: loadingProduct})}
              data-testid="product-details-update-button"
            >
              <Trans i18nKey="illustratorTranslation:illustration breakdown.product details.button submit" />
            </Button>

            {/** "START APPLICATION" button */}
            <LoginModal>
              <Button
                hidden={!showStartApplicationButton}
                className={classes.productDetailsButtonElement}
                type="button"
                btnStyle="whitePrimary"
                onClick={() => {
                  onStartApplicationButtonClick({
                    originUrl: ROUTES.ILLUSTRATOR_ROOT_PAGE,
                  });
                }}
                fullWidth
                data-testid="product-details-start-application-button"
              >
                <Trans i18nKey="illustratorTranslation:illustration breakdown.product details.button start application" />
              </Button>
            </LoginModal>

            {/** "SAVE" button */}
            <LoginModal>
              <SlideOutModal
                isBorrowDetails={false}
                defaultValues={borrowerProfile}
                enabled={enabled}
                submitButtonLabel={t(
                  'illustratorTranslation:illustration breakdown.product details.button update and save',
                )}
              >
                <SlideOutModalSubmitButton
                  className={clsx(classes.productDetailsButtonElement)}
                  variant=""
                  fullWidth
                  btnStyle="whitePrimary"
                  data-testid="product-details-save-button"
                >
                  <Trans i18nKey="illustratorTranslation:illustration breakdown.product details.button save" />
                </SlideOutModalSubmitButton>
              </SlideOutModal>
            </LoginModal>

            {/** "PRODUCT DISCLAIMERS" button" */}
            <Button
              className={classes.productDetailsButtonElement}
              type="button"
              variant="text"
              onClick={goToProductDisclaimer}
              fullWidth
              data-testid="product-details-disclaimer-button"
            >
              <Trans i18nKey="illustratorTranslation:illustration breakdown.product details.button disclaimer" />
            </Button>
          </Item>
        </Container>
      </LinearLoader>
    </Item>
  );
}

ProductDetails.propTypes = {};

export default ProductDetails;
