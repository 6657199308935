import React, {useCallback, useEffect, useMemo} from 'react';
import {Box, Collapse, ListItem} from '@material-ui/core';
import {Link, matchPath, useLocation} from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import useStyles from './NavListItemStyles';
import {useTranslation} from 'lib/i18n';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {isFunction} from 'lodash';

export function getItemLink(item) {
  return item.link || item.href;
}

function useInRoute(props = {}) {
  const {item} = props;

  const location = useLocation();

  const hasSublist = !!item.sublist;

  const shouldSelect = useCallback(
    (item, isSublist) => {
      const match = matchPath(location.pathname, {path: getItemLink(item)});

      if (isSublist) {
        return !!match;
      }

      return match?.isExact;
    },
    [location],
  );

  const isSelectedRoute = useMemo(() => {
    if (hasSublist) return !!item.sublist.find((item) => shouldSelect(item, true));
    return shouldSelect(item);
  }, [item, shouldSelect, hasSublist]);

  return {hasSublist, isSelectedRoute};
}

function NavListItem(props) {
  const {item, drawerOpen, handleDrawerOpen, handleDrawerClose, NavList, isSublist, ...rest} = props;
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [openCollapse, setOpenCollapse] = React.useState(false);

  const hookResults = item.useHook ? item.useHook() : undefined;

  useEffect(() => {
    if (!drawerOpen) setOpenCollapse(false);
  }, [drawerOpen, location.pathname]);

  const {hasSublist, isSelectedRoute} = useInRoute({item});

  const onClick = useCallback(async () => {
    if (item.onClick) {
      if (hookResults) {
        item.onClick(hookResults);
      } else {
        await dispatch(item.onClick());
      }
    }

    if (!drawerOpen) await handleDrawerOpen();
    if (!drawerOpen && openCollapse) await handleDrawerClose();
    setOpenCollapse(!openCollapse);
  }, [hookResults, drawerOpen, openCollapse, setOpenCollapse, handleDrawerClose, handleDrawerOpen, item, dispatch]);

  const customClassName = useMemo(() => {
    return item?.classname ? classes[item.classname] : '';
  }, [classes, item?.classname]);

  const hideItem = useMemo(() => {
    if (isFunction(item.displayCondition)) {
      return !item.displayCondition(hookResults);
    }

    return false;
  }, [hookResults, item]);

  if (hideItem) return null;

  return (
    <React.Fragment key={item.label}>
      <ListItem
        className={clsx(classes.listItem, {
          [customClassName]: !isSelectedRoute,
          [classes.sublistItem]: isSublist,
          [classes.selectedListItem]: isSelectedRoute,
        })}
        button
        component={hasSublist ? Box : item.href ? 'a' : Link}
        to={hookResults?.link || item.link}
        href={item.href}
        onClick={onClick}
        target={item.target}
        data-testid={`nav-list-item-${item.label}`}
      >
        {item.icon && (
          <ListItemIcon>
            <FontAwesomeIcon icon={item.icon} className={classes.navigationItemIcon} />
          </ListItemIcon>
        )}
        <ListItemText className={classes.navigationItem} primary={t(`appHeaderTranslation:pageName.${item.label}`)} />

        {hasSublist ? (
          openCollapse ? (
            <ExpandLess className={classes.arrow} />
          ) : (
            <ExpandMore className={classes.arrow} />
          )
        ) : null}
      </ListItem>
      {hasSublist && (
        <Collapse in={openCollapse}>
          <NavList
            navigationList={item.sublist}
            drawerOpen={drawerOpen}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            isSublist
            {...rest}
          />
        </Collapse>
      )}
    </React.Fragment>
  );
}

NavListItem.propTypes = {};

export default NavListItem;
