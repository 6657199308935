import React, {useCallback, useMemo} from 'react';
import Item from 'components/common/layout/item/Item';
import Container from 'components/common/layout/container/Container';
import Button from 'components/common/button/Button';
import Form from 'components/common/form/Form';
import {useDispatch, useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower, updateSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import {useSnackbar} from 'notistack';
import useStyles from './ProductSelectionFormStyles';
import {useTranslation} from 'lib/i18n';
import yup from 'lib/validation';
import ProductSelection from 'pages/illustrator-borrower/product-selection/components/product-selection-form/components/product-selection/ProductSelection';
import clsx from 'clsx';
import LinearLoader from 'components/common/loader/LinearLoader';

const validationSchema = yup.object({
  ApiRoute: yup.string().required(),
});

function ProductSelectionForm(props) {
  const {loading, nextStep} = props;

  const {ApiRoute} = useSelector(getSelectedIllustratorBorrower);

  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const classes = useStyles();

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (data) => {
      await dispatch(updateSelectedIllustratorBorrower(data));

      return nextStep(data.ApiRoute);
    },
    [dispatch, nextStep],
  );

  const onError = useCallback(() => {
    /**
     *
     * Info !
     *
     * A product must be selected !!!
     *
     */
    return enqueueSnackbar(t('translation:justText.product selection.error select a product'), {variant: 'error'});
  }, [enqueueSnackbar, t]);

  const defaultValues = useMemo(() => ({ApiRoute}), [ApiRoute]);

  return (
    <LinearLoader loading={loading}>
      <Container
        component={Form}
        id="product-selection"
        onSubmit={onSubmit}
        onError={onError}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
      >
        <Item xs={12}>
          <ProductSelection
            radioGroupClassName={classes.radioGroupContainer}
            radioGroupOptionClassName={classes.radioGroupOptionContainer}
            className={clsx(classes.justifyContentSpaceAround)}
          />
          {/* content / stop */}
        </Item>

        <Item xs={12}>
          <Button
            buttonStyling={{lg: true}}
            type="submit"
            btnStyle="bgElectric"
            data-testid="product-selection-next-button"
          >
            {t('translation:common.next')}
          </Button>
        </Item>
      </Container>
    </LinearLoader>
  );
}

export default ProductSelectionForm;
