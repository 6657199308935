import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    black: {
      color: theme.palette.common.black,
    },
    primaryDark: {
      color: theme.palette.primary.dark,
    },
    primary: {
      color: theme.palette.primary.main,
    },
    grey: {
      color: theme.palette.grey.main,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    whiteLight: {
      color: theme.palette.white.light,
    },
  }),
  {index: 1},
);

export default useStyles;
