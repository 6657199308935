import {BORROWER_PREFIX} from 'constants/borrower';
import YupValidationSchemaGovernmentRegulation1009 from 'pages/illustrator-borrower/declarations/components/YupValidationSchemaGovernmentRegulation1009';
import YupValidationSchemaGovernmentRegulation1003 from 'pages/illustrator-borrower/declarations/components/YupValidationSchemaGovernmentRegulation1003';

/**
 *
 * This is the YUP validation schema for the `Declaration` page within the borrower flow.
 *
 * If the parent element is checked, the child elements might/should be required.
 *
 */

function YupValidationSchema(props = {}) {
  /**
   *
   * By default, we know that the borrower data is available.
   *
   * The co-borrower data might be missing.
   *
   */

  const {prefix = BORROWER_PREFIX, isAvailableCoBorrowerInfo = false} = props;

  if (prefix === 'coBorrower' && !isAvailableCoBorrowerInfo) return {};

  return {
    ...YupValidationSchemaGovernmentRegulation1003({prefix: prefix}),
    ...YupValidationSchemaGovernmentRegulation1009({prefix: prefix}),
  };
}

export default YupValidationSchema;
