import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    itemContainer: {
      width: '100%',
      margin: 'auto',
      '& .MuiFilledInput-root': {
        backgroundColor: 'unset',
      },
      '& .MuiInputLabel-root': {
        color: theme.palette.primary.dark,
        fontWeight: '600 !important',
      },
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    fullwidth: {
      width: '100%',
    },
  }),
  {index: 1},
);

export default useStyles;
