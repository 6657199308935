import React from 'react';
import Item from 'components/common/layout/item/Item';
import Title from 'components/common/typeography/title/Title';
import {Trans, useTranslation} from 'lib/i18n';
import clsx from 'clsx';
import DatePicker from 'components/common/form/date-picker/DatePicker';
import ValueOfProperty from 'components/common/inputs/ValueOfProperty';
import Button from 'components/common/button/Button';
import useStyles from './BorrowerDetailsFormStyles';
import ZipCode from 'components/common/inputs/ZipCode';
import Text from 'components/common/typeography/text/Text';
import {useIsJumboRate} from 'hooks/jumbo-rate';
import JumboRateInputsItem from 'components/common/inputs/JumboRateInputs';
import Form from 'components/common/form/Form';
import {useOwnFormContext} from 'contexts/form-context';
import Container from 'components/common/layout/container/Container';
import ShortToCloseFormInputs from 'components/products/hybrid/short-to-close/ShortToCloseFormInputs';
import {useUpdateBorrowerProfile} from 'hooks/borrower-profile';
import {isPending} from 'adapter/loading.adapter';
import FeaturesWrapper from 'components/common/features-wrapper/FeaturesWrapper';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import RenderAllAdditionalOptions from 'components/common/render-all-additional-options/render-all-additional-options';
import {useProductContext} from 'contexts/product-context';
import MortgageRates from 'components/common/inputs/MortgageRates';

/**
 *
 * The "Borrower Details Form" component.
 *
 */

function BorrowerDetailsForm(props) {
  const {id = 'illustration', eligible, hasSubmitted, setHasSubmitted} = props;

  const classes = useStyles();
  const {t} = useTranslation();
  const isJumboRate = useIsJumboRate();

  const {validationSchema} = useOwnFormContext();

  const {defaultValues, onSubmit, loading} = useUpdateBorrowerProfile({
    hasSubmitted,
    setHasSubmitted,
  });

  const mdCol = 4;

  const {productDetails: {additionalBorrowerDetailsInputs = []} = {}} = useProductContext();

  return (
    <Container
      component={Form}
      id={id}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Item xs={12}>
        <Title variant="h4">
          <Trans i18nKey="illustratorTranslation:borrower details.title" />
        </Title>
        <Title className={clsx(classes.borrowerDetailsDescription)} variant="h6">
          <Trans i18nKey="illustratorTranslation:borrower details.description" />
        </Title>
      </Item>
      <Item xs={12} md={mdCol}>
        <DatePicker
          autoFocus
          id="BorrowerProfile.DateOfBirth"
          name="BorrowerProfile.DateOfBirth"
          label={t('illustratorTranslation:borrower details.inputs.DateOfBirth')}
        />
      </Item>
      <Item xs={12} md={mdCol}>
        <ValueOfProperty label={t('illustratorTranslation:borrower details.inputs.ValueOfProperty')} allowOnChange />
      </Item>
      <Item xs={12} md={mdCol}>
        <ZipCode />
      </Item>

      <MortgageRates mdCol={mdCol} />

      <JumboRateInputsItem xs={12} md={mdCol} />

      {eligible && <ShortToCloseFormInputs hideAddressField={isJumboRate} hideNumberOfUnits={isJumboRate} md={mdCol} />}

      <JumboRateInputsItem show={false}>
        <Item xs={12}>
          <Text>
            <Trans i18nKey="inputsTranslation:NumberOfUnits.warnings.jumboRate" />
          </Text>
        </Item>
      </JumboRateInputsItem>
      <FeaturesWrapper
        Component={React.Fragment}
        ProductFeatureKey={PRODUCT_FEATURE_KEY.ILLUSTRATOR_BORROWER_DETAILS_EXTRA_INPUTS}
      >
        <RenderAllAdditionalOptions md={mdCol} options={additionalBorrowerDetailsInputs} />
      </FeaturesWrapper>
      <Item xs={12} className={classes.borrowerDetailsButtonTopContainer}>
        <Button
          type="submit"
          form={id}
          btnStyle="bgElectric"
          buttonStyling={{lg: true}}
          disabled={isPending({loading})}
          data-testid="borrower-details-submit"
        >
          <Trans i18nKey="illustratorTranslation:borrower details.button submit" />
        </Button>
      </Item>
    </Container>
  );
}

export default BorrowerDetailsForm;
