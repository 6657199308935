import {makeStyles} from '@material-ui/core/styles';
import {fontWeightMedium} from 'lib/mui-config';

const useStyles = makeStyles(
  (theme) => ({
    productCard: {
      maxWidth: theme.spacing(40),
      height: '100%',
      overflow: 'visible',
    },
    productCardCardContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    productCardActionArea: {
      padding: theme.spacing(3, 2),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
    },
    productCardImage: {
      minHeight: 85,
      backgroundSize: 'contain',
      width: '100%',
    },
    productCardDescriptionContainer: {
      textAlign: 'center',
      fontSize: `${theme.spacing(1.75)}px !important`,
      fontWeight: `${fontWeightMedium} !important`,
      lineHeight: `${theme.spacing(3)}px !important`,
    },

    productCardRecommendedChip: {
      position: 'absolute',
      top: theme.spacing(-2),
      backgroundColor: theme.palette.recommended.main,
      color: theme.palette.getContrastText(theme.palette.recommended.main),
      zIndex: 1,
    },
    productCardDescriptionRoot: {},
    productCardButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 'auto',
    },
    productCardButtonRoot: {
      margin: theme.spacing(1, 0),
    },

    productCardImageSmall: {
      minHeight: theme.spacing(5),
      marginBottom: theme.spacing(1),
    },
  }),
  {index: 1},
);

export default useStyles;
