import React from 'react';
import {Cell, Row} from 'react-data-grid';
import useStyles from './AmortizationTableAdvancedOptionsRowStyles';
import clsx from 'clsx';
import {useAdvancedOptionsError} from 'hooks/input';

function AmortizationTableAdvancedOptionsCell(props) {
  const {children, className, rowIdx, column, ...rest} = props;

  const {showError} = useAdvancedOptionsError({rowIdx, column});
  const classes = useStyles();

  return (
    <Cell
      {...rest}
      rowIdx={rowIdx}
      column={column}
      className={clsx(classes.cellContainer, className, {error: showError})}
    >
      {children}
    </Cell>
  );
}

function AmortizationTableAdvancedOptionsRow(props) {
  const classes = useStyles();

  return <Row {...props} cellRenderer={AmortizationTableAdvancedOptionsCell} className={classes.rowContainer} />;
}

AmortizationTableAdvancedOptionsRow.propTypes = {};

export default AmortizationTableAdvancedOptionsRow;
