import {useQuery} from 'react-query';
import {getProductStateDobValidationMutationKey} from 'constants/query';
import ValidationsApi from 'api/validations-api';
import {useMemo} from 'react';

export function useJumboRateValidationQuery(props = {}) {
  const {Zip = '', enabled: moduleEnabled = true, ...rest} = props;

  const enabled = useMemo(() => !!(moduleEnabled && Zip), [moduleEnabled, Zip]);

  return useQuery(
    [getProductStateDobValidationMutationKey, {Zip}],
    async () => {
      return ValidationsApi.postJumboRateValidation({Zip});
    },
    {...rest, enabled, staleTime: 0, cacheTime: 0},
  );
}
