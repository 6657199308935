import {useMutation, useQuery, useQueryClient} from 'react-query';
import CalculateServiceApi from 'api/calculate-service-api';
import {statusToLoading} from 'adapter/loading.adapter';
import {useMemo} from 'react';
import {ToNumber, ToString, ToZipCode} from 'lib/utils';
import {getBorrowerBirthDate} from 'lib/borrower';
import {getApiRouteFromCalculation} from 'lib/product';
import {deleteIllustrationsMutationKey, savedIllustrationsQueryKey} from 'constants/query';
import {useOktaAuth} from '@okta/okta-react';

export function useSavedIllustrationsQuery() {
  const {
    authState: {isAuthenticated},
    oktaAuth: {
      token: {getUserInfo},
    },
  } = useOktaAuth();
  const {
    data: savedIllustrationsData,
    status,
    isFetching,
  } = useQuery([savedIllustrationsQueryKey, getUserInfo, isAuthenticated], async () => {
    let BrokerId = undefined;

    if (isAuthenticated) {
      const user = await getUserInfo();
      BrokerId = user.sub;
    }

    return CalculateServiceApi.getSavedIllustrationsByBrokerId(BrokerId);
  });

  const savedIllustrations = useMemo(() => {
    const data = savedIllustrationsData || [];

    /**
     *
     * This is where the table column values get computed.
     *
     */

    return data.map((calculation) => ({
      id: calculation.CalculationId,
      fullName: `${ToString(calculation.FirstName)} ${ToString(calculation.LastName)}`,
      DateOfBirth: getBorrowerBirthDate(calculation.DateOfBirth),
      Address: ToString(calculation.Address),
      Email: ToString(calculation.Email),
      ValueOfProperty: calculation.ValueOfProperty,
      ApiRoute: getApiRouteFromCalculation(calculation),
      dateCreated: calculation.SysCreateDate,
      MortgageBalance: calculation.Liens,
      Zip: ToZipCode(calculation.Zip),
      Age: ToNumber(calculation.Age),
    }));
  }, [savedIllustrationsData]);
  const loading = statusToLoading(status, isFetching);

  return {data: savedIllustrations, loading};
}

export function useSavedIllustrationsDeleteMutation() {
  const queryClient = useQueryClient();

  const {mutate, status} = useMutation({
    mutationKey: [deleteIllustrationsMutationKey],
    mutationFn(calculationIds) {
      return CalculateServiceApi.deleteSavedCalculations(calculationIds);
    },
    onSuccess() {
      return queryClient.invalidateQueries(savedIllustrationsQueryKey);
    },
  });

  const loading = statusToLoading(status);

  return {mutate, loading};
}
