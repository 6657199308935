import {useDateOfBirthValidation} from 'hooks/validator';
import {useMemo} from 'react';
import {BorrowerValidation} from 'lib/validation/validation';
import {useSelector} from 'react-redux';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';

export function useCalculatorValidationSchema() {
  const illustration = useSelector(getSelectedIllustration);

  const DateOfBirthValidation = useDateOfBirthValidation({
    Zip: illustration?.BorrowerProfile?.Zip,
    ApiRoute: illustration.ApiRoute,
  });

  const validationSchema = BorrowerValidation({DateOfBirthValidation});

  return {validationSchema};
}
