import React, {useCallback, useMemo} from 'react';
import {TabContext} from '@material-ui/lab';
import {useDispatch, useSelector} from 'react-redux';
import {getSelectedIllustrationId, setSelectedIllustrationId} from 'reducers/illustrator.reducer';
import {useBorrowerIllustrationLabel} from 'hooks/borrower';
import IllustrationTab from './components/illustration-tab/IllustrationTab';
import {useTranslation} from 'lib/i18n';
import IllustratorBreakdown from '../illustrator-brakedown/IllustratorBreakdown';
import useStyles from './IllustratorTabsStyles';
import Tabs from 'components/common/Tabs/Tabs';
import IllustrationTabDropDown from './components/illustration-tab/IllustrationTabDropdown';
import {NEW_ILLUSTRATION} from 'constants/illustrator';
import IllustrationContextProvider from 'contexts/illustration-context';
import LoginModal from 'components/app-header/components/login-modal/LoginModal';

IllustratorTabs.propTypes = {};

function IllustratorTabs(props) {
  const {t} = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedIllustrationId = useSelector(getSelectedIllustrationId);

  const onTabChange = useCallback(
    (event, id) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      dispatch(setSelectedIllustrationId(id));
    },
    [dispatch],
  );

  const {getBorrowerIllustrationLabel, availableIllustrations} = useBorrowerIllustrationLabel();

  const tabValue = useMemo(
    () => (availableIllustrations.length ? selectedIllustrationId : NEW_ILLUSTRATION),
    [availableIllustrations.length, selectedIllustrationId],
  );

  return (
    <TabContext value={tabValue}>
      <Tabs
        value={tabValue}
        onChange={onTabChange}
        indicatorColor="primary"
        TabIndicatorProps={{showIndicator: false}}
        classes={{flexContainer: classes.illustratorTabPanelFlexContainer}}
      >
        {/* list the illustrations / start */}
        {availableIllustrations.map((illustration) => {
          const label = getBorrowerIllustrationLabel(illustration);

          return (
            <IllustrationTab
              rounded
              key={illustration.id}
              value={illustration.id}
              data-id={illustration.id}
              title={label}
              label={label}
              className={classes.tabElement}
            />
          );
        })}
        {/* list the illustrations / stop */}

        {/* "New Illustration" dropdown / start */}
        <LoginModal>
          <IllustrationTabDropDown
            rounded
            value={NEW_ILLUSTRATION}
            title={t('illustratorTranslation:illustration.new button')}
            label={t('illustratorTranslation:illustration.new button with plus')}
            className={classes.tabElement}
            data-testid="illustrator-new-illustration-button"
            setHasSubmitted={props.setHasSubmitted}
          />
        </LoginModal>
        {/* "New Illustration" dropdown / stop */}
      </Tabs>

      {availableIllustrations.map((illustration) => (
        <IllustrationContextProvider key={illustration.id} defaultState={illustration}>
          <IllustratorBreakdown setHasSubmitted={props.setHasSubmitted} />
        </IllustrationContextProvider>
      ))}
    </TabContext>
  );
}

export default React.memo(IllustratorTabs);
