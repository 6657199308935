import React, {useCallback, useMemo} from 'react';
import allStates from 'constants/all-states.json';
import {Geography} from 'react-simple-maps';
import {useTheme} from '@material-ui/core';
import {useProductContext} from 'contexts/product-context';

function MapChartGeography(props) {
  const theme = useTheme();

  const {geographies, setTooltipContent} = props;
  const {product} = useProductContext();

  const productAvailableStates = useMemo(
    () =>
      product.ProductAvailableStates?.map((productAvailableState) => {
        const geo = geographies.find(
          (geo) => allStates.find((s) => s.val === geo.id)?.id === productAvailableState.State,
        );

        return {...productAvailableState, geo};
      }),
    [geographies, product.ProductAvailableStates],
  );

  const setTooltip = useCallback((content) => setTooltipContent(content), [setTooltipContent]);

  return geographies.map((geo) => {
    const productAvailableState = productAvailableStates?.find(
      (productAvailableState) => productAvailableState?.geo?.id === geo.id,
    );

    return (
      <Geography
        key={geo.rsmKey}
        geography={geo}
        onMouseEnter={() => setTooltip(geo?.properties?.name)}
        onMouseLeave={() => setTooltip()}
        style={{
          default: {
            fill: productAvailableState ? theme.palette.primary.dark : theme.palette.primary.main,
          },
          hover: {
            fill: theme.palette.secondary.main,
          },
        }}
      />
    );
  });
}

MapChartGeography.propTypes = {};

export default MapChartGeography;
