import React, {useMemo} from 'react';
import AutoSubmit from 'components/common/form/AutoSubmit';
import {useProductContext} from 'contexts/product-context';
import {isIdle, isLoaded} from 'adapter/loading.adapter';
import {useSelector} from 'react-redux';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';

HybridAutoSubmit.propTypes = {};

function HybridAutoSubmit(props) {
  const {enabled, validationSchema, loading: loadingProduct} = props;
  const illustration = useSelector(getSelectedIllustration);

  const {product: {calculation: {CalculationId} = {}} = {}, loading} = useProductContext();

  const shouldAutoSubmit = useMemo(() => {
    if (!enabled) return false;

    if (isLoaded({loading})) {
      if (!CalculationId && isIdle({loading: loadingProduct})) {
        try {
          validationSchema.validateSync(illustration);
          return true;
        } catch (e) {}
      }
    }

    return false;
  }, [enabled, loading, loadingProduct, CalculationId, validationSchema, illustration]);

  return <AutoSubmit condition={shouldAutoSubmit} />;
}

export default HybridAutoSubmit;
