import React, {useCallback} from 'react';
import useStyles from 'components/common/custom-formatter/CustomFormatterStyles';
import {Tooltip} from '@material-ui/core';
import TooltipIcon from 'components/common/tooltip/icon/Icon';
import {Trans} from 'lib/i18n';
import {useAdvancedOptionsError} from 'hooks/input';
import clsx from 'clsx';

function CustomFormatter(props) {
  const {column, row, rowIdx, isCellSelected, autoFill} = props;
  const classes = useStyles();

  const onAutoFill = useCallback(() => {
    if (autoFill) autoFill(column.key, row[column.key], rowIdx);
  }, [autoFill, row, column, rowIdx]);

  const {showError, errorMessage} = useAdvancedOptionsError({rowIdx, column});

  return (
    <div className={clsx(classes.customFormatterContainer)}>
      {showError && (
        <Tooltip title={errorMessage}>
          <TooltipIcon className={clsx(classes.icon, classes.errorIcon)} icon={['fas', 'exclamation-triangle']} />
        </Tooltip>
      )}

      {column?.editable && isCellSelected ? (
        <Tooltip
          title={
            <Trans
              i18nKey="illustratorTranslation:illustration.advanced options.formatter.tooltip"
              values={{name: column.name, value: props.children}}
            />
          }
        >
          <TooltipIcon
            className={clsx(classes.icon, classes.customFormatterIcon)}
            icon={['fas', 'angle-down']}
            onClick={onAutoFill}
          />
        </Tooltip>
      ) : null}

      {props.children}
    </div>
  );
}

CustomFormatter.propTypes = {};

export default CustomFormatter;
