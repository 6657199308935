import {useMediaQuery} from '@material-ui/core';

export function isMobileAppQuery(theme) {
  return theme.breakpoints.down('xs');
}

export function isMobileAppQueryUp(theme) {
  return theme.breakpoints.up('xs');
}

export function isSmSizeAppQuery(theme) {
  return theme.breakpoints.down('sm');
}

export function isSmSizeAppQueryUp(theme) {
  return theme.breakpoints.up('sm');
}

export function isTabletAppQuery(theme) {
  return theme.breakpoints.down('md');
}

export function isTabletAppQueryUp(theme) {
  return theme.breakpoints.up('md');
}

export function isLgSizeAppQuery(theme) {
  return theme.breakpoints.down('lg');
}

export function isLgSizeAppQueryUp(theme) {
  return theme.breakpoints.up('lg');
}

export function isXlSizeAppQuery(theme) {
  return theme.breakpoints.down('xl');
}

export function isXlSizeAppQueryUp(theme) {
  return theme.breakpoints.up('xl');
}

export function isMdToLgQuery(theme) {
  return theme.breakpoints.between('md', 1655);
}

export function useMobileApp() {
  return useMediaQuery(isMobileAppQuery);
}

export function useSmSizeApp() {
  return useMediaQuery(isSmSizeAppQuery);
}

export function useTabletApp() {
  return useMediaQuery(isTabletAppQuery);
}

export function useLgSizeApp() {
  return useMediaQuery(isLgSizeAppQuery);
}

export function useXlSizeApp() {
  return useMediaQuery(isXlSizeAppQuery);
}

export function useAppBreakpoints() {
  const isMobileApp = useMobileApp();
  const isSmSizeApp = useSmSizeApp();
  const isTabletApp = useTabletApp();
  const isLgSizeApp = useLgSizeApp();
  const isXlSizeApp = useXlSizeApp();

  return {isMobileApp, isSmSizeApp, isTabletApp, isLgSizeApp, isXlSizeApp};
}
