import React from 'react';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';

/**
 *
 * page sections
 *
 */
// section #1 : ethnicity
import DemographicInfoEthnicityFormSection from './DemographicInfoEthnicityFormSection';
// section #2 : sex
import DemographicInfoSexFormSection from './DemographicInfoSexFormSection';
// section #3 : race
import DemographicInfoRaceFormSection from './DemographicInfoRaceFormSection';
// section #4 : observations
import DemographicInfoObservationsFormSection from './DemographicInfoObservationsFormSection';

import {useWatchObject} from 'lib/react-hook-form/use-watch';

/**
 *
 * The "Interview and Demographic Info Components" component.
 *
 * This component is used within the "Interview and Demographic Info" page, for the borrower and co-borrower.
 *
 */

function InterviewAndDemographicInfoComponents(props) {
  /**
   *
   * The "prefix" prop is required !
   *
   * The "prefix" can be : 'borrower' or 'coBorrower'
   *
   */

  const {prefix = ''} = props;

  const {demographicInformationSource} = useWatchObject({name: 'demographicInformationSource'});

  if (!demographicInformationSource) return null;

  return (
    <Container
      id={`${prefix}InterviewAndDemographicInfoComponents`}
      data-testid="interview-and-demographic-info-components"
    >
      <Item xs={12}>
        {/* section : ethnicity / start */}
        <DemographicInfoEthnicityFormSection prefix={prefix} />
        {/* section : ethnicity / stop */}

        {/* section : sex / start */}
        <DemographicInfoSexFormSection prefix={prefix} />
        {/* section : sex / stop */}

        {/* race / start */}
        <DemographicInfoRaceFormSection prefix={prefix} />
        {/* race / stop */}

        {/* observations / start */}
        <DemographicInfoObservationsFormSection prefix={prefix} />
        {/* observations / stop */}
      </Item>
    </Container>
  );
}

InterviewAndDemographicInfoComponents.propTypes = {};

InterviewAndDemographicInfoComponents.defaultValues = {};

export default InterviewAndDemographicInfoComponents;
