/**
 *
 * This is the PHONE NUMBER regex that should be used.
 *
 * For details, please see BorrowerInfo.js or CoBorrowerInfo.js within the Link pages Wizard
 *
 */
export const PHONE_NUMBER = /^$|^[0-9]{10}$|^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;

/**
 *
 * This is the ZIP CODE regex that should be used.
 *
 * For details, please see PropertyForm.js within the Link pages Wizard
 *
 */

export const ZIP_CODE = /^$|^[0-9]+$/;

export const PROPERTY_VESTED_IN_A_TRUST = /^$|^[y][e][s]$|^[n][o]$/;
