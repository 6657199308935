import {makeStyles} from '@material-ui/core/styles';
import {isTabletAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    productDetailsItem: {
      marginTop: 0,
      paddingTop: `${theme.spacing(0.5)}px !important`,
      [isTabletAppQuery(theme)]: {
        padding: `${theme.spacing(0)} !important`,
      },
    },
    productDetailsContainer: {
      margin: 0,
      width: 'auto',
    },
    inputContainer: {
      padding: `0 !important`,
    },
    inputContainerWarnings: {
      margin: `0 !important`,
    },
    buttonsContainer: {
      padding: `0 !important`,
    },
    productDetailsButtonElement: {
      marginTop: theme.spacing(0.75),
      marginBottom: theme.spacing(0.75),
    },
  }),
  {index: 1},
);

export default useStyles;
