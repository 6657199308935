import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    amortizationTableAdvancedOptions: {
      maxWidth: theme.withPrint('calc(100vw) !important'),
      '&::-webkit-scrollbar-track': {
        boxShadow: 'unset',
      },
      display: theme.forPrint('initial !important'),
      marginTop: theme.forPrint(theme.spacing(2)),
    },
    amortizationTableDataGrid: {
      color: theme.palette.primary.dark,
      '--background-color': 'unset !important',
      '--header-background-color': 'unset !important',

      '& .rdg-row:nth-child(1n+1) .rdg-cell': {
        border: 'none',
        borderLeft: '1px solid var(--border-color)',
        borderRight: '1px solid var(--border-color)',
      },
      '& .rdg-row:nth-child(1n)': {
        backgroundColor: `${theme.palette.common.white} !important`,
      },
      '& .rdg-row:hover': {
        backgroundColor: `${theme.palette.grey.secondary} !important`,
      },
    },
  }),
  {index: 1},
);

export default useStyles;
