import React, {useMemo} from 'react';
import useStyles from './PropertyTaxesFormElementsStyles';
import {useFormContext} from 'react-hook-form';
import Item from 'components/common/layout/item/Item';
import Input from 'components/common/form/input/Input';
import usePropertyTypeOptions, {
  CONDO,
  HOA_MONTHLY_FEES,
  PROPERTY_TYPE,
  PUD_ATTACHED,
  PUD_DETACHED,
  SITE_CONDOMINIUM,
  TOWNHOUSE,
} from 'hooks/link-application/property-taxes/property-type';
import {useTranslation} from 'lib/i18n';
import {useEnabledProductFeatures} from 'hooks/product-features';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';

function PropertyTaxesFormElements() {
  const classes = useStyles();
  const {t} = useTranslation();

  const {isEnabled: isEnabledHOAMonthlyFees} = useEnabledProductFeatures(PRODUCT_FEATURE_KEY.BORROWER_HOA_MONTHLY_FEES);

  const {watch, setValue} = useFormContext();

  /**
   *
   * Annual County Tax Amount
   * Annual Hazard Insurance Amount
   * HOA Monthly Fees
   *
   */

  const propertyType = watch(PROPERTY_TYPE);

  // Determine if the `HOA Monthly FEES` field should be visible.
  const flagHOAMonthlyFees = useMemo(
    () =>
      (propertyType === PUD_ATTACHED ||
        propertyType === PUD_DETACHED ||
        propertyType === CONDO ||
        propertyType === SITE_CONDOMINIUM ||
        propertyType === TOWNHOUSE) &&
      isEnabledHOAMonthlyFees,
    [propertyType, isEnabledHOAMonthlyFees],
  );

  return (
    <>
      {/* row #1 / start */}
      <Item xs={12} sm={6}>
        <Input name="annualCountyTaxAmount" type="currency" variant="filled" className={classes.customInputElement} />
      </Item>

      <Item xs={12} sm={6}>
        <Input
          name="annualHazardInsuranceAmount"
          type="currency"
          variant="filled"
          className={classes.customInputElement}
        />
      </Item>
      {/* row #1 / stop */}

      {/* row #2 / start */}
      {flagHOAMonthlyFees ? (
        <Item xs={12} sm={6}>
          <Input name={HOA_MONTHLY_FEES} type="currency" variant="filled" className={classes.customInputElement} />
        </Item>
      ) : null}
      {/* row #2 / stop */}
    </>
  );
}

PropertyTaxesFormElements.propTypes = {};

export default PropertyTaxesFormElements;
