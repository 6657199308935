import {useQueries} from 'react-query';
import {getPreviewPdfQueryKey} from 'constants/query';
import {generateSafePath} from 'lib/react-router-dom';
import {useIllustrationPDFPrint} from 'hooks/print';
import {ROUTES} from 'constants/routes';

/**
 *
 * Trigger multiple API requests using the react query "useQueries"
 *
 * Load the Illustration images for the "Preview PDF" modal step page
 *
 * Keep the "options" on the HOC
 *
 */

export function usePdfWithPreviewQuery(props = {}) {
  // props
  const {illustrations = [], ...rest} = props;
  const printPdf = useIllustrationPDFPrint();

  return useQueries(
    illustrations.map((illustration) => ({
      queryKey: [getPreviewPdfQueryKey, illustration.CalculationId],
      queryFn() {
        return new Promise((resolve, reject) => {
          const data = {CalculationId: illustration.CalculationId};
          printPdf
            .handlePDF({
              src: generateSafePath(ROUTES.ILLUSTRATOR_PDF_PAGE, {CalculationId: illustration.CalculationId}),
              illustration,
              handlePDF(html2pdf, fileName) {
                return html2pdf
                  .outputImg('datauristring')
                  .then(function (image) {
                    data.image = image;
                  })
                  .outputPdf('blob')
                  .then(function (pdf) {
                    data.pdf = pdf;
                    data.fileName = fileName;
                    resolve(data);
                  });
              },
            })
            .catch((e) => {
              reject(e);
            });
        });
      },
      ...rest,
    })),
  );
}
