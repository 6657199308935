import React from 'react';
import PaymentBreakdownComp from 'pages/illustrator/components/product-printout-page/components/payment-breakdown/PaymentBreakdown';
import useStyles from './PaymentBreakdownStyles';
import ProductDetails from '../product-details/ProductDetails';
import Container from 'components/common/layout/container/Container';

PaymentBreakdown.propTypes = {};

/**
 *
 * the "Payment Breakdown" component
 *
 */

function PaymentBreakdown(props) {
  const classes = useStyles();

  return (
    <Container className={classes.paymentBreakdownContainer}>
      {/** left side / form */}
      <ProductDetails xs={12} md={3} setHasSubmitted={props.setHasSubmitted} />
      {/** right side / charts, tables ... */}
      <PaymentBreakdownComp
        className={classes.paymentBreakdownElement}
        xs={12}
        md={9}
        classNameListElements={classes.paymentBreakdownListElement}
      />
    </Container>
  );
}

export default React.memo(PaymentBreakdown);
