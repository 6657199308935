import {useQuery} from 'react-query';
import RateServiceApi from 'api/rate-service-api';
import {useMemo} from 'react';
import {homeSafeMetricsQueryKey} from 'constants/query';
import {statusToLoading} from 'adapter/loading.adapter';
import {uniqBy} from 'lodash';

export function useHomeSafeMetricsQuery(props = {}) {
  const {enabled = true, ProductId} = props;

  const {
    data: metrics = [],
    status,
    isFetching,
  } = useQuery({
    queryKey: [homeSafeMetricsQueryKey],
    async queryFn() {
      const response = await RateServiceApi.getHomeSafeMetrics();

      response.data = uniqBy(response.data, 'HomeSafeMetricId');

      return response;
    },
    enabled,
  });

  const data = useMemo(() => metrics.filter((metric) => metric.ProductId === ProductId), [metrics, ProductId]);
  const defaultTier = useMemo(() => data.find((tier) => tier?.IsDefault), [data]);
  const loading = useMemo(() => statusToLoading(status, isFetching), [status, isFetching]);

  return {data, defaultTier, status, loading};
}
