import React from 'react';
import Text from 'components/common/typeography/text/Text';
import useStyles from './ScenarioStyles';

function Scenario(props = {}) {
  const {tab: description = ''} = props;
  const classes = useStyles();

  if (Array.isArray(description))
    return description.map((text, index) => (
      <Text key={index} className={classes.scenarioDescription}>
        {text}
      </Text>
    ));

  return <Text className={classes.scenarioDescription}>{description}</Text>;
}

Scenario.propTypes = {};

export default Scenario;
