import React from 'react';
import {Tabs as MuiTabs} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './TabsStyles';

Tabs.propTypes = {};

function Tabs(props) {
  const {className, classes, TabIndicatorProps = {}, ...rest} = props;

  const {showIndicator = true, className: tabIndicatorClassName, ...TabIndicatorPropsRest} = TabIndicatorProps;
  const ownClasses = useStyles();

  return (
    <MuiTabs
      className={clsx(ownClasses.tabsComponent, className, {[ownClasses.tabsComponentHideScroll]: !showIndicator})}
      TabIndicatorProps={{
        ...TabIndicatorPropsRest,
        className: clsx(ownClasses.tabIndicatorComponent, tabIndicatorClassName, {
          [ownClasses.tabsIndicatorPropsHideScroll]: !showIndicator,
        }),
      }}
      classes={{
        ...classes,
        flexContainer: clsx(ownClasses.flexContainer, classes?.flexContainer),
        fixed: clsx(ownClasses.fixedTab, classes?.fixed),
      }}
      {...rest}
    />
  );
}

export default Tabs;
