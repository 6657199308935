import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery, isTabletAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    hide: {
      display: 'none',
    },
    container: {
      marginBottom: theme.forPrint(theme.spacing(7)),
      paddingBottom: theme.forPrint(theme.spacing(2)),
      overflow: 'visible',
      boxSizing: 'border-box',

      [isMobileAppQuery(theme)]: {},
      [isTabletAppQuery(theme)]: {
        paddingBottom: theme.spacing(5),
      },
      '& .recharts-legend-wrapper': {
        left: '0 !important',
        bottom: `${theme.withPrint(theme.spacing(-5), theme.spacing(-3))}px !important`,
        paddingBottom: theme.forPrint(0),
        position: 'relative !important',
        width: '100% !important',
        [isTabletAppQuery(theme)]: {
          bottom: `${theme.spacing(-1)}px !important`,
        },
        [isMobileAppQuery(theme)]: {
          bottom: `${theme.spacing(-1)}px !important`,
        },
      },
      '& .recharts-wrapper': {
        margin: 'auto',
      },
      '& .recharts-legend-item': {
        marginRight: `${theme.spacing(3)}px !important`,
      },
    },
  }),
  {index: 1},
);

export default useStyles;
