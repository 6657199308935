import React, {useCallback, useContext, useMemo} from 'react';
import {optionsToFlatMap} from 'lib/checkbox';

const inputOptionsContext = {};

const InputOptionsContext = React.createContext(inputOptionsContext);

export function useInputOptionsContext(props = {}) {
  return useContext(InputOptionsContext);
}

/**
 *
 * @param props {object}
 * @param props.value {object?}
 * @param props.children
 * @return {JSX.Element}
 * @constructor
 */
export function InputOptionsContextProvider(props = {}) {
  const {children, value} = props;

  const getOptions = useCallback(
    (name, options = value.options) =>
      optionsToFlatMap(options)
        .map((option) => option.name)
        .filter((optionName) => optionName !== name),
    [value.options],
  );

  const context = useMemo(() => {
    return {...value, getOptions};
  }, [getOptions, value]);

  return <InputOptionsContext.Provider value={context}>{children}</InputOptionsContext.Provider>;
}
