import React from 'react';
import {isFunction} from 'lodash';

/**
 *
 * @param {React.ReactNode|(()=>React.ReactNode)} children
 * @param {?object} props
 * @return {React.ReactNode}
 */
export function renderChildren(children, props = {}) {
  return isFunction(children) ? children(props) : children;
}

export function renderElement(children, props) {
  return React.isValidElement(children) ? React.cloneElement(children, props) : children;
}
