import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    default: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      textDecoration: 'none',
      textTransform: 'uppercase',
      whiteSpace: 'normal',
      fontSize: theme.spacing(1.75),
      letterSpacing: '.05rem',
    },
    buttonLabel: {},
    small: {
      padding: '0.5rem 0.75rem',
      lineHeight: '0.75rem',
      border: '1px solid',
      borderRadius: '6px',
    },
    medium: {
      padding: '0.875rem 1.125rem',
      lineHeight: '1.125rem',
      border: '1px solid',
      borderRadius: '6px',
    },
    noBorder: {border: 'none'},
    bgElectric: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.white.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
        color: `${theme.palette.white.light} !important`,
      },
      '&:hover $buttonLabel': {
        color: `${theme.palette.white.light} !important`,
      },
      '&:disabled': {
        color: theme.palette.white.light,
        opacity: 0.3,
      },
    },
    bgError: {
      backgroundColor: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      color: theme.palette.white.light,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
        borderColor: theme.palette.error.dark,
      },
      '&:disabled': {
        color: theme.palette.white.light,
        opacity: 0.3,
      },
    },

    primary: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
    primaryDark: {
      color: theme.palette.primary.dark,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },

    whitePrimary: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.white.main,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.white.dark,
      },
    },

    containSecondaryMain: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.dark,
      },
    },

    neutralMain: {
      backgroundColor: theme.palette.neutral.main,
      borderColor: theme.palette.neutral.main,
      color: theme.palette.white.light,
      '&:hover': {
        backgroundColor: theme.palette.neutral.dark,
        borderColor: theme.palette.neutral.dark,
      },
      '&:disabled': {
        color: theme.palette.white.light,
        opacity: 0.3,
      },
    },

    buttonProgressContainer: {
      position: 'relative',
      display: 'inline',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    buttonStyling: {
      margin: theme.spacing(0, 'auto', 0),
      minWidth: '144px',
      width: '100%',
      '& span': {
        lineHeight: '20px !important',
      },
    },
    buttonStylingLg: {
      maxWidth: '250px',
    },
    buttonStylingMd: {
      maxWidth: '180px',
    },
    buttonStylingSm: {
      maxWidth: '144px',
    },
    buttonStylingNoMargin: {
      margin: 'unset',
    },
    hide: {
      display: 'none',
    },
  }),
  {index: 1},
);

export default useStyles;
