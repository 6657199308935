import React from 'react';
import {Tooltip} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Text from 'components/common/typeography/text/Text';
import useStyles from 'components/common/tooltip/TooltipLabelStyles';

TooltipLabel.propTypes = {};

function TooltipLabel(props = {}) {
  const {variant = 'body1', label, tooltip = ''} = props;
  const classes = useStyles();

  return (
    <Text variant={variant}>
      {React.isValidElement(label) ? label : <span>{label}</span>}
      <span className={classes.customToolTipContainer}>
        <Tooltip title={tooltip} className={classes.customToolTip}>
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
      </span>
    </Text>
  );
}

export default TooltipLabel;
