import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery, isSmSizeAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minHeight: 74,
    },
    tooBarDark: {
      backgroundColor: theme.palette.primary.dark,
      margin: 'unset !important',
    },
    toolBarWithSpace: {
      padding: `0 ${theme.spacing(10)}px !important`,
      [isSmSizeAppQuery(theme)]: {
        padding: 'initial !important',
      },
    },
    toolBar: {
      height: '100%',
      paddingLeft: 0,
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: 'auto 0',

      ...theme.mixins.toolbar,
    },
    illustrationPrintTitle: {
      display: theme.withPrint('none', 'block'),
      paddingLeft: theme.forPrint(theme.spacing(4)),
    },
    hidePrint: {
      display: theme.forPrint('none'),
      [isMobileAppQuery(theme)]: {
        display: 'none',
      },
    },
    toolBarBox: {
      height: '100%',
    },
    nonAuthListItem: {
      textAlign: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',

      '& .MuiTypography-colorPrimary': {
        color: theme.palette.primary.dark,
      },
    },

    farList: {
      '& .MuiTypography-colorWhite': {
        color: theme.palette.common.white,
        whiteSpace: 'nowrap',
      },
      '& .MuiDivider-root': {
        backgroundColor: theme.palette.common.white,
      },
    },
    divider: {
      height: '60%',
      alignSelf: 'center',
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  {index: 1},
);

export default useStyles;
