import {ToNumber} from 'lib/utils';
import {useCallback} from 'react';

export function useCalculateForwardRefiQuery(props) {
  const addAdditionalData = useCallback((data) => {
    return {};
  }, []);

  const addAdditionalResults = useCallback((data) => {
    return {
      TotalInitialRate: ToNumber(data.ForwardRefiMetric?.Rate),
      SimplifiedCashToBorrower: ToNumber(data.ForwardRefiResult?.RemainingCashToBorrower),
      HybridMetric: {PaymentTermYears: ToNumber(data.ForwardRefiMetric?.PaymentTermYears)},
      HybridResult: {NewMonthlyPayment: ToNumber(data.ForwardRefiResult?.MortgagePayment)},
      hideModelImpactButton: true,
      showLearnMore: false,
    };
  }, []);

  return {
    enabled: true,
    addAdditionalData,
    addAdditionalResults,
  };
}
