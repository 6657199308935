import React, {useCallback, useMemo} from 'react';
import InRangeSelect from 'components/common/form/select/InRangeSelect';
import {useHECMAvailableRatesQuery} from 'query/products/hecm/hecmAvailableRates';
import {formatPercent} from 'lib/currency';
import {useFormContext} from 'react-hook-form';

AnnualRatesSelect.propTypes = {};

function AnnualRatesSelect(props) {
  const {defaultValue: defValue, name, ...rest} = props;
  const {data: annualRates = [], defaultRate} = useHECMAvailableRatesQuery();

  const defaultValue = useMemo(() => defValue ?? defaultRate?.Margin, [defValue, defaultRate?.Margin]);
  const annualRatesSorted = annualRates.sort((a,b) => a.Margin > b.Margin ? -1 : 1);

  const data = useMemo(
    () =>
      annualRatesSorted.map((Rate) => ({
        value: Rate.Margin,
        label: formatPercent(Rate.Margin),
        selected: defaultValue === Rate.Margin,
      })) ?? [],
    [annualRatesSorted, defaultValue],
  );

  const {getValues, setValue} = useFormContext();
  const clearCashAtClose = useCallback(
    (event, option) => {
      if (option.value === getValues(name)) {
        return;
      }

      setValue('CashAtClose', null);
    },
    [name, getValues, setValue],
  );
  return <InRangeSelect {...rest} name={name} options={data} defaultValue={defaultValue} onChange={clearCashAtClose} />;
}

export default AnnualRatesSelect;
