import React from 'react';

import {Box} from '@material-ui/core';
import Title from 'components/common/typeography/title/Title';
import useStyles from 'pages/illustrator/components/product-printout-page/components/payment-breakdown/components/small-pie-chart-with-horizontal-legend/SmallPieChartWithHorizontalLegendStyles';
import Text from 'components/common/typeography/text/Text';
import clsx from 'clsx';
import Item from 'components/common/layout/item/Item';

function SmallPieChartWithHorizontalLegend(props) {
  const {title, titleColor, color, value, className, titleClassName, dataTestId} = props;
  const classes = useStyles();

  return (
    <Item className={clsx(classes.smallPieChartRootElementItem, className)}>
      <Box className={classes.smallPieChartBoxContainer}>
        <Box className={clsx(classes.smallPieChartTitleContainer, titleClassName)}>
          <div className={classes.smallPieChartLegendContainer}>
            <span className={classes.smallPieChart} style={{backgroundColor: color}} />
            {React.isValidElement(title) ? (
              title
            ) : (
              <Text className={classes.smallPieChartTitleElement} variant="subtitle2">
                {title}
              </Text>
            )}
          </div>
        </Box>
        {React.isValidElement(value) ? (
          value
        ) : (
          <Title
            className={classes.smallPieChartValueElement}
            variant="subtitle1"
            data-testid={dataTestId || `small-pie-chart-with-horizontal-legend-${title}`}
            style={{color: titleColor}}
          >
            {value}
          </Title>
        )}
      </Box>
    </Item>
  );
}

export default SmallPieChartWithHorizontalLegend;
