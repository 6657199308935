import React, {useMemo} from 'react';
import Collapse from 'components/common/collapse/Collapse';
import {List} from '@material-ui/core';
import useStyles from './FaqStyles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {toArray} from 'lib/utils';

/**
 *
 * The "FAQ's" component, part of the "More about PRODUCT" component.
 *
 *********************************************************
 * Is there a minimum loan amount ?                      *
 * What does the home ownership status have to be ?      *
 * When does a borrower get loan proceeds ?              *
 * What happens after the 10 year period ?               *
 *********************************************************
 *
 */

function Faq(props) {
  const {tab: {a = [], q = []} = {}} = props;
  const classes = useStyles();

  const questions = useMemo(() => toArray(q), [q]);
  const answers = useMemo(() => toArray(a), [a]);

  const list = useMemo(
    () =>
      questions.map((question, index) => ({
        q: question,
        a: answers[index],
      })),
    [answers, questions],
  );

  return (
    <div className={classes.faqContainer}>
      <List>
        {list.map((element, index) => (
          <Collapse
            title={element.q}
            description={element.a}
            key={index}
            className={classes.faqElement}
            endAnchor={({open}) => <FontAwesomeIcon icon={['fas', open ? 'angle-up' : 'angle-down']} />}
          />
        ))}
      </List>
    </div>
  );
}

Faq.propTypes = {};

export default Faq;
