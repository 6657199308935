import {useQuery} from 'react-query';
import ProductFeaturesApi from 'api/product-features/product-features';
import {productFeaturesQueryKey} from 'constants/query';

export function useProductFeaturesQuery() {
  return useQuery({
    queryKey: [productFeaturesQueryKey],
    queryFn() {
      return ProductFeaturesApi.getAll();
    },
    refetchOnReconnect: false,
  });
}
