import React, {useEffect, useMemo, useState} from 'react';
import useStyles from 'components/illustrator/modal-email-to-borrower/components/preview-pdf/components/full-screen-for-screenshot/FullScreenForScreenShotStyles';
import {Fab} from '@material-ui/core';
import {Trans, useTranslation} from 'lib/i18n';
import DialogCloseButton from 'components/common/dialog-close-button/DialogCloseButton';
import clsx from 'clsx';
import Dialog from 'components/common/dialog/Dialog';
import {useMobileApp} from 'lib/app-theme';

function FullScreenForScreenShot(props = {}) {
  // props
  const {state, setState, image} = props;

  const classes = useStyles();
  const {t} = useTranslation();

  const [dialogProps, setDialogProps] = useState({open: false});

  useEffect(() => {
    setDialogProps({open: state});
  }, [state]);

  const doClose = () => {
    setState();
  };

  const isMobileApp = useMobileApp();
  const fabExtended = useMemo(() => !isMobileApp, [isMobileApp]);

  if (!dialogProps.open) return null;

  return (
    <Dialog open={dialogProps.open} onClose={doClose} className={classes.modalFullScreenFormScreenshot} fullScreen>
      <img
        src={image}
        alt={t('illustratorTranslation:modal email to borrower.preview pdf.alt')}
        className={classes.fullWidth}
      />

      {fabExtended && (
        <Fab
          color="primary"
          variant="extended"
          type="submit"
          className={clsx(classes.closeButtonText, classes.closeButton, classes.closeButtonExtended)}
          onClick={doClose}
        >
          <DialogCloseButton className={classes.closeButtonIcon} disableRipple component="span" />

          <Trans i18nKey="illustratorTranslation:buttons.exit button" />
        </Fab>
      )}
    </Dialog>
  );
}

export default FullScreenForScreenShot;
