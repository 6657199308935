import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    noMargin: {
      margin: 'unset !important',
      width: '100%',
    },
    hide: {
      display: 'none',
    },
  }),
  {index: 1},
);

export default useStyles;
