import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    checkboxItem: {},
    checkboxContainer: {
      textAlign: 'left',
    },
    checkboxItemContainer: {
      margin: theme.spacing(1, 3),
      width: `calc(100% - ${theme.spacing(3)}px)`,
    },
    labels: {
      color: theme.palette.primary.dark,
      fontSize: '1rem',
      lineHeight: 1.43,
      fontWeight: '600 !important',
      fontFamily:
        'Montserrat,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    },
    textAlignLeft: {
      textAlign: 'left',
    },
    errorMessage: {
      color: theme.palette.error.main,
    },
  }),
  {index: 1},
);

export default useStyles;
