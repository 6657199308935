import React from 'react';
import useStyles from './LoanPipelinePageStyles';
import {useLinkLoansQuery} from 'query/link-loans';
import Container from 'components/common/layout/container/Container';
import {Box} from '@material-ui/core';
import Button from 'components/common/button/Button';
import {useHistory} from 'react-router-dom';
import {generatePath} from 'lib/react-router-dom';
import {ROUTES} from 'constants/routes';
import {LOADING_STATUS} from 'constants/loading';

function LoanPipelinePage() {
  const {data: linkLoans, loading} = useLinkLoansQuery();
  const classes = useStyles();
  const history = useHistory();

  function handleDetailsClick(loanId) {
    return history.push(generatePath(ROUTES.LOAN_PIPELINE_DETAILS_PAGE, {loanId}));
  }

  return (
    <Container className={classes.root}>
      <Box className={classes.titleContainer}>
        {loading === LOADING_STATUS.PENDING ? (
          <div>Loading...</div>
        ) : (
          linkLoans?.map((linkLoan) => (
            <div className={classes.linkLoan}>
              <div>First Name: {linkLoan.PrimaryBorrowerFirstName}</div>
              <div>Last Name: {linkLoan.PrimaryBorrowerLastName}</div>
              <div>Loan Number: {linkLoan.LoanNumber}</div>
              <div>Loan Status: {linkLoan.LoanStatus}</div>
              <div>Loan Program: {linkLoan.LoanProgram}</div>
              <div>Loan Type: {linkLoan.LoanType}</div>
              <div>Street: {linkLoan.PropertyStreet}</div>
              <div>City: {linkLoan.PropertyCity}</div>
              <div>State: {linkLoan.PropertyStateId}</div>
              <div>Zip: {linkLoan.PropertyZip}</div>
              <br />
              <Button btnStyle="primary" onClick={() => handleDetailsClick(linkLoan.LoanNumber)}>
                Details
              </Button>
            </div>
          ))
        )}
      </Box>
    </Container>
  );
}

LoanPipelinePage.propTypes = {};

export default LoanPipelinePage;
