import React from 'react';
import {Dialog as MuiDialog, DialogActions, DialogContent} from '@material-ui/core';
import DialogHeader from './components/dialog-header/DialogHeader';
import useStyles from './DialogStyles';
import clsx from 'clsx';
import {useMobileApp} from 'lib/app-theme';

/**
 *
 * @param props {Xcelerate.DialogProps}
 * @returns {JSX.Element}
 */
function Dialog(props) {
  const {
    dialogHeaderProps,
    dialogContentProps,
    dialogActionsProps,
    children,
    paperMargin = true,
    stepWizardClass = false,
    classes: restClasses,
    ...rest
  } = props;

  const classes = useStyles();
  const isMobileApp = useMobileApp();

  return (
    <MuiDialog
      fullScreen={isMobileApp}
      {...rest}
      classes={{
        ...restClasses,
        paper: clsx(restClasses?.paper, {
          [classes.dialogPaperContainer]: paperMargin,
          [classes.dialogPaperContainerStepWizard]: stepWizardClass,
        }),
      }}
    >
      <DialogHeader {...dialogHeaderProps} />

      <DialogContent
        {...dialogContentProps}
        className={clsx(classes.dialogChildrenContainer, dialogContentProps?.className)}
      >
        {children}
      </DialogContent>

      <DialogActions
        {...dialogActionsProps}
        className={clsx(classes.dialogActionsContainer, dialogActionsProps?.className)}
      />
    </MuiDialog>
  );
}

export default Dialog;
