import React, {useCallback} from 'react';
import {FormControlLabel, FormGroup, Switch as MuiSwitch} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './SwitchElementStyles';
import {useAutoSubmit} from 'hooks/auto-submit';
import {theme} from 'lib/mui-config';
import {FormControl, FormHelperText} from '@mui/material';
import {ToString} from 'lib/utils';

const SwitchElement = React.forwardRef(function SwitchElementRender(props, ref) {
  const {
    label,
    onChange,
    onBlur,
    value,
    name,
    hidden,
    autoSubmit,
    autoSubmitBlur,
    onOffFlag = false,
    color = theme.palette.primary.main,
    showError,
    errorMessage,
    disabled
  } = props;

  const classes = useStyles();

  const handleChange = useCallback(
    (event) => {
      onChange(event.target.checked);
    },
    [onChange],
  );

  const {onChange: onAutoSubmitHandle, onBlur: handleBlur} = useAutoSubmit({
    autoSubmitBlur,
    autoSubmit,
    onChange: handleChange,
    value,
    onBlur,
  });

  return (
    <FormControl data-testid={`switch-form-control-${name}`}>
      <FormGroup className={clsx({[classes.hide]: hidden})}>
        <FormControlLabel
          color={color}
          error={ToString(showError)}
          control={
            <MuiSwitch
              inputProps={{'data-testid': `switch-input-${name}`}}
              classes={
                onOffFlag
                  ? {
                      root: classes.root,
                      switchBase: clsx(classes.switchBase, {[classes.switchBaseNull]: value === null}),
                      thumb: classes.thumb,
                      track: clsx(classes.track, {[classes.trackActive]: !!value, [classes.trackNull]: value === null}),
                      checked: classes.checked,
                    }
                  : undefined
              }
              ref={ref}
              onBlur={handleBlur}
              onChange={onAutoSubmitHandle}
              checked={!!value}
              name={name}
              color="primary"
              disabled={disabled}
            />
          }
          label={<span data-testid={`switch-label-${name}`}>{label}</span>}
          className={clsx({
            [classes.textLeft]: true,
            [classes.marginLeftNone]: onOffFlag,
            [classes.marginRightNone]: onOffFlag,
            [classes.formControlLabelElement]: onOffFlag,
          })}
        />
      </FormGroup>
      {showError && (
        <FormHelperText className={classes.formHelperText} error>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
});

SwitchElement.propTypes = {};

export default SwitchElement;
