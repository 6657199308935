import {useCallback} from 'react';
import {useProductsContext} from 'contexts/products-context';

export function useIsEnabledProduct() {
  const {productQuery = {}} = useProductsContext();

  const isEnabled = useCallback(
    (product) => {
      const {[product?.ApiRoute]: {enabled} = {}} = productQuery;

      return enabled;
    },
    [productQuery],
  );

  return {isEnabled};
}
