import TableRow from '@material-ui/core/TableRow';
import {TableCell, Typography} from '@material-ui/core';
import clsx from 'clsx';
import Switch3States from 'components/common/form/switch-3-states/Switch3States';
import {BORROWER_PREFIX} from 'constants/borrower';
import {CO_BORROWER_PREFIX} from 'constants/co-borrower';
import Switch3StatesElementErrors from 'components/common/form/switch-3-states/components/Switch3StatesElementErrors';
import React, {useMemo} from 'react';
import useQuestionsOptions from 'hooks/link-application/declarations/questions';
import useStyles from './DeclarationsQuestionsStyles';
import {useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import {useTranslation} from 'lib/i18n';
import QuestionExtraSubElements from 'pages/illustrator-borrower/declarations/components/question-extra-sub-elements/QuestionExtraSubElements';
import useGovernmentRegulationForProduct from 'hooks/government-regulation/governmentRegulationForProduct';

/**
 *
 * The Declarations page within the Borrower Flow.
 *
 * On this page, we must take control over the labels of each element.
 *
 */

function DeclarationsQuestions(props) {
  const classes = useStyles();
  const {t} = useTranslation();

  const selectedIllustratorBorrower = useSelector(getSelectedIllustratorBorrower);

  const isAvailableCoBorrowerInfo = useMemo(() => {
    return !!selectedIllustratorBorrower?.coBorrowerInfo?.coBorrowerFirstName;
  }, [selectedIllustratorBorrower]);

  // !!! the QUESTIONS list !!!
  const questions = useQuestionsOptions();

  const regulation = useGovernmentRegulationForProduct();

  if (questions.length === 0) return null;

  return (
    <>
      {questions.map((question, index) => (
        <TableRow key={index}>
          {/* 1st column */}
          <TableCell align="center" className={clsx(classes.verticalAlignTop, classes.customSwitchElementContainer)}>
            {/* borrower switch */}
            <Switch3States name={BORROWER_PREFIX + question.value} />
          </TableCell>

          {/* 2nd column */}
          {isAvailableCoBorrowerInfo ? (
            <TableCell align="center" className={clsx(classes.verticalAlignTop, classes.customSwitchElementContainer)}>
              {/* co-borrower switch */}
              <Switch3States name={CO_BORROWER_PREFIX + question.value} />
            </TableCell>
          ) : null}

          {/* 3rd column */}
          <TableCell align="left">
            {/* question */}
            <div>
              <Typography variant="subtitle2">
                {t(
                  'inputsTranslation:' +
                    (question.value[0].toLowerCase() + question.value.substring(1)) +
                    regulation +
                    '.label',
                )}
              </Typography>

              <Switch3StatesElementErrors name={BORROWER_PREFIX + question.value} />

              {isAvailableCoBorrowerInfo ? (
                <Switch3StatesElementErrors name={CO_BORROWER_PREFIX + question.value} />
              ) : null}
            </div>

            {/** extra sub elements / start */}

            {question?.options?.length ? <QuestionExtraSubElements question={question} /> : null}

            {/** extra sub elements / stop */}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}

DeclarationsQuestions.propTypes = {};

DeclarationsQuestions.defaultValues = {};

export default DeclarationsQuestions;
