import {isEqual, isFunction} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useInViewport} from 'react-in-viewport';
import {usePrintContext} from 'contexts/print-context';
import {useDebounce, useDeepCompareEffect} from 'react-use';
import {useAppBreakpoints, useMobileApp} from 'lib/app-theme';

/**
 * Memoize a result using deep equality. This hook has two advantages over
 * React.useMemo: it uses deep equality to compare memo keys, and it guarantees
 * that the memo function will only be called if the keys are unequal.
 * React.useMemo cannot be relied on to do this, since it is only a performance
 * optimization (see https://reactjs.org/docs/hooks-reference.html#usememo).
 */
export function useDeepMemo(memoFn, key) {
  const ref = useRef();

  if (!ref.current || !isEqual(key, ref.current.key)) {
    ref.current = {key, value: memoFn()};
  }

  return ref.current.value;
}

export function useMemoCompare(next, compare) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = isFunction(compare) ? compare(previous, next) : true;
  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });
  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
}

export function useVisible(options = {}) {
  const [isVisible, setIsVisible] = useState(false);
  const {printing} = usePrintContext();

  const {
    onEnterViewport: enterViewport,
    onLeaveViewport: leaveViewport,
    onVisible,
    disconnectOnLeave = false,
    ...rest
  } = options;
  const visibleNodeRef = useRef(null);

  const onEnterViewport = useCallback(() => {
    setIsVisible(true);
    if (enterViewport) enterViewport();
  }, [enterViewport, setIsVisible]);

  const onLeaveViewport = useCallback(() => {
    setIsVisible(false);
    if (leaveViewport) leaveViewport();
  }, [leaveViewport, setIsVisible]);

  useEffect(() => {
    if (isVisible && onVisible) onVisible();
  }, [isVisible, onVisible]);

  useDebounce(
    () => {
      if (printing) onEnterViewport();
    },
    200,
    [printing, onEnterViewport],
  );

  const {inViewport, enterCount, leaveCount} = useInViewport(
    visibleNodeRef,
    {...rest},
    {disconnectOnLeave},
    printing
      ? undefined
      : {
          onEnterViewport,
          onLeaveViewport,
        },
  );

  const isNodeVisible = useMemo(() => inViewport, [inViewport]);

  return {visibleNodeRef, isNodeVisible, enterCount, leaveCount};
}

export function usePrevious(value) {
  const ref = useRef();

  useDeepCompareEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default function useLongPress(props = {}) {
  const {callback = () => undefined, timeout = 300} = props;
  const [startLongPress, setStartLongPress] = useState(false);
  const eventRef = useRef(null);
  const timerRef = useRef(0);

  useEffect(() => {
    if (!timerRef.current && startLongPress) {
      timerRef.current = setTimeout(async () => {
        await callback(eventRef.current, startLongPress);
        eventRef.current = null;
      }, timeout);
    }

    return () => {
      clearTimeout(timerRef.current);
      timerRef.current = 0;
    };
  }, [callback, eventRef, timeout, startLongPress, timerRef]);

  const persistEvent = useCallback((event) => {
    event.persist();

    eventRef.current = {...event, currentTarget: event.currentTarget};
  }, []);

  const start = useCallback(
    (event) => {
      persistEvent(event);

      setStartLongPress(true);
    },
    [persistEvent],
  );
  const stop = useCallback(
    (event) => {
      persistEvent(event);

      setStartLongPress(false);
    },
    [persistEvent],
  );

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  };
}

export function useHookWithRefCallback(initialValue = null) {
  const ref = useRef(initialValue);

  const setRef = useCallback((node) => {
    if (ref.current) {
      ref.current = undefined;
    }

    ref.current = node;
  }, []);

  return {ref, setRef};
}

export function useIntervalHook(props) {
  const {interval, intervalXl, ...rest} = props;

  const {isTabletApp, isXlSizeApp} = useAppBreakpoints();
  const {printing} = usePrintContext();

  const chartInterval = useMemo(() => {
    if (printing) return undefined;
    if (isTabletApp) return undefined;
    if (isXlSizeApp && intervalXl) return intervalXl;

    return interval;
  }, [interval, isTabletApp, isXlSizeApp, intervalXl, printing]);

  return {chartInterval, isTabletApp, ...rest};
}

export function usePrintingValue(normal, print) {
  const {printing} = usePrintContext();

  return printing ? print : normal;
}

export function useChartAspect(defaultRatio = 2, printingRatio = defaultRatio) {
  const isMobileApp = useMobileApp();

  const {printing} = usePrintContext();
  const chartAspect = useMemo(() => {
    if (printing) return printingRatio;
    else if (isMobileApp) return defaultRatio / 2;

    return defaultRatio;
  }, [isMobileApp, printing, defaultRatio, printingRatio]);

  return {chartAspect};
}

export function useRotateYearChartAxis(props) {
  const {rotate: isRotated} = props;

  const rotateX = useMemo(() => (isRotated ? -20 : 0), [isRotated]);
  const rotateY = useMemo(() => (isRotated ? 30 : 0), [isRotated]);
  const rotateAngle = useMemo(() => (isRotated ? -45 : 0), [isRotated]);

  return {
    rotateX,
    rotateY,
    rotateAngle,
  };
}
