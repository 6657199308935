import React, {useCallback} from 'react';
import {useTranslation} from 'lib/i18n';
import {useLocation} from 'react-router-dom';
import IconButton from 'components/common/button/IconButton';
import {Typography} from '@material-ui/core';
import {ROUTES} from 'constants/routes';
import {oktaAuth} from 'services/auth.service';

const appUri = window.env.REACT_APP_CLIENT_HOST;

export function useLogin() {
  const location = useLocation();

  const doLogin = useCallback(() => {
    if (location.pathname === ROUTES.AUTH_ERROR_PAGE) {
      oktaAuth.setOriginalUri(appUri);
    } else {
      oktaAuth.setOriginalUri();
    }

    return oktaAuth.signInWithRedirect({originalUri: location.pathname});
  }, [location]);

  return {doLogin};
}

function LoginButton(props) {
  const {t} = useTranslation();

  const {doLogin} = useLogin();

  return (
    <IconButton colorVariant="white" icon={['far', 'user']} data-testid="login-button" onClick={doLogin}>
      <Typography variant="subtitle2">{t('appHeaderTranslation:login')}</Typography>
    </IconButton>
  );
}

export default LoginButton;
