import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    demographicInfoErrorItem: {
      textAlign: 'left',
      color: theme.palette.error.main,
      display: 'none',
    },
    demographicInfoErrorItemVisible: {
      display: 'block',
    },
  }),
  {index: 1},
);

export default useStyles;
