import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    roundedButton: {
      borderRadius: `${theme.spacing(10)}px !important`,
    },
    exitButton: {
      marginRight: theme.spacing(2),
    },
    progressBarContainer: {
      display: 'flex',
    },
    fullWidth: {
      width: '100%',
      display: 'flex',
      margin: theme.spacing(0, 'auto'),
    },
    illustratorHeaderButtonContainer: {
      textAlign: 'center',
      justifyContent: 'space-between',
    },
    hideDisplay: {
      display: 'none',
    },
    hide: {
      visibility: 'hidden',
      width: 0,
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0,
      marginRight: 0,
      minWidth: 0,
    },
  }),
  {index: 1},
);

export default useStyles;
