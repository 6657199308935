import React, {useCallback, useImperativeHandle, useMemo} from 'react';
import {Alert, AlertTitle} from '@material-ui/lab';
import {groupBy, keys} from 'lodash';
import useStyles from 'components/illustrator/calculation-warnings/CalculationWarningsStyles';
import {LOADING_STATUS} from 'constants/loading';
import {useQueryProducts} from 'hooks/query-products';
import {renderChildren} from 'lib/react';
import {productHasErrors, productHasWarnings} from 'lib/product';

const CalculationWarnings = React.forwardRef(function CalculationWarningsRender(props, ref) {
  const {
    products: productsData = [],
    exclude,
    ApiRoute,
    children,
    loading,
    component: Component = React.Fragment,
    componentProps = {},
  } = props;

  const classes = useStyles();

  const productsWithWarnings = productsData.filter(productHasWarnings);
  const productsWithErrors = productsData.filter(productHasErrors);

  const ApiRouteFilter = useCallback((product) => (ApiRoute ? product.ApiRoute === ApiRoute : true), [ApiRoute]);

  const calculationWarningsKeys = useMemo(() => {
    const calculationWarnings = groupBy(productsWithWarnings.filter(ApiRouteFilter), (product) =>
      product?.calculation?.CalculationWarnings?.map((calculationWarning) => calculationWarning.WarningMessage),
    );

    return keys(calculationWarnings);
  }, [productsWithWarnings, ApiRouteFilter]);

  const calculationErrorsKeys = useMemo(() => {
    const calculationErrors = groupBy(productsWithErrors.filter(ApiRouteFilter), (product) =>
      product?.calculation?.Errors?.map((calculationError) => calculationError.ErrorMessage),
    );

    return keys(calculationErrors);
  }, [productsWithErrors, ApiRouteFilter]);

  const warningsMessages = useMemo(() => {
    let results = [...calculationWarningsKeys, ...calculationErrorsKeys];
    if (exclude) results = results.filter((warningsMessage) => exclude.indexOf(warningsMessage) === -1);
    return results;
  }, [calculationWarningsKeys, exclude, calculationErrorsKeys]);

  useImperativeHandle(ref, () => ({disabled: warningsMessages.length > 0}), [warningsMessages.length]);

  if (loading !== LOADING_STATUS.LOADED && loading !== LOADING_STATUS.NOT_LOADED) return null;

  return (
    <Component {...componentProps}>
      {warningsMessages.map((warningsMessage) => (
        <Alert severity="warning" key={warningsMessage} className={classes.calculationWarningMessage}>
          <AlertTitle className={classes.calculationWarningMessageTitle}>{warningsMessage}</AlertTitle>
        </Alert>
      ))}
      {!warningsMessages.length ? renderChildren(children) : null}
    </Component>
  );
});

CalculationWarnings.propTypes = {};

export default CalculationWarnings;

export const ProductCalculationWarnings = React.forwardRef(function ProductCalculationWarningsRender(props, ref) {
  const {products, loading} = useQueryProducts();

  return <CalculationWarnings {...props} products={products} loading={loading} ref={ref} />;
});
