import {isFunction, noop} from 'lodash';
import {useCallback, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useDebouncedCallback} from 'use-debounce';

export function useAutoSubmit(props = {}) {
  const {
    onChange = noop,
    onBlur = noop,
    onCustomChange,
    autoSubmitBlur = false,
    autoSubmit = false,
    autoSubmitTimeout = 300,
  } = props;

  const {triggerSubmit} = useFormContext();
  const [triggerTimeout, setTriggerTimeout] = useState();

  const callback = useCallback(() => {
    if (autoSubmit) {
      triggerSubmit();
    }
  }, [autoSubmit, triggerSubmit]);

  const doSubmit = useDebouncedCallback(callback, autoSubmitTimeout);

  const handleChange = useCallback(
    (...args) => {
      if (triggerTimeout) {
        clearTimeout(triggerTimeout);
        setTriggerTimeout(null);
      }

      onChange(...args);
      if (isFunction(onCustomChange)) onCustomChange(...args);

      return doSubmit();
    },
    [triggerTimeout, onChange, onCustomChange, doSubmit],
  );

  const handleBlur = useCallback(
    (...args) => {
      if (triggerTimeout) {
        clearTimeout(triggerTimeout);
        setTriggerTimeout(null);
      }

      onBlur(...args);

      if (autoSubmitBlur) {
        setTriggerTimeout(setTimeout(() => triggerSubmit(), autoSubmitTimeout));
      }
    },
    [onBlur, triggerSubmit, autoSubmitBlur, triggerTimeout, setTriggerTimeout, autoSubmitTimeout],
  );

  return {onChange: handleChange, onBlur: handleBlur};
}
