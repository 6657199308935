import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    menuWrapper: {},
    menuItem: {
      justifyContent: 'center',
      minHeight: theme.spacing(7),
      color: theme.palette.primary.dark,

      '&.MuiListItem-button:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    menuIcon: {
      fontSize: '2.3rem',
      paddingBottom: '0.75rem',
      margin: '0 auto',
      display: 'block',
      color: theme.palette.primary.dark,
    },
    menuIconLabel: {
      color: theme.palette.primary.dark,
      flexDirection: 'column',
    },
    divider: {
      width: '60%',
      backgroundColor: theme.palette.primary.dark,
      margin: 'auto',
    },
  }),
  {index: 1},
);

export default useStyles;
