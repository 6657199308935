import React, {useCallback, useMemo, useRef, useState} from 'react';
import StepWizard from 'components/common/step-wizard/StepWizard';

// component pages used within the step wizard
import ShareIllustrations from './components/share-illustrations/ShareIllustrations';
import AddBorrowerNotes from './components/add-borrower-notes/AddBorrowerNotes';
import EmailIllustrations from './components/email-illustrations/Emailllustrations';
import PreviewPdf from './components/preview-pdf/PreviewPdf';
import IllustrationsSent from './components/illustrations-sent/IllustrationsSent';

import useStyles from 'components/illustrator/modal-email-to-borrower/ModalEmailToBorrowerStyles';
import {useModalClose} from 'hooks/react-hook-form';
import Dialog from 'components/common/dialog/Dialog';
import Button from 'components/common/button/Button';
import {useTranslation} from 'lib/i18n';
import clsx from 'clsx';
import Title from 'components/common/typeography/title/Title';
import { trackEvent } from '../../../services/analytics.service';
import { SCENARIO_CATEGORIES, SCENARIO_ACTIONS } from '../../../constants/analytic-events';

export function useModalEmailToBorrower(props = {}) {
  // props
  const {printType} = props;

  const [state, setState] = useState({
    title: '',
    formId: '',
    buttonNext: '',
    buttonCancel: '',
    buttonReturn: '',
    buttonNextDisabled: false,
    buttonNextProgress: false,
    closeDialogAndSendAnalyticsEvent: true,

    open: false,
    // page : share illustrations
    illustrationsToShare: [],
    notes: {},
  });

  // load all illustrations

  /**
   *
   * open the step wizard as a modal
   *
   */

  const doOpen = useCallback(() => {
    return setState((oldState) => ({
      ...oldState,
      notes: {},
      open: true,
    }));
  }, [setState]);

  /**
   *
   * close the wizard modal
   *
   */

  const doClose = useCallback(() => {
    setState((oldState) => ({
      ...oldState,
      notes: {},
      open: false,
    }));
  }, [setState]);

  return {
    state,
    setState,
    doOpen,
    doClose,
    printType,
  };
}

/**
 *
 * modal "Email to Borrower"
 *
 */

function ModalEmailToBorrower(props = {}) {
  // props
  const {state, setState, doOpen, doClose, printType} = useModalEmailToBorrower(props);

  const {children = () => null, ApiRoute} = props;
  const classes = useStyles();

  const {onClose} = useModalClose({disableBackdropClick: true, onClose: doClose});

  const wizardRef = useRef();
  const {t} = useTranslation();

  const isLastStep = useMemo(
    () =>
      wizardRef?.current?.currentStep &&
      wizardRef?.current?.totalSteps &&
      wizardRef?.current?.currentStep === wizardRef?.current?.totalSteps,
    [wizardRef?.current?.currentStep, wizardRef?.current?.totalSteps],
  );

  return (
    <>
      <Dialog
        open={state.open}
        onClose={onClose}
        aria-labelledby="modal-email-to-borrower"
        className={classes.modalEmailToBorrower}
        classes={{paper: classes.transitionOverflow}}
        dialogHeaderProps={{
          doClose: ()=> {
            if(state.closeDialogAndSendAnalyticsEvent) {
              trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_EMAIL_TO_BORROWER_CLOSE);
            }
            onClose();
          },
          withPrevious: wizardRef?.current?.currentStep > 1,
          previousStep: wizardRef?.current?.previousStep,
          children: (
            <Title variant="h4" className={clsx('strong')}>
              {t(state.title)}
            </Title>
          ),
        }}
        dialogActionsProps={{
          children: (
            <>
              {state.buttonCancel && (
                <Button
                  onClick={()=>{
                    trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_EMAIL_TO_BORROWER_CANCEL);
                    onClose();
                  }}
                  buttonStyling={{
                    sm: !isLastStep,
                    lg: isLastStep,
                    noMargin: true,
                  }}
                  btnStyle="primary"
                >
                  {t(state.buttonCancel)}
                </Button>
              )}

              {state.buttonReturn && (
                <Button
                  onClick={()=>{
                    onClose();
                  }}
                  buttonStyling={{
                    sm: !isLastStep,
                    lg: isLastStep,
                    noMargin: true,
                  }}
                  btnStyle="primary"
                >
                  {t(state.buttonReturn)}
                </Button>
              )}

              {state.buttonNext && (
                <Button
                  form={state.formId}
                  type="submit"
                  buttonStyling={{sm: true, noMargin: true}}
                  disabled={state.buttonNextDisabled}
                  showProgress={state.buttonNextProgress}
                  btnStyle="bgElectric"
                >
                  {t(state.buttonNext)}
                </Button>
              )}
            </>
          ),
        }}
      >
        <StepWizard ref={wizardRef}>
          {/* share illustrations */}
          <ShareIllustrations ApiRoute={ApiRoute} state={state} setState={setState} />

          {/* add borrower notes */}
          <AddBorrowerNotes printType={printType} state={state} setState={setState} />

          {/* preview PDF */}
          <PreviewPdf state={state} setState={setState} />

          {/* email illustrations */}
          <EmailIllustrations state={state} setState={setState} />

          {/* illustrations sent */}
          <IllustrationsSent state={state} setState={setState} />
        </StepWizard>
      </Dialog>

      {children({doOpen})}
    </>
  );
}

ModalEmailToBorrower.propTypes = {};

ModalEmailToBorrower.defaultValues = {};

export default ModalEmailToBorrower;
