import React, {useCallback} from 'react';

import HeaderSection from '../../header-section/HeaderSection';
import CurrentMortgageSection from '../../current-mortgage-section/CurrentMortgageSection';
import useStyles from './HybridWizardStyles';
import IntriguedSection from '../../intrigued-section/IntriguedSection';
import NewMortgageSection from '../../new-mortgage-section/NewMortgageSection';
import RetireFreedomSection from '../../retire-freedom-section/RetireFreedomSection';
import ConsumerCashUpFrontSection from '../../consumer-cash-up-front-section/ConsumerCashUpFrontSection';
import WowSection from '../../wow-section/WowSection';
import OkaySection from '../../okay-section/OkaySection';
import AboutEquitySection from '../../about-equity-section/AboutEquitySection';
import FooterSection from 'pages/illustrator-hybrid-page/components/footer-section/FooterSection';
import MortgageChartSection from 'pages/illustrator-hybrid-page/components/mortgage-chart/MortgageChartSection';
import ScatterChartSection from 'pages/illustrator-hybrid-page/components/scatter-chart/ScatterChartSection';
import PieChartSection from 'pages/illustrator-hybrid-page/components/pie-chart/PieChartSection';
import LineChartSection from 'pages/illustrator-hybrid-page/components/line-chart/LineChartSection';
import AreaChartSection from 'pages/illustrator-hybrid-page/components/area-chart/AreaChartSection';
import MultipleCharts from 'pages/illustrator-hybrid-page/components/wizard/hybrid-wizard/components/MultipleCharts';
import EquityAvailForm from 'pages/illustrator-hybrid-page/components/wizard/hybrid-wizard/components/EquityAvailForm';
import {useIllustrationPDFPrint} from 'hooks/print';
import {firstChartSectionId} from 'constants/hybrid';
import {usePrintContext} from 'contexts/print-context';
import {useProductContext} from 'contexts/product-context';
import {updateSelectedIllustration} from 'reducers/illustrator.reducer';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';
import {PRINT_TYPE} from 'constants/illustrator';
import {useTabletApp} from 'lib/app-theme';

function HybridWizard(props) {
  const isTabletApp = useTabletApp();
  const {printType, pdfRoute} = props;
  const {product: {calculation: {CalculationId} = {}} = {}} = useProductContext();

  const classes = useStyles();

  const goToFirstError = useCallback((error, event) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  const scrollTo = useCallback(() => {
    window.scrollTo({top: document.getElementById(firstChartSectionId)?.offsetTop, behavior: 'smooth'});
  }, []);

  const {printing} = usePrintContext();

  const printPdf = useIllustrationPDFPrint(CalculationId, {
    type: printType,
    height: '4000',
  });

  const dispatch = useDispatch();
  const onSuccess = useCallback(
    (response, data) => {
      dispatch(updateSelectedIllustration(data));
    },
    [dispatch],
  );

  return (
    <EquityAvailForm
      pdfRoute={pdfRoute}
      childrenContainerClassName={classes.hybridWizardChildrenContainer}
      goToFirstError={goToFirstError}
      printPdf={printPdf}
      className={classes.root}
      actionButtonsClassName={clsx({
        [classes.speedDialConsumerFacing]: printType === PRINT_TYPE.CONSUMER_FACING,
      })}
      onSuccess={onSuccess}
    >
      <HeaderSection goToFirstError={goToFirstError} scrollTo={scrollTo} />

      {isTabletApp || printing ? (
        <MultipleCharts />
      ) : (
        <>
          <MortgageChartSection id={firstChartSectionId}>
            <CurrentMortgageSection />
            <NewMortgageSection />
          </MortgageChartSection>
          <ScatterChartSection>
            <IntriguedSection />
            <RetireFreedomSection />
          </ScatterChartSection>
          <PieChartSection>
            <ConsumerCashUpFrontSection />
          </PieChartSection>
          <LineChartSection>
            <WowSection />
            <OkaySection />
          </LineChartSection>
          <AreaChartSection>
            <AboutEquitySection />
          </AreaChartSection>
        </>
      )}
      <FooterSection printPdf={printPdf} pdfRoute={pdfRoute} />
    </EquityAvailForm>
  );
}

HybridWizard.propTypes = {};

export default React.memo(HybridWizard);
