import {useMutation} from 'react-query';
import LinkUserLicenseValidation from 'api/link-user-api-validation';
import {linkUserLicenseValidationMutationKey} from 'constants/query';
import {useLoadingQuery} from 'hooks/query';

/**
 *
 * !!! This file will contain only one method !!!
 *
 * The method triggers the `Link User License Validation` mutation.
 *
 */

export default function usePostLinkUserLicenseValidationMutation(props) {
  return useLoadingQuery(
    useMutation({
      mutationKey: [linkUserLicenseValidationMutationKey],
      mutationFn: (data) => {
        return LinkUserLicenseValidation.validate(data);
      },
      ...props,
    }),
  );
}
