import React, {useCallback, useMemo, useRef} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import 'react-data-grid/dist/react-data-grid.css';
import {formatCurrency} from 'lib/currency';
import useStyles from 'components/illustrator/illustrator-detailed-headers/IllustratorDetailedHeadersStyles';
import clsx from 'clsx';
import CustomFormatter from '../../common/custom-formatter/CustomFormatter';
import {useTranslation} from 'lib/i18n';
import {advancedOptionsName} from 'constants/illustrator';
import {useProductContext} from 'contexts/product-context';
import {omit} from 'lodash';
import List from 'components/common/list/List';
import Text from 'components/common/typeography/text/Text';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';

function headerRenderer({column}) {
  return <p>{column.name}</p>;
}

function getColumns({productDetails, handleAutoFill, t}) {
  let lifetimeModelOptionHeader = productDetails.lifetimeModelOptionHeader;

  return lifetimeModelOptionHeader
    .filter((col) => typeof col.condition === "boolean" ? col.condition : true)
    .map((col, index, array) => ({
      ...col,
      width: col.width,
      maxWidth: col.maxWidth,
      minWidth: col.minWidth,
      name: t(`illustratorTranslation:illustration.advanced options.${col.key}`),
      listDataName: col.listData ? t(`illustratorTranslation:illustration.advanced options.${col.listData}`) : undefined,
      headerRenderer,
      headerCellClass: !isNaN(col?.group)
        ? clsx('grouped-col', `grouped-col-${col.group}`, col.group !== array?.[index + 1]?.group ? 'last' : '')
        : undefined,
      formatter: col?.formatter
        ? (props) => (
            <CustomFormatter autoFill={handleAutoFill} {...props}>
              {col.formatter(props) ?? null}
            </CustomFormatter>
          )
        : undefined,
    }));
}

function useIllustratorDetailedHeaders(props) {
  const {forceActive = false} = props;
  const {product, productDetails, loadingProduct: loading} = useProductContext();

  const {
    control,
    setValue,
    triggerSubmit,
    formState: {dirtyFields},
  } = useFormContext();

  const {fields} = useFieldArray({control, name: advancedOptionsName});

  const showSaveButton = !!dirtyFields[advancedOptionsName];

  return {
    product,
    productDetails,
    isAdvancedOptionsActive: forceActive ? forceActive : product?.calculation?.showAdvancedOptions,
    fields,
    name: advancedOptionsName,
    setValue,
    triggerSubmit,
    loading,
    showSaveButton,
  };
}

function IllustratorDetailedHeaders(props) {
  const containerRef = useRef(null);

  const {productDetails, fields, name, triggerSubmit, setValue} = useIllustratorDetailedHeaders(props);

  const {t} = useTranslation();

  const handleChanged = useCallback(
    (fields) =>
      setValue(
        name,
        fields.map((field) => omit(field, 'id')),
        {shouldDirty: true},
      ),
    [setValue, name],
  );

  const handleAutoFill = useCallback(
    async (columnKey, value, rowIdx) => {
      await handleChanged(
        fields.map((field, index) => {
          let data = {...field};
          if (index >= rowIdx) data[columnKey] = value;

          return data;
        }),
      );

      triggerSubmit();
    },
    [fields, handleChanged, triggerSubmit],
  );

  const columns = useMemo(() => getColumns({productDetails, handleAutoFill, t}), [productDetails, handleAutoFill, t]);

  const classes = useStyles({
    collWidth: (containerRef?.current?.offsetWidth || 0) / (columns?.length || 1),
  });

  const listDataColumns = useMemo(
    () => columns.filter((d) => d.listData).filter((d) => fields.find((row) => row[d.listData])),
    [columns, fields],
  );

  return (
    <>
      <Container className={classes.startValuesListContainer}>
        <Item xs={12} md={5} lg={4} xl={3} className={classes.startValuesListItem}>
          <List flexDirection="column" listItemClassName={classes.startValuesListInfo}>
            {listDataColumns.map((d) => (
              <React.Fragment key={d.listData}>
                <Text
                  variant="body2"
                  className={classes.startValuesListItemTitle}
                  data-testid={`start-values-list-item-${d.listData}`}
                >
                  {d.listDataName}
                </Text>
                <Text variant="body2" data-testid={`start-values-list-item-${d.listData}-value`}>
                  {formatCurrency(fields?.[0]?.[d.listData])}
                </Text>
              </React.Fragment>
            ))}
          </List>
        </Item>
      </Container>
    </>
  );
}

IllustratorDetailedHeaders.propTypes = {};

export default React.memo(IllustratorDetailedHeaders);
