import {axios} from './api';

class UserInfo {
  static baseUrl = '/userinfo';

  static get = async (config) => {
    return axios.get(`${this.baseUrl}`, config);
  };
}

export default UserInfo;
