import {useEffect} from 'react';
import {defaultsDeep, noop} from 'lodash';

export function withDialogState(
  Component,
  {title, formId, buttonCancel = 'button.cancel', buttonNext = 'button.continue', buttonReturn='button.return'},
) {
  return function (props) {
    const {setState = noop} = props;

    useEffect(() => {
      setState((oldState) => ({
        ...oldState,
        title,
        formId,
        buttonCancel,
        buttonNext,
        buttonReturn,
      }));
    }, [setState]);

    return <Component {...props} />;
  };
}

export function useDialogStateMutation(props) {
  const {setState = noop, title, formId, buttonNext, buttonCancel, buttonReturn, buttonNextDisabled, buttonNextProgress} = props;

  useEffect(() => {
    setState((oldState) =>
      defaultsDeep(
        {
          title,
          formId,
          buttonNext,
          buttonCancel,
          buttonReturn,
          buttonNextDisabled,
          buttonNextProgress,
        },
        {...oldState},
      ),
    );
  }, [setState, title, formId, buttonNext, buttonCancel, buttonReturn, buttonNextDisabled, buttonNextProgress]);
}
