import React, {useCallback, useMemo, useState} from 'react';
import Form from 'components/common/form/Form';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import useStyles from './DeclarationsStyles';
import {useTranslation} from 'lib/i18n';
import Button from 'components/common/button/Button';
import {useDispatch, useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower, updateSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import {useSaveIllustratorBorrowerMutation} from 'query/borrower/save-illustrator-borrower';
import AlertDialogSlide from 'pages/illustrator-borrower/alert-dialog-slide/AlertDialogSlide';
import {TableCell} from '@material-ui/core';
import {BORROWER_PREFIX} from 'constants/borrower';
import {CO_BORROWER_PREFIX} from 'constants/co-borrower';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {useProductContext} from 'contexts/product-context';
import {useStepWizardContext} from 'contexts/step-wizard';
import {withProduct} from 'components/products/product-hoc';
import {productCalculationQueryKey} from 'constants/query';
import {withProducts} from 'components/products';
import StepElement from 'pages/illustrator-borrower/components/step-element/StepElement';
import * as yup from 'yup';
import YupValidationSchema from 'pages/illustrator-borrower/declarations/components/YupValidationSchema';
import DeclarationsQuestions from 'pages/illustrator-borrower/declarations/components/DeclarationsQuestions';

function Declarations(props) {
  const {goToStep} = useStepWizardContext();

  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

  const {product} = useProductContext();

  const saveIllustratorBorrowerMutation = useSaveIllustratorBorrowerMutation();

  const selectedIllustratorBorrower = useSelector(getSelectedIllustratorBorrower);

  const [isLoading, setIsLoading] = useState(false);

  const [statusAlertDialogSlide, setStatusAlertDialogSlide] = useState(null);
  const [errorDescriptionAlertDialogSlide, setErrorAlertDialogSlide] = useState('');
  const [loanNumber, setLoanNumber] = useState('');

  const isAvailableCoBorrowerInfo = useMemo(() => {
    return !!selectedIllustratorBorrower?.coBorrowerInfo?.coBorrowerFirstName;
  }, [selectedIllustratorBorrower]);

  /**
   *
   * The main submit method.
   *
   */

  const onSubmit = useCallback(
    async (data) => {
      setDisabledSubmitButton(true);

      setIsLoading(true);

      // save the data in redux
      await dispatch(
        updateSelectedIllustratorBorrower({
          declarations: data,
        }),
      );

      const ibData = {
        ...selectedIllustratorBorrower,
        ...{declarations: data},
        ...{LinkProductName: product.LinkProductName},
      };

      saveIllustratorBorrowerMutation.mutate(ibData, {
        onSuccess(response) {
          setDisabledSubmitButton(true);
          setIsLoading(false);
          setErrorAlertDialogSlide('');
          setLoanNumber(response?.data?.LoanNumber);
          setStatusAlertDialogSlide(true);
        },
        onError(e) {
          setDisabledSubmitButton(false);
          setIsLoading(false);
          const errorMessage = e.response?.data?.Message ? e.response.data.Message : '';
          setLoanNumber('');
          setErrorAlertDialogSlide(errorMessage);
          setStatusAlertDialogSlide(false);
        },
      });
    },
    [saveIllustratorBorrowerMutation, dispatch, selectedIllustratorBorrower, product],
  );

  const defaultValues = useMemo(() => {
    return (
      // !!! Make sure that the value exists within the store. READ THE DOCS !
      selectedIllustratorBorrower?.declarations
    );
  }, [selectedIllustratorBorrower]);

  const handleAlertDialogSlideClose = () => {
    setStatusAlertDialogSlide(null);
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        ...YupValidationSchema({prefix: BORROWER_PREFIX}),
        ...YupValidationSchema({prefix: CO_BORROWER_PREFIX, isAvailableCoBorrowerInfo: isAvailableCoBorrowerInfo}),
      }),
    [isAvailableCoBorrowerInfo],
  );

  return (
    <StepElement
      id="declarationsForm"
      data-testid="declarations"
      title={t('illustratorTranslation:illustrator borrower declarations.title')}
      subTitle={t('illustratorTranslation:illustrator borrower declarations.sub title v2')}
    >
      {/* form / start */}
      <Container component={Form} onSubmit={onSubmit} defaultValues={defaultValues} validationSchema={validationSchema}>
        {typeof statusAlertDialogSlide === 'boolean' ? (
          <AlertDialogSlide
            close={handleAlertDialogSlideClose}
            goToStep={goToStep}
            statusAlertDialogSlide={statusAlertDialogSlide}
            errorDescription={errorDescriptionAlertDialogSlide}
            loanNumber={loanNumber}
          />
        ) : null}

        <Item xs={12}>
          {/* the "Declarations" table / start */}
          <Table className={classes.table} aria-label="caption table">
            {/* the header section / start */}
            <TableHead>
              <TableRow>
                {/* the "Borrower" column */}
                <TableCell align="center">{t('translation:common.borrower')}</TableCell>

                {/* the "Co-Borrower" column */}
                {isAvailableCoBorrowerInfo ? (
                  <TableCell align="center">{t('translation:common.coborrower')}</TableCell>
                ) : null}

                {/* the "Questions & extras" column */}
                <TableCell align="left" />
              </TableRow>
            </TableHead>
            {/* the header section / stop */}

            {/* the body section / start */}
            <TableBody>
              <DeclarationsQuestions />
            </TableBody>
            {/* the body section / stop */}
          </Table>
          {/* the "Declarations" table / stop */}
        </Item>

        <Container className={classes.buttonContainer}>
          <Item xs={12}>
            <Button
              btnStyle="bgElectric"
              type="submit"
              buttonStyling={{lg: true}}
              disabled={disabledSubmitButton || isLoading}
              showProgress={isLoading}
              data-testid="declarations-submit-button"
            >
              {t('illustratorTranslation:illustrator borrower declarations.submit to link')}
            </Button>
          </Item>
        </Container>
        {/* form components / stop */}
      </Container>
      {/* form / stop */}
    </StepElement>
  );
}

Declarations.propTypes = {};

Declarations.defaultValues = {};

const ProductDeclarations = withProduct(Declarations);

function DeclarationsProduct(props) {
  const {ApiRoute} = useSelector(getSelectedIllustratorBorrower);

  const isSelectedProduct = useCallback(
    (product) => {
      return ApiRoute ? product?.ApiRoute === ApiRoute : true;
    },
    [ApiRoute],
  );

  return (
    <ProductDeclarations
      {...props}
      isSelectedProduct={isSelectedProduct}
      queryKey={[productCalculationQueryKey, ApiRoute]}
    />
  );
}

const ProductsDeclarations = withProducts(DeclarationsProduct, {
  productFeatureKey: PRODUCT_FEATURE_KEY.APPLICATION_PRODUCT_DDL,
});

export default ProductsDeclarations;
