import {makeStyles} from '@material-ui/core/styles';
import {isSmSizeAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    leftSide: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      marginLeft: theme.spacing(-5),
      [isSmSizeAppQuery(theme)]: {marginLeft: theme.spacing(-3)},
    },
    rightSide: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    content: {
      backgroundColor: theme.palette.white.light,
      display: theme.withPrint('flex', 'block'),
    },
    text2left: {
      textAlign: 'left',
    },
    listXMainElementContainer: {
      [isSmSizeAppQuery(theme)]: {padding: theme.spacing(1)},
    },
    listItemText: {
      '& span': {},
    },
  }),
  {index: 1},
);

export default useStyles;
