import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {CartesianGrid, Legend, Line, LineChart as RLineChart, Tooltip, XAxis, YAxis} from 'recharts';
import CurrencyChartAxisTick from 'components/common/recharts/CurrencyChartAxisTick';
import {useTheme} from '@material-ui/core';
import {formatCurrency} from 'lib/currency';
import YearChartAxisTick from 'components/common/recharts/YearChartAxisTick';
import {useTranslation} from 'lib/i18n';
import {pick} from 'lodash';
import {useScrollSpeed} from 'hooks/parallax';
import {usePrintContext} from 'contexts/print-context';
import {useIntervalHook} from 'hooks/utils';

export function useGeneratedData(props) {
  const {advancedOptions, keys} = props;
  const {t} = useTranslation();

  const [results, setResults] = useState([]);

  const refreshResults = useCallback(
    () =>
      setResults(
        advancedOptions?.map((advancedOption) => ({
          ...pick(advancedOption, keys),
          name: t('line chart section.chart label.name', {year: advancedOption.name}),
        })),
      ),
    [advancedOptions, setResults, keys, t],
  );

  useEffect(() => {
    refreshResults();
  }, [advancedOptions, keys, refreshResults]);

  return {
    results,
    refreshResults,
    cleanResults: useCallback(() => {
      setResults([]);
    }, [setResults]),
  };
}

function LineChart(props) {
  const {chartInterval, isTabletApp, ...rest} = useIntervalHook(props);
  const {t} = useTranslation();

  const {duration} = useScrollSpeed();
  const {animation: isAnimationActive} = usePrintContext({animation: true});

  const theme = useTheme();

  const showHomeValueYearEnd = useMemo(() => !!rest.data?.find((d) => d.homeValueYearEnd > 0), [rest.data]);
  const showLoanBalanceValue = useMemo(() => !!rest.data?.find((d) => d.loanBalanceValueYearEnd > 0), [rest.data]);

  return (
    <RLineChart {...rest}>
      <CartesianGrid />
      <XAxis dataKey="name" interval={chartInterval} tick={<YearChartAxisTick rotate={!chartInterval} />} />
      <YAxis tick={<CurrencyChartAxisTick formatShort={isTabletApp} />} tickMargin={10} />
      <Tooltip formatter={(x) => formatCurrency(x)} />

      <Legend />

      {showHomeValueYearEnd && (
        <Line
          type="monotone"
          name={t('line chart section.chart label.homeValueYearEnd')}
          dataKey="homeValueYearEnd"
          stroke={theme.palette.primary.dark}
          activeDot={{r: 8}}
          isAnimationActive={isAnimationActive}
          animationDuration={duration}
        />
      )}
      {showLoanBalanceValue && (
        <Line
          type="monotone"
          name={t('line chart section.chart label.loanBalanceValueYearEnd')}
          dataKey="loanBalanceValueYearEnd"
          stroke={theme.palette.recommended.main}
          isAnimationActive={isAnimationActive}
          animationDuration={duration}
        />
      )}
    </RLineChart>
  );
}

LineChart.propTypes = {};

export default LineChart;
