import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    profileMenu: {
      display: theme.forPrint('none'),
    },
    container: {
      backgroundColor: theme.palette.grey.dark,
      padding: 0,
    },
    listItem: {
      padding: 0,
      width: 'auto',
      '&:hover': {backgroundColor: theme.palette.primary.main},
    },
    itemElement: {
      textDecoration: 'none',

      color: theme.palette.white.light,
      textTransform: 'uppercase',
      fontSize: '1rem',
      padding: '1rem',
      fontWeight: 500,
    },
  }),
  {index: 1},
);

export default useStyles;
