import React from 'react';

import {useTranslation} from 'lib/i18n';
import InterviewerForm from 'pages/illustrator-borrower/interviewer/components/InterviewerForm';
import StepElement from 'pages/illustrator-borrower/components/step-element/StepElement';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';

/**
 *
 * The "Interviewer" page.
 *
 */

function Interviewer(props) {
  const {t} = useTranslation();

  return (
    <StepElement
      id="interview"
      data-testid="interview"
      title={t('illustratorTranslation:illustrator borrower interviewer page.title')}
    >
      <Container>
        <Item xs={0} sm={1} md={2} />
        <Item xs={12} sm={10} md={8}>
          <InterviewerForm />
        </Item>
        <Item xs={0} sm={1} md={2} />
      </Container>
    </StepElement>
  );
}

Interviewer.propTypes = {};

Interviewer.defaultValues = {};

export default Interviewer;
