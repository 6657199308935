export const advancedOptionsName = 'advancedOptions';

export const NEW_ILLUSTRATION = 'new_illustration';
export const ALL_ILLUSTRATIONS = 'all_illustrations';

export const CALCULATION_CONTAINER = 'calculation-container';

export const BORROWER_PREFERENCE = {
  MOST_PROCEEDS: 'Most Cash Out',
  LOWEST_RATE: 'Lowest Interest Rate',
  LOWEST_FEES: 'Lowest Service Fee',
  LINE_OF_CREDIT: 'Line of Credit Option',
  MOST_EQUITY: 'Most Equity Remaining',
};

export const PRINT_TYPE = {
  HYBRID: 'hybrid',
  CONSUMER_FACING: 'far-equityavail',
};

export const API_ROUTES = {
  ANNUAL: 'annual',
  FIXED: 'fixed',
  FLEX: 'flex',
  SELECT: 'select',
  TIER: 'tier',
  SECOND: 'second',
  HYBRID: 'hybrid',
  FORWARDREFI: 'forwardrefi',
  EXISTING_MORTGAGE: 'existing-mortgage',
  TENURE: 'tenure',
  DEFAULT: '',
};

export const PRODUCT_TYPE = {
  HECM: 'hecm',
  HECM_ANNUAL: 'hecm-annual',
  HECM_MONTHLY: 'hecm-monthly',
  HECM_TENURE: 'hecm-tenure',
  HECM_FIXED: 'hecm-fixed',
  HYBRID: 'hecm-hybrid',
  FORWARDREFI: 'hecm-forwardrefi',

  HOMESAFE_SECOND: 'homesafe-second',
  HOMESAFE_FIXED: 'homesafe-fixed',
  HOMESAFE_STANDARD: 'homesafe-standard',
  HOMESAFE_SELECT: 'homesafe-select',
};

export const FUND_ACCESS = {
  LUMP_SUM: 'lumpsum',
  LINE_OF_CREDIT: 'lineOfCredit',
};

export const PRODUCT_FUND_ACCESS = {
  [API_ROUTES.TIER]: [{value: FUND_ACCESS.LINE_OF_CREDIT, label: `fundAccessTranslation:${FUND_ACCESS.LINE_OF_CREDIT}`}],
  [API_ROUTES.SECOND]: [{value: FUND_ACCESS.LINE_OF_CREDIT, label: `fundAccessTranslation:${FUND_ACCESS.LINE_OF_CREDIT}`}],
  [API_ROUTES.ANNUAL]: [
    {value: FUND_ACCESS.LUMP_SUM, label: `fundAccessTranslation:${FUND_ACCESS.LUMP_SUM}`},
    {value: FUND_ACCESS.LINE_OF_CREDIT, label: `fundAccessTranslation:${FUND_ACCESS.LINE_OF_CREDIT}`},
  ],
  [API_ROUTES.FIXED]: [{value: FUND_ACCESS.LINE_OF_CREDIT, label: `fundAccessTranslation:${FUND_ACCESS.LINE_OF_CREDIT}`}],
  [API_ROUTES.SELECT]: [{value: FUND_ACCESS.LINE_OF_CREDIT, label: `fundAccessTranslation:${FUND_ACCESS.LINE_OF_CREDIT}`}],
  [API_ROUTES.HYBRID]: [{value: FUND_ACCESS.LINE_OF_CREDIT, label: `fundAccessTranslation:hybrid`}],
};

export const DEFAULT_HOME_SAFE_FLEX_YEARS_OF_PAYMENT = 7;
export const DEFAULT_HOME_SAFE_FLEX_TIER = 'tier_1';
export const DEFAULT_HOME_SAFE_STANDARD_TIER = 'tier_4';

export const EXCLUDE_ILLUSTRATOR_WARNING_MESSAGES = [
  'Some products might have T&I reserves that are not reflected here.',
];

export const ZIP_CODE_LENGTH = 5;
export const SSN_LENGTH = 9;
