import {useQuery} from 'react-query';
import {versionExposeQueryKey} from 'constants/query';
import VersionServiceApi from 'api/version-service-api';
import {useMemo} from 'react';
import {APP_ROUTES} from 'constants/routes';

const IGNORED_PATH = [APP_ROUTES.PDF];

export function useVersionExposeQuery(props) {
  const {enabled: moduleEnabled = true, ...rest} = props;

  const enabled = useMemo(
    () => moduleEnabled && !IGNORED_PATH.find((path) => new RegExp(path).test(window.location)),
    [moduleEnabled],
  );

  return useQuery({
    queryKey: [versionExposeQueryKey],
    queryFn() {
      return VersionServiceApi.get();
    },
    select: (response) => response.data.trim(),
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    enabled,
    ...rest,
  });
}
