import {useOktaAuth} from '@okta/okta-react';

export function useAuthenticated() {
  const oktaAuth = useOktaAuth();

  return {
    authState: oktaAuth?.authState,
    isAuthenticated: oktaAuth?.authState?.isAuthenticated,
    isLoaded: oktaAuth?.authState !== null,
  };
}

export function withAuth(Component) {
  return function WithAuthRender(props) {
    const auth = useAuthenticated();

    return <Component {...props} {...auth} />;
  };
}
