import * as inputsTranslation from './inputsTranslation.json';
import * as inputsBorrowerFlowDeclarations1003Translation from './inputsBorrowerFlowDeclarations1003Translation.json';
import * as inputsBorrowerFlowDeclarations1009Translation from './inputsBorrowerFlowDeclarations1009Translation.json';

const InputsTranslation = {
  inputsTranslation: {
    ...inputsTranslation,
    ...inputsBorrowerFlowDeclarations1003Translation,
    ...inputsBorrowerFlowDeclarations1009Translation,
  },
};

export default InputsTranslation;
