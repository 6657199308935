import createListAdapter from 'adapter/list.adapter';
import {createSelector, createSlice} from '@reduxjs/toolkit';
import {defaultsDeep} from 'lodash';
import {generateId} from 'lib/generator';

/**
 *
 * the "Borrower Store" structure & data
 *
 * "Link" requires that the data to be sent in a specific way.
 *
 * The "store" data params naming have nothing to do with the "link" data params naming.
 *
 * Make sure to map the redux store param names to the required "Link" request param names.
 *
 */

export const defaultCoBorrowerInfo = {
  coBorrowerMiddleName: '',
  coBorrowerFirstName: '',
  coBorrowerLastName: '',
  coBorrowerEmail: '',
  coBorrowerDOB: '',
  coBorrowerSSN: '',
  coBorrowerHomePhoneNumber: '',
  coBorrowerMaritalStatus: '',
};

export const defaultDemographicInfoBorrowerForNewInterviewMethod = {
  // ethnicity
  borrowerEthnicityHispanicLatino: false,
  borrowerEthnicityMexican: false,
  borrowerEthnicityPuertoRican: false,
  borrowerEthnicityCuban: false,
  borrowerEthnicityOtherHispanicLatino: false,
  borrowerEthnicityOtherHispanicLatinoText: '',
  borrowerEthnicityNotHispanicLatino: false,
  borrowerEthnicityWishNotToProvide: false,
  // sex
  borrowerSexFemale: false,
  borrowerSexMale: false,
  borrowerSexWishNotToProvide: false,
  // race
  borrowerRaceAmericanIndianAlaskaNative: false,
  borrowerRaceAmericanIndianAlaskaNativeText: '',
  borrowerRaceAsian: false,
  borrowerRaceAsianIndian: false,
  borrowerRaceChinese: false,
  borrowerRaceFilipino: false,
  borrowerRaceJapanese: false,
  borrowerRaceKorean: false,
  borrowerRaceVietnamese: false,
  borrowerRaceOtherAsian: false,
  borrowerRaceOtherAsianText: '',
  borrowerRaceBlackAfricanAmerican: false,
  borrowerRaceHawaiianPacificIslander: false,
  borrowerRaceNativeHawaiian: false,
  borrowerRaceGuamanianChamorro: false,
  borrowerRaceSamoan: false,
  borrowerRaceOtherHawaiianPacific: false,
  borrowerRaceOtherHawaiianPacificText: '',
  borrowerRaceWhite: false,
  borrowerRaceWishNotToProvide: false,
  // observations
  borrowerObservedEthnicity: null,
  borrowerObservedSex: null,
  borrowerObservedRace: null,
};

export const defaultDemographicInfoCoBorrowerForNewInterviewMethod = {
  // ethnicity
  coBorrowerEthnicityHispanicLatino: false,
  coBorrowerEthnicityMexican: false,
  coBorrowerEthnicityPuertoRican: false,
  coBorrowerEthnicityCuban: false,
  coBorrowerEthnicityOtherHispanicLatino: false,
  coBorrowerEthnicityOtherHispanicLatinoText: '',
  coBorrowerEthnicityNotHispanicLatino: false,
  coBorrowerEthnicityWishNotToProvide: false,
  // sex
  coBorrowerSexFemale: false,
  coBorrowerSexMale: false,
  coBorrowerSexWishNotToProvide: false,
  // race
  coBorrowerRaceAmericanIndianAlaskaNative: false,
  coBorrowerRaceAmericanIndianAlaskaNativeText: '',
  coBorrowerRaceAsian: false,
  coBorrowerRaceAsianIndian: false,
  coBorrowerRaceChinese: false,
  coBorrowerRaceFilipino: false,
  coBorrowerRaceJapanese: false,
  coBorrowerRaceKorean: false,
  coBorrowerRaceVietnamese: false,
  coBorrowerRaceOtherAsian: false,
  coBorrowerRaceOtherAsianText: '',
  coBorrowerRaceBlackAfricanAmerican: false,
  coBorrowerRaceHawaiianPacificIslander: false,
  coBorrowerRaceNativeHawaiian: false,
  coBorrowerRaceGuamanianChamorro: false,
  coBorrowerRaceSamoan: false,
  coBorrowerRaceOtherHawaiianPacific: false,
  coBorrowerRaceOtherHawaiianPacificText: '',
  coBorrowerRaceWhite: false,
  coBorrowerRaceWishNotToProvide: false,
  /**
   *
   * sub-section : Observations
   *
   * The initial data type must be boolean.
   *
   * This data type is compatible with the `borrower` & `co-borrower` info pages.
   *
   */
  coBorrowerObservedEthnicity: null,
  coBorrowerObservedSex: null,
  coBorrowerObservedRace: null,
};

function getDefaultIllustratorBorrower(data) {
  return {
    // the record unique identifier
    id: data?.id || generateId(),

    ApiRoute: data?.ApiRoute || '',

    originUrl: data?.originUrl || '',

    destinationPage: data?.destinationPage || '',

    //
    // the "product selection" page data
    //

    productSelectionPageAvailability: data?.productSelectionPageAvailability || 'yes',

    //
    // the "interviewer page" data
    //

    interviewPage: {
      thirdPartyLOInterviewerName: data?.interviewPage?.thirdPartyLOInterviewerName || '',
      thirdPartyLOEmailInterviewerEmail: data?.interviewPage?.thirdPartyLOEmailInterviewerEmail || '',
      propertyZipCode: data?.interviewPage?.propertyZipCode || '',
    },

    //
    // borrower info
    //

    borrowerInfo: {
      borrowerMiddleName: data?.borrowerInfo?.borrowerMiddleName || '',
      borrowerFirstName: data?.borrowerInfo?.borrowerFirstName || '',
      borrowerLastName: data?.borrowerInfo?.borrowerLastName || '',
      borrowerEmail: data?.borrowerInfo?.borrowerEmail || '',
      borrowerDOB: data?.borrowerInfo?.borrowerDOB || '',
      borrowerSSN: data?.borrowerInfo?.borrowerSSN || '',
      borrowerSuffix: data?.borrowerInfo?.borrowerSuffix || '',
      borrowerHomePhoneNumber: data?.borrowerInfo?.borrowerHomePhoneNumber || '',
      borrowerMaritalStatus: data?.borrowerInfo?.borrowerMaritalStatus || '',
      borrowerPowerOfAttorney: data?.borrowerInfo?.borrowerPowerOfAttorney || '',
      borrowerPropertyVestedInATrust: data?.borrowerInfo?.borrowerPropertyVestedInATrust || '',
    },

    //
    // coBorrower info
    //

    coBorrowerInfoPageAvailability: data?.coBorrowerInfoPageAvailability || 'no',

    coBorrowerInfo: defaultsDeep(
      {
        coBorrowerMiddleName: data?.coBorrowerInfo?.coBorrowerMiddleName || '',
        coBorrowerFirstName: data?.coBorrowerInfo?.coBorrowerFirstName || '',
        coBorrowerLastName: data?.coBorrowerInfo?.coBorrowerLastName || '',
        coBorrowerEmail: data?.coBorrowerInfo?.coBorrowerEmail || '',
        coBorrowerDOB: data?.coBorrowerInfo?.coBorrowerDOB || '',
        coBorrowerSSN: data?.coBorrowerInfo?.coBorrowerSSN || '',
        coBorrowerHomePhoneNumber: data?.coBorrowerInfo?.coBorrowerHomePhoneNumber || '',
        coBorrowerMaritalStatus: data?.coBorrowerInfo?.coBorrowerMaritalStatus || '',
      },
      {...defaultCoBorrowerInfo},
    ),

    //
    // property
    //

    property: {
      propertyAddress: data?.property?.propertyAddress || '',
      propertyAddress2: data?.property?.propertyAddress2 || '',
      propertyCity: data?.property?.propertyCity || '',
      propertyState: data?.property?.propertyState || '',
      propertyZipCode: data?.property?.propertyZipCode || '',
      propertyHomeValue: data?.property?.propertyHomeValue || '',
      propertyMortgagePayoff: data?.property?.propertyMortgagePayoff || '',
      flagMailAddress: data?.property?.flagMailAddress || '',
      // "first mortgage" is a HomeSafe Second specific attr
      propertyFirstLien: data?.property?.propertyFirstLien || '',
      // "other mortgages" is a HomeSafe Second specific attr
      propertyOtherLiens: data?.property?.propertyOtherLiens || '',
    },

    //
    // mailing address
    //

    mailingAddress: {
      mailingAddress: data?.mailingAddress?.mailingAddress || '',
      mailingAddress2: data?.mailingAddress?.mailingAddress2 || '',
      mailingCity: data?.mailingAddress?.mailingCity || '',
      mailingState: data?.mailingAddress?.mailingState || '',
      mailingZipCode: data?.mailingAddress?.mailingZipCode || '',
    },

    //
    // additional properties
    //

    additionalProperties: {
      apFormList: data?.additionalProperties?.apFormList || [
        {
          streetAddress: '',
          unitNumber: '',
          propertyCity: '',
          propertyState: '',
          propertyZip: '',
        },
      ],
    },

    //
    // credit authorization
    //

    creditAuthorization: {
      authorization: data?.creditAuthorization?.authorization || '', // required,
      creditAuthorizationDate: data?.creditAuthorization?.creditAuthorizationDate || '',
      report: data?.creditAuthorization?.report || '', // required,
      reportReferenceId: data?.creditAuthorization?.reportReferenceId || '',
    },

    //
    // income
    //

    income: {
      incomeFormList: data?.income?.incomeFormList || [
        {
          incomeType: '',
          incomeFrequency: '',
          collectedBy: '',
          monthlyAmount: '',
        },
      ],
    },

    //
    // property taxes
    //

    propertyTaxes: {
      annualCountyTaxAmount: data?.propertyTaxes?.annualCountyTaxAmount || '',
      annualHazardInsuranceAmount: data?.propertyTaxes?.annualHazardInsuranceAmount || '',
      propertyType: data?.propertyTaxes?.propertyType || '',
      NumberOfUnits: data?.propertyTaxes?.NumberOfUnits || '',
      hoaMonthlyFees: data?.propertyTaxes?.hoaMonthlyFees || '',
    },

    //
    // affiliate business arrangements
    //

    affiliatedBusinessArrangements: {
      abaFormList: data?.affiliatedBusinessArrangements?.abaFormList || [
        {
          providerName: '',
          settlementService: '',
          estCost: '',
          natureOfRelation: '',
        },
      ],
    },

    //
    // the "broker transaction question" section
    //
    // "Yes" or "No" values
    //
    //

    BrokerOffersForwardMortgageProducts: data?.BrokerOffersForwardMortgageProducts || 'No',

    //
    // the "broker transactions" section
    //

    brokerTransactions: {
      // section #1
      totalInitialRate1: data?.brokerTransactions?.totalInitialRate1 || '',
      totalFeesPoints1: data?.brokerTransactions?.totalFeesPoints1 || '',
      // section #2
      totalInitialRate2: data?.brokerTransactions?.totalInitialRate2 || '',
      totalFeesPoints2: data?.brokerTransactions?.totalFeesPoints2 || '',
      // section #3
      totalInitialRate3: data?.brokerTransactions?.totalInitialRate3 || '',
      totalFeesPoints3: data?.brokerTransactions?.totalFeesPoints3 || '',
    },

    // interviewer & demographics

    interviewerAndDemographics: {
      demographicInformationSource: data?.interviewerAndDemographics?.demographicInformationSource || '',
      submitted: data?.interviewerAndDemographics?.submitted || false,
    },

    // demographic info : borrower
    demographicInfoBorrower: {
      // ethnicity
      borrowerEthnicityHispanicLatino: data?.demographicInfoBorrower?.borrowerEthnicityHispanicLatino || false,
      borrowerEthnicityMexican: data?.demographicInfoBorrower?.borrowerEthnicityMexican || false,
      borrowerEthnicityPuertoRican: data?.demographicInfoBorrower?.borrowerEthnicityPuertoRican || false,
      borrowerEthnicityCuban: data?.demographicInfoBorrower?.borrowerEthnicityCuban || false,
      borrowerEthnicityOtherHispanicLatino:
        data?.demographicInfoBorrower?.borrowerEthnicityOtherHispanicLatino || false,
      borrowerEthnicityOtherHispanicLatinoText:
        data?.demographicInfoBorrower?.borrowerEthnicityOtherHispanicLatinoText || '',
      borrowerEthnicityNotHispanicLatino: data?.demographicInfoBorrower?.borrowerEthnicityNotHispanicLatino || false,
      borrowerEthnicityWishNotToProvide: data?.demographicInfoBorrower?.borrowerEthnicityWishNotToProvide || false,
      // sex
      borrowerSexFemale: data?.demographicInfoBorrower?.borrowerSexFemale || false,
      borrowerSexMale: data?.demographicInfoBorrower?.borrowerSexMale || false,
      borrowerSexWishNotToProvide: data?.demographicInfoBorrower?.borrowerSexWishNotToProvide || false,
      // race
      borrowerRaceAmericanIndianAlaskaNative:
        data?.demographicInfoBorrower?.borrowerRaceAmericanIndianAlaskaNative || false,
      borrowerRaceAmericanIndianAlaskaNativeText:
        data?.demographicInfoBorrower?.borrowerRaceAmericanIndianAlaskaNativeText || '',
      borrowerRaceAsian: data?.demographicInfoBorrower?.borrowerRaceAsian || false,
      borrowerRaceAsianIndian: data?.demographicInfoBorrower?.borrowerRaceAsianIndian || false,
      borrowerRaceChinese: data?.demographicInfoBorrower?.borrowerRaceChinese || false,
      borrowerRaceFilipino: data?.demographicInfoBorrower?.borrowerRaceFilipino || false,
      borrowerRaceJapanese: data?.demographicInfoBorrower?.borrowerRaceJapanese || false,
      borrowerRaceKorean: data?.demographicInfoBorrower?.borrowerRaceKorean || false,
      borrowerRaceVietnamese: data?.demographicInfoBorrower?.borrowerRaceVietnamese || false,
      borrowerRaceOtherAsian: data?.demographicInfoBorrower?.borrowerRaceOtherAsian || false,
      borrowerRaceOtherAsianText: data?.demographicInfoBorrower?.borrowerRaceOtherAsianText || '',
      borrowerRaceBlackAfricanAmerican: data?.demographicInfoBorrower?.borrowerRaceBlackAfricanAmerican || false,
      borrowerRaceHawaiianPacificIslander: data?.demographicInfoBorrower?.borrowerRaceHawaiianPacificIslander || false,
      borrowerRaceNativeHawaiian: data?.demographicInfoBorrower?.borrowerRaceNativeHawaiian || false,
      borrowerRaceGuamanianChamorro: data?.demographicInfoBorrower?.borrowerRaceGuamanianChamorro || false,
      borrowerRaceSamoan: data?.demographicInfoBorrower?.borrowerRaceSamoan || false,
      borrowerRaceOtherHawaiianPacific: data?.demographicInfoBorrower?.borrowerRaceOtherHawaiianPacific || false,
      borrowerRaceOtherHawaiianPacificText: data?.demographicInfoBorrower?.borrowerRaceOtherHawaiianPacificText || '',
      borrowerRaceWhite: data?.demographicInfoBorrower?.borrowerRaceWhite || false,
      borrowerRaceWishNotToProvide: data?.demographicInfoBorrower?.borrowerRaceWishNotToProvide || false,
      /**
       *
       * sub-section : Observations
       *
       */
      borrowerObservedEthnicity: data?.demographicInfoBorrower?.borrowerObservedEthnicity || '', // conditional required
      borrowerObservedSex: data?.demographicInfoBorrower?.borrowerObservedSex || '', // conditional required
      borrowerObservedRace: data?.demographicInfoBorrower?.borrowerObservedRace || '', // conditional required
    },

    // demographic info : co-borrower
    demographicInfoCoBorrower: {
      // ethnicity
      coBorrowerEthnicityHispanicLatino: data?.demographicInfoCoBorrower?.coBorrowerEthnicityHispanicLatino || false,
      coBorrowerEthnicityMexican: data?.demographicInfoCoBorrower?.coBorrowerEthnicityMexican || false,
      coBorrowerEthnicityPuertoRican: data?.demographicInfoCoBorrower?.coBorrowerEthnicityPuertoRican || false,
      coBorrowerEthnicityCuban: data?.demographicInfoCoBorrower?.coBorrowerEthnicityCuban || false,
      coBorrowerEthnicityOtherHispanicLatino:
        data?.demographicInfoCoBorrower?.coBorrowerEthnicityOtherHispanicLatino || false,
      coBorrowerEthnicityOtherHispanicLatinoText:
        data?.demographicInfoCoBorrower?.coBorrowerEthnicityOtherHispanicLatinoText || '',
      coBorrowerEthnicityNotHispanicLatino:
        data?.demographicInfoCoBorrower?.coBorrowerEthnicityNotHispanicLatino || false,
      coBorrowerEthnicityWishNotToProvide:
        data?.demographicInfoCoBorrower?.coBorrowerEthnicityWishNotToProvide || false,
      // sex
      coBorrowerSexFemale: data?.demographicInfoCoBorrower?.coBorrowerSexFemale || false,
      coBorrowerSexMale: data?.demographicInfoCoBorrower?.coBorrowerSexMale || false,
      coBorrowerSexWishNotToProvide: data?.demographicInfoCoBorrower?.coBorrowerSexWishNotToProvide || false,
      // race
      coBorrowerRaceAmericanIndianAlaskaNative:
        data?.demographicInfoCoBorrower?.coBorrowerRaceAmericanIndianAlaskaNative || false,
      coBorrowerRaceAmericanIndianAlaskaNativeText:
        data?.demographicInfoCoBorrower?.coBorrowerRaceAmericanIndianAlaskaNativeText || '',
      coBorrowerRaceAsian: data?.demographicInfoCoBorrower?.coBorrowerRaceAsian || false,
      coBorrowerRaceAsianIndian: data?.demographicInfoCoBorrower?.coBorrowerRaceAsianIndian || false,
      coBorrowerRaceChinese: data?.demographicInfoCoBorrower?.coBorrowerRaceChinese || false,
      coBorrowerRaceFilipino: data?.demographicInfoCoBorrower?.coBorrowerRaceFilipino || false,
      coBorrowerRaceJapanese: data?.demographicInfoCoBorrower?.coBorrowerRaceJapanese || false,
      coBorrowerRaceKorean: data?.demographicInfoCoBorrower?.coBorrowerRaceKorean || false,
      coBorrowerRaceVietnamese: data?.demographicInfoCoBorrower?.coBorrowerRaceVietnamese || false,
      coBorrowerRaceOtherAsian: data?.demographicInfoCoBorrower?.coBorrowerRaceOtherAsian || false,
      coBorrowerRaceOtherAsianText: data?.demographicInfoCoBorrower?.coBorrowerRaceOtherAsianText || '',
      coBorrowerRaceBlackAfricanAmerican: data?.demographicInfoCoBorrower?.coBorrowerRaceBlackAfricanAmerican || false,
      coBorrowerRaceHawaiianPacificIslander:
        data?.demographicInfoCoBorrower?.coBorrowerRaceHawaiianPacificIslander || false,
      coBorrowerRaceNativeHawaiian: data?.demographicInfoCoBorrower?.coBorrowerRaceNativeHawaiian || false,
      coBorrowerRaceGuamanianChamorro: data?.demographicInfoCoBorrower?.coBorrowerRaceGuamanianChamorro || false,
      coBorrowerRaceSamoan: data?.demographicInfoCoBorrower?.coBorrowerRaceSamoan || false,
      coBorrowerRaceOtherHawaiianPacific: data?.demographicInfoCoBorrower?.coBorrowerRaceOtherHawaiianPacific || false,
      coBorrowerRaceOtherHawaiianPacificText:
        data?.demographicInfoCoBorrower?.coBorrowerRaceOtherHawaiianPacificText || '',
      coBorrowerRaceWhite: data?.demographicInfoCoBorrower?.coBorrowerRaceWhite || false,
      coBorrowerRaceWishNotToProvide: data?.demographicInfoCoBorrower?.coBorrowerRaceWishNotToProvide || false,
      /**
       *
       * sub-section : Observations
       *
       * This data type is compatible with the `borrower` & `co-borrower` info pages.
       *
       */
      coBorrowerObservedEthnicity: data?.demographicInfoCoBorrower?.coBorrowerObservedEthnicity || '', // conditional required
      coBorrowerObservedSex: data?.demographicInfoCoBorrower?.coBorrowerObservedSex || '', // conditional required
      coBorrowerObservedRace: data?.demographicInfoCoBorrower?.coBorrowerObservedRace || '', // conditional required
    },

    /** ********** ********** ********** ********** **********
     *
     * "Declarations" page / start
     *
     * Make sure that the 'declarations' key & the values within the 'declarations' component exist & that they are in sync.
     *
     * This value is used as the defaultValue within the 'declarations' component.
     *
     */

    declarations: {
      /**
       *
       * "borrower" section keys & values
       *
       * We are dealing with :
       *    - on/off 'switch' elements => boolean values
       *    - input 'elements' => string values
       *    - dropdown 'elements' => string values
       *
       * By default, the 'switch' elements must be set as 'false', type of string.
       *
       * By default, the 'dropdown' elements must be set as blank.
       *
       */

      /** outstanding judgement ( switch ) ( 1009 ) */

      borrowerOutstandingJudgement: data?.declarations?.borrowerOutstandingJudgement || 'false',

      /** declared bankruptcy ( switch ) ( 1009 ) */

      borrowerDeclaredBankruptcy: data?.declarations?.borrowerDeclaredBankruptcy || 'false',

      /** declared foreclosure ( switch ) */

      borrowerDeclaredForeclosure: data?.declarations?.borrowerDeclaredForeclosure || 'false',

      /** lawsuit party ( switch ) ( 1009 ) */

      borrowerLawsuitParty: data?.declarations?.borrowerLawsuitParty || 'false',

      /** loan obligation ( switch ) */

      borrowerLoanObligation: data?.declarations?.borrowerLoanObligation || 'false',

      /**
       *
       * - loan delinquency ( switch ) ( 1009 )
       *    - date ( input / date picker ) ( 1009 )
       *    - lender name ( input ) ( 1009 )
       *    - lender address ( input ) ( 1009 )
       *    - FHA case number ( input ) ( 1009 )
       *    - reason ( input ) ( 1009 )
       *
       */

      // main switch question field
      borrowerLoanDelinquency: data?.declarations?.borrowerLoanDelinquency || 'false',
      // sub-options fields
      borrowerLoanDelinquencyDate: data?.declarations?.borrowerLoanDelinquencyDate || '', // 1009
      borrowerLoanDelinquencyLenderName: data?.declarations?.borrowerLoanDelinquencyLenderName || '', // 1009
      borrowerLoanDelinquencyLenderAddress: data?.declarations?.borrowerLoanDelinquencyLenderAddress || '', // 1009
      borrowerLoanDelinquencyFHACaseNumber: data?.declarations?.borrowerLoanDelinquencyFHACaseNumber || '', // 1009
      borrowerLoanDelinquencyReason: data?.declarations?.borrowerLoanDelinquencyReason || '', // 1009

      /** alimony obligation */

      borrowerAlimonyObligation: data?.declarations?.borrowerAlimonyObligation || 'false',

      /**
       *
       * - down payment required ( switch ) ( 1009 )
       * - down payment borrowed ( switch ) ( 1009 )
       *
       */

      borrowerDownPaymentRequired: data?.declarations?.borrowerDownPaymentRequired || 'false',
      borrowerDownPaymentBorrowed: data?.declarations?.borrowerDownPaymentBorrowed || 'false',

      /** note endorser ( 1009 ) */

      borrowerNoteEndorser: data?.declarations?.borrowerNoteEndorser || 'false',

      /** U.S. citizen ( 1009 ) */

      borrowerUsCitizen: data?.declarations?.borrowerUsCitizen || 'false',

      /** resident alien ( 1009 ) */

      borrowerResidentAlien: data?.declarations?.borrowerResidentAlien || 'false',

      /** non-resident alien */

      borrowerNonResidentAlien: data?.declarations?.borrowerNonResidentAlien || 'false',

      /** immigration status */

      borrowerImmigrationStatus: data?.declarations?.borrowerImmigrationStatus || '', // dropdown

      /** property occupation ( switch ) ( 1009 ) */

      borrowerPropertyOccupation: data?.declarations?.borrowerPropertyOccupation || 'false', // required

      /**
       *
       * - property ownership interest ( switch )
       *    - property type (dropdown )
       *    - title hold ( dropdown )
       *
       */

      borrowerPropertyOwnershipInterest: data?.declarations?.borrowerPropertyOwnershipInterest || '', // required
      borrowerPropertyOwnershipInterestPropertyType:
        data?.declarations?.borrowerPropertyOwnershipInterestPropertyType || '', // dropdown
      borrowerPropertyOwnershipInterestTitleHold: data?.declarations?.borrowerPropertyOwnershipInterestTitleHold || '', // dropdown

      /**
       *
       * - reverse mortgage investment ( switch ) ( 1009 )
       *    - product name ( input ) ( 1009 )
       *    - product cost ( input / currency / 2 decimals places ) ( 1009 )
       *
       */

      borrowerReverseMortgageInvestment: data?.declarations?.borrowerReverseMortgageInvestment || 'false', // 1009
      borrowerReverseMortgageInvestmentProductName:
        data?.declarations?.borrowerReverseMortgageInvestmentProductName || '', // 1009
      borrowerReverseMortgageInvestmentProductCost:
        data?.declarations?.borrowerReverseMortgageInvestmentProductCost || '', // 1009

      /**
       *
       *  - has existing FHA insurance loan ( switch ) ( 1009 )
       *    - existing FHA insured loan ( sub-options )
       *      - creditor name ( input / string ) ( 1009 )
       *      - street ( input / string ) ( 1009 )
       *      - city ( input / string ) ( 1009 )
       *      - state ( input ) ( 1009 )
       *      - zip code ( input ) ( 1009 )
       *      - account number ( input / string ) ( 1009 )
       *      - disposition type ( dropdown ) ( 1009 )
       *      - mortgage amount ( input / currency / 2 decimals places ) ( 1009 )
       *      - loan balance ( input / currency / 2 decimals places ) ( 1009 )
       *
       */

      // main switch question field
      borrowerHasExistingFHAInsuredLoan: data?.declarations?.borrowerHasExistingFHAInsuredLoan || 'false',
      // sub-options fields
      borrowerExistingFHAInsuredLoanCreditorName: data?.declarations?.borrowerExistingFHAInsuredLoanCreditorName || '',
      borrowerExistingFHAInsuredLoanStreet: data?.declarations?.borrowerExistingFHAInsuredLoanStreet || '',
      borrowerExistingFHAInsuredLoanCity: data?.declarations?.borrowerExistingFHAInsuredLoanCity || '',
      borrowerExistingFHAInsuredLoanState: data?.declarations?.borrowerExistingFHAInsuredLoanState || '',
      borrowerExistingFHAInsuredLoanZipCode: data?.declarations?.borrowerExistingFHAInsuredLoanZipCode || '',
      borrowerExistingFHAInsuredLoanAccountNumber:
        data?.declarations?.borrowerExistingFHAInsuredLoanAccountNumber || '',
      borrowerExistingFHAInsuredLoanDispositionType:
        data?.declarations?.borrowerExistingFHAInsuredLoanDispositionType || '',
      borrowerExistingFHAInsuredLoanMortgageAmount:
        data?.declarations?.borrowerExistingFHAInsuredLoanMortgageAmount || '',
      borrowerExistingFHAInsuredLoanUnpaidLoanBalance:
        data?.declarations?.borrowerExistingFHAInsuredLoanUnpaidLoanBalance || '',

      /** ********** ********** ********** ********** **********
       *
       * "co-borrower" section keys & values
       *
       * We are dealing with :
       *    - on/off 'switch' elements => boolean values
       *    - input 'elements' => string values
       *    - dropdown 'elements' => string values
       *
       * By default, the 'switch' elements must be set as 'false', type of string.
       *
       * By default, the 'dropdown' elements must be set as blank.
       *
       */

      /** outstanding judgement ( switch ) ( 1009 ) */

      coBorrowerOutstandingJudgement: data?.declarations?.coBorrowerOutstandingJudgement || 'false',

      /** declared bankruptcy ( switch ) ( 1009 ) */

      coBorrowerDeclaredBankruptcy: data?.declarations?.coBorrowerDeclaredBankruptcy || 'false',

      /** declared foreclosure ( switch ) */

      coBorrowerDeclaredForeclosure: data?.declarations?.coBorrowerDeclaredForeclosure || 'false',

      /** lawsuit party ( switch ) ( 1009 ) */

      coBorrowerLawsuitParty: data?.declarations?.coBorrowerLawsuitParty || 'false',

      /** loan obligation ( switch ) */

      coBorrowerLoanObligation: data?.declarations?.coBorrowerLoanObligation || 'false',

      /**
       *
       * - loan delinquency ( switch ) ( 1009 )
       *    - date ( input / date picker ) ( 1009 )
       *    - lender name ( 1009 )
       *    - lender address ( 1009 )
       *    - FHA case number ( 1009 )
       *    - reason ( 1009 )
       *
       */

      // main switch question field
      coBorrowerLoanDelinquency: data?.declarations?.coBorrowerLoanDelinquency || 'false',
      // sub-options fields
      coBorrowerLoanDelinquencyDate: data?.declarations?.coBorrowerLoanDelinquencyDate || '',
      coBorrowerLoanDelinquencyLenderName: data?.declarations?.coBorrowerLoanDelinquencyLenderName || '',
      coBorrowerLoanDelinquencyLenderAddress: data?.declarations?.coBorrowerLoanDelinquencyLenderAddress || '',
      coBorrowerLoanDelinquencyFHACaseNumber: data?.declarations?.coBorrowerLoanDelinquencyFHACaseNumber || '',
      coBorrowerLoanDelinquencyReason: data?.declarations?.coBorrowerLoanDelinquencyReason || '',

      /** alimony obligation ( switch ) */

      coBorrowerAlimonyObligation: data?.declarations?.coBorrowerAlimonyObligation || 'false',

      /**
       *
       * - down payment required ( switch ) ( 1009 )
       * - down payment borrower ( switch ) ( 1009 )
       *
       */

      coBorrowerDownPaymentRequired: data?.declarations?.coBorrowerDownPaymentRequired || 'false', // 1009
      coBorrowerDownPaymentBorrowed: data?.declarations?.coBorrowerDownPaymentBorrowed || 'false',

      /** note endorser ( switch ) ( 1009 ) */

      coBorrowerNoteEndorser: data?.declarations?.coBorrowerNoteEndorser || 'false',

      /** U.S. citizen ( 1009 ) */

      coBorrowerUsCitizen: data?.declarations?.coBorrowerUsCitizen || 'false',

      /** resident alien ( 1009 ) */

      coBorrowerResidentAlien: data?.declarations?.coBorrowerResidentAlien || 'false',

      /** non-resident alien */

      coBorrowerNonResidentAlien: data?.declarations?.coBorrowerNonResidentAlien || 'false',

      /** immigration status */

      coBorrowerImmigrationStatus: data?.declarations?.coBorrowerImmigrationStatus || '', // dropdown

      /** property occupation ( switch ) ( 1009 ) */

      coBorrowerPropertyOccupation: data?.declarations?.coBorrowerPropertyOccupation || 'false', // required

      /**
       *
       * - property ownership interest ( switch )
       *    - property type (dropdown )
       *    - title hold ( dropdown )
       *
       */

      coBorrowerPropertyOwnershipInterest: data?.declarations?.coBorrowerPropertyOwnershipInterest || '', // required
      coBorrowerPropertyOwnershipInterestPropertyType:
        data?.declarations?.coBorrowerPropertyOwnershipInterestPropertyType || '', // dropdown
      coBorrowerPropertyOwnershipInterestTitleHold:
        data?.declarations?.coBorrowerPropertyOwnershipInterestTitleHold || '', // dropdown

      /**
       *
       * - reverse mortgage investment ( switch ) ( 1009 )
       *    - product name ( input ) ( 1009 )
       *    - product cost ( input / currency / 2 decimals places ) ( 1009 )
       *
       */

      coBorrowerReverseMortgageInvestment: data?.declarations?.coBorrowerReverseMortgageInvestment || 'false', // 1009
      coBorrowerReverseMortgageInvestmentProductName:
        data?.declarations?.coBorrowerReverseMortgageInvestmentProductName || '', // 1009
      coBorrowerReverseMortgageInvestmentProductCost:
        data?.declarations?.coBorrowerReverseMortgageInvestmentProductCost || '', // 1009

      /**
       *
       *  - has existing FHA insurance loan ( switch ) ( 1009 )
       *    - existing FHA insured loan ( sub-options )
       *      - creditor name ( input / string ) ( 1009 )
       *      - street ( input / string ) ( 1009 )
       *      - city ( input / string ) ( 1009 )
       *      - state ( input ) ( 1009 )
       *      - zip code ( input ) ( 1009 )
       *      - account number ( input / string ) ( 1009 )
       *      - disposition type ( dropdown ) ( 1009 )
       *      - mortgage amount ( input / currency / 2 decimals places ) ( 1009 )
       *      - loan balance ( input / currency / 2 decimals places ) ( 1009 )
       *
       */

      // main switch question field
      coBorrowerHasExistingFHAInsuredLoan: data?.declarations?.coBorrowerHasExistingFHAInsuredLoan || 'false',
      // sub-options fields
      coBorrowerExistingFHAInsuredLoanCreditorName:
        data?.declarations?.coBorrowerExistingFHAInsuredLoanCreditorName || '',
      coBorrowerExistingFHAInsuredLoanStreet: data?.declarations?.coBorrowerExistingFHAInsuredLoanStreet || '',
      coBorrowerExistingFHAInsuredLoanCity: data?.declarations?.coBorrowerExistingFHAInsuredLoanCity || '',
      coBorrowerExistingFHAInsuredLoanState: data?.declarations?.coBorrowerExistingFHAInsuredLoanState || '',
      coBorrowerExistingFHAInsuredLoanZipCode: data?.declarations?.coBorrowerExistingFHAInsuredLoanZipCode || '',
      coBorrowerExistingFHAInsuredLoanAccountNumber:
        data?.declarations?.coBorrowerExistingFHAInsuredLoanAccountNumber || '',
      coBorrowerExistingFHAInsuredLoanDispositionType:
        data?.declarations?.coBorrowerExistingFHAInsuredLoanDispositionType || '',
      coBorrowerExistingFHAInsuredLoanMortgageAmount:
        data?.declarations?.coBorrowerExistingFHAInsuredLoanMortgageAmount || '',
      coBorrowerExistingFHAInsuredLoanUnpaidLoanBalance:
        data?.declarations?.coBorrowerExistingFHAInsuredLoanUnpaidLoanBalance || '',
    },

    /**
     *
     *  "Declarations" page / stop
     *
     ********** ********** ********** ********** ********** */
  };
}

/**
 *
 * Always assume IDs are stored in a field other that illustrator.id
 *
 * To obtain the illustration ID field, take a look at the getDefaultIllustration json.
 *
 */

function selectId(illustrationBorrower) {
  return illustrationBorrower.id;
}

export const illustratorBorrowerAdapter = createListAdapter({
  selectId,
  defaultValue: getDefaultIllustratorBorrower,
});

export const illustratorBorrowerSelectors = illustratorBorrowerAdapter.getSelectors();

const illustratorBorrowerSlice = createSlice({
  name: 'illustratorBorrower',

  initialState: illustratorBorrowerAdapter.getInitialState({
    progressBarValue: undefined,
  }),

  reducers: {
    addOneIllustrationBorrower: illustratorBorrowerAdapter.addOne,
    updateSelectedIllustratorBorrower: illustratorBorrowerAdapter.updateSelected,
    setSelectedIllustratorBorrowerId: illustratorBorrowerAdapter.setSelectedId,
    removeOneIllustrationBorrower: illustratorBorrowerAdapter.removeOne,
    removeAllIllustratorBorrower: illustratorBorrowerAdapter.removeAll,
    setProgressBarValue: (state, action) => {
      state.progressBarValue = action.payload;
    },
  },
});

export const {
  addOneIllustrationBorrower,
  updateSelectedIllustratorBorrower,
  setSelectedIllustratorBorrowerId,
  removeOneIllustrationBorrower,
  removeAllIllustratorBorrower,
  setProgressBarValue,
} = illustratorBorrowerSlice.actions;

function selectSelf(state) {
  return state.illustratorBorrower;
}

export const getProgressBarValue = createSelector(selectSelf, (state) => state?.progressBarValue);

export const getSelectedIllustratorBorrower = createSelector(selectSelf, (state) =>
  illustratorBorrowerSelectors.getBySelectedId(state),
);

export const getAllIllustratorBorrower = createSelector(selectSelf, (state) =>
  illustratorBorrowerSelectors.selectAll(state),
);

export default illustratorBorrowerSlice.reducer;
