import React, {useState} from 'react';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import BorrowerDetails from 'pages/illustrator/components/borrower-details/BorrowerDetails';
import IllustrationShare from 'pages/illustrator/components/illustration-share/IllustrationShare';
import IllustrationTabs from './components/illustration-tabs/IllustrationTabs';
import ReadyToShare from 'pages/illustrator/components/ready-to-share/ReadyToShare';
import Disclaimer from 'components/common/disclaimer/Disclaimer';
import {Trans} from 'lib/i18n';
import {Box} from '@material-ui/core';
import useStyles from './IllustrationStyles';
import MoreAboutProduct from 'pages/illustrator/components/more-about-product/MoreAboutProduct';
import {useAuthenticated} from 'hooks/authenticate';
import {useIllustratorShare} from 'hooks/illustrator';
import HideInIFrame from 'components/hide-in-iframe/HideInIFrame';

/**
 *
 * the "Illustration" main component
 *
 */

function Illustration(props) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const classes = useStyles();
  const {isAuthenticated} = useAuthenticated();

  const {disableButtons, ApiRoute} = useIllustratorShare();

  return (
    <Box className={classes.productComparisonBoxContainer}>
      <Container className={classes.borrowerDetailsContainer}>
        <Item xs={0} lg={1} />
        <Item xs={12} lg={10}>
          <Container className={classes.illustratorContainer}>
            {/* header "Borrower Details" component */}
            <BorrowerDetails xs={12} md={disableButtons ? 12 : 9} hasSubmitted={hasSubmitted} setHasSubmitted={setHasSubmitted} />
            {/* header "Share Illustrations" component */}
            <IllustrationShare
              xs={disableButtons ? 0 : 12}
              md={disableButtons ? 0 : 3}
              disableButtons={disableButtons}
              ApiRoute={ApiRoute}
            />

            {/* Illustrate / Compares || Card / List */}
            <IllustrationTabs component={Item} xs={12} setHasSubmitted={setHasSubmitted}>
              {isAuthenticated && <MoreAboutProduct />}

              {disableButtons ? null : (
                <ReadyToShare className={classes.readyToShare} disableButtons={disableButtons} ApiRoute={ApiRoute} />
              )}
            </IllustrationTabs>
          </Container>
        </Item>
        <Item xs={0} lg={1} />
      </Container>
      <HideInIFrame>
        <Disclaimer>
          <Trans i18nKey="illustratorTranslation:illustration.disclaimer" />
        </Disclaimer>
      </HideInIFrame>
    </Box>
  );
}

export default Illustration;
