import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    calculationWarningMessage: {
      textAlign: 'left',
    },
    calculationWarningMessageTitle: {
      margin: 'auto',
    },
  }),
  {index: 1},
);

export default useStyles;
