import React, {useEffect} from 'react';
import ThemeProviders from 'providers/ThemeProviders';
import StylesProvider from 'providers/StyleProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import {theme as defaultTheme, themePDF} from 'lib/mui-config';
import {useTranslation} from 'lib/i18n';
import {I18nextProvider} from 'react-i18next';
import {ActionsContextProvider} from 'contexts/actions-context';
import {useLocation} from 'react-router-dom';

function SubProviders(props) {
  const location = useLocation();

  const {
    children,
    theme = !!location.pathname.match(/pdf/i) ? themePDF : defaultTheme,
    defaultNS = 'translation',
  } = props;
  const {i18n} = useTranslation();

  useEffect(() => {
    if (i18n.options.defaultNS !== defaultNS) {
      i18n.setDefaultNamespace(defaultNS);
    }
  }, [defaultNS, i18n]);

  return (
    <StylesProvider>
      <ThemeProviders theme={theme}>
        <CssBaseline />

        <I18nextProvider defaultNS={defaultNS} i18n={i18n}>
          <ActionsContextProvider>{children}</ActionsContextProvider>
        </I18nextProvider>
      </ThemeProviders>
    </StylesProvider>
  );
}

SubProviders.propTypes = {};

export default SubProviders;
