import {useMemo} from 'react';
import {useAppBreakpoints} from 'lib/app-theme';

export function useWithWidth() {
  const {isMobileApp, isSmSizeApp, isTabletApp, isLgSizeApp, isXlSizeApp} = useAppBreakpoints();

  const width = useMemo(() => {
    if (isMobileApp) return 'xs';
    if (isSmSizeApp) return 'sm';
    if (isTabletApp) return 'md';
    if (isLgSizeApp) return 'lg';
    if (isXlSizeApp) return 'xl';
  }, [isXlSizeApp, isLgSizeApp, isTabletApp, isSmSizeApp, isMobileApp]);

  return {width};
}

export function useCleanupWidth(props) {
  const {xs, sm, md, lg, xl} = props;

  return {
    xs: xs || undefined,
    sm: sm || undefined,
    md: md || undefined,
    lg: lg || undefined,
    xl: xl || undefined,
  };
}
