import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useStyles from './AppHeaderStyles';
import Logo from './components/logo/Logo';
import LogoutButton from './components/logout-button/LogoutButton';
import {Box} from '@material-ui/core';
import clsx from 'clsx';
import logo from 'assets/images/logo.png';
import {ROUTES} from 'constants/routes';
import PrintPageTitle from 'components/app-header/components/print-page-title/PrintPageTitle';

const AppHeader = React.forwardRef(function AppHeaderRender(props, ref) {
  const {children, className} = props;
  const classes = useStyles();

  return (
    <AppBar
      position="sticky"
      color="inherit"
      elevation={1}
      className={clsx(classes.root, className)}
      data-testid="app-header"
      ref={ref}
    >
      <Toolbar className={classes.toolBar}>
        <Box display="flex" flexDirection="row" className={classes.toolBarBox}>
          {children}
          <Box display="flex">
            <Logo img={logo} href={ROUTES.XCELERATE_HOME_PAGE} />

            <PrintPageTitle className={classes.illustrationPrintTitle} />
          </Box>
        </Box>

        <LogoutButton className={clsx(classes.hidePrint)} />
      </Toolbar>
    </AppBar>
  );
});

export default AppHeader;
