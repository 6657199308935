import React from 'react';
import IllustrationFormPrompt from 'components/illustrator/illustrator-form/IllustrationFormPrompt';
import {TabContext} from '@material-ui/lab';
import Tabs from 'components/common/Tabs/Tabs';
import IllustrationTab from 'pages/illustrator/components/illustration/components/illustrator-tabs/components/illustration-tab/IllustrationTab';
import {BREAKDOWN_VIEW_TYPE, useViewType} from 'hooks/view-type';
import {Trans, useTranslation} from 'lib/i18n';
import TabPanel from 'components/common/tab-panel/TabPanel';
import PaymentBreakdown from '../payment-breakdown/PaymentBreakdown';
import LifetimeModel from '../lifetime-model/LifetimeModel';
import AmortizationTable from '../amortization-table/AmortizationTable';
import {Box} from '@material-ui/core';
import useStyles from './IllustratorBreakdownTabsStyles';
import {useDispatch} from 'react-redux';
import {setBreakDownViewType} from 'reducers/settings.reducer';
import {useIllustratorActions} from 'hooks/illustrator-form';
import {useFormContext} from 'react-hook-form';
import ProductFeaturesWrapper from 'components/common/features-wrapper/ProductFeaturesWrapper';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {useProductContext} from 'contexts/product-context';

IllustratorBreakDownTabs.propTypes = {};

function IllustratorBreakDownTabs(props) {
  const {defaultViewType = BREAKDOWN_VIEW_TYPE['payment-breakdown']} = props;
  const classes = useStyles();
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {viewType, onTabChange} = useViewType({
    defaultValue: defaultViewType,
    watchDefaultValue: true,
    onTabChange(event, viewType) {
      dispatch(setBreakDownViewType(viewType));
    },
  });

  const {formState: {isDirty, isSubmitting} = {}} = useFormContext();

  useIllustratorActions({
    withSubmitButton: viewType === BREAKDOWN_VIEW_TYPE['amortization-table'] && isDirty,
    submitButtonDisabled: isSubmitting,
  });

  const {product} = useProductContext();

  return (
    <Box className={classes.illustratorBreakdownForm}>
      <IllustrationFormPrompt />

      <TabContext value={viewType}>
        <Tabs value={viewType} onChange={onTabChange} indicatorColor="primary">
          {/* tab : payment breakdown */}
          <IllustrationTab
            className={classes.tabElement}
            value={BREAKDOWN_VIEW_TYPE['payment-breakdown']}
            title={t('illustratorTranslation:illustration breakdown.payment-breakdown.title')}
            label={<Trans i18nKey="illustratorTranslation:illustration breakdown.payment-breakdown.title" />}
          />
          {/* tab : lifetime model */}
          <ProductFeaturesWrapper
            Component={IllustrationTab}
            ProductFeatureKey={PRODUCT_FEATURE_KEY.LIFETIME_MODEL}
            ProductId={product?.ProductId}
            className={classes.tabElement}
            value={BREAKDOWN_VIEW_TYPE['lifetime-model']}
            title={t('illustratorTranslation:illustration breakdown.lifetime-model.title')}
            label={<Trans i18nKey="illustratorTranslation:illustration breakdown.lifetime-model.title" />}
          />

          {/* tab : amortization table */}
          <ProductFeaturesWrapper
            Component={IllustrationTab}
            ProductFeatureKey={PRODUCT_FEATURE_KEY.AMORTIZATION_TABLE}
            ProductId={product?.ProductId}
            withLoginModal
            className={classes.tabElement}
            value={BREAKDOWN_VIEW_TYPE['amortization-table']}
            title={t('illustratorTranslation:illustration breakdown.amortization-table.title')}
            label={<Trans i18nKey="illustratorTranslation:illustration breakdown.amortization-table.title" />}
          />
        </Tabs>

        <TabPanel className={classes.illustratorBreakdownTabPanel} value={BREAKDOWN_VIEW_TYPE['payment-breakdown']}>
          {/* content : payment breakdown */}
          <PaymentBreakdown setHasSubmitted={props.setHasSubmitted} />
        </TabPanel>

        <ProductFeaturesWrapper
          Component={TabPanel}
          ProductFeatureKey={PRODUCT_FEATURE_KEY.LIFETIME_MODEL}
          ProductId={product?.ProductId}
          className={classes.illustratorBreakdownTabPanel}
          value={BREAKDOWN_VIEW_TYPE['lifetime-model']}
        >
          <LifetimeModel />
        </ProductFeaturesWrapper>

        <ProductFeaturesWrapper
          Component={TabPanel}
          ProductFeatureKey={PRODUCT_FEATURE_KEY.AMORTIZATION_TABLE}
          ProductId={product?.ProductId}
          className={classes.illustratorBreakdownTabPanel}
          value={BREAKDOWN_VIEW_TYPE['amortization-table']}
        >
          <AmortizationTable />
        </ProductFeaturesWrapper>
      </TabContext>
    </Box>
  );
}

export default React.memo(IllustratorBreakDownTabs);
