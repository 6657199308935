import React from 'react';
import Button from 'components/common/button/Button';

function SlideOutModalSubmitButton(props) {
  const {doOpen, enabled, showProgress, variant = 'text', children, btnStyle = 'primary', ...rest} = props;

  return (
    <Button
      type="button"
      btnStyle={btnStyle}
      variant={variant}
      onClick={doOpen}
      showProgress={showProgress}
      disabled={!enabled}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default SlideOutModalSubmitButton;
