import React, {useCallback, useMemo} from 'react';

import {Sector, Text} from 'recharts';
import useStyles from './RenderLabelStyles';
import clsx from 'clsx';

const RADIAN = Math.PI / 180;

function useRenderLabel(props) {
  const {cx, cy, outerRadius, midAngle} = props;

  const results = useMemo(() => {
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return {sin, cos, sx, sy, mx, my, ex, ey, textAnchor};
  }, [outerRadius, cx, cy, midAngle]);

  return {...results};
}

function RenderLabel(props) {
  const {
    activeIndex,
    index,
    cx,
    cy,
    fill,
    payload,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    children,
    maxX,
    onMouseLeave,
    onMouseEnter,
    showLabel = true,
  } = props;
  const {sin, cos, sx, sy, mx, my, ex, ey, textAnchor} = useRenderLabel(props);

  const isActive = activeIndex === index;

  const classes = useStyles();
  const iconSize = isActive ? 32 : 30;

  const textX = showLabel ? ex + (cos >= 0 ? 1 : -1) * 12 : ex + (cos >= 0 ? 0.3 : -3) * 12;

  const onMouseEnterCb = useCallback(() => onMouseEnter?.(null, index), [onMouseEnter, index]);

  return (
    <g strokeWidth={isActive ? 3 : 1} onMouseEnter={onMouseEnterCb} onMouseLeave={onMouseLeave}>
      {children}
      {isActive && (
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      )}
      {fill && <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />}
      {showLabel && payload?.label && (
        <Text
          className={clsx(classes.labelText, {[classes.labelTextActive]: isActive})}
          x={textX}
          y={ey}
          textAnchor={textAnchor}
          fill={fill}
          width={maxX - textX}
          verticalAnchor="start"
        >
          {payload.label}
        </Text>
      )}

      {payload?.icon &&
        React.cloneElement(payload.icon, {
          width: iconSize,
          height: iconSize,
          x: showLabel ? ex + (cos >= 0 ? 1 : -2) * 22 : textX,
          y: ey + ((sin >= 0 && cos >= 0) || (sin >= 0 && cos) <= 0 ? -1 : 0) * (showLabel ? 42 : 15),
          color: fill,
          textAnchor,
        })}
    </g>
  );
}

RenderLabel.propTypes = {};

export default RenderLabel;
