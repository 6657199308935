import React, {useCallback, useRef, useState} from 'react';
import Link from '@material-ui/core/Link';
import List from 'components/common/list/List';
import {Collapse, ListItem, Paper} from '@material-ui/core';
import useStyles from './expendable-link-styles';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function ExpendableLink(props) {
  const {children, text, className, sublist, listClassName, listItemClassName, listItemExcluded, ...rest} = props;
  const [isOpen, setOpen] = useState(false);
  const ref = useRef();
  const classes = useStyles({offsetLeft: ref.current?.offsetLeft});

  const setIsOpen = useCallback(() => {
    return setOpen(true);
  }, [setOpen]);

  const setIsClose = useCallback(() => {
    return setOpen(false);
  }, [setOpen]);

  return (
    <div className={clsx(classes.rootExpendableContainer)} onMouseEnter={setIsOpen} onMouseLeave={setIsClose}>
      <ListItem
        className={clsx(listClassName, classes.linkNoUnderline, {[classes.sublistLinkChild]: sublist})}
        ref={ref}
      >
        <Link className={clsx(className)} {...rest}>
          {text}
        </Link>

        {sublist && <FontAwesomeIcon icon={['fas', 'chevron-right']} />}
      </ListItem>

      <Collapse in={isOpen}>
        <Paper className={clsx(classes.paperContainer, {[classes.sublistPaperContainer]: sublist})}>
          <List flexDirection="column" listItemClassName={clsx(listItemClassName, classes.listItemNoUnderline)}>
            {children}
          </List>
        </Paper>
      </Collapse>
    </div>
  );
}

ExpendableLink.defaultProps = {
  listItemExcluded: true,
};
ExpendableLink.propTypes = {};

export default ExpendableLink;
