import React from 'react';
import ProductWidget from '../product-widget/ProductWidget';
import {useProductsContext} from 'contexts/products-context';
import {useFilterCalculations} from 'pages/illustrator/components/use-filter-products';

/**
 *
 * typeof "product" is array
 *
 * the array will contain 2 products
 *
 * the 2 products are :
 *
 *    - "annual" aka "HECM Adjustable"
 *    - "tier " aka "Homesafe Standard"
 *
 * the "annual" product object looks like :
 *
 * {
 *    ApiRoute : "annual"
 *    Enabled : true
 *    LinkProductName : "HECM Monthly CMT"
 *    ProductAvailableStates : [ {}, ... ]
 *    ProductFeatures : [ {}, ... ]
 *    ProductId : 1
 *    ProductName : "HECM Adjustable"
 *    ProductValue : "annual"
 *    Rank : 4
 *    calculation : {
 *      AdjustedPL : 0
 *      ApiRoute : "annual"
 *      BorrowerProfile : {
 *        Address : ""
 *        Age : 63
 *        BorrowerPreferenceId : 26015
 *        BorrowerProfileId : "hash_goes_here"
 *        DateOfBirth : "1960-01-01T00:00:00"
 *        Email : ""
 *        ExistingMortgage : {
 *          ExistingMortgageId : 1356628
 *          ExistingMortgageRemainingBalance : 0
 *          HomeownersInsurancePremium : ""
 *          InsurancePremiumPaymentType : {
 *            PaymentTypeId : 1
 *            PaymentTypeName : "Monthly"
 *          }
 *          InsurancePremiumPaymentTypeId : 1
 *          InsurancePremiumTypeName : "Monthly"
 *          MortgagePayment : ""
 *          MortgageRate : ""
 *          PropertyTaxPayment : ""
 *          ...
 *        }
 *        ExistingMortgageId : 135628
 *        FirstName : ""
 *        IsSaved : false
 *        LastName : ""
 *        Liens : 5000
 *        NumberOfUnits : ""
 *        OwnerUserId : "hash_hoes_here"
 *        State : "CA"
 *        ValueOfProperty : 500000
 *        Zip : "92507"
 *      }
 *      BorrowerProfileId : "unique_hash_goes_here"
 *      CalculationId : "unique_hash_goes_here"
 *      CalculationWarnings : []
 *      CashAtClose : 0
 *      ClosingCost : 17000
 *      Errors : []
 *      ExpectedRate : 6.58
 *      Fee : 0
 *      FixedRate : 0
 *      FundAccess : "lineOfCredit"
 *      HecmMetric : {
 *        Active : false
 *        AgeRoundingDayThreshold : 244
 *        ClosingDateEstimateDays : 62
 *        HecmMetricId : 18
 *        LoanLimit : 970800
 *        MaxBorrowerAge : 99
 *        MaxClaimAmount : 970800
 *        MinBorrowerAge : 62
 *        MinBorrowerAgeWarning : 62
 *        SysEndDate : "0001-01-01T00:00:00"
 *        SysStartDate : "0001-01-01T00:00:00"
 *      }
 *      InitialRate : 6.82
 *      IsSaved : false
 *      LenderCredit : false
 *      Lesa : false
 *      LineOfCredit : 145500
 *      MIP : 0.5
 *      Margin : 2.75
 *      MaxRevolvingLoc : 0
 *      ...
 *    }
 *    validation : {
 *      maxDate : Moment
 *      minDate : Moment
 *    }
 * }
 *
 */

function ProductsWidget(props) {
  const {products} = useProductsContext();

  const orderProducts = useFilterCalculations({Calculations: props?.Calculations ?? [], products});

  return (
    <>
      {orderProducts?.map((product) => (
        <ProductWidget key={product.ProductId} product={product} />
      ))}
    </>
  );
}

export default ProductsWidget;
