import {useQuery} from 'react-query';
import LinkLoansApi from 'api/link-loans-api';
import {statusToLoading} from 'adapter/loading.adapter';
import {linkLoanDetailsQueryKey} from 'constants/query';
import {useOktaAuth} from '@okta/okta-react';

export function useLinkLoanDetailsQuery(props) {
  const {
    authState: {isAuthenticated},
    oktaAuth: {
      token: {getUserInfo},
    },
  } = useOktaAuth();
  const {
    data: linkLoanDetailsData,
    status,
    isFetching,
  } = useQuery([linkLoanDetailsQueryKey, getUserInfo, isAuthenticated, props.loanId], async () => {
    return LinkLoansApi.getLoan(props.loanId);
  });

  const loading = statusToLoading(status, isFetching);

  return {data: linkLoanDetailsData, loading};
}
