import React, {useCallback, useContext, useRef} from 'react';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

const defaultBodyLockContextValue = {
  onDisableBodyScroll: () => undefined,
  onEnableBodyScroll: () => undefined,
  setLockBodyScroll: (val = false) => undefined,
};
export const BodyLockContext = React.createContext(defaultBodyLockContextValue);

export function useBodyLockContext() {
  return useContext(BodyLockContext);
}

function BodyLockProvider(props) {
  const {children} = props;

  const childrenRef = useRef();

  const onDisableBodyScroll = useCallback(() => {
    return disableBodyScroll(childrenRef);
  }, [childrenRef]);

  const onEnableBodyScroll = useCallback(() => {
    return enableBodyScroll(childrenRef);
  }, [childrenRef]);

  return (
    <BodyLockContext.Provider value={{onDisableBodyScroll, onEnableBodyScroll, childrenRef}}>
      {children}
    </BodyLockContext.Provider>
  );
}

export default BodyLockProvider;
