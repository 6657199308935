import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {ROUTES} from 'constants/routes';

import CalculatorPage from 'pages/widgets-page/calculator-page/CalculatorPage';
import {themeWidget} from 'lib/mui-config';
import SubProviders from 'providers/SubProviders';

function AppWidgets() {
  return (
    <SubProviders defaultNS="widgetsTranslation" theme={themeWidget}>
      <div className="App-widgets">
        <Switch>
          <Route path={ROUTES.WIDGETS_CALCULATOR} render={(props) => <CalculatorPage {...props} />} />
        </Switch>
      </div>
    </SubProviders>
  );
}

export default AppWidgets;
