import ContentServiceApi from 'api/content-api';
import {useQuery} from 'react-query';
import {keyBy, mapValues, merge, set, transform} from 'lodash';
import {contentQueryKey} from 'constants/query';
import {addI18nResource} from 'lib/i18n';
import {LANGUAGES} from 'constants/i18n';

function index(obj, i) {
  return obj[i] || {[i]: {}};
}

function getKeyNamespace(key) {
  if (key?.indexOf(':') !== -1) return key.split(':');

  return ['translation', key];
}

export function useContentQuery() {
  return useQuery({
    queryKey: [contentQueryKey],
    queryFn() {
      return ContentServiceApi.getAll();
    },
    onSuccess(data) {
      const translationsData = transform(mapValues(keyBy(data, 'ContentKey'), 'ContentText'), (r, v, k) => {
        const [namespace, key] = getKeyNamespace(k);
        const accumulator = {};

        key.split('.').reduce(index, accumulator);

        const setData = (v?.length > 1 ? v : v?.[0]) || '';

        merge(r, set(accumulator, `${namespace}.${key}`, setData));
      });

      Object.keys(translationsData).map((namespace) =>
        addI18nResource(LANGUAGES.EN, namespace, translationsData[namespace]),
      );
    },
    staleTime: Infinity,
  });
}
