import React, {useMemo} from 'react';
import {ProductContextProvider} from 'contexts/product-context';
import {useTierHook} from 'components/products/tier/TierHook';
import {useCalculateTierQuery} from 'query/products/home-safe/tier';
import {useTierOverallResults} from 'components/products/tier/TierOverallResults';
import {API_ROUTES} from 'constants/illustrator';
import {useProductWithRef} from 'hooks/product-ref';

/**
 *
 * The "Tier" product, aka "HomeSafe Standard", part of the "HomeSafe" products.
 *
 */

const Tier = React.forwardRef(function TitleRender(props, ref) {
  const {product, setRef, ...rest} = props;

  const tierHook = useTierHook({product});
  const tierQuery = useCalculateTierQuery({product});
  const tierOverallResults = useTierOverallResults({product});

  const productResults = useMemo(() => ({...tierOverallResults, ...tierHook}), [tierHook, tierOverallResults]);

  useProductWithRef({
    productDetails: tierHook,
    productResults: tierOverallResults,
    productQuery: tierQuery,
    ref,
    ApiRoute: API_ROUTES.TIER,
  });

  return (
    <ProductContextProvider
      productDetails={tierHook}
      productResults={productResults}
      productQuery={tierQuery}
      product={product}
      {...rest}
    />
  );
});

export default Tier;
