import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  ProductCard,
  ProductCardInfo,
} from 'pages/illustrator/components/product-cards/components/product-card/ProductCard';
import useStyles from './ProductCardsStyles';
import {useExistingMortgageProduct, useIllustrateProduct} from 'hooks/product';
import {ProductCardContextProvider} from 'contexts/product-card-context';
import {excludeForwardRefi, isProductCalculated} from 'lib/product';
import clsx from 'clsx';
import RecommendedForm from './components/recommended-form/RecommendedForm';
import LinearLoader from 'components/common/loader/LinearLoader';
import {isLoaded} from 'adapter/loading.adapter';
import {withProductComparisonList} from 'hooks/product-comparison';
import {useProductsContext} from 'contexts/products-context';
import {useFilterCalculations} from 'pages/illustrator/components/use-filter-products';
import {getScenario} from 'reducers/settings.reducer';
import {SCENARIOS} from 'constants/scenarios';
import HybridOffer from './components/hybrid-offer/HybridOffer';
import Grid from '@material-ui/core/Grid';

function ProductCards(props) {
  const {isProductComparsion, onTabChange, onProductSelected, className, loading, children = () => null, data, setHasSubmitted} = props;
  const classes = useStyles();

  const {products = []} = useProductsContext();

  const {illustrateProduct} = useIllustrateProduct({onTabChange, onProductSelected, isProductComparsion, setHasSubmitted});
  const {ExistingMortgageProduct} = useExistingMortgageProduct();

  const offerHybridScenario = useSelector(getScenario(SCENARIOS.OFFER_HYBRID));
  const offerHybridNoProductsScenario = useSelector(getScenario(SCENARIOS.OFFER_HYBRID_NO_PRODUCTS));
  const scenario = offerHybridScenario ?? offerHybridNoProductsScenario;

  const [normalProductsData = [], infoProducts = [], displayHybridOffer] = useMemo(() => {
    if (!isLoaded({loading})) return [];
    let normalProducts = products.filter(isProductCalculated).filter(excludeForwardRefi);
    let infoProducts = products.filter(isProductCalculated).filter((product) => !excludeForwardRefi(product));
    if (ExistingMortgageProduct) infoProducts.push(ExistingMortgageProduct);

    // Display "Hybrid Offer"
    // 1. If there is no any hybrid offer scenario, we don't display hybrid offer card.
    // 2. If there is a hybrid offer scenario, and we don't have hybrid one among normal products, we display hybrid offer card.
    // 3. If there is a hybrid offer scenario, and we also have hybrid one among normal products, we don't display hybrid offer card.
    function enableHybridOffer(normalProducts) {
      const hybridProductValue = 'hybrid';
      let displayHybridProductOffer = false;
      let hasHybridInNormalProducts = normalProducts.filter((p) => p.ProductValue === hybridProductValue).length > 0;
      if (
        scenario &&
        !hasHybridInNormalProducts &&
        (scenario.Key === SCENARIOS.OFFER_HYBRID || scenario.Key === SCENARIOS.OFFER_HYBRID_NO_PRODUCTS)
      ) {
        displayHybridProductOffer = products.filter((p) => p.ProductValue === hybridProductValue).length > 0;
      }
      return displayHybridProductOffer;
    }
    let displayHybridOffer = enableHybridOffer(normalProducts);

    return [normalProducts, infoProducts, displayHybridOffer];
  }, [loading, products, ExistingMortgageProduct, scenario]);

  const normalProducts = useFilterCalculations({Calculations: data?.Calculations ?? [], products: normalProductsData});

  return (
    <>
      {children({products, loading})}

      <LinearLoader loading={loading} childrenContainerClassName={clsx(className, classes.productsCardsContainer)}>
        <RecommendedForm data={data}>
          <ProductCardContextProvider>
            <Grid container spacing={3}>
              {normalProducts.map((product) => (
                <Grid container item xs justifyContent="center" key={product.ProductId}>
                  <ProductCard
                    key={product.ProductId}
                    product={product}
                    illustrateProduct={illustrateProduct}
                    loading={loading}
                    setHasSubmitted={setHasSubmitted}
                  />
                </Grid>
              ))}

              {infoProducts.map((product) => (
                <Grid container item xs justifyContent="center" key={product.ProductId}>
                  <ProductCardInfo
                    key={product.ProductId}
                    product={product}
                    loading={loading}
                    isProductComparsion={isProductComparsion}
                  />
                </Grid>
              ))}

              {displayHybridOffer &&
                <Grid container item xs justifyContent="center">
                  <HybridOffer scenario={scenario} />
                </Grid>
              }
            </Grid>
          </ProductCardContextProvider>
        </RecommendedForm>
      </LinearLoader>
    </>
  );
}

ProductCards.propTypes = {};

export default withProductComparisonList(ProductCards);
