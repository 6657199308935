import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    backgroundContainer: {
      backgroundColor: theme.palette.common.white,
    },
    productComparisonBoxContainer: {
      width: '100%',
    },
    productComparisonContainer: {
      textAlign: 'right',
      paddingTop: '0 !important',
      padding: '0 !important',
    },
    gridOutContainer: {
      paddingBottom: '0 !important',

      '& .Mui-selected $changeButton': {
        color: theme.palette.primary.main,
      },
    },
    alignLeftMenu: {
      justifyContent: 'flex-start',
    },
    alignRightMenu: {
      marginLeft: 'auto',
    },
    close: {
      display: 'none',
    },
    hide: {
      visibility: 'hidden',
      display: 'none',
    },
    changeButton: {
      color: theme.palette.primary.dark,
      textTransform: 'none',
      fontSize: `${theme.spacing(2.5)}px !important`,
    },
    productComparisonProductCards: {
      justifyContent: 'space-evenly !important',
    },
    noUnderline: {
      '&.Mui-selected': {
        borderBottom: 'none',
      },
    },
  }),
  {index: 1},
);

export default useStyles;
