import {useCallback, useEffect, useState} from 'react';
import {isFunction} from 'lodash';
import {generateId} from 'lib/generator';

const CLOSE_OTHER = 'close-others';

export function useCollapse(props = {}) {
  const {id = generateId(), scope = 'default', onToggle} = props;
  const [open, setOpen] = useState(false);

  const toggleCollapse = useCallback(() => {
    const newOpen = !open;

    setOpen(newOpen);

    window.dispatchEvent(new CustomEvent(CLOSE_OTHER, {detail: {id, scope}}));
    if (isFunction(onToggle)) onToggle(newOpen);
  }, [open, setOpen, id, scope, onToggle]);

  useEffect(() => {
    function listener(event) {
      if (event.detail.id !== id && event.detail.scope === scope) setOpen(false);
    }

    window.addEventListener(CLOSE_OTHER, listener);

    return () => {
      window.removeEventListener(CLOSE_OTHER, listener);
    };
  }, [scope, id]);

  return {open, toggleCollapse};
}
