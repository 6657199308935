import {axios} from './api';

export default class CalculationsApi {
  static baseUrl = '/calculations';

  static async saveBorrowerNotes(data, config) {
    return axios.post(`${this.baseUrl}/${data.CalculationId}/notes`, {Notes: data.Notes}, config);
  }

  static async getBorrowerNotes(query, config) {
    return axios.get(`${this.baseUrl}/${query.CalculationId}/notes`, config);
  }

  static async getProductComparison(data, config) {
    return axios.post(`${this.baseUrl}/comparison`, data, config);
  }

  // illustrator : modal email to borrower : email illustrations
  static async saveEmailIllustrations(data, config) {
    return axios.post(
      `${this.baseUrl}/share`,
      {
        toEmails: data.toEmails,
        calculationIds: data.calculationIds,
      },
      config,
    );
  }

  // illustrator : modal email to borrower : email illustrations
  static async shareIllustrations(data, config) {
    return axios.post(`${this.baseUrl}/share/pdfs`, data, config);
  }
}
