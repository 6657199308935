import React, {useCallback, useMemo} from 'react';
import Form from 'components/common/form/Form';
import {Box} from '@material-ui/core';
import clsx from 'clsx';
import Text from 'components/common/typeography/text/Text';
import Input from 'components/common/form/input/Input';
import DatePicker from 'components/common/form/date-picker/DatePicker';
import Button from 'components/common/button/Button';
import {useTranslation} from 'lib/i18n';
import useStyles from './SlideOutModalFormStyles';
import SubmitButton from 'components/common/form/SubmitButton';
import {defaultsDeep, isFunction, omit} from 'lodash';
import {useDateOfBirthValidation} from 'hooks/validator';
import yup from 'lib/validation';
import {dollarLimitedField, ZipField} from 'lib/validation/validation';
import {useSelector} from 'react-redux';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import {trackEvent} from 'services/analytics.service';
import {SCENARIO_ACTIONS, SCENARIO_CATEGORIES} from 'constants/analytic-events';

function useSlideOutModalForm(props = {}) {
  const {onSubmit, defaultValues, doClose, isBorrowDetails} = props;
  const illustration = useSelector(getSelectedIllustration);

  const doSubmit = useCallback(
    async (data, event) => {
      doClose();

      if (isBorrowDetails) {
        trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_SAVED_BORROWER_DETAILS);
      } else {
        trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_SAVED_PAYMENT_BREAKDOWN);
      }

      /**
       *
       * mapping the form fields to the "Borrower Profile" fields
       * &
       * saving the "Borrower Profile" data
       *
       */

      const BorrowerProfile = defaultsDeep(
        {
          BorrowerProfileId: data.BorrowerProfileId,
          Age: data.Age,
          DateOfBirth: data.DateOfBirth,
          FirstName: data.FirstName,
          LastName: data.LastName,
          Email: data.Email,
          Address: data.propertyAddress,
          Zip: data.Zip,
          city: data.city,
          State: data.State,
          county: data.county,
          Liens: data.Liens,
          ValueOfProperty: data.ValueOfProperty,
        },
        {...omit(illustration?.BorrowerProfile, [])},
      );

      return isFunction(onSubmit) && onSubmit(BorrowerProfile);
    },
    [illustration?.BorrowerProfile, onSubmit, doClose, isBorrowDetails],
  );

  const DateOfBirthValidation = useDateOfBirthValidation({Zip: defaultValues.Zip, ApiRoute: illustration?.ApiRoute});

  const validationSchema = useMemo(
    () =>
      yup.object({
        // the borrower information
        FirstName: yup
          .string()
          .max(30)
          .test({
            name: 'please-provide-firstName-or-lastName', // the error identifier
            test(value) {
              return !!(value.length || this.parent?.LastName.length);
            },
          }),
        LastName: yup
          .string()
          .max(30)
          .test({
            name: 'please-provide-firstName-or-lastName', // the error identifier
            test(value) {
              return !!(this.parent?.FirstName.length || value.length);
            },
          }),
        DateOfBirth: DateOfBirthValidation, // the property information
        propertyAddress: yup.string().max(256),
        Zip: ZipField(),
        ValueOfProperty: dollarLimitedField({dollarLimitMax: 99999999}),
        Liens: dollarLimitedField({required: false, dollarLimitMax: 99999999}),
      }),
    [DateOfBirthValidation],
  );

  return {doSubmit, validationSchema};
}

function SlideOutModalForm(props) {
  const {defaultValues, submitButtonLabel, doClose, isBorrowDetails} = props;
  const {doSubmit, validationSchema} = useSlideOutModalForm(props);

  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Form id="slide-out-modal" defaultValues={defaultValues} onSubmit={doSubmit} validationSchema={validationSchema}>
      {/* Borrower Information / start */}
      <Container spacing={0}>
        <Item spacing={0} xs={12}>
          <Box className={classes.m_1_0_1_0}>
            <h2 className={clsx(classes.headers)}>{t('borrowerDetailsModalTranslation:borrower information')}</h2>
          </Box>
        </Item>
        <Item spacing={0} xs={12}>
          <Container spacing={1}>
            <Item spacing={0} xs={12} sm={6}>
              <Box className={classes.m_1_0_1_0}>
                <Text variant="subtitle2" className={clsx(classes.labels)}>
                  {t('translation:common.FirstName')}
                </Text>
              </Box>
              <Input type="input" name="FirstName" variant="outlined" label="" />
            </Item>
            <Item spacing={0} xs={12} sm={6}>
              <Box className={classes.m_1_0_1_0}>
                <Text variant="subtitle2" className={clsx(classes.labels)}>
                  {t('translation:common.LastName')}
                </Text>
              </Box>
              <Input type="input" name="LastName" variant="outlined" label="" />
            </Item>
          </Container>
        </Item>
        {/* youngest borrower DOB / start */}
        <Item spacing={0} xs={12}>
          <Container spacing={1}>
            <Item spacing={0} xs={12} sm={6}>
              <Box className={classes.m_2_0_1_0}>
                <Text variant="subtitle2" className={clsx(classes.labels)}>
                  {t('borrowerDetailsModalTranslation:youngest borrower dob')}
                </Text>
              </Box>
              <Container spacing={1}>
                <Item spacing={0} xs={12}>
                  <DatePicker name="DateOfBirth" label="" />
                </Item>
              </Container>
            </Item>
          </Container>
        </Item>
        {/* youngest borrower DOB / stop */}
      </Container>
      {/* Borrower Information / stop */}
      {/* Property Information / start */}
      <Container spacing={0}>
        <Item spacing={0} xs={12}>
          <Box className={classes.m_4_0_0_0}>
            <h2 className={clsx(classes.headers)}>{t('borrowerDetailsModalTranslation:propertyInformation')}</h2>
          </Box>
        </Item>
        <Item spacing={0} xs={12}>
          <Container spacing={1}>
            <Item spacing={0} xs={12} sm={6}>
              <Box className={classes.m_1_0_1_0}>
                <Text variant="subtitle2" className={clsx(classes.labels)}>
                  {t('borrowerDetailsModalTranslation:propertyAddress')}
                </Text>
              </Box>
              <Input type="input" name="propertyAddress" variant="outlined" label="" />
            </Item>
            <Item spacing={0} xs={12} sm={6}>
              <Box className={classes.m_1_0_1_0}>
                <Text variant="subtitle2" className={clsx(classes.labels)}>
                  {t('borrowerDetailsModalTranslation:Zip')}
                </Text>
              </Box>
              <Input id="Zip" name="Zip" type="zip" maxLength={5} variant="outlined" label="" />
            </Item>
            <Item spacing={0} xs={12} sm={6}>
              <Box className={classes.m_1_0_1_0}>
                <Text variant="subtitle2" className={clsx(classes.labels)}>
                  {t('borrowerDetailsModalTranslation:ValueOfProperty')}
                </Text>
              </Box>
              <Input id="ValueOfProperty" type="currency" name="ValueOfProperty" variant="outlined" label="" />
            </Item>
            <Item spacing={0} xs={12} sm={6}>
              <Box className={classes.m_1_0_1_0}>
                <Text variant="subtitle2" className={clsx(classes.labels)}>
                  {t('borrowerDetailsModalTranslation:Liens')}
                </Text>
              </Box>
              <Input id="Liens" type="currency" name="Liens" variant="outlined" label="" />
            </Item>
          </Container>
        </Item>
      </Container>
      {/* Property Information / stop */}

      <Container spacing={0}>
        <Item spacing={0} xs={12} sm={6}>
          <Box>
            <SubmitButton form="slide-out-modal" className={clsx(classes.submitButton)} btnStyle="bgElectric">
              {submitButtonLabel ?? t('translation:justText.illustrate')}
            </SubmitButton>
          </Box>
        </Item>
      </Container>
      <Container spacing={0}>
        <Item spacing={0} xs={12} sm={6}>
          <Button
            className={clsx(classes.hideButton)}
            btnStyle="primary"
            onClick={() => {
              doClose();

              if (isBorrowDetails) {
                trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_SAVED_CANCEL_BORROWER_DETAILS);
              } else {
                trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_SAVED_CANCEL_PAYMENT_BREAKDOWN);
              }
            }}
          >
            {t('translation:justText.hide')}
          </Button>
        </Item>
      </Container>
    </Form>
  );
}

export default SlideOutModalForm;
