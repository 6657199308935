import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    modalAlertTitle: {
      '& .MuiAlert-icon': {
        fontSize: theme.spacing(4),
        marginTop: 'auto',
        marginBottom: 'auto',
      },
    },
  }),
  {index: 1},
);

export default useStyles;
