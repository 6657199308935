import Item from 'components/common/layout/item/Item';
import React from 'react';

/**
 *
 * This component is used to render all additional options/configs/steps.
 *
 */

function RenderAllAdditionalOptions(props) {
  const {options = [], component: Component = Item, ...rest} = props;

  if (!options.length) return false;

  return options.map((step, index) => (
    <Component xs={12} key={index} {...rest}>
      {step}
    </Component>
  ));
}

export default RenderAllAdditionalOptions;
