import React, {useCallback, useMemo} from 'react';
import Item from 'components/common/layout/item/Item';
import Button from 'components/common/button/Button';
import Container from 'components/common/layout/container/Container';
import Title from 'components/common/typeography/title/Title';
import Text from 'components/common/typeography/text/Text';
import useStyles from './FooterSectionStyles';
import useContentStyles from '../content-section/ContentSectionStyles';
import {Trans, useTranslation} from 'lib/i18n';
import clsx from 'clsx';
import {useHistory} from 'react-router-dom';
import {ROUTES} from 'constants/routes';
import {useDispatch, useSelector} from 'react-redux';
import {getSelectedIllustration, updateSelectedIllustration} from 'reducers/illustrator.reducer';
import {generateSafePath} from 'lib/react-router-dom';
import {isPending} from 'adapter/loading.adapter';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {useProductFeature} from 'hooks/product-features';
import Disclaimer from 'components/common/disclaimer/Disclaimer';
import {useProductContext} from 'contexts/product-context';

function FooterSection(props) {
  const {printPdf, pdfRoute} = props;
  const {t} = useTranslation();
  const history = useHistory();

  const classes = useStyles();
  const classesContent = useContentStyles();
  const dispatch = useDispatch();

  const illustration = useSelector(getSelectedIllustration);
  const {product} = useProductContext();

  const goToIllustrator = useCallback(async () => {
    await dispatch(updateSelectedIllustration({ApiRoute: product?.ApiRoute}));

    return history.push({
      pathname: generateSafePath(ROUTES.ILLUSTRATOR_ROOT_PAGE, {CalculationId: illustration?.id}),
    });
  }, [dispatch, history, illustration, product?.ApiRoute]);

  const {isProductFeatureEnabled} = useProductFeature();
  const isButtonEnabled = useMemo(() => {
    if (!t('footer section.submit button')) return false;
    return isProductFeatureEnabled(product?.ApiRoute, PRODUCT_FEATURE_KEY.LIFETIME_IMPACT_BTN);
  }, [t, product, isProductFeatureEnabled]);

  const handlePrintClick = useCallback(
    () => printPdf.handlePDF({src: generateSafePath(pdfRoute, {CalculationId: illustration.CalculationId})}),
    [illustration.CalculationId, pdfRoute, printPdf],
  );

  return (
    <Container className={clsx(classesContent.fullPage, classesContent.rootContainer, classesContent.noWrapPrint)}>
      <Item xs={12}>
        <Disclaimer className={classes.disclaimerContainer}>
          <Trans i18nKey="footer section.disclaimer" />
        </Disclaimer>
      </Item>

      <Item xs={12} className={classesContent.flexContainer}>
        <Container className={clsx(classesContent.descriptionContainer, classes.noMarginTop)}>
          <Item xs={12} md={3} />
          <Item xs={12} md={6} className={classes.leftSection}>
            <Container>
              <Item xs={12}>
                <Title variant="h4">
                  <Trans i18nKey="footer section.title" />
                </Title>
              </Item>

              <Item xs={12}>
                <Text variant="h6">
                  <Trans i18nKey="footer section.description" />
                </Text>
              </Item>
            </Container>
          </Item>
          <Item xs={12} md={3} />

          {isButtonEnabled ? (
            <Item xs={12} className={classesContent.hidePrint}>
              <Button btnStyle="bgElectric" type="button" onClick={goToIllustrator}>
                <Trans i18nKey="footer section.submit button" />
              </Button>
            </Item>
          ) : null}
          <Item xs={12} className={classesContent.hidePrint}>
            <Button
              btnStyle="bgElectric"
              type="button"
              showProgress={isPending(printPdf)}
              disabled={isPending(printPdf) || !illustration.CalculationId}
              onClick={handlePrintClick}
            >
              <Trans i18nKey="translation:common.print button" />
            </Button>
          </Item>
        </Container>
      </Item>

      <Item xs={12}>
        <Disclaimer>
          <Trans i18nKey="illustratorTranslation:illustration.disclaimer" />
        </Disclaimer>
      </Item>
    </Container>
  );
}

FooterSection.propTypes = {};

export default FooterSection;
