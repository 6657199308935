import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    illustratorTabPanelContainer: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    illustratorTabPanelFlexContainer: {
      overflowX: 'scroll',
    },
    tabElement: {
      '& span': {
        fontWeight: 500,
      },
    },
  }),
  {index: 1},
);

export default useStyles;
