import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    titleElementWithAnchor: {
      display: 'flex',
      '& .MuiTypography-root': {
        width: '100%',
      },
      '& p': {
        fontWeight: '500 !important',
      },
      '& svg': {
        margin: theme.spacing('auto', 0, 'auto', 2),
      },
    },
    collapseElementContainer: {
      width: '100%',
      margin: 'auto',
      '& .collapseElementChild': {
        marginTop: '0',
        paddingTop: '0',
        marginLeft: '20px',
      },
      '& p': {
        fontWeight: '500 !important',
      },
    },
  }),
  {index: 1},
);

export default useStyles;
