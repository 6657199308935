import React, {useMemo} from 'react';
import Title from 'components/common/typeography/title/Title';
import Text from 'components/common/typeography/text/Text';
import {Trans} from 'lib/i18n';
import {useAuthenticated} from 'hooks/authenticate';
import useStyles from './PublicDisclaimerStyles';
import Item from 'components/common/layout/item/Item';
import Container from 'components/common/layout/container/Container';
import {useSelector} from 'react-redux';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';
import clsx from 'clsx';

function PublicDisclaimer(props) {
  const {children} = props;
  const {BorrowerProfileId} = useSelector(getSelectedIllustration);
  const {isAuthenticated} = useAuthenticated();
  const classes = useStyles();

  const showPublicDisclaimer = useMemo(
    () => !isAuthenticated && !BorrowerProfileId,
    [isAuthenticated, BorrowerProfileId],
  );

  return (
    <Container className={clsx('fullWidth', classes.clearElementsMargins)}>
      <Item xs={showPublicDisclaimer ? 12 : 0} className={clsx(classes.publicDisclaimer)}>
        <Title className={classes.publicDisclaimerTypographyTitle} variant="h4">
          <Trans i18nKey="illustratorTranslation:borrower story start.public disclaimer.title" />
        </Title>
        <Text className={classes.publicDisclaimerTypography}>
          <Trans i18nKey="illustratorTranslation:borrower story start.public disclaimer.financial tool" />
        </Text>
        <Text className={classes.publicDisclaimerTypography}>
          <Trans i18nKey="illustratorTranslation:borrower story start.public disclaimer.the program" />
        </Text>
        <Text className={classes.publicDisclaimerTypography}>
          <Trans i18nKey="illustratorTranslation:borrower story start.public disclaimer.quick calculation" />
        </Text>
      </Item>

      <Item xs={12} className={clsx(classes.clearElementsMargins, classes.publicDisclaimerChildren)}>
        {children}
      </Item>
    </Container>
  );
}

PublicDisclaimer.propTypes = {};

export default PublicDisclaimer;
