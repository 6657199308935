import useStyles from './DemographicInfoObservationsFormSectionStyles';
import {useTranslation} from 'lib/i18n';
import Item from 'components/common/layout/item/Item';
import Title from 'components/common/typeography/title/Title';
import Container from 'components/common/layout/container/Container';
import React, {useMemo} from 'react';
import {useDemographicInformationOptions} from 'hooks/link-application/borrower-info/demographic-information-source-options';
import {useWatchObject} from 'lib/react-hook-form/use-watch';
import Switch3States from 'components/common/form/switch-3-states/Switch3States';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import {TableCell, Typography} from '@material-ui/core';
import Switch3StatesElementErrors from 'components/common/form/switch-3-states/components/Switch3StatesElementErrors';
import useQuestionsOptions from 'hooks/link-application/interview-and-demographic-info/observations-questions';
import clsx from 'clsx';

/**
 *
 * Demographic form "Observations" section
 *
 */

function DemographicInfoObservationsFormSection(props) {
  const {
    prefix = '', // !!! the prefix prop is required !!!
  } = props;

  const classes = useStyles();
  const {t} = useTranslation();

  const questions = useQuestionsOptions();

  const prefixForSectionHeader = useMemo(() => {
    return t(`translation:common.${prefix.toLowerCase()}`);
  }, [prefix, t]);

  // required param
  const {defaultOption} = useDemographicInformationOptions();

  const {demographicInformationSource} = useWatchObject({name: 'demographicInformationSource'});
  const shouldRender = useMemo(
    () => demographicInformationSource === defaultOption,
    [demographicInformationSource, defaultOption],
  );

  if (!shouldRender) return null;

  return (
    <Container>
      <Item xs={12} className={classes.noPadding}>
        <Title variant="h3">
          {prefixForSectionHeader} {t('demographicInfoFormTranslation:observations')}
        </Title>
        {t('demographicInfoFormTranslation:for applications taken in person')}
      </Item>

      {/* content / start */}
      <Item xs={12} className={classes.noPadding}>
        <Container>
          <Item xs={12}>
            <Table className={classes.table} aria-label="caption table">
              <TableBody>
                {questions.map((question, index) => (
                  <TableRow key={index}>
                    <TableCell align="center" className={clsx(classes.tableCell)}>
                      <Switch3States name={`${prefix}${question.value}`} />
                    </TableCell>

                    <TableCell align="left" className={classes.tableCell}>
                      <Typography variant="subtitle2">
                        {t(`inputsTranslation:${prefix}${question.value}.label`)}
                      </Typography>

                      <Switch3StatesElementErrors name={`${prefix}${question.value}`} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Item>
        </Container>
      </Item>
      {/* content / stop */}
    </Container>
  );
}

DemographicInfoObservationsFormSection.propTypes = {};

DemographicInfoObservationsFormSection.defaultValues = {};

export default DemographicInfoObservationsFormSection;
