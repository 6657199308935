import React from 'react';
import {NumericFormat as NumberFormat} from 'react-number-format';
import {useOnChangeInterceptor} from 'components/common/form/input/components/OnChangeInterceptor';

function ZipCode(props) {
  const {inputRef, onChange, ...other} = props;

  const {doChange} = useOnChangeInterceptor({onChange, getValue: (event) => event.target.value});

  return <NumberFormat {...other} getInputRef={inputRef} onChange={doChange} valueIsNumericString allowLeadingZeros />;
}

ZipCode.propTypes = {};

export default ZipCode;
