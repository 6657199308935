import {axios} from './api';

export default class RateServiceApi {
  static baseUrl = '/RateService';

  static getHECMRates = (config) => {
    return axios.get(`${this.baseUrl}/Rates`, config);
  };

  static getDefaultFixedRate = (config) => {
    return axios.get(`${this.baseUrl}/FixedRates/Default`, config);
  };

  static getFixedRate = (config) => {
    return axios.get(`${this.baseUrl}/FixedRates`, config);
  };

  static getHomeSafeMetrics = (config) => {
    return axios.get(`${this.baseUrl}/HomeSafeMetrics`, {
      ...config,
      params: {...config?.params, Active: true},
    });
  };

  static getHomeSafeMetricsByName = (Name, config) => {
    return axios.get(`${this.baseUrl}/HomeSafeMetrics/ByName`, {
      ...config,
      params: {Name, ...config?.params},
    });
  };

  static getDefaultHomeSafeStandardTier = (config) => {
    return axios.get(`${this.baseUrl}/HomeSafeStandardTiers/Default`, config);
  };

  static getHomeSafeStandardTiers = (params) => {
    return axios.get(`${this.baseUrl}/HomeSafeStandardTiers`, {params});
  };

  static getHomeSafeSelectRates = (config) => {
    return axios.get(`${this.baseUrl}/HomeSafeSelectRates`, config);
  };

  static getHybridMetricLatest = (config) => {
    return axios.get(`${this.baseUrl}/HybridMetrics/Latest`, config);
  };

  static getForwardRefiMetricLatest = (config) => {
    return axios.get(`${this.baseUrl}/ForwardRefiMetrics/Latest`, config);
  };
}
