import React, {useMemo} from 'react';
import InRangeSelect from 'components/common/form/select/InRangeSelect';
import {formatPercent} from 'lib/currency';
import {useProductContext} from 'contexts/product-context';

SelectRatesSelect.propTypes = {};

function SelectRatesSelect(props) {
  const {defaultValue: defValue, ...rest} = props;
  const {productQuery: {tiers = [], defaultTier} = {}} = useProductContext();

  const defaultValue = useMemo(() => defValue ?? defaultTier?.Rate, [defValue, defaultTier?.Rate]);

  const data = useMemo(
    () =>
      tiers.map((Rate) => ({
        value: Rate.Rate,
        label: formatPercent(Rate.Rate),
        selected: Rate.Rate === defaultValue,
      })),
    [tiers, defaultValue],
  );

  return <InRangeSelect {...rest} options={data} defaultValue={defaultValue} />;
}

export default SelectRatesSelect;
