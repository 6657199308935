import {generatePath as rrdGeneratePath} from 'react-router-dom';

export function generatePath(path, params) {
  return rrdGeneratePath(path, params);
}

export function generateSafePath(path, params) {
  try {
    return rrdGeneratePath(path, params);
  } catch (e) {
    return null;
  }
}
