import React from 'react';
import {API_ROUTES} from 'constants/illustrator';
import Annual from 'components/products/annual';
import Fixed from 'components/products/fixed';
import Flex from 'components/products/flex';
import Select from 'components/products/select';
import Tier from 'components/products/tier';
import ForwardRefi from 'components/products/forwardrefi';
import Hybrid from 'components/products/hybrid';
import ExistingMortgage from 'components/products/existing-mortgage';
import {ProductContextProvider} from 'contexts/product-context';
import Second from 'components/products/second';

/**
 *
 * This is a component where data regarding the "product" gets centralized.
 *
 * This is where the "product context" gets returned.
 *
 */

const Product = React.forwardRef(function ProductRender(props, productRef) {
  const {product: {ApiRoute} = {}} = props;

  switch (ApiRoute) {
    case API_ROUTES.ANNUAL: {
      return <Annual {...props} ref={productRef} />;
    }
    case API_ROUTES.FIXED: {
      return <Fixed {...props} ref={productRef} />;
    }
    case API_ROUTES.FLEX: {
      return <Flex {...props} ref={productRef} />;
    }
    case API_ROUTES.SELECT: {
      return <Select {...props} ref={productRef} />;
    }
    case API_ROUTES.TIER: {
      return <Tier {...props} ref={productRef} />;
    }
    case API_ROUTES.SECOND: {
      return <Second {...props} ref={productRef} />;
    }
    case API_ROUTES.FORWARDREFI: {
      return <ForwardRefi {...props} ref={productRef} />;
    }
    case API_ROUTES.HYBRID: {
      return <Hybrid {...props} ref={productRef} />;
    }
    case API_ROUTES.EXISTING_MORTGAGE: {
      return <ExistingMortgage {...props} ref={productRef} />;
    }
    default: {
      return <ProductContextProvider {...props} ref={productRef} />;
    }
  }
});

export default Product;
