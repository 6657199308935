import React from 'react';
import Title from 'components/common/typeography/title/Title';
import Item from 'components/common/layout/item/Item';
import Container from 'components/common/layout/container/Container';
import Input from 'components/common/form/input/Input';
import Select from 'components/common/form/select/Select';
import {useUSStates} from 'hooks/us-states';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'lib/i18n';

function MailAddressFormElements() {
  const {t} = useTranslation();
  const {watch} = useFormContext();
  const flagMailAddressAnswer = watch('flagMailAddress'); 

  const {data: usStates = []} = useUSStates();

  return (
    <Container hide={flagMailAddressAnswer !== 'false'}>
      <Item xs={12}>
        <Title variant="h5" style={{textAlign: 'left'}}>
          {t('illustratorTranslation:illustrator borrower mailing address.section')}
        </Title>
      </Item>
      <Item xs={12} sm={6}>
        <Input name="mailingAddress" variant="filled" />
      </Item>
      <Item xs={12} sm={6}>
        <Input name="mailingAddress2" variant="filled" />
      </Item>

      <Item xs={12} sm={6}>
        <Input name="mailingCity" variant="filled" />
      </Item>
      <Item xs={12} sm={6}>
        <Select
          variant="filled"
          name="mailingState"
          options={usStates?.map((State) => ({
            label: State.Name,
            value: State.Abbreviation,
          }))}
        />
      </Item>

      <Item xs={12} sm={6}>
        <Input name="mailingZipCode" variant="filled" type="number" />
      </Item>

      <Item sm={3} />
    </Container>
  );
}

export default MailAddressFormElements;