import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    breakdownTitleContainer: {
      textAlign: 'left',
    },
    breakdownTitle: {
      marginBottom: theme.spacing(1),
    },
    breakdownDescription: {
      marginBottom: theme.spacing(5),
    },
  }),
  {index: 1},
);

export default useStyles;
