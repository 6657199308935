import React, {useMemo} from 'react';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import useStyles from './ContentSectionStyles';
import Title from 'components/common/typeography/title/Title';
import clsx from 'clsx';
import Disclaimer from 'components/common/disclaimer/Disclaimer';
import {usePrintContext} from 'contexts/print-context';
import {useChartAspect, usePrintingValue} from 'hooks/utils';
import ResponsiveContainer from 'components/common/recharts/ResponsiveContainer';

const ContentSection = React.forwardRef(function ContentSectionRender(props, ref) {
  const {children, chart, component: Component = Container, chartProps = {}, ...rest} = props;
  const classes = useStyles();

  const {
    component: ChartContainer = Container,
    chartTitle,
    className,
    chartDisclaimer,
    chartDisclaimerClassName,
    ...chartPropsRest
  } = chartProps;

  const {printing} = usePrintContext();

  const childrenContainer = usePrintingValue({xs: 12, sm: 4, md: 4, xl: 3}, {xs: 12, sm: 6, md: 4, xl: 4});
  const chartContainer = usePrintingValue({xs: 12, sm: 6, md: 5, xl: 4}, {xs: 12, sm: 5, md: 5, xl: 5});
  const marginContainer = usePrintingValue({xs: 0, md: 1, xl: 2}, {xs: 0, sm: 1, xl: 1});

  const childrenCount = useMemo(() => React.Children.count(children), [children]);
  const {chartAspect} = useChartAspect(2);

  return (
    <Component {...rest} className={clsx(classes.rootContainer, {[classes.fullPage]: childrenCount === 1})} ref={ref}>
      <Item {...marginContainer} />
      <Item {...childrenContainer} className={classes.leftSection}>
        {React.Children.map(children, (child) => React.cloneElement(child, {...rest}))}
      </Item>
      <Item xs={0} sm={1} md={1} xl={1} />
      <Item className={classes.chartWrapper} {...chartContainer}>
        <ChartContainer
          className={clsx(classes.chartContainer, className, {[classes.animatedChartContainer]: childrenCount > 1})}
          {...chartPropsRest}
        >
          {chartTitle && (
            <Title className={clsx(classes.chartTitle)} variant="h6">
              {chartTitle}
            </Title>
          )}

          {chart && (
            <Item xs={12}>
              <ResponsiveContainer
                className={clsx(classes.chartResponsiveContainer)}
                aspect={chartAspect}
                minHeight={400}
              >
                {chart}
              </ResponsiveContainer>
            </Item>
          )}

          {chartDisclaimer && (
            <>
              <Item xs={printing ? 2 : 0} />
              <Item xs={printing ? 10 : 12}>
                <Disclaimer className={chartDisclaimerClassName}>{chartDisclaimer}</Disclaimer>
              </Item>
            </>
          )}
        </ChartContainer>
      </Item>
      <Item {...marginContainer} />
    </Component>
  );
});

ContentSection.propTypes = {};

export default ContentSection;
