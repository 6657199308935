import React, {useCallback} from 'react';
import {useIllustrationContext} from 'contexts/illustration-context';
import {withProduct} from 'components/products/product-hoc';
import {productCalculationQueryKey} from 'constants/query';
import IllustratorBreakdownComp from './components/IllustratatorBreakdownComp';

const IllustratorBreakdownProduct = withProduct(IllustratorBreakdownComp);

/**
 *
 * The "Illustrator Breakdown" main component.
 *
 */

function IllustratorBreakdown(props) {
  const illustration = useIllustrationContext();

  const isSelectedProduct = useCallback(
    (product) => product.ApiRoute === illustration.ApiRoute,
    [illustration.ApiRoute],
  );

  return (
    <IllustratorBreakdownProduct
      {...props}
      isSelectedProduct={isSelectedProduct}
      queryKey={[productCalculationQueryKey, illustration.ApiRoute]}
      fetching
    />
  );
}

export default React.memo(IllustratorBreakdown);
