import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    inputSection: {
      marginTop: theme.spacing(1.5),
    },
    width65px: {
      width: '65px',
    },
  }),
  {index: 1},
);

export default useStyles;
