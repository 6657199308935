import {makeStyles} from '@material-ui/core/styles';
import {isXlSizeAppQueryUp} from 'lib/app-theme';
import {fontWeightMedium, fontWeight600} from 'lib/mui-config';
import {isMobileAppQuery, isSmSizeAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    fixedContainer: {
      width: '100%',
      margin: 'auto',
      display: theme.forPrint('block'),
    },
    paymentBreakdownContainer: {
      display: 'flex',
    },
    root: {
      flexGrow: theme.withPrint(1),
      textAlign: 'left',
    },
    ProductNameTitle: {
      marginTop: theme.spacing(1),
    },
    paymentBreakdownLoaderContainer: {
      margin: theme.spacing(1.5),
    },
    paymentBreakdownListElement: {
      width: '100%',
      margin: 'auto',
      padding: 0,
      display: theme.withPrint('block', 'flex'),
      [isXlSizeAppQueryUp(theme)]: {
        display: 'flex',
      },
    },
    p2: {
      padding: theme.spacing(2, 3),
    },
    m2: {
      margin: theme.spacing(2),
    },
    p0p3: {
      padding: theme.spacing(0, 3),
    },
    content: {
      backgroundColor: theme.palette.white.light,
      marginTop: theme.spacing(1),
      display: theme.withPrint('flex', 'block'),
    },
    paper: {
      padding: theme.spacing(2),
      marginRight: `${theme.spacing(0.5)}px !important`,
    },
    hidden: {
      display: 'none',
    },
    widthMarginTop: {
      marginTop: theme.spacing(1),
    },
    noWrap: {
      flexWrap: 'nowrap',
    },
    paymentBreakdown: {
      padding: theme.spacing(2, 2),
    },
    avatarContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    avatarIcon: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    text2left: {
      textAlign: 'left',
    },
    text2right: {
      textAlign: 'right',
    },
    hSpacing: {
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
    },
    brokerNotesDescription: {
      margin: '30px',
      //fontFamily: 'Gotham',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '22px',
      lineHeight: '34px',
      alignItems: 'center',
      color: theme.palette.borrower.dark,
    },
    borderBottom: {
      borderBottom: '1px solid #eee',
    },
    illustratorChart: {
      display: theme.withPrint('inherit', 'none !important'),
    },
    outer: {
      position: 'relative',
      height: '100%',
    },
    inner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
    },
    rotate: {
      transform: 'translateX(-50%) translateY(-50%) rotate(-90deg)',
    },
    printContainer: {
      display: theme.forPrint('block'),
    },
    forPrint: {
      pageBreakInside: theme.forPrint('avoid'),
      pageBreakAfter: theme.forPrint('auto'),
      pageBreakBefore: theme.forPrint('auto'),
    },
    printoutPDFAmortizationTable: {
      marginLeft: theme.forPrint(theme.spacing(4)),
    },
  }),
  {index: 1},
);

export default useStyles;
