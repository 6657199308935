import 'symbol-observable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import './index.css';

import 'lib/register-icons';
import 'lib/i18n';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AutoLogin from 'AutoLogin';
import Providers from 'providers/Providers';
import VersionControl from 'components/common/version-control/VersionControl';
import TermsOfService from 'components/terms-of-service/TermsOfService';
import ContentManagementSystem from 'components/common/content-management-system/ContentManagementSystem';
import {APP_ROUTES, ROUTES} from 'constants/routes';
import LoginCallback from 'components/login-callback/LoginCallback';
import AppEquityAvail from 'components/app/App-equity-avail';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {PRINT_TYPE} from 'constants/illustrator';
import AppWidgets from 'components/app/App-widgets';
import App from 'components/app/App';
import InactivityControl from 'components/inactivity-control/InactivityControl';

ReactDOM.render(
  <Router>
    <AutoLogin>
      <Providers>
        <VersionControl />
        <TermsOfService />
        <ContentManagementSystem />
        <InactivityControl />

        <Switch>
          <Route path={APP_ROUTES.LOGIN_CALLBACK} render={() => <LoginCallback />} />
          <Route
            path={APP_ROUTES.FAR_PAGES}
            render={() => (
              <AppEquityAvail
                route={ROUTES.REVERSE_EQUITY_AVAIL_PAGE}
                pdfRoute={ROUTES.REVERSE_EQUITY_AVAIL_PDF_PAGE}
                defaultNS="farEquityavailTranslation"
                productFeatureKey={PRODUCT_FEATURE_KEY.FAR_EQUITYAVAIL_PAGE}
                productFeatureRedirectPath={ROUTES.HOME_PAGE}
                printType={PRINT_TYPE.CONSUMER_FACING}
              />
            )}
          />
          <Route
            path={APP_ROUTES.EQUITY_AVAIL}
            render={() => (
              <AppEquityAvail
                featureRoute
                route={ROUTES.ILLUSTRATOR_HYBRID_PAGE}
                pdfRoute={ROUTES.ILLUSTRATOR_HYBRID_PDF_PAGE}
                defaultNS="equityavailTranslation"
                productFeatureKey={PRODUCT_FEATURE_KEY.STANDALONE_PAGE}
                productFeatureRedirectPath={ROUTES.HOME_PAGE}
                printType={PRINT_TYPE.HYBRID}
              />
            )}
          />

          <Route path={APP_ROUTES.WIDGET_PAGES} component={AppWidgets} />
          <Route path={APP_ROUTES.ROOT} component={App} />
        </Switch>
      </Providers>
    </AutoLogin>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
