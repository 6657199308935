import {useUSStatesQuery} from 'query/us-states';
import {statusToLoading} from 'adapter/loading.adapter';

export function useUSStates() {
  const {data: usStates, status, isFetching} = useUSStatesQuery();

  const loading = statusToLoading(status, isFetching);

  return {data: usStates || [], loading};
}
