import React from 'react';
import {MuiThemeProvider} from '@material-ui/core';
import {theme as defaultTheme} from 'lib/mui-config';

ThemeProviders.propTypes = {};

function ThemeProviders(props) {
  const {children, theme = defaultTheme} = props;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}

export default ThemeProviders;

export function withThemeProvider(Component, options = {}) {
  const {theme = defaultTheme} = options;

  return function WithThemeProviderRender(props) {
    return (
      <ThemeProviders theme={theme}>
        <Component {...props} />
      </ThemeProviders>
    );
  };
}
