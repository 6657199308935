import {useDispatch} from 'react-redux';
import {useRedirectHistory} from 'hooks/history';
import {useCallback} from 'react';
import {RESET_STORAGE_ACTION} from 'lib/store';
import {ROUTES} from 'constants/routes';
import {oktaAuth} from 'services/auth.service';
import {LoggerService} from 'services/logger.service';
import {useMediaQuery} from '@material-ui/core';
import {isMobileAppQuery} from 'lib/app-theme';

export function useLogout() {
  const dispatch = useDispatch();
  const {redirect} = useRedirectHistory();

  const logout = useCallback(() => {
    dispatch(RESET_STORAGE_ACTION);

    redirect(ROUTES.HOST_HOME_PAGE);
  }, [redirect, dispatch]);

  const doLogout = useCallback(async () => {
    try {
      await oktaAuth.signOut({
        postLogoutRedirectUri: '/',
      });
    } catch (error) {
      LoggerService.error('doLogout', error);
    } finally {
      logout();
    }
  }, [logout]);

  return {logout, doLogout};
}

export function useLogoutNavList() {
  const {doLogout} = useLogout();

  const isMobileApp = useMediaQuery(isMobileAppQuery);

  return {doLogout, isMobileApp};
}
