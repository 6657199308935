import React from 'react';
import Container from 'components/common/layout/container/Container';
import clsx from 'clsx';
import Item from 'components/common/layout/item/Item';
import IllustratorHeaderTitle from 'components/illustrator/illustrator-header/IllustratorHeaderTitle';
import useStyles from './StepElementStyles';

function StepElement(props) {
  const {title, subTitle = '', children, ...rest} = props;

  const classes = useStyles();

  return (
    <Container className={clsx(classes.stepElementContainer)} {...rest}>
      <Item xs={0} md={1} lg={2} xl={3} />
      <Item xs={12} md={10} lg={8} xl={6}>
        <Container>
          <Item xs={12}>
            <IllustratorHeaderTitle title={title} />
          </Item>
          {subTitle.length ? (
            <Item xs={12} className={classes.noSpaceForTop}>
              <IllustratorHeaderTitle subTitle={subTitle} variant="h6" />
            </Item>
          ) : null}
          <Item xs={12}>{children}</Item>
        </Container>
      </Item>
      <Item xs={0} md={1} lg={2} xl={3} />
    </Container>
  );
}

export default StepElement;
