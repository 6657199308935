import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    dialogHeaderBox: {
      margin: theme.spacing(0.5, 0.5, 0, 0.5),
      position: 'sticky',
    },
    dialogHeaderBoxButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing('auto', -5),
      position: 'relative',
    },
    dialogHeaderBoxButton: {
      justifyContent: 'flex-end',
    },
    dialogHeaderMargin: {
      marginTop: theme.spacing(2.5),
    },
    dialogHeaderChildrenContainer: {
      margin: theme.spacing(0, 3, 2),
      textAlign: 'center',
    },
    dialogHeaderChildrenContainerNoMargin: {
      margin: theme.spacing(0),
    },
  }),
  {index: 1},
);

export default useStyles;
