import {axios} from 'api/api';

export default class BorrowerProfileServiceApi {
  static baseUrl = '/borrowerProfiles';

  static async getBorrowerProfileById(BorrowerProfileId, config) {
    return axios.get(`${this.baseUrl}/${BorrowerProfileId}`, config);
  }

  static async getSavedBorrowerProfiles(brokerId, config) {
    return axios.get(`${this.baseUrl}/`, config);
  }

  static async saveBorrowerProfile(BorrowerProfile, config) {
    return axios.post(`${this.baseUrl}/`, BorrowerProfile, config);
  }

  static async updateBorrowerProfile(BorrowerProfile, config) {
    return axios.put(`${this.baseUrl}/${BorrowerProfile.BorrowerProfileId}`, BorrowerProfile, config);
  }

  static async deleteSavedBorrowerProfiles(BorrowerProfileIds, config) {
    config = config || {};
    config.params = config.params || {};
    config.params = new URLSearchParams();

    for (const id of BorrowerProfileIds) {
      config.params.append('ids', id);
    }

    const data = [{op: 'add', path: '/IsSaved', value: false}];

    return axios.patch(`${this.baseUrl}`, data, config);
  }
}
