import React, {useCallback, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import Item from 'components/common/layout/item/Item';
import Button from 'components/common/button/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useStyles from './IllustratorHeaderStyles';
import {useHeaderContext} from 'contexts/header-context';
import Container from 'components/common/layout/container/Container';
import clsx from 'clsx';
import ProgressBar from 'components/common/progress-bar/ProgressBar';
import {useHistory} from 'react-router-dom';

export function useHeaderTitle(props) {
  const {title, backButton} = props;
  const {setHeaderProps, resetHeaderProps} = useHeaderContext();

  useEffect(() => {
    setHeaderProps({
      title,
      backButton,
    });

    return () => {
      resetHeaderProps();
    };
  }, [title, backButton, setHeaderProps, resetHeaderProps]);
}

/**
 *
 * V2 Illustrator Header
 *
 * Elements :
 *
 *  EXIT or BACK button
 *  &&
 *  Progress Bar
 *
 * This component is hidden on every 1st page within any step wizard where it is used.
 *
 */

function IllustratorHeader(props) {
  const {totalSteps = 1, currentStep = 0, responsive = {}, showProgress = (e) => true, backButton, exitButton} = props;

  const classes = useStyles();
  const history = useHistory();

  // progress value as %
  const value = useMemo(() => ((currentStep - 1) / totalSteps) * 100, [totalSteps, currentStep]);

  const goBack = useCallback(
    (event) => {
      event.preventDefault();

      history.goBack();
    },
    [history],
  );

  // responsive stuff
  const {item, content} = useMemo(() => {
    return responsive[currentStep] || {item: {xs: 0, sm: 1, md: 2, xl: 3}, content: {xs: 12, sm: 10, md: 8, xl: 6}};
  }, [responsive, currentStep]);

  const withProgress = useMemo(() => showProgress({...props, value}), [showProgress, props, value]);

  return (
    <Container className={clsx(classes.fullWidth)}>
      <Item {...item} />
      <Item {...content} className={clsx(classes.illustratorHeaderButtonContainer, classes.progressBarContainer)}>
        <Button
          onClick={goBack}
          className={clsx(classes.roundedButton, classes.exitButton, {[classes.hide]: !backButton})}
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={['fa', 'arrow-left']} />}
          data-testid="illustrator-header-back-button"
        >
          {currentStep === 1 ? exitButton : backButton}
        </Button>

        <ProgressBar value={withProgress ? value : undefined} rounded />
      </Item>

      <Item {...item} />
    </Container>
  );
}

IllustratorHeader.propTypes = {
  title: PropTypes.any,
  backButton: PropTypes.any,
  value: PropTypes.any,
  doExit: PropTypes.func,
};

export default IllustratorHeader;
