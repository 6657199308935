import {makeStyles} from '@material-ui/core/styles';
import {isTabletAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    fullPage: {
      minHeight: theme.withPrint('75vh', 'unset !important'),
      margin: theme.forPrint('auto !important'),
      [isTabletAppQuery(theme)]: {
        minHeight: '50vh',
      },
    },
    flexContainer: {
      display: 'flex',
    },
    rootContainer: {
      width: '100%',
      margin: 'auto',
      [isTabletAppQuery(theme)]: {
        margin: theme.spacing(1),
      },
      flexWrap: theme.forPrint('nowrap !important'),
      pageBreakInside: theme.forPrint('avoid'),
      pageBreakAfter: theme.forPrint('auto'),
    },
    noWrapPrint: {
      pageBreakInside: theme.forPrint('auto !important'),
      flexWrap: theme.forPrint('wrap !important'),
    },
    leftSection: {
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      pageBreakInside: theme.forPrint('avoid'),
      pageBreakAfter: theme.forPrint('auto'),
    },
    hidePrint: {
      display: theme.forPrint('none'),
    },
    chartTitle: {
      margin: 'auto',
      padding: theme.forPrint(theme.spacing(0, 0, 0, 15)),
    },
    rightSection: {
      display: 'flex',
      pageBreakInside: theme.forPrint('avoid'),
      pageBreakAfter: theme.forPrint('auto'),
    },
    descriptionContainer: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    chartWrapper: {
      display: 'flex',
    },
    chartContainer: {
      margin: 'auto',
      '& svg': {
        overflow: 'visible',
      },
    },
    animatedChartContainer: {
      marginTop: 'unset',
      position: 'sticky',
      transition: 'opacity 1s linear',
      top: theme.withPrint('40%', 0),
    },
    descriptionText: {
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    chartResponsiveContainer: {
      maxWidth: '99%',
      '& .recharts-surface': {
        overflow: 'visible',
      },

      '& .recharts-wrapper': {
        margin: 'auto',
      },
      marginLeft: theme.forPrint('auto'),
      marginRight: theme.forPrint('auto'),

      '& .recharts-legend-wrapper': {
        bottom: `${theme.withPrint(theme.spacing(-4), theme.spacing(0))}px !important`,
        left: theme.forPrint('60px !important'),
        paddingBottom: theme.forPrint('0 !important'),
        position: theme.forPrint('relative !important'),
        width: theme.forPrint('100% !important'),
      },
    },
  }),
  {index: 1},
);

export default useStyles;
