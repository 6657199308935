import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    verticalAlignTop: {
      verticalAlign: 'top',
    },
    table: {
      margin: '0 auto',
      border: 'none ',
      borderCollapse: 'collapse',

      '& td, & th': {
        border: 'none',
      },
    },
    customInputElement: {
      '& .MuiFormLabel-root': {
        fontSize: '0.875rem',
      },
      '& .MuiInputLabel-filled': {
        transform: 'translate(0, 24px) scale(1)',
      },
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        transform: 'translate(12px, 10px) scale(0.75)',
      },
    },
    customSwitchElementContainer: {
      '& .MuiFormGroup-root': {
        display: 'block',
      },
    },
  }),
  {index: 1},
);

export default useStyles;
