import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {ToString} from 'lib/utils';
import Text from 'components/common/typeography/text/Text';
import clsx from 'clsx';

QuickFillPrintTable.propTypes = {};

function QuickFillPrintTable(props) {
  const {className, columns, fields} = props;

  return (
    <TableContainer className={className}>
      <Table>
        <TableHead>
          <TableRow>
            {columns?.map((col, index) => {
              const maxVal = fields?.reduce((prev, current) => {
                const pValue = col.formatter ? col.formatter({row: prev}) : prev[col.key];
                const cValue = col.formatter ? col.formatter({row: current}) : current[col.key];

                return cValue > pValue ? current : prev;
              }, {});

              return (
                <TableCell
                  align="center"
                  valign="middle"
                  width={ToString(maxVal?.[col.key], col.name).length}
                  key={index}
                  className={clsx(col.headerCellClass)}
                >
                  <Text variant="subtitle2">{col.name}</Text>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields?.map((field, index) => (
            <TableRow key={index}>
              {columns?.map((col, index) => {
                const value = col.formatter ? col.formatter({row: field}) : field[col.key];

                return (
                  <TableCell align="center" valign="middle" width={value?.length} key={index}>
                    <Text variant="subtitle2">{value}</Text>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default QuickFillPrintTable;
