import React from 'react';
import {ProductContextProvider} from 'contexts/product-context';
import {useExistingMortgageHook} from 'components/products/existing-mortgage/ExistingMortgageHook';
import {API_ROUTES} from 'constants/illustrator';
import {useProductWithRef} from 'hooks/product-ref';

const ExistingMortgage = React.forwardRef(function ExistingMortgageRender(props, ref) {
  const {product, setRef, ...rest} = props;

  const existingMortgageHook = useExistingMortgageHook({product});

  useProductWithRef({productDetails: existingMortgageHook, ref, ApiRoute: API_ROUTES.EXISTING_MORTGAGE});

  return <ProductContextProvider ref={ref} productDetails={existingMortgageHook} product={product} {...rest} />;
});

export default ExistingMortgage;
