import {useCallback, useMemo} from 'react';
import {cleanUpIllustration, getSelectedIllustration} from 'reducers/illustrator.reducer';
import {useSaveBorrowerInfoMutation} from 'query/borrower/save-borrower-info';
import {useUpdateIllustrations} from 'hooks/illustrator-form';
import {useSelector} from 'react-redux';
import {isFunction, noop} from 'lodash';
import {CALCULATION_CONTAINER} from 'constants/illustrator';
import {useProductFeature} from 'hooks/product-features';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {excludeForwardRefi} from 'lib/product';
import {useDeepMemo} from 'hooks/utils';
import {trackEvent} from '../services/analytics.service';
import {SCENARIO_CATEGORIES, SCENARIO_ACTIONS} from '../constants/analytic-events';

export function useUpdateBorrowerProfile(props) {
  const {hasSubmitted} = props;
  const {defaultValues: moduleDefaultValues = {}, onSuccess = noop} = props;
  const {ApiRoute, BorrowerProfile, BorrowerProfileId} = useSelector(getSelectedIllustration);

  const saveBorrowerInfoMutation = useSaveBorrowerInfoMutation({
    async onSuccess(response) {
      await updateIllustrations({
        changes: (illustration) => cleanUpIllustration(illustration),
      });

      await updateIllustrationsBorrowerProfile({old: BorrowerProfileId, new: response.data});

      const element = document.getElementById(CALCULATION_CONTAINER);

      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'nearest'});
      }

      if (isFunction(onSuccess)) await onSuccess();
    },
  });

  const {updateIllustrationsBorrowerProfile, updateIllustrations} = useUpdateIllustrations();

  /**
   *
   * onSubmit() "data" that gets sent : {
   *
   *   ApiRoute : "product_name_goes_here"
   *   BorrowerProfile : {
   *
   *     Address : "address_goes_here"
   *     Age : 63
   *
   *     BorrowerPreference : {
   *
   *       AdditionalBorrowerPreferences : [
   *         ...
   *       ]
   *
   *       BorrowerPreferenceId : 26906
   *
   *       WithdrawalMethod : {
   *
   *         Enabled : true
   *
   *         IsDefault : true
   *
   *         SysEndDate : "0001-01-01T00:00:00"
   *         SysStartDate : "0001-01-01T00:00:00"
   *
   *         WithdrawalMethodDescription : "Lump Sum Only"
   *         WithdrawalMethodId : 1
   *         WithdrawalMethodKey : "lumpsum"
   *
   *       }
   *       WithdrawalMethodId : 1
   *
   *     }
   *     BorrowerPreferenceId : 26910
   *     BorrowerProfileId : "hash_goes_here"
   *
   *     DateOfBirth : "1960-01-01T00:00:00"
   *     DateSaved : "2022-11-25T08:18:27.1366667"
   *
   *     Email : "email_goes_here"
   *     ExistingMortgage : {
   *
   *       ExistingMortgageId : 136589
   *       ExistingMortgageRemainingBalance : 0
   *
   *       HomeownersInsurancePremium : ""
   *
   *       InsurancePremiumPaymentTypeId : null
   *
   *       MortgagePayment : ""
   *       MortgageRate : ""
   *
   *       PropertyTaxPayment : ""
   *       PropertyTaxPaymentTypeId : null
   *
   *       TaxesAndInsuranceReserve : null
   *
   *       YearsRemaining : ""
   *
   *     }
   *     ExistingMortgageId : "number_goes_here"
   *
   *     FirstLien : "first_lien_value_goes_here"
   *     FirstName : "first_name_goes_here"
   *
   *     IsSaved : boolean_value_goes_here
   *
   *     LastName : "last_name_goes_here"
   *     Liens : "450000"
   *
   *     NumberOfUnits : "number_of_units_goes_here"
   *
   *     OtherLiens : "other_mortgages_value_goes_here"
   *     OwnerUserId : "hash_goes_here"
   *
   *     State : "CA"
   *
   *     ValueOfProperty : "500000"
   *
   *     Zip : "90210"
   *
   *   }
   *
   * }
   *
   */

  const onSubmit = useCallback(
    async (data = {}) => {
      if (!hasSubmitted) {
        trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_UPDATE_RESULTS);
      } else {
        trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_UPDATE_PAY_BREAK_TOP);
      }

      const {BorrowerProfile} = data;
      return saveBorrowerInfoMutation.mutateAsync({BorrowerProfile});
    },
    [hasSubmitted, saveBorrowerInfoMutation],
  );

  const defaultValues = useMemo(
    () => ({BorrowerProfile, ApiRoute, ...moduleDefaultValues}),
    [BorrowerProfile, ApiRoute, moduleDefaultValues],
  );

  return {onSubmit, defaultValues, loading: saveBorrowerInfoMutation.loading};
}

export function useBorrowerProducts() {
  const {filterEnabledProduct, products} = useProductFeature();

  const select = useCallback(
    (response) => {
      return response.data
        .filter(filterEnabledProduct(PRODUCT_FEATURE_KEY.APPLICATION_PRODUCT_DDL, response.data))
        .filter(excludeForwardRefi);
    },
    [filterEnabledProduct],
  );

  return {select, filterEnabledProduct, products};
}

export function useBorrowerStepRoute() {
  const {select, products = []} = useBorrowerProducts();

  const availableProducts = useDeepMemo(() => select({data: products}), [products, select]);

  return {availableProducts};
}
