import React, {useCallback, useMemo} from 'react';
import {Bar, BarChart as RBarChart, CartesianGrid, XAxis, YAxis} from 'recharts';
import {round} from 'lodash';
import CurrencyChartAxisTick from 'components/common/recharts/CurrencyChartAxisTick';
import {usePrintContext} from 'contexts/print-context';
import {useTabletApp} from 'lib/app-theme';

function isInvisible(visibility) {
  return visibility === 'hidden';
}

function BarChart(props) {
  const {data: moduleData, ...rest} = props;

  const domain = useMemo(() => [0, round(Math.max(...moduleData?.map((d) => d.y), 0), 1)], [moduleData]);
  const data = useMemo(() => moduleData?.filter((e) => !isInvisible(e.visibility)), [moduleData]);
  const isTabletApp = useTabletApp();

  const isVertical = useMemo(() => rest.layout === 'vertical', [rest.layout]);

  const verticalCondition = useCallback(
    (a, b) => {
      if (isVertical) return a;
      return b;
    },
    [isVertical],
  );

  const xAxisProps = useMemo(() => ({dataKey: 'name', type: 'category', tickMargin: 10}), []);

  const yAxisProps = useMemo(
    () => ({
      dataKey: 'y',
      type: 'number',
      domain,
      tickMargin: 20,
      tickCount: 6,
      scale: verticalCondition('linear', 'auto'),
      tick: <CurrencyChartAxisTick formatShort={isTabletApp} rotate={isVertical} />,
    }),
    [domain, isTabletApp, isVertical, verticalCondition],
  );

  const {printing} = usePrintContext();

  if (!data?.length) return null;

  return (
    <RBarChart data={data} {...rest}>
      <CartesianGrid strokeDasharray="4" />
      <XAxis {...verticalCondition(yAxisProps, xAxisProps)} />
      <YAxis {...verticalCondition(xAxisProps, yAxisProps)} />
      <Bar dataKey="y" isAnimationActive={!printing} />
    </RBarChart>
  );
}

BarChart.propTypes = {};

export default BarChart;
