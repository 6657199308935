import {axios} from './api';

/**
 *
 * This is the Service component for Link User License Validation.
 *
 * This feature needs to use the internal axios header.
 *
 */

export default class LinkUserLicenseValidation {
  static baseUrl = '/validations/link/userLicense';

  /**
   *
   * Request body will have the following properties:
   *
   * - email
   * - state
   *
   */

  static async validate({Email, State, Zip}, config) {
    return axios.post(`${this.baseUrl}?userEmail=${Email}&StateCode=${State}&Zip=${Zip}`, {}, config);
  }
}
