import React, {useCallback, useMemo} from 'react';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import Input from 'components/common/form/input/Input';
import Button from 'components/common/button/Button';
import {isPending} from 'adapter/loading.adapter';
import Form from 'components/common/form/Form';
import {useDispatch, useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower, updateSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import usePostLinkUserLicenseValidationMutation from 'query/link-user-license-validation/use-post-link-user-license-validation-mutation';
import {useStepWizardContext} from 'contexts/step-wizard';
import {useTranslation} from 'lib/i18n';
import yup from 'lib/validation';
import useStyles from './InterviewerFormStyles';
import InterviewerModalError from 'pages/illustrator-borrower/interviewer/components/InterviewerModalError';

const validationSchema = yup.object({
  thirdPartyLOInterviewerName: yup.string(),
  thirdPartyLOEmailInterviewerEmail: yup.string().email().required(),
  propertyZipCode: yup
    .string()
    .matches(/^$|^[0-9]+$/)
    .min(5)
    .max(9),
});

function InterviewerForm(props) {
  const {nextStep} = useStepWizardContext();
  const {t} = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();

  const illustratorBorrower = useSelector(getSelectedIllustratorBorrower);

  const postLinkUserLicenseValidationMutation = usePostLinkUserLicenseValidationMutation({
    onSuccess() {
      nextStep();
    },
  });

  /**
   *
   * The main submit method.
   *
   */

  const onSubmit = useCallback(
    async (data) => {
      let {thirdPartyLOInterviewerName, thirdPartyLOEmailInterviewerEmail, propertyZipCode} = data;

      let interviewPageData = {
        ...illustratorBorrower?.interviewPage,
        thirdPartyLOInterviewerName: thirdPartyLOInterviewerName,
        thirdPartyLOEmailInterviewerEmail: thirdPartyLOEmailInterviewerEmail,
      };

      let propertyPageData = {
        ...illustratorBorrower?.property,
        propertyZipCode: propertyZipCode,
      };

      /**
       *
       * No matter what happens, make sure that the data gets saved within the Redux Store !
       *
       * DO NOT TOUCH the `await dispatch` !!!
       *
       * DO NOT MOVE IT !!!
       *
       * Leave it here, at this level !!!
       *
       */

      await dispatch(
        updateSelectedIllustratorBorrower({
          interviewPage: interviewPageData,
          property: propertyPageData,
        }),
      );

      /**
       *
       * Before we redirect, we must validate the `Link User License`.
       *
       */

      postLinkUserLicenseValidationMutation.mutate({
        Email: thirdPartyLOEmailInterviewerEmail || '',
        State: illustratorBorrower?.property?.propertyState || '',
        Zip: propertyZipCode || '',
      });
    },
    [dispatch, postLinkUserLicenseValidationMutation, illustratorBorrower],
  );

  const defaultValues = useMemo(() => {
    return {
      thirdPartyLOInterviewerName: illustratorBorrower?.interviewPage?.thirdPartyLOInterviewerName || '',
      thirdPartyLOEmailInterviewerEmail: illustratorBorrower?.interviewPage?.thirdPartyLOEmailInterviewerEmail || '',
      propertyZipCode: illustratorBorrower?.property?.propertyZipCode || '',
    };
  }, [illustratorBorrower]);

  return (
    <>
      <InterviewerModalError
        open={!!postLinkUserLicenseValidationMutation.error}
        email={defaultValues.thirdPartyLOEmailInterviewerEmail}
        errorMessage={postLinkUserLicenseValidationMutation.error?.response?.data?.Message}
      />

      <Container
        className={classes.itemContainer}
        component={Form}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
      >
        <Item xs={12} md={6}>
          <Input name="thirdPartyLOInterviewerName" variant="filled" color="primary" />
        </Item>
        {/* 3rd party LO/Interviewer name / stop */}

        {/* 3rd party LO/Interviewer email / start */}
        <Item xs={12} md={6}>
          <Input name="thirdPartyLOEmailInterviewerEmail" variant="filled" />
        </Item>

        <Item xs={12} md={6}>
          <Input variant="filled" name="propertyZipCode" />
        </Item>

        <Item xs={12}>
          <Button
            disabled={isPending(postLinkUserLicenseValidationMutation)}
            showProgress
            btnStyle="bgElectric"
            buttonStyling={{lg: true}}
            type="submit"
          >
            {t('translation:common.next')}
          </Button>
        </Item>
      </Container>
    </>
  );
}

export default InterviewerForm;
