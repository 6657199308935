import React from 'react';
import IllustratorChart from 'components/illustrator/illustrator-chart/IllustratorChart';
import BreakdownTitle from '../brakedown-title/BreakdownTitle';
import {BREAKDOWN_VIEW_TYPE} from 'hooks/view-type';
import {useTranslation} from 'lib/i18n';
import useStyles from './LifetimeModelStyles';
import ProductDetailsModal from '../product-details/ProductDetailsModal';
import {Label} from 'recharts';
import IllustratorDetailedHeaders from 'components/illustrator/illustrator-detailed-headers/IllustratorDetailedHeaders';

LifetimeModel.propTypes = {};

/**
 *
 * the "Lifetime Model" component
 *
 */

function LifetimeModel(props) {
  const {
    showProductDetailsModal,
    brakeDownType = BREAKDOWN_VIEW_TYPE['lifetime-model'],
    isAnimationActive = true,
    interval,
  } = props;

  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <>
      <ProductDetailsModal showProductDetailsModal={showProductDetailsModal} />

      <BreakdownTitle brakeDownType={brakeDownType} />

      <IllustratorDetailedHeaders />

      <IllustratorChart
        interval={interval}
        isAnimationActive={isAnimationActive}
        childrenContainerClassName={classes.childrenContainerClassName}
        chartLabel={
          <Label
            value={t('illustratorTranslation:illustration breakdown.lifetime-model.chartTitle')}
            className={classes.lifetimeModelLabel}
            angle={-90}
            position="left"
          />
        }
      />
    </>
  );
}

export default LifetimeModel;
