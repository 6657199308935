import React from 'react';
import {useBorrowerIllustrationLabel} from 'hooks/borrower';
import {Box, Card, CardContent} from '@material-ui/core';
import Title from 'components/common/typeography/title/Title';
import Input from 'components/common/form/input/Input';
import useStyles from 'components/illustrator/borrower-notes-modal/components/illustration-share-element/IllustrationShareElementStyles';
import IllustrationExtraDetails from 'components/illustrator/illustration-extra-details/IllustrationExtraDetails';

function IllustrationShareElement(props) {
  const {illustration} = props;
  const classes = useStyles();

  const {getBorrowerIllustrationLabel} = useBorrowerIllustrationLabel();

  return (
    <Card variant="outlined">
      <CardContent>
        <Box className={classes.illustrationShareBoxContainer}>
          {/* the product name */}
          <Title variant="h5" gutterBottom>
            {getBorrowerIllustrationLabel(illustration)}
          </Title>

          {/* checkbox element */}
          <Input
            className={classes.illustrationShareCheckboxElement}
            name={illustration.id}
            type="checkbox"
            defaultValue={false}
          />
        </Box>

        <Box>
          <IllustrationExtraDetails illustration={illustration} />
        </Box>
      </CardContent>
    </Card>
  );
}

export default IllustrationShareElement;
