import React from 'react';
import {ProductContextProvider} from 'contexts/product-context';
import {useFlexHook} from 'components/products/flex/FlexHook';
import {useCalculateFlexQuery} from 'query/products/home-safe/flex';
import {useFlexOverallResults} from 'components/products/flex/FlexOverallResults';
import {API_ROUTES} from 'constants/illustrator';
import {useProductWithRef} from 'hooks/product-ref';

const Flex = React.forwardRef(function FlexRender(props, ref) {
  const {product, setRef, ...rest} = props;

  const flexHook = useFlexHook({product});
  const flexQuery = useCalculateFlexQuery({product});
  const flexOverallResults = useFlexOverallResults({product});

  useProductWithRef({
    productDetails: flexHook,
    productResults: flexOverallResults,
    productQuery: flexQuery,
    ref,
    ApiRoute: API_ROUTES.FLEX,
  });

  return (
    <ProductContextProvider
      productDetails={flexHook}
      productResults={flexOverallResults}
      productQuery={flexQuery}
      product={product}
      {...rest}
    />
  );
});

export default Flex;
