import {API_ROUTES, FUND_ACCESS} from 'constants/illustrator';
import {parentheses} from 'lib/utils';
import {formatCurrency} from 'lib/currency';

export function isRecommendedProduct(product) {
  return product?.calculation?.recommended;
}

export function getApiRouteFromCalculation(calculation) {
  const route =
    calculation.Type.toLowerCase() === 'homesafe'
      ? calculation.HomeSafeType.toLowerCase()
      : calculation.Type.toLowerCase();

  return route === 'standard' ? API_ROUTES.TIER : route;
}

export function filterProductByRoute(ApiRoute) {
  return (product) => product.ApiRoute === ApiRoute;
}

export function excludeForwardRefi({ApiRoute} = {}) {
  return ApiRoute !== API_ROUTES.FORWARDREFI;
}

export function isProductCalculated({calculation: {CalculationId, Errors = []} = {}} = {}) {
  return !!CalculationId && !Errors.length;
}

export function productHasErrors(product) {
  return product?.calculation?.Errors?.length > 0;
}

export function productHasWarnings(product) {
  return product?.calculation?.CalculationWarnings?.length > 0;
}

export function isTenurePayment({Tenure} = {}) {
  return Tenure === true;
}

export function isLenderCredit({Lesa, LenderCredit} = {}) {
  return !Lesa && LenderCredit;
}

export function isPaymentBreakdownValueNegative(value) {
  return value < 0;
}

export function formatPaymentBreakdownValue(value) {
  return isPaymentBreakdownValueNegative(value) ? parentheses(formatCurrency(Math.abs(value))) : formatCurrency(value);
}