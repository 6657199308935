import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    clearElementsMargins: {
      padding: '0 !important',
      margin: '0 !important',
    },
    publicDisclaimer: {
      maxWidth: theme.spacing(150),
      margin: 'auto',
      textAlign: 'center',
    },
    publicDisclaimerTypography: {
      marginBottom: theme.spacing(2),
    },
    publicDisclaimerTypographyTitle: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1.5),
      paddingLeft: 0,
    },
    publicDisclaimerChildren: {
      display: 'flex',
    },
  }),
  {index: 1},
);

export default useStyles;
