import useStyles from 'pages/illustrator-borrower/income/components/IncomeFormTableStyles';
import {useTranslation} from 'lib/i18n';
import {useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import React, {useCallback, useMemo} from 'react';
import Item from 'components/common/layout/item/Item';
import Input from 'components/common/form/input/Input';
import Button from 'components/common/button/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {TableCell} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import Select from 'components/common/form/select/Select';
import useIncomeFormTable from './use-income-form-table';
import useIncomeTypeOptions from 'hooks/link-application/borrower-info/income-type-options';
import useIncomeFrequencyOptions from 'hooks/link-application/borrower-info/income-frequency-options';
import IconButton from 'components/common/button/IconButton';
import Container from 'components/common/layout/container/Container';

function IncomeFormTable(props) {
  const {fields, append, remove} = useIncomeFormTable();

  const classes = useStyles();
  const {t} = useTranslation();

  const selectedIllustratorBorrower = useSelector(getSelectedIllustratorBorrower);

  const isAvailableCoBorrowerInfo = useMemo(() => {
    return selectedIllustratorBorrower?.coBorrowerInfo?.coBorrowerFirstName !== '';
  }, [selectedIllustratorBorrower]);

  // collectedBy drop down options
  const collectedByOptions = useMemo(() => {
    let arr = [
      {
        value: 'Borrower',
        label: t('translation:common.borrower'),
        //selected: true, // it was requested to be removed
      },
    ];
    if (isAvailableCoBorrowerInfo) {
      arr.push({
        value: 'Coborrower',
        label: t('translation:common.coborrower'),
      });
    }
    return arr;
  }, [isAvailableCoBorrowerInfo, t]);

  // incomeType drop down options
  const incomeTypeOptions = useIncomeTypeOptions();

  // incomeFrequency drop down options
  const incomeFrequencyOptions = useIncomeFrequencyOptions();

  // get default value for a drop down element, if available
  const getDefaultValue = useCallback((options = []) => options.find((option) => option.selected)?.value, []);

  const addOneRecord = () => {
    append({
      collectedBy: getDefaultValue(collectedByOptions), // income for
      incomeType: getDefaultValue(incomeTypeOptions), // income type
      monthlyAmount: '', // amount
      incomeFrequency: getDefaultValue(incomeFrequencyOptions), // pay frequency
    });
  };

  const removeOneRecord = (index) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  return (
    <>
      {/* content section / start */}
      <Item xs={12}>
        <Table className={classes.table} aria-label="caption table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="left" className={classes.tableCell} width="20%">
                {t('incomeFormTableTranslation:collected by')}*
              </TableCell>
              <TableCell align="left" className={classes.tableCell} width="30%">
                {t('incomeFormTableTranslation:income type')}*
              </TableCell>
              <TableCell align="left" className={classes.tableCell} width="25%">
                {t('incomeFormTableTranslation:amount')}*
              </TableCell>
              <TableCell align="left" className={classes.tableCell} width="25%">
                {t('incomeFormTableTranslation:pay frequency')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell align="left" className={classes.tableCell}>
                  <Select
                    variant="standard"
                    name={`incomeFormList[${index}].collectedBy`}
                    label=""
                    defaultValue={field?.collectedBy || getDefaultValue(collectedByOptions)}
                    options={collectedByOptions?.map((option) => ({
                      value: option.value,
                      label: option.label,
                      selected: option?.selected,
                    }))}
                  />
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  <Select
                    variant="standard"
                    name={`incomeFormList[${index}].incomeType`}
                    label=""
                    defaultValue={field?.incomeType || getDefaultValue(incomeTypeOptions)}
                    options={incomeTypeOptions?.map((option) => ({
                      value: option.value,
                      label: option.label,
                      selected: option?.selected,
                    }))}
                  />
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  <Input
                    fullWidth
                    name={`incomeFormList[${index}].monthlyAmount`}
                    required={true}
                    variant="standard"
                    label=""
                    type="currency"
                    defaultValue={field?.monthlyAmount || ''}
                  />
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  <Select
                    variant="standard"
                    name={`incomeFormList[${index}].incomeFrequency`}
                    label=""
                    defaultValue={field?.incomeFrequency || getDefaultValue(incomeFrequencyOptions)}
                    options={incomeFrequencyOptions?.map((option) => ({
                      value: option.value,
                      label: option.label,
                      selected: option?.selected,
                    }))}
                    InputProps={{
                      endAdornment:
                        fields.length > 1 ? (
                          <IconButton
                            size="sm"
                            onClick={() => removeOneRecord(index)}
                            icon={['fas', 'window-close']}
                            className={classes.deleteButton}
                          />
                        ) : null,
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Item>
      {/* content section / stop */}

      <Item xs={12}>
        <Container className={classes.buttonContainer}>
          <Item xs={12} sm={5} md={4} xl={3}>
            <Button
              btnStyle="bgElectric"
              buttonStyling={{lg: true}}
              onClick={() => addOneRecord()}
              data-testid="income-form-add-income-button"
            >
              {t('illustratorTranslation:add more income')}
            </Button>
          </Item>
          <Item xs={12} sm={5} md={4} xl={3}>
            <Button
              btnStyle="bgElectric"
              buttonStyling={{lg: true}}
              type="submit"
              data-testid="goToNextOriginBorrowerInfo"
            >
              {t('translation:common.next')}
            </Button>
          </Item>
        </Container>
      </Item>
    </>
  );
}

IncomeFormTable.propTypes = {};

IncomeFormTable.defaultValues = {};

export default IncomeFormTable;
