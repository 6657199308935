import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    textAlignLeft: {
      textAlign: 'left',
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    textAlignRight: {
      textAlign: 'right',
    },
    closeIcon: {
      position: 'absolute',
      right: 10,
      top: 10,
      color: theme.palette.grey.main,
      fontWeight: 'bold',
      fontSize: '2.0rem',
    },
    alertDialogSlideContainerBorderGreen: {
      '& .MuiDialog-paper': {
        minWidth: '800px',
        minHeight: '326px',
        border: '3px solid ' + theme.palette.secondary.dark,
        borderRadius: '20px',
        fontSize: '2.0rem',
      },
    },
    alertDialogSlideContainerBorderRed: {
      '& .MuiDialog-paper': {
        minWidth: '600px',
        minHeight: '280px',
        border: '3px solid ' + theme.palette.error.main,
        borderRadius: '20px',
        fontSize: '2.0rem',
      },
    },
    fontWeight400: {
      fontWeight: 400,
    },
    mt_2_0_0_0: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    mb_1_0_0_0: {
      margin: theme.spacing(0, 0, 1, 0),
    },
    alertDialogSlide: {
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          minWidth: 'auto',
        },
      },
    },
  }),
  {index: 1},
);

export default useStyles;
