import React, {useMemo} from 'react';
import Title from 'components/common/typeography/title/Title';
import Text from 'components/common/typeography/text/Text';
import Input from 'components/common/form/input/Input';
import DatePicker from 'components/common/form/date-picker/DatePicker';
import useStyles from './HeaderSectionStyles';
import useContentStyles from '../content-section/ContentSectionStyles';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import ValueOfPropertyInput from 'components/common/inputs/ValueOfProperty';
import {Trans} from 'lib/i18n';
import clsx from 'clsx';
import ScrollDown from 'components/common/scroll-down/ScrollDown';
import {useFormContext} from 'react-hook-form';
import ZipCode from 'components/common/inputs/ZipCode';
import {usePrintContext} from 'contexts/print-context';
import {useSmSizeApp} from 'lib/app-theme';
import {usePrintingValue} from 'hooks/utils';

function HeaderSection(props) {
  const {scrollTo} = props;
  const fullWidth = useSmSizeApp();
  const classes = useStyles();
  const classesContent = useContentStyles();
  const {watch} = useFormContext();
  const {printing} = usePrintContext();

  const [ValueOfProperty, DateOfBirth, Zip] = watch([
    'BorrowerProfile.ValueOfProperty',
    'BorrowerProfile.DateOfBirth',
    'BorrowerProfile.Zip',
  ]);
  const showScroll = useMemo(() => ValueOfProperty && DateOfBirth && Zip, [ValueOfProperty, DateOfBirth, Zip]);

  const textContainer = usePrintingValue({xs: 12, sm: 6, xl: 4}, {xs: 12, sm: 6, xl: 6});
  const marginTextContainer = usePrintingValue({xs: 12, sm: 3, xl: 4}, {xs: 12, sm: 3, xl: 3});

  const inputContainer = usePrintingValue({xs: 12, lg: 8, xl: 6}, {xs: 12, lg: 8, xl: 8});
  const marginInputContainer = usePrintingValue({xs: 12, lg: 2, xl: 3}, {xs: 12, lg: 2, xl: 2});

  return (
    <Container className={classesContent.rootContainer}>
      <Item xs={12} className={clsx(classesContent.flexContainer, classesContent.fullPage)}>
        <Container className={clsx(classesContent.descriptionContainer)}>
          <Item {...marginTextContainer} />
          <Item {...textContainer}>
            <Title variant="h3" className={classes.textLeft}>
              <Trans i18nKey="header section.title" />
            </Title>
          </Item>
          <Item {...marginTextContainer} />

          <Item {...marginTextContainer} />
          <Item {...textContainer}>
            <Text>
              <Trans i18nKey="header section.description" />
            </Text>

            <Text className={clsx(classes.withMargin)}>
              <Trans i18nKey="header section.description disclaimer" />
            </Text>

            <Text className={clsx(classes.withMargin)}>
              <Trans i18nKey="header section.description info" />
            </Text>
          </Item>
          <Item {...marginTextContainer} />

          <Item {...marginInputContainer} />
          <Item {...inputContainer} className={classes.inputContainer}>
            <ValueOfPropertyInput fullWidth={fullWidth} autoFocus={!printing} variant="standard" />
            <DatePicker name="BorrowerProfile.DateOfBirth" fullWidth={fullWidth} variant="standard" />
            <ZipCode fullWidth={fullWidth} variant="standard" />
            <Input name="BorrowerProfile.Liens" type="currency" fullWidth={fullWidth} variant="standard" />
          </Item>
          <Item {...marginInputContainer} />

          {showScroll && <ScrollDown component={Item} xs={12} onClick={scrollTo} />}
        </Container>
      </Item>
    </Container>
  );
}

HeaderSection.propTypes = {};

export default HeaderSection;
