import {makeStyles} from '@material-ui/core/styles';
import {isSmSizeAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    withMargin: {
      marginTop: theme.spacing(2.5),

      '& span': {
        color: theme.palette.primary.main,
      },
    },
    textLeft: {
      textAlign: 'center',
    },
    inputContainer: {
      display: theme.withPrint('flex', 'flex !important'),
      [isSmSizeAppQuery(theme)]: {
        display: 'block',
      },

      justifyContent: 'space-between',
      '& > .MuiFormControl-root': {
        margin: theme.withPrint(theme.spacing(2), theme.spacing('auto', 2)),
        maxWidth: theme.forPrint('20%'),

        '&:first-child': {
          marginLeft: '0 !important',
        },
        '&:last-child': {
          marginRight: '0 !important',
        },

        '&.MuiFormControl-fullWidth': {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
  }),
  {index: 1},
);

export default useStyles;
