import {useCallback, useEffect, useState} from 'react';
import {formatCurrency} from 'lib/currency';
import {API_ROUTES} from 'constants/illustrator';
import {SqlDateFormat} from 'lib/utils';

export function useDataGridFormat() {
  const formatApiRouteValue = useCallback(
    (products) => (params) => {
      const value = params.value === API_ROUTES.TENURE ? API_ROUTES.ANNUAL : params.value;

      return products.find((product) => product.ApiRoute === value)?.ProductName;
    },
    [],
  );

  const formatDateValue = useCallback((params) => SqlDateFormat(params.value), []);

  const formatCurrencyValue = useCallback((params) => {
    return formatCurrency(params.value);
  }, []);

  /**
   *
   * The `formatName` callback is used to concatenate the FirstName and LastName values.
   *
   */

  const formatName = useCallback((params) => {
    return `${params.row.FirstName || ''} ${params.row.LastName || ''}`;
  }, []);

  return {
    formatCurrencyValue,
    formatApiRouteValue,
    formatDateValue,
    formatName,
  };
}

export function useDataGridControl() {
  const [disableSelectionOnClick, setDisableSelectionOnClick] = useState(true);

  useEffect(() => {
    /**
     * @param e {React.KeyboardEvent}
     */
    function onKeyUp(e) {
      if (e?.which === 17) {
        setDisableSelectionOnClick(true);
      }
    }

    /**
     * @param e {React.KeyboardEvent}
     */
    function onKeyDown(e) {
      if (e?.which === 17) {
        setDisableSelectionOnClick(false);
      }
    }

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [setDisableSelectionOnClick]);

  return {disableSelectionOnClick};
}
