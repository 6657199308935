import React from 'react';
import Paper from 'components/common/paper/Paper';
import Title from 'components/common/typeography/title/Title';
import {Trans} from 'lib/i18n';
import Button from 'components/common/button/Button';
import Text from 'components/common/typeography/text/Text';
import {Box} from '@material-ui/core';
import Item from 'components/common/layout/item/Item';
import Container from 'components/common/layout/container/Container';
import BorrowerNotesModal from 'components/illustrator/borrower-notes-modal/BorrowerNotesModal';

/**
 *
 * "Ready to Share" component
 *
 * This component is used on :
 *
 *  - Illustrator V2 compare page ( illustrator/v2#compare )
 *
 *
 ******************************************************************************************************************
 *
 *                                                  Ready to Share ?
 *
 *        Generate PDF to send borrowers and decision makers details around the illustrations you've created.
 *
 *                                                 SHARE ILLUSTRATIONS
 *
 ******************************************************************************************************************
 *
 */

function ReadyToShare(props) {
  const {disableButtons, ApiRoute, className} = props;

  return (
    <Box component={Paper} className={className}>
      <Item xs={12}>
        <Container>
          <Item xs={12} md={3} />
          <Item xs={12} md={6}>
            <Title variant="h4" className="strong">
              <Trans i18nKey="illustratorTranslation:ready to share.title" />
            </Title>
          </Item>
          <Item xs={12} md={3} />
        </Container>
      </Item>
      <Item xs={12}>
        <Container>
          <Item xs={12} md={3} />
          <Item xs={12} md={6}>
            <Text className="strong">
              <Trans i18nKey="illustratorTranslation:ready to share.description" />
            </Text>
          </Item>
          <Item xs={12} md={3} />
        </Container>
      </Item>
      <Item xs={12}>
        <Container>
          <Item xs={12} md={3} />
          <Item xs={12} md={6}>
            <BorrowerNotesModal ApiRoute={ApiRoute} isDownloadPdf={false}>
              {({doOpen, loading}) => (
                <Button
                  btnStyle="bgElectric"
                  buttonStyling={{lg: true}}
                  type="button"
                  onClick={doOpen}
                  disabled={loading || disableButtons}
                  data-testid="illustrator-ready-to-share-button"
                >
                  <Trans i18nKey="illustratorTranslation:ready to share.button share" />
                </Button>
              )}
            </BorrowerNotesModal>
          </Item>
          <Item xs={12} md={3} />
        </Container>
      </Item>
    </Box>
  );
}

ReadyToShare.propTypes = {};

export default ReadyToShare;
