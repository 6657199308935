import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    productResultToggleIcon: {
      marginLeft: theme.spacing(2),
    },
    productResultToggleButton: {
      marginTop: theme.spacing(2),
      textTransform: 'none',
    },
    productResultListItem: {
      paddingTop: 0,

      '&:nth-child(odd)': {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.white.main,
      },
      '&:nth-child(even)': {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.common.white,
      },
    },
    productResultListItemTooltipImg: {
      margin: theme.spacing('auto', 'auto', 'auto', 2),
    },
    productResultListItemTooltipContainer: {
      display: 'flex',
      width: '100%',
      textAnchor: 'end',
    },
  }),
  {index: 1},
);

export default useStyles;
