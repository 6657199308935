import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    itemContainerWrapper: {
      display: 'block',
    },
    padding3units: {
      padding: theme.spacing(3, 3, 3, 3),
    },
    table: {
      margin: '0 auto',
      border: 'none ',
      borderCollapse: 'collapse',
    },
    tableCell: {
      width: 'auto',
      height: 40,
      border: 'none',
      '& .MuiTextField-root': {
        maxWidth: `calc(100% - 40px)`,
      },
    },
    noPadding: {
      padding: theme.spacing(0, 0, 0, 0) + ' !important',
    },
  }),
  {index: 1},
);

export default useStyles;
