import React, {useCallback, useContext, useState} from 'react';
import {cloneDeep, isFunction, merge} from 'lodash';

const widthContext = {};

const WidthContext = React.createContext(widthContext);

export function useWidthContext(props = {}) {
  return useContext(WidthContext);
}

/**
 *
 * @param props {object}
 * @param props.extraArguments
 * @param props.defaultState
 * @param props.children
 * @return {JSX.Element}
 * @constructor
 */
export function WidthContextProvider(props = {}) {
  const {children, defaultState = widthContext, extraArguments} = props;
  const [value, setValue] = useState(defaultState);

  const setWidthContext = useCallback((data) => setValue((value) => merge(cloneDeep(value), data)), [setValue]);
  const resetWidthContext = useCallback(() => setValue(defaultState), [setValue, defaultState]);

  const context = {...value, extraArguments, setWidthContext, resetWidthContext};

  return (
    <WidthContext.Provider value={context}>{isFunction(children) ? children(context) : children}</WidthContext.Provider>
  );
}
