import {axios} from './api';

export default class LinkLoansApi {
  static baseUrl = '/linkLoans';

  static getLoans = (config) => {
    return axios.get(this.baseUrl, config);
  };

  static getLoan = (loanId, config) => {
    return axios.get(`${this.baseUrl}/${loanId}`, config);
  };
}
