import {formatCurrency, formatPercent} from 'lib/currency';
import {useTheme} from '@material-ui/core';
import {useTranslation} from 'lib/i18n';
import {useMemo} from 'react';
import {isLoaded} from 'adapter/loading.adapter';
import {excludeForwardRefi} from 'lib/product';

export function useProductInfo(props = {}) {
  const {
    product: {
      ApiRoute,
      calculation: {
        TotalInitialRate,
        PrincipalLimit,
        HybridMetric: {PaymentTermYears} = {},
        HybridResult: {NewMonthlyPayment} = {},
      } = {},
    } = {},
    loading,
  } = props;

  const theme = useTheme();
  const {t} = useTranslation();

  const productLoaded = useMemo(() => isLoaded({loading}), [loading]);

  const tableData = useMemo(() => {
    let data = [
      {
        primary: t('illustratorTranslation:product results.product result pie.interest rate'),
        secondary: formatPercent(TotalInitialRate),
        key: 'TotalInitialRate',
        color: theme.palette.primary.dark,
      },
      {
        primary: t('illustratorTranslation:product results.product result pie.payment'),
        secondary: formatCurrency(NewMonthlyPayment),
        key: 'NewMonthlyPayment',
        color: theme.palette.primary.dark,
      },
      {
        primary: t('illustratorTranslation:product results.product result pie.payment term'),
        secondary: PaymentTermYears,
        key: 'PaymentTermYears',
        color: theme.palette.primary.dark,
      },
    ];

    if (!excludeForwardRefi({ApiRoute}))
      data.push({
        primary: t('illustratorTranslation:product results.product result pie.PrincipalLimit'),
        secondary: formatCurrency(PrincipalLimit),
        key: 'PrincipalLimit',
        color: theme.palette.primary.dark,
      });

    return data;
  }, [NewMonthlyPayment, PaymentTermYears, TotalInitialRate, ApiRoute, PrincipalLimit, t, theme.palette.primary.dark]);

  return {
    tableData,
    productLoaded,
  };
}
