import {axios} from './api';

export default class CalculateServiceApi {
  static baseUrl = '/CalculationService';

  static getPaymentTypes = async (config) => {
    return axios.get(`${this.baseUrl}/PaymentTypes`, config);
  };

  static getProducts = async (config) => {
    return axios.get(`${this.baseUrl}/Products`, config);
  };

  static getProductsByState = (State, config) => {
    return axios.get(`${this.baseUrl}/ProductsByState?State=${State}`, config);
  };

  static getProductsByZipCode = (Zip, config) => {
    return axios.get(`${this.baseUrl}/ProductsByZipCode?ZipCode=${Zip}`, config);
  };

  static getProductsByName = (ProductName, config) => {
    return axios.get(`${this.baseUrl}/ProductsByName`, {
      ...config,
      params: {...config?.params, ProductName},
    });
  };

  static getStateByZipCode = (Zip, config) => {
    return axios.get(`${this.baseUrl}/StateZipCode?ZipCode=${Zip}`, config);
  };

  static async getCalculationById(CalculationId, config) {
    return axios.get(`${this.baseUrl}/Calculations`, {
      ...config,
      params: {...config?.params, CalculationId},
    });
  }

  static async saveCalculation(calculation, config) {
    return axios.post(`${this.baseUrl}/Save`, calculation, config);
  }

  static async getSavedIllustrationsByBrokerId(BrokerId, config) {
    return axios.get(`${this.baseUrl}/SavedCalculations`, {
      ...config,
      params: {...config?.params, BrokerId},
    });
  }

  static async deleteSavedCalculations(calculationIds, config) {
    config = config || {};
    config.params = config.params || {};
    config.params = new URLSearchParams();

    for (const id of calculationIds) {
      config.params.append('ids', id);
    }

    const data = [{op: 'add', path: '/IsSaved', value: false}];

    return axios.patch(`/calculations`, data, config);
  }
}
