import {useCallback} from 'react';

export function useOnChangeInterceptor(props = {}) {
  const {onChange, getValue} = props;

  const handleChange = useCallback(
    (event, ...rest) => {
      event.target.value = getValue?.(event, ...rest) ?? '';

      return onChange(event, ...rest);
    },
    [onChange, getValue],
  );

  return {doChange: handleChange};
}
