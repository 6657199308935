import React, {useCallback, useState} from 'react';
import {useTranslation} from 'lib/i18n';
import Text from 'components/common/typeography/text/Text';
import {Card, CardMedia, CardActionArea, CardContent, Box} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './HybridOfferStyles';
import {useProductCardContext} from 'contexts/product-card-context';
import equityAvailImg from '../../../../../../assets/images/products/equityavail.svg';
import ShortToCloseModal from 'components/products/hybrid/short-to-close/ShortToCloseModal';
import Button from 'components/common/button/Button';

HybridOffer.propTypes = {};

function HybridOffer(props) {
  const {scenario} = props;
  const img = equityAvailImg;
  const {description} = useProductCardContext();
  const {t} = useTranslation();
  const classes = useStyles();

  // Continue for open/close ShortToClose modal
  const [showShortToCloseModal, setShowShortToCloseModal] = useState(false);
  const openShowShortToCloseModal = useCallback(() => setShowShortToCloseModal(true), [setShowShortToCloseModal]);
  const closeShowShortToCloseModal = useCallback(() => setShowShortToCloseModal(false), [setShowShortToCloseModal]);

  return (
    <>
      <Card className={clsx(classes.productCard)}>
        <CardActionArea className={clsx(classes.productCardActionArea)} component="div">
          {img && <CardMedia className={clsx(classes.productCardImage)} image={equityAvailImg} />}

          <CardContent className={classes.productCardCardContent}>
            <Box className={clsx(classes.productCardDescriptionContainer)} style={description}>
              <Text className={classes.productCardDescriptionRoot}>
                {t('illustratorTranslation:illustration.products.hybrid.description')}
              </Text>

              <br />

              <Text className={classes.productCardDescriptionRoot}>
                {t('illustratorTranslation:short to close.offer description')}
              </Text>

              <br />

              <Text className={classes.productCardDescriptionRoot}>
                {t('illustratorTranslation:short to close.offer description2')}
              </Text>

              <br />
            </Box>

            <Box className={clsx(classes.productCardButtonContainer)}>
              <Button
                className={classes.productCardButtonRoot}
                buttonStyling={{lg: true}}
                type="button"
                component="p"
                btnStyle="bgElectric"
                disabled={false}
                onClick={openShowShortToCloseModal}
                data-testid={'eaoffer'}
              >
                {t('illustratorTranslation:additional step.continue button')}
              </Button>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>

      <ShortToCloseModal show={showShortToCloseModal} close={closeShowShortToCloseModal} scenario={scenario} />
    </>
  );
}

export default HybridOffer;
