import {useSelector} from 'react-redux';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';
import {useUpdateIllustrations} from 'hooks/illustrator-form';
import {useCallback} from 'react';

function useBorrowerDetails() {
  const {ApiRoute, BorrowerProfile = {}} = useSelector(getSelectedIllustration);

  const {updateIllustrationsBorrowerProfile} = useUpdateIllustrations();

  const updateBorrowerProfileId = useCallback(
    (response, context = {}) => {
      const {BorrowerProfile} = context;
      updateIllustrationsBorrowerProfile({old: BorrowerProfile?.BorrowerProfileId, new: response?.data});
    },
    [updateIllustrationsBorrowerProfile],
  );

  return {updateBorrowerProfileId, BorrowerProfile, ApiRoute};
}

export default useBorrowerDetails;
