import React, {useMemo} from 'react';
import BoOrCo from './bo-or-co/BoOrCo';
import {BORROWER_PREFIX, CO_BORROWER_PREFIX} from 'constants/borrower';
import {useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';

/**
 *
 * This component is used to list all the extra sub-elements of a question.
 *
 * First, we need to list all the sub-elements for the "borrower".
 *
 * Second, we need to list all the sub-elements for the "co-borrower".
 *
 * ****************************************
 * ***   Borrower   ***   Co-Borrower   ***
 * **********************************************************
 * ***   Yes | No   ***   Yes | No      *** Question text ***
 * ************************************************************************************************
 *                                      *** generate all the extra sub elements for "borrower"    *
 *                                      ***********************************************************
 *                                      *** generate all the extra sub elements for "co-borrower" *
 *                                      ***********************************************************
 *
 */

function QuestionExtraSubElements(props = {}) {
  const {question} = props;

  const selectedIllustratorBorrower = useSelector(getSelectedIllustratorBorrower);

  const isAvailableCoBorrowerInfo = useMemo(() => {
    return !!selectedIllustratorBorrower?.coBorrowerInfo?.coBorrowerFirstName;
  }, [selectedIllustratorBorrower]);

  /**
   *
   * check if the current question has extra dropdowns
   *
   */

  if (question.hasOwnProperty('options') === false || question?.options?.length === 0) {
    /**
     *
     * the current question has no extra dropdowns
     *
     */

    return null;
  }

  return (
    <>
      <BoOrCo prefix={BORROWER_PREFIX} question={question} />

      {isAvailableCoBorrowerInfo ? <BoOrCo prefix={CO_BORROWER_PREFIX} question={question} /> : null}
    </>
  );
}

QuestionExtraSubElements.propTypes = {};

QuestionExtraSubElements.defaultValues = {};

export default QuestionExtraSubElements;
