import {useMemo} from 'react';
import {formatCurrency, formatPercent} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import {getBorrowerBirthDate} from 'lib/borrower';
import {usePrintContext} from 'contexts/print-context';
import {parentheses} from 'lib/utils';
import {isPaymentBreakdownValueNegative, formatPaymentBreakdownValue} from 'lib/product';

export function useSelectOverallResults(props = {}) {
  const {
    product: {
      ApiRoute,
      calculation: {
        BorrowerProfile: {
          Age,
          DateOfBirth,
          Liens,
          ValueOfProperty,
          OtherLiens
        } = {},
        PrincipalLimit,
        ClosingCost,
        CashAtClose,
        TotalUpfrontDraw,
        MaxRevolvingLoc,
        LineOfCredit,
        Rate,
        FundAccess,
        upfrontDrawPercentage,
        SimplifiedCashToBorrower,
        ServiceFee,
        InitialLoanBalance,
        UnutilizedCash,
        InitialCashDraw,
        AvailableLoc,
        GrowthRate,
        Margin
      } = {},
    } = {},
  } = props;

  const {t, override, cashLabel} = useTranslation();
  const {printing} = usePrintContext();



  const availableCashSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.total proceeds'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.total proceeds tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(PrincipalLimit),
        order: 2,
        id: 'availableCashSection-total-proceeds',
        highlight: false,
      },
      {
        label: override('illustratorTranslation:illustration.overall results.total mortgages liens',{
          product: {ApiRoute},
        }),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.total mortgages liens tooltip', {
          product: {ApiRoute},
        }),
        value: parentheses(formatCurrency(Liens)),
        useColorOptions: {value: 'red'},
        order: 3,
        id: 'availableCashSection-total-mortgage-liens',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.estimated closing costs'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.select.estimated closing costs tooltip'),
        value: parentheses(formatCurrency(ClosingCost)),
        useColorOptions: {value: 'red'},
        order: 4,
        id: 'availableCashSection-estimated-closing-cost',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.available cash'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.available cash tooltip', {
          product: {ApiRoute},
        }),
        value: formatPaymentBreakdownValue(SimplifiedCashToBorrower),
        useColorOptions: {value: isPaymentBreakdownValueNegative(SimplifiedCashToBorrower) ? 'red' : undefined},
        order: 5,
        id: 'availableCashSection-available-cash',
        highlight: true,
      },
    ],
    [t, override, ApiRoute, PrincipalLimit, OtherLiens, ClosingCost, SimplifiedCashToBorrower],
  );

  const initialLoanBalanceSection = useMemo(
    () => [
      {
        label: override('illustratorTranslation:illustration.overall results.mortgage liens payoff',{
          product: {ApiRoute},
        }),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.mortgage liens payoff tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(Liens),
        order: 2,
        id: 'initialLoanBalanceSection-mortgage-liens-payoff',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.estimated closing costs'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.select.estimated closing costs tooltip'),
        value: formatCurrency(ClosingCost),
        order: 3,
        id: 'initialLoanBalanceSection-estimated-closing-cost',
        highlight: false,
      },
      {
        label: cashLabel('illustratorTranslation:illustration.overall results.initial cash draw', {
          cash: CashAtClose,
        }),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.initial cash draw tooltip', {
          product: {ApiRoute},
        }),
        value: formatPaymentBreakdownValue(InitialCashDraw),
        order: 4,
        id: 'initialLoanBalanceSection-initial-cash-draw',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.initial loan balance'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.initial loan balance tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(InitialLoanBalance),
        order: 5,
        id: 'initialLoanBalanceSection-initial-loan-balance',
        highlight: true,
      },
      {
        label: cashLabel('illustratorTranslation:illustration.overall results.available loc', {
          cash: UnutilizedCash,
        }),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.available loc tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(LineOfCredit), 
        order: 6,
        id: 'initialLoanBalanceSection-available-loc',
        highlight: false,
      },
    ],
    [override, ApiRoute, Liens, t, ClosingCost, cashLabel, CashAtClose, InitialLoanBalance, UnutilizedCash],
  );

  const homeInformationSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.estimated home value'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.est home value tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(ValueOfProperty),
        order: 2,
        id: 'homeInformationSection-estimated-home-value',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.current mortgage liens'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.current mortgage liens tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(Liens),
        order: 3,
        id: 'homeInformationSection-current-mortgage-liens',
      },
    ],
    [t, override, ApiRoute, ValueOfProperty, Liens],
  );

  const youngestBorrowerSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.birth date'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.birthdate tooltip', {
          product: {ApiRoute},
        }),
        value: getBorrowerBirthDate(DateOfBirth),
        order: 2,
        id: 'youngestBorrowerSection-birth-date',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.nearest age'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.nearest age tooltip', {
          product: {ApiRoute},
        }),
        value: Age,
        order: 3,
        id: 'youngestBorrowerSection-nearest-age',
      },
    ],
    [t, override, ApiRoute, DateOfBirth, Age],
  );

  const loanDetailsSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.initial interest rate'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.initial interest rate tooltip', {
          product: {ApiRoute},
        }),
        value: formatPercent(Rate),
        order: 2,
        id: 'loanDetailsSection-interest-rate',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.margin'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.margin tooltip', {
          product: {ApiRoute},
        }),
        value: formatPercent(Margin),
        order: 2,
        id: 'loanDetailsSection-margin',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.loc growth rate'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.loc growth rate tooltip', {
          product: {ApiRoute},
        }),
        value: formatPercent(GrowthRate),
        order: 2,
        id: 'loanDetailsSection-loc-growth-rate',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.monthly service fee'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.select.monthly service fee tooltip', {
          product: {ApiRoute},
        }),
        value: formatCurrency(ServiceFee),
        order: 4,
        id: 'loanDetailsSection-monthly-service-fee',
      },
    ], [t, override, ApiRoute, Rate, ServiceFee, Margin]
  );

  return {
    availableCashSection,
    initialLoanBalanceSection,
    homeInformationSection,
    youngestBorrowerSection,
    loanDetailsSection,
  };
}
