import React, {useCallback, useMemo, useEffect} from 'react';
import {Box} from '@material-ui/core';
import {useTranslation} from 'lib/i18n';
import Form from 'components/common/form/Form';
import Text from 'components/common/typeography/text/Text';
import useStyles from 'components/illustrator/modal-email-to-borrower/components/share-illustrations/ShareIllustrationsStyles';
import IllustrationShareElement from 'components/illustrator/borrower-notes-modal/components/illustration-share-element/IllustrationShareElement';
import {ALL_ILLUSTRATIONS} from 'constants/illustrator';
import {useSnackbar} from 'notistack';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import {useShareIllustration} from 'hooks/borrower';
import SelectAllIllustrationsButton from 'components/illustrator/borrower-notes-modal/components/select-all-illustrations-button/SelectAllIllustrationsButton';
import {useDialogStateMutation, withDialogState} from 'hooks/dialog';
import {useFormContext} from 'react-hook-form';
import { trackEvent } from '../../../../../services/analytics.service';
import { SCENARIO_CATEGORIES, SCENARIO_ACTIONS } from '../../../../../constants/analytic-events';

/**
 *
 * "Share Illustrations" component
 *
 *    parent component : modal "Email to Borrower"
 *
 */

function ShareIllustrations(props = {}) {
  // props
  const {state, nextStep, setState} = props;
  const {illustrations} = useShareIllustration();

  useEffect(() => {
    setState((preState)=> ({...preState, closeDialogAndSendAnalyticsEvent:true }));
    trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_EMAIL_TO_BORROWER_START);
  }, []);

  const {t} = useTranslation();
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  const doSubmit = useCallback(
    async (data) => {
      trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_EMAIL_TO_BORROWER_PROD_SELECT);

      let illustrationsToShareKey = Object.keys(data).filter((key) => key !== ALL_ILLUSTRATIONS);

      // check if all checkboxes have been checked or not
      if (!data[ALL_ILLUSTRATIONS]) {
        illustrationsToShareKey = illustrationsToShareKey.filter((key) => data[key]);
      }

      if (illustrationsToShareKey.length) {
        setState((oldState) => ({
          ...oldState,
          //open: true,
          illustrationsToShare: illustrations.filter((illustration) =>
            illustrationsToShareKey.includes(illustration.id),
          ),
        }));

        return nextStep();
      } else {
        return enqueueSnackbar(t('illustrationShareModalTranslation:no item selected'), {variant: 'error'});
      }
    },
    [setState, illustrations, enqueueSnackbar, t, nextStep],
  );

  const defaultValues = useMemo(() => {
    return illustrations.reduce((a, v) => ({...a, [v.id]: false}), {[ALL_ILLUSTRATIONS]: false});
  }, [illustrations]);

  return (
    <Form id={state.formId} onSubmit={doSubmit} defaultValues={defaultValues}>
      <ShareIllustrationButtonManager setState={setState} />

      <Box className={classes.illustrationShareInfoBox}>
        <Text className={classes.subTitle}>
          {t('illustratorTranslation:modal email to borrower.share illustrations.subtitle')}
        </Text>

        <SelectAllIllustrationsButton />
      </Box>

      <Container className={classes.itemContainer}>
        {illustrations.map((illustration) => (
          <Item xs={12} key={illustration.id}>
            <IllustrationShareElement illustration={illustration} />
          </Item>
        ))}
      </Container>
    </Form>
  );
}

function ShareIllustrationButtonManager(props) {
  const {setState} = props;

  const {
    formState: {dirtyFields},
  } = useFormContext();

  const buttonNextDisabled = useMemo(() => Object.keys(dirtyFields).length < 1, [Object.keys(dirtyFields).length]);

  useDialogStateMutation({setState, buttonNextDisabled, buttonNextProgress: false});

  return null;
}

ShareIllustrations.propTypes = {};

ShareIllustrations.defaultValues = {};

export default withDialogState(ShareIllustrations, {
  title: 'illustratorTranslation:modal email to borrower.share illustrations.title',
  formId: 'share-illustration',
  buttonReturn: '',
});
