import {useQuery} from 'react-query';
import RateServiceApi from 'api/rate-service-api';
import {useMemo} from 'react';
import {HECMFixedRatesQueryKey} from 'constants/query';

export function useHECMFixedRatesQuery(props = {}) {
  const {enabled} = props;
  const {data: rates, status} = useQuery(HECMFixedRatesQueryKey, () => RateServiceApi.getFixedRate(), {enabled});

  const data = useMemo(() => rates ?? [], [rates]);
  const defaultRate = useMemo(() => data.find((Rate) => Rate.IsDefault), [data]);

  return {data, defaultRate, status};
}
