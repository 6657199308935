import React from 'react';

export const defaultContext = {};
export const StepWizardContext = React.createContext(defaultContext);

/**
 *
 * DOC : https://github.com/jcmcneal/react-step-wizard/blob/master/src/index.js
 *
 * useStepWizardContext data & format:
 *
 *  - context
 *  - firstStep
 *  - getHash
 *  - getSteps
 *
 *        ƒ () {
 *          return i.Children.toArray(o.props.children);
 *        }
 *
 *  - getTranslations
 *  - goToNamedStep
 *  - goToStep
 *
 *        ƒ (t) {
 *           o.props.isHashEnabled && "string" == typeof t && void 0 !== o.state.hashKeys[t] ? o.setActiveStep(o.state.hashKeys[t]) : o.setActiveStep(t - 1);
 *        }
 *
 *  - initialState
 *  - isInvalidStep
 *
 *        ƒ (t) {
 *           return t < 0 || t >= o.totalSteps;
 *        }
 *
 *  - isReactComponent
 *  - lastStep
 *  - nextStep
 *
 *        ƒ () {
 *          return o.setActiveStep(o.state.activeStep + 1);
 *        }
 *
 *  - onHashChange
 *  - onStepChange
 *  - previousStep
 *
 *        ƒ () {
 *          return o.setActiveStep(o.state.activeStep - 1);
 *        }
 *
 *  - props:
 *      - children
 *      - className
 *      - initialStep
 *      - instance
 *      - isHashEnabled
 *      - isLazyMount
 *      - nav
 *      - onStepChange
 *  - refs
 *  - setActiveStep
 *  - state
 *  - updateHash
 *  - updater
 *
 */

export function useStepWizardContext() {
  return React.useContext(StepWizardContext);
}

function StepWizardProvider({value, children} = {}) {
  return <StepWizardContext.Provider value={value}>{children}</StepWizardContext.Provider>;
}

export default StepWizardProvider;
