import React from 'react';
import PropTypes from 'prop-types';
import {Controller, useFormContext} from 'react-hook-form';
import DatePickerInput from './DatePickerInput';

import 'react-datepicker/dist/react-datepicker.css';
import {useInputError, useInputLabel, useValidationSchema} from 'hooks/input';

function DatePicker(props) {
  const {label, name, fullWidth = true, ...rest} = props;
  const {control} = useFormContext();

  const {required} = useValidationSchema({name});
  const {showError, errorMessage} = useInputError({name});

  const inputLabel = useInputLabel({label, name});

  return (
    <Controller
      control={control}
      name={name}
      render={({field}) => (
        <DatePickerInput
          label={inputLabel}
          fullWidth={fullWidth}
          format="MM/dd/yyyy"
          placeholder="MM/DD/YYYY"
          required={required}
          error={showError}
          helperText={errorMessage}
          {...field}
          {...rest}
        />
      )}
    />
  );
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default DatePicker;
