import React from 'react';
import {TabPanel as MuiTabPanel} from '@material-ui/lab';
import clsx from 'clsx';
import useStyles from './TabPanelStyles';
import {isTest} from 'constants/app';

const overrideTest = isTest ? {id: '', 'aria-labelledby': ''} : {};

const TabPanel = React.forwardRef(function TabPanelRender(props, ref) {
  const {className, ...rest} = props;
  const classes = useStyles();

  return <MuiTabPanel className={clsx(classes.tabPanelComponent, className)} {...rest} ref={ref} {...overrideTest} />;
});

export default TabPanel;
