import {makeStyles} from '@material-ui/core/styles';

const noBorder = {
  '&:not(.last)': {
    borderRight: 'unset',
  },
};

const useStyles = makeStyles(
  (theme) => ({
    rootContainer: {
      display: theme.withPrint('none', 'unset !important'),
      textAlign: 'center',

      maxWidth: theme.forPrint('100%'),
      overflowX: theme.forPrint('visible'),
    },
    disclaimerText: {
      textAlign: 'left',
      fontWeight: '500',
    },
    tableDataContainer: {
      display: theme.withPrint('none', 'block'),
      overflow: 'visible !important',
      textAlign: 'center',
      '& thead': {
        lineHeight: '1.25rem',
        marginTop: theme.spacing(1),
      },
      '& tr:nth-child(even)': {
        backgroundColor: theme.palette.grey.light,
      },

      '& th.grouped-col-0': {
        backgroundColor: theme.palette.advancedOptions.light,
        ...noBorder,
      },
      '& th.grouped-col-1': {
        backgroundColor: theme.palette.advancedOptions.main,
        ...noBorder,
      },
      '& th.grouped-col-2': {
        backgroundColor: theme.palette.advancedOptions.dark,
        ...noBorder,
      },
    },
    rootContainerActive: {
      display: 'initial',
    },
    dataGridContainer: {
      display: theme.withPrint('inline-block', 'none'),
      width: '100%',
      overflow: 'auto',
      flex: 1,
      minHeight: '60vh',
      '& .rdg-cell-drag-handle': {
        cursor: 'ns-resize',
      },
      '& .rdg-cell.error': {
        boxShadow: `inset 0 0 0 2px ${theme.palette.error.main}`,

        '& .rdg-cell-drag-handle': {
          backgroundColor: theme.palette.error.main,
        },
      },
      '& .rdg-row > .rdg-cell': {
        padding: 'unset',
      },
      '& .rdg-row:nth-child(odd)': {
        backgroundColor: theme.palette.grey.light,
      },
      '& .rdg-header-row': {
        lineHeight: `${theme.spacing(2)}px`,
        '& > .rdg-cell': {
          overflow: 'unset',
          textOverflow: 'unset',
          whiteSpace: 'pre-line',
          display: 'flex',

          '& > p': {
            margin: 'auto',
          },

          '&.grouped-col-0': {
            backgroundColor: theme.palette.advancedOptions.light,
            ...noBorder,
          },
          '&.grouped-col-1': {
            backgroundColor: theme.palette.advancedOptions.main,
            ...noBorder,
          },
          '&.grouped-col-2': {
            backgroundColor: theme.palette.advancedOptions.dark,
            ...noBorder,
          },
        },
      },
    },
  }),
  {index: 1},
);

export default useStyles;
