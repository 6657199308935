import React, {useMemo} from 'react';
import Button from 'components/common/button/Button';
import {Trans} from 'lib/i18n';
import {Box} from '@mui/material';
import Form from 'components/common/form/Form';
import ShortToCloseFormInputs from 'components/products/hybrid/short-to-close/ShortToCloseFormInputs';
import {useUpdateBorrowerProfile} from 'hooks/borrower-profile';
import {setBreakDownViewType, updateScenario} from 'reducers/settings.reducer';
import {useDispatch} from 'react-redux';
import {updateSelectedIllustration} from 'reducers/illustrator.reducer';
import {API_ROUTES} from 'constants/illustrator';
import {ShortToCloseValidation} from './ShortToCloseValidation';
/**
 *
 * @param {Object} props
 * @param {string} props.className
 * @param {number} props.xs
 * @param {number} props.md
 * @param {string} props.multipleClassName
 * @param {boolean} props.hideNumberOfUnits
 * @param {boolean} props.hide
 * @return {JSX.Element|null}
 * @constructor
 */
function ShortToCloseForm(props) {
  const {closeModal, id = 'short-to-close', scenario, ...rest} = props;

  const dispatch = useDispatch();

  const {defaultValues, onSubmit} = useUpdateBorrowerProfile({
    defaultValues: {
      scenario: scenario.Key,
    },
    async onSuccess() {
      await dispatch(setBreakDownViewType());
      await dispatch(updateSelectedIllustration({ApiRoute: API_ROUTES.HYBRID}));
      await dispatch(updateScenario({eligible: true, completed: true, ...scenario}));
      closeModal();
    },
  });

  const validationSchema = useMemo(() => ShortToCloseValidation(), []);

  return (
    <Form id={id} onSubmit={onSubmit} defaultValues={defaultValues} validationSchema={validationSchema}>
      <ShortToCloseFormInputs {...rest} />

      <Box display="flex" justifyContent="center" marginTop={3}>
        <Button type="submit" form={id} btnStyle="bgElectric" buttonStyling={{md: true, noMargin: true}}>
          <Trans i18nKey={`illustratorTranslation:short to close.${scenario?.Key}.modal.button submit`} />
        </Button>
      </Box>
    </Form>
  );
}

export default ShortToCloseForm;
