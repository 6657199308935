import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconButton} from '@material-ui/core';
import useStyles from './GoBackAsArrowButtonStyles';
import clsx from 'clsx';

function GoBackAsArrowButton(props) {
  const {className, ...rest} = props;
  const classes = useStyles();

  return (
    <IconButton color="primary" className={clsx(classes.goBackButtonIcon, className)} {...rest}>
      <FontAwesomeIcon icon={['fa', 'arrow-left']} />
    </IconButton>
  );
}

export default GoBackAsArrowButton;
