import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    buttonContainer: {
      justifyContent: 'center',
    },
    subtitleItem: {
      marginTop: '0 !important',
      paddingTop: '0 !important',
    },
  }),
  {index: 1},
);

export default useStyles;
