import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Box, Button as MuiButton, CircularProgress} from '@material-ui/core';
import useStyles from './ButtonStyles';
import clsx from 'clsx';

const Button = React.forwardRef(function ButtonRender(props, ref) {
  // props
  const {
    classes: componentClasses,
    children,
    btnStyle,
    component,
    border,
    type,
    className,
    containerProps = {},
    disabled,
    showProgress,
    size,
    variant,
    buttonStyling = {},
    hidden,
    ...rest
  } = props;

  const classes = useStyles();

  const button = useMemo(
    () => (
      <MuiButton
        {...rest}
        classes={{...componentClasses, label: clsx(componentClasses?.label, classes.buttonLabel)}}
        className={clsx(classes.default, classes[size], classes[btnStyle], !border && classes.noBorder, className, {
          [classes.noBorder]: variant === 'text',
          [classes.buttonStyling]: Object.keys(buttonStyling).length,
          [classes.buttonStylingLg]: buttonStyling?.lg,
          [classes.buttonStylingMd]: buttonStyling?.md,
          [classes.buttonStylingSm]: buttonStyling?.sm,
          [classes.buttonStylingNoMargin]: buttonStyling?.noMargin,
          [classes.hide]: hidden,
        })}
        hidden={hidden}
        size={size}
        type={type}
        component={component}
        disabled={disabled}
        ref={ref}
        variant={variant}
      >
        {children}
      </MuiButton>
    ),
    [
      rest,
      componentClasses,
      classes,
      size,
      btnStyle,
      border,
      className,
      variant,
      buttonStyling,
      hidden,
      type,
      component,
      disabled,
      ref,
      children,
    ],
  );

  if (!showProgress) return button;

  return (
    <Box {...containerProps} className={clsx(classes.buttonProgressContainer, containerProps.className)}>
      {button}

      {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
    </Box>
  );
});

Button.defaultProps = {
  type: 'button',
  size: 'medium',
  btnStyle: 'primaryDark',
  variant: 'outlined',
  border: true,
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  btnStyle: PropTypes.string,
  border: PropTypes.bool,
  showProgress: PropTypes.bool,
  buttonStyling: PropTypes.any,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
};

export default Button;
