import React, {useRef} from 'react';
import {ResponsiveContainer as RResponsiveContainer} from 'recharts';
import {usePrintContext} from 'contexts/print-context';
import {Box} from '@mui/material';
import {useDeepMemo} from 'hooks/utils';
import clsx from 'clsx';

ResponsiveContainer.propTypes = {};

function useClientRect(props) {
  const {minWidth = 200, minHeight = 200, maxHeight, maxWidth} = props;
  const ref = useRef();

  const clientRect = ref.current?.getBoundingClientRect() ?? {};

  const {width, height} = useDeepMemo(() => {
    const height = clientRect.height < minHeight ? minHeight : clientRect.height;
    const width = clientRect.width < minWidth ? minWidth : clientRect.width;

    return {
      width: maxWidth ? (width > maxWidth ? maxWidth : width) : width,
      height: maxHeight ? (height > maxHeight ? maxHeight : height) : height,
    };
  }, [clientRect, minHeight, minWidth, maxHeight]);

  return {width: width, height: height, ref};
}

function ResponsiveContainer(props) {
  const {className, children, aspect, minWidth, minHeight, maxHeight, maxWidth, isAnimationActive, ...rest} = props;

  const {printing} = usePrintContext();
  const {width, height, ref: boxRef} = useClientRect({minWidth, minHeight, maxHeight, maxWidth});

  if (printing) {
    return (
      <Box className={clsx('printing-container', className)} width="99%" height="100%" ref={boxRef} {...rest}>
        {React.cloneElement(children, {width, height})}
      </Box>
    );
  }

  return <RResponsiveContainer {...props}>{children}</RResponsiveContainer>;
}

export default ResponsiveContainer;
