import React from 'react';

import {formatCurrency} from 'lib/currency';

import useStyles from './RenderActiveShapeStyles';

function RenderActiveShape(props) {
  const {cx, cy, payload} = props;
  const classes = useStyles();

  return (
    <>
      {payload && (
        <text className={classes.chartCurrency} x={cx} y={cy} dy={8} textAnchor="middle">
          {formatCurrency(payload.name)}
        </text>
      )}
    </>
  );
}

RenderActiveShape.propTypes = {};

export default RenderActiveShape;
