import React, {useCallback} from 'react';
import useStyles from './IllustratorHybridPageStyles';
import {useStyleContentEffect} from 'hooks/style-content';
import HybridWizard from './components/wizard/hybrid-wizard/HybridWizard';
import {API_ROUTES} from 'constants/illustrator';
import {useNewIllustration} from 'hooks/illustrator';
import LinearLoader from 'components/common/loader/LinearLoader';
import {LOADING_STATUS} from 'constants/loading';
import {usePrintHook} from 'contexts/print-context';
import {withProducts} from 'components/products';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {withProduct} from 'components/products/product-hoc';
import {productCalculationMutationKey} from 'constants/query';

/**
 *
 * "Illustrator Hybrid" page
 * aka
 * the "EA standalone" page
 * aka
 * the "Equity Avail standalone" page
 *
 */

function IllustratorHybrid(props) {
  const classes = useStyles();

  useStyleContentEffect(classes.content);

  useNewIllustration({defaultData: {ApiRoute: API_ROUTES.HYBRID}});

  const onBeforePrint = useCallback(() => {
    window.scrollTo({top: 0});
  }, []);

  usePrintHook({onBeforePrint});

  return <HybridWizard {...props} DefaultComponent={() => <LinearLoader loading={LOADING_STATUS.PENDING} />} />;
}

IllustratorHybrid.propTypes = {};

const IllustratorHybridProduct = withProduct(IllustratorHybrid);

export const IllustratorHybridPage = React.memo((props) => {
  return (
    <IllustratorHybridProduct
      {...props}
      isSelectedProduct={(product) => product.ApiRoute === API_ROUTES.HYBRID}
      queryKey={[productCalculationMutationKey]}
    />
  );
});

export default withProducts(IllustratorHybridPage, {productFeatureKey: PRODUCT_FEATURE_KEY.STANDALONE_PAGE});
