import React from 'react';
import {Route, Switch} from 'react-router-dom';
import IllustratorHybridPage from 'pages/illustrator-hybrid-page/IllustratorHybridPage';

import {ROUTES} from 'constants/routes';
import AppSidebar from 'components/app-sidebar/AppSidebar';
import IllustratorHybridPDFPage from 'pages/illustrator-hybrid-page/IllustratorHybridPDFPage';

import './App.css';
import FeaturesRoute from 'components/common/routes/FeaturesRoute';
import LinearLoader from 'components/common/loader/LinearLoader';
import SubProviders from 'providers/SubProviders';

function AppEquityAvail(props) {
  const {
    route = ROUTES.ILLUSTRATOR_HYBRID_PAGE,
    pdfRoute = ROUTES.ILLUSTRATOR_HYBRID_PDF_PAGE,
    defaultNS,
    featureRoute = false,
    productFeatureKey,
    productFeatureRedirectPath,
    printType,
    DefaultComponent = LinearLoader,
    ComponentRoute = featureRoute ? FeaturesRoute : Route,
  } = props;

  return (
    <SubProviders defaultNS={defaultNS}>
      <div className="App">
        <AppSidebar showPartnerBanner={featureRoute} showDrawer={featureRoute}>
          <Switch>
            <Route path={pdfRoute} render={(props) => <IllustratorHybridPDFPage {...props} pdfPage={route} />} />
            <ComponentRoute
              DefaultComponent={DefaultComponent}
              productFeatureKey={productFeatureKey}
              productFeatureRedirectPath={productFeatureRedirectPath}
              path={route}
              render={(props) => <IllustratorHybridPage {...props} printType={printType} pdfRoute={pdfRoute} />}
            />
          </Switch>
        </AppSidebar>
      </div>
    </SubProviders>
  );
}

export default AppEquityAvail;
