import React from 'react';
import IconButton from 'components/common/button/IconButton';
import {Typography} from '@material-ui/core';

function SearchButton(props) {
  const {children, size = 'lg', colorVariant = 'primaryDark'} = props;
  return (
    <IconButton size={size} colorVariant={colorVariant} icon={['fas', 'search']} data-testid="search-button">
      <Typography variant="subtitle2">{children}</Typography>
    </IconButton>
  );
}

export default SearchButton;
