import CalculationsApi from 'api/calculations-api';
import {useQuery} from 'react-query';
import {getBorrowerNotesQueryKey} from 'constants/query';
import {useMemo} from 'react';

export function useGetBorrowerNotesQuery(props = {}) {
  const {CalculationId, enabled: moduleEnabled = true} = props;

  const enabled = useMemo(() => {
    if (moduleEnabled) return !!CalculationId;

    return false;
  }, [moduleEnabled, CalculationId]);

  return useQuery([getBorrowerNotesQueryKey], () => CalculationsApi.getBorrowerNotes({CalculationId}), {enabled});
}
