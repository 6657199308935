import {useCallback, useMemo} from 'react';
import {useProductFeaturesQuery} from 'query/product-features';
import {useQueryClient} from 'react-query';
import {productsQueryKey} from 'constants/query';
import {isLoaded, statusToLoading} from 'adapter/loading.adapter';

export function useProductFeatures() {
  const {data: productFeatures = [], status, isFetching} = useProductFeaturesQuery();

  const loading = statusToLoading(status, isFetching);

  const isProductFeatureKeyEnabled = useCallback(
    (ProductFeatureKey) => productFeatures?.find((pf) => pf.ProductFeatureKey === ProductFeatureKey)?.Enabled,
    [productFeatures],
  );

  return {loading, isProductFeatureKeyEnabled};
}

/**
 *
 * @param {string=} ProductFeatureKey
 * @returns {{isEnabled:boolean}}
 */
export function useEnabledProductFeatures(ProductFeatureKey) {
  const {loading, isProductFeatureKeyEnabled} = useProductFeatures();

  const isEnabled = useMemo(
    () => isProductFeatureKeyEnabled(ProductFeatureKey),
    [ProductFeatureKey, isProductFeatureKeyEnabled],
  );

  return {
    loading,
    isEnabled,
    isProductFeatureKeyEnabled,
  };
}

/**
 *
 * @param {string=} ProductFeatureKey
 * @returns {{isEnabled:boolean}}
 */
export function useLoadedEnabledProductFeatures(ProductFeatureKey) {
  const {isEnabled, loading} = useEnabledProductFeatures(ProductFeatureKey);

  return {
    isEnabled: isLoaded({loading}) && isEnabled,
  };
}

export function useProductFeaturesById() {
  const {data: productFeatures = [], status, isFetching} = useProductFeaturesQuery();

  const isProductFeatureEnabled = useCallback(
    (ProductId, ProductFeatureKey) =>
      productFeatures?.find((pf) => pf.ProductFeatureKey === ProductFeatureKey && pf.ProductId === ProductId)?.Enabled,
    [productFeatures],
  );

  const loading = statusToLoading(status, isFetching);

  return {loading, isProductFeatureEnabled};
}

export function useProductFeature() {
  const queryClient = useQueryClient();
  const queryState = queryClient.getQueryState([productsQueryKey], {exact: false});
  const queryData = queryClient.getQueryData([productsQueryKey], {exact: false});

  const queryProducts = queryData?.data;

  const isProductFeatureEnabled = useCallback(
    (apiRoute, featureKey, products = queryProducts) => {
      const prod = products?.find((product) => product.ApiRoute === apiRoute);

      if (!prod?.Enabled) {
        return false;
      }

      const productFeature = prod.ProductFeatures.find((pf) => pf.ProductFeatureKey === featureKey);

      if (!productFeature) {
        return false;
      }

      return productFeature.Enabled;
    },
    [queryProducts],
  );

  const filterEnabledProduct = useCallback(
    (featureKey, products) => {
      return (product = {}) => {
        return isProductFeatureEnabled(product.ApiRoute, featureKey, products);
      };
    },
    [isProductFeatureEnabled],
  );

  return {
    isProductFeatureEnabled,
    filterEnabledProduct,
    loading: statusToLoading(queryState?.status, queryState?.isFetching),
    products: queryProducts,
  };
}
