import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      flexGrow: 1,
      margin: theme.spacing(0),
      display: theme.withPrint('flex', 'block'),
      position: 'relative',
      pageBreakInside: theme.forPrint('auto'),
      [isMobileAppQuery(theme)]: {
        '& .disclaimerContainerMarginTop': {
          marginTop: theme.spacing(2),
        },
      },
    },
    hybridWizardChildrenContainer: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    speedDialConsumerFacing: {
      [isMobileAppQuery(theme)]: {
        bottom: 'calc(100vh - 140px)',
      },
    },
  }),
  {index: 1},
);

export default useStyles;
