import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import {useProductContext} from 'contexts/product-context';
import {useFormContext} from 'react-hook-form';
import {API_ROUTES} from 'constants/illustrator';
import {ToNumber} from 'lib/utils';
import Input from 'components/common/form/input/Input';
import {useQuery} from 'react-query';
import {UpfrontDrawQueryKey} from 'constants/query';
import {isLoaded} from 'adapter/loading.adapter';

function useUpfrontDrawQuery(props) {
  const {
    upfrontDrawPercentage: UDP,
    MaxRevolvingLoc: MRL,
    PrincipalLimit: PL,
    ClosingCost,
    MinimumInitialDraw,
    Liens,
    loading,
    ApiRoute,
    ...rest
  } = props;

  const checkDraw = useMemo(() => ToNumber(ClosingCost) + ToNumber(Liens), [ClosingCost, Liens]);
  const InitialDraw = useMemo(() => ToNumber(MinimumInitialDraw), [MinimumInitialDraw]);
  const upfrontDrawPercentage = useMemo(() => ToNumber(UDP), [UDP]);
  const MaxRevolvingLoc = useMemo(() => ToNumber(MRL), [MRL]);
  const PrincipalLimit = useMemo(() => ToNumber(PL), [PL]);
  const MinInitialDraw = useMemo(() => (checkDraw > InitialDraw ? checkDraw : InitialDraw), [InitialDraw, checkDraw]);

  const queryKey = useMemo(
    () => [UpfrontDrawQueryKey, upfrontDrawPercentage, MaxRevolvingLoc, PrincipalLimit, MinInitialDraw],
    [upfrontDrawPercentage, MaxRevolvingLoc, PrincipalLimit, MinInitialDraw],
  );

  return useQuery({
    queryKey,
    enabled: isLoaded({loading}) && ApiRoute === API_ROUTES.SELECT,
    queryFn() {
      return new Promise((resolve) => {
        const TotalUpfrontDraw = ToNumber(MaxRevolvingLoc * (upfrontDrawPercentage / 100));

        const CashAtClose =
          checkDraw < MinInitialDraw || TotalUpfrontDraw > MinInitialDraw
            ? TotalUpfrontDraw - checkDraw
            : checkDraw - TotalUpfrontDraw;

        return resolve({
          TotalUpfrontDraw: ToNumber(TotalUpfrontDraw),
          CashAtClose: ToNumber(CashAtClose),
          LineOfCredit: ToNumber(PrincipalLimit - TotalUpfrontDraw),
        });
      });
    },
    ...rest,
  });
}

function useUpfrontDrawPercentage() {
  const {product, loading} = useProductContext();
  const {watch, setValue, getValues, triggerSubmit} = useFormContext();

  const [
    upfrontDrawPercentage,
    MaxRevolvingLoc,
    PrincipalLimit,
    ClosingCost,
    MinimumInitialDraw,
    Liens,
    TotalUpfrontDraw,
    CashAtClose,
    LineOfCredit,
  ] = watch([
    'upfrontDrawPercentage',
    'MaxRevolvingLoc',
    'PrincipalLimit',
    'ClosingCost',
    'MinimumInitialDraw',
    'BorrowerProfile.Liens',
    'TotalUpfrontDraw',
    'CashAtClose',
    'LineOfCredit',
  ]);
  const ApiRoute = useMemo(() => product?.ApiRoute, [product?.ApiRoute]);

  const ref = useRef();
  useImperativeHandle(
    ref,
    () => ({
      TotalUpfrontDraw,
      CashAtClose,
      LineOfCredit,
    }),
    [TotalUpfrontDraw, CashAtClose, LineOfCredit],
  );

  const select = useCallback(
    (response) => ({
      TotalUpfrontDraw: ToNumber(response.TotalUpfrontDraw),
      CashAtClose: ToNumber(response.CashAtClose),
      LineOfCredit: ToNumber(response.LineOfCredit),
    }),
    [],
  );

  const {data = {}} = useUpfrontDrawQuery({
    upfrontDrawPercentage,
    MaxRevolvingLoc,
    PrincipalLimit,
    ClosingCost,
    MinimumInitialDraw,
    Liens,
    loading,
    ApiRoute,
    select,
  });

  useEffect(() => {
    Object.keys(data).forEach((key) => {
      if (ToNumber(ref.current?.[key]) !== ToNumber(data[key])) {
        setValue(key, data[key], {shouldValidate: false});
      }
    });
  }, [data]);

  const onChangeCommitted = useCallback(() => {
    triggerSubmit();
  }, [triggerSubmit]);

  return {onChangeCommitted};
}

function UpfrontDrawPercentage(props) {
  const {onChangeCommitted} = useUpfrontDrawPercentage();

  return <Input {...props} name="upfrontDrawPercentage" type="range" onChangeCommitted={onChangeCommitted} />;
}

export default UpfrontDrawPercentage;
