import {makeStyles} from '@material-ui/core/styles';
import {isSmSizeAppQuery, isTabletAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    illustrationSharePaperContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      [isTabletAppQuery(theme)]: {
        paddingBottom: theme.spacing(4),
      },
    },
    illustrationShareIconContainer: {
      marginTop: theme.spacing(1),
      color: theme.palette.primary.dark,
    },
    buttonContainerStyling: {
      width: '100%',
      maxWidth: '250px',
      marginBottom: theme.spacing(1),
      alignSelf: 'center',
    },
    buttonContainerStylingBottom: {
      marginBottom: 'auto !important',
    },
    marginTop: {
      marginTop: '20%',
      marginBottom: theme.spacing(1),
      [isSmSizeAppQuery(theme)]: {
        marginTop: '5%',
      },
    },
    marginBottom: {
      marginBottom: theme.spacing(1),
      [isSmSizeAppQuery(theme)]: {
        marginBottom: theme.spacing(4),
      },
    },
    fontWeight500: {
      fontWeight: '500 !important',
    },
  }),
  {index: 1},
);

export default useStyles;
