import React from 'react';
import Form from 'components/common/form/Form';
import Item from 'components/common/layout/item/Item';
import DatePicker from 'components/common/form/date-picker/DatePicker';
import Input from 'components/common/form/input/Input';
import SubmitButton from 'components/common/form/SubmitButton';
import {isPending} from 'adapter/loading.adapter';
import {Trans} from 'lib/i18n';
import Container from 'components/common/layout/container/Container';
import {useOwnFormContext} from 'contexts/form-context';

CalculatorFormElement.propTypes = {};

function CalculatorFormElement(props) {
  const {onSubmit, defaultValues, loading} = props;
  const {validationSchema} = useOwnFormContext();

  return (
    <Container
      component={Form}
      spacing={3}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      <Input name="OverrideClosingCosts" type="hidden" defaultValue={true} />
      <Input name="ClosingCost" type="hidden" defaultValue={0} />
      <Item xs={12} sm={6} md={3}>
        <DatePicker name="BorrowerProfile.DateOfBirth" type="datepicker" />
      </Item>
      <Item xs={12} sm={6} md={3}>
        <Input name="BorrowerProfile.ValueOfProperty" type="currency" />
      </Item>
      <Item xs={12} sm={6} md={3}>
        <Input name="BorrowerProfile.Liens" type="currency" label="Total Mortgage Balance *" />
      </Item>
      <Item xs={12} sm={6} md={3}>
        <Input name="BorrowerProfile.Zip" type="zip" />
      </Item>
      <Item xs={12} style={{textAlign: 'center'}}>
        <SubmitButton btnStyle="bgElectric" disabled={isPending({loading})} showProgress>
          <Trans i18nKey="calculator.submit button" />
        </SubmitButton>
      </Item>
    </Container>
  );
}

export default CalculatorFormElement;
