export const HECMFixedRatesQueryKey = 'HECMFixedRatesQuery';
export const UpfrontDrawQueryKey = 'upfrontDrawQueryKey';

export const productFeaturesQueryKey = 'productFeaturesQuery';

export const productsQueryKey = 'productsQuery';
export const productCalculationMutationKey = 'productCalculationMutation';
export const productCalculationQueryKey = 'productCalculationQuery';
export const productsComparisonQueryKey = 'productsComparisonQuery';

export const showEquityAvailProductQueryKey = 'showEquityAvailProductQuery';
export const showForwardRefiProductQueryKey = 'showForwardRefiProductQuery';

export const useGetCalculationByIdQueryKey = 'getCalculationByIdQuery';
export const useGetCalculationByIdMutationKey = 'getCalculationByIdMutation';

export const paymentTypesQueryKey = 'paymentTypesQuery';

export const loanPriorityTypesQueryKey = 'loanPriorityTypesQuery';

export const withdrawalMethodsQueryKey = 'withdrawalMethodsQuery';

export const additionalBorrowerPreferencesQueryKey = 'additionalBorrowerPreferencesQuery';

export const HECMAvailableRatesQueryKey = 'HECMAvailableRatesQuery';
export const homeSafeSelectRatesQueryKey = 'homeSafeSelectRatesQuery';
export const homeSafeTierQueryKey = 'homeSafeTierQuery';
export const homeSafeMetricsQueryKey = 'homeSafeMetricsQuery';

export const savedIllustrationsQueryKey = 'savedIllustrationsQuery';
export const saveCalculationMutationKey = 'saveCalculationMutation';
export const saveBorrowerNotesMutationKey = 'saveBorrowerNotesMutation';
export const getBorrowerNotesQueryKey = 'getBorrowerNotesQuery';
export const getBorrowerPreferencesQueryKey = 'getBorrowerPreferencesQuery';
export const deleteIllustrationsMutationKey = 'deleteIllustrationsMutation';
export const linkLoansQueryKey = 'linkLoansQuery';
export const linkLoanDetailsQueryKey = 'linkLoanDetailsQuery';

export const getProductStateDobValidationQueryKey = 'getProductStateDobValidationQuery';
export const getProductStateDobValidationMutationKey = 'getProductStateDobValidationMutation';
export const getApiKeyValidationQueryKey = 'getApiKeyValidationQuery';

export const savedBorrowerProfilesQueryKey = 'savedBorrowerProfilesQuery';
export const saveBorrowerProfileInfoMutationKey = 'saveBorrowerProfileInfoMutation';
export const savedBorrowerProfilesDeleteMutationKey = 'savedBorrowerProfilesDeleteMutation';
export const saveExistingBorrowerProfileMutationKey = 'saveExistingBorrowerProfileMutation';

export const getBorrowerProfileQueryKey = 'getBorrowerProfileQuery';

export const useGetBorrowerProfileByIdQueryKey = 'getBorrowerProfileByIdQuery';
export const useGetBorrowerProfileByIdMutationKey = 'getBorrowerProfileByIdMutation';

export const userRolesFeatureKeyQueryKey = 'userRolesFeatureKeyQuery';
export const userRolesQueryKey = 'userRolesQuery';
export const usStatesQueryKey = 'usStatesQuery';
export const contentQueryKey = 'contentQuery';

export const hybridMetricsLatestQueryKey = 'hybridMetricsLatestQuery';

// illustrator
export const saveEmailIllustrationsMutationKey = 'saveEmailIllustrationsMutation';
export const shareIllustrationsMutationKey = 'shareIllustrationsMutation';
export const getPreviewPdfQueryKey = 'getPreviewPdfQuery';

// illustration note query key
export const getIllustrationNoteQueryKey = 'getIllustrationNoteQuery';

export const saveTotalExpertMutationKey = 'saveTotalExpertMutation';

export const smartyAutoCompleteQueryKey = 'smartyAutoCompleteQuery';

export const linkUserLicenseValidationMutationKey = 'linkUserLicenseValidationMutation';

export const oktaUserInfoQueryKey = 'oktaUserInfoQuery';
export const versionExposeQueryKey = 'versionExposeQuery';

export const autoLoginKey = 'autoLogin';
