import React, {useCallback, useMemo} from 'react';
import useStyles from 'pages/illustrator/components/product-cards/components/product-card/ProductCardStyles';
import {Box, Card, CardActionArea, CardContent, CardMedia, Chip} from '@material-ui/core';
import ProductResult from './components/product-result/ProductResult';
import {useProductCardContext} from 'contexts/product-card-context';
import {useProductDetails} from 'hooks/comparison/product-details';
import {useProductInfo} from 'hooks/comparison/product-info';
import clsx from 'clsx';
import Button from 'components/common/button/Button';
import {isPending} from 'adapter/loading.adapter';
import {useTranslation} from 'lib/i18n';
import {useSkipBorrowerAdditionalStep} from 'lib/borrower';
import {useHistory} from 'react-router-dom';
import {ROUTES} from 'constants/routes';
import {isProductCalculated} from 'lib/product';
import Text from 'components/common/typeography/text/Text';
import {useProductContext} from 'contexts/product-context';
import {useRecommendedProductContext} from 'contexts/recommended-product-context';
import Product from 'components/products/product';
import {useStartApplication} from 'hooks/start-application';
import {noop} from 'lodash';

/**
 *
 * !!! This function is not exported !!!
 *
 * It is used as a helper component.
 *
 */
function ProductCardBase(props = {}) {
  const {
    product = {},
    product: {calculation: {CalculationId, hideModelImpactButton, showLearnMore} = {}} = {},
    illustrateProduct,
    useProductData = noop,
    showDetails = true,
    setHasSubmitted,
  } = props;

  const classes = useStyles();

  const {t} = useTranslation();
  const history = useHistory();

  const {recommendedCalculationId} = useRecommendedProductContext();

  const {productDetails: {description: descriptionMessage, additionalStep, skipAdditionalStep, img, imgText} = {}} =
    useProductContext();
  const {productLoaded, tableData} = useProductData(props);

  const {shouldSkip: skipBorrowerAdditionalStep} = useSkipBorrowerAdditionalStep({additionalStep, skipAdditionalStep});

  const {description, descriptionRef} = useProductCardContext();

  const {showStartApplicationButton, onStartApplicationButtonClick} = useStartApplication({setHasSubmitted});

  const showIllustrateButton = useMemo(() => !hideModelImpactButton, [hideModelImpactButton]);
  const showLearnMoreButton = useMemo(() => showLearnMore, [showLearnMore]);

  const onIllustrateProductClick = useCallback(
    (event) => {
      if (event) {
        event.persist();
      }

      if (showIllustrateButton && product)
        return illustrateProduct({skipBorrowerAdditionalStep, ...product}, undefined, event);
    },
    [showIllustrateButton, product, illustrateProduct, skipBorrowerAdditionalStep],
  );

  const onShowLearnMoreButtonClick = useCallback(
    (event) => {
      event.persist();
      event.stopPropagation();

      onIllustrateProductClick(event);

      if (showLearnMoreButton) return history.push(ROUTES.ILLUSTRATOR_HYBRID_PAGE);
    },
    [history, showLearnMoreButton, onIllustrateProductClick],
  );

  return (
    <Card
      className={clsx(classes.productCard, {[classes.hide]: !isProductCalculated(product)})}
      data-testid={`${product.ProductValue}-card`}
    >
      <CardActionArea disabled={!showDetails} className={clsx(classes.productCardActionArea)} component="div">
        <Box className={clsx(classes.productCardImageContainer, {[classes.productCardImageContainerNoText]: !imgText})}>
          {img && (
            <CardMedia
              className={clsx(classes.productCardImage, {[classes.productCardImageSmall]: !showDetails})}
              image={img}
            />
          )}

          {imgText && (
            <Text className={classes.productCardName}>
              {imgText}
            </Text>
          )}
        </Box>

        <Chip
          className={clsx(classes.productCardRecommendedChip, {
            [classes.hide]: recommendedCalculationId !== CalculationId,
          })}
          data-testid="recommended-chip"
          label={t('illustratorTranslation:product comparison.recommended')}
        />

        <CardContent className={clsx(classes.productCardCardContent)}>
          <Box
            className={clsx(classes.productCardDescriptionContainer, {[classes.hide]: !showDetails})}
            style={description}
          >
            <Text className={clsx(classes.productCardDescriptionRoot)} ref={descriptionRef}>
              {descriptionMessage}
            </Text>
          </Box>

          <ProductResult
            className={clsx({[classes.productCardSmallResults]: !showDetails})}
            items={tableData}
            enabled={productLoaded}
          />

          <Box className={clsx(classes.productCardButtonContainer, {[classes.hide]: !showDetails})}>
            {/* the "Learn More" a.k.a. the "Present" button */}
            {showLearnMoreButton && (
              <Button
                className={clsx(classes.productCardButtonRoot, classes.productCardAlign)}
                component="p"
                type="button"
                disabled={isPending(product)}
                onClick={onShowLearnMoreButtonClick}
                data-testid={`preset-${product.ProductValue}-button`}
              >
                {t('illustratorTranslation:product comparison.present')}
              </Button>
            )}
            {/* Illustrate button */}
            {showIllustrateButton && (
              <Button
                className={classes.productCardButtonRoot}
                buttonStyling={{lg: true}}
                type="button"
                component="p"
                btnStyle="bgElectric"
                disabled={isPending(product)}
                onClick={onIllustrateProductClick}
                data-testid={`illustrate-${product.ProductValue}-button`}
              >
                {t('illustratorTranslation:product comparison.illustrate')}
              </Button>
            )}
            {/* the Link "Start Application" button */}
            {showStartApplicationButton && showLearnMore && (
              <Button
                className={classes.productCardButtonRoot}
                buttonStyling={{lg: true}}
                type="button"
                component="p"
                disabled={isPending(product)}
                onClick={() =>
                  onStartApplicationButtonClick({
                    navigationAttemptModal: false,
                    originUrl: ROUTES.ILLUSTRATOR_ROOT_PAGE,
                  })
                }
                data-testid={`start-application-${product.ProductValue}-button`}
              >
                {t('illustratorTranslation:product comparison.start application')}
              </Button>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

/**
 *
 * the "main" exported function/s
 *
 */

export function ProductCard(props) {
  return (
    <Product product={props.product}>
      <ProductCardBase {...props} useProductData={useProductDetails} showDetails={true} />
    </Product>
  );
}

export function ProductCardInfo(props) {
  return (
    <Product product={props.product}>
      <ProductCardBase {...props} useProductData={useProductInfo} showDetails={false} />
    </Product>
  );
}
