import React, {useEffect, useState} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import Item from 'components/common/layout/item/Item';
import useStyles from 'components/illustrator/modal-email-to-borrower/components/email-illustrations/components/email-illustrations-fields/EmailIllustrationsFieldsStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import {IconButton, TableCell, useTheme} from '@material-ui/core';
import Input from 'components/common/form/input/Input';
import Button from 'components/common/button/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Container from 'components/common/layout/container/Container';

function useEmailIllustrationsFields(props) {
  const {control} = useFormContext();

  const name = 'eiFormList';

  const {fields, append, remove} = useFieldArray({control, name});

  return {
    fields,
    append,
    remove,
  };
}

function EmailIllustrationsFields(props = {}) {
  // props
  const {fields, append, remove} = useEmailIllustrationsFields(props);

  const theme = useTheme();
  const classes = useStyles();

  // max number of records allowed
  const maxRecords = 5;
  const [disabledAddOneRecord, setDisabledAddOneRecord] = useState(false);

  const addOneRecord = () => {
    if (fields.length < maxRecords) {
      append({
        emailAddress: '',
      });
    }
  };

  const removeOneRecord = (index) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  /**
   *
   * There is a max limit of records
   *
   * check & activate/deactivate the "add another property" button, when the `state` updates
   *
   */

  useEffect(() => {
    if (fields.length < maxRecords) {
      setDisabledAddOneRecord(false);
    } else {
      setDisabledAddOneRecord(true);
    }
  }, [fields, setDisabledAddOneRecord, maxRecords]);

  return (
    <>
      <Table>
        <TableBody>
          {fields.map((field, index) => (
            <TableRow key={field.id}>
              <TableCell align="left" className={classes.tableCell}>
                <Input
                  name={`eiFormList[${index}].emailAddress`}
                  variant="standard"
                  label=""
                  defaultValue={field?.emailAddress}
                  className={classes.customInputElement}
                />
                {fields.length > 1 ? (
                  <div className={classes.relativeEl}>
                    <div className={classes.absoluteEl}>
                      <IconButton onClick={() => removeOneRecord(index)}>
                        <FontAwesomeIcon icon={['fas', 'window-close']} color={theme.palette.error.main} />
                      </IconButton>
                    </div>
                  </div>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {fields.length < maxRecords ? (
        <Container>
          <Item xs={12}>
            <Button variant="text" onClick={() => addOneRecord()} disabled={disabledAddOneRecord}>
              + add another email address
            </Button>
          </Item>
        </Container>
      ) : null}
    </>
  );
}

export default EmailIllustrationsFields;
