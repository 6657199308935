import {useQuery} from 'react-query';
import LinkLoansApi from 'api/link-loans-api';
import {statusToLoading} from 'adapter/loading.adapter';
import {linkLoansQueryKey} from 'constants/query';
import {useOktaAuth} from '@okta/okta-react';

export function useLinkLoansQuery() {
  const {
    authState: {isAuthenticated},
    oktaAuth: {
      token: {getUserInfo},
    },
  } = useOktaAuth();
  const {
    data: linkLoansData,
    status,
    isFetching,
  } = useQuery([linkLoansQueryKey, getUserInfo, isAuthenticated], async () => {
    return LinkLoansApi.getLoans();
  });

  const loading = statusToLoading(status, isFetching);

  return {data: linkLoansData, loading};
}
