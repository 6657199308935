import React from 'react';
import Input from 'components/common/form/input/Input';
import {useProductsContext} from 'contexts/products-context';
import ProductSelectionImage from 'pages/illustrator-borrower/product-selection/components/product-selection-image/ProductSelectionImage';
import Product from 'components/products/product';
import Item from 'components/common/layout/item/Item';
import {useDeepMemo} from 'hooks/utils';

function ProductSelectionOption(props) {
  const {product, value, onChange, radioGroupOptionClassName, dataTestId} = props;

  return (
    <Item xs={12} sm={6} md={4} className={radioGroupOptionClassName}>
      <Product product={product}>
        <ProductSelectionImage value={value} onChange={onChange} dataTestId={dataTestId} />
      </Product>
    </Item>
  );
}

function ProductSelection(props) {
  const {className, radioGroupClassName, radioGroupOptionClassName} = props;
  const {products = []} = useProductsContext();

  const options = useDeepMemo(
    () =>
      products.map((product) => (
        <ProductSelectionOption
          key={product.ProductName}
          product={product}
          radioGroupOptionClassName={radioGroupOptionClassName}
          dataTestId={`product-selection-option-${product.ProductValue}`}
        />
      )),
    [products, radioGroupOptionClassName],
  );

  return (
    <Input
      row
      radioGroupClassName={radioGroupClassName}
      label=""
      type="radio-group"
      name="ApiRoute"
      className={className}
      options={options}
    />
  );
}

export default ProductSelection;
