import {useMemo} from 'react';
import {API_ROUTES} from 'constants/illustrator';
import {useProductContext} from 'contexts/product-context';

// Government Regulation #1003 to be used for the EA aka "Hybrid" product
export const GovernmentRegulation_BorrowerFlow_Declarations_1003 = 1003;

// Government Regulation #1009 to be used for the "HomeSafe Second" product
export const GovernmentRegulation_BorrowerFlow_Declarations_1009 = 1009;

/**
 *
 * Government Regulation for any Product
 *
 */

export default function useGovernmentRegulationForProduct() {
  const {product} = useProductContext();

  const regulation = useMemo(() => {
    if (product?.ApiRoute) {
      if (product.ApiRoute === API_ROUTES.HYBRID) {
        return GovernmentRegulation_BorrowerFlow_Declarations_1003;
      }

      if (product.ApiRoute === API_ROUTES.SECOND) {
        return GovernmentRegulation_BorrowerFlow_Declarations_1009;
      }
    }

    return '';
  }, [product]);

  return regulation;
}
