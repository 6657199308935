import React, {useCallback} from 'react';
import {NumericFormat as NumberFormat} from 'react-number-format';
import {dollarToNumber} from 'lib/currency';
import {useOnChangeInterceptor} from 'components/common/form/input/components/OnChangeInterceptor';
import TextField from 'components/common/form/input/components/TextField';
import {useElementFocus} from 'hooks/input';
import {isFunction} from 'lodash';

export const currencySymbol = '$';

function CurrencyInputComponent(props) {
  const {inputRef, onChange, fixedDecimalScale, ...other} = props;

  const {doChange} = useOnChangeInterceptor({
    onChange,
    getValue(event) {
      let value = dollarToNumber(event.target.value);
      if (isNaN(value)) value = '';

      return value;
    },
  });

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onChange={doChange}
      thousandSeparator
      valueIsNumericString
      decimalScale={2}
      fixedDecimalScale={fixedDecimalScale}
      prefix={currencySymbol}
    />
  );
}

function Currency(props) {
  const {onFocus, onBlur, onCustomChange, onChange, ...rest} = props;

  const {isFocused, ...focusEvents} = useElementFocus({onBlur, onFocus});

  const doChange = useCallback(
    (event) => {
      if (isFunction(onChange)) onChange(event);
      if (isFunction(onCustomChange)) onCustomChange(event);
    },
    [onChange, onCustomChange],
  );

  return (
    <TextField
      {...rest}
      {...focusEvents}
      onChange={doChange}
      type={undefined}
      inputProps={{
        ...rest?.inputProps,
        fixedDecimalScale: !isFocused,
        isAllowed: rest?.isAllowed,
      }}
      InputProps={{
        ...rest?.InputProps,
        inputComponent: CurrencyInputComponent,
      }}
    />
  );
}

Currency.propTypes = {};

export default Currency;
