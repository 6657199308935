import {useMemo} from 'react';
import {formatCurrency, formatPercent} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import {getBorrowerBirthDate} from 'lib/borrower';
import {parentheses} from 'lib/utils';
import {isPaymentBreakdownValueNegative, formatPaymentBreakdownValue} from 'lib/product';

export function useFixedOverallResults(props = {}) {
  const {
    product: {
      calculation: {
        BorrowerProfile: {Age, DateOfBirth, Liens, ValueOfProperty} = {},
        PrincipalLimit,
        ClosingCost,
        CashAtClose,
        SimplifiedCashToBorrower,
        InitialLoanBalance,
        UnutilizedCash,
        Rate,
        MIP,
        TotalInitialRate,
        InitialRate,
        UnavailableHUDLimitProceeds
      } = {},
    } = {},
  } = props;

  const {t, cashLabel} = useTranslation();

  const isShortToClose = useMemo(() => {
    return SimplifiedCashToBorrower < 0 ? true : false;
  }, [SimplifiedCashToBorrower]);

  const availableCashSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.total proceeds'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.total proceeds tooltip'),
        value: formatCurrency(PrincipalLimit),
        order: 2,
        id: 'availableCashSection-total-proceeds',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.total mortgages liens'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.total mortgages liens tooltip'),
        value: parentheses(formatCurrency(Liens)),
        useColorOptions: {value: 'red'},
        order: 3,
        id: 'availableCashSection-total-mortgage-balance-liens',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.estimated closing costs'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.estimated closing costs tooltip'),
        value: parentheses(formatCurrency(ClosingCost)),
        useColorOptions: {value: 'red'},
        order: 4,
        id: 'availableCashSection-estimated-closing-cost',
        highlight: false,
      },
      {
        label: cashLabel(isShortToClose ? 'illustratorTranslation:illustration.overall results.cash due at close' : 'illustratorTranslation:illustration.overall results.available cash', {
          cash: SimplifiedCashToBorrower,
        }),
        tooltip: t(isShortToClose ? 'illustratorTranslation:illustration.overall results.override.fixed.cash due at close tooltip' : 'illustratorTranslation:illustration.overall results.override.fixed.available cash tooltip'),
        value: formatPaymentBreakdownValue(SimplifiedCashToBorrower),
        useColorOptions: {value: isPaymentBreakdownValueNegative(SimplifiedCashToBorrower) ? 'red' : undefined},
        order: 5,
        id: 'availableCashSection-available-cash',
        highlight: true,
      },
      {
        label: cashLabel('illustratorTranslation:illustration.overall results.unavailable hud limit proceeds', {
          cash: UnavailableHUDLimitProceeds,
        }),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.unavailable hud limit proceeds tooltip'),
        value: formatPaymentBreakdownValue(UnavailableHUDLimitProceeds),
        useColorOptions: {value: isPaymentBreakdownValueNegative(UnavailableHUDLimitProceeds) ? 'red' : undefined},
        order: 6,
        id: 'availableCashSection-unavailable-hud-limit-proceeds',
        highlight: false,
      },
    ],
    [t, PrincipalLimit, Liens, ClosingCost, cashLabel, SimplifiedCashToBorrower, UnavailableHUDLimitProceeds],
  );

  const initialLoanBalanceSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.mortgage liens payoff'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.mortgage liens payoff tooltip'),
        value: formatCurrency(Liens),
        order: 2,
        id: 'initialLoanBalanceSection-mortgage-liens-payoff',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.estimated closing costs'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.estimated closing costs tooltip'),
        value: formatCurrency(ClosingCost),
        order: 3,
        id: 'initialLoanBalanceSection-estimated-closing-cost',
        highlight: false,
      },
      {
        label: cashLabel(isShortToClose ? 'illustratorTranslation:illustration.overall results.cash due at close' : 'illustratorTranslation:illustration.overall results.requested cash', {
          cash: SimplifiedCashToBorrower,
        }),
        tooltip: t(isShortToClose ? 'illustratorTranslation:illustration.overall results.override.fixed.cash due at close tooltip' : 'illustratorTranslation:illustration.overall results.override.fixed.requested cash tooltip'),
        value: formatPaymentBreakdownValue(SimplifiedCashToBorrower),
        useColorOptions: {value: isPaymentBreakdownValueNegative(SimplifiedCashToBorrower) ? 'red' : undefined},
        order: 4,
        id: 'initialLoanBalanceSection-requested-cash',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.initial loan balance'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.initial loan balance tooltip'),
        value: formatCurrency(InitialLoanBalance),
        order: 5,
        id: 'initialLoanBalanceSection-initial-loan-balance',
        highlight: true,
      },
    ],
    [t, Liens, ClosingCost, cashLabel, CashAtClose, InitialLoanBalance, SimplifiedCashToBorrower],
  );

  const homeInformationSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.estimated home value'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.estimated home value tooltip'),
        value: formatCurrency(ValueOfProperty),
        order: 2,
        id: 'homeInformationSection-estimated-home-value',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.current mortgage liens'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.current mortgage liens tooltip'),
        value: formatCurrency(Liens),
        order: 3,
        id: 'homeInformationSection-current-mortgage-liens',
      },
    ],
    [t, ValueOfProperty, Liens],
  );

  const youngestBorrowerSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.birth date'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.birth date tooltip'),
        value: getBorrowerBirthDate(DateOfBirth),
        order: 2,
        id: 'youngestBorrowerSection-birth-date',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.nearest age'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.nearest age tooltip'),
        value: Age,
        order: 3,
        id: 'youngestBorrowerSection-nearest-age',
      },
    ],
    [t, DateOfBirth, Age],
  );

  const loanDetailsSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.interest rate'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.interest rate tooltip'),
        value: formatPercent(InitialRate),
        order: 2,
        id: 'loanDetailsSection-interest-rate',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.mip'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.mortgage insurance premium tooltip'),
        value: formatPercent(MIP),
        order: 3,
        id: 'loanDetailsSection-mip',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.total accrual rate'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.fixed.total accrual rate tooltip'),
        value: formatPercent(TotalInitialRate), 
        order: 4,
        id: 'loanDetailsSection-total-accrual-rate',
      },
    ], [t, InitialRate, MIP, TotalInitialRate]
  );

  return {
    availableCashSection,
    initialLoanBalanceSection,
    homeInformationSection,
    youngestBorrowerSection,
    loanDetailsSection,
  };
}
