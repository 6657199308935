import {makeStyles} from '@material-ui/core/styles';
import {isTabletAppQueryUp} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    productDetailsModalContainer: {
      textAlign: 'left',
    },
    productDetailsContainer: {
      zIndex: theme.zIndex.drawer,
      position: 'absolute',
      backgroundColor: theme.palette.common.white,

      [isTabletAppQueryUp(theme)]: {
        minWidth: theme.spacing(50),
      },
      padding: '0 !important',
    },
    productDetailsPaper: {
      padding: theme.spacing(2.5, 5),
    },
    productDetailsCloseContainer: {
      textAlign: 'right',
    },
  }),
  {index: 1},
);

export default useStyles;
