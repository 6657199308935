import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    customFormatterContainer: {},
    icon: {
      position: 'absolute',
      zIndex: 99999999,
    },
    customFormatterIcon: {
      right: theme.spacing(2.5),
      bottom: theme.spacing(0.25),
      cursor: 'pointer',
    },
    errorIcon: {
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      cursor: 'not-allowed',
      color: theme.palette.error.main,
    },
  }),
  {index: 1},
);

export default useStyles;
