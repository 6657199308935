import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    root: (props) => ({
      bottom: -25,
      fontWeight: 600,
      textAlign: props.align ? `${props.align} !important` : null
    }),
  }),
  {index: 1},
);

export default useStyles;
