import React, {useState} from 'react';
import ContentSection from 'pages/illustrator-hybrid-page/components/content-section/ContentSection';
import BarChart from 'components/common/charts/bar-chart';
import {usePrintingValue} from 'hooks/utils';

function MortgageChartSection(props) {
  const {children, id} = props;

  const [chartData, setChartData] = useState([]);
  const [chartDisclaimer, setChartDisclaimer] = useState(null);
  const margin = usePrintingValue(undefined, {top: 30, right: 30, left: 120, bottom: 30});

  return (
    <ContentSection
      id={id}
      chartProps={{
        chartDisclaimer,
      }}
      chart={<BarChart data={chartData} margin={margin} />}
    >
      {React.Children.map(children, (child) => React.cloneElement(child, {setChartData, setChartDisclaimer}))}
    </ContentSection>
  );
}

MortgageChartSection.propTypes = {};

export default MortgageChartSection;
