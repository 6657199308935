import React, {useMemo} from 'react';
import Button from 'components/common/button/Button';
import {useFormContext} from 'react-hook-form';

SubmitButton.propTypes = {};

/**
 *
 * @param {object} props
 * @param {?boolean} props.watchSubmit - possibility to disable form watch
 * @param {?boolean} props.disabled
 * @param {object} props
 * @return {JSX.Element}
 * @constructor
 */
function SubmitButton(props) {
  const {children, disabled: buttonDisabled, watchSubmit = true, ...rest} = props;

  const {formState: {isSubmitting} = {}} = useFormContext();

  const disabled = useMemo(() => {
    if (buttonDisabled) return buttonDisabled;
    if (watchSubmit) return isSubmitting;
    return false;
  }, [isSubmitting, buttonDisabled, watchSubmit]);

  return (
    <Button type="submit" disabled={disabled} {...rest}>
      {children}
    </Button>
  );
}

export default React.memo(SubmitButton);
