import React, {useCallback, useMemo, useEffect} from 'react';
import {Paper} from '@material-ui/core';
import {Trans, useTranslation} from 'lib/i18n';
import clsx from 'clsx';
import IllustrationExtraDetails from 'components/illustrator/illustration-extra-details/IllustrationExtraDetails';
import Input from 'components/common/form/input/Input';
import useStyles from 'components/illustrator/borrower-notes-modal/components/borrower-notes-illustrations-select-dialog/BorrowerNotesIllustrationsSelectDialogStyles';
import yup from 'lib/validation';
import Text from 'components/common/typeography/text/Text';
import {useDialogStateMutation, withDialogState} from 'hooks/dialog';
import Form from 'components/common/form/Form';
import {useSnackbar} from 'notistack';
import {useSaveBorrowerNotesMutation} from 'query/borrower/save-borrower-notes';
import {useIllustrationPDFPrint} from 'hooks/print';
import {useProductContext} from 'contexts/product-context';
import {useIllustrationProduct} from 'hooks/illustration-product';
import {LOADING_STATUS} from 'constants/loading';
import {isPending} from 'adapter/loading.adapter';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import {generatePath} from 'lib/react-router-dom';
import {ROUTES} from 'constants/routes';
import {trackEvent} from '../../../../../services/analytics.service';
import {SCENARIO_CATEGORIES, SCENARIO_ACTIONS} from '../../../../../constants/analytic-events';

function BorrowerNotesIllustrationsSelectDialog(props) {
  const {state, doClose, setState} = props;
  const {isDownloadPdf, multipleProductsSelected} = state;

  useEffect(() => {
    if (isDownloadPdf) {
      if (multipleProductsSelected) {
        trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_DOWNLOAD_PROD_SELECT);
      } else {
        trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_DOWNLOAD_START);
      }
    } else {
      if (multipleProductsSelected) {
        trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_SHARE_ILLUSTRATION_PROD_SELECT);
      } else {
        trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_SHARE_ILLUSTRATION_START);
      }
    }
  }, [isDownloadPdf, multipleProductsSelected]);

  const classes = useStyles();

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const {product: {calculation: {CalculationId} = {}} = {}} = useProductContext();

  const saveBorrowerNotesMutation = useSaveBorrowerNotesMutation();
  const printPdf = useIllustrationPDFPrint(CalculationId, {});

  const doSubmit = useCallback(
    async (data) => {
      printPdf.setLoading(LOADING_STATUS.PENDING);
      const actions = state.illustrationsToShare.map(async (illustration) => {
        const notes = data[illustration.id]?.note;

        if (notes?.length) {
          await saveBorrowerNotesMutation.mutateAsync({notes, CalculationId: illustration.CalculationId});
        }

        return illustration;
      });

      Promise.all(actions)
        .then((illustrations) => {
          return Promise.all(
            illustrations.map((illustration) => {
              return printPdf.handlePDF({
                illustration,
                src: generatePath(ROUTES.ILLUSTRATOR_PDF_PAGE, {CalculationId: illustration.CalculationId}),
              });
            }),
          );
        })
        .then(() => {
          if (state.isDownloadPdf) {
            trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_DOWNLOAD_END);
          } else {
            trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_SHARE_ILLUSTRATION_END);
          }

          doClose();
        })
        .catch((e) => {
          enqueueSnackbar(t('illustrationShareModalTranslation:save notes failed'), {variant: 'error'});
        });
    },
    [printPdf, state.illustrationsToShare, state.isDownloadPdf, saveBorrowerNotesMutation, doClose, enqueueSnackbar, t],
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        ...state.illustrationsToShare
          .map((illustration) => illustration.id)
          .reduce(
            (a, v) => ({
              ...a,
              [v]: yup.object({
                note: yup.string().notRequired().max(256),
              }),
            }),
            {},
          ),
      }),
    [state.illustrationsToShare],
  );

  const {getProduct} = useIllustrationProduct();

  const showProgressLoading = useMemo(() => isPending({loading: printPdf.loading}), [printPdf.loading]);
  useDialogStateMutation({setState, buttonNextDisabled: showProgressLoading, buttonNextProgress: showProgressLoading});

  return (
    <>
      <Text className={clsx(classes.textAlignCenter, classes.subTitle)}>
        <Trans i18nKey="borrowerNotesModalTranslation:subTitle" />
      </Text>

      <Form onSubmit={doSubmit} id={state.formId} validationSchema={validationSchema}>
        {state.illustrationsToShare.map((illustrationToShare) => {
          const product = getProduct(illustrationToShare);

          return (
            <Container spacing={2} key={product?.ApiRoute}>
              <Paper variant="outlined" square className={clsx(classes.paper, classes.m2)}>
                <Item xs={12} className={classes.m2}>
                  <h2>{product?.ProductName}</h2>
                </Item>

                <Item xs={12} className={classes.m2}>
                  <IllustrationExtraDetails className={classes.mb2} illustration={illustrationToShare} />

                  <Container spacing={3}>
                    <Item item xs={12}>
                      <Input
                        type="text"
                        name={`${illustrationToShare.id}.note`}
                        defaultValue={''}
                        variant="outlined"
                        multiline={true}
                        minRows={3}
                        maxRows={5}
                        fullWidth={true}
                        className={classes.textArea}
                        placeholder={t('borrowerNotesModalTranslation:add upToChars')}
                      />
                    </Item>
                  </Container>
                </Item>
              </Paper>
            </Container>
          );
        })}
      </Form>
    </>
  );
}

BorrowerNotesIllustrationsSelectDialog.propTypes = {};

export default withDialogState(BorrowerNotesIllustrationsSelectDialog, {
  title: 'borrowerNotesModalTranslation:title',
  formId: 'borrower-notes',
  buttonReturn: '',
});
