import {useCallback, useEffect, useMemo} from 'react';
import {
  addOneIllustration,
  cleanUpIllustration,
  getAllIllustrations,
  getSelectedIllustration,
  removeAllIllustrations,
  updateSelectedIllustration,
} from 'reducers/illustrator.reducer';
import {useDispatch, useSelector} from 'react-redux';
import {isEmptyVal} from 'lib/utils';
import {removeScenarios, setBreakDownViewType} from 'reducers/settings.reducer';
import {useEffectOnce} from 'react-use';
import {useEnabledProductFeatures} from './product-features';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {generateId} from 'lib/generator';

export function useAddIllustration(props = {}) {
  const {defaultData} = props;
  const dispatch = useDispatch();

  const addIllustration = useCallback(
    (data = defaultData) => {
      dispatch(removeAllIllustrations());
      dispatch(addOneIllustration(data));
      dispatch(removeScenarios());
      dispatch(setBreakDownViewType());
    },
    [dispatch, defaultData],
  );

  return {addIllustration};
}

export function useNewIllustration(props = {}) {
  const {defaultData} = props;
  const illustration = useSelector(getSelectedIllustration);

  const {addIllustration} = useAddIllustration();
  const canAddIllustration = useMemo(() => {
    if (defaultData?.ApiRoute) return illustration.ApiRoute !== defaultData.ApiRoute;

    return isEmptyVal(illustration);
  }, [illustration, defaultData?.ApiRoute]);

  useEffect(() => {
    if (canAddIllustration) addIllustration(defaultData);
  }, [canAddIllustration]);

  return {illustration, addIllustration};
}

export function useNewEmptyIllustration(props = {}) {
  const {defaultData = {}} = props;

  const illustration = useSelector(getSelectedIllustration);

  const {addIllustration} = useAddIllustration({defaultData});

  useEffectOnce(() => {
    addIllustration(defaultData);
  });

  const defaultValues = useMemo(
    () => ({
      TotalProceeds: illustration.TotalProceeds,
      BorrowerProfile: {
        DateOfBirth: illustration?.BorrowerProfile?.DateOfBirth,
        ValueOfProperty: illustration?.BorrowerProfile?.ValueOfProperty,
        Liens: illustration?.BorrowerProfile?.Liens,
        Zip: illustration?.BorrowerProfile?.Zip,
        IsThere2nd: illustration?.BorrowerProfile?.IsThere2nd,
        FirstLien: illustration?.BorrowerProfile?.FirstLien,
        OtherLiens: illustration?.BorrowerProfile?.OtherLiens,
      },
    }),
    [illustration],
  );

  return {
    defaultValues,
  };
}

export function useIllustratorShare() {
  // load selected illustration
  const illustration = useSelector(getSelectedIllustration);

  // load all illustrations
  const illustrations = useSelector(getAllIllustrations);

  const {isEnabled} = useEnabledProductFeatures(PRODUCT_FEATURE_KEY.SHARE_ILLUSTRATION_PANEL);

  const disableButtons = useMemo(() => {
    if (!isEnabled) return true;

    return (
      !illustration || illustrations.length === 0 || !illustration.BorrowerProfileId || !illustration.CalculationId
    );
  }, [illustration, illustrations, isEnabled]);

  return {disableButtons, ApiRoute: illustration.ApiRoute};
}

export function useIllustratorProductChange(props = {}) {
  const {defaultValue} = props;
  const dispatch = useDispatch();

  const onChange = useCallback(
    async (event, option) => {
      if (option.value !== defaultValue) {
        await dispatch(setBreakDownViewType());

        await dispatch(
          updateSelectedIllustration(
            cleanUpIllustration({
              id: generateId(),
              ApiRoute: option.value,
              SimplifiedCashToBorrower: '',
              RemainingEquity: '',
              ClosingCost: null,
              FirstLien: null,
              OtherLiens: null,
            }),
          ),
        );
      }
    },
    [dispatch, defaultValue],
  );

  return {onChange};
}
