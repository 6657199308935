import {ToString} from './utils';
import moment from 'lib/moment';
import {useCallback, useMemo} from 'react';
import {BORROWER_PREFERENCE} from 'constants/illustrator';

export function getIllustrationLabel(illustration = {}, format) {
  const {label, BorrowerProfile: {FirstName, LastName, DateOfBirth} = {}} = illustration;
  return label ?? (getBorrowerName(FirstName, LastName) || getBorrowerBirthDate(DateOfBirth, format));
}

export function getBorrowerName(fistName, LastName) {
  return ToString(`${ToString(fistName)} ${ToString(LastName)}`).trim();
}

const momentFormats = ['MM-DD-YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY', 'YYYY/MM/DD'];

export function getBorrowerBirthDate(DateOfBirth, format = 'MM/DD/YYYY') {
  return moment(DateOfBirth, momentFormats).format(format);
}

export function getAge(DateOfBirth) {
  return moment().diff(DateOfBirth, 'years');
}

export function useSkipBorrowerAdditionalStep(props = {}) {
  const {skipAdditionalStep, additionalStep} = props;

  const isSkipAdditionalStep = useCallback((args = {}) => {
    const {skipAdditionalStep, additionalStep} = args;
    return !additionalStep?.length || skipAdditionalStep;
  }, []);

  const shouldSkip = useMemo(
    () => isSkipAdditionalStep({skipAdditionalStep, additionalStep}),
    [isSkipAdditionalStep, skipAdditionalStep, additionalStep],
  );

  return {isSkipAdditionalStep, shouldSkip};
}

export function calculateMaxAdvancedOptionsLength() {
  return 30;
}

export function simpleBorrowerPreference(BorrowerPreference) {
  if (BorrowerPreference?.LoanPriorityTypeId === 1) {
    return BORROWER_PREFERENCE.MOST_PROCEEDS;
  } else if (BorrowerPreference?.WithdrawalMethodId === 2) {
    return BORROWER_PREFERENCE.LINE_OF_CREDIT;
  } else if (BorrowerPreference?.AdditionalPreference?.find((id) => id === 2)) {
    return BORROWER_PREFERENCE.LOWEST_RATE;
  } else if (BorrowerPreference?.AdditionalPreference?.find((id) => id === 3)) {
    return BORROWER_PREFERENCE.LOWEST_FEES;
  }
}

export function reverseSimpleBorrowerPreference(BorrowerPreference) {
  switch (BorrowerPreference) {
    case BORROWER_PREFERENCE.MOST_PROCEEDS: {
      return {LoanPriorityTypeId: 1, WithdrawalMethodId: '', AdditionalPreference: ''};
    }
    case BORROWER_PREFERENCE.LINE_OF_CREDIT: {
      return {LoanPriorityTypeId: '', WithdrawalMethodId: 2, AdditionalPreference: ''};
    }
    case BORROWER_PREFERENCE.LOWEST_RATE: {
      return {LoanPriorityTypeId: '', WithdrawalMethodId: '', AdditionalPreference: [2]};
    }
    case BORROWER_PREFERENCE.LOWEST_FEES: {
      return {LoanPriorityTypeId: '', WithdrawalMethodId: '', AdditionalPreference: [3]};
    }
  }
}
