import React from 'react';
import InRangeSelect from 'components/common/form/select/InRangeSelect';
import {useIllustrationDDLOptions} from 'hooks/illustration-ddl';

ApiRoutesSelect.propTypes = {};

function ApiRoutesSelect(props) {
  const {data} = useIllustrationDDLOptions();

  return <InRangeSelect {...props} options={data} />;
}

export default ApiRoutesSelect;
