import React, {useState} from 'react';
import ContentSection from 'pages/illustrator-hybrid-page/components/content-section/ContentSection';
import BarChart from 'components/common/charts/bar-chart';
import {useMobileApp} from 'lib/app-theme';
import {usePrintingValue} from 'hooks/utils';

function ScatterChartSection(props) {
  const {children} = props;

  const showLegend = useMobileApp();

  const [chartData, setChartData] = useState([]);
  const [chartTitle, setChartTitle] = useState(null);
  const [chartDisclaimer, setChartDisclaimer] = useState(null);

  const margin = usePrintingValue(undefined, {top: 30, right: 30, left: 120, bottom: 80});

  return (
    <ContentSection
      chartProps={{
        chartTitle,
        chartDisclaimer,
        chartDisclaimerClassName: 'disclaimerContainerMarginTop',
      }}
      chart={<BarChart data={chartData} layout="vertical" margin={margin} />}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          setChartData,
          setChartTitle,
          showLegend,
          setChartDisclaimer,
        }),
      )}
    </ContentSection>
  );
}

ScatterChartSection.propTypes = {};

export default ScatterChartSection;
