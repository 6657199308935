import {useQuery} from 'react-query';
import SmartStreetsServiceApi from 'api/smarty-streets-service-api';
import {smartyAutoCompleteQueryKey} from 'constants/query';
import {isEqual, isFunction, pick} from 'lodash';
import {SMARTY_KEYS, smartyToXcelerate} from 'services/smarty.service';

export function useGetSmartStreetsDataQuery(props = {}) {
  const {enabled, search, defaultValue, onFilterOptions, ...rest} = props;

  return useQuery({
    enabled: enabled && search?.length > 2,
    queryKey: [smartyAutoCompleteQueryKey, search],
    async queryFn() {
      let {
        data: {suggestions: suggestionsResults = []},
      } = await SmartStreetsServiceApi.get({search});

      let suggestions = suggestionsResults.map(smartyToXcelerate);

      if (isFunction(onFilterOptions)) {
        suggestions = suggestions.filter((option) => onFilterOptions(option));
      }

      return {
        data: {
          suggestions,
          selectedValue: suggestions.find((suggestion) => {
            return isEqual(pick(suggestion, SMARTY_KEYS), defaultValue);
          }),
        },
      };
    },
    ...rest,
  });
}
