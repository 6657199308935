import {useTranslation} from 'lib/i18n';
import {useMemo} from 'react';

/**
 *
 * The dropdown values must remain hardcoded !
 *
 * When updating the const values ( add / remove ), make sure to check :
 *
 *  - add/remove the field within the yup validation
 *  - the "PropertyTaxes" page
 *  - the "PropertyTaxesFormElements" page
 *  - the "save-illustrator-borrower.js" file
 *
 */

// attribute : property type : dropdown element
export const PROPERTY_TYPE = 'propertyType';
// attribute : property type : dropdown values
export const PUD_ATTACHED = 'PUD Attached';
export const PUD_DETACHED = 'PUD Detached';
export const CONDO = 'Condo';
export const SITE_CONDOMINIUM = 'Site Condominium';
export const TOWNHOUSE = 'Townhouse';

// attribute : hoa monthly fees
export const HOA_MONTHLY_FEES = 'hoaMonthlyFees';

export default function usePropertyTypeOptions() {
  const {t} = useTranslation();

  return useMemo(
    () => [
      {
        label: t('inputsTranslation:property taxes.single family detached'),
        value: 'Single Family Detached',
      },
      {
        label: t('inputsTranslation:property taxes.pud attached'),
        value: PUD_ATTACHED,
      },
      {
        label: t('inputsTranslation:property taxes.pud detached'),
        value: PUD_DETACHED,
      },
      {
        label: t('inputsTranslation:property taxes.condo'),
        value: CONDO,
      },
      {
        label: t('inputsTranslation:property taxes.townhouse'),
        value: TOWNHOUSE,
      },
      {
        label: t('inputsTranslation:property taxes.site condominium'),
        value: 'Site Condominium',
      },
      {
        label: t('inputsTranslation:property taxes.two-four units'),
        value: '2-4 Units',
      },
    ],
    [t],
  );
}
