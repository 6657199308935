import React from 'react';
import PropTypes from 'prop-types';
import {Controller, useFormContext} from 'react-hook-form';
import InputField from './InputField';
import useStyles from './InputStyles';
import clsx from 'clsx';
import {useInputError, useInputLabel, useValidationSchema} from 'hooks/input';
import {useInputCondition} from 'hooks/condition';
import {withGeneratedId} from 'lib/generator';
import {excludeFeatureWrapperKeys} from 'components/common/features-wrapper/FeaturesWrapper';

function Input(props) {
  const {
    id,
    type,
    maxLength,
    className,
    label,
    name,
    disabled,
    onChange,

    condition,
    conditionStyle,
    style,

    InputProps,
    withInputLabelClass = true,

    defaultValue,

    ...rest
  } = excludeFeatureWrapperKeys(props);

  const {control} = useFormContext();
  const classes = useStyles();

  const {required} = useValidationSchema({name});
  const {showError, errorMessage} = useInputError({name});

  const inputLabel = useInputLabel({label, name});
  const {style: styleResults, ...inputCondition} = useInputCondition({condition, conditionStyle, style});

  /**
   *
   * We must be able to control the style for each type of input element.
   *
   */

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field}) => (
        <InputField
          id={id}
          name={name}
          variant="outlined"
          fullWidth
          className={clsx(classes.inputController, className)}
          InputLabelProps={{
            'data-testid': `input-label-${name}`,
            ...rest?.InputLabelProps,
            className: clsx(rest?.InputLabelProps?.className, {
              [classes.inputLabel]: withInputLabelClass && InputProps?.endAdornment,
              [classes.inputControllerTooltip]: !!rest.toolTipText,
            }),
            classes: {
              ...rest?.InputLabelProps?.classes,
              shrink: clsx(rest?.InputLabelProps?.classes?.shrink, classes.inputShrink),
            },
          }}
          disabled={disabled}
          style={styleResults}
          type={type}
          label={inputLabel}
          required={required}
          error={showError}
          helperText={errorMessage}
          FormHelperTextProps={{
            'data-testid': `${name}-helper-text`,
            ...rest?.FormHelperTextProps,
          }}
          InputProps={{
            maxLength,
            'data-testid': `input-${name}`,
            ...InputProps,
          }}
          {...inputCondition}
          {...rest}
          {...field}
        />
      )}
    />
  );
}

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default withGeneratedId(Input);
