import React, {useCallback, useMemo, useState} from 'react';
import ReactRouterPause from '@allpro/react-router-pause';
import Button from 'components/common/button/Button';
import {Trans} from 'lib/i18n';
import {useFormContext} from 'react-hook-form';
import Item from 'components/common/layout/item/Item';
import Title from 'components/common/typeography/title/Title';
import Text from 'components/common/typeography/text/Text';
import useStyles from 'components/illustrator/illustrator-form/IllustrationFormPromptStyles';
import {useIllustrationSave} from 'hooks/illustration-save';
import {useAuthenticated} from 'hooks/authenticate';
import {useProductContext} from 'contexts/product-context';
import Dialog from 'components/common/dialog/Dialog';
import {useSelector} from 'react-redux';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';

function IllustrationFormPrompt(props) {
  const {product: {ApiRoute, calculation: {IsSaved, CalculationId} = {}} = {}} = useProductContext();

  const selectedIllustration = useSelector(getSelectedIllustration);

  const {isAuthenticated} = useAuthenticated();
  const {formState, triggerSubmit} = useFormContext();
  const [dialogProps, setDialogProps] = useState({open: false});
  const handleClose = useCallback(() => setDialogProps({open: false}), [setDialogProps]);

  const classes = useStyles();

  /**
   *
   * When should the "navigation attempt" modal be rendered ?
   *
   */

  const shouldShowSavePrompt = useMemo(() => {
    if (!isAuthenticated) return false;
    if (!CalculationId) return false;
    if (selectedIllustration.navigationAttemptModal === false) return false;

    return !IsSaved;
  }, [IsSaved, CalculationId, isAuthenticated, selectedIllustration?.navigationAttemptModal]);

  const {onSaveIllustration} = useIllustrationSave();

  /**
   *
   * Handle the navigation attempt event
   *
   */

  const handleNavigationAttempt = useCallback(
    (navigation, location) => {
      setDialogProps({
        open: true,
        handleStay: () => {
          handleClose();
          let action = new Promise((resolve) => resolve());
          if (formState.isDirty) action = triggerSubmit();

          action.then((product) =>
            onSaveIllustration({
              ApiRoute: product?.calculation?.ApiRoute || ApiRoute,
              CalculationId: product?.calculation?.CalculationId || CalculationId,
              onSuccess: () => navigation.resume(),
              onError: () => navigation.cancel(),
            }),
          );
        },
        handleLeave: () => {
          handleClose();
          navigation.resume();
        },
      });

      return null;
    },
    [setDialogProps, handleClose, triggerSubmit, onSaveIllustration, CalculationId, formState.isDirty, ApiRoute],
  );

  return (
    <>
      <ReactRouterPause handler={handleNavigationAttempt} when={shouldShowSavePrompt} />

      <Dialog
        onClose={dialogProps.handleLeave}
        aria-labelledby="dialog-title"
        open={dialogProps.open}
        dialogActionsProps={{
          children: (
            <>
              <Item xs={6}>
                <Button onClick={dialogProps.handleLeave} fullWidth>
                  <Trans i18nKey="illustratorTranslation:illustration.form prompt.button close" />
                </Button>
              </Item>
              <Item xs={6}>
                <Button onClick={dialogProps.handleStay} btnStyle="bgElectric" autoFocus fullWidth>
                  <Trans i18nKey="illustratorTranslation:illustration.form prompt.button submit" />
                </Button>
              </Item>
            </>
          ),
        }}
      >
        <Item>
          <Title variant="h4" className="strong">
            <Trans i18nKey="illustratorTranslation:illustration.form prompt.title" />
          </Title>
        </Item>
        <Item className={classes.illustrationFormPromptInputContainer}>
          <Text className="strong">
            <Trans i18nKey="illustratorTranslation:illustration.form prompt.description" />
          </Text>
        </Item>
      </Dialog>
    </>
  );
}

IllustrationFormPrompt.propTypes = {};

export default IllustrationFormPrompt;
