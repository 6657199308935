import React from 'react';
import {Cell, Pie, PieChart, Tooltip} from 'recharts';
import CustomTooltip from 'components/common/custom-tooltip/CustomTooltip';
import ResponsiveContainer from 'components/common/recharts/ResponsiveContainer';
import {usePrintingValue} from 'hooks/utils';

function SmallPieChart(props) {
  const {data, innerRadius, isAnimationActive = true, className} = props;

  const minHeight = usePrintingValue(150, 250);
  return (
    <ResponsiveContainer minWidth={150} minHeight={minHeight} maxHeight={200} maxWidth={200}>
      <PieChart className={className}>
        <Pie
          isAnimationActive={isAnimationActive}
          data={data}
          dataKey="value"
          nameKey="id"
          startAngle={90}
          endAngle={-270}
          innerRadius={innerRadius}
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default React.memo(SmallPieChart);
