import React from 'react';
import {useTranslation} from 'lib/i18n';
import IllustrationExtraDetailsMenuItem from 'components/illustrator/illustration-extra-details/IllustrationExtraDetailsMenuItem';
import ApiRoutesSelect from 'components/illustrator/api-routes-select/ApiRoutesSelect';
import useStyles from './NewIllustrationFieldsStyles';

function NewIllustrationFields(props) {
  const {t} = useTranslation();

  const classes = useStyles();

  return (
    <ApiRoutesSelect
      // MenuItemProps={{ApiRoute, productFeatureKey: PRODUCT_FEATURE_KEY.ILLUSTRATION_DDL}}
      SelectProps={{MenuProps: {PopoverClasses: {paper: classes.newIllustrationFieldsPaper}}}}
      MenuItem={IllustrationExtraDetailsMenuItem}
      variant="standard"
      name="ApiRoute"
      label={t('illustratorTranslation:new illustration modal.inputs.ApiRoute.label')}
      fullWidth
    />
  );
}

export default NewIllustrationFields;
