export const SMARTY_KEYS = ['street_line', 'secondary', 'city', 'state', 'zipcode'];

export function smartyToXcelerate(smartyObj = {}) {
  return {
    Address: smartyObj.street_line,
    Address2: smartyObj.secondary,
    City: smartyObj.city,
    State: smartyObj.state,
    ZipCode: smartyObj.zipcode,
  };
}

export function xcelerateToSmarty(xcelerateObj = {}) {
  return {
    street_line: xcelerateObj.Address,
    secondary: xcelerateObj.Address2,
    city: xcelerateObj.City,
    state: xcelerateObj.State,
    zipcode: xcelerateObj.ZipCode,
  };
}
