import React, {useMemo} from 'react';
import {TabContext, TabPanel} from '@material-ui/lab';
import IllustrationTab from '../../../illustration/components/illustrator-tabs/components/illustration-tab/IllustrationTab';
import {useDefaultViewType} from 'hooks/view-type';
import Tabs from 'components/common/Tabs/Tabs';
import useStyles from './RecommendedFormStyles';
import {RecommendedProductContextProvider} from 'contexts/recommended-product-context';

function RecommendedForm(props) {
  const {data: {Recommendations = {}} = {}, children} = props;
  const classes = useStyles();

  const BorrowerRecommendations = useMemo(() => Object.keys(Recommendations), [Recommendations]);

  const defaultValue = useMemo(() => BorrowerRecommendations?.[0], [BorrowerRecommendations]);

  const {viewType, onTabChange} = useDefaultViewType({
    defaultValue,
    watchDefaultValue: true,
  });

  return (
    <RecommendedProductContextProvider recommendedCalculationId={Recommendations[viewType]}>
      <TabContext id="BorrowerPreferenceRecommendation" value={viewType}>
        <Tabs
          className={classes.recommendedTabs}
          value={viewType || defaultValue}
          onChange={onTabChange}
          TabIndicatorProps={{showIndicator: false}}
        >
          {BorrowerRecommendations.map((recommendation) => (
            <IllustrationTab
              rounded
              key={recommendation}
              value={recommendation}
              title={recommendation}
              label={recommendation}
            />
          ))}
        </Tabs>

        <TabPanel className={classes.recommendedTabPanel} value={viewType}>
          {children}
        </TabPanel>
      </TabContext>
    </RecommendedProductContextProvider>
  );
}

RecommendedForm.propTypes = {};

export default RecommendedForm;
