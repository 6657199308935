import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery} from 'lib/app-theme';

const drawerWidthMobile = 320;
const drawerWidth = 350;
const drawerCloseWidth = 70;

const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',

        '& .disclaimerContainer': {
          textAlign: 'left',
          fontSize: theme.spacing(1.5),
          margin: theme.withPrint(theme.spacing(2, 'auto'), theme.spacing(1, 'auto')),
          textDecoration: 'none',
          color: theme.palette.grey.main,

          '& *:not(:hover)': {
            textDecoration: 'none',
          },
          maxWidth: theme.withPrint(theme.spacing(160), 'unset !important'),

          [isMobileAppQuery(theme)]: {
            padding: theme.spacing(0, 1),
          },
        },

        '& .VictoryContainer': {
          userSelect: 'auto !important',
          pointerEvents: 'auto !important',
          touchAction: 'auto !important',
        },
      },
      drawerPaper: {
        backgroundColor: theme.palette.primary.dark,
        display: theme.withPrint('flex', 'none'),
      },
      container: {
        flexGrow: 1,
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      menuImg: {
        maxHeight: 42,
      },
      appBar: {
        marginLeft: theme.withPrint(drawerCloseWidth, '0 !important'),
        width: theme.withPrint('auto', '100% !important'),
        zIndex: theme.zIndex.drawer + 2,
        transition: theme.transitions.create(['margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarDrawerOpen: {
        marginLeft: `${drawerWidth}px !important`,
        [isMobileAppQuery(theme)]: {
          overflow: 'hidden',
        },
      },
      appBarNoDrawer: {
        marginLeft: 0,
        width: 'auto',
      },
      appBarMenuButton: {
        padding: theme.spacing(2),
      },
      hide: {
        display: 'none !important',
      },
      hideOpacity: {
        display: 'none',
        opacity: '0 !important',
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        zIndex: theme.zIndex.drawer + 1,
        opacity: 1,
        transition: theme.transitions.create(['opacity'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        [isMobileAppQuery(theme)]: {
          width: drawerWidthMobile,
          opacity: 0,
        },
        display: theme.forPrint('none'),
      },
      drawerOpen: {
        width: `${drawerWidth}px !important`,
        opacity: `1 !important`,
        transition: theme.transitions.create(['width', 'z-index'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),

        [isMobileAppQuery(theme)]: {
          width: `${drawerWidthMobile}px !important`,
        },
      },
      drawerClose: {
        transition: theme.transitions.create(['width', 'z-index'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: drawerCloseWidth,
      },
      drawerHide: {
        [isMobileAppQuery(theme)]: {
          width: 0,
        },
      },
      toolbar: {
        alignItems: 'center',
      },
      content: {
        paddingLeft: theme.withPrint(drawerCloseWidth, '0 !important'),
        backgroundColor: theme.palette.common.white,
        flexGrow: 1,
      },
      contentNoDrawer: {
        paddingLeft: 0,
      },
    };
  },
  {index: 1},
);

export default useStyles;
