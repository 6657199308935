import React, {useCallback, useContext, useState} from 'react';
import Product from 'components/products/product';
import {defaultsDeep, isEmpty, isEqual} from 'lodash';

const productsContext = {};
const ProductsContext = React.createContext(productsContext);

export function useProductsContext(props = {}) {
  return useContext(ProductsContext);
}

function useProductsContextHook(props = {}) {
  const {products, loading} = props;
  const [value, setValue] = useState(productsContext);

  const setProductsContext = useCallback(
    (data) => {
      setValue((value) => defaultsDeep(data, value));
    },
    [setValue],
  );

  const refCallback = useCallback(
    (product) => (refData) => {
      const {productDetails, productResults, productQuery} = refData ?? {};

      const data = {};

      if (
        productQuery &&
        (!isEqual(value.productQuery?.[product.ApiRoute]?.enabled, productQuery?.enabled) ||
          !isEqual(value.productQuery?.[product.ApiRoute]?.ApiRoute, productQuery?.ApiRoute))
      ) {
        data.productQuery = {[product.ApiRoute]: productQuery};
      }
      if (productResults && !isEqual(value.productResults?.[product.ApiRoute], productResults)) {
        data.productResults = {[product.ApiRoute]: productResults};
      }
      if (productDetails && !isEqual(value.productDetails?.[product.ApiRoute], productDetails)) {
        data.productDetails = {[product.ApiRoute]: productDetails};
      }

      if (!isEmpty(data)) {
        setProductsContext(data);
      }

      return undefined;
    },
    [setProductsContext, value],
  );

  return {
    ...value,
    setProductsContext,
    products,
    loading,
    refCallback,
  };
}

/**
 *
 * @param {object} props
 * @param {React.ReactNode|(()=>React.ReactNode)} props.children
 * @param {object} props.products
 * @param {?string} props.loading
 * @return {React.ReactNode}
 * @constructor
 */
export function ProductsContextProvider(props = {}) {
  const {children, products, renderProducts = true} = props;
  const {refCallback, ...context} = useProductsContextHook(props);

  return (
    <ProductsContext.Provider value={context}>
      {renderProducts &&
        products.map((product) => (
          <React.Fragment key={product.ProductId}>
            <Product product={product} loadin={context.loading} ref={refCallback(product)} />
          </React.Fragment>
        ))}

      {children}
    </ProductsContext.Provider>
  );
}
