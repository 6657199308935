import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    inputController: {
      textAlign: 'left !important',
    },
    inputShrink: {},
    inputControllerTooltip: {
      '&:not($inputShrink)': {
        paddingRight: theme.spacing(6),
      },
    },
    inputLabel: {
      maxWidth: `calc(100% - ${theme.spacing(3)}px)`,
    },
  }),
  {index: 1},
);

export default useStyles;
