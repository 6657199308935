import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    mainEl: {
      width: '100%',
      display: 'block',
      margin: theme.spacing(0, 0, 0, 0),
    },
    formContainer: {
      margin: theme.spacing(0, 'auto'),
    },
    radioGroupContainer: {
      justifyContent: 'space-evenly',
      textAlign: 'center !important',
    },
    radioGroupOptionContainer: {},
    itemContainer: {
      width: '100%',
      margin: 'auto',
      '& .MuiFilledInput-root': {
        backgroundColor: 'unset',
      },
      '& .MuiInputLabel-root': {
        color: theme.palette.primary.dark,
        fontWeight: '600 !important',
      },
    },
    labels: {
      color: theme.palette.primary.dark,
      fontSize: theme.spacing(2),
      lineHeight: 1.43,
      fontWeight: '600 !important',
      fontFamily:
        'Montserrat,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    },
    maxWidth: {
      maxWidth: '100%',
    },
    checkBox: {
      '& .MuiButtonBase-root': {
        width: '10%',
      },
    },
    customInputElement: {
      '& .MuiFormLabel-root': {
        fontSize: theme.spacing(1.75),
      },
      '& .MuiInputLabel-filled': {
        transform: 'translate(0, 24px) scale(1)',
      },
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        transform: 'translate(12px, 10px) scale(0.75)',
      },
    },
    textAlignCenter: {
      textAlign: 'center',
    },

    justifyContentSpaceAround: {
      justifyContent: 'space-around',
    },
    noSpacingTopBottom: {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
    },
    padding2units: {
      padding: theme.spacing(2, 2, 2, 2),
    },

    maxButtonWidth: {
      width: '100%',
      maxWidth: 273,
      [isMobileAppQuery(theme)]: {
        maxWidth: undefined,
      },
    },
  }),
  {index: 1},
);

export default useStyles;
