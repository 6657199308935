import React from 'react';
import {ProductContextProvider} from 'contexts/product-context';
import {useHybridHook} from 'components/products/hybrid/HybridHook';
import {useHybridOverallResults} from 'components/products/hybrid/HybridOverallResults';
import {API_ROUTES} from 'constants/illustrator';
import {useCalculateHybridQuery} from 'query/products/hybrid';
import {useProductWithRef} from 'hooks/product-ref';

/**
 *
 * The "Hybrid" product, aka "EquityAvail".
 *
 * This is where the "product context provider" for the "hybrid" product gets generated.
 *
 */

const Hybrid = React.forwardRef(function HybridRender(props, ref) {
  const {product, setRef, ...rest} = props;

  const hybridHook = useHybridHook({product});
  const hybridQuery = useCalculateHybridQuery({product});
  const hybridOverallResults = useHybridOverallResults({product});

  useProductWithRef({
    productDetails: hybridHook,
    productResults: hybridOverallResults,
    productQuery: hybridQuery,
    ref,
    ApiRoute: API_ROUTES.HYBRID,
  });

  return (
    <ProductContextProvider
      productDetails={hybridHook}
      productResults={hybridOverallResults}
      productQuery={hybridQuery}
      product={product}
      {...rest}
    />
  );
});

export default Hybrid;
