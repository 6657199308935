import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery, isTabletAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    tabPanelComponent: {
      [isTabletAppQuery(theme)]: {
        padding: theme.spacing(1, 3),
      },
      [isMobileAppQuery(theme)]: {
        padding: theme.spacing(1),
      },
    },
  }),
  {index: 1},
);

export default useStyles;
