import {useTranslation} from 'lib/i18n';
import forwardRefiImg from 'assets/images/products/forwardrefi.svg';

export function useForwardRefiHook(props) {
  const {t} = useTranslation();

  return {
    description: t('illustratorTranslation:illustration.products.forwardrefi.description'),

    img: forwardRefiImg,

    skipAdditionalStep: true,
  };
}
