import React from 'react';
import PropTypes from 'prop-types';
import Title from 'components/common/typeography/title/Title';
import {Trans} from 'lib/i18n';
import Text from 'components/common/typeography/text/Text';
import {Box} from '@material-ui/core';
import {BREAKDOWN_VIEW_TYPE} from 'hooks/view-type';
import useStyles from './BreakdownTitleStyles';
import clsx from 'clsx';

function BreakdownTitle(props = {}) {
  const {className, brakeDownType = BREAKDOWN_VIEW_TYPE['payment-breakdown'], showDescription = true} = props;
  const classes = useStyles();

  return (
    <Box className={clsx(classes.breakdownTitleContainer, className)}>
      <Title className={clsx(classes.breakdownTitle, {[classes.breakdownDescription]: !showDescription})} variant="h4">
        <Trans i18nKey={`illustratorTranslation:illustration breakdown.${brakeDownType}.title`} />
      </Title>
      {showDescription && (
        <Text className={classes.breakdownDescription} variant="body2">
          <Trans i18nKey={`illustratorTranslation:illustration breakdown.${brakeDownType}.description`} />
        </Text>
      )}
    </Box>
  );
}

BreakdownTitle.propTypes = {
  brakeDownType: PropTypes.oneOf([
    BREAKDOWN_VIEW_TYPE['payment-breakdown'],
    BREAKDOWN_VIEW_TYPE['lifetime-model'],
    BREAKDOWN_VIEW_TYPE['amortization-table'],
    BREAKDOWN_VIEW_TYPE['lifetime-model-pdf'],
  ]),
};

export default React.memo(BreakdownTitle);
