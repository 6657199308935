import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {isFunction} from 'lodash';
import {useFormContext} from 'react-hook-form';
import {useUpdateEffect} from 'react-use';
import {useInputOptionsContext} from 'contexts/input-options-context';
import CustomCheckbox from 'components/common/form/input/components/CustomCheckbox';
import {optionsToFlatMap} from 'lib/checkbox';

function IndeterminateCheckbox(props = {}) {
  const {name, onChange: moduleOnChange, value, singleOption, onExternalChange, options = [], type, ...rest} = props;

  const {watch, setValue} = useFormContext();

  const {getOptions} = useInputOptionsContext();
  const siblings = useMemo(() => getOptions(name), [getOptions, name]);

  const onChange = useCallback(
    (event, value) => {
      event.persist();

      moduleOnChange(event, value);

      if (singleOption && siblings.length) {
        siblings.map((sibling) => setValue(sibling, null));
      }

      if (isFunction(onExternalChange)) {
        onExternalChange(event, value);
      }
    },
    [singleOption, siblings, onExternalChange, moduleOnChange, setValue],
  );

  const optionsToWatch = useMemo(
    () =>
      optionsToFlatMap(options)
        .filter((option) => option.type === type)
        .map((option) => option.name),
    [options, type],
  );

  const childValues = watch(optionsToWatch);

  const indeterminate = useMemo(() => {
    return value ? childValues.findIndex((val) => val !== value) > -1 : false;
  }, [childValues, value]);

  const siblingsValues = watch(siblings);

  useUpdateEffect(() => {
    if (singleOption) {
      const newValue = siblingsValues.find(Boolean);
      if (newValue === value) {
        setValue(name, !value, {shouldValidate: true});
      }
    }
  }, [siblingsValues]);

  return <CustomCheckbox {...rest} onChange={onChange} value={value} name={name} indeterminate={indeterminate} />;
}

export default IndeterminateCheckbox;
