import React, {useMemo} from 'react';
import InRangeSelect from 'components/common/form/select/InRangeSelect';
import {useProductContext} from 'contexts/product-context';
import {isLenderCredit} from 'lib/product';
import {useFormContext} from 'react-hook-form';

SecondRatesSelect.propTypes = {};

function SecondRatesSelect(props) {
  const {defaultValue: defValue, ...rest} = props;

  const {watch} = useFormContext();
  const [Lesa, LenderCredit] = watch(['Lesa', 'LenderCredit']);

  const {productQuery: {tiers = [], defaultTier, getTierLabel} = {}} = useProductContext();

  const defaultValue = useMemo(
    () => defValue ?? defaultTier?.HomeSafeMetricId,
    [defValue, defaultTier?.HomeSafeMetricId],
  );

  const data = useMemo(() => {
    try {
      return tiers
        ?.filter((tier) => (isLenderCredit({Lesa, LenderCredit}) ? tier.LenderCredit > 0 : true))
        ?.map((tier) => ({
          value: tier.HomeSafeMetricId,
          label: getTierLabel(tier),
          selected: tier.HomeSafeMetricId === defaultValue,
        }));
    } catch (e) {
      return [];
    }
  }, [tiers, defaultValue, getTierLabel, Lesa, LenderCredit]);

  return <InRangeSelect {...rest} options={data} defaultValue={defaultValue} />;
}

export default SecondRatesSelect;
