import React, {useCallback} from 'react';
import {ToNumber} from 'lib/utils';
import {useVisible} from 'hooks/utils';
import {useTranslation} from 'lib/i18n';
import ContentContainer from 'pages/illustrator-hybrid-page/components/content-section/components/ContentContainer';
import {useOwnFormContext} from 'contexts/form-context';

function AboutEquitySection(props) {
  const {y, setChartData} = props;

  const {futureAge} = useOwnFormContext();
  const {t} = useTranslation();

  const onEnterViewport = useCallback(() => {
    setChartData(['homeValueYearEnd', 'loanBalanceValueYearEnd', 'year', 'name']);
  }, [setChartData]);

  const {visibleNodeRef} = useVisible({threshold: 0.75, onEnterViewport});

  return (
    <ContentContainer
      ref={visibleNodeRef}
      style={{y}}
      title={t('about equity section.title')}
      description={t('about equity section.description', {futureAge: ToNumber(futureAge)})}
    />
  );
}

AboutEquitySection.propTypes = {};

export default AboutEquitySection;
