const HOST = window.env.REACT_APP_WP_HOST;
const FARWHOLESALE_HOST = window.env.REACT_APP_FARWHOLESALE_HOST;
const REVERSE_HOST = window.env.REACT_APP_REVERSE_HOST;
const FOA_U_LINK = window.env.REACT_APP_FOA_U_LINK;
const DASHBOARD_LINK = window.env.REACT_APP_DASHBOARD_LINK;
const CAPACITY_LINK = window.env.REACT_APP_CAPACITY_LINK;

export const APP_ROUTES = {
  ROOT: '/',
  PDF: '/pdf',
  LOGIN_CALLBACK: '/login/callback',
  FAR_PAGES: '/far',
  WIDGET_PAGES: '/widgets',
  EQUITY_AVAIL: '/equityavail',
};

export const IGNORE_AUTO_LOGIN = [APP_ROUTES.PDF, APP_ROUTES.WIDGET_PAGES];

export const ROUTES = {
  HOME_PAGE: `/`,
  AUTH_ERROR_PAGE: '/error',
  SAVED_ILLUSTRATOR_PAGE: `/saved-illustrator`,
  SAVED_BORROWER_PROFILE_PAGE: `/borrower-profiles`,
  LOAN_PIPELINE_PAGE: '/loan-pipeline',
  LOAN_PIPELINE_DETAILS_PAGE: '/loan-pipeline/:loanId',

  ILLUSTRATOR_ROOT_PAGE: `/illustrator`,
  ILLUSTRATOR_PDF_PAGE: `/pdf/illustrator/:CalculationId?`,

  //
  // ILLUSTRATOR BORROWER LINK PAGES steps / start
  //

  ILLUSTRATOR_BORROWER_ROOT_PAGE: '/illustrator/borrower-page',

  //
  // ILLUSTRATOR BORROWER LINK PAGES steps / stop
  //

  // EA standalone page a.k.a. Equity Avail standalone page
  ILLUSTRATOR_HYBRID_PAGE: `${APP_ROUTES.EQUITY_AVAIL}`,
  // EA standalone PDF page a.k.a. Equity Avail standalone PDF page
  ILLUSTRATOR_HYBRID_PDF_PAGE: `${APP_ROUTES.EQUITY_AVAIL}/:CalculationId?/pdf`,
  PRODUCTS_PAGE: `/products`,
  PRODUCTS_DETAILS_PAGE: `${FARWHOLESALE_HOST}/xcelerate/products`,
  PRODUCTS_AVAILABILITY_PAGE: `${HOST}/wholesale/product-availability`,

  TRAINING_PAGE: `${HOST}/training`,
  RESOURCES_PAGE: `${HOST}/resources`,
  DASHBOARD_PAGE: `${DASHBOARD_LINK}`,

  HOST_HOME_PAGE: `${HOST}/`,
  PROFILE: `${FARWHOLESALE_HOST}/xcelerate/profile`,
  HELP: `${FARWHOLESALE_HOST}/xcelerate/help`,

  WHOLESALE_MANUAL_PAGE: `${FARWHOLESALE_HOST}/xcelerate/wholesale-manual`,
  RESOURCES_UNDERWRITING_PAGE: `${FARWHOLESALE_HOST}/xcelerate/resources-underwriting`,
  FINANCIAL_ASSESSMENT_PAGE: `${FARWHOLESALE_HOST}/xcelerate/financial-assessment`,
  APPLICATION_WORKFLOW_PAGE: `${FARWHOLESALE_HOST}/xcelerate/application-workflow/`,
  RESOURCES_BULLETINS_PAGE: `${FARWHOLESALE_HOST}/xcelerate/resources-bulletins`,
  RESOURCES_FORMS_PAGE: `${FARWHOLESALE_HOST}/xcelerate/resources-forms`,
  IMPORTANT_LINKS_PAGE: `${FARWHOLESALE_HOST}/xcelerate/important-links/`,

  FINANCE_OF_AMERICA_UNIVERSITY_PAGE: `${FOA_U_LINK}`,
  TRAINING_TEAM_PAGE: `${FARWHOLESALE_HOST}/xcelerate/training-team`,
  TRAINING_DOCUMENTS_PAGE: `${FARWHOLESALE_HOST}/xcelerate/training-documents`,
  TEAM_PAGE: `${FARWHOLESALE_HOST}/xcelerate/wholesale-team`,
  TRAINING_CALENDAR_PAGE: `${FARWHOLESALE_HOST}/xcelerate/training-calendar/`,
  TRAINING_VIDEOS_PAGE: `${FARWHOLESALE_HOST}/xcelerate/training-videos/`,

  WHY_FAR: `${HOST}/wholesale`,
  PARTNER_TOOLS: `${HOST}/partner-tools`,
  PRODUCT_PORTFOLIO: `${HOST}/wholesale/solutions`,
  INDUSTRY_NEWS: `${HOST}/news-events`,
  BECOME_PARTNER: `${HOST}/wholesale/become-partner`,

  XCELERATE_HOME_PAGE: `${FARWHOLESALE_HOST}/xcelerate`,

  WIDGETS_CALCULATOR: `${APP_ROUTES.WIDGET_PAGES}/calculator/:apiKey?`,
  REVERSE_EQUITY_AVAIL_PAGE: `${APP_ROUTES.FAR_PAGES}/equityavail`,
  REVERSE_EQUITY_AVAIL_PDF_PAGE: `${APP_ROUTES.FAR_PAGES}/equityavail/:CalculationId?/pdf`,
  REVERSE_HOME_PAGE: `${REVERSE_HOST}/`,
  REVERSE_CONTACT: `${REVERSE_HOST}/contact`,
  REVERSE_PARTNER: `${REVERSE_HOST}/professional-partners/`,
  REVERSE_SPOTLIGHT: `${REVERSE_HOST}/customer-spotlight/`,
  REVERSE_BLOG: `${REVERSE_HOST}/articles/`,
  REVERSE_ABOUT: `${REVERSE_HOST}/why-far/`,
  REVERSE_OUR_COMPANY: `${REVERSE_HOST}/company/`,
  REVERSE_TESTIMONIALS: `${REVERSE_HOST}/testimonials/`,
  REVERSE_FAQ: `${REVERSE_HOST}/faqs/`,
  REVERSE_START: `${REVERSE_HOST}/#wrapper-takeover`,
  REVERSE_OVERVIEW: `${REVERSE_HOST}/explore/`,
  REVERSE_OVERVIEW_TOOLS: `${REVERSE_HOST}/explore/tools/`,
  REVERSE_OVERVIEW_HOME_SAFE: `${REVERSE_HOST}/explore/homesafe/`,
  REVERSE_OVERVIEW_EQUITY_AVAIL: `${REVERSE_HOST}/explore/equityavail/`,
  REVERSE_OVERVIEW_HECM: `${REVERSE_HOST}/explore/hecm/`,
  REVERSE_OVERVIEW_REVERSE: `${REVERSE_HOST}/explore/reverse-for-purchase/`,
  REVERSE_OVERVIEW_SILVERNEST: `${REVERSE_HOST}/explore/silvernest/`,
  CAPACITY: `${CAPACITY_LINK}`
};

export const ILLUSTRATOR_BORROWER_HASH = {
  // product selection
  PRODUCT_SELECTION: 'product-selection',

  // borrower info
  BORROWER_INFO: `borrower-info`,

  // co-borrower info
  CO_BORROWER_INFO: `co-borrower-info`,

  // combined property page
  COMBINED_PROPERTY: `property`,

  // mailing address
  MAILING_ADDRESS: `mailing-address`,

  // credit authorization
  CREDIT_AUTHORIZATION: `credit-authorization`,

  // income
  INCOME: `income`,

  // affiliated business arrangements
  AFFILIATED_BUSINESS_ARRANGEMENTS: `affiliated-business-arrangements`,

  // broker transaction question
  BROKER_TRANSACTION_QUESTION: `broker-transaction-question`,

  // broker transactions
  BROKER_TRANSACTIONS: `broker-transactions`,

  // interviewer page
  INTERVIEWER_PAGE: 'interviewer-page',

  // demographic info borrower
  DEMOGRAPHIC_INFO_BORROWER: 'borrower-demographic-info',

  // declarations
  DECLARATIONS: 'declarations',
};

export const BORROWER_PROFILE_INTERVIEWER = `${ROUTES.ILLUSTRATOR_BORROWER_ROOT_PAGE}#${ILLUSTRATOR_BORROWER_HASH.INTERVIEWER_PAGE}`;
