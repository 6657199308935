export const PRODUCT_FEATURE_KEY = {
  STANDALONE_PAGE: 'standalone-page',
  CALCULATION: 'calculation',
  WIDGETS_CALCULATION: 'widgets-calculation',
  LOAN_COMPARISON: 'loan-comparison',
  ILLUSTRATION_DDL: 'illustration-ddl',
  LIFETIME_IMPACT_BTN: 'lifetime-impact-btn',
  SIDEBAR_MENU: 'sidebar-menu',
  EXISTING_MORTGAGE_WIZ: 'existing-mortgage-wiz',
  BORROW_INFO_SIDEBAR_MENU: 'borrow-info-sidebar-menu',
  FAR_EQUITYAVAIL_PAGE: 'far-equityavail-page',
  TOTAL_EXPERT_MODAL: 'total-expert-modal',
  BORROWER_NOTES_ADD_MODAL: 'borrower-notes-add-modal',
  BORROWER_ADVANCED_DETAILS: 'borrower-advanced-details',
  START_APPLICATION: 'start-application-btn',
  APPLICATION_PRODUCT_DDL: 'application-product-ddl',
  LIFETIME_MODEL: 'lifetime-model',
  AMORTIZATION_TABLE: 'amortization-table',
  //
  // ILLUSTRATOR BORROWER / START
  //
  // illustrator borrower component main key
  BORROWER_MAIN_KEY: 'borrower-info-page',
  //
  // illustrator borrower : product selection
  BORROW_PRODUCT_SELECTION: 'borrower-product-selection',
  // illustrator borrower : borrower/client
  BORROW_INFO: 'borrow-info-form',
  // illustrator borrower : coBorrower
  CO_BORROW_INFO: 'coborrow-info-form',
  // illustrator borrower : property
  //BORROW_PROPERTY_FORM: 'borrow-property-form',
  // illustrator borrower : property-info
  //BORROW_PROPERTY_INFO_FORM: 'borrow-property-form',
  // illustrator borrower : mailing address
  BORROW_MAILING_ADDRESS: 'borrow-mailing-address',
  // illustrator borrower : additional property question
  //BORROW_ADDITIONAL_PROPERTY_QUESTION: 'borrow-additional-prop-q',
  // illustrator borrower : additional properties
  //BORROW_ADDITIONAL_PROPERTIES: 'borrow-additional-props',
  // illustrator borrower : credit authorization
  BORROW_CREDIT_AUTHORIZATION: 'borrow-credit-auth',
  // illustrator borrower : income
  BORROW_INCOME: 'borrow-income',
  // illustrator borrower : property taxes
  //BORROW_PROPERTY_TAXES: 'borrow-property-taxes',
  // illustrator borrower : affiliate business arrangements
  BORROW_ABA_INFO: 'borrow-aba-info',
  // illustrator borrower : broker transaction question
  BORROW_BROKER_TRANSACTION_QUESTION: 'borrow-broker-transaction-q',
  // illustrator borrower : broker transactions
  BORROW_BROKER_TRANSACTIONS: 'borrow-broker-trans',
  // illustrator borrower : ethnicity page : borrower
  BORROW_DEMOGRAPHIC_INFO_BORROWER_PAGE: 'borrower-demographic-info',
  // illustrator borrower : ethnicity page : co-borrower
  BORROW_DEMOGRAPHIC_INFO_CO_BORROWER_PAGE: 'coborrower-demographic-info',
  // illustrator borrower : declarations
  BORROW_DECLARATIONS: 'borrow-declarations',
  // illustrator borrower : the new "interviewer page"; the old "interview page";
  BORROW_INTERVIEWER_PAGE: 'borrow-interview-page',
  // illustrator borrower combined page
  BORROW_COMBINED_PROPERTIES: 'borrow-combined-properties',
  //
  // ILLUSTRATOR BORROWER / STOP
  //
  SAVED_BORROWERS_PAGE: 'saved-borrowers-page',
  ADDRESS_AUTOCOMPLETE: 'address-autocomplete',

  VERSION_CONTROL: 'version-control',
  DOWNLOAD_PDF_BUTTON: 'download-pdf-btn',
  SHARE_ILLUSTRATION_EMAIL_BUTTON: 'share-illustration-email-btn',
  SHARE_ILLUSTRATION_PANEL: 'share-illustrations-panel',

  //
  // DEMO
  //
  BORROWER_SHORT_TO_CLOSE: 'borrower-short-to-close',

  BORROWER_HOA_MONTHLY_FEES: 'borrower-hoa-monthly-fees',

  ILLUSTRATOR_BORROWER_DETAILS_EXTRA_INPUTS: 'borrower-details-extra-inputs',

  LOAN_PIPELINE_NAV: 'loan-pipeline-nav',
  LOAN_PIPELINE_SUMMARY_PAGE: 'loan-pipeline-summary-page',
  LOAN_PIPELINE_DETAILS_PAGE: 'loan-pipeline-defails-page',
};
