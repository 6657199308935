import ReactGA from 'react-ga4';

let debug_mode = window.env.REACT_APP_GA_DEBGUG_ENABLED ? window.env.REACT_APP_GA_DEBGUG_ENABLED : false;
let trackingId = window.env.REACT_APP_GA_TRACKING_ID ? window.env.REACT_APP_GA_TRACKING_ID : null;
let tracking = false;
const cookieFlags = 'SameSite=None;Secure';

async function startTracking() {
  if (!window.env.REACT_APP_GA_TRACKING_ENABLED) {
    console.warn('Attempted to start analytics, but analytics is disabled.');
    return;
  }

  if (!trackingId || trackingId.match('#{.*}') !== null) {
    console.warn('No Google Analytics code found in environment, skipping registration of analytics');
    return;
  }

  if (tracking) {
    return;
  }

  ReactGA.initialize(trackingId, {
    gaOptions: {
      debug_mode: debug_mode,
      cookieFlags: cookieFlags,
    },
    gtagOptions: {
      debug_mode: debug_mode,
      cookie_flags: cookieFlags,
    },
  });

  tracking = true;
}

function trackEvent(category, action) {
  if (!tracking) {
    return;
  }

  ReactGA.event({
    action: action,
    category: category,
  });
}

function trackException(description, fatal = false) {
  if (!tracking) {
    return;
  }

  ReactGA.event('exception', {
    description: description,
    fatal: fatal,
  });
}

async function setUserId(sub) {
  if (!tracking) {
    return;
  }

  const userId = await createSHA2Hash(sub);
  ReactGA.set({userId: userId});
}

async function createSHA2Hash(input) {
  if (!input) {
    return '';
  }

  const msgBuffer = new TextEncoder().encode(input);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export {startTracking, setUserId, trackEvent, trackException};
