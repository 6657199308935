import {useFieldArray, useFormContext} from 'react-hook-form';
import useStyles from './AdditionalPropertiesFormTableStyles';
import {useTranslation} from 'lib/i18n';
import React, {useCallback, useMemo} from 'react';
import Item from 'components/common/layout/item/Item';
import Input from 'components/common/form/input/Input';
import Button from 'components/common/button/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {TableCell} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import Select from 'components/common/form/select/Select';
import {useUSStates} from 'hooks/us-states';
import {ADDITIONAL_PROPERTIES_FORM_LIST} from 'constants/link-pages';
import IconButton from 'components/common/button/IconButton';

function useAdditionalPropertiesFormTable(props) {
  const {control, triggerSubmit} = useFormContext();

  const name = ADDITIONAL_PROPERTIES_FORM_LIST;

  const {fields, append, remove} = useFieldArray({control, name});

  return {
    fields,
    append,
    remove,
    triggerSubmit,
  };
}

const MAX_RECORDS = 5;

function AdditionalPropertiesFormTable(props) {
  // props
  const {fields, append, remove} = useAdditionalPropertiesFormTable(props);

  const classes = useStyles();
  const {t} = useTranslation();
  const {data: usStates} = useUSStates();

  // max number of records allowed

  const addOneRecord = useCallback(() => {
    if (fields.length < MAX_RECORDS) {
      append({
        streetAddress: '',
        unitNumber: '',
        propertyCity: '',
        propertyState: '',
        propertyZip: '',
      });
    }
  }, [append, fields.length]);

  const removeLastRecord = useCallback(() => {
    if (fields.length > 1) {
      remove(fields.length - 1);
    }
  }, [fields.length, remove]);

  const disabledAddOneRecord = useMemo(() => fields.length >= MAX_RECORDS, [fields.length]);
  const isDeleteVisible = useMemo(() => fields.length > 1, [fields.length]);

  return (
    <>
      {/* content section / start */}
      <Item xs={12}>
        <div className={classes.overflowHidden}>
          <Table className={classes.table} aria-label="caption table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="left" className={classes.tableCell}>
                  {t('additionalPropertiesFormTranslation:street address')}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {t('additionalPropertiesFormTranslation:unit number')}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {t('additionalPropertiesFormTranslation:property city')}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {t('additionalPropertiesFormTranslation:property state')}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {t('additionalPropertiesFormTranslation:property zip')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((field, index) => (
                <TableRow key={index}>
                  <TableCell align="left" className={classes.tableCell}>
                    <Input
                      name={`apFormList[${index}].streetAddress`}
                      variant="standard"
                      label=""
                      defaultValue={field?.streetAddress}
                      className={classes.input}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    <Input
                      name={`apFormList[${index}].unitNumber`}
                      variant="standard"
                      label=""
                      defaultValue={field?.unitNumber}
                      className={classes.input}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    <Input
                      name={`apFormList[${index}].propertyCity`}
                      variant="standard"
                      label=""
                      defaultValue={field?.propertyCity}
                      className={classes.input}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    <Select
                      variant="standard"
                      name={`apFormList[${index}].propertyState`}
                      label=""
                      defaultValue={field?.propertyState}
                      options={usStates?.map((state) => ({
                        label: state.Abbreviation,
                        value: state.Abbreviation,
                      }))}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    <Input
                      name={`apFormList[${index}].propertyZip`}
                      variant="standard"
                      label=""
                      defaultValue={field?.propertyZip}
                      className={classes.input}
                      InputProps={{
                        endAdornment:
                          isDeleteVisible && index === fields.length - 1 ? (
                            <IconButton
                              size="sm"
                              onClick={removeLastRecord}
                              icon={['fas', 'window-close']}
                              className={classes.deleteButton}
                            />
                          ) : null,
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Item>
      {/* content section / stop */}

      {/* buttons section / start */}
      <Item xs={0} md={4} />

      <Item xs={12} md={4}>
        <Button
          btnStyle="bgElectric"
          buttonStyling={{lg: true}}
          onClick={addOneRecord}
          disabled={disabledAddOneRecord}
          data-testid="additional-properties-add-property-button"
        >
          {t('illustratorTranslation:add another property')}
        </Button>
      </Item>
      <Item xs={0} md={4} />

      {/* buttons section / stop */}
    </>
  );
}

AdditionalPropertiesFormTable.propTypes = {};

export default AdditionalPropertiesFormTable;
