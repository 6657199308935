import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    stepElementContainer: {
      width: '100%',
      margin: 0,
    },
    noSpaceForTop: {
      marginTop: 0,
      paddingTop: '0px !important',
    },
  }),
  {index: 1},
);

export default useStyles;
