import React from 'react';
import {ProductContextProvider} from 'contexts/product-context';
import {useForwardRefiHook} from 'components/products/forwardrefi/ForwardRefiHook';
import {useCalculateForwardRefiQuery} from 'query/products/hecm/forward-refi';
import {useForwardRefiOverallResults} from 'components/products/forwardrefi/ForwardRefiOverallResults';
import {API_ROUTES} from 'constants/illustrator';
import {useProductWithRef} from 'hooks/product-ref';

const ForwardRefi = React.forwardRef(function ForwardRefiRender(props, ref) {
  const {product, setRef, ...rest} = props;

  const forwardRefiHook = useForwardRefiHook({product});
  const forwardRefiQuery = useCalculateForwardRefiQuery({product});
  const forwardRefiOverallResults = useForwardRefiOverallResults({product});

  useProductWithRef({
    productDetails: forwardRefiHook,
    productResults: forwardRefiOverallResults,
    productQuery: forwardRefiQuery,
    ref,
    ApiRoute: API_ROUTES.FORWARDREFI,
  });

  return (
    <ProductContextProvider
      productDetails={forwardRefiHook}
      productResults={forwardRefiOverallResults}
      productQuery={forwardRefiQuery}
      product={product}
      {...rest}
    />
  );
});

export default ForwardRefi;
