import React from 'react';
import clsx from 'clsx';
import {Box} from '@material-ui/core';
import {disclaimerClassName} from 'constants/disclaimer';

/**
 *
 * @param props
 * @param props.Component {React.Component}
 * @param props.className {?string}
 * @param props.children {React.ReactNode}
 * @return {JSX.Element}
 * @constructor
 */
function WidgetDisclaimer(props) {
  const {Component = Box, className, children, ...rest} = props;

  return (
    <Component className={clsx(className, disclaimerClassName)} {...rest}>
      {children}
    </Component>
  );
}

export default WidgetDisclaimer;
