import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    chartCurrency: {
      ...theme.typography.h6,
      fontWeight: 700,
      fill: theme.palette.grey.main,
    },
  }),
  {index: 1},
);

export default useStyles;
