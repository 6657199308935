import createListAdapter from 'adapter/list.adapter';
import {createSelector, createSlice} from '@reduxjs/toolkit';
import {BORROWER_PREFERENCE} from 'constants/illustrator';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {assign, defaultsDeep} from 'lodash';
import {generateId} from 'lib/generator';

export const DEFAULT_PAYMENT_TERM = 'No Term';
export const DEFAULT_PRINCIPAL_AND_INTEREST = 'N/A';

export const DEFAULT_EXISTING_MORTGAGE = {
  MortgageRate: '',
  MortgagePayment: '',
  HomeownersInsurancePremium: '',
  TaxesAndInsuranceReserve: null,
  PropertyTaxPayment: '',
  YearsRemaining: '',
  PropertyTaxPaymentTypeId: null,
  InsurancePremiumPaymentTypeId: null,
};

export const DEFAULT_TENURE_RESULTS = {
  MaxMonthlyPayment: '',
  ConversionAge: '',
};

export const DEFAULT_HOME_SAFE_METRIC = {
  HomeSafeMetricId: '',
  Name: '',
  Rate: '',
  OriginationFee: '',
};

export const DEFAULT_HYBRID_METRIC = {
  PaymentTermYears: DEFAULT_PAYMENT_TERM,
};
export const DEFAULT_HYBRID_RESULT = {
  NewMonthlyPayment: DEFAULT_PRINCIPAL_AND_INTEREST,
};

export const DEFAULT_BORROWER_PROFILE = {
  Age: '',
  BorrowerProfileId: '',
  DateOfBirth: '',
  Email: '',
  ExistingMortgage: {...DEFAULT_EXISTING_MORTGAGE},
  ExistingMortgageId: '',
  FirstName: '',
  IsSaved: false,
  LastName: '',
  Liens: '',
  OwnerUserId: '',
  Address: '',
  State: '',
  ValueOfProperty: '',
  Zip: '',
  FirstLien: null, // HomeSafe Second specific attr
  OtherLiens: null, // HomeSafe Second specific attr
  IsThere2nd: 'no',
};

export function cleanUpIllustration(data = {}, defaultData = {}) {
  const {CashAtClose = null, ClosingCost=0, OverrideClosingCosts=false, advancedOptions = [], ...rest} = defaultData;

  return assign({...data}, {CashAtClose, ClosingCost, OverrideClosingCosts, advancedOptions, ...rest});
}

export function getDefaultIllustration(data) {
  return {
    id: data?.id || generateId(),

    scenario: data?.scenario || '',

    BorrowerPreference: data?.BorrowerPreference || BORROWER_PREFERENCE.MOST_PROCEEDS,
    Address: data?.Address || '',

    BorrowerProfileId: data?.BorrowerProfileId || '',

    showAdvancedOptions: data?.showAdvancedOptions || false,
    showLearnMore: data?.showLearnMore || false,

    ApiRoute: data?.ApiRoute || '',
    IllustrationName: data?.IllustrationName || '',
    CalculationId: data?.CalculationId || '',
    FundAccess: data?.FundAccess || '',
    Tenure: data?.Tenure || false,
    recommended: data?.recommended || '',
    PrincipalLimit: data?.PrincipalLimit || '',
    AdjustedPL: data?.AdjustedPL || '',
    ServiceFee: data?.ServiceFee || '',
    LineOfCredit: data?.LineOfCredit || '',
    RemainingPrincipalLimit: data?.RemainingPrincipalLimit || '',
    RemainingEquity: data?.RemainingEquity || '',
    SimplifiedCashToBorrower: data?.SimplifiedCashToBorrower || '',
    TotalUpfrontDraw: data?.TotalUpfrontDraw || '',
    MaxRevolvingLoc: data?.MaxRevolvingLoc || '',
    ClosingCost: data?.ClosingCost || '',
    CashAtClose: data?.CashAtClose || '',
    FirstLien: data?.FirstLien || null,
    OtherLiens: data?.OtherLiens || null,
    ExpectedRate: data?.ExpectedRate || '',
    InitialRate: data?.InitialRate || '',
    FixedRate: data?.FixedRate || '',
    MIP: data?.MIP || '',
    TotalInitialRate: data?.TotalInitialRate || '',
    HybridMetric: defaultsDeep({...data?.HybridMetric}, {...DEFAULT_HYBRID_METRIC}),
    HybridResult: defaultsDeep({...data?.HybridResult}, {...DEFAULT_HYBRID_RESULT}),
    TenureResult: defaultsDeep({...data?.TenureResult}, {...DEFAULT_TENURE_RESULTS}),
    HomeSafeMetric: defaultsDeep({...data?.HomeSafeMetric}, {...DEFAULT_HOME_SAFE_METRIC}),
    MinimumInitialDraw: data?.MinimumInitialDraw || '',
    OverrideClosingCosts: data?.OverrideClosingCosts || '',
    OverrideClosingCostsKey: Math.random(),
    LenderCredit: data?.LenderCredit || '',
    Lesa: data?.Lesa || '',
    HomeSafeMetricId: data?.HomeSafeMetricId || '',
    Fee: data?.Fee || '',
    Rate: data?.Rate || '',
    Margin: data?.Margin || '',
    upfrontDrawPercentage: data?.upfrontDrawPercentage || '',
    TotalDraw: data?.TotalDraw || '',
    BorrowerProfile: defaultsDeep({...data?.BorrowerProfile}, {...DEFAULT_BORROWER_PROFILE}),
    advancedOptions: data?.advancedOptions || [],
    showQuickFill: data?.showQuickFill || true,
  };
}

/**
 *
 * Always assume IDs are stored in a field other that illustrator.id
 *
 * To obtain the illustration ID field, take a look at the getDefaultIllustration json.
 *
 */

function selectId(illustration) {
  return illustration.id;
}

export const illustratorAdapter = createListAdapter({
  selectId,
  defaultValue: getDefaultIllustration,
});

export const illustratorSelectors = illustratorAdapter.getSelectors();

const illustratorSlice = createSlice({
  name: 'illustrator',
  initialState: illustratorAdapter.getInitialState({
    progressBarValue: undefined,
  }),
  reducers: {
    addOneIllustration: illustratorAdapter.addOne,
    addOneIllustrationAndRemove: (state, action) => {
      state = illustratorAdapter.removeOne(state, {payload: state.selectedId});

      return illustratorAdapter.addOne(state, action);
    },
    addManyIllustrations: illustratorAdapter.addMany,
    removeOneIllustration: illustratorAdapter.removeOne,
    removeAllIllustrations: illustratorAdapter.removeAll,
    removeSelectedIllustration: illustratorAdapter.removeBySelectedId,
    updateOneIllustration: illustratorAdapter.updateOne,
    updateSelectedIllustration: illustratorAdapter.updateSelected,
    updateManyIllustration: illustratorAdapter.updateMany,
    setSelectedIllustrationId: illustratorAdapter.setSelectedId,
    setProgressBarValue: (state, action) => {
      state.progressBarValue = action.payload;
    },
    toggleShowQuickFill: (state, action) => {
      const selectedIllustration = getSelectedIllustration({illustrator: state});

      return illustratorAdapter.updateSelected(state, {payload: {showQuickFill: !selectedIllustration.showQuickFill}});
    },
  },
});

export const {
  addOneIllustration,
  addOneIllustrationAndRemove,
  addManyIllustrations,
  updateSelectedIllustration,
  removeAllIllustrations,
  removeOneIllustration,
  removeSelectedIllustration,
  setSelectedIllustrationId,
  setSelectedIllustrationByCalculationId,
  updateOneIllustration,
  updateManyIllustration,
  toggleShowQuickFill,
  setProgressBarValue,
} = illustratorSlice.actions;

function selectSelf(state) {
  return state.illustrator;
}

export const getSelectedIllustration = createSelector(selectSelf, (state) =>
  illustratorSelectors.getBySelectedId(state),
);
export const getIllustrationById = (id) =>
  createSelector(selectSelf, (state) => {
    return illustratorSelectors.selectById(state, id);
  });

export const getSelectedIllustrationId = createSelector(selectSelf, (state) =>
  illustratorSelectors.getSelectedId(state),
);
export const getAllIllustrations = createSelector(selectSelf, (state) => illustratorSelectors.selectAll(state));
export const getShowQuickFill = createSelector(
  selectSelf,
  (state) => illustratorSelectors.getBySelectedId(state)?.showQuickFill,
);

export function useIllustrationHasProductSet() {
  const {ApiRoute, BorrowerProfileId} = useSelector(getSelectedIllustration);
  const illustrations = useSelector(getAllIllustrations);

  const selectedProductSet = useMemo(() => !!ApiRoute, [ApiRoute]);
  const productSet = useMemo(() => {
    if (ApiRoute) return true;

    return illustrations.find((illustration) => illustration?.ApiRoute);
  }, [ApiRoute, illustrations]);

  return {selectedProductSet, productSet, enabled: !!BorrowerProfileId};
}

export default illustratorSlice.reducer;
