import React, {useMemo} from 'react';
import {BORROWER_FOR_PARENTHESIS, CO_BORROWER_FOR_PARENTHESIS, CO_BORROWER_PREFIX} from 'constants/borrower';
import Select from 'components/common/form/select/Select';
import useStyles from './BoOrCoStyles';
import Input from 'components/common/form/input/Input';
import {useTranslation} from 'lib/i18n';
import useGovernmentRegulationForProduct from 'hooks/government-regulation/governmentRegulationForProduct';
import DatePicker from 'components/common/form/date-picker/DatePicker';
import Switch3States from 'components/common/form/switch-3-states/Switch3States';
import {TableBody} from '@mui/material';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import {TableCell} from '@material-ui/core';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import {useFormContext} from 'react-hook-form';

/**
 *
 * Borrower or Co-Borrower ?
 *
 * This component is used to generate all the sub-elements for :
 *    - the "borrower"
 *    or for :
 *    - the "co-borrower"
 *
 */

function BoOrCo(props) {
  const {prefix, question, item = {xs: 12}} = props;

  const classes = useStyles();
  const {t} = useTranslation();

  const attributeName = useMemo(() => prefix + question.value, [prefix, question]);

  const {watch} = useFormContext();

  const questionToWatch = watch(attributeName);

  const declarationsDropdownOptions = useMemo(
    () => t('illustratorBorrowerDeclarationTranslation:dropDownSources', {returnObjects: true}) || [],
    [t],
  );

  const regulation = useGovernmentRegulationForProduct();

  const prefixWithinParenthesis = useMemo(() => {
    if (prefix === CO_BORROWER_PREFIX) {
      return CO_BORROWER_FOR_PARENTHESIS;
    }
    return BORROWER_FOR_PARENTHESIS;
  }, [prefix]);

  if (questionToWatch !== 'true') {
    /**
     * the current question is set as !"true"
     * !!! The entire section must not render.
     */

    return null;
  }

  return (
    <Container>
      {question?.options.map((questionOption) => (
        <Item key={prefix + questionOption.name} className={classes.inputSection} {...item}>
          {questionOption?.type === 'dropDown' ? (
            <Select
              variant="standard"
              name={prefix + questionOption.name}
              label={
                t(
                  'inputsTranslation:' +
                    (questionOption.name[0].toLowerCase() + questionOption.name.substring(1)) +
                    regulation +
                    '.label',
                ) + ` (${prefixWithinParenthesis})`
              }
              defaultValue={null}
              options={declarationsDropdownOptions[questionOption.dropDownSource]?.map((option) => ({
                value: option.value,
                label: option.label,
                selected: option?.selected,
              }))}
            />
          ) : null}

          {questionOption?.type === 'input' || questionOption?.type === 'currency' || questionOption?.type === 'zip' ? (
            <Input
              name={prefix + questionOption.name}
              label={
                t(
                  'inputsTranslation:' +
                    (questionOption.name[0].toLowerCase() + questionOption.name.substring(1)) +
                    regulation +
                    '.label',
                ) + ` (${prefixWithinParenthesis})`
              }
              type={questionOption.type}
              variant="filled"
            />
          ) : null}

          {questionOption?.type === 'datePicker' ? (
            <DatePicker
              name={`${prefix}` + questionOption.name}
              label={
                t(
                  'inputsTranslation:' +
                    (questionOption.name[0].toLowerCase() + questionOption.name.substring(1)) +
                    regulation +
                    '.label',
                ) + ` (${prefixWithinParenthesis})`
              }
              inputVariant="filled"
            />
          ) : null}

          {questionOption?.type === 'switch' ? (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.width65px}>
                    <Switch3States name={prefix + questionOption.name} />
                  </TableCell>
                  <TableCell align="left">
                    {t(
                      'inputsTranslation:' +
                        (questionOption.name[0].toLowerCase() + questionOption.name.substring(1)) +
                        regulation +
                        '.label',
                    ) + ` (${prefixWithinParenthesis})`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : null}
        </Item>
      ))}
    </Container>
  );
}

export default BoOrCo;
