import React, {useImperativeHandle} from 'react';
import Paper from 'components/common/paper/Paper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Trans} from 'lib/i18n';
import Title from 'components/common/typeography/title/Title';
import Text from 'components/common/typeography/text/Text';
import Button from 'components/common/button/Button';
import {Box} from '@material-ui/core';
import useStyles from './IllustrationShareStyles';
import BorrowerNotesModal from 'components/illustrator/borrower-notes-modal/BorrowerNotesModal';
import ModalEmailToBorrower from 'components/illustrator/modal-email-to-borrower/ModalEmailToBorrower';
import Item from 'components/common/layout/item/Item';
import LoginModal from 'components/app-header/components/login-modal/LoginModal';
import clsx from 'clsx';
import FeaturesWrapper from 'components/common/features-wrapper/FeaturesWrapper';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';

const IllustrationShare = React.forwardRef(function IllustrationShareRender(props, ref) {
  const classes = useStyles();

  const {disableButtons, ApiRoute, ...rest} = props;

  useImperativeHandle(ref, () => ({disabled: disableButtons}), [disableButtons]);
  return (
    <Item {...rest}>
      <Paper className={classes.illustrationSharePaperContainer}>
        {/* icon */}
        <Box className={classes.illustrationShareIconContainer}>
          <FontAwesomeIcon size="3x" icon={['fas', 'share-alt']} />
        </Box>

        {/* title text : Share Illustrations */}
        <Box className={classes.marginTop}>
          <Title className="strong" variant="h5">
            <Trans i18nKey="illustratorTranslation:illustration share.title" />
          </Title>
        </Box>

        {/* description text : Share this illustration with your borrower via email or download it directly. */}
        <Box className={classes.marginBottom}>
          <Text className={classes.fontWeight500}>
            <Trans i18nKey="illustratorTranslation:illustration share.description" />
          </Text>
        </Box>

        {/* button : Email to Borrower */}
        <FeaturesWrapper ProductFeatureKey={PRODUCT_FEATURE_KEY.SHARE_ILLUSTRATION_EMAIL_BUTTON}>
          <LoginModal>
            <Box className={clsx(classes.buttonContainerStyling)}>
              <ModalEmailToBorrower ApiRoute={ApiRoute}>
                {({doOpen, loading}) => (
                  <Button
                    btnStyle="bgElectric"
                    buttonStyling={{lg: true}}
                    onClick={doOpen}
                    disabled={loading || disableButtons}
                    data-testid="email-to-borrower-button"
                  >
                    <Trans i18nKey="translation:justText.email to borrower" />
                  </Button>
                )}
              </ModalEmailToBorrower>
            </Box>
          </LoginModal>
        </FeaturesWrapper>

        {/* button : Download PDF */}
        <FeaturesWrapper ProductFeatureKey={PRODUCT_FEATURE_KEY.DOWNLOAD_PDF_BUTTON}>
          <Box className={clsx(classes.buttonContainerStyling, classes.buttonContainerStylingBottom)}>
            <BorrowerNotesModal ApiRoute={ApiRoute} isDownloadPdf={true}>
              {({doOpen, loading}) => (
                <Button
                  btnStyle="bgElectric"
                  buttonStyling={{lg: true}}
                  onClick={doOpen}
                  disabled={loading || disableButtons}
                  data-testid="download-pdf-button"
                >
                  <Trans i18nKey="translation:common.download pdf" />
                </Button>
              )}
            </BorrowerNotesModal>
          </Box>
        </FeaturesWrapper>
      </Paper>
    </Item>
  );
});
export default IllustrationShare;
