import React from 'react';
import Item from 'components/common/layout/item/Item';
import Title from 'components/common/typeography/title/Title';
import Container from 'components/common/layout/container/Container';
import clsx from 'clsx';
import Text from 'components/common/typeography/text/Text';
import Input from 'components/common/form/input/Input';
import {useTranslation} from 'lib/i18n';
import useStyles from 'pages/illustrator-borrower/broker-transactions/BrokerTransactionsStyles';
import {percentSymbol} from 'components/common/form/input/components/Percent';
import {currencySymbol} from 'components/common/form/input/components/Currency';
import {useBrokerTransactionQuestion} from 'pages/illustrator-borrower/broker-transactions/components/broker-transaction-question';

function BrokerTransactionsQuestionOne(props) {
  const {t} = useTranslation();
  const classes = useStyles();

  const {withValueLimit} = useBrokerTransactionQuestion();

  return (
    <Container>
      <Item xs={12}>
        <Title variant="h6" colorVariant="primaryDark" className={classes.title}>
          {t('illustratorTranslation:illustrator borrower broker transactions.q1')}
        </Title>
      </Item>

      <Item xs={1} sm={0} />
      <Item xs={10} sm={12}>
        <Container>
          <Item xs={0} sm={2} />

          <Item xs={12} sm={5} className={clsx(classes.brokerTransactionItem)}>
            <Text colorVariant="primaryDark" variant="body2">
              {t('illustratorTranslation:illustrator borrower broker transactions.qf1')}
            </Text>
          </Item>

          <Item xs={12} sm={3} className={clsx(classes.brokerTransactionItem)}>
            <Input
              name="totalInitialRate1"
              variant="standard"
              label=""
              type="percent"
              isAllowed={withValueLimit}
              inputProps={{decimalScale: 3}}
              placeholder={percentSymbol}
            />
          </Item>

          <Item xs={0} sm={2} />
        </Container>

        <Container>
          <Item xs={0} sm={2} />

          <Item xs={12} sm={5} className={clsx(classes.brokerTransactionItem)}>
            <Text colorVariant="primaryDark" variant="body2">
              {t('illustratorTranslation:illustrator borrower broker transactions.qf2')}
            </Text>
          </Item>

          <Item xs={12} sm={3} className={clsx(classes.brokerTransactionItem)}>
            <Input
              name="totalFeesPoints1"
              variant="standard"
              label=""
              type="currency"
              isAllowed={withValueLimit}
              placeholder={currencySymbol}
            />
          </Item>

          <Item xs={0} sm={2} />
        </Container>
      </Item>
      <Item xs={1} sm={0} />
    </Container>
  );
}

BrokerTransactionsQuestionOne.propTypes = {};

BrokerTransactionsQuestionOne.defaultValues = {};

export default BrokerTransactionsQuestionOne;
