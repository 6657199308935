import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    borrowerDetailsDescription: {
      marginTop: theme.spacing(1.25),
    },
    borrowerDetailsButtonTopContainer: {
      marginBottom: '0 !important',
      paddingBottom: '0 !important',
    },
    multipleElementItem: {
      margin: 0,
      padding: 0,
    },
    multipleElementContainer: {
      margin: 0,
      width: '100%',
    },
  }),
  {index: 1},
);

export default useStyles;
