import React from 'react';
import useStyles from './ProductPDFPrintoutCashFlowDifferenceStyles';
import {useStyleContentEffect} from 'hooks/style-content';

import Paper from '@material-ui/core/Paper';
import {ListItemText} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import clsx from 'clsx';
import Title from 'components/common/typeography/title/Title';

import {useTranslation} from 'lib/i18n';
import {formatCurrency} from 'lib/currency';
import {useGetBorrowerNotesQuery} from 'query/borrower/get-borrower-notes';
import {useProductContext} from 'contexts/product-context';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';

function ProductPDFPrintoutCashFlowDifference(props) {
  const {
    product: {
      calculation: {
        CalculationId,
        HybridResult: {MonthlySaved, NewMonthlyPayment} = {},
        BorrowerProfile: {ExistingMortgage: {MortgagePayment} = {}} = {},
      } = {},
    } = {},
  } = useProductContext();

  const classes = useStyles();
  const {t} = useTranslation();

  useStyleContentEffect(classes.content);

  const {data: note} = useGetBorrowerNotesQuery({CalculationId});

  return (
    <React.Fragment>
      <Paper variant="outlined" square className={clsx(classes.paper, classes.m2, {[classes.widthMarginTop]: !!note})}>
        <Title variant="h5" className={classes.paymentBreakdown}>
          {t('equityavailPrintoutTranslation:cash flow difference')}
        </Title>
        <Title variant="h3" className={classes.paymentBreakdown}>
          +{formatCurrency(MonthlySaved)}
          {t('equityavailPrintoutTranslation:per month')}
        </Title>
        <Container spacing={2} className={clsx(classes.p0p3)}>
          <Item xs={6} className={classes.borderBottom}>
            <ListItemText primary={t('equityavailPrintoutTranslation:before')} className={classes.text2left} />
          </Item>
          <Item xs={6} className={classes.borderBottom}>
            <ListItemText primary={t('equityavailPrintoutTranslation:after')} className={classes.text2left} />
          </Item>
          <Divider />
        </Container>
        <Container spacing={2} className={classes.p0p3}>
          <Item xs={6}>
            <ListItemText
              primary={formatCurrency(MortgagePayment) + t('equityavailPrintoutTranslation:per month')}
              className={classes.text2left}
            />
          </Item>
          <Item xs={6}>
            <ListItemText
              primary={formatCurrency(NewMonthlyPayment) + t('equityavailPrintoutTranslation:monthly interest payment')}
              className={classes.text2left}
            />
          </Item>
        </Container>
        <Container spacing={2} className={clsx(classes.p0p3)}>
          <Item xs={6} className={classes.borderBottom} />
          <Item xs={6} className={classes.borderBottom} />
        </Container>
        <Container spacing={2} className={classes.p0p3}>
          <Item xs={6}>
            <ListItemText primary={t('equityavailPrintoutTranslation:total expenses')} className={classes.text2left} />
          </Item>
          <Item xs={6}>
            <ListItemText primary={t('equityavailPrintoutTranslation:total expenses')} className={classes.text2left} />
          </Item>
          <Divider />
        </Container>
        <Container spacing={2} className={classes.p0p3}>
          <Item xs={6}>
            <ListItemText
              primary={'-' + formatCurrency(MortgagePayment) + t('equityavailPrintoutTranslation:per month')}
              className={classes.text2left}
            />
          </Item>
          <Item xs={6}>
            <ListItemText
              primary={'+' + formatCurrency(NewMonthlyPayment) + t('equityavailPrintoutTranslation:per month')}
              className={clsx(classes.text2left)}
            />
          </Item>
        </Container>
      </Paper>
    </React.Fragment>
  );
}

export default ProductPDFPrintoutCashFlowDifference;
