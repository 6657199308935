import {useCallback, useMemo} from 'react';
import {useProductFeature} from 'hooks/product-features';
import {ILLUSTRATOR_BORROWER_HASH, ROUTES} from 'constants/routes';
import {useHistory} from 'react-router-dom';
import {useIllustrateProduct} from 'hooks/product';
import {getBorrowerBirthDate} from 'lib/borrower';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {useProductContext} from 'contexts/product-context';
import {useBorrowerIllustration} from 'hooks/borrower';
import {generateId} from 'lib/generator';

/**
 *
 * This component is a small part of the "START APPLICATION" functionality.
 *
 * !! Please add & DO NOT DELETE any details or comments !!!
 *
 * This component is used to jump from the "Illustrator V2 compare" page to another location.
 *
 * - before jumping to the Borrower Link pages, we need to delete & create a new set of data
 *
 */

export function useStartApplication(props = {}) {
  const {product} = useProductContext();
  const {setHasSubmitted} = props;

  const {isProductFeatureEnabled} = useProductFeature();

  const showStartApplicationButton = useMemo(
    () => product && isProductFeatureEnabled(product.ApiRoute, PRODUCT_FEATURE_KEY.START_APPLICATION),
    [isProductFeatureEnabled, product],
  );

  const {illustrateProduct} = useIllustrateProduct({setHasSubmitted});

  /**
   *
   * The "START APPLICATION" button onClick event handler.
   *
   * "navigationAttemptModal" is a feature to hide the illustration form prompt navigation attempt modal
   *
   */

  const doStartApplicationCallback = useStartApplicationCallback();

  const onStartApplicationButtonClick = useCallback(
    async ({navigationAttemptModal = true, originUrl = ''}) => {
      const illustration = await illustrateProduct({navigationAttemptModal, ...product});

      const {BorrowerProfile, ApiRoute} = illustration;

      if (showStartApplicationButton && BorrowerProfile) {
        const {
          FirstName,
          LastName,
          Email,
          Address,
          Zip,
          State,
          Liens,
          ValueOfProperty,
          DateOfBirth: DOB,
          FirstLien,
          OtherLiens,
        } = BorrowerProfile;

        const DateOfBirth = getBorrowerBirthDate(DOB);

        /**
         *
         * This is the place where the new borrower data is being created, to be later used.
         *
         * The history data will be loaded & used within the "IllustratorBorrower" main file.
         *
         */

        return doStartApplicationCallback(
          {
            ApiRoute,
            // origin Url to come back at
            originUrl: originUrl,
            borrowerInfo: {
              borrowerFirstName: FirstName || '',
              borrowerLastName: LastName || '',
              borrowerEmail: Email || '',
              borrowerDOB: DateOfBirth || '',
            },
            property: {
              propertyAddress: Address || '',
              /**
               *
               * !!! CAUTION !!!
               *
               * The "property state" is required on the "interviewer page" for the "license" validation.
               *
               * Based on the "property zip code", the illustration MUST provide the "property state".
               *
               * If the "property state" is not provided, we might be dealing with a possible SHOW STOPPER !
               *
               */
              propertyState: State || '',
              // the "property zip code" is required on the "interviewer page"
              propertyZipCode: Zip || '',
              propertyHomeValue: ValueOfProperty || '',
              propertyMortgagePayoff: Liens || '',
              propertyFirstLien: FirstLien || '',
              propertyOtherLiens: OtherLiens || '', // todo : do we add & keep this param ?
            },
          },
          `${ROUTES.ILLUSTRATOR_BORROWER_ROOT_PAGE}#${ILLUSTRATOR_BORROWER_HASH.INTERVIEWER_PAGE}`,
        );
      }
    },
    [illustrateProduct, product, showStartApplicationButton, doStartApplicationCallback],
  );

  return {showStartApplicationButton, onStartApplicationButtonClick};
}

export function useStartApplicationCallback() {
  const {addBorrowerIllustration} = useBorrowerIllustration();

  const history = useHistory();

  return useCallback(
    async (data = {}, path = ROUTES.ILLUSTRATOR_BORROWER_ROOT_PAGE) => {
      await addBorrowerIllustration({
        id: generateId(),
        ...data,
      });

      return history.push(path);
    },
    [addBorrowerIllustration, history],
  );
}
