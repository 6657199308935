import {useTranslation} from 'lib/i18n';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {get, isFunction} from 'lodash';
import moment from 'lib/moment';
import {getAge, getBorrowerBirthDate} from 'lib/borrower';
import {ToString} from 'lib/utils';
import {useFormContext} from 'react-hook-form';
import {advancedOptionsName} from 'constants/illustrator';

export function useInputName(props = {}) {
  const {name = '', arrayField} = props;

  return useMemo(() => {
    if (arrayField) return name.replace(/(\[\d+])/, '');

    return name.split('.')?.pop();
  }, [name, arrayField]);
}

export function useInputLabel({label, name}) {
  const {t} = useTranslation();

  const inputName = useInputName({name});

  return label ?? t(`inputsTranslation:${inputName}.label`);
}

export function useInputDisabled({disabled, name}) {
  const {watch} = useFormContext();

  const isDisabledFunction = useMemo(() => isFunction(disabled), [disabled]);

  const data = isDisabledFunction ? watch() : null;

  return useMemo(() => (isDisabledFunction ? disabled(data) : disabled), [disabled, data, isDisabledFunction]);
}

export function useInputError({name, arrayField}) {
  const {t} = useTranslation();
  const {
    formState: {errors},
  } = useFormContext();

  const inputName = useInputName({name, arrayField});

  const error = useMemo(() => get(errors, name), [errors, name]);
  const errorType = useMemo(() => error?.type, [error]);

  const showError = useMemo(() => !!error, [error]);

  const params = useMemo(
    () =>
      showError
        ? {
            ...error.params,
            [errorType]:
              error.params?.[errorType] instanceof moment
                ? getBorrowerBirthDate(error.params[errorType])
                : error.params?.[errorType],
          }
        : undefined,
    [showError, error, errorType],
  );

  const errorMessage = useMemo(() => {
    const inputReplace = arrayField ? inputName : ToString(inputName).replace(/(\[*.\])|(^.*\.)|(\..*$)/, '');

    return showError ? t(`inputsTranslation:${inputReplace}.errors.${errorType}`, {...params}) : undefined;
  }, [t, showError, inputName, params, errorType, arrayField]);

  return {...error, showError, errorMessage};
}

export function useAdvancedOptionsError({rowIdx, column}) {
  const name = useMemo(() => `${advancedOptionsName}[${rowIdx}].${column?.key}`, [rowIdx, column]);

  return useInputError({name, arrayField: true});
}

export function useValidationSchema({name = ''}) {
  const {describe} = useFormContext();

  const tests = useMemo(() => get(describe?.fields, name.split('.').join('.fields.'))?.tests, [describe, name]);

  return useMemo(() => {
    const validations = {};

    if (tests)
      tests.forEach((test) => {
        validations[test.name] = true;
      });
    return validations;
  }, [tests]);
}

export function useSelectHookRangeValues(props = {}) {
  const {name, data = [], defaultValue: moduleDefaultValue, enabled = true, forceOverride = false} = props;
  const {watch, setValue} = useFormContext();

  const value = watch(name);

  const valueExistInSelect = useMemo(
    () => (forceOverride ? false : data.find((element) => ToString(element.value) === ToString(value))),
    [forceOverride, data, value],
  );

  const defaultValue = useMemo(() => moduleDefaultValue ?? data[0]?.value, [moduleDefaultValue, data]);

  useEffect(() => {
    if (enabled) {
      /**
       * If the current value from form is not in available options, set the default value
       */
      if (ToString(defaultValue) !== ToString(value) && !valueExistInSelect) {
        setValue(name, defaultValue, {shouldDirty: true});
      }
    }
  }, [name, value, setValue, enabled, defaultValue, valueExistInSelect]);
}

export function useMinDateOfBirth() {
  const {watch} = useFormContext();
  const DateOfBirth = watch('BorrowerProfile.DateOfBirth');

  const minAge = useMemo(() => getAge(DateOfBirth), [DateOfBirth]);

  return {minAge};
}

export function useElementFocus(props = {}) {
  const {onFocus: moduleOnFocus, onBlur: moduleOnBlur} = props;
  const [isFocused, setFocused] = useState(false);

  const onFocus = useCallback(
    (event) => {
      event.persist();
      setFocused(true);
      if (isFunction(moduleOnFocus)) moduleOnFocus(event);
    },
    [moduleOnFocus],
  );

  const onBlur = useCallback(
    (event) => {
      event.persist();
      setFocused(false);
      if (isFunction(moduleOnBlur)) moduleOnBlur(event);
    },
    [moduleOnBlur],
  );

  return {isFocused, onBlur, onFocus};
}
