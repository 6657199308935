import React from 'react';
import Tabs from 'components/common/Tabs/Tabs';
import IllustrationTab from 'pages/illustrator/components/illustration/components/illustrator-tabs/components/illustration-tab/IllustrationTab';
import clsx from 'clsx';
import {useViewType, VIEW_TYPE} from 'hooks/view-type';
import {IconButton} from '@material-ui/core';
import TabPanel from 'components/common/tab-panel/TabPanel';
import CalculationWarnings from 'components/illustrator/calculation-warnings/CalculationWarnings';
import {CALCULATION_CONTAINER, EXCLUDE_ILLUSTRATOR_WARNING_MESSAGES} from 'constants/illustrator';
import ProductCards from 'pages/illustrator/components/product-cards/ProductCards';
import IllustratorTabs from 'pages/illustrator/components/illustration/components/illustrator-tabs/IllustratorTabs';
import {TabContext} from '@material-ui/lab';
import {useTranslation} from 'lib/i18n';
import useStyles from './IllustrationTabsStyles';
import Paper from 'components/common/paper/Paper';
import {useIllustrationHasProductSet} from 'reducers/illustrator.reducer';
import Item from 'components/common/layout/item/Item';
import {renderChildren} from 'lib/react';
import { CSSTransition } from 'react-transition-group';

/**
 *
 * This is the "Illustration Tabs" component.
 *
 * Within this component, we have created the structure of the "Illustration" page.
 *
 * See the "IllustrationTabs-README.md" file for details.
 *
 */

function IllustrationTabs(props) {
  const {children, component: Component = Item, setHasSubmitted, ...rest} = props;
  const classes = useStyles();
  const {t} = useTranslation();

  const {selectedProductSet, productSet, enabled} = useIllustrationHasProductSet();

  const {viewType, onTabChange} = useViewType({
    defaultValue: selectedProductSet ? VIEW_TYPE.illustrate : VIEW_TYPE.card,
    watchDefaultValue: true,
  });

  if (!enabled) return null;

  return (
    <>
      <CSSTransition in={true} timeout={500}>
        <Component {...rest} id={CALCULATION_CONTAINER}>
          <TabContext value={viewType}>
            {/** tabs / start */}
            <Tabs
              value={viewType}
              onChange={onTabChange}
              indicatorColor="primary"
              classes={{root: classes.gridOutContainer, flexContainer: classes.alignLeftMenu}}
            >
              {/** left side tabs / start */}

              {/* tab : Illustrate / start */}
              <IllustrationTab
                classes={{root: clsx({[classes.hide]: !productSet, [classes.noUnderline]: !productSet})}}
                key={VIEW_TYPE.illustrate}
                value={VIEW_TYPE.illustrate}
                title={t('illustratorTranslation:illustration.change view.card')}
                icon={
                  <IconButton color="primary" className={classes.changeButton} component="div">
                    {t('illustratorTranslation:illustration.change view.card')}
                  </IconButton>
                }
                data-testid="illustration-product-tab-illustrate"
              />
              {/* tab : Illustrate / stop */}

              {/* tab : Compare / start */}
              <IllustrationTab
                classes={{root: clsx({[classes.hide]: !productSet, [classes.noUnderline]: !productSet})}}
                value={viewType === VIEW_TYPE.illustrate ? VIEW_TYPE.card : viewType}
                title={t('illustratorTranslation:illustration.change view.list')}
                icon={
                  <IconButton color="primary" className={classes.changeButton} component="div">
                    {t('illustratorTranslation:illustration.change view.list')}
                  </IconButton>
                }
                data-testid="illustration-product-tab-compare"
              />
              {/* tab : Compare / stop */}

              {/** left side tabs / stop */}
            </Tabs>
            {/** tabs / stop */}

            {/** tabs panels / start */}
            <Paper className={clsx(classes.productComparisonContainer)}>
              {/** compare tab panels / start */}

              {/* card view / start */}
              <TabPanel value={VIEW_TYPE.card}>
                <ProductCards
                  onTabChange={onTabChange}
                  className={classes.productComparisonProductCards}
                  isProductComparsion={productSet}
                  setHasSubmitted={setHasSubmitted}
                >
                  {({products, loading}) => (
                    <CalculationWarnings
                      products={products}
                      exclude={EXCLUDE_ILLUSTRATOR_WARNING_MESSAGES}
                      loading={loading}
                    />
                  )}
                </ProductCards>
              </TabPanel>
              {/* card view / stop */}

              {/* illustrate tabs / start */}
              <TabPanel value={VIEW_TYPE.illustrate}>
                <IllustratorTabs setHasSubmitted={setHasSubmitted} />
              </TabPanel>
              {/* illustrate tabs / stop */}

              {/** compare tab panels / stop */}
            </Paper>
            {/** tabs panels / stop */}
          </TabContext>
        </Component>
      </CSSTransition>

      {/* list the children elements */}
      {React.Children.map(
        children,
        (child, index) =>
          child && (
            <Component key={index} {...rest} hide={viewType !== VIEW_TYPE.illustrate}>
              {renderChildren(child)}
            </Component>
          ),
      )}
    </>
  );
}

export default React.memo(IllustrationTabs);
