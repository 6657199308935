import React, {useCallback, useEffect, useMemo} from 'react';
import clsx from 'clsx';
import {Box} from '@material-ui/core';
import useStyles from './AppSideBarStyles';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'lib/i18n';
import HeaderComp from 'components/app-header';
import {useStyleContentContext} from 'contexts/style-content-context';
import OpenMenuButton from 'components/app-sidebar/components/menu/open-menu-button/OpenMenuButton';
import ScrollTop from 'components/scroll-top/ScrollTop';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import collapseMenuInactive from 'assets/images/collapse-menu.png';
import Divider from '@material-ui/core/Divider';
import NavList from 'components/app-sidebar/components/nav-list/NavList';
import ProfileMenu from 'components/app-sidebar/components/profile-menu/ProfileMenu';
import BecomePartnerMenu from 'components/app-sidebar/components/become-partner-menu/BecomePartnerMenu';
import {useMobileApp} from 'lib/app-theme';
import {useAuthenticated} from 'hooks/authenticate';
import HideInIFrame from 'components/hide-in-iframe/HideInIFrame';

function AppSidebarSponsors(props) {
  const {showPartnerBanner, isAuthenticated} = props;

  if (showPartnerBanner) {
    if (isAuthenticated) return <ProfileMenu />;
    else return <BecomePartnerMenu />;
  }

  return null;
}

function AppSidebarChildren(props) {
  const {hideDrawer, handleDrawerClose, children, showPartnerBanner, isAuthenticated} = props;

  const {contentClassName} = useStyleContentContext();
  const classes = useStyles();

  return (
    <Box className={classes.container} onClick={handleDrawerClose}>
      <Box className={classes.toolbar} />
      <HideInIFrame>
        <AppSidebarSponsors showPartnerBanner={showPartnerBanner} isAuthenticated={isAuthenticated} />
      </HideInIFrame>
      <Box className={clsx(classes.content, contentClassName, {[classes.contentNoDrawer]: hideDrawer})}>{children}</Box>
    </Box>
  );
}

function AppSidebarDrawer(props) {
  const {open, handleDrawerOpen, handleDrawerClose, showDrawer, isAuthenticated} = props;
  const {t} = useTranslation();
  const classes = useStyles();

  if (showDrawer && isAuthenticated) {
    return (
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, classes.drawerHide, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, classes.drawerHide, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Box display="flex" justifyContent="flex-start" className={classes.toolbar}>
          <OpenMenuButton
            color="secondary"
            onClick={handleDrawerOpen}
            className={clsx(classes.appBarMenuButton, {[classes.hideOpacity]: open})}
            imgClassName={classes.menuImg}
            data-testid="menu-open-drawer-button"
          />
          <IconButton
            onClick={handleDrawerClose}
            className={clsx(classes.appBarMenuButton, {[classes.hide]: !open})}
            data-testid="menu-close-drawer-button"
          >
            <img
              data-testid="menu-inactive-img"
              className={classes.menuImg}
              src={collapseMenuInactive}
              alt={t('translation:common.close')}
            />
          </IconButton>
        </Box>

        <Divider />

        <NavList drawerOpen={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
      </Drawer>
    );
  }

  return null;
}

function AppSidebar(props) {
  const isMobileApp = useMobileApp();
  const [open, setOpen] = React.useState(false);
  const {children, showPartnerBanner = true, showDrawer = true, component: Component = HeaderComp} = props;
  const location = useLocation();

  const classes = useStyles();

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    handleDrawerClose();
  }, [handleDrawerClose, location.pathname]);

  const {isAuthenticated} = useAuthenticated();

  const hideDrawer = useMemo(
    () => !showDrawer || !isAuthenticated || isMobileApp,
    [isAuthenticated, isMobileApp, showDrawer],
  );

  return (
    <Box className={classes.root} data-testid="app-sidebar">
      <HideInIFrame>
        <Component
          className={clsx(classes.appBar, {
            [classes.appBarDrawerOpen]: open,
            [classes.appBarNoDrawer]: hideDrawer,
          })}
        >
          {isAuthenticated && isMobileApp ? (
            <Box className={clsx(classes.drawerPaper)}>
              <OpenMenuButton
                color="secondary"
                onClick={handleDrawerOpen}
                className={clsx(classes.appBarMenuButton, {[classes.hideOpacity]: open})}
                imgClassName={classes.menuImg}
              />
            </Box>
          ) : null}
        </Component>
        <AppSidebarDrawer
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          showDrawer={showDrawer}
          isAuthenticated={isAuthenticated}
        />
      </HideInIFrame>

      <AppSidebarChildren
        hideDrawer={hideDrawer}
        handleDrawerClose={handleDrawerClose}
        children={children}
        showPartnerBanner={showPartnerBanner}
        isAuthenticated={isAuthenticated}
      />

      <ScrollTop />
    </Box>
  );
}

AppSidebar.propTypes = {};

export default AppSidebar;
