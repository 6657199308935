import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    formContainer: {
      margin: theme.spacing(0, 'auto'),
    },
    itemContainerWrapper: {
      display: 'block',
    },
    itemContainer: {
      width: '100%',
      margin: 'auto',
      '& .MuiFilledInput-root': {
        backgroundColor: 'unset',
      },
      '& .MuiInputLabel-root': {
        color: theme.palette.primary.dark,
        fontWeight: '600 !important',
      },
    },
    m_2: {
      margin: theme.spacing(2, 2, 2, 2),
    },
    m_0_1: {
      margin: theme.spacing(0, 1),
    },
    overflowHidden: {
      display: 'block',
      maxWidth: `calc(100vw)`,
      overflow: 'auto',
      '& .MuiSwitch-root': {
        minWidth: '56px',
        '& .MuiSwitch-thumb': {
          width: '26px',
        },
      },
    },
    labels: {
      color: theme.palette.primary.dark,
      fontSize: '1rem',
      lineHeight: 1.43,
      fontWeight: '600 !important',
      fontFamily:
        'Montserrat,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    },
    customInputElement: {
      '& .MuiFormLabel-root': {
        fontSize: '0.875rem',
      },
      '& .MuiInputLabel-filled': {
        transform: 'translate(0, 24px) scale(1)',
      },
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        transform: 'translate(12px, 10px) scale(0.75)',
      },
    },
    textAlignLeft: {
      textAlign: 'left',
    },
    textAlignRight: {
      textAlign: 'right',
    },
    pl3: {
      paddingLeft: theme.spacing(3),
    },
    pr3: {
      paddingRight: theme.spacing(3),
    },
    pl5: {
      paddingLeft: theme.spacing(5),
    },
    pr5: {
      paddingRight: theme.spacing(5),
    },
    noPadding: {
      padding: theme.spacing(0, 0, 0, 0) + ' !important',
    },
    maxButtonWidth: {
      width: '100%',
      maxWidth: 273,
      [isMobileAppQuery(theme)]: {
        maxWidth: undefined,
      },
    },
    customSelectElement: {
      '& .MuiFormLabel-root': {
        fontSize: '0.875rem',
      },
      '& .MuiFormLabel-filled': {
        transform: 'translate(0, 24px) scale(1)',
      },
      '& .MuiFormLabel-filled.MuiInputLabel-shrink': {
        transform: 'translate(12px, 10px) scale(0.75)',
      },
      '& .MuiSelect-root': {
        paddingLeft: '12px',
        marginTop: '5px',
      },
    },
  }),
  {index: 1},
);

export default useStyles;
