import React, {useCallback} from 'react';
import ContentContainer from '../content-section/components/ContentContainer';
import {useVisible} from 'hooks/utils';
import {Trans, useTranslation} from 'lib/i18n';
import {formatPercent} from 'lib/currency';
import {useOwnFormContext} from 'contexts/form-context';

function OkaySection(props) {
  const {y, setChartData, setChartTitle, setChartDisclaimer} = props;
  const {t} = useTranslation();
  const {Rate} = useOwnFormContext();

  const onEnterViewport = useCallback(() => {
    setChartData(['homeValueYearEnd', 'loanBalanceValueYearEnd', 'year', 'name']);
    setChartTitle(<Trans i18nKey="okay section.chart title" />);
    setChartDisclaimer(t('line chart section.disclaimer', {Rate: formatPercent(Rate)}));
  }, [setChartData, t, setChartDisclaimer, setChartTitle, Rate]);

  const {visibleNodeRef} = useVisible({threshold: 0.75, onEnterViewport});

  return (
    <ContentContainer
      ref={visibleNodeRef}
      style={{y}}
      title={t('okay section.title')}
      description={t('okay section.description')}
      disclaimer={t('okay section.disclaimer')}
    />
  );
}

OkaySection.propTypes = {};

export default OkaySection;
