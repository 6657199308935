import {useCallback, useMemo} from 'react';
import {isFunction} from 'lodash';
import {useProductsContext} from 'contexts/products-context';
import {useIsFetching, useQueryClient} from 'react-query';
import {productsComparisonQueryKey} from 'constants/query';
import {statusToLoading} from 'adapter/loading.adapter';

export function useQueryProducts(props = {}) {
  const {isSelectedProduct, queryKey = [productsComparisonQueryKey], fetching} = props;
  const {products, loading} = useProductsContext();
  const queryClient = useQueryClient();

  const productSelected = useCallback(
    (product) => {
      if (isFunction(isSelectedProduct)) return isSelectedProduct(product);

      return false;
    },
    [isSelectedProduct],
  );

  const product = useMemo(() => products?.find(productSelected), [products, productSelected]);
  const queryState = queryClient.getQueryState(queryKey, {exact: false, fetching});

  useIsFetching({exact: false, active: true, fetching, queryKey});

  return {
    products,
    loading,
    loadingProduct: statusToLoading(queryState?.status, queryState?.isFetching),
    product,
  };
}
