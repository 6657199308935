import React from 'react';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import {Trans} from 'lib/i18n';
import Title from 'components/common/typeography/title/Title';
import WidgetDisclaimer from 'components/common//widget-disclaimer/WidgetDisclaimer';
import Text from 'components/common/typeography/text/Text';
import {withProducts} from 'components/products';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import CalculatorForm from './components/calculator-form/CalculatorForm';
import {withApiKeyValidation} from 'components/validation/withApiKey';

function CalculatorPage(props) {
  return (
    <Container spacing={3} style={{margin: 'unset', width: '100%'}}>
      <Item xs={1} md={1} lg={3} />
      <Item xs={10} md={10} lg={6}>
        <Container>
          <Item xs={12}>
            <Title variant="h4" style={{textAlign: 'center'}}>
              <Trans i18nKey="calculator.title" />
            </Title>
          </Item>
          <Item xs={12} className={'subtitle'} style={{marginTop: '0px', paddingTop: '0px', paddingBottom: '0px'}}>
            <Title variant="subtitle2" style={{textAlign: 'left'}}>
              <Trans i18nKey="calculator.subtitle" />
            </Title>
          </Item>

          <CalculatorForm />

          <Item xs={12}>
            <WidgetDisclaimer>
              <Text variant="body2" style={{textAlign: 'left'}}>
                <Trans i18nKey="calculator.disclaimer" />
              </Text>
            </WidgetDisclaimer>
            <WidgetDisclaimer>
              <Title variant="body2" style={{textAlign: 'left', marginTop: '5px'}}>
                <Trans i18nKey="calculator.powered by" />
              </Title>
            </WidgetDisclaimer>
          </Item>
        </Container>
      </Item>
      <Item xs={1} md={2} lg={3} />
    </Container>
  );
}

export default withApiKeyValidation(
  withProducts(CalculatorPage, {productFeatureKey: PRODUCT_FEATURE_KEY.WIDGETS_CALCULATION}),
);
