import {useTranslation} from 'lib/i18n';
import {useMemo} from 'react';

/**
 *
 * Add only the question translation key.
 *
 * Make sure that the question is added in the translations file.
 *
 */

export default function useQuestionsOptions() {
  const {t} = useTranslation();

  return useMemo(
    () => [
      {
        value: 'ObservedEthnicity',
      },
      {
        value: 'ObservedSex',
      },
      {
        value: 'ObservedRace',
      },
    ],
    [t],
  );
}
