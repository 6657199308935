import {useMemo} from 'react';
import {sortBy} from 'lodash';

/**
 * Orders the Products based on order ProductId's list
 * @param {Object} props
 * @param {number[]} props.order ProductId orders
 * @param {Object[]} props.products Products list
 * @returns {Object[]} Ordered products list
 */
export function useFilterProducts(props = {}) {
  const {order: productsOrder, products} = props;

  return useMemo(() => {
    return sortBy(products, (product) => productsOrder.indexOf(product.ProductId));
  }, [products, productsOrder]);
}

/**
 * Order the list of ProductId's from a Calculations response
 * @param  {Object} props
 * @param  {Object[]} props.Calculations Calculations response
 * @returns {number[]} Ordered ProductId's list
 */
export function useCalculationOrder(props = {}) {
  const {Calculations = []} = props;

  return useMemo(() => Calculations?.map((calc) => calc.ProductId) ?? [], [Calculations]);
}

/**
 * Get the Calculations and Products and order that based on Calculations list order
 * @param {Object} props
 * @param {Object[]} props.products Products list
 * @param {Object[]} props.Calculations Calculations response
 * @returns {Object[]} Ordered products list
 */
export function useFilterCalculations(props = {}) {
  const {Calculations, products} = props;
  const order = useCalculationOrder({Calculations});

  return useFilterProducts({order, products});
}
