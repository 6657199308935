import {usePDFMatch} from 'hooks/print';

function HideInIFrame(props) {
  const isProductPDFPrintOutRouteMatch = usePDFMatch();

  if (window.top !== window.self && !isProductPDFPrintOutRouteMatch?.isExact) {
    return null;
  }

  return props.children;
}

export default HideInIFrame;
