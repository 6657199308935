import {useImperativeHandle} from 'react';

export function useProductWithRef(props = {}) {
  const {ref, productResults = {}, productDetails, productQuery, ApiRoute} = props;

  useImperativeHandle(
    ref,
    () => {
      return {productResults, productDetails, productQuery, ApiRoute};
    },
    [productResults, productDetails, productQuery, ApiRoute],
  );
}
