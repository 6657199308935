import React from 'react';
import useStyles from './ScrollDownStyles';
import clsx from 'clsx';

function ScrollDown(props) {
  const {component: Component = 'div', className, ...rest} = props;
  const classes = useStyles();

  return <Component {...rest} className={clsx(classes.section, className)} />;
}

ScrollDown.propTypes = {};

export default ScrollDown;
