import React, {useCallback, useMemo} from 'react';
import Form from 'components/common/form/Form';
import {useDispatch, useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower, updateSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import {useProductContext} from 'contexts/product-context';
import {useDateOfBirthValidation} from 'hooks/validator';
import yup from 'lib/validation';
import {propertyVestedInATrust, socialSecurityNumber} from 'lib/validation/validation';
import {PHONE_NUMBER as REGEX_PHONE_NUMBER} from 'constants/regex';
import useStyles from './BorrowerInfoFormStyles';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import Input from 'components/common/form/input/Input';
import DatePicker from 'components/common/form/date-picker/DatePicker';
import Select from 'components/common/form/select/Select';
import Button from 'components/common/button/Button';
import {Trans} from 'lib/i18n';
import useMaritalStatusOptions from 'hooks/link-application/borrower-info/marital-status-options';
import usePropertyVestedInATrustOptions from 'hooks/link-application/borrower-info/property-vested-in-a-trust-options';
import {useStepWizardContext} from 'contexts/step-wizard';
import DisableElement from 'components/common/form/DisableElement';
import {useEnabledProductFeatures} from 'hooks/product-features';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {toBoolean} from 'lib/utils';
import SocialSecurityNumber from 'components/common/form/input/SocialSecurityNumber';

function BorrowerInfoForm(props) {
  const {prefix = 'borrower'} = props;

  const {nextStep, skipNextStep} = useStepWizardContext();
  const classes = useStyles();

  const maritalStatusOptions = useMaritalStatusOptions();

  const propertyVestedInATrustOptions = usePropertyVestedInATrustOptions();

  const dispatch = useDispatch();

  const isBorrower = useMemo(() => prefix === 'borrower', [prefix]);
  const {isEnabled: isEnabledCoBorrowerButton} = useEnabledProductFeatures(PRODUCT_FEATURE_KEY.CO_BORROW_INFO);
  const showCoBorrowerButton = useMemo(
    () => isBorrower && isEnabledCoBorrowerButton,
    [isBorrower, isEnabledCoBorrowerButton],
  );

  const {[`${prefix}Info`]: defaultValues = {}, property: {propertyZipCode} = {}} =
    useSelector(getSelectedIllustratorBorrower);
  const {product} = useProductContext();

  const onSubmit = useCallback(
    async (data, event) => {
      let dataToSubmit = {
        [`${prefix}Info`]: data,
      };

      await dispatch(updateSelectedIllustratorBorrower(dataToSubmit));

      if (showCoBorrowerButton && !toBoolean(event.nativeEvent.submitter.dataset.coborrower)) {
        return skipNextStep();
      }

      return nextStep();
    },
    [prefix, dispatch, showCoBorrowerButton, nextStep, skipNextStep],
  );

  const DateOfBirthValidation = useDateOfBirthValidation({
    Zip: propertyZipCode,
    ApiRoute: product?.ApiRoute,
  });

  const validationSchema = useMemo(
    () =>
      yup.object({
        [`${prefix}FirstName`]: yup.string().required(),
        [`${prefix}LastName`]: yup.string().required(),
        [`${prefix}Suffix`]: yup.string().max(4),
        [`${prefix}MiddleName`]: yup.string(),
        [`${prefix}DOB`]: DateOfBirthValidation,
        [`${prefix}SSN`]: socialSecurityNumber(),
        [`${prefix}Email`]: yup.string().email(),
        [`${prefix}HomePhoneNumber`]: yup.string().matches(REGEX_PHONE_NUMBER).min(0).max(13),
        [`${prefix}MaritalStatus`]: yup.string().required(),
        [`${prefix}PowerOfAttorney`]: yup.string(),
        [`${prefix}PropertyVestedInATrust`]: propertyVestedInATrust(),
      }),
    [DateOfBirthValidation, prefix],
  );

  const isNextButtonDisabled = useCallback(({borrowerMaritalStatus}) => borrowerMaritalStatus === 'Married', []);

  return (
    <Container component={Form} onSubmit={onSubmit} defaultValues={defaultValues} validationSchema={validationSchema}>
      <Item xs={0} md={1} lg={2} />

      <Item xs={12} md={10} lg={8}>
        <Container>
          <Item xs={12} sm={6}>
            <Input name={`${prefix}FirstName`} variant="filled" />
          </Item>
          <Item xs={12} sm={6}>
            <Input name={`${prefix}LastName`} variant="filled" />
          </Item>

          <Item xs={12} sm={6}>
            <Input name={`${prefix}MiddleName`} variant="filled" />
          </Item>
          {isBorrower ? (
            <Item xs={12} sm={6}>
              <Input name={`${prefix}Suffix`} variant="filled" />
            </Item>
          ) : null}

          <Item xs={12} sm={6}>
            <DatePicker name={`${prefix}DOB`} inputVariant="filled" />
          </Item>
          <Item xs={12} sm={6}>
            <SocialSecurityNumber name={`${prefix}SSN`} variant="filled" />
          </Item>

          <Item xs={12} sm={6}>
            <Input name={`${prefix}Email`} type="email" variant="filled" />
          </Item>
          <Item xs={12} sm={6}>
            <Input name={`${prefix}HomePhoneNumber`} variant="filled" />
          </Item>

          <Item xs={12} sm={6}>
            <Select
              order="1"
              name={`${prefix}MaritalStatus`}
              variant="filled"
              options={maritalStatusOptions?.map((option) => ({
                value: option.value,
                label: option.label,
              }))}
            />
          </Item>
          {isBorrower ? (
            <Item xs={12} sm={6}>
              <Input name={`${prefix}PowerOfAttorney`} variant="filled" />
            </Item>
          ) : null}

          {isBorrower ? (
            <Item xs={12} sm={6}>
              <Select
                order="1"
                name={`${prefix}PropertyVestedInATrust`}
                variant="filled"
                options={propertyVestedInATrustOptions}
              />
            </Item>
          ) : null}

          <Item xs={12}>
            <Container className={classes.buttonsContainer}>
              {showCoBorrowerButton ? null : <Item xs={0} sm={3} />}
              <Item xs={12} sm={6}>
                <DisableElement isDisabled={isNextButtonDisabled}>
                  <Button
                    data-testid="goToNextOriginBorrowerInfo"
                    name="goToNext"
                    btnStyle="bgElectric"
                    buttonStyling={{lg: true}}
                    type="submit"
                  >
                    <Trans i18nKey="illustratorTranslation:illustrator borrower info.button next" />
                  </Button>
                </DisableElement>
              </Item>
              {showCoBorrowerButton ? (
                <Item xs={12} sm={6}>
                  <Button
                    data-testid="goToCoBorrower"
                    data-coborrower={true}
                    name="goToCoBorrower"
                    btnStyle="bgElectric"
                    buttonStyling={{lg: true}}
                    type="submit"
                  >
                    <Trans i18nKey="illustratorTranslation:illustrator borrower info.button co-borrower" />
                  </Button>
                </Item>
              ) : null}
            </Container>
          </Item>
        </Container>
      </Item>

      <Item xs={0} md={1} lg={2} />
    </Container>
  );
}

BorrowerInfoForm.propTypes = {};

export default BorrowerInfoForm;
