import React, {useEffect} from 'react';
import {useProductAdditionalSteps} from 'hooks/product-additional-steps';
import RenderAllAdditionalOptions from 'components/common/render-all-additional-options/render-all-additional-options';

/**
 *
 * This component is used to list all the additional steps/configs/inputs.
 *
 */

const AdditionalSteps = React.forwardRef(function AdditionalStepsRender(props, ref) {
  const {className} = props;
  const {allAdditionalSteps} = useProductAdditionalSteps();

  useEffect(() => {
    if (ref) ref.current = {disabled: !allAdditionalSteps?.length};
  }, [ref, allAdditionalSteps]);

  return (
    <React.Fragment key="AdditionalSteps">
      <RenderAllAdditionalOptions options={allAdditionalSteps} className={className} />
    </React.Fragment>
  );
});

export default React.memo(AdditionalSteps);
