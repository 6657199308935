import React, {useCallback} from 'react';

import {formatCurrency} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import ContentContainer from 'pages/illustrator-hybrid-page/components/content-section/components/ContentContainer';
import {useVisible} from 'hooks/utils';
import NegativeCashUpFrontModal from './components/negative-cash-up-front-modal/NegativeCashUpFrontModal';
import {useOwnFormContext} from 'contexts/form-context';
import {usePrintContext} from 'contexts/print-context';

function ConsumerCashUpFrontSection(props) {
  const {y, setChartData} = props;
  const {CashAtClose} = useOwnFormContext();
  const {t} = useTranslation();

  const {printing} = usePrintContext();
  const onEnterViewport = useCallback(() => {
    setChartData(CashAtClose);
  }, [setChartData, CashAtClose]);

  const {visibleNodeRef} = useVisible({threshold: 0.5, onEnterViewport});

  return (
    <>
      <ContentContainer
        ref={visibleNodeRef}
        style={{y}}
        disclaimer={t('consumer cash up front section.disclaimer')}
        title={t('consumer cash up front section.title')}
        description={t('consumer cash up front section.description', {
          CashAtClose: formatCurrency(CashAtClose),
        })}
      />

      {!printing && <NegativeCashUpFrontModal SimplifiedCashToBorrower={CashAtClose} />}
    </>
  );
}

ConsumerCashUpFrontSection.propTypes = {};

export default ConsumerCashUpFrontSection;
