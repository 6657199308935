import React, {useCallback} from 'react';
import Input from '../form/input/Input';
import {useQueryClient} from 'react-query';
import {productsQueryKey} from 'constants/query';
import {WarningMessageIds} from 'constants/error';
import {useWatchObject} from 'lib/react-hook-form/use-watch';

function ValueOfProperty(props) {
  const {allowOnChange = true, ...rest} = props;
  const queryClient = useQueryClient();
  const {ApiRoute} = useWatchObject({name: 'ApiRoute'});

  const onChanged = useCallback(() => {
    if (allowOnChange) {
      queryClient.setQueryData([productsQueryKey], (response) => {
        if (Array.isArray(response?.data)) {
          response.data = response.data.map((product) => {
            if (product.ApiRoute === ApiRoute) {
              const calculationWarnings = product.calculation?.CalculationWarnings?.filter(
                (calculationWarning) =>
                  [WarningMessageIds.HOME_VALUE].indexOf(calculationWarning.WarningMessageId) === -1,
              );

              if (product?.calculation) {
                if (calculationWarnings?.length) product.calculation.CalculationWarnings = calculationWarnings;
                else product.calculation.CalculationWarnings = undefined;
              }
            }

            return product;
          });
        }

        return response;
      });
    }
  }, [ApiRoute, queryClient, allowOnChange]);

  return (
    <Input
      id="BorrowerProfile.ValueOfProperty"
      name="BorrowerProfile.ValueOfProperty"
      type="currency"
      {...rest}
      onChangeCapture={onChanged}
    />
  );
}

export default ValueOfProperty;
