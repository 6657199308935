import React, {useCallback} from 'react';
import {Divider, IconButton, Menu, MenuItem} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Text from 'components/common/typeography/text/Text';
import useStyles from './NonAuthMenuStyles';
import {Trans} from 'lib/i18n';
import {ROUTES} from 'constants/routes';
import {useHistory} from 'react-router-dom';
import {useSmSizeApp} from 'lib/app-theme';

const LinkMenuItem = React.forwardRef(function LinkMenuItemRender(props, ref) {
  const {href, children, handleClose, ...rest} = props;
  const history = useHistory();

  const withRedirect = useCallback(() => {
    if (href.indexOf(window.env.REACT_APP_WP_HOST) > -1 || href.indexOf(window.env.REACT_APP_FARWHOLESALE_HOST) > -1) {
      window.location.href = href;
    }
    else {
      history.push(href);
      handleClose();
    }
  }, [history, handleClose, href]);

  return (
    <MenuItem ref={ref} {...rest} href={href} onClick={withRedirect}>
      {children}
    </MenuItem>
  );
});

function NonAuthMenu(props) {
  const classes = useStyles();
  const isSmSizeApp = useSmSizeApp();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <div>
      <IconButton
        color="primary"
        classes={{label: classes.menuIconLabel}}
        className={classes.menuIcon}
        onClick={handleClick}
        data-testid="menu-icon-button"
      >
        <FontAwesomeIcon icon={['fas', 'bars']} />

        <Text>
          <Trans i18nKey="appHeaderTranslation:menu" />
        </Text>
      </IconButton>

      <Menu
        className={classes.menuWrapper}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isSmSizeApp && [
          <LinkMenuItem
            key="0"
            className={classes.menuItem}
            href={ROUTES.WHY_FAR}
            handleClose={handleClose}
            data-testid="why-far-link"
          >
            <Trans i18nKey="appHeaderTranslation:why far" />
          </LinkMenuItem>,
          <Divider key="1" className={classes.divider} />,
          <LinkMenuItem
            key="2"
            className={classes.menuItem}
            href={ROUTES.PARTNER_TOOLS}
            handleClose={handleClose}
            data-testid="partner-tools-link"
          >
            <Trans i18nKey="appHeaderTranslation:partner tools" />
          </LinkMenuItem>,
          <Divider key="3" className={classes.divider} />,
          <LinkMenuItem
            key="4"
            className={classes.menuItem}
            href={ROUTES.PRODUCT_PORTFOLIO}
            handleClose={handleClose}
            data-testid="product-portfolio-link"
          >
            <Trans i18nKey="appHeaderTranslation:product portfolio" />
          </LinkMenuItem>,
          <Divider key="5" className={classes.divider} />,
        ]}

        <LinkMenuItem
          className={classes.menuItem}
          href={ROUTES.ILLUSTRATOR_ROOT_PAGE}
          handleClose={handleClose}
          data-testid="illustrator-link"
        >
          <Trans i18nKey="appHeaderTranslation:illustrator" />
        </LinkMenuItem>
        <Divider className={classes.divider} />
        <LinkMenuItem
          className={classes.menuItem}
          href={ROUTES.XCELERATE_HOME_PAGE}
          handleClose={handleClose}
          data-testid="xcelerate-link"
        >
          <Trans i18nKey="appHeaderTranslation:xcelerate" />
        </LinkMenuItem>
        <Divider className={classes.divider} />
        <LinkMenuItem
          className={classes.menuItem}
          href={ROUTES.PRODUCTS_AVAILABILITY_PAGE}
          handleClose={handleClose}
          data-testid="product-availability-link"
        >
          <Trans i18nKey="appHeaderTranslation:product availability" />
        </LinkMenuItem>
      </Menu>
    </div>
  );
}

NonAuthMenu.propTypes = {};

export default NonAuthMenu;
