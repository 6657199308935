import React from 'react';
import TextField from 'components/common/form/input/components/TextField';
import {useOnChangeInterceptor} from 'components/common/form/input/components/OnChangeInterceptor';

/**
 *
 * This component acts like a wrapper.
 *
 * It is build over the default form "input" field of type "text" component.
 *
 * The "TextField" component accepts a lot of params.
 *
 * This component is enhancing the "TextField" component.
 *
 * For details, open the "TextField" component.
 *
 */

function SocialSecurityNumber(props = {}) {
  const {onChange, visibility, ...rest} = props;

  const {doChange} = useOnChangeInterceptor({
    onChange,
    getValue: (event) => {
      let value = '';

      if (event.target.value) {
        value = event.target.value.match(/[0-9]/gim)?.join('');
      }

      return value ?? '';
    },
  });

  return <TextField {...rest} type={visibility ? 'text' : 'password'} autoComplete="ssn" onChange={doChange} />;
}

SocialSecurityNumber.propTypes = {};

export default SocialSecurityNumber;
