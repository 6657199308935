import React, {useCallback} from 'react';
import clsx from 'clsx';
import {useProductContext} from 'contexts/product-context';
import {ButtonBase} from '@material-ui/core';
import useStyles from './ProductSelectionImageStyles';

function ProductSelectionImage(props) {
  const {value, onChange, dataTestId} = props;

  const {product: {ProductValue, ProductName, ApiRoute} = {}, productDetails: {img} = {}} = useProductContext();
  const classes = useStyles();
  const onClick = useCallback(
    (event) => {
      event.persist();

      event.target = event.currentTarget;

      return onChange(event);
    },
    [onChange],
  );

  return (
    <ButtonBase
      className={classes.buttonContainer}
      onClick={onClick}
      value={ApiRoute}
      data-testid={dataTestId || `product-selection-image-${ProductValue}`}
    >
      <img className={clsx(classes.imageElement)} src={img} alt={ProductName} />
      <div className={clsx({[classes.selectedElement]: value === ApiRoute})} />
    </ButtonBase>
  );
}

ProductSelectionImage.propTypes = {};

export default ProductSelectionImage;
