import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    labelText: {
      ...theme.typography.body1,
      fontWeight: 600,
    },
    labelTextActive: {
      fontWeight: 700,
    },
  }),
  {index: 1},
);

export default useStyles;
