import React, {useCallback} from 'react';
import ContentContainer from '../content-section/components/ContentContainer';
import {formatCurrency} from 'lib/currency';
import {Trans, useTranslation} from 'lib/i18n';
import {useVisible} from 'hooks/utils';
import {useOwnFormContext} from 'contexts/form-context';

function WowSection(props) {
  const {y, setChartData, setChartTitle, setChartDisclaimer} = props;
  const {CashAtClose} = useOwnFormContext();
  const {t} = useTranslation();

  const onEnterViewport = useCallback(() => {
    setChartData(['homeValueYearEnd', 'year', 'name']);
    setChartTitle(<Trans i18nKey="wow section.chart title" />);
    setChartDisclaimer(t('wow section.chart disclaimer'));
  }, [setChartData, t, setChartDisclaimer, setChartTitle]);

  const {visibleNodeRef} = useVisible({threshold: 0.75, onEnterViewport});

  return (
    <ContentContainer
      ref={visibleNodeRef}
      style={{y}}
      title={t('wow section.title')}
      disclaimer={t('wow section.disclaimer')}
      description={t('wow section.description', {
        CashAtClose: formatCurrency(CashAtClose),
      })}
    />
  );
}

WowSection.propTypes = {};

export default WowSection;
