import React from 'react';
import Item from 'components/common/layout/item/Item';
import Container from 'components/common/layout/container/Container';
import DatePicker from 'components/common/form/date-picker/DatePicker';
import Input from 'components/common/form/input/Input';
import Switch3States from 'components/common/form/switch-3-states/Switch3States';
import clsx from 'clsx';
import {TableCell, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Switch3StatesElementErrors from 'components/common/form/switch-3-states/components/Switch3StatesElementErrors';
import {useTranslation} from 'lib/i18n';
import {useFormContext} from 'react-hook-form';
import useStyles from './CreditAuthorizationQuestionsStyles';

function CreditAuthorizationQuestions(props) {
  const {t} = useTranslation();

  const classes = useStyles();

  const {watch} = useFormContext();
  const authorizationAnswer = watch('authorization');
  const reportAnswer = watch('report');

  return (
    <Container>
      <Table className={classes.table} aria-label="caption table">
        <TableBody>
          <TableRow key="1">
            <TableCell align="center" className={clsx(classes.verticalAlignTop, classes.customSwitchElementContainer)}>
              <Switch3States name="authorization" />
            </TableCell>

            <TableCell align="left">
              <div>
                <Typography variant="subtitle2">
                  {t('illustratorTranslation:illustrator borrower credit authorization.q1')}
                </Typography>
                <Switch3StatesElementErrors name="authorization" />
              </div>

              <Container hide={authorizationAnswer !== 'true'}>
                <Item>
                  <DatePicker name="creditAuthorizationDate" inputVariant="filled" />
                </Item>
              </Container>
            </TableCell>
          </TableRow>

          <TableRow key="2">
            <TableCell align="center" className={clsx(classes.verticalAlignTop, classes.customSwitchElementContainer)}>
              <Switch3States name="report" />
            </TableCell>

            <TableCell align="left">
              <div>
                <Typography variant="subtitle2">
                  {t('illustratorTranslation:illustrator borrower credit authorization.q2')}
                </Typography>

                <Switch3StatesElementErrors name="report" />
              </div>

              <Container hide={reportAnswer !== 'true'}>
                <Item>
                  <Input name="reportReferenceId" variant="filled" className={classes.customInputElement} />
                </Item>
              </Container>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  );
}

CreditAuthorizationQuestions.propTypes = {};

CreditAuthorizationQuestions.defaultValues = {};

export default CreditAuthorizationQuestions;
