import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minHeight: 74,
    },
    toolBar: {
      height: '100%',
      paddingLeft: 0,
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: 'auto 0',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      ...theme.mixins.toolbar,

      '& .show-accessibility': {
        padding: 'unset',
      },

      '& .button-start': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    toolBarBox: {
      height: '100%',
    },
    text2left: {
      textAlign: 'left',
    },
    m3: {
      margin: theme.spacing(3, 0),
    },
    borrower1stLine: {
      color: theme.palette.borrower.dark,
      fontWeight: 'bold',
    },
    borrower2ndLine: {
      color: theme.palette.borrower.dark,
    },
  }),
  {index: 1},
);

export default useStyles;
