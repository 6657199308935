import React from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'lib/i18n';

function AuthErrorPage() {
  const {t} = useTranslation();
  const history = useHistory();
  let error = history.location?.state?.error;

  if (!error) {
    error = {message: t('illustratorTranslation:generic error')};
  }

  return <h2 data-testid="auth-error-page">{error.message}</h2>;
}

export default AuthErrorPage;
