import React, {useMemo} from 'react';
import Form from 'components/common/form/Form';
import {useOwnFormContext} from 'contexts/form-context';
import {useIllustratorForm} from 'hooks/illustrator-form';
import TabPanel from 'components/common/tab-panel/TabPanel';
import Button from 'components/common/button/Button';

function IllustrationForm(props) {
  const {children} = props;
  const {validationSchema} = useOwnFormContext();

  const {onSubmit, defaultValues} = useIllustratorForm();

  const formId = useMemo(() => `illustration-${defaultValues.id}`, [defaultValues.id]);

  return (
    <TabPanel value={defaultValues.id}>
      <Form id={formId} defaultValues={defaultValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {children}

        <Button type="submit" form={formId} style={{display: 'none'}} />
      </Form>
    </TabPanel>
  );
}

export default IllustrationForm;
