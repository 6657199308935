import numeral from 'numeral';
import {ToNumber, ToString} from './utils';

export function formatPercent(str, isDecimalFormat = false, useZeroUndefinedDisplay = false) {
  if (isNaN(str) && !useZeroUndefinedDisplay) {
    return 'N/A';
  } else if (isNaN(str) && useZeroUndefinedDisplay) {
    return `0%`;
  }
  if (!isDecimalFormat) {
    return `${ToNumber(str)}%`;
  } else {
    return `${ToNumber(str) * 100}%`;
  }
}

export function formatPercentage(str) {
  return `${str}%`;
}

export function formatCurrency(str, format = false) {
  if (isNaN(str)) str = '0';
  return `${formatDollar(dollarToNumber(str), typeof format === 'string' ? format : format ? '$0,0' : undefined)}`;
}

function formatDollar(num, format = '$0,0.00') {
  return numeral(num).format(format);
}

export function dollarToNumber(str) {
  return parseFloat(ToString(str).replace(/\$|,/g, ''));
}

export function percentToNumber(str) {
  str.replace('%', '');
  if (str === '') return '';
  return parseFloat(str);
}
