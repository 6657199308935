import React from 'react';
import TextField from 'components/common/form/input/components/TextField';
import {Tooltip} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

/**
 *
 * This component acts like a wrapper.
 *
 * It is build over the default form "input" field of type "text" component.
 *
 * The "TextField" component accepts a lot of params.
 *
 * This component is enhancing the "TextField" component.
 *
 * For details, open the "TextField" component.
 *
 */

function TextFieldWithToolTipIcon(props = {}) {
  const {InputProps, toolTipTitle = '', ...rest} = props;

  return (
    <TextField
      {...rest}
      type="text"
      InputProps={{
        ...InputProps,
        endAdornment: (
          <Tooltip title={toolTipTitle}>
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        ),
      }}
    />
  );
}

TextFieldWithToolTipIcon.propTypes = {};

export default TextFieldWithToolTipIcon;
