import {useTranslation} from 'lib/i18n';
import {useMemo} from 'react';

/**
 *
 * This custom hook loads the "Interview Method" options
 *
 */

export default function useDemographicInformationSourceOptions() {
  const {t} = useTranslation();

  return useMemo(
    () => t('inputsTranslation:borrower info.demographic information source options', {returnObjects: true}),
    [t],
  );
}

export function useDemographicInformationOptions() {
  const options = useDemographicInformationSourceOptions();

  const defaultOption = useMemo(() => options[0].value, [options]);

  return {options, defaultOption};
}
