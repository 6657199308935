import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    imageElement: {
      marginBottom: 'auto',
      padding: theme.spacing(2),
      [isMobileAppQuery(theme)]: {
        maxWidth: theme.spacing(27),
      },
    },
    selectedElement: {
      width: '100%',
      borderBottom: `2px solid ${theme.palette.primary.main} !important`,
    },
    buttonContainer: {
      display: 'block',
      margin: 'auto',
    },
  }),
  {index: 1},
);

export default useStyles;
