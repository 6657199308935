import React from 'react';
import {Collapse as MuiCollapse} from '@material-ui/core';
import Text from 'components/common/typeography/text/Text';
import {isFunction} from 'lodash';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import useStyles from './CollapseStyles';
import {useCollapse} from 'hooks/collapse';

/**
 *
 * The "Collapse" component is a common component, it might be used in several places.
 *
 * Before update/enhancement, check within the entire project where it is used.
 *
 * Make sure to search for "<Collapse" within the entire project.
 *
 */

function Collapse(props = {}) {
  const {className, title, description, endAnchor = (props) => null} = props;
  const classes = useStyles();

  const {open, toggleCollapse} = useCollapse();

  return (
    <Container className={className} onClick={toggleCollapse}>
      <Item xs={12} className={classes.titleElementWithAnchor}>
        <Text>{title}</Text>

        {isFunction(endAnchor) ? endAnchor({open}) : null}
      </Item>

      <MuiCollapse in={open}>
        <Container className={classes.collapseElementContainer}>
          <Item xs={12} className={'collapseElementChild'}>
            <Text>{description}</Text>
          </Item>
        </Container>
      </MuiCollapse>
    </Container>
  );
}

Collapse.propTypes = {};

export default Collapse;
