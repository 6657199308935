import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '50px',
      height: '24px',
      padding: '0px',
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    textLeft: {
      textAlign: 'left',
    },
    switchBase: {
      color: theme.palette.grey.lighter,
      padding: '1px',
      '&$checked': {
        '& + $track': {
          backgroundColor: theme.palette.secondary.lighter,
        },
      },
    },
    switchBaseNull: {
      transform: 'translateX(13px) !important',
    },
    thumb: {
      color: 'white',
      width: '20px',
      height: '20px',
      margin: '1px',
    },
    track: {
      borderRadius: '20px',
      backgroundColor: theme.palette.grey.lighter,
      opacity: '1 !important',
      '&:after, &:before': {
        color: 'white',
        fontSize: '11px',
        position: 'absolute',
        top: '4px',
      },

      '&:after': {
        content: "''",
      },
      '&:before': {
        content: "'No'",
        right: '7px',
      },
    },
    trackActive: {
      '&:after': {
        content: "'Yes'",
        left: '6px',
      },
      '&:before': {
        content: "''",
      },
    },
    trackNull: {
      '&:after': {
        content: "''",
      },
      '&:before': {
        content: "''",
      },
    },
    checked: {
      color: theme.palette.secondary.lighter + ' !important',
      transform: 'translateX(26px) !important',
    },
    marginLeftNone: {
      marginLeft: '0 !important',
    },
    marginRightNone: {
      marginRight: '0 !important',
    },

    formControlLabelElement: {
      marginLeft: '10px !important',
      fontWeight: 600,
      fontSize: '1rem',
      color: theme.palette.primary.dark,
    },
    formHelperText: {
      position: 'absolute',
      width: 500,
      bottom: -25,
    },
  }),
  {index: 1},
);

export default useStyles;
