import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {addOneIllustrationBorrower, removeAllIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import {getAllIllustrations} from 'reducers/illustrator.reducer';
import {useProductsContext} from 'contexts/products-context';
import {productHasErrors, productHasWarnings} from 'lib/product';
import {useProductFeatures} from 'hooks/product-features';

export function useBorrowerIllustrationLabel(props = {}) {
  const allIllustration = useSelector(getAllIllustrations);
  const {products} = useProductsContext();

  const getBorrowerIllustrationLabel = useCallback(
    ({IllustrationName, label, ApiRoute} = {}) => {
      if (IllustrationName) return IllustrationName;
      if (label) return label;
      return products?.find((product) => product.ApiRoute === ApiRoute)?.ProductName;
    },
    [products],
  );

  const availableIllustrations = useMemo(
    () => allIllustration.filter((illustration) => illustration?.id).filter(getBorrowerIllustrationLabel),
    [allIllustration, getBorrowerIllustrationLabel],
  );

  return {getBorrowerIllustrationLabel, availableIllustrations};
}

export function useShareIllustration() {
  const {availableIllustrations = []} = useBorrowerIllustrationLabel();

  const illustrations = useMemo(
    () =>
      availableIllustrations
        .filter((calculation) => !productHasErrors({calculation}))
        .filter((calculation) => !productHasWarnings({calculation})),
    [availableIllustrations],
  );
  return {illustrations};
}

/**
 *
 * This method is used :
 *
 * - within Borrower Link flow, within the "Entry Point" component
 *
 * - check also "useStartApplication"
 *
 */

export function useBorrowerIllustration() {
  const dispatch = useDispatch();

  const addBorrowerIllustration = useCallback(
    async (state) => {
      await dispatch(removeAllIllustratorBorrower());
      await dispatch(addOneIllustrationBorrower(state));
    },
    [dispatch],
  );

  return {addBorrowerIllustration};
}

export function useIllustratorBorrowerNavList() {
  const {addBorrowerIllustration} = useBorrowerIllustration();

  const {isProductFeatureKeyEnabled} = useProductFeatures();

  return {addBorrowerIllustration, isProductFeatureKeyEnabled};
}
