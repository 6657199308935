import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useStyles from './AppHeaderStyles';
import Logo from './components/logo/Logo';
import {Box} from '@material-ui/core';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import {ROUTES} from 'constants/routes';
import {Trans} from 'lib/i18n';
import List from 'components/common/list/List';
import LoginButton from 'components/app-header/components/login-button/LoginButton';
import NonAuthMenu from 'components/app-header/components/non-auth-menu/NonAuthMenu';
import CalculatorButton from 'components/app-header/components/calculator-button/CalculatorButton';
import CallButton from 'components/app-header/components/call-button/CallButton';
import EmailButton from 'components/app-header/components/email-button/EmailButton';
import ExcludedDivider from 'components/common/list/ExcludedDivider';
import logo from 'assets/images/foa-logo.svg';
import PrintPageTitle from 'components/app-header/components/print-page-title/PrintPageTitle';
import {useSmSizeApp} from 'lib/app-theme';

const AppHeaderNonAuth = React.forwardRef(function AppHeaderNonAuthRender(props, ref) {
  const {className} = props;
  const isSmSizeApp = useSmSizeApp();
  const classes = useStyles();

  return (
    <AppBar
      position="sticky"
      color="inherit"
      elevation={1}
      className={clsx(classes.root, className)}
      data-testid="app-header-non-auth"
      ref={ref}
    >
      <Toolbar className={clsx(classes.toolBar, classes.toolBarWithSpace, classes.tooBarDark, classes.hidePrint)}>
        <Box display="flex" flexDirection="row" className={classes.toolBarBox} />
        <Box display="flex" flexDirection="row" className={classes.toolBarBox}>
          <CalculatorButton />
          <CallButton />
          <EmailButton />
          <LoginButton />
        </Box>
      </Toolbar>
      <Toolbar className={clsx(classes.toolBar, classes.toolBarWithSpace)}>
        <Box display="flex" flexDirection="row" className={classes.toolBarBox}>
          <Box display="flex">
            <Logo img={logo} href={ROUTES.XCELERATE_HOME_PAGE} />

            <PrintPageTitle className={classes.illustrationPrintTitle} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" className={clsx(classes.toolBarBox, classes.hidePrint)}>
          {!isSmSizeApp && (
            <List flexDirection="row" justifyContent="flex-center" listItemClassName={classes.nonAuthListItem}>
              <Link href={ROUTES.WHY_FAR} className="MuiTypography-body1" data-testid="why-far-link">
                <Trans i18nKey="appHeaderTranslation:why far" />
              </Link>
              <ExcludedDivider className={classes.divider} orientation="vertical" flexItem />
              <Link
                href={ROUTES.PRODUCT_PORTFOLIO}
                className="MuiTypography-body1"
                data-testid="product-portfolio-link"
              >
                <Trans i18nKey="appHeaderTranslation:product portfolio" />
              </Link>
            </List>
          )}

          <NonAuthMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
});

export default AppHeaderNonAuth;
