import React from 'react';
import {useTranslation} from 'lib/i18n';
import IconButton from 'components/common/button/IconButton';
import {Typography} from '@material-ui/core';

function CallButton(props) {
  const {t} = useTranslation();

  return (
    <IconButton
      colorVariant="white"
      component="a"
      href={t('appHeaderTranslation:tel')}
      icon={['fas', 'phone']}
      data-testid="call-button"
    >
      <Typography variant="subtitle2">{t('appHeaderTranslation:call')}</Typography>
    </IconButton>
  );
}

export default CallButton;
