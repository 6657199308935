import React from 'react';
import {useRouteMatch} from 'react-router-dom';
import EquityAvailPdfHeader from 'components/app-header/EquityAvailPdfHeader';
import AppHeader from 'components/app-header/AppHeader';
import {useAuthenticated} from 'hooks/authenticate';
import {ROUTES} from 'constants/routes';
import AppHeaderNonAuth from 'components/app-header/AppHeaderNonAuth';
import AppHeaderFar from 'components/app-header/AppHeaderFar';
import {usePDFMatch} from 'hooks/print';

const HeaderComp = React.forwardRef(function HeaderCompRender(props, ref) {
  const {isAuthenticated, isLoaded} = useAuthenticated();

  const isProductPDFPrintOutRouteMatch = usePDFMatch();
  const isConsumerFacingMatch = useRouteMatch(ROUTES.REVERSE_EQUITY_AVAIL_PAGE);

  if (isLoaded) {
    if (isProductPDFPrintOutRouteMatch?.isExact) {
      /* set the general custom header for the Product PDF Printout page */
      return <EquityAvailPdfHeader ref={ref} {...props} />;
    } else if (isConsumerFacingMatch?.isExact) {
      /* set the general custom header for the Product PDF Printout page */
      return <AppHeaderFar ref={ref} {...props} />;
    } else if (isAuthenticated) {
      /* set the authenticated page header */
      return <AppHeader ref={ref} {...props} />;
    }
    /* set the default page header */
    return <AppHeaderNonAuth ref={ref} {...props} />;
  }

  return null;
});

export default HeaderComp;
