import {makeStyles} from '@material-ui/core/styles';
import {isTabletAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(5),
      width: '100%',
      flexGrow: 1,
      flexDirection: 'column',
      margin: 'auto',
      '& .MuiTabs-flexContainer': {flexWrap: 'wrap'},
      [isTabletAppQuery(theme)]: {
        paddingLeft: 0,
      },
    },
    titleContainer: {
      margin: 'auto',
    },
    tabContainer: {
      marginTop: theme.spacing(5),
      margin: 'auto',
    },
    loaderContainer: {
      marginTop: theme.spacing(5),
      width: '90%',
    },
  }),
  {index: 1},
);

export default useStyles;
