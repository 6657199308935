import yup from 'lib/validation';
import {dollarToNumber, formatCurrency} from 'lib/currency';
import {API_ROUTES, FUND_ACCESS, SSN_LENGTH, ZIP_CODE_LENGTH} from 'constants/illustrator';
import moment from 'lib/moment';
import {ToFixedNumber} from 'lib/utils';
import {MIN_BORROWER_AGE, MAX_BORROWER_AGE} from 'constants/borrower';
import {PROPERTY_VESTED_IN_A_TRUST} from 'constants/regex';
import {toMomentDateValidation} from 'lib/utils';

/**
 *
 * Custom validations for the illustrator comparison page.
 *
 */

const MIN_VALUE_LENGTH = 1;
const MAX_VALUE_LENGTH = 10;

export function ZipField(options = {}) {
  const {required = true} = options;

  let validation = yup.string();

  if (required) validation = validation.required().min(ZIP_CODE_LENGTH);

  validation = validation.max(ZIP_CODE_LENGTH);

  const pattern = new RegExp(`^\\d{0,${ZIP_CODE_LENGTH}}$`);
  validation = validation.matches(pattern);

  return validation;
}

export function dollarLimitedField(options = {}) {
  const {required = true, withRequired = true, dollarLimitMax = 99999999, dollarLimitMin = 0} = options;

  let validation = yup
    .string()
    .default('')
    .typeError()
    .test('is-currency-value', (value) => (value === '' ? true : !isNaN(dollarToNumber(value))))
    .test({
      name: 'max',
      params: {max: formatCurrency(dollarLimitMax)},
      test: (value) => ToFixedNumber(value, 2, '0') <= dollarLimitMax,
    });

  if (withRequired) {
    if (required) {
      validation = validation.required().test({
        name: 'min',
        params: {min: formatCurrency(dollarLimitMin)},
        test: (value) => ToFixedNumber(value, 2, '0') >= dollarLimitMin,
      });
    } else {
      validation = validation.notRequired().nullable();
    }
  }

  return validation;
}

export function currencyField(options = {}) {
  const {required = true, withRequired = true} = options;

  let validation = yup
    .string()
    .default('')
    .typeError()
    .test('is-currency-value', (value) => (value === '' ? true : !isNaN(dollarToNumber(value))))
    .test({
      name: 'max',
      params: {max: MAX_VALUE_LENGTH},
      test: (value) => ToFixedNumber(value, 2, '0').length <= MAX_VALUE_LENGTH,
    });

  if (withRequired) {
    if (required) {
      validation = validation.required().test({
        name: 'min',
        params: {min: MIN_VALUE_LENGTH},
        test: (value) => ToFixedNumber(value, 2, '0').length >= MIN_VALUE_LENGTH,
      });
    } else {
      validation = validation.notRequired().nullable();
    }
  }

  return validation;
}

export function getFundAccessValidation(props = {}) {
  const {values = Object.values(FUND_ACCESS)} = props;

  return yup.string().oneOf(values);
}

export function getAdvancedOptionsFieldDefaultValidation(props = {}) {
  const {ApiRoute, ...rest} = props;

  return yup.array().when('ApiRoute', {
    is: ApiRoute,
    then: (schema) =>
      schema.of(
        yup.object({
          year: yup.string(),
          Age: yup.number(),
          homeValueYearEnd: yup.number(),
          locYearEnd: yup.number(),
          homeAppreciation: yup.number(),
          annualPayment: yup.number(),
          monthlyPayment: yup.number(),
          rateAdjustments: yup.number(),
          loanBalanceValueYearEnd: yup.number(),
          netEquityValue: yup.number(),
          existingMortgageEndBalance: yup.number(),
          ...rest,
        }),
      ),
    otherwise: (schema) => schema.strip(),
  });
}

/**
 *
 * The "Borrower Profile" component.
 *
 * The attributes are part of the "borrower profile" data.
 *
 * The fields :
 *
 *  - FirstName
 *  - LastName
 *  - DateOfBirth ( the value is received from the API )
 *
 *  can be found on the UI within the "slide out modal", within the "Borrower Information" section.
 *
 *  The "Email" field is just part of the "Borrower Profile".
 *
 */

export function BorrowerProfile({
  DateOfBirthValidation = DateOfBirth({AgeMin: MIN_BORROWER_AGE, AgeMax: MAX_BORROWER_AGE}),
} = {}) {
  return {
    FirstName: yup.string().notRequired(),
    LastName: yup.string().notRequired(),
    Email: yup.string().email(),
    DateOfBirth: DateOfBirthValidation,
  };
}

export function BorrowerProfileValidation(options = {}) {
  const {BorrowerProfile: BP} = options;

  return yup.object({
    BorrowerProfile: yup.object({
      ...BorrowerProfile(options),
      ...BP,
    }),
    BorrowerProfileId: yup.string().nullable(),
  });
}

/**
 *
 * The "Borrower Profile Property" component.
 *
 * The attributes are part of the "borrower profile" data.
 *
 * The fields :
 *
 *  - Address
 *  - Zip
 *  - ValueOfProperty
 *  - Liens ( a.k.a. "Mortgage Balance" )
 *
 *  can be found on the UI within the "slide out modal", within the "Property Information" section.
 *
 */

export function BorrowerProfileProperty({required} = {}) {
  return {
    Address: yup.string().notRequired(),
    Zip: ZipField({required}),
    ValueOfProperty: dollarLimitedField({required, dollarLimitMax: 99999999}),
    Liens: yup.string().when('IsThere2nd', {
      is: 'yes',
      then: (s) => s.nullable().transform(() => null),
      otherwise: () => dollarLimitedField({required, dollarLimitMax: 99999999}),
    }),
    FirstLien: yup.mixed().when('IsThere2nd', {
      is: 'yes',
      then: () => dollarLimitedField({required, dollarLimitMax: 99999999}),
      otherwise: (s) => s.nullable().transform(() => null),
    }),
    OtherLiens: yup.string().when('IsThere2nd', {
      is: 'yes',
      then: () => dollarLimitedField({required, dollarLimitMax: 99999999}),
      otherwise: (s) => s.nullable().transform(() => null),
    }),
    IsThere2nd: yup.string().required(),
  };
}

/**
 *
 * The "Borrower Profile Jumbo Rate" component.
 *
 * The attributes are part of the "borrower profile" data.
 *
 */

export function BorrowerProfileJumboRate() {
  return {
    Address: yup.string().notRequired(),
    NumberOfUnits: yup.string().notRequired(),
  };
}

export function BorrowerProfilePropertyValidation(options = {}) {
  const {required, BorrowerProfile, ...rest} = options;

  return yup.object({
    BorrowerProfile: yup.object({...BorrowerProfileProperty({required}), ...BorrowerProfile}),
    BorrowerProfileId: yup.string().nullable(),
    ...rest,
  });
}

/**
 *
 * The "Borrower Validation" component.
 *
 * It is used as the "Borrower Details" validation schema.
 *
 *
 * For the "defaultState", the "options" look like :
 *
 * {
 *   DateOfBirthValidation
 * }
 *
 *
 * For the "extraArguments", the "options" look like :
 *
 * {
 *   BorrowerProfile
 *   DateOfBirthValidation
 * }
 *
 * The returned yup.object looks like :
 *
 * ObjectSchema {
 *
 *  ...
 *
 *  fields : {
 *
 *    BorrowerProfile : ObjectSchema {
 *
 *      ...
 *
 *      fields : {
 *
 *        Address
 *        DateOfBirth
 *        Email
 *        FirstName
 *        LastName
 *        Liens
 *        NumberOfUnits
 *        ValueOfProperty
 *        Zip
 *
 *      }
 *
 *      ...
 *
 *    }
 *
 *    BorrowerProfileId
 *
 *  }
 *
 *  ...
 *
 * }
 *
 */

export function BorrowerValidation(options = {}) {
  const {required, DateOfBirthValidation, BorrowerProfile: BP, BorrowerProfileHomeSafeSecond: BPHSS, ...rest} = options;

  return yup.object({
    BorrowerProfile: yup.object({
      ...BorrowerProfile({DateOfBirthValidation}),
      ...BorrowerProfileProperty({required}),
      ...BP,
      ...BPHSS, // BP HomeSafe Second
    }),
    BorrowerProfileId: yup.string().nullable(),
    ...rest,
  });
}

export function DateOfBirth(options = {}) {
  const MinDateOfBirth = toMomentDateValidation(MAX_BORROWER_AGE);
  const MaxDateOfBirth = toMomentDateValidation(MIN_BORROWER_AGE);

  const minDateOfBirthString = MinDateOfBirth.format('MM/DD/YYYY');
  const maxDateOfBirthString = MaxDateOfBirth.format('MM/DD/YYYY');

  return yup
    .date()
    .required()
    .typeError()
    .test({
      name: 'min',
      params: {min: minDateOfBirthString},
      test: (value) => {
        return moment(value).isSameOrAfter(MinDateOfBirth);
      },
    })
    .test({
      name: 'max',
      params: {max: maxDateOfBirthString},
      test: (value) => {
        return moment(value).isSameOrBefore(MaxDateOfBirth);
      },
    });
}

export function socialSecurityNumber() {
  return yup
    .string()
    .matches(/^[0-9]{9}$/)
    .min(SSN_LENGTH)
    .max(SSN_LENGTH)
    .required();
}

export function propertyVestedInATrust() {
  return yup.string().matches(PROPERTY_VESTED_IN_A_TRUST);
}

export function FirstLineValidation(props) {
  const {ApiRoute, key, params} = props;
  return yup
    .mixed()
    .nullable()
    .when('ApiRoute', {
      is: () => {
        return ApiRoute === API_ROUTES.SECOND;
      },
      then: (s) => s.higherThanZero().maxValue({key, params}),
      otherwise: (s) => s.transform(() => null),
    });
}
