import React, {useEffect} from 'react';
import Input from 'components/common/form/input/Input';
import Item from 'components/common/layout/item/Item';
import Text from 'components/common/typeography/text/Text';
import {Trans, useTranslation} from 'lib/i18n';
import {useFormContext} from 'react-hook-form';

function MortgageRates(props = {}) {
  const {mdCol = 4} = props;
  const {t} = useTranslation();

  const {watch, setValue} = useFormContext();

  const [isThere2nd, firstLien, liens, otherLiens] = watch([
    'BorrowerProfile.IsThere2nd',
    'BorrowerProfile.FirstLien',
    'BorrowerProfile.Liens',
    'BorrowerProfile.OtherLiens',
  ]);

  const onChangeIsThere2nd = useEffect(() => {
    if (isThere2nd === 'yes' && firstLien === null && liens !== null) {
      setValue('BorrowerProfile.FirstLien', liens);
    }
    if (isThere2nd === 'no' && firstLien !== null && otherLiens !== null) {
      setValue('BorrowerProfile.Liens', firstLien);
      setValue('BorrowerProfile.FirstLien', firstLien);
      setValue('BorrowerProfile.OtherLiens', null);
    }
  }, [isThere2nd, firstLien, liens, otherLiens, setValue]);

  return (
    <>
      <Item xs={12} md={mdCol}>
        {isThere2nd === 'yes' ? (
          <Input
            key="BorrowerProfile.FirstLien"
            id="BorrowerProfile.FirstLien"
            name="BorrowerProfile.FirstLien"
            label={t('inputsTranslation:Liens.label')}
            required
            type="currency"
          />
        ) : (
          <Input
            key="BorrowerProfile.Liens"
            id="BorrowerProfile.Liens"
            name="BorrowerProfile.Liens"
            label={t('inputsTranslation:Liens.label')}
            required
            type="currency"
          />
        )}
      </Item>
      <Item xs={12} md={mdCol}>
        <Text colorVariant="grey" align="left">
          <Trans i18nKey="inputsTranslation:propertyMortgageIsThereA2nd.label" />
        </Text>
        <Input
          id="BorrowerProfile.IsThere2nd"
          type="radio-group-with-icons"
          name="BorrowerProfile.IsThere2nd"
          defaultValue="no"
          onChange={onChangeIsThere2nd}
          required
          row
          options={[
            {
              label: 'Yes',
              value: 'yes',
            },
            {
              label: 'No',
              value: 'no',
            },
          ]}
        />
      </Item>
      {isThere2nd === 'yes' && (
        <Item xs={12} md={mdCol}>
          <Input
            id="BorrowerProfile.OtherLiens"
            name="BorrowerProfile.OtherLiens"
            label={t('inputsTranslation:OtherLiens.label')}
            required
            type="currency"
          />
        </Item>
      )}
    </>
  );
}

export default MortgageRates;
