import {useQuery} from 'react-query';
import CalculateServiceApi from 'api/calculate-service-api';
import {paymentTypesQueryKey} from 'constants/query';
import {prefetchQuery} from 'lib/query';

const paymentTypesQueryOptions = {
  queryKey: [paymentTypesQueryKey],
  queryFn() {
    return CalculateServiceApi.getPaymentTypes();
  },
};

export function prefetchPaymentTypeQuery() {
  return prefetchQuery(paymentTypesQueryOptions);
}

export function usePaymentTypesQuery(props) {
  return useQuery(paymentTypesQueryOptions);
}
