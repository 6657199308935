import {axios} from 'api/api';

export default class CalculationsComparisonManager {
  static baseUrl = '/CalculationService';

  static annual = (data, config) => {
    return axios.post(`${this.baseUrl}/hecm`, data, config);
  };

  static fixed = (data, config) => {
    return axios.post(`${this.baseUrl}/Fixed`, data, config);
  };

  static hybrid = (data, config) => {
    return axios.post(`${this.baseUrl}/Hybrid`, data, config);
  };

  static select = (data, config) => {
    return axios.post(`${this.baseUrl}/Select`, data, config);
  };

  static tier = (data, config) => {
    return axios.post(`${this.baseUrl}/Tier`, data, config);
  };

  static forwardrefi = (data, config) => {
    return axios.post(`${this.baseUrl}/ForwardRefi`, data, config);
  };

  static flex = (data, config) => {
    return axios.post(`${this.baseUrl}/Flex`, data, config);
  };

  static second = (data, config) => {
    return axios.post(`${this.baseUrl}/Second`, data, config);
  };
}
