import React from 'react';
import InRangeSelect from 'components/common/form/select/InRangeSelect';
import {useProductFundAccess} from 'hooks/product-fund-access';

FundAccessInput.propTypes = {};

function FundAccessInput(props) {
  const {ApiRoute, ...rest} = props;
  const productFundAccess = useProductFundAccess(ApiRoute);

  return <InRangeSelect {...rest} name="FundAccess" options={productFundAccess} />;
}

export default FundAccessInput;
