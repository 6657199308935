import React from 'react';
import {useInputError} from 'hooks/input';
import {FormHelperText} from '@mui/material';

import useStyles from './Switch3StatesElementErrorsStyles';
import clsx from 'clsx';

/**
 *
 * This type of error is used on the "Declarations" page.
 *
 * It is a specific error.
 *
 */

function Switch3StatesElementErrors(props) {
  const {name, align} = props;
  
  const {showError, errorMessage} = useInputError({name});

  const classes = useStyles({align});

  return (
    <FormHelperText
      classes={{
        root: clsx(classes.root),
      }}
      error={showError}
      align='center'
    >
      {errorMessage}
    </FormHelperText>
  );
}

export default Switch3StatesElementErrors;
