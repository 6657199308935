import React from 'react';
import {ComposableMap, Geographies} from 'react-simple-maps';

import MapChartGeography from './MapChartGeography';
import useStyles from './MapChartStyles';

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

function MapChart(props) {
  const classes = useStyles();

  return (
    <ComposableMap className={classes.mapChart} data-tip="" projection="geoAlbersUsa">
      <Geographies geography={geoUrl}>
        {({geographies}) => <MapChartGeography geographies={geographies} {...props} />}
      </Geographies>
    </ComposableMap>
  );
}

export default MapChart;
