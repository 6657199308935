import React from 'react';
import Item from 'components/common/layout/item/Item';
import NumberOfUnits from 'components/common/inputs/NumberOfUnits';
import Container from 'components/common/layout/container/Container';
import Input from 'components/common/form/input/Input';
import PaymentTypesList from 'components/payment-types/PaymentTypesList';
import clsx from 'clsx';
import {usePaymentTypesQuery} from 'query/payment-types';
/**
 *
 * The "Short to Close Form Inputs" component.
 *
 * This component returns the extra-fields, but it only gets rendered, if the flag "eligible" is set as true.
 *
 */

function ShortToCloseFormInputs(props) {
  const {xs = 12, md = 4, className, multipleClassName, hideNumberOfUnits = false, hideAddressField = false} = props;
  const {data: paymentTypes} = usePaymentTypesQuery();

  return (
    <>
      <Item xs={xs} md={md} className={className} hide={hideAddressField}>
        <Input name="BorrowerProfile.Address" />
      </Item>

      <Item xs={xs} md={md} className={className}>
        <Input type="integer" name="BorrowerProfile.ExistingMortgage.YearsRemaining" />
      </Item>

      <Item xs={xs} md={md} className={clsx(className, multipleClassName)}>
        <Container spacing={0}>
          <Item xs={9} lg={8} spacing={0}>
            <Input name="BorrowerProfile.ExistingMortgage.HomeownersInsurancePremium" type="currency" />
          </Item>
          <Item xs={3} lg={4} spacing={0}>
            <PaymentTypesList
              defaultValue=""
              enableOptionPrompt
              paymentTypes={paymentTypes}
              controlName="BorrowerProfile.ExistingMortgage.InsurancePremiumPaymentTypeId"
            />
          </Item>
        </Container>
      </Item>

      <Item xs={xs} md={md} className={clsx(className, multipleClassName)}>
        <Container spacing={0}>
          <Item xs={9} lg={8} spacing={0}>
            <Input name="BorrowerProfile.ExistingMortgage.PropertyTaxPayment" type="currency" />
          </Item>
          <Item xs={3} lg={4} spacing={0}>
            <PaymentTypesList
              defaultValue=""
              enableOptionPrompt
              paymentTypes={paymentTypes}
              controlName="BorrowerProfile.ExistingMortgage.PropertyTaxPaymentTypeId"
            />
          </Item>
        </Container>
      </Item>

      <Item xs={xs} md={md} className={className} hide={hideNumberOfUnits}>
        <NumberOfUnits />
      </Item>

      <Input type="hidden" name="scenario" />
    </>
  );
}

export default ShortToCloseFormInputs;
