import {library} from '@fortawesome/fontawesome-svg-core';
import {faEdit, faUser} from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faArrowLeft,
  faBars,
  faBookOpen,
  faCalculator,
  faChalkboardTeacher,
  faChartBar,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCubes,
  faCut,
  faEllipsisV,
  faEnvelope,
  faExclamationTriangle,
  faExpand,
  faEye,
  faEyeSlash,
  faGripHorizontal,
  faHeadset,
  faHome,
  faMoneyBill,
  faPhone,
  faPlaneDeparture,
  faPlayCircle,
  faPlus,
  faPrint,
  faQuestionCircle,
  faRedo,
  faSave,
  faSearch,
  faShareAlt,
  faSync,
  faTh,
  faThList,
  faTimes,
  faTimesCircle,
  faUsers,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faRedo,
  faUser,
  faWindowClose,
  faHome,
  faChartBar,
  faChalkboardTeacher,
  faBookOpen,
  faCalculator,
  faGripHorizontal,
  faCubes,
  faBars,
  faEdit,
  faPlus,
  faHeadset,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faUsers,
  faPhone,
  faPlaneDeparture,
  faMoneyBill,
  faCut,
  faEnvelope,
  faPrint,
  faAngleUp,
  faAngleDown,
  faShareAlt,
  faSync,
  faExclamationTriangle,
  faSave,
  faSearch,
  faAngleLeft,
  faArrowLeft,
  faTimes,
  faThList,
  faTh,
  faTimesCircle,
  faEye,
  faEyeSlash,
  faEllipsisV,
  faExpand,
  faPlayCircle,
  faQuestionCircle,
);
