import React, { useEffect } from 'react';

function InactivityControl() {
    useEffect(() => {
      window.addEventListener("mouseover", sendUserAction.bind(this), {
        capture: true,
        passive: true,
      });  

      window.addEventListener("keydown", sendUserAction.bind(this), {
        capture: true,
        passive: true,
      });  

      window.addEventListener("scroll", sendUserAction.bind(this), {
        capture: true,
        passive: true,
      });  

      window.addEventListener("click", sendUserAction.bind(this), {
        capture: true,
        passive: true,
      });  
    });
  
    function sendUserAction() {
      if (window.top !== window.self) {
        window.top.postMessage('resetInactivityTimer', '*');
      }
    }
  
    return null;
  }

  export default InactivityControl;