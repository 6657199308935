import {makeStyles} from '@material-ui/core/styles';
import {isSmSizeAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    becomePartnerMenu: {
      display: theme.forPrint('none'),
    },
    container: {
      padding: `0.875rem ${theme.spacing(10)}px !important`,
      [isSmSizeAppQuery(theme)]: {
        padding: '0.875rem !important',
      },
    },
    listItem: {
      padding: 0,
      width: 'auto',
    },
    itemElement: {
      textDecoration: 'none',

      color: theme.palette.primary.dark,
      fontSize: theme.spacing(1.75),
      padding: '1rem',
      fontWeight: 500,
    },
  }),
  {index: 1},
);

export default useStyles;
