import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    recommendedTabPanel: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '100%',
    },
    recommendedTabs: {},
  }),
  {index: 1},
);

export default useStyles;
