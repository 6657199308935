import {makeStyles} from '@material-ui/core/styles';
import {fontWeightMedium} from 'lib/mui-config';

const useStyles = makeStyles(
  (theme) => ({
    customCheckBox: {
      padding: theme.spacing(1),
    },
    customCheckboxLabel: {},
    item: {
      '& $customCheckboxLabel': {
        fontSize: theme.spacing(1.75),
        color: theme.palette.primary.dark,
        fontWeight: fontWeightMedium,
      },
    },
  }),
  {index: 1},
);

export default useStyles;
