import {makeStyles} from '@material-ui/core/styles';
import {isSmSizeAppQuery, isTabletAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    slideOutTitleElement: {
      margin: theme.spacing(1, 2),
    },
    slideOutHeaderContainer: {
      margin: theme.spacing(1, 1, 0, 1),
    },
    slideOutHeaderChildrenContainer: {
      textAlign: 'left !important',
    },
    dialogHeaderBoxButtonsClassName: {
      margin: 'auto !important',
    },
    dialog: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.white.light,
      fontWeight: 'bold',
      width: '50%',
      height: '100% !important',
      maxHeight: '100% !important',
      margin: 0,
      maxWidth: '40%',
      minWidth: '40%',
      [isTabletAppQuery(theme)]: {
        width: '50%',
        maxWidth: '50%',
        minWidth: '50%',
      },
      [isSmSizeAppQuery(theme)]: {
        width: '100%',
        maxWidth: '100%',
        minWidth: '100%',
      },
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    m1313: {
      margin: theme.spacing(1, 3),
    },

    closeButtonIcon: {
      right: 0,
    },
  }),
  {index: 1},
);

export default useStyles;
