import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    customToolTipContainer: {
      position: 'relative',
      margin: theme.spacing('auto', 'auto', 'auto', 0.5),
      display: 'inline-flex',
      verticalAlign: 'middle',
    },
    customToolTip: {
      //position: 'absolute',
      //left: theme.spacing(0.5),
      width: '15px',
      height: '15px',
    },
  }),
  {index: 1},
);

export default useStyles;
