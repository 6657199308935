import React, {useMemo} from 'react';
import {ProductContextProvider} from 'contexts/product-context';
import {useSecondHook} from 'components/products/second/SecondHook';
import {useSecondOverallResults} from 'components/products/second/SecondOverallResults';
import {API_ROUTES} from 'constants/illustrator';
import {useProductWithRef} from 'hooks/product-ref';
import {useCalculateSecondQuery} from 'query/products/home-safe/second';

/**
 *
 * The "Second" product, aka "HomeSafe Second", part of the "HomeSafe" products.
 *
 */

const Second = React.forwardRef(function SecondRender(props, ref) {
  const {product, setRef, ...rest} = props;

  const secondHook = useSecondHook({product});
  const secondQuery = useCalculateSecondQuery({product});
  const secondOverallResults = useSecondOverallResults({product});

  const productResults = useMemo(() => ({...secondOverallResults, ...secondHook}), [secondHook, secondOverallResults]);

  useProductWithRef({
    productDetails: secondHook,
    productResults: secondOverallResults,
    productQuery: secondQuery,
    ref,
    ApiRoute: API_ROUTES.SECOND,
  });

  return (
    <ProductContextProvider
      productDetails={secondHook}
      productResults={productResults}
      productQuery={secondQuery}
      product={product}
      {...rest}
    />
  );
});

export default Second;
