import {useFormContext} from 'react-hook-form';
import {useMemo} from 'react';
import {useDeepMemo} from 'hooks/utils';
import {useWatchObject} from 'lib/react-hook-form/use-watch';
import {useYupResolver} from 'lib/react-hook-form/use-yup-resolver';

export function useFormErrors(props = {}) {
  const {prefix = '', validationSchema} = props;

  const fieldName = useMemo(() => `${prefix}WishNotToProvide`, [prefix]);

  const {
    formState: {isSubmitted, touchedFields},
  } = useFormContext();

  const keysToWatch = useMemo(() => validationSchema?._nodes ?? [], [validationSchema]);
  const dataToWatch = useWatchObject({name: keysToWatch});

  const {resolver} = useYupResolver({validationSchema, abortEarly: false});

  const {errors} = useDeepMemo(() => {
    return resolver(dataToWatch);
  }, [dataToWatch]);

  const error = useDeepMemo(() => errors[fieldName], [errors, fieldName]);
  const isVisible = useDeepMemo(() => {
    return error?.type && (isSubmitted || Object.keys(touchedFields).find((key) => key.match(prefix)));
  }, [error?.type, isSubmitted, touchedFields, fieldName]);

  return {type: error?.type, isVisible};
}
