import React, {useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';
import {withProduct} from 'components/products/product-hoc';
import BorrowerDetailsComp from './components/BorrowerDetailsComp';
import {trackEvent} from '../../../../services/analytics.service';
import {SCENARIO_CATEGORIES, SCENARIO_ACTIONS} from '../../../../constants/analytic-events';

const BorrowerDetailsProduct = withProduct(BorrowerDetailsComp);

/**
 *
 * The "Borrower Details" main component.
 *
 */

function BorrowerDetails(props) {
  useEffect(() => {
    trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_INITIAL);
  }, []);

  const illustration = useSelector(getSelectedIllustration);

  const isSelectedProduct = useCallback(
    (product) => product.ApiRoute === illustration.ApiRoute,
    [illustration.ApiRoute],
  );

  return <BorrowerDetailsProduct {...props} isSelectedProduct={isSelectedProduct} />;
}

export default React.memo(BorrowerDetails);
