import React from 'react';
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@material-ui/core';
import {useOnChangeInterceptor} from 'components/common/form/input/components/OnChangeInterceptor';

const CustomRadioGroup = React.forwardRef(function CustomRadioGroupRender(props, ref) {
  const {
    id,
    name,
    label,
    onChange,
    options = [],
    fullWidth,
    className,
    row,
    radioGroupClassName,
    InputProps,
    InputLabelProps,
    FormHelperTextProps,
    helperText,
    error,
    ...rest
  } = props;

  const {doChange} = useOnChangeInterceptor({onChange, getValue: (event, value) => value ?? false});

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      {label && <FormLabel id={id}>{label}</FormLabel>}
      <RadioGroup
        aria-labelledby={id}
        name={name}
        onChange={doChange}
        row={row}
        className={radioGroupClassName}
        {...rest}
      >
        {React.isValidElement(options?.[0])
          ? React.Children.map(options, (child) => React.cloneElement(child, props))
          : options.map((option) => (
              <FormControlLabel
                defaultChecked={option.defaultChecked}
                key={option.key ?? option.value}
                value={option.value}
                control={option.control ?? <Radio color="primary" />}
                label={option.label}
              />
            ))}
      </RadioGroup>
    </FormControl>
  );
});

export default CustomRadioGroup;
