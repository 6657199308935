import React from 'react';
import TextField from 'components/common/form/input/components/TextField';
import IconButton from 'components/common/button/IconButton';

function EmailWithDeleteIcon(props = {}) {
  const {InputProps, ...rest} = props;

  return (
    <TextField
      {...rest}
      type={'text'}
      InputProps={{
        ...InputProps,
        endAdornment: <IconButton icon={['fas', 'times-circle']} />,
      }}
    />
  );
}

EmailWithDeleteIcon.propTypes = {};

export default EmailWithDeleteIcon;
