import React, {useMemo} from 'react';
import {Box} from '@material-ui/core';
import Paper from 'components/common/paper/Paper';
import Item from 'components/common/layout/item/Item';
import Title from 'components/common/typeography/title/Title';
import {Trans, useTranslation} from 'lib/i18n';
import clsx from 'clsx';
import useStyles from './MoreAboutProductStyles';
import {TabContext} from '@material-ui/lab';
import Tabs from 'components/common/Tabs/Tabs';
import TabPanel from 'components/common/tab-panel/TabPanel';
import {useDefaultViewType} from 'hooks/view-type';
import IllustrationTab from '../illustration/components/illustrator-tabs/components/illustration-tab/IllustrationTab';

import Details from './components/details/Details';
import Faq from './components/faq/Faq';
import Scenario from './components/scenario/Scenario';
import Container from 'components/common/layout/container/Container';
import {isLoaded} from 'adapter/loading.adapter';
import {useProductContext} from 'contexts/product-context';
import {withProduct} from 'components/products/product-hoc';
import {useSelector} from 'react-redux';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';

/**
 *
 * "More about PRODUCT", a "Illustrator V2" component
 *
 *
 * ROUTE : /illustrator
 *
 * "More about PRODUCT" section, where PRODUCT might be "EquityAvail"
 *
 *
 * "Details" option selected :
 *
 *********************************************************
 * --> Details <--  * FAQ's                              *
 *********************************************************
 * Why use PRODUCT? * Benefits          * Considerations *
 *********************************************************
 * text goes here   * Improve Cash Flow * A              *
 *                  * Still Have Equity * g              *
 *                  * Lump SUm Payout   * e              *
 *********************************************************
 *
 *
 * "FAQ's" option selected :
 *
 *********************************************************
 * Details          * --> FAQ's <--                      *
 *********************************************************
 * Is there a minimum loan amount ?                      *
 * What does the home ownership status have to be ?      *
 * When does a borrower get loan proceeds ?              *
 * What happens after the 10 year period ?               *
 *********************************************************
 *
 */

function MoreAboutProductComp(props) {
  const classes = useStyles();
  const {t} = useTranslation();

  const {product: {ProductName = '', ApiRoute} = {}, loading} = useProductContext();

  const currentProductTabs = useMemo(() => t(`translation:calc.${ApiRoute}`, {returnObjects: true}), [t, ApiRoute]);
  const tabs = useMemo(
    () => Object.keys(currentProductTabs).filter((tab) => currentProductTabs[tab]),
    [currentProductTabs],
  );

  const defaultValue = useMemo(() => tabs?.[0] || 'details', [tabs]);
  const {viewType, onTabChange} = useDefaultViewType({defaultValue});

  const currentTabsExtra = useMemo(
    () => t(`illustratorTranslation:more about product.tabs.${viewType}`, {returnObjects: true}),
    [t, viewType],
  );

  if (!currentProductTabs) return null;

  return (
    <Box component={Paper} className={clsx({[classes.hide]: !isLoaded({loading})})}>
      <Container>
        <Item xs={0} md={1} xl={2} />
        <Item xs={12} md={10} xl={8}>
          <Title variant="h4" className={clsx(classes.moreAboutProductTitleElement, 'strong')}>
            <Trans i18nKey="illustratorTranslation:more about product.title" values={{ProductName}} />
          </Title>
        </Item>
        <Item xs={0} md={1} xl={2} />

        <Item xs={0} md={1} xl={2} />
        <Item xs={12} md={10} xl={8}>
          <TabContext value={viewType}>
            <Tabs value={viewType} onChange={onTabChange} indicatorColor="primary">
              {tabs.map((tab) => (
                <IllustrationTab
                  classes={{root: classes.moreAboutProductTabElement}}
                  key={tab}
                  value={tab}
                  title={t(`illustratorTranslation:more about product.tabs.${tab}.name`)}
                  label={t(`illustratorTranslation:more about product.tabs.${tab}.name`)}
                />
              ))}
            </Tabs>

            <TabPanel value="details">
              <Details tab={currentProductTabs[viewType]} {...currentTabsExtra} />
            </TabPanel>
            <TabPanel value="faqs">
              <Faq tab={currentProductTabs[viewType]} {...currentTabsExtra} />
            </TabPanel>
            <TabPanel value="scenario">
              <Scenario tab={currentProductTabs[viewType]} {...currentTabsExtra} />
            </TabPanel>
          </TabContext>
        </Item>
        <Item xs={0} md={1} xl={2} />
      </Container>
    </Box>
  );
}

MoreAboutProductComp.propTypes = {};

const MoreAboutProductIllustration = withProduct(MoreAboutProductComp);

const MoreAboutProduct = React.memo((props) => {
  const illustration = useSelector(getSelectedIllustration);

  return (
    <MoreAboutProductIllustration
      {...props}
      isSelectedProduct={(product) => product.ApiRoute === illustration.ApiRoute}
    />
  );
});
export default MoreAboutProduct;
