import {useProductContext} from 'contexts/product-context';
import {API_ROUTES} from 'constants/illustrator';
import yup from 'lib/validation';
import {BORROWER_PREFIX} from 'constants/borrower';
import {ZIP_CODE} from 'constants/regex';

function YupValidationSchemaGovernmentRegulation1009(props = {}) {
  const {prefix = BORROWER_PREFIX} = props;

  const {product} = useProductContext();

  if (product?.ApiRoute !== API_ROUTES.SECOND) {
    return {};
  }

  return {
    /** outstanding judgement */

    [`${prefix}OutstandingJudgement`]: yup.string(),

    /** declared bankruptcy */

    [`${prefix}DeclaredBankruptcy`]: yup.string(),

    /** lawsuit party */

    [`${prefix}LawsuitParty`]: yup.string(),

    /**
     *
     * - loan delinquency
     *    - date
     *    - lender name
     *    - lender address
     *    - FHA case number
     *    - reason
     *
     */

    // main switch question field
    [`${prefix}LoanDelinquency`]: yup.string(),
    // sub-options fields
    [`${prefix}LoanDelinquencyDate`]: yup.string().when(`${prefix}LoanDelinquency`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    [`${prefix}LoanDelinquencyLenderName`]: yup.string().when(`${prefix}LoanDelinquency`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    [`${prefix}LoanDelinquencyLenderAddress`]: yup.string().when(`${prefix}LoanDelinquency`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    [`${prefix}LoanDelinquencyFHACaseNumber`]: yup.string(),
    [`${prefix}LoanDelinquencyReason`]: yup.string().when(`${prefix}LoanDelinquency`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),

    /** property occupation */

    [`${prefix}PropertyOccupation`]: yup.string().required(),

    /** note endorser */

    [`${prefix}NoteEndorser`]: yup.string(),

    /** U.S. citizen */

    [`${prefix}UsCitizen`]: yup.string(),

    /** resident alien */

    [`${prefix}ResidentAlien`]: yup.string(),

    /**
     *
     * - down payment required
     * - down payment borrowed
     *
     */

    [`${prefix}DownPaymentRequired`]: yup.string(),
    [`${prefix}DownPaymentBorrowed`]: yup.string(),

    /**
     *
     * - reverse mortgage investment
     *    - product name
     *    - product cost
     *
     */

    [`${prefix}ReverseMortgageInvestment`]: yup.string(),
    [`${prefix}ReverseMortgageInvestmentProductName`]: yup.string().when(`${prefix}ReverseMortgageInvestment`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    [`${prefix}ReverseMortgageInvestmentProductCost`]: yup.string().when(`${prefix}ReverseMortgageInvestment`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),

    /**
     *
     *  - has existing FHA insurance loan
     *    - existing FHA insured loan
     *      - creditor name
     *      - street
     *      - city
     *      - state
     *      - zip code
     *      - account number
     *      - disposition type
     *      - mortgage amount
     *      - loan balance
     *
     */

    // main switch question field
    [`${prefix}HasExistingFHAInsuredLoan`]: yup.string(),
    // sub-options fields
    [`${prefix}ExistingFHAInsuredLoanCreditorName`]: yup.string().when(`${prefix}HasExistingFHAInsuredLoan`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    [`${prefix}ExistingFHAInsuredLoanStreet`]: yup.string().when(`${prefix}HasExistingFHAInsuredLoan`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    [`${prefix}ExistingFHAInsuredLoanCity`]: yup.string().when(`${prefix}HasExistingFHAInsuredLoan`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    [`${prefix}ExistingFHAInsuredLoanState`]: yup.string().when(`${prefix}HasExistingFHAInsuredLoan`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    [`${prefix}ExistingFHAInsuredLoanZipCode`]: yup.string().when(`${prefix}HasExistingFHAInsuredLoan`, {
      is: 'true',
      then: (s) => s.matches(ZIP_CODE).min(0).max(9).required(),
      otherwise: (s) => s.strip(),
    }),
    [`${prefix}ExistingFHAInsuredLoanAccountNumber`]: yup.string().when(`${prefix}HasExistingFHAInsuredLoan`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    [`${prefix}ExistingFHAInsuredLoanDispositionType`]: yup.string().when(`${prefix}HasExistingFHAInsuredLoan`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    [`${prefix}ExistingFHAInsuredLoanMortgageAmount`]: yup.string().when(`${prefix}HasExistingFHAInsuredLoan`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
    [`${prefix}ExistingFHAInsuredLoanUnpaidLoanBalance`]: yup.string().when(`${prefix}HasExistingFHAInsuredLoan`, {
      is: 'true',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    }),
  };
}

export default YupValidationSchemaGovernmentRegulation1009;
