import React, {useCallback} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useInputLabel, useValidationSchema} from 'hooks/input';
import {isFunction} from 'lodash';
import SwitchToggleButtonElement from 'components/common/form/switch-toggle-button/SwitchToggleButtonElement.js';

function SwitchToggleButton(props) {
  const {name, label, labels, onChange: onChangeExternal, ...rest} = props;
  
  const {control} = useFormContext();

  const inputLabel = useInputLabel({label, name});

  const {required} = useValidationSchema({name});

  const onChangeHandler = useCallback(
    (event) => {
      if (isFunction(onChangeExternal)) {
        onChangeExternal(event, name);
      }
    },
    [name, onChangeExternal],
  );
  return (
    <Controller
      variant="outlined"
      name={name}
      {...rest}
      label={inputLabel}
      render={({field: {onChange, value, ref}}) => {
        return (
          <SwitchToggleButtonElement
            ref={ref}
            name={name}
            value={value}
            label={label}
            labels={labels}
            required={required}
            {...rest}
            onChange={(e) => {
              onChange(e);
              onChangeHandler(e);
            }}
          />
        );
      }}
      control={control}
    />
  );
}

SwitchToggleButton.defaultProps = {};

SwitchToggleButton.propTypes = {};

export default SwitchToggleButton;
