import {useQuery} from 'react-query';
import RateServiceApi from 'api/rate-service-api';
import {useMemo} from 'react';
import {hybridMetricsLatestQueryKey} from 'constants/query';

export function useHybridMetricsLatestQuery(props = {}) {
  const {enabled} = props;
  const {data: tiers, status} = useQuery(hybridMetricsLatestQueryKey, () => RateServiceApi.getHybridMetricLatest(), {
    enabled,
  });

  const data = useMemo(() => tiers ?? [], [tiers]);
  const defaultTier = useMemo(() => data[0], [data]);

  return {data, defaultTier, status};
}
