import React, {useCallback, useState} from 'react';
import ContentSection from 'pages/illustrator-hybrid-page/components/content-section/ContentSection';
import AreaChart from 'pages/illustrator-hybrid-page/components/area-chart/AreaChart';
import {useGeneratedData} from 'pages/illustrator-hybrid-page/components/line-chart/LineChart';
import {useTranslation} from 'lib/i18n';
import {formatPercent} from 'lib/currency';
import {useOwnFormContext} from 'contexts/form-context';
import {usePrintingValue} from 'hooks/utils';

function AreaChartSection(props) {
  const {children} = props;
  const {advancedOptions, Rate} = useOwnFormContext();
  const {t} = useTranslation();

  const [keys, setKeys] = useState([]);

  const {results: chartData, refreshResults} = useGeneratedData({
    advancedOptions,
    keys,
  });

  const setChartData = useCallback(
    (keys) => {
      setKeys(keys);

      refreshResults();
    },
    [setKeys, refreshResults],
  );
  const margin = usePrintingValue(undefined, {top: 30, right: 30, left: 120, bottom: 30});

  return (
    <ContentSection
      chart={<AreaChart data={chartData} interval={3} intervalXl={2} margin={margin} />}
      chartProps={{
        chartTitle: t('area chart section.chart title'),
        chartDisclaimer: t('area chart section.disclaimer', {Rate: formatPercent(Rate)}),
      }}
    >
      {React.Children.map(children, (child) => React.cloneElement(child, {setChartData}))}
    </ContentSection>
  );
}

AreaChartSection.propTypes = {};

export default AreaChartSection;
