import React, {useCallback, useState} from 'react';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import MapChart from './components/map-chart/MapChart';
import {TabContext, TabList} from '@material-ui/lab';
import {ToString} from 'lib/utils';
import {AppBar, Box, Tab, useTheme} from '@material-ui/core';
import Title from 'components/common/typeography/title/Title';
import Text from 'components/common/typeography/text/Text';
import Container from 'components/common/layout/container/Container';
import useStyles from './ProductAvailabilityPageStyles';
import StateList from './components/state-list/StateList';
import LinearLoader from 'components/common/loader/LinearLoader';
import {useGetProducts} from 'hooks/product';
import {Trans} from 'lib/i18n';
import TabPanel from 'components/common/tab-panel/TabPanel';

function ProductAvailabilityPage(props) {
  const {loading, products, product, setProduct} = useGetProducts({
    onSuccess: (products) => setProduct({ApiRoute: products[0].ApiRoute}),
  });
  const classes = useStyles();

  const theme = useTheme();

  const [tooltipContent, setTooltipContent] = useState('');

  const handleChange = useCallback((event, value) => setProduct({ApiRoute: value}), [setProduct]);

  const setTooltipContentCallback = useCallback(
    (content) => {
      setTooltipContent(content);
    },
    [setTooltipContent],
  );

  return (
    <Container className={classes.root}>
      <Box className={classes.titleContainer}>
        <Title variant="h4">
          <Trans i18nKey="productAvailabilityTranslation:title" />
        </Title>
        <Text>
          <Trans i18nKey="productAvailabilityTranslation:description" />
        </Text>
      </Box>

      <TabContext value={ToString(product?.ApiRoute)}>
        <LinearLoader
          className={classes.loaderContainer}
          childrenContainerClassName={classes.tabContainer}
          loading={loading}
        >
          <Box>
            <AppBar color="primary" position="relative">
              <TabList onChange={handleChange}>
                {products.map((product) => (
                  <Tab key={product.ApiRoute} label={product.ProductName} value={ToString(product.ApiRoute)} />
                ))}
              </TabList>
            </AppBar>
            {products.map((product) => (
              <TabPanel key={product.ApiRoute} value={ToString(product.ApiRoute)}>
                <StateList product={product} tooltipContent={tooltipContent} />
                <Box>
                  <MapChart product={product} setTooltipContent={setTooltipContentCallback} />
                </Box>
                <ReactTooltip backgroundColor={theme.palette.secondary.main}>{tooltipContent}</ReactTooltip>
              </TabPanel>
            ))}
          </Box>
        </LinearLoader>
      </TabContext>
    </Container>
  );
}

ProductAvailabilityPage.propTypes = {};

export default ProductAvailabilityPage;
