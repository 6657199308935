import React from 'react';
import useStyles from './BecomePartnerMenuStyles';
import List from 'components/common/list/List';
import {Trans} from 'lib/i18n';
import clsx from 'clsx';
import Button from 'components/common/button/Button';
import {Link} from '@material-ui/core';
import {ROUTES} from 'constants/routes';

function BecomePartnerMenu(props) {
  const classes = useStyles();

  return (
    <List
      className={clsx(classes.becomePartnerMenu, classes.container)}
      listItemClassName={classes.listItem}
      flexDirection="row"
      justifyContent="flex-end"
      data-testid="become-partner-menu"
    >
      <span itemScope="itemscope" className={classes.itemElement}>
        <Trans i18nKey="appHeaderTranslation:partner join" />
      </span>
      <Link
        className={classes.button}
        btnStyle="containSecondaryMain"
        component={Button}
        href={ROUTES.BECOME_PARTNER}
        data-testid="become-partner-link"
      >
        <Trans i18nKey="appHeaderTranslation:become partner" />
      </Link>
    </List>
  );
}

BecomePartnerMenu.propTypes = {};

export default BecomePartnerMenu;
