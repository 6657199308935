import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    content: {
      backgroundColor: theme.palette.white.light,
      display: theme.withPrint('flex', 'block'),
      overflow: 'hidden',
    },
    illustratorBorrowerContainer: {
      textAlign: 'center',
    },
    progressBarContainer: {
      display: 'flex',
    },
    fullWidth: {
      width: '100%',
      display: 'flex',
      margin: theme.spacing(0, 'auto'),
      '& .MuiTypography-root': {
        fontWeight: '600 !important',
      },
    },
    noPaddingContainer: {
      padding: `${theme.spacing(0)}px !important`,
      margin: 0,
    },
  }),
  {index: 1},
);

export default useStyles;
