import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    itemContainer: {
      width: '100%',
      margin: 'auto',
      '& .MuiFilledInput-root': {
        backgroundColor: 'unset',
      },
      '& .MuiInputLabel-root': {
        color: theme.palette.primary.dark,
        fontWeight: '600 !important',
      },
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    textAlignRight: {
      textAlign: 'right',
    },
    previewPDFContainer: {},
    screenShotElement: {
      minWidth: theme.spacing(20),
      maxWidth: theme.spacing(30),
      flex: 1,
    },
    screenShotContainer: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    previewPdf: {
      padding: '2px',
      width: '100%',
      display: 'block',
      margin: theme.spacing(0, 'auto'),
    },
    subTitle: {
      fontWeight: 500,
    },
    noMargin: {
      margin: 0,
    },
    imageContainer: {
      height: '220px',
      overflow: 'hidden',
    },
  }),
  {index: 1},
);

export default useStyles;
