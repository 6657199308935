import * as yup from 'yup';
import {ToNumber} from 'lib/utils';

export const ONE_OPTION_REQUIRED = 'one-option-required';
export const WISH_NOT_TO_PROVIDE = 'wish-not-to-provide';
export const HIGHER_THAN_ZERO = 'higher-than-zero';
export const MAX_VALUE = 'max-value';

yup.addMethod(yup.bool, 'oneOptionRequired', function (args = {}) {
  const {message, optionKeys = []} = args;

  return this.test({
    name: ONE_OPTION_REQUIRED,
    message,
    test() {
      const isOptionSelected = optionKeys.find((key) => this.parent[key]);

      if (!isOptionSelected) {
        return this.createError({path: this.path, message});
      }

      return true;
    },
  });
});

yup.addMethod(yup.bool, 'wishNotToProvide', function (args = {}) {
  const {message, optionKeys = []} = args;

  return this.test({
    name: WISH_NOT_TO_PROVIDE,
    message,
    test(value) {
      const isOptionSelected = optionKeys.find((key) => this.parent[key]);

      if (!isOptionSelected && value) {
        return this.createError({path: this.path, message});
      }

      return true;
    },
  });
});

/**
 *
 * A value higher than 0.00 is required.
 *
 */

yup.addMethod(yup.mixed, 'higherThanZero', function (args = {}) {
  const {message} = args;

  return this.test({
    name: HIGHER_THAN_ZERO,
    message,
    test(value) {
      if (ToNumber(value) <= 0) {
        return this.createError({path: this.path, message});
      }

      return true;
    },
  }).transform((value) => ToNumber(value));
});

yup.addMethod(yup.mixed, 'maxValue', function (args = {}) {
  const {message, key: comparedKey, ...rest} = args;

  return this.test({
    name: MAX_VALUE,
    message,
    test(value, context) {
      if (comparedKey && ToNumber(value) > context.parent[comparedKey]) {
        return this.createError({
          path: this.path,
          message,
          ...rest,
        });
      }

      return true;
    },
  }).transform((value) => ToNumber(value));
});

export default yup;
