import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './IconButtonStyles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconButton as MuiIconButton} from '@material-ui/core';
import clsx from 'clsx';

function IconButton(props) {
  const {
    icon,
    className,
    iconClassName,
    colorVariant,
    children,
    'data-testid': dataTestId,
    size,
    color,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <MuiIconButton
      data-testid={dataTestId}
      className={clsx(className, classes.iconButtonContainer, classes[colorVariant], classes[size])}
      {...rest}
    >
      <FontAwesomeIcon
        data-testid={`${dataTestId}-icon`}
        className={clsx(iconClassName, classes.buttonIcon)}
        icon={icon}
        color={color}
      />

      {children}
    </MuiIconButton>
  );
}

IconButton.defaultProps = {
  color: 'primary',
  underline: 'none',
  size: 'md',
  colorVariant: '',
};

IconButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'default', 'inherit']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  colorVariant: PropTypes.string,
};

export default IconButton;
