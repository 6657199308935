import React, {useCallback} from 'react';
import {formatCurrency} from 'lib/currency';
import {useVisible} from 'hooks/utils';
import {useTranslation} from 'lib/i18n';
import ContentContainer from 'pages/illustrator-hybrid-page/components/content-section/components/ContentContainer';
import {alpha, useTheme} from '@material-ui/core';
import {useSpace} from 'hooks/print';
import {useOwnFormContext} from 'contexts/form-context';

function RetireFreedomSection(props) {
  const {y, setChartData, showLegend, setChartTitle, setChartDisclaimer} = props;
  const {space} = useSpace({forceSpace: !showLegend});
  const {LifetimePaid, ExistingMortgageRemainingBalance, MonthlySaved, LifetimeSaved} = useOwnFormContext();
  const {t} = useTranslation();
  const theme = useTheme();

  const onEnterViewport = useCallback(() => {
    const chartData = [];

    chartData.push(
      {
        name: t('scatter chart section.chart label.MortgagePayment', {space}),
        y: ExistingMortgageRemainingBalance,
        fill: alpha(theme.palette.primary.dark, 0.6),
        visibility: 'hidden',
      },
      {
        name: t('scatter chart section.chart label.NewMortgage', {space}),
        y: LifetimePaid,
        fill: alpha(theme.palette.primary.main, 0.6),
      },
    );

    setChartData(chartData);
    setChartTitle(t('retire freedom section.chart title'));
    setChartDisclaimer(t('retire freedom section.chart disclaimer'));
  }, [
    setChartData,
    ExistingMortgageRemainingBalance,
    LifetimePaid,
    theme,
    t,
    space,
    setChartTitle,
    setChartDisclaimer,
  ]);

  const {visibleNodeRef} = useVisible({threshold: 0.75, onEnterViewport});

  return (
    <ContentContainer
      ref={visibleNodeRef}
      style={{y}}
      title={t('retire freedom section.title')}
      description={t('retire freedom section.description', {
        mortgageDiff: formatCurrency(MonthlySaved),
        mortgageDiffSecond: formatCurrency(LifetimeSaved),
      })}
    />
  );
}

RetireFreedomSection.propTypes = {};

export default RetireFreedomSection;
