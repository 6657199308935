import React, {useMemo} from 'react';
import InRangeSelect from 'components/common/form/select/InRangeSelect';
import {formatPercent} from 'lib/currency';
import {useHECMFixedRatesQuery} from 'query/products/hecm/hecmFixedRates';

FixedRatesSelect.propTypes = {};

function FixedRatesSelect(props = {}) {
  const {defaultValue: defValue, ...rest} = props;
  const {data: fixedRates = [], defaultRate} = useHECMFixedRatesQuery();

  const defaultValue = useMemo(() => defValue ?? defaultRate?.FixedRate, [defValue, defaultRate?.FixedRate]);

  const data = useMemo(
    () =>
      fixedRates.map((Rate) => ({
        value: Rate.FixedRate,
        label: formatPercent(Rate.FixedRate),
        selected: Rate.FixedRate === defaultValue,
      })),
    [fixedRates, defaultValue],
  );

  return <InRangeSelect {...rest} options={data} defaultValue={defaultValue} />;
}

export default FixedRatesSelect;
