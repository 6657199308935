import React from 'react';
import useStyles from './ProductPDFPrintoutChartStyles';

import Paper from '@material-ui/core/Paper';

import clsx from 'clsx';

import PropTypes from 'prop-types';
import LifetimeModel from 'pages/illustrator/components/illustration/components/illustrator-brakedown/components/lifetime-model/LifetimeModel';
import Item from 'components/common/layout/item/Item';
import Container from 'components/common/layout/container/Container';

function ProductPDFPrintoutChart(props) {
  const {...rest} = props;
  const classes = useStyles();

  return (
    <Item xs={12} {...rest}>
      <Container className={classes.printContainer}>
        <Item xs={12} noMargin>
          <Paper variant="outlined" square className={clsx(classes.paper, classes.m2)}>
            <LifetimeModel
              isAnimationActive={false}
              interval={0}
              showProductDetailsModal={false}
              brakeDownType="lifetime-model-pdf"
            />
          </Paper>
        </Item>
      </Container>
    </Item>
  );
}

ProductPDFPrintoutChart.propTypes = {
  isAnimationActive: PropTypes.func,
};

export default ProductPDFPrintoutChart;
