import {makeStyles} from '@material-ui/core/styles';
import {isSmSizeAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    paymentBreakdownContainer: {
      textAlign: 'left',
      [isSmSizeAppQuery(theme)]: {
        padding: `${theme.spacing(1)}px !important`,
      },
    },
    paymentBreakdownElement: {
      paddingTop: theme.withPrint('0 !important'),
      paddingBottom: theme.withPrint('0 !important'),

      [isSmSizeAppQuery(theme)]: {
        padding: `${theme.spacing(0)}px !important`,
      },
    },
    paymentBreakdownListElement: {
      display: theme.withPrint('flex', 'block'),

      [isSmSizeAppQuery(theme)]: {
        display: 'block',
      },
    },
  }),
  {index: 1},
);

export default useStyles;
