import {makeStyles} from '@material-ui/core/styles';
import {fontWeight600, fontWeight700, fontWeightRegular} from 'lib/mui-config';

const useStyles = makeStyles(
  (theme) => ({
    underline: {
      textDecoration: 'underline',
    },
    root: {
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(5),
      width: '100%',
      flexGrow: 1,
      flexDirection: 'row',
    },
    gridContainer: {
      width: '100%',
      marginBottom: theme.spacing(3),

      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: fontWeight600,
      },
      '& .MuiDataGrid-cell': {
        cursor: 'pointer',
        fontWeight: fontWeightRegular,
      },

      '& .MuiDataGrid-row:hover .MuiDataGrid-cell': {
        fontWeight: fontWeight700,
      },
    },
    gridElementMenu: {
      position: 'absolute',
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(3),
    },
    deleteButton: {
      marginRight: theme.spacing(2),
    },
  }),
  {index: 1},
);

export default useStyles;
