import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    alignLeft: {textAlign: 'left'},
    hidden: {display: 'none'},
  }),
  {index: 1},
);

export default useStyles;
