import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'lib/i18n';
import {SpeedDial, SpeedDialAction} from '@material-ui/lab';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useStyles from './ActionButtonsStyles';
import {Box} from '@material-ui/core';
import clsx from 'clsx';
import {useMobileApp} from 'lib/app-theme';

ActionButtons.propTypes = {};

function ActionHoverElement(props = {}) {
  const {component: Component, extendedClassName, className, name, icon, action, ...rest} = props;

  const ref = useRef();
  const isMobileApp = useMobileApp();

  const fabExtended = useMemo(() => name && !isMobileApp, [name, isMobileApp]);

  const showMore = useMemo(() => fabExtended, [fabExtended]);

  const renderIcon = useCallback(
    (icon, name) => {
      return React.cloneElement(icon, {
        className: clsx(className, {[extendedClassName]: showMore}),
        title: name,
        fabExtended: showMore,
      });
    },
    [showMore, extendedClassName, className],
  );

  const getActionProps = useCallback(
    (action) => {
      return {...action, variant: showMore ? 'extended' : undefined};
    },
    [showMore],
  );

  return <Component icon={renderIcon(icon, name)} FabProps={getActionProps(action)} ref={ref} {...rest} />;
}

function ActionButtons(props) {
  const {actions = [], classes, className} = props;
  const ownClasses = useStyles();
  const [open, setOpen] = useState(false);

  const {t} = useTranslation();
  const isMobileApp = useMobileApp();

  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  if (isMobileApp) {
    return (
      <SpeedDial
        classes={{
          ...classes,
          root: clsx(ownClasses.speedDialRoot, classes?.root),
          fab: clsx(ownClasses.speedDialFab, classes?.fab),
          actions: clsx(ownClasses.speedDialActions, classes?.actions),
        }}
        onClick={handleOpen}
        ariaLabel={t('translation:common.speed dial')}
        title={t('translation:common.speed dial')}
        className={clsx(ownClasses.speedDial, className, {[ownClasses.noActions]: !actions.length})}
        icon={<FontAwesomeIcon size="lg" icon={['fas', 'ellipsis-v']} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="down"
      >
        {actions.map(({name, icon, ...action}) => (
          <ActionHoverElement
            component={SpeedDialAction}
            extendedClassName={ownClasses.speedDialExtendedIcon}
            key={name}
            tooltipTitle={name}
            name={name}
            action={action}
            icon={icon}
          />
        ))}
      </SpeedDial>
    );
  }

  return (
    <Box className={ownClasses.speedDial}>
      {actions.map(({name, onClick, icon, ...action}) => (
        <ActionHoverElement
          component={SpeedDial}
          extendedClassName={ownClasses.speedDialExtendedIcon}
          key={name}
          onClick={onClick}
          ariaLabel={name}
          title={name}
          open={false}
          name={name}
          action={action}
          icon={icon}
          classes={{
            root: clsx(ownClasses.speedDialRoot),
          }}
        />
      ))}
    </Box>
  );
}

export default ActionButtons;
