import yup from 'lib/validation';

export function ShortToCloseValidation() {
  return yup.object({
    BorrowerProfile: yup.object({
      ExistingMortgage: yup.object({
        HomeownersInsurancePremium: yup.number().required().positive(),
        InsurancePremiumPaymentTypeId: yup.number().required().positive().integer(),
        PropertyTaxPayment: yup.number().required().positive(),
        PropertyTaxPaymentTypeId: yup.number().required().positive().integer(),
      }),
    }),
  });
}
