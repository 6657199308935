import {useMemo} from 'react';
import {concat, orderBy} from 'lodash';
import {useProductContext} from 'contexts/product-context';

export function useProductAdditionalSteps(props = {}) {
  const {productDetails: {additionalStep, additionalQuickFill} = {}, loading} = useProductContext();

  const allAdditionalSteps = useMemo(() => {
    return orderBy(concat([], additionalStep, additionalQuickFill), 'props.order').filter(Boolean);
  }, [additionalStep, additionalQuickFill]);

  return {allAdditionalSteps, loading};
}
