import React, {useMemo} from 'react';
import {useProductFeatures} from 'hooks/product-features';
import {LazyLoadStep} from 'components/common/step-wizard/LazyLoadStep';
import StepWizard from 'components/common/step-wizard/StepWizard';

FeatureWrapperStepWizard.propTypes = {};

/**
 *
 * @param props {object}
 * @param props.id {string} This params will be used as key for HashStepWizard
 * @returns {JSX.Element}
 * @constructor
 */
function FeatureWrapperStepWizard(props) {
  const {children, ...rest} = props;
  const {isProductFeatureKeyEnabled} = useProductFeatures();

  const mappedChildren = useMemo(
    () =>
      React.Children.map(children, (child) =>
        child && isProductFeatureKeyEnabled(child.props.ProductFeatureKey) ? (
          <LazyLoadStep {...child.props} key={child.props.hashKey} hashKey={child.props.hashKey}>
            {child}
          </LazyLoadStep>
        ) : null,
      ),
    [children, isProductFeatureKeyEnabled],
  );

  return (
    <StepWizard isHashEnabled {...rest}>
      {mappedChildren}
    </StepWizard>
  );
}

export default FeatureWrapperStepWizard;
