import React from 'react';
import MuiList from '@material-ui/core/List';
import clsx from 'clsx';
import {Box} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';

function List(props) {
  const {children, listItemClassName, flexDirection, ...rest} = props;

  return (
    <MuiList component={(props) => <Box display="flex" flexDirection={flexDirection} {...props} />} {...rest}>
      {React.Children.map(children, (child = {}, index) => {
        const {props: {listItemExcluded = false, className: childClassName, ...childProps} = {}} = child;

        return listItemExcluded ? (
          React.cloneElement(child, {...childProps, className: clsx(listItemClassName, childClassName)})
        ) : (
          <ListItem className={listItemClassName} key={index}>
            {child}
          </ListItem>
        );
      })}
    </MuiList>
  );
}

List.defaultProps = {
  flexDirection: 'row',
};
List.propTypes = {};

export default List;
