import React, {useCallback, useContext, useState} from 'react';
import {cloneDeep, merge} from 'lodash';
import ActionButtons from 'pages/illustrator-hybrid-page/components/wizard/hybrid-wizard/components/ActionButtons/ActionButtons';

const actionsContext = {};

const ActionsContext = React.createContext(actionsContext);

export function useActionsContext(props = {}) {
  return useContext(ActionsContext);
}

/**
 *
 * @param props {object}
 * @param props.extraArguments
 * @param props.defaultState
 * @param props.children
 * @return {JSX.Element}
 * @constructor
 */
export function ActionsContextProvider(props = {}) {
  const {children} = props;
  const [value, setValue] = useState({});

  const setActionsContext = useCallback((data) => setValue((value) => merge(cloneDeep(value), data)), [setValue]);
  const resetActionsContext = useCallback(() => setValue({}), [setValue]);

  return (
    <ActionsContext.Provider value={{...value, setActionsContext, resetActionsContext}}>
      {children}

      <ActionButtons {...value} />
    </ActionsContext.Provider>
  );
}
