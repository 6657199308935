import React, {useCallback} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useInputLabel, useValidationSchema} from 'hooks/input';
import {isFunction} from 'lodash';
import Switch3StatesElement from 'components/common/form/switch-3-states/components/Switch3StatesElement';

function Switch3States(props) {
  const {name, label, labels, onChange: onChangeExternal, ...rest} = props;
  
  const {control} = useFormContext();

  const inputLabel = useInputLabel({label, name});

  const {required} = useValidationSchema({name});

  const onChangeHandler = useCallback(
    (event) => {
      if (isFunction(onChangeExternal)) {
        onChangeExternal(event, name);
      }
    },
    [name, onChangeExternal],
  );

  return (
    <Controller
      variant="outlined"
      name={name}
      {...rest}
      label={inputLabel}
      render={({field: {onChange, value, ref}}) => {
        return (
          <Switch3StatesElement
            ref={ref}
            name={name}
            value={value}
            label={label}
            labels={labels}
            required={required}
            {...rest}
            onChange={(e) => {
              onChange(e);
              onChangeHandler(e);
            }}
          />
        );
      }}
      control={control}
    />
  );
}

Switch3States.defaultProps = {};

Switch3States.propTypes = {};

export default Switch3States;
