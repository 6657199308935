import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    illustrationShareRootBox: {
      margin: theme.spacing(8),
    },
    illustrationShareInfoBox: {
      margin: theme.spacing(2, 0, 1),
      display: 'flex',
      justifyContent: 'space-between',
      '& .MuiTypography-root': {
        lineHeight: 2.5,
      },
    },
    itemContainer: {
      width: '100%',
      margin: 'auto',
      '& .MuiFilledInput-root': {
        backgroundColor: 'unset',
      },
      '& .MuiInputLabel-root': {
        color: theme.palette.primary.dark,
        fontWeight: '600 !important',
      },
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    padding8with10: {
      padding: '8px 10px',
    },
    paddingTop10: {
      paddingTop: '10px',
    },
    subTitle: {
      paddingTop: '10px',
      marginLeft: '20px',
    },
    checkBox: {
      top: '-10px',
      right: '-30px',
    },
    dialogContent: {
      paddingTop: theme.spacing(3),
    },
    noMargin: {
      margin: 0,
    },
  }),
  {index: 1},
);

export default useStyles;
