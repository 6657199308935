import React, {useCallback} from 'react';
import {FormControl} from '@mui/material';
import {FormControlLabel, FormGroup} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './SwitchToggleButtonElementStyles';
import {theme} from 'lib/mui-config';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {useAutoSubmit} from 'hooks/auto-submit';

const SwitchToggleButtonElementValues = {
  TRUE: 'true',
  FALSE: 'false',
};

const SwitchToggleButtonElement = React.forwardRef(function SwitchToggleButtonElementRender(props, ref) {
  const {
    children,
    name,
    value,
    label,
    hidden,
    color = theme.palette.primary.main,
    onChange,
    onClick,
    onBlur,
    autoSubmit,
    autoSubmitBlur,
    size = 'large',
  } = props;

  const classes = useStyles();

  const handleChange = useCallback(
    (event) => {
      onChange(event.target.value);
      onClick(event.target.value);
    },
    [onChange, onClick],
  );

  const {onChange: onAutoSubmitHandle, onBlur: handleBlur} = useAutoSubmit({
    autoSubmitBlur,
    autoSubmit,
    onChange: handleChange,
    value,
    onBlur,
  });

  return (
    <FormControl className = {clsx(classes.fullscreen)}>
      <FormGroup className={clsx({[classes.hide]: hidden})}>
        <FormControlLabel
          color={color}
          control={
            <ToggleButtonGroup
              name={name}
              value={value ?? children[1].key}
              orientation="horizontal"
              exclusive
              onBlur={handleBlur}
              onChange={onAutoSubmitHandle}
              aria-label="text alignment"
              ref={ref}
              color="primary"
              size={size}
              fullwidth="true"
              classes={{
                root: clsx(classes.root, {
                  [classes.leftPillActive]: value === SwitchToggleButtonElementValues.TRUE,
                  [classes.rightPillActive]: value === SwitchToggleButtonElementValues.FALSE,
                }),
              }}
            >
              <ToggleButton
                value={children[1].key}
                fullwidth="true"
                classes={{
                  root: clsx(
                    classes.pill,
                    {[classes.leftPillActive]: value === SwitchToggleButtonElementValues.TRUE},
                    {[classes.leftPillInactive]: value === SwitchToggleButtonElementValues.FALSE},
                  ),
                }}
                aria-label="left aligned"
              >
                {children[1].props.children}
              </ToggleButton>

              <ToggleButton
                value={children[0].key}
                fullwidth="true"
                classes={{
                  root: clsx(
                    classes.pill,
                    {[classes.rightPillActive]: value === SwitchToggleButtonElementValues.FALSE},
                    {[classes.rightPillInactive]: value === SwitchToggleButtonElementValues.TRUE},
                  ),
                }}
                aria-label="right aligned"
              >
                {children[0].props.children}
              </ToggleButton>
            </ToggleButtonGroup>
          }
          className={clsx(classes.marginLeftNone, classes.marginRightNone, classes.formControlLabelElement)}
        />
      </FormGroup>
    </FormControl>
  );
});

SwitchToggleButtonElement.propTypes = {};

export default SwitchToggleButtonElement;
