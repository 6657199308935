import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    modalEmailToBorrower: {},
    transitionOverflow: {
      overflowX: 'hidden',
    },
  }),
  {index: 1},
);

export default useStyles;
