import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import TextField from 'components/common/form/input/components/TextField';
import clsx from 'clsx';
import useStyles from './DatePickerInputStyles';

const DatePickerInput = React.forwardRef(function DatePickerInputRender(props, ref) {
  const {onChange, value, InputProps, InputLabelProps, InputAdornmentProps, variant = 'outlined', ...rest} = props;

  const classes = useStyles();
  const onPickerChange = useCallback(
    (date, value, ...r) => {
      return onChange(value);
    },
    [onChange],
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        variant="inline"
        inputVariant={variant}
        openTo="year"
        views={['year', 'month', 'date']}
        value={value || null}
        inputRef={ref}
        autoOk
        TextFieldComponent={TextField}
        InputLabelProps={{
          ...InputLabelProps,
          className: clsx(classes.inputController, InputLabelProps?.className),
          classes: {
            ...InputLabelProps?.classes,
            shrink: clsx(InputLabelProps?.classes?.shrink, classes.inputShrink),
          },
        }}
        InputAdornmentProps={{
          ...InputAdornmentProps,
          className: clsx('adornment-end', InputAdornmentProps?.className),
        }}
        {...rest}
        onChange={onPickerChange}
      />
    </MuiPickersUtilsProvider>
  );
});

DatePickerInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default DatePickerInput;
