import React from 'react';
import {useAnnualOverallResults} from 'components/products/annual/AnnualOverallResults';
import {ProductContextProvider} from 'contexts/product-context';
import {useAnnualHook} from 'components/products/annual/AnnualHook';
import {useCalculateAnnualQuery} from 'query/products/hecm/annual';
import {API_ROUTES} from 'constants/illustrator';
import {useProductWithRef} from 'hooks/product-ref';

/**
 *
 * This is the "Annual" product, aka "HECM Adjustable", part of the "HECM" products.
 *
 */

const Annual = React.forwardRef(function AnnualRender(props, ref) {
  const {product, setRef, ...rest} = props;

  const annualQuery = useCalculateAnnualQuery({product});
  const annualHook = useAnnualHook({product});
  const annualOverallResults = useAnnualOverallResults({product});

  useProductWithRef({
    productDetails: annualHook,
    productResults: annualOverallResults,
    productQuery: annualQuery,
    ref,
    ApiRoute: API_ROUTES.ANNUAL,
  });

  return (
    <ProductContextProvider
      productDetails={annualHook}
      productResults={annualOverallResults}
      productQuery={annualQuery}
      product={product}
      {...rest}
    />
  );
});

export default Annual;
