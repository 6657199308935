import {useCallback, useEffect, useState} from 'react';
import {isFunction} from 'lodash';

export const VIEW_TYPE = {illustrate: 'illustrate', list: 'list', card: 'card'};

export function useViewType(props = {}) {
  const {defaultValue = VIEW_TYPE.card, onTabChange: onTabChangeEvent, watchDefaultValue} = props;

  const {viewType, setView, onTabChange} = useDefaultViewType({
    defaultValue,
    onTabChange: onTabChangeEvent,
    watchDefaultValue,
  });

  return {viewType, setView, onTabChange};
}

export const BREAKDOWN_VIEW_TYPE = {
  'payment-breakdown': 'payment-breakdown',
  'lifetime-model': 'lifetime-model',
  'amortization-table': 'amortization-table',
  'lifetime-model-pdf': 'lifetime-model-pdf',
};

export function useDefaultViewType(props = {}) {
  const {defaultValue = '', onTabChange: onTabChangeEvent, watchDefaultValue = false} = props;

  const [viewType, setViewType] = useState(defaultValue);

  const setView = useCallback((viewType = defaultValue) => setViewType(viewType), [setViewType, defaultValue]);
  const onTabChange = useCallback(
    (event, viewType = defaultValue) => {
      setView(viewType);

      if (isFunction(onTabChangeEvent)) onTabChangeEvent(event, viewType);
    },
    [setView, defaultValue, onTabChangeEvent],
  );

  useEffect(() => {
    if (watchDefaultValue) setView(defaultValue);
  }, [setView, defaultValue, watchDefaultValue]);

  return {viewType, setView, onTabChange};
}
