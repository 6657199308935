import React from 'react';
import {ProductsContextProvider} from 'contexts/products-context';
import {useGetProducts} from 'hooks/product';

const Products = React.memo((props = {}) => {
  const {products = [], loading, children, renderProducts} = props;

  return (
    <ProductsContextProvider products={products} loading={loading} renderProducts={renderProducts}>
      {children}
    </ProductsContextProvider>
  );
});

export function withProducts(Component, {productFeatureKey, renderProducts} = {}) {
  return function WithProductsRender({select, onSuccess, queryKey, ...props}) {
    const {products, loading} = useGetProducts({productFeatureKey, select, onSuccess, queryKey});

    return (
      <Products products={products} loading={loading} renderProducts={renderProducts}>
        <Component {...props} loading={loading} />
      </Products>
    );
  };
}

export default Products;
