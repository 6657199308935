import React from 'react';
import BreakdownTitle from '../brakedown-title/BreakdownTitle';
import {BREAKDOWN_VIEW_TYPE} from 'hooks/view-type';
import AmortizationTableAdvancedOptions from 'components/illustrator/amortization-table-advanced-options/AmortizationTableAdvancedOptions';
import useStyles from './AmortizationTableStyles';
import ProductDetailsModal from '../product-details/ProductDetailsModal';
import {Box} from '@material-ui/core';
import clsx from 'clsx';
import IllustratorDetailedHeaders from 'components/illustrator/illustrator-detailed-headers/IllustratorDetailedHeaders';

AmortizationTable.propTypes = {};

/**
 *
 * the "Amortization Table" component
 *
 */

function AmortizationTable(props = {}) {
  const {showProductDetailsModal, showBreakdownDescription, titleClassName} = props;
  const classes = useStyles();

  return (
    <>
      <ProductDetailsModal showProductDetailsModal={showProductDetailsModal} />

      <BreakdownTitle
        className={titleClassName}
        brakeDownType={BREAKDOWN_VIEW_TYPE['amortization-table']}
        showDescription={showBreakdownDescription}
      />

      <IllustratorDetailedHeaders />

      <Box className={clsx(classes.amortizationTableAdvancedOptions)}>
        <AmortizationTableAdvancedOptions
          defaultColumnOptions={{resizable: false}}
          dataGridClassName={classes.amortizationTableDataGrid}
          forceActive
          showLoader
        />
      </Box>
    </>
  );
}

export default AmortizationTable;
