import React, {useCallback, useState} from 'react';
import ContentSection from 'pages/illustrator-hybrid-page/components/content-section/ContentSection';
import LineChart, {useGeneratedData} from 'pages/illustrator-hybrid-page/components/line-chart/LineChart';
import {useOwnFormContext} from 'contexts/form-context';
import {usePrintingValue} from 'hooks/utils';

function LineChartSection(props) {
  const {children} = props;
  const {advancedOptions} = useOwnFormContext();

  const [keys, setKeys] = useState([]);
  const [chartTitle, setChartTitle] = useState('');
  const [chartDisclaimer, setChartDisclaimer] = useState('');

  const {results: chartData} = useGeneratedData({
    advancedOptions,
    keys,
  });

  const setChartData = useCallback(
    (keys) => {
      setKeys(keys);
    },
    [setKeys],
  );
  const margin = usePrintingValue(undefined, {top: 30, right: 30, left: 120, bottom: 30});

  return (
    <ContentSection
      chartProps={{
        chartTitle,
        chartDisclaimer,
      }}
      chart={<LineChart data={chartData} interval={3} intervalXl={2} margin={margin} />}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {setChartData, setChartTitle, setChartDisclaimer}),
      )}
    </ContentSection>
  );
}

LineChartSection.propTypes = {};

export default LineChartSection;
