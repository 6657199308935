import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      textAlign: 'left',
      width: '100%',
      margin: theme.spacing(2, 'auto'),
      display: theme.forPrint('block'),
    },
    p2: {
      padding: theme.spacing(2, 3),
    },
    m2: {
      margin: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0, 2, 0),
      width: '100%',
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    m8: {
      margin: theme.spacing(4),
    },
    itemContainer: {
      width: '100%',
      margin: 'auto',
      '& .MuiFilledInput-root': {
        backgroundColor: 'unset',
      },
      '& .MuiInputLabel-root': {
        color: theme.palette.primary.dark,
        fontWeight: '600 !important',
      },
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    padding8with10: {
      padding: '8px 10px',
    },
    textArea: {
      '& textarea': {
        fontWeight: 'normal',
      },
    },
    noMargin: {
      margin: 0,
    },
  }),
  {index: 1},
);

export default useStyles;
