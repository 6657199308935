import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    item: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    noMargin: {
      margin: 'unset !important',

      '&:nth-child(even)': {
        paddingLeft: theme.spacing(0.5),
      },
      '&:nth-child(odd)': {
        paddingRight: theme.spacing(0.5),
      },
    },
    noPadding: {
      padding: 'unset !important',
    },
    hide: {
      display: 'none',
    },
  }),
  {index: 1},
);

export default useStyles;
