import React, {useCallback} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useStyles from './AppHeaderFarStyles';
import Logo from './components/logo/Logo';
import {Box} from '@material-ui/core';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import {ROUTES} from 'constants/routes';
import {Trans, useTranslation} from 'lib/i18n';
import List from 'components/common/list/List';
import SearchButton from 'components/app-header/components/search-button/SearchButton';
import ExcludedDivider from 'components/common/list/ExcludedDivider';
import Button from 'components/common/button/Button';
import ExpendableLink from '../common/expendable-link/expendable-link';
import logo from 'assets/images/foa-logo.svg';
import PrintPageTitle from 'components/app-header/components/print-page-title/PrintPageTitle';
import {useSmSizeApp} from 'lib/app-theme';

const AppHeaderFar = React.forwardRef(function AppHeaderFarRender(props, ref) {
  const {className} = props;
  const isSmSizeApp = useSmSizeApp();
  const classes = useStyles();
  const {t} = useTranslation();

  const goToStart = useCallback(() => {
    window.open(ROUTES.REVERSE_START);
  }, []);

  return (
    <AppBar
      position="sticky"
      color="inherit"
      elevation={1}
      className={clsx(classes.root, className)}
      data-testid="app-header-far"
      ref={ref}
    >
      <Toolbar className={clsx(classes.toolBar, classes.toolBarWithSpace, classes.tooBarDark, classes.hidePrint)}>
        <Box display="flex" flexDirection="row" className={classes.toolBarBox} />
        <Box display="flex" flexDirection="row" className={classes.toolBarBox}>
          <List
            color="secondary"
            flexDirection="row"
            justifyContent="flex-center"
            className={classes.farList}
            listItemClassName={clsx(classes.nonAuthListItem)}
          >
            <Link href={t('appHeaderFarTranslation:tel')} className="MuiTypography-body1 MuiTypography-colorWhite">
              <Trans i18nKey="appHeaderFarTranslation:phone" />
            </Link>
            <ExcludedDivider className={classes.divider} orientation="vertical" flexItem />
            <Link href={ROUTES.REVERSE_CONTACT} className="MuiTypography-body1 MuiTypography-colorWhite">
              <Trans i18nKey="appHeaderFarTranslation:contact" />
            </Link>
            <ExcludedDivider className={classes.divider} orientation="vertical" flexItem />
            <Link href={ROUTES.REVERSE_PARTNER} className="MuiTypography-body1 MuiTypography-colorWhite">
              <Trans i18nKey="appHeaderFarTranslation:partner" />
            </Link>

            <Button className="MuiTypography-body1 MuiTypography-colorWhite show-accessibility" variant="outlined">
              <span className="regular">A</span>
              <span className="large">A</span>
              <span className="extralarge">A</span>
            </Button>

            <SearchButton colorVariant="white" size="sm" />
          </List>
        </Box>
      </Toolbar>
      <Toolbar className={clsx(classes.toolBar, classes.toolBarWithSpace)}>
        <Box display="flex" flexDirection="row" className={clsx(classes.toolBarBox, classes.toolBarBoxNoPadding)}>
          <Box display="flex">
            <Logo img={logo} href={ROUTES.REVERSE_HOME_PAGE} />

            <PrintPageTitle className={classes.illustrationPrintTitle} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" className={clsx(classes.toolBarBox, classes.hidePrint)}>
          {!isSmSizeApp && (
            <List
              flexDirection="row"
              justifyContent="flex-center"
              className={classes.farListHeader}
              listItemClassName={classes.nonAuthListItem}
            >
              <ExpendableLink
                className="MuiTypography-body1"
                text={<Trans i18nKey="appHeaderFarTranslation:explore" />}
                listItemClassName={classes.nonAuthListItem}
              >
                <ExcludedDivider
                  className={clsx(classes.dividerHorizontal, classes.divider)}
                  orientation="horizontal"
                  flexItem
                />
                <Link href={ROUTES.REVERSE_OVERVIEW} className="MuiTypography-body1" underline="none">
                  <Trans i18nKey="appHeaderFarTranslation:overview" />
                </Link>
                <ExcludedDivider
                  className={clsx(classes.dividerHorizontal, classes.divider)}
                  orientation="horizontal"
                  flexItem
                />

                <ExpendableLink
                  sublist
                  className="MuiTypography-body1"
                  text={<Trans i18nKey="appHeaderFarTranslation:tools" />}
                  listItemClassName={classes.nonAuthListItem}
                >
                  <Link href={ROUTES.REVERSE_OVERVIEW_TOOLS} className="MuiTypography-body1" underline="none">
                    <Trans i18nKey="appHeaderFarTranslation:tools overview" />
                  </Link>
                  <ExcludedDivider
                    className={clsx(classes.dividerHorizontal, classes.divider)}
                    orientation="horizontal"
                    flexItem
                  />
                  <Link href={ROUTES.REVERSE_OVERVIEW_HOME_SAFE} className="MuiTypography-body1" underline="none">
                    <Trans i18nKey="appHeaderFarTranslation:tools homesafe" />
                  </Link>
                  <ExcludedDivider
                    className={clsx(classes.dividerHorizontal, classes.divider)}
                    orientation="horizontal"
                    flexItem
                  />
                  <Link href={ROUTES.REVERSE_OVERVIEW_EQUITY_AVAIL} className="MuiTypography-body1" underline="none">
                    <Trans i18nKey="appHeaderFarTranslation:tools equityavail" />
                  </Link>
                  <ExcludedDivider
                    className={clsx(classes.dividerHorizontal, classes.divider)}
                    orientation="horizontal"
                    flexItem
                  />
                  <Link href={ROUTES.REVERSE_OVERVIEW_HECM} className="MuiTypography-body1" underline="none">
                    <Trans i18nKey="appHeaderFarTranslation:tools hecm" />
                  </Link>
                  <ExcludedDivider
                    className={clsx(classes.dividerHorizontal, classes.divider)}
                    orientation="horizontal"
                    flexItem
                  />
                  <Link href={ROUTES.REVERSE_OVERVIEW_REVERSE} className="MuiTypography-body1" underline="none">
                    <Trans i18nKey="appHeaderFarTranslation:tools reverse" />
                  </Link>
                  <ExcludedDivider
                    className={clsx(classes.dividerHorizontal, classes.divider)}
                    orientation="horizontal"
                    flexItem
                  />
                  <Link href={ROUTES.REVERSE_OVERVIEW_SILVERNEST} className="MuiTypography-body1" underline="none">
                    <Trans i18nKey="appHeaderFarTranslation:tools silvernest" />
                  </Link>
                </ExpendableLink>

                <ExcludedDivider
                  className={clsx(classes.dividerHorizontal, classes.divider)}
                  orientation="horizontal"
                  flexItem
                />
                <Link href={ROUTES.REVERSE_SPOTLIGHT} className="MuiTypography-body1" underline="none">
                  <Trans i18nKey="appHeaderFarTranslation:faq" />
                </Link>
                <ExcludedDivider
                  className={clsx(classes.dividerHorizontal, classes.divider)}
                  orientation="horizontal"
                  flexItem
                />
                <Link href={ROUTES.REVERSE_TESTIMONIALS} className="MuiTypography-body1" underline="none">
                  <Trans i18nKey="appHeaderFarTranslation:testimonials" />
                </Link>
              </ExpendableLink>
              <ExcludedDivider className={classes.divider} orientation="vertical" flexItem />
              <Link href={ROUTES.REVERSE_SPOTLIGHT} className="MuiTypography-body1">
                <Trans i18nKey="appHeaderFarTranslation:spotlight" />
              </Link>
              <ExcludedDivider className={classes.divider} orientation="vertical" flexItem />
              <Link href={ROUTES.REVERSE_BLOG} className="MuiTypography-body1">
                <Trans i18nKey="appHeaderFarTranslation:blog" />
              </Link>
              <ExcludedDivider className={classes.divider} orientation="vertical" flexItem />

              <ExpendableLink
                className="MuiTypography-body1"
                text={<Trans i18nKey="appHeaderFarTranslation:why far" />}
                listItemClassName={classes.nonAuthListItem}
              >
                <ExcludedDivider
                  className={clsx(classes.dividerHorizontal, classes.divider)}
                  orientation="horizontal"
                  flexItem
                />
                <Link
                  href={ROUTES.REVERSE_ABOUT}
                  className="MuiTypography-body1"
                  underline="none"
                  data-testid="why-far-link"
                >
                  <Trans i18nKey="appHeaderFarTranslation:about far" />
                </Link>
                <ExcludedDivider
                  className={clsx(classes.dividerHorizontal, classes.divider)}
                  orientation="horizontal"
                  flexItem
                />
                <Link
                  href={ROUTES.REVERSE_OUR_COMPANY}
                  className="MuiTypography-body1"
                  underline="none"
                  data-testid="our-company-link"
                >
                  <Trans i18nKey="appHeaderFarTranslation:our company" />
                </Link>
                <ExcludedDivider
                  className={clsx(classes.dividerHorizontal, classes.divider)}
                  orientation="horizontal"
                  flexItem
                />
                <Link href={ROUTES.REVERSE_TESTIMONIALS} className="MuiTypography-body1" underline="none">
                  <Trans i18nKey="appHeaderFarTranslation:testimonials" data-testid="testimonials-link" />
                </Link>
              </ExpendableLink>

              <Button
                onClick={goToStart}
                className="button-start"
                data-testid="button-start"
                btnStyle="containSecondaryMain"
              >
                <Trans i18nKey="appHeaderFarTranslation:start" />
              </Button>
            </List>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
});

export default AppHeaderFar;
