import React, {useMemo} from 'react';
import Item from 'components/common/layout/item/Item';
import Title from 'components/common/typeography/title/Title';
import Container from 'components/common/layout/container/Container';
import clsx from 'clsx';
import {ToNumber} from 'lib/utils';

/**
 *
 * This component now serves as a header title with optional subtitle.
 *
 * variant = h1, h2, h3, h4, h5, h6 or blank ""
 *
 * The "declarations" page within the borrower flow has a title and a subtitle.
 *
 */

function IllustratorHeaderTitle(props) {
  // props
  const {
    title = '',
    subTitle = '',
    className,
    item = {
      xs: 0,
      sm: 1,
      md: 3,
    },
    subTitleItem = {
      xs: 0,
      sm: 1,
    },
    variant = 'h4', // h1-h6 or blank
  } = props;

  const titleElement = useMemo(() => {
    const element = {};

    Object.keys(item).map((key) => (element[key] = 12 - ToNumber(item[key]) * 2));

    return element;
  }, [item]);

  const subTitleElement = useMemo(() => {
    const element = {};

    Object.keys(subTitleItem).map((key) => (element[key] = 12 - ToNumber(subTitleItem[key]) * 2));

    return element;
  }, [subTitleItem]);

  return (
    <Container className={clsx(className, 'fullWidth')}>
      {title.length ? (
        <>
          <Item {...item} />
          <Item {...titleElement}>
            <Title variant={variant}>{title}</Title>
          </Item>
          <Item {...item} />
        </>
      ) : null}
      {subTitle.length ? (
        <>
          <Item {...subTitleItem} />
          <Item {...subTitleElement}>
            <Title variant={variant}>{subTitle}</Title>
          </Item>
          <Item {...subTitleItem} />
        </>
      ) : null}
    </Container>
  );
}

IllustratorHeaderTitle.propTypes = {};

export default IllustratorHeaderTitle;
