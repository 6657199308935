import React, {useCallback} from 'react';
import ContentContainer from '../content-section/components/ContentContainer';
import {formatCurrency, formatPercent} from 'lib/currency';
import {useVisible} from 'hooks/utils';
import {useTranslation} from 'lib/i18n';
import {alpha, useTheme} from '@material-ui/core';
import {useOwnFormContext} from 'contexts/form-context';

function NewMortgageSection(props) {
  const {setChartData, setChartDisclaimer} = props;

  const theme = useTheme();

  const {MortgagePayment, NewMonthlyPayment, CouponRateReverse, startLoanValue} = useOwnFormContext();
  const {t} = useTranslation();

  const onEnterViewport = useCallback(() => {
    const chartData = [];

    chartData.push(
      {
        name: t('mortgage chart section.chart label.MortgagePayment'),
        y: MortgagePayment,
        fill: alpha(theme.palette.primary.dark, 0.6),
      },
      {
        name: t('mortgage chart section.chart label.NewMortgage'),
        y: NewMonthlyPayment,
        fill: alpha(theme.palette.primary.main, 0.6),
      },
    );

    setChartData(chartData);
    setChartDisclaimer(
      t('new mortgage section.chart disclaimer', {
        CouponRateReverse: formatPercent(CouponRateReverse),
        startLoanValue: formatCurrency(startLoanValue),
      }),
    );
  }, [
    MortgagePayment,
    NewMonthlyPayment,
    setChartData,
    setChartDisclaimer,
    CouponRateReverse,
    startLoanValue,
    t,
    theme,
  ]);

  const {visibleNodeRef} = useVisible({threshold: 0.75, onEnterViewport});

  return (
    <ContentContainer
      ref={visibleNodeRef}
      title={t('new mortgage section.title')}
      disclaimer={t('new mortgage section.disclaimer')}
      description={t('new mortgage section.description', {
        NewMonthlyPayment: formatCurrency(NewMonthlyPayment),
        mortgageDiff: formatCurrency(MortgagePayment - NewMonthlyPayment),
      })}
    />
  );
}

NewMortgageSection.propTypes = {};

export default NewMortgageSection;
