import {useEffect} from 'react';
import clsx from 'clsx';
import {isFunction} from 'lodash';
import {useStyleContentContext} from 'contexts/style-content-context';

export function useStyleContentEffect(className) {
  const {setContentClassName, resetContentClassName} = useStyleContentContext();

  useEffect(() => {
    if (isFunction(setContentClassName)) setContentClassName(clsx(className));

    return () => {
      if (isFunction(resetContentClassName)) resetContentClassName();
    };
  }, [setContentClassName, resetContentClassName, className]);
}
