import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    dialogChildrenContainer: {
      overflow: 'visible',
    },
    dialogActionsContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100%',
      '& button': {
        margin: 0,
      },
    },
    dialogPaperContainer: {
      padding: theme.spacing(1, 6, 4),
    },
    dialogPaperContainerStepWizard: {
      overflow: 'hidden',
    },
  }),
  {index: 1},
);

export default useStyles;
