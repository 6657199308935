import CalculationsApi from 'api/calculations-api';
import {useMutation} from 'react-query';
import {saveEmailIllustrationsMutationKey, shareIllustrationsMutationKey} from 'constants/query';
import {statusToLoading} from 'adapter/loading.adapter';

export function useSaveEmailIllustrationsMutation() {
  const mutation = useMutation([saveEmailIllustrationsMutationKey], ({toEmails, calculationIds}) =>
    CalculationsApi.saveEmailIllustrations({
      toEmails: toEmails,
      calculationIds: calculationIds,
    }),
  );

  const loading = statusToLoading(mutation.status, mutation.isLoading);

  return {...mutation, loading};
}

export function useShareIllustrationsMutation() {
  const mutation = useMutation([shareIllustrationsMutationKey], ({toEmails, calculationIds, pdfs}) => {
    const formData = new FormData();

    toEmails.forEach((email) => formData.append('toEmails[]', email));
    pdfs.forEach((pdf) => {
      formData.append('calculationIds[]', pdf.CalculationId);
      formData.append('files', pdf.pdf, pdf.fileName);
    });

    return CalculationsApi.shareIllustrations(formData);
  });

  const loading = statusToLoading(mutation.status, mutation.isLoading);

  return {...mutation, loading};
}
