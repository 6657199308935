import React from 'react';
import {useProductFeaturesById} from 'hooks/product-features';
import FeaturesRender from './FeaturesRender';

/**
 *
 * @param props {Object}
 * @param props.DefaultComponent {React.Component}
 * @param props.Component {React.Component}
 * @param props.NotEnabledComponent {React.Component}
 * @param props.ProductFeatureKey {string}
 * @param props.ProductId {string}
 * @return {JSX.Element|null}
 * @constructor
 */
function ProductFeaturesWrapper(props) {
  const {ProductFeatureKey, ProductId, ...rest} = props;
  const {isProductFeatureEnabled, loading} = useProductFeaturesById();

  const isEnabled = isProductFeatureEnabled(ProductId, ProductFeatureKey);

  return <FeaturesRender isEnabled={isEnabled} loading={loading} {...rest} />;
}

export default ProductFeaturesWrapper;
