import {useMutation} from 'react-query';
import IllustratorBorrowerServiceApi from 'api/illustrator-borrower-service-api';
import {DEFAULT_INCOME_TYPE, INCOME_FREQUENCY} from 'constants/income';
import {
  CONDO,
  PUD_ATTACHED,
  PUD_DETACHED,
  SITE_CONDOMINIUM,
  TOWNHOUSE,
} from 'hooks/link-application/property-taxes/property-type';
import {ToFixedNumber} from 'lib/utils';
import {API_ROUTES} from 'constants/illustrator';

/**
 *
 * !!! README !!!
 *
 * "Link" requires that the data to be sent in a specific way.
 *
 * The "store" data params naming have nothing to do with the "link" data params naming.
 *
 * Make sure to map the redux store param names to the required request param names.
 *
 * !!! When mapping, the attr might be not the same as within other flows !!!
 *
 * example :
 *
 *  - within an illustration, we have FirstLien mapped as FirstLien
 *  - within the link app, FirstLien is mapped as FirstMortgage
 *
 */

export function useSaveIllustratorBorrowerMutation() {
  return useMutation(
    (data) => {
      // processing the "affiliated business arrangements" data
      const abaList = data?.affiliatedBusinessArrangements?.abaFormList?.map((record) => ({
        providerName: record.providerName,
        settlementService: record.settlementService,
        estCost: record.estCost,
        relationship: record.natureOfRelation,
      }));

      // processing the "income" data
      const incomeList = data?.income?.incomeFormList?.map((record) => {
        let incomeType = record.incomeType;
        let monthlyAmount = record.monthlyAmount;
        let incomeFrequency = record.incomeFrequency;

        if (incomeType === DEFAULT_INCOME_TYPE && incomeFrequency === INCOME_FREQUENCY.annual) {
          incomeFrequency = INCOME_FREQUENCY.monthly;
          monthlyAmount = ToFixedNumber(monthlyAmount / 12, 2);
        } else {
          // making sure that the amount does not get split multiple times
          if (incomeFrequency === INCOME_FREQUENCY.annual) {
            monthlyAmount = ToFixedNumber(monthlyAmount / 12, 2);
          }
        }

        return {
          collectedBy: record.collectedBy,
          incomeType: incomeType,
          incomeFrequency: incomeFrequency,
          monthlyAmount: monthlyAmount,
        };
      });

      // processing the "additional properties" data
      const additionalPropertiesList = data?.additionalProperties?.apFormList?.map((record) => ({
        street: record.streetAddress,
        unitNumber: record.unitNumber,
        city: record.propertyCity,
        State: record.propertyState,
        zip: record.propertyZip,
      }));

      /**
       *
       * ibData / start
       *
       * key as "link" param name : value from "store" param name
       *
       */

      let ibData = {
        LinkProductName: data?.LinkProductName,

        // borrower info
        FirstName: data?.borrowerInfo?.borrowerFirstName || '',
        LastName: data?.borrowerInfo?.borrowerLastName || '',
        MiddleName: data?.borrowerInfo?.borrowerMiddleName || '',
        suffix: data?.borrowerInfo?.borrowerSuffix || '',
        birthDate: data?.borrowerInfo?.borrowerDOB || '',
        ssn: data?.borrowerInfo?.borrowerSSN || '',
        EmailAddress: data?.borrowerInfo?.borrowerEmail || '',
        maritalStatus: data?.borrowerInfo?.borrowerMaritalStatus || '',
        homePhone: data?.borrowerInfo?.borrowerHomePhoneNumber || '',
        poaName: data?.borrowerInfo?.borrowerPowerOfAttorney || '',
        PropertyVestedInATrust: data?.borrowerInfo?.borrowerPropertyVestedInATrust || '',

        // co-borrower info
        coBorrowerMiddleName: data?.coBorrowerInfo?.coBorrowerMiddleName || '',
        coBorrowerFirstName: data?.coBorrowerInfo?.coBorrowerFirstName || '',
        coBorrowerLastName: data?.coBorrowerInfo?.coBorrowerLastName || '',
        coBorrowerSSN: data?.coBorrowerInfo?.coBorrowerSSN || '',
        coBorrowerBirthDate: data?.coBorrowerInfo?.coBorrowerDOB || '',
        coBorrowerMaritalStatus: data?.coBorrowerInfo?.coBorrowerMaritalStatus || '',
        coBorrowerHomePhoneNumber: data?.coBorrowerInfo?.coBorrowerHomePhoneNumber || '',
        coBorrowerEmailAddress: data?.coBorrowerInfo?.coBorrowerEmail || '',

        // property
        propertyAddress: {
          street: data?.property?.propertyAddress || '',
          unitNumber: data?.property?.propertyAddress2 || '',
          city: data?.property?.propertyCity || '',
          State: data?.property?.propertyState || '',
          zip: data?.property?.propertyZipCode || '',
        },
        loanAmount: data?.property?.propertyMortgageBalance || 0, // todo : is this used ?
        mortgagePayoffs: data?.property?.propertyMortgagePayoff || '',
        estimatedAppraisedValue: data?.property?.propertyHomeValue || 0,

        // credit authorization
        creditAuthorizationReceived: data?.creditAuthorization?.authorization || false,
        creditAuthorizationDate: data?.creditAuthorization?.creditAuthorizationDate || '',
        creditAuthorizationReport: data?.creditAuthorization?.report || false,
        creditAuthorizationReportReferenceId: data?.creditAuthorization?.reportReferenceId || '',

        // property taxes
        annualCountyTaxAmount: data?.propertyTaxes?.annualCountyTaxAmount || '',
        annualHazardInsuranceAmount: data?.propertyTaxes?.annualHazardInsuranceAmount || '',
        propertyType: data?.propertyTaxes?.propertyType || '',
        noOfUnits: data?.propertyTaxes?.NumberOfUnits || '',
        HOAMonthlyFees: '',

        // mailing address
        mailingAddress: {
          street: data?.mailingAddress?.mailingAddress || '',
          unitNumber: data?.mailingAddress?.mailingAddress2 || '',
          city: data?.mailingAddress?.mailingCity || '',
          State: data?.mailingAddress?.mailingState || '',
          zip: data?.mailingAddress?.mailingZipCode || '',
        },

        // additional properties
        otherREOAddresses: additionalPropertiesList,

        // broker transaction question
        BrokerOffersForwardMortgageProducts: data?.BrokerOffersForwardMortgageProducts || 'No',

        // broker transactions
        // question #1
        opt1Loan: data?.brokerTransactions?.totalInitialRate1 || 0,
        opt1LoanTotalOriginationPts: data?.brokerTransactions?.totalFeesPoints1 || 0,
        // question #2
        opt2Loan: data?.brokerTransactions?.totalInitialRate2 || 0,
        opt2LoanTotalOriginationPts: data?.brokerTransactions?.totalFeesPoints2 || 0,
        // question #3
        opt3Loan: data?.brokerTransactions?.totalInitialRate3 || 0,
        opt3LoanTotalOriginationPts: data?.brokerTransactions?.totalFeesPoints3 || 0,

        // interviewer page
        thirdPartyInterviewerName: data?.interviewPage?.thirdPartyLOInterviewerName || '',
        thirdPartyInterviewerEmail: data?.interviewPage?.thirdPartyLOEmailInterviewerEmail || '',

        // affiliated business arrangements
        abaProviders: abaList,

        // income
        incomes: incomeList,

        /** ********** ********** ********** ********** ********** ********** ********** ********** ********** **********
         *
         * demographic info data / start
         *
         ********** ********** ********** ********** ********** ********** ********** ********** ********** ********** */

        /**
         *
         * interviewer and demographics
         *
         */

        demographicInformationSource: data?.interviewerAndDemographics?.demographicInformationSource || '',

        /**
         *
         * borrower demographic info data
         *
         */

        // borrower demographic info / ethnicity
        BorrowerEthnicity: {
          HispanicLatino: data?.demographicInfoBorrower?.borrowerEthnicityHispanicLatino || false,
          Mexican: data?.demographicInfoBorrower?.borrowerEthnicityMexican || false,
          PuertoRican: data?.demographicInfoBorrower?.borrowerEthnicityPuertoRican || false,
          Cuban: data?.demographicInfoBorrower?.borrowerEthnicityCuban || false,
          OtherHispanicLatino: data?.demographicInfoBorrower?.borrowerEthnicityOtherHispanicLatino || false,
          OtherHispanicLatinoText: '',
          NotHispanicLatino: data?.demographicInfoBorrower?.borrowerEthnicityNotHispanicLatino || false,
          WishNotToProvide: data?.demographicInfoBorrower?.borrowerEthnicityWishNotToProvide || false,
        },

        // borrower demographic info / sex
        BorrowerSex: {
          Female: data?.demographicInfoBorrower?.borrowerSexFemale || false,
          Male: data?.demographicInfoBorrower?.borrowerSexMale || false,
          WishNotToProvide: data?.demographicInfoBorrower?.borrowerSexWishNotToProvide || false,
        },

        // borrower demographic info / race
        BorrowerRace: {
          AmericanIndianAlaskaNative: data?.demographicInfoBorrower?.borrowerRaceAmericanIndianAlaskaNative || false,
          AmericanIndianAlaskaNativeText: '',
          Asian: data?.demographicInfoBorrower?.borrowerRaceAsian || false,
          AsianIndian: data?.demographicInfoBorrower?.borrowerRaceAsianIndian || false,
          Chinese: data?.demographicInfoBorrower?.borrowerRaceChinese || false,
          Filipino: data?.demographicInfoBorrower?.borrowerRaceFilipino || false,
          Japanese: data?.demographicInfoBorrower?.borrowerRaceJapanese || false,
          Korean: data?.demographicInfoBorrower?.borrowerRaceKorean || false,
          Vietnamese: data?.demographicInfoBorrower?.borrowerRaceVietnamese || false,
          OtherAsian: data?.demographicInfoBorrower?.borrowerRaceOtherAsian || false,
          OtherAsianText: '',
          BlackAfricanAmerican: data?.demographicInfoBorrower?.borrowerRaceBlackAfricanAmerican || false,
          HawaiianPacificIslander: data?.demographicInfoBorrower?.borrowerRaceHawaiianPacificIslander || false,
          NativeHawaiian: data?.demographicInfoBorrower?.borrowerRaceNativeHawaiian || false,
          GuamanianChamorro: data?.demographicInfoBorrower?.borrowerRaceGuamanianChamorro || false,
          Samoan: data?.demographicInfoBorrower?.borrowerRaceSamoan || false,
          OtherHawaiianPacific: data?.demographicInfoBorrower?.borrowerRaceOtherHawaiianPacific || false,
          OtherHawaiianPacificText: '',
          White: data?.demographicInfoBorrower?.borrowerRaceWhite || false,
          WishNotToProvide: data?.demographicInfoBorrower?.borrowerRaceWishNotToProvide || false,
        },

        // borrower demographic info / observations
        ObservedBorrowerEthnicity: data?.demographicInfoBorrower?.borrowerObservedEthnicity || false,
        ObservedBorrowerSex: data?.demographicInfoBorrower?.borrowerObservedSex || false,
        ObservedBorrowerRace: data?.demographicInfoBorrower?.borrowerObservedRace || false,

        /**
         *
         * co-borrower demographic info data / start
         *
         */

        // co-borrower demographic info / ethnicity
        CoBorrowerEthnicity: {
          HispanicLatino: data?.demographicInfoCoBorrower?.coBorrowerEthnicityHispanicLatino || '',
          Mexican: data?.demographicInfoCoBorrower?.coBorrowerEthnicityMexican || '',
          PuertoRican: data?.demographicInfoCoBorrower?.coBorrowerEthnicityPuertoRican || '',
          Cuban: data?.demographicInfoCoBorrower?.coBorrowerEthnicityCuban || '',
          OtherHispanicLatino: data?.demographicInfoCoBorrower?.coBorrowerEthnicityOtherHispanicLatino || '',
          OtherHispanicLatinoText: '',
          NotHispanicLatino: data?.demographicInfoCoBorrower?.coBorrowerEthnicityNotHispanicLatino || '',
          WishNotToProvide: data?.demographicInfoCoBorrower?.coBorrowerEthnicityWishNotToProvide || '',
        },

        // co-borrower demographic info / sex
        CoBorrowerSex: {
          Female: data?.demographicInfoCoBorrower?.coBorrowerSexFemale || '',
          Male: data?.demographicInfoCoBorrower?.coBorrowerSexMale || '',
          WishNotToProvide: data?.demographicInfoCoBorrower?.coBorrowerSexWishNotToProvide || '',
        },

        // co-borrower demographic info / race
        CoBorrowerRace: {
          AmericanIndianAlaskaNative: data?.demographicInfoCoBorrower?.coBorrowerRaceAmericanIndianAlaskaNative || '',
          AmericanIndianAlaskaNativeText: '',
          Asian: data?.demographicInfoCoBorrower?.coBorrowerRaceAsian || '',
          AsianIndian: data?.demographicInfoCoBorrower?.coBorrowerRaceAsianIndian || '',
          Chinese: data?.demographicInfoCoBorrower?.coBorrowerRaceChinese || '',
          Filipino: data?.demographicInfoCoBorrower?.coBorrowerRaceFilipino || '',
          Japanese: data?.demographicInfoCoBorrower?.coBorrowerRaceJapanese || '',
          Korean: data?.demographicInfoCoBorrower?.coBorrowerRaceKorean || '',
          Vietnamese: data?.demographicInfoCoBorrower?.coBorrowerRaceVietnamese || '',
          OtherAsian: data?.demographicInfoCoBorrower?.coBorrowerRaceOtherAsian || '',
          OtherAsianText: '',
          BlackAfricanAmerican: data?.demographicInfoCoBorrower?.coBorrowerRaceBlackAfricanAmerican || '',
          HawaiianPacificIslander: data?.demographicInfoCoBorrower?.coBorrowerRaceHawaiianPacificIslander || '',
          NativeHawaiian: data?.demographicInfoCoBorrower?.coBorrowerRaceNativeHawaiian || '',
          GuamanianChamorro: data?.demographicInfoCoBorrower?.coBorrowerRaceGuamanianChamorro || '',
          Samoan: data?.demographicInfoCoBorrower?.coBorrowerRaceSamoan || '',
          OtherHawaiianPacific: data?.demographicInfoCoBorrower?.coBorrowerRaceOtherHawaiianPacific || '',
          OtherHawaiianPacificText: '',
          White: data?.demographicInfoCoBorrower?.coBorrowerRaceWhite || '',
          WishNotToProvide: data?.demographicInfoCoBorrower?.coBorrowerRaceWishNotToProvide || '',
        },

        /**
         *
         * co-borrower demographic info / sub-section : Observations
         *
         * If the co-borrower data is !available => the demographic co-borrower info page is also !available
         *  => if the demographic co-borrower info page is !available => the data should be sent as '' a.k.a. blank values
         *
         * type of elements :
         *    switch => values : on/off a.k.a. true/false
         *
         * If the co-borrower data is available, the data should be sent as boolean values.
         *
         * If the co-borrower data is !available, the data should be sent as '' a.k.a. blank spaces.
         *
         * The data is stored within the redux store will be used.
         *
         */

        ObservedCoBorrowerEthnicity: '',
        ObservedCoBorrowerSex: '',
        ObservedCoBorrowerRace: '',

        /**
         *
         * co-borrower demographic info data / stop
         *
         */

        /**
         *
         * demographic info data / stop
         *
         */

        /** ********** ********** ********** ********** **********
         *
         * "declarations" page / start
         *
         ********** ********** ********** ********** ********** */

        /**
         *
         * declarations "borrower" params / start
         *
         */

        /** outstanding judgement ( default & 1009 ) */

        outstandingJudgement: data?.declarations?.borrowerOutstandingJudgement || false,

        /** declared bankruptcy ( default & 1009 ) */

        declaredBankruptcy: data?.declarations?.borrowerDeclaredBankruptcy || false,

        /** declared foreclosure ( default ) */

        declaredForeclosure: data?.declarations?.borrowerDeclaredForeclosure || false,

        /** lawsuit party ( default & 1009 ) */

        lawsuitParty: data?.declarations?.borrowerLawsuitParty || false,

        /** loan obligation */

        loanObligation: data?.declarations?.borrowerLoanObligation || false,

        /**
         *
         * - loan delinquency ( default & 1009 )
         *    - date ( 1009 )
         *    - lender name ( 1009 )
         *    - lender address ( 1009 )
         *    - FHA case number ( 1009 )
         *    - reason ( 1009 )
         *
         */

        // main switch question field
        loanDelinquency: data?.declarations?.borrowerLoanDelinquency || false,
        // sub-options fields
        loanDelinquencyDate: data?.declarations?.borrowerLoanDelinquencyDate || '',
        loanDelinquencyLenderName: data?.declarations?.borrowerLoanDelinquencyLenderName || '',
        loanDelinquencyLenderAddress: data?.declarations?.borrowerLoanDelinquencyLenderAddress || '',
        loanDelinquencyFHACaseNumber: data?.declarations?.borrowerLoanDelinquencyFHACaseNumber || '',
        loanDelinquencyReason: data?.declarations?.borrowerLoanDelinquencyReason || '',

        /** alimony obligation ( default ) */

        alimonyObligation: data?.declarations?.borrowerAlimonyObligation || false,

        /**
         *
         * - down payment required ( 1009 )
         * - down payment borrowed ( default & 1009 )
         *
         */

        downPaymentRequired: data?.declarations?.borrowerDownPaymentRequired || false,
        downPaymentBorrowed: data?.declarations?.borrowerDownPaymentBorrowed || false,

        /** note endorser ( default & 1009 ) */

        noteEndorser: data?.declarations?.borrowerNoteEndorser || false,

        /** U.S. citizen ( default & 1009 ) */

        usCitizen: data?.declarations?.borrowerUsCitizen || false,

        /** resident alien ( default & 1009 )  */

        residentAlien: data?.declarations?.borrowerResidentAlien || false,

        /** non-resident alien ( default ) */

        nonResidentAlien: data?.declarations?.borrowerNonResidentAlien || false,

        /** immigration status ( default ) */

        immigrationStatus: '',

        /** property occupation ( default & 1009 ) */

        propertyOccupation: data?.declarations?.borrowerPropertyOccupation || false,

        /**
         *
         * - property ownership interest ( default )
         *    - property type ( default )
         *    - title hold( default )
         *
         */

        propertyOwnershipInterest: data?.declarations?.borrowerPropertyOwnershipInterest || false,
        propertyOwnershipInterestPropertyType: '',
        propertyOwnershipInterestTitleHold: '',

        /**
         *
         * - reverse mortgage investment ( 1009 )
         *    - product name ( 1009 )
         *    - product cost ( 1009 )
         *
         */

        // main switch question field
        reverseMortgageInvestment: data?.declarations?.borrowerReverseMortgageInvestment || false,
        // sub-options fields
        reverseMortgageInvestmentProductName: data?.declarations?.borrowerReverseMortgageInvestmentProductName || '',
        reverseMortgageInvestmentProductCost: data?.declarations?.borrowerReverseMortgageInvestmentProductCost || '',

        /**
         *
         * - has existing FHA insurance loan ( default & 1009 )
         *    - existing FHA insured loan ( sub options )
         *      - creditor name ( default & 1009 )
         *      - street ( 1009 )
         *      - city ( 1009 )
         *      - state ( 1009 )
         *      - zip code ( 1009 )
         *      - account number ( 1009 )
         *      - disposition type ( 1009 )
         *      - mortgage amount ( 1009 )
         *      - loan balance ( 1009 )
         *
         */

        // main switch question field
        hasExistingFHAInsuredLoan: data?.declarations?.borrowerHasExistingFHAInsuredLoan || false,
        // sub-options fields
        existingFHAInsuredLoanCreditorName: '',
        existingFHAInsuredLoanStreet: '',
        existingFHAInsuredLoanCity: '',
        existingFHAInsuredLoanState: '',
        existingFHAInsuredLoanZipCode: '',
        existingFHAInsuredLoanAccountNumber: '',
        existingFHAInsuredLoanDispositionType: '',
        existingFHAInsuredLoanMortgageAmount: '',
        existingFHAInsuredLoanUnpaidLoanBalance: '',

        /**
         *
         * declarations "borrower" section / stop
         *
         ********** ********** ********** ********** ********** */

        /** ********** ********** ********** ********** **********
         *
         * declarations "co-borrower" section / start
         *
         */

        /**
         *
         * The co-borrower data might be missing.
         *
         * If the co-borrower data is missing, all the co-borrower data should be sent as 'blank', aka '' values.
         *
         * This is where & how we make sure that we send the co-borrower values as blank, right before the submit.
         *
         */

        /** outstanding judgement ( default & 1009 ) */

        coBorrowerOutstandingJudgement: '',

        /** declared bankruptcy ( default & 1009 ) */

        coBorrowerDeclaredBankruptcy: '',

        /** declared foreclosure ( default ) */

        coBorrowerDeclaredForeclosure: '',

        /** lawsuit party ( default & 1009 ) */

        coBorrowerLawsuitParty: '',

        /** loan obligation ( default ) */

        coBorrowerLoanObligation: '',

        /**
         *
         * - loan delinquency ( default & 1009 )
         *    - date ( 1009 )
         *    - lender name ( 1009 )
         *    - lender address ( 1009 )
         *    - FHA case number ( 1009 )
         *    - reason ( 1009 )
         *
         */

        // main switch question field
        coBorrowerLoanDelinquency: '',
        // sub-options fields
        coBorrowerLoanDelinquencyDate: '',
        coBorrowerLoanDelinquencyLenderName: '',
        coBorrowerLoanDelinquencyLenderAddress: '',
        coBorrowerLoanDelinquencyFHACaseNumber: '',
        coBorrowerLoanDelinquencyReason: '',

        /** alimony obligation ( default ) */

        coBorrowerAlimonyObligation: '',

        /**
         *
         * - down payment required ( 1009 )
         * - down payment borrowed ( default & 1009 )
         *
         */

        coBorrowerDownPaymentRequired: '',
        coBorrowerDownPaymentBorrowed: '',

        /** note endorser ( default & 1009 ) */

        coBorrowerNoteEndorser: '',

        /** U.S. citizen ( default & 1009 ) */

        coBorrowerUsCitizen: '',

        /** resident alien ( default & 1009 ) */

        coBorrowerResidentAlien: '',

        /** non-resident alien ( default ) */

        coBorrowerNonResidentAlien: '',

        /** immigration status ( default ) */

        coBorrowerImmigrationStatus: '',

        /** property occupation ( default & 1009 ) */

        coBorrowerPropertyOccupation: '',

        /**
         *
         * - property ownership interest ( default )
         *    - property type ( default )
         *    - title hold ( default )
         *
         */

        coBorrowerPropertyOwnershipInterest: '',
        coBorrowerPropertyOwnershipInterestPropertyType: '',
        coBorrowerPropertyOwnershipInterestTitleHold: '',

        /**
         *
         * - reverse mortgage investment ( 1009 )
         *    - product name ( 1009 )
         *    - product cost ( 1009 )
         *
         */

        coBorrowerReverseMortgageInvestment: '',
        coBorrowerReverseMortgageInvestmentProductName: '',
        coBorrowerReverseMortgageInvestmentProductCost: '',

        /**
         *
         *  - has existing FHA insurance loan ( default & 1009 )
         *    - existing FHA insured loan ( sub options )
         *      - creditor name ( default & 1009 )
         *      - street ( 1009 )
         *      - city ( 1009 )
         *      - state ( 1009 )
         *      - zip code ( 1009 )
         *      - account number ( 1009 )
         *      - disposition type ( 1009 )
         *      - mortgage amount ( 1009 )
         *      - loan balance ( 1009 )
         *
         */

        // main switch question field
        coBorrowerHasExistingFHAInsuredLoan: '',
        // sub-options fields
        coBorrowerExistingFHAInsuredLoanCreditorName: '',
        coBorrowerExistingFHAInsuredLoanStreet: '',
        coBorrowerExistingFHAInsuredLoanCity: '',
        coBorrowerExistingFHAInsuredLoanState: '',
        coBorrowerExistingFHAInsuredLoanZipCode: '',
        coBorrowerExistingFHAInsuredLoanAccountNumber: '',
        coBorrowerExistingFHAInsuredLoanDispositionType: '',
        coBorrowerExistingFHAInsuredLoanMortgageAmount: '',
        coBorrowerExistingFHAInsuredLoanUnpaidLoanBalance: '',

        /**
         *
         * declarations "co-borrower" section / stop
         *
         ********** ********** ********** ********** ********** */

        /** ********** ********** ********** ********** **********
         *
         * "declarations" page / stop
         *
         ********** ********** ********** ********** ********** */
      };

      /**
       *
       * ibData / stop
       *
       */

      /** ********** ********** ********** ********** **********
       *
       * `property taxes` page
       *
       ********** ********** ********** ********** ********** */

      if (
        ibData.propertyType === PUD_ATTACHED ||
        ibData.propertyType === PUD_DETACHED ||
        ibData.propertyType === CONDO ||
        ibData.propertyType === SITE_CONDOMINIUM ||
        ibData.propertyType === TOWNHOUSE
      ) {
        ibData.HOAMonthlyFees = data?.propertyTaxes?.hoaMonthlyFees;
      }

      // the "First Mortgage" attr might not be present within the request, if the product is not "HomeSafe Second"
      if (data?.property?.propertyFirstLien) {
        ibData.FirstMortgage = data?.property?.propertyFirstLien;
      }

      /** ********** ********** ********** ********** **********
       *
       * `demographic` info pages
       *
       ********** ********** ********** ********** ********** */

      /**
       *
       * verify and complete the `borrower demographic info` data
       *
       */

      // Other Hispanic Latino Text
      if (
        typeof ibData.BorrowerEthnicity.OtherHispanicLatino === 'boolean' &&
        ibData.BorrowerEthnicity.OtherHispanicLatino === true
      ) {
        ibData.BorrowerEthnicity.OtherHispanicLatinoText =
          data?.demographicInfoBorrower?.borrowerEthnicityOtherHispanicLatinoText;
      }

      // American Indian Alaska Native Text
      if (
        typeof ibData.BorrowerRace.AmericanIndianAlaskaNative === 'boolean' &&
        ibData.BorrowerRace.AmericanIndianAlaskaNative === true
      ) {
        ibData.BorrowerRace.AmericanIndianAlaskaNativeText =
          data?.demographicInfoBorrower?.borrowerRaceAmericanIndianAlaskaNativeText;
      }

      // Other Asian Text
      if (typeof ibData.BorrowerRace.OtherAsian === 'boolean' && ibData.BorrowerRace.OtherAsian === true) {
        ibData.BorrowerRace.OtherAsianText = data?.demographicInfoBorrower?.borrowerRaceOtherAsianText;
      }

      // Other Hawaiian Pacific Text
      if (
        typeof ibData.BorrowerRace.OtherHawaiianPacific === 'boolean' &&
        ibData.BorrowerRace.OtherHawaiianPacific === true
      ) {
        ibData.BorrowerRace.OtherHawaiianPacificText =
          data?.demographicInfoBorrower?.borrowerRaceOtherHawaiianPacificText;
      }

      /**
       *
       * verify and complete the `co-borrower demographic info` data
       *
       */

      // Other Hispanic Latino Text
      if (
        typeof ibData.CoBorrowerEthnicity.OtherHispanicLatino === 'boolean' &&
        ibData.CoBorrowerEthnicity.OtherHispanicLatino === true
      ) {
        ibData.CoBorrowerEthnicity.OtherHispanicLatinoText =
          data?.demographicInfoCoBorrower?.coBorrowerEthnicityOtherHispanicLatinoText;
      }

      // American Indian Alaska Native Text
      if (
        typeof ibData.CoBorrowerRace.AmericanIndianAlaskaNative === 'boolean' &&
        ibData.CoBorrowerRace.AmericanIndianAlaskaNative === true
      ) {
        ibData.CoBorrowerRace.AmericanIndianAlaskaNativeText =
          data?.demographicInfoCoBorrower?.coBorrowerRaceAmericanIndianAlaskaNativeText;
      }

      // Other Asian Text
      if (typeof ibData.CoBorrowerRace.OtherAsian === 'boolean' && ibData.CoBorrowerRace.OtherAsian === true) {
        ibData.CoBorrowerRace.OtherAsianText = data?.demographicInfoCoBorrower?.coBorrowerRaceOtherAsianText;
      }

      // Other Hawaiian Pacific Text
      if (
        ibData.CoBorrowerRace.OtherHawaiianPacific === 'boolean' &&
        ibData.CoBorrowerRace.OtherHawaiianPacific === true
      ) {
        ibData.CoBorrowerRace.OtherHawaiianPacificText =
          data?.demographicInfoCoBorrower?.coBorrowerRaceOtherHawaiianPacificText;
      }

      // observations co-borrower
      if (ibData.coBorrowerFirstName !== '') {
        ibData.ObservedCoBorrowerEthnicity = data?.demographicInfoCoBorrower?.coBorrowerObservedEthnicity;
        ibData.ObservedCoBorrowerSex = data?.demographicInfoCoBorrower?.coBorrowerObservedSex;
        ibData.ObservedCoBorrowerRace = data?.demographicInfoCoBorrower?.coBorrowerObservedRace;
      }

      /** ********** ********** ********** ********** **********
       *
       * The `Declarations` page attributes mapping.
       *
       ********** ********** ********** ********** ********** */

      /**
       *
       * The borrower data mapping.
       *
       */

      if (ibData.nonResidentAlien === 'true') {
        ibData.immigrationStatus = data?.declarations?.borrowerImmigrationStatus;
      }

      if (ibData.propertyOwnershipInterest === 'true') {
        /** property ownership interest property type */
        ibData.propertyOwnershipInterestPropertyType =
          data?.declarations?.borrowerPropertyOwnershipInterestPropertyType;
        /** property ownership interest title hold */
        ibData.propertyOwnershipInterestTitleHold = data?.declarations?.borrowerPropertyOwnershipInterestTitleHold;
      }

      if (ibData.hasExistingFHAInsuredLoan === 'true') {
        ibData.existingFHAInsuredLoanCreditorName = data?.declarations?.borrowerExistingFHAInsuredLoanCreditorName;
        ibData.existingFHAInsuredLoanStreet = data?.declarations?.borrowerExistingFHAInsuredLoanStreet;
        ibData.existingFHAInsuredLoanCity = data?.declarations?.borrowerExistingFHAInsuredLoanCity;
        ibData.existingFHAInsuredLoanState = data?.declarations?.borrowerExistingFHAInsuredLoanState;
        ibData.existingFHAInsuredLoanZipCode = data?.declarations?.borrowerExistingFHAInsuredLoanZipCode;
        ibData.existingFHAInsuredLoanAccountNumber = data?.declarations?.borrowerExistingFHAInsuredLoanAccountNumber;
        ibData.existingFHAInsuredLoanDispositionType =
          data?.declarations?.borrowerExistingFHAInsuredLoanDispositionType;
        ibData.existingFHAInsuredLoanMortgageAmount = data?.declarations?.borrowerExistingFHAInsuredLoanMortgageAmount;
        ibData.existingFHAInsuredLoanUnpaidLoanBalance =
          data?.declarations?.borrowerExistingFHAInsuredLoanUnpaidLoanBalance;
      }

      /**
       *
       * The co-borrower data mapping.
       *
       * The co-borrower data might be missing.
       *
       * If the co-borrower data is missing:
       *  - all the co-borrower data should be sent as 'blank', aka '' values
       *
       * If the co-borrower data is available:
       *  - the co-borrower data for switch elements should be sent as 'boolean' values
       *  - the co-borrower data for text elements should be sent as 'string' values
       *
       */

      if (ibData.coBorrowerFirstName !== '') {
        /**
         *
         * The co-borrower data exists.
         *
         */

        /**
         *
         * STEP 1/2
         *
         * !!! Set value only to the parent attributes. !!!
         *
         */

        /** co-borrower outstanding judgement ( default & 1009 ) */
        ibData.coBorrowerOutstandingJudgement = data?.declarations?.coBorrowerOutstandingJudgement;

        /** co-borrower declared bankruptcy ( default & 1009 ) */
        ibData.coBorrowerDeclaredBankruptcy = data?.declarations?.coBorrowerDeclaredBankruptcy;

        /** co-borrower declared foreclosure ( default ) */
        ibData.coBorrowerDeclaredForeclosure = data?.declarations?.coBorrowerDeclaredForeclosure;

        /** co-borrower lawsuit party ( default & 1009 ) */
        ibData.coBorrowerLawsuitParty = data?.declarations?.coBorrowerLawsuitParty;

        /** co-borrower loan obligation ( default ) */
        ibData.coBorrowerLoanObligation = data?.declarations?.coBorrowerLoanObligation;

        /** co-borrower loan delinquency ( default & 1009 ) */
        ibData.coBorrowerLoanDelinquency = data?.declarations?.coBorrowerLoanDelinquency;

        /** co-borrower alimony obligation ( default ) */
        ibData.coBorrowerAlimonyObligation = data?.declarations?.coBorrowerAlimonyObligation;

        /** co-borrower property occupation ( default & 1009 ) */
        ibData.coBorrowerPropertyOccupation = data?.declarations?.coBorrowerPropertyOccupation;

        /** co-borrower note endorser ( default & 1009 ) */
        ibData.coBorrowerNoteEndorser = data?.declarations?.coBorrowerNoteEndorser;

        /** co-borrower U.S. citizen ( default & 1009 ) */
        ibData.coBorrowerUsCitizen = data?.declarations?.coBorrowerUsCitizen;

        /** co-borrower resident alien ( default & 1009 ) */
        ibData.coBorrowerResidentAlien = data?.declarations?.coBorrowerResidentAlien;

        /** co-borrower non resident alien ( default ) */
        ibData.coBorrowerNonResidentAlien = data?.declarations?.coBorrowerNonResidentAlien;

        /** co-borrower ownership interest ( default ) */
        ibData.coBorrowerPropertyOwnershipInterest = data?.declarations?.coBorrowerPropertyOwnershipInterest;

        /**
         *
         * product !== HomeSafe Second
         *
         */

        if (data?.ApiRoute !== API_ROUTES.SECOND) {
          /** co-borrower down payment borrowed ( dedicated for default; !1009 ) */
          ibData.coBorrowerDownPaymentBorrowed = data?.declarations?.coBorrowerDownPaymentBorrowed;
        }

        /**
         *
         * product === HomeSafe Second
         *
         */

        if (data?.ApiRoute === API_ROUTES.SECOND) {
          /** co-borrower down payment required ( dedicated for 1009; !default ) */
          ibData.coBorrowerDownPaymentRequired = data?.declarations?.coBorrowerDownPaymentRequired;
          /** co-borrower reverse mortgage investment ( dedicated for 1009; !default ) */
          ibData.coBorrowerReverseMortgageInvestment = data?.declarations?.coBorrowerReverseMortgageInvestment;
        }

        /** co-borrower has existing FHA insured loan ( default & 1009 )*/
        ibData.coBorrowerHasExistingFHAInsuredLoan = data?.declarations?.coBorrowerHasExistingFHAInsuredLoan;
        /** co-borrower existing FHA insured loan creditor name( default; 1009 ) */
        ibData.coBorrowerExistingFHAInsuredLoanCreditorName =
          data?.declarations?.coBorrowerExistingFHAInsuredLoanCreditorName;

        /**
         *
         * STEP 2/2
         *
         * !!! Set value only to the child attributes. !!!
         *
         */

        if (ibData.coBorrowerFirstName !== '' && ibData.coBorrowerNonResidentAlien === 'true') {
          /** co-borrower immigration status */
          ibData.coBorrowerImmigrationStatus = data?.declarations?.coBorrowerImmigrationStatus;
        }

        if (ibData.coBorrowerFirstName !== '' && ibData.coBorrowerPropertyOwnershipInterest === 'true') {
          /** co-borrower property ownership interest property type ( default ) */
          ibData.coBorrowerPropertyOwnershipInterestPropertyType =
            data?.declarations?.coBorrowerPropertyOwnershipInterestPropertyType;
          /** co-borrower property ownership interest title hold ( default ) */
          ibData.coBorrowerPropertyOwnershipInterestTitleHold =
            data?.declarations?.coBorrowerPropertyOwnershipInterestTitleHold;
        }

        // only deal with the dedicated sub-attributes for product HomeSafe Second / start
        if (data?.ApiRoute === API_ROUTES.SECOND) {
          // the co-borrower loan delinquency sub-fields /start
          if (ibData.coBorrowerLoanDelinquency === 'true') {
            /** co-borrower loan delinquency date ( dedicated for 1009; !default ) */
            ibData.coBorrowerLoanDelinquencyDate = data?.declarations?.coBorrowerLoanDelinquencyDate;
            /** co-borrower loan delinquency lender name ( dedicated for 1009; !default ) */
            ibData.coBorrowerLoanDelinquencyLenderName = data?.declarations?.coBorrowerLoanDelinquencyLenderName;
            /** co-borrower loan delinquency lender address ( dedicated for 1009; !default ) */
            ibData.coBorrowerLoanDelinquencyLenderAddress = data?.declarations?.coBorrowerLoanDelinquencyLenderAddress;
            /** co-borrower loan delinquency FHA case number ( dedicated for 1009; !default ) */
            ibData.coBorrowerLoanDelinquencyFHACaseNumber = data?.declarations?.coBorrowerLoanDelinquencyFHACaseNumber;
            /** co-borrower loan delinquency reason ( dedicated for 1009; !default ) */
            ibData.coBorrowerLoanDelinquencyReason = data?.declarations?.coBorrowerLoanDelinquencyReason;
          }
          // the co-borrower loan delinquency sub-fields / stop
          //
          // the co-borrower down payment sub-fields / start
          if (ibData.coBorrowerDownPaymentRequired === 'true') {
            /** co-borrower down payment borrowed ( dedicated for 1009; !default ) */
            ibData.coBorrowerDownPaymentBorrowed = data?.declarations?.coBorrowerDownPaymentBorrowed;
          }
          // the co-borrower down payment sub-fields / stop
          //
          // the co-borrower reverse mortgage investment fields / start
          if (ibData.coBorrowerReverseMortgageInvestment === 'true') {
            /** co-borrower reverse mortgage investment product name ( dedicated for 1009; !default )*/
            ibData.coBorrowerReverseMortgageInvestmentProductName =
              data?.declarations?.coBorrowerReverseMortgageInvestmentProductName;
            /** co-borrower reverse mortgage investment product cost ( dedicated for 1009; !default ) */
            ibData.coBorrowerReverseMortgageInvestmentProductCost =
              data?.declarations?.coBorrowerReverseMortgageInvestmentProductCost;
          }
          // the co-borrower reverse mortgage investment fields / stop
          //
          // the co-borrower has existing FHA insured loan / start
          if (ibData.coBorrowerHasExistingFHAInsuredLoan === 'true') {
            /** co-borrower existing FHA insured loan street ( dedicated for 1009; !default ) */
            ibData.coBorrowerExistingFHAInsuredLoanStreet = data?.declarations?.coBorrowerExistingFHAInsuredLoanStreet;
            /** co-borrower existing FHA insured loan city ( dedicated for 1009; !default ) */
            ibData.coBorrowerExistingFHAInsuredLoanCity = data?.declarations?.coBorrowerExistingFHAInsuredLoanCity;
            /** co-borrower existing FHA insured loan state ( dedicated for 1009; !default ) */
            ibData.coBorrowerExistingFHAInsuredLoanState = data?.declarations?.coBorrowerExistingFHAInsuredLoanState;
            /** co-borrower existing FHA insured loan zip code( dedicated for 1009; !default ) */
            ibData.coBorrowerExistingFHAInsuredLoanZipCode =
              data?.declarations?.coBorrowerExistingFHAInsuredLoanZipCode;
            /** co-borrower existing FHA insured loan account number ( dedicated for 1009; !default ) */
            ibData.coBorrowerExistingFHAInsuredLoanAccountNumber =
              data?.declarations?.coBorrowerExistingFHAInsuredLoanAccountNumber;
            /** co-borrower existing FHA insured loan disposition type ( dedicated for 1009; !default ) */
            ibData.coBorrowerExistingFHAInsuredLoanDispositionType =
              data?.declarations?.coBorrowerExistingFHAInsuredLoanDispositionType;
            /** co-borrower existing FHA insured loan mortgage amount ( dedicated for 1009; !default ) */
            ibData.coBorrowerExistingFHAInsuredLoanMortgageAmount =
              data?.declarations?.coBorrowerExistingFHAInsuredLoanMortgageAmount;
            /** co-borrower existing FHA insured loan unpaid balance ( dedicated for 1009; !default ) */
            ibData.coBorrowerExistingFHAInsuredLoanUnpaidLoanBalance =
              data?.declarations?.coBorrowerExistingFHAInsuredLoanUnpaidLoanBalance;
          }
          // the co-borrower has existing FHA insured loan / stop
        }
        // only deal with the dedicated sub-attributes for product HomeSafe Second / stop

        /** end of co-borrower data */
      }

      /** ********** ********** ********** ********** **********
       *
       * Illustrator Borrower data save
       *
       ********** ********** ********** ********** ********** */

      return new Promise(
        (resolve, reject) => {
          IllustratorBorrowerServiceApi.save(ibData)
            .then((response) => {
              return resolve(response);
            })
            .catch((e) => {
              return reject(e);
            });
        }, // end of : executor
      ); // end of : new Promise
    }, // end of : mutation fn
  ); // end of : useMutation
}
