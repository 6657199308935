import React from 'react';
import {createGenerateClassName, StylesProvider as MuiStyleProvider} from '@material-ui/core';
import {isTest} from 'constants/app';

StylesProvider.propTypes = {};

const generateClassName = isTest
  ? (rule, styleSheet) => `${styleSheet.options.classNamePrefix}-${rule.key}`
  : createGenerateClassName({});

function StylesProvider(props) {
  const {children} = props;

  return <MuiStyleProvider generateClassName={generateClassName}>{children}</MuiStyleProvider>;
}

export default StylesProvider;
