import React, {useCallback} from 'react';
import {Autocomplete as AutocompleteMui} from '@mui/material';
import {useGetSmartStreetsDataQuery} from 'query/smarty-streets/get-smarty-streets-data';
import {useTranslation} from 'lib/i18n';
import {ToString} from 'lib/utils';
import TextField from 'components/common/form/input/components/TextField';
import {merge, noop} from 'lodash';
import clsx from 'clsx';
import {useEnabledProductFeatures} from 'hooks/product-features';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {withGeneratedId} from 'lib/generator';

const SmartyAutoComplete = React.forwardRef(function SmartyAutoCompleteRender(props, ref) {
  const {
    id,
    value,
    label,
    name,
    variant,
    className: moduleClassName,
    InputLabelProps: moduleInputLabelProps,
    InputProps: moduleInputProps,
    onChange: moduleOnInputChange = noop,
    onChangeCommitted = noop,
    onClear = noop,
    onFilterOptions,
    error,
    helperText,
    defaultValue,

    ...rest
  } = props;

  const {isEnabled} = useEnabledProductFeatures(PRODUCT_FEATURE_KEY.ADDRESS_AUTOCOMPLETE);

  const {data: {suggestions: options = [], selectedValue = {}} = {}} = useGetSmartStreetsDataQuery({
    search: value,
    enabled: isEnabled,
    defaultValue,
    onFilterOptions,
  });

  const {t} = useTranslation();

  const onChange = useCallback(
    (event, value, reason) => {
      if (reason === 'clear' && !event.isPropagationStopped()) {
        onClear(event, value, reason);
      } else if (reason === 'selectOption') {
        onChangeCommitted(event, value, reason);
      }
    },
    [onClear, onChangeCommitted],
  );

  const onInputChange = useCallback(
    (event, value, reason) => {
      if (reason === 'input') {
        if (value === '') event.stopPropagation();

        moduleOnInputChange(event, value);
      } else if (reason === 'clear') {
        moduleOnInputChange(event, value);
      }
    },

    [moduleOnInputChange],
  );

  if (!isEnabled) {
    return (
      <TextField
        id={id}
        value={value}
        label={label}
        name={name}
        variant={variant}
        className={moduleClassName}
        InputLabelProps={moduleInputLabelProps}
        InputProps={moduleInputProps}
        onChange={moduleOnInputChange}
        error={error}
        helperText={helperText}
        {...rest}
      />
    );
  }

  return (
    <AutocompleteMui
      freeSolo
      clearOnBlur={false}
      autoSelect
      id={id}
      value={selectedValue}
      inputValue={value}
      autoHighlight={false}
      selectOnFocus={false}
      openOnFocus={false}
      loadingText={t('smartyAutoCompleteTranslation:mui-autocomplete-loading')}
      noOptionsText={t('smartyAutoCompleteTranslation:mui-autocomplete-no options')}
      clearText={t('smartyAutoCompleteTranslation:mui-autocomplete-clear-text')}
      openText={t('smartyAutoCompleteTranslation:mui-autocomplete-open-text')}
      onChange={onChange}
      onInputChange={onInputChange}
      isOptionEqualToValue={(option, value) => option.Address === value.Address}
      getOptionLabel={(option) => ToString(option?.Address)}
      options={options}
      renderInput={({InputProps, InputLabelProps, inputProps, ...params}) => {
        return (
          <TextField
            {...rest}
            {...params}
            variant={variant}
            className={clsx(moduleClassName, moduleInputProps?.className)}
            label={label}
            name={name}
            InputLabelProps={merge({...InputLabelProps}, {...moduleInputLabelProps})}
            InputProps={merge({...InputProps}, {...moduleInputProps})}
            inputProps={merge({...inputProps}, {autoComplete: 'smarty-auto-complete'})}
            error={error}
            helperText={helperText}
          />
        );
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={props.id}>
            {option?.Address + ', ' + option?.City + ', ' + option?.State + ', ' + option?.ZipCode}
          </li>
        );
      }}
    />
  );
});

export default withGeneratedId(SmartyAutoComplete);
