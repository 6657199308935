import React from 'react';
import {useTranslation} from 'lib/i18n';
import {useBooleanToString} from 'hooks/currency';

export function useFlexOverallResults(props = {}) {
  const {
    product: {
      ProductName,
      calculation: {
        BorrowerProfile: {
          Age,
          DateOfBirth,
          Liens,
          ValueOfProperty,
          ExistingMortgage: {TaxesAndInsuranceReserve} = {},
        } = {},
        PrincipalLimit,
        ClosingCost,
        CashAtClose,
        FundAccess,
        Lesa,
        OverrideClosingCosts,
        RemainingPrincipalLimit,
        Rate,
        ServiceFee,
        HomeSafeTier,
      } = {},
    } = {},
  } = props;
  const {t} = useTranslation();

  const {booleanToString} = useBooleanToString();

  return {};
}
