import React, {useContext} from 'react';

const recommendedProductContext = {};
const RecommendedProductContext = React.createContext(recommendedProductContext);

export function useRecommendedProductContext(props = {}) {
  return useContext(RecommendedProductContext);
}

/**
 *
 * @param {object} props
 * @param {React.ReactNode|(()=>React.ReactNode)} props.children
 * @return {React.ReactNode}
 * @constructor
 */
export function RecommendedProductContextProvider(props = {}) {
  const {children, ...rest} = props;

  return <RecommendedProductContext.Provider value={rest}>{children}</RecommendedProductContext.Provider>;
}
