import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import useStyles from './ListSubElementsStyles';
import clsx from 'clsx';
import ListItemText from 'components/common/list/ListItemText';
import useColor from 'hooks/use-color';
import Item from 'components/common/layout/item/Item';

/**
 *
 * list Y sub elements of Xi main element
 *
 * "Home Value" ... $ 123,456.78
 * ...
 * "Fund Access" ... Lump Sum
 *
 */

function ListYSubElementsOfXiMainElement(props) {
  const {data = [], className, deep = 1} = props;
  const classes = useStyles({deep});
  const colorClasses = useColor();

  return (
    <React.Fragment>
      {data.map((value, index) => {
        return (
          <React.Fragment key={index}>
            {value.subSection?.length > 0 ? (
              <Item xs={12}>
                <ListItemText primary={value.label} className={clsx(classes.text2left, classes.listItemText)} />
              </Item>
            ) : (
              <ListItem 
                role={undefined} 
                className={clsx(classes.paymentBreakdownListItem, className, {[classes.highlight]: value.highlight})}
              >
                  <ListItemText
                    label={value.label}
                    tooltip={value.tooltip}
                    className={clsx(
                      classes.flexAlign,
                      classes.text2left,
                      classes.listSubElement,
                      colorClasses[value.useColorOptions?.label],
                      {[classes.bold]: value.highlight}
                    )}
                    id={'label_' + index}
                  />

                  <ListItemText
                    label={value.value}
                    className={clsx(
                      classes.flexAlign,
                      classes.text2right,
                      classes.listSubElement,
                      colorClasses[value.useColorOptions?.value],
                      {[classes.bold]: value.highlight}
                    )}
                    id={'value_' + index}
                    data-testid={`list-item-value-${value.id}`}
                  />
              </ListItem>
            )}

            {deep !== 1 ? <Divider /> : index !== data.length - 1 ? <Divider /> : null}

            {value.subSection?.length > 0 ? (
              <ListYSubElementsOfXiMainElement data={value.subSection} deep={deep} />
            ) : null}

            {value.options?.length > 0 ? (
              <ListYSubElementsOfXiMainElement data={value.options} className={classes.childElement} deep={deep + 1} />
            ) : null}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

export default ListYSubElementsOfXiMainElement;
