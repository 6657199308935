import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery, isSmSizeAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    newIllustrationFieldsPaper: {
      top: '20%',
      [isSmSizeAppQuery(theme)]: {
        top: '7% !important',
        left: '15% !important',
        right: '15% !important',
      },
      [isMobileAppQuery(theme)]: {
        top: '0% !important',
        left: '0% !important',
        bottom: '0% !important',
        right: '0% !important',
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
  }),
  {index: 1},
);

export default useStyles;
