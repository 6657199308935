import {isEmpty, isObject, keyBy, padStart, transform} from 'lodash';
import moment from 'lib/moment';
import {LANGUAGES} from 'constants/i18n';
import {ZIP_CODE_LENGTH} from 'constants/illustrator';

export async function timeOut(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function ToArray(val, defaultValue = []) {
  if (Array.isArray(val)) return val;

  return defaultValue;
}

export function ToInt(str, defaultValue = 0) {
  const val = parseInt(str, 10);

  if (!val || isNaN(val)) return defaultValue;

  return val;
}

export function ToNumberOrDefault(str, defaultValue = 0) {
  const val = parseFloat(str);

  if (isNaN(val) || !val) return defaultValue;

  return val;
}

export function ToNumber(str, defaultValue = 0) {
  const val = parseFloat(str);

  if (isNaN(val)) return defaultValue;

  return val;
}

export function ToZipCode(str) {
  return padStart(str, ZIP_CODE_LENGTH, '0');
}

export function ToNumberNullable(str) {
  const val = parseFloat(str);
  if (isNaN(val)) return null;
  return val;
}

export function ToNumberWithPrecision(str, precision = 4, defaultValue = 0) {
  const val = parseFloat(str).toPrecision(precision);

  if (isNaN(val)) return defaultValue;

  return val;
}

export function ToFixedNumber(str, precision = 2, defaultValue = 0) {
  const val = ToNumber(str).toFixed(precision);

  if (isNaN(ToNumber(str))) return defaultValue;
  return val;
}

export function ToString(str, defaultValue = '') {
  if (!str) return defaultValue;
  else if (str.toString) return str.toString();

  return `${str}`;
}

export function isNullVal(val) {
  return val === null || val === undefined;
}

export function isEmptyVal(val) {
  return isEmpty(val);
}

export function toBoolean(val) {
  const string = ToString(val);

  switch (string.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case 'empty':
    case null:
      return false;
    default:
      return Boolean(string);
  }
}

export function normalizeBetweenTwoRanges(val, minVal, maxVal, newMin, newMax) {
  return newMin + ((val - minVal) * (newMax - newMin)) / (maxVal - minVal);
}

export function SqlDateFormat(sqlDate, local = LANGUAGES.EN) {
  moment.locale(local);
  return moment.utc(sqlDate).local().format('l LT');
}

export function getLocalDateTime(utcDate, format) {
  return moment.utc(utcDate).local().format(format);
}

export function hasValues(...args) {
  return args?.find(Boolean);
}

export function toArray(data) {
  if (Array.isArray(data)) return data;

  return [data].filter(Boolean);
}

export function toMomentDateValidation(years = 0) {
  return moment().startOf('day').subtract(years, 'years');
}

export function getAgeRestriction(metricData, currentState, key) {
  return currentState?.[key] ?? metricData?.[key];
}

export function getCurrentState(ProductAvailableStates, State) {
  return ProductAvailableStates?.find((availableState) => availableState.State === State);
}

export function deepOmit(obj, keysToOmit) {
  const keysToOmitIndex = keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]); // create an index object of the keys that should be omitted

  function omitFromObject(obj) {
    // the inner function which will be called recursively
    return transform(obj, function (result, value, key) {
      // transform to a new object
      if (key in keysToOmitIndex) {
        // if the key is in the index skip it
        return;
      }

      result[key] = isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
    });
  }

  return omitFromObject(obj); // return the inner function result
}

export function parentheses(str) {
  return `(${str})`;
}
