import {useCallback, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';

export function useInputCondition(props = {}) {
  const {condition, conditionStyle, style} = props;

  const {watch} = useFormContext();

  const formValues = watch();
  const getConditionTest = useCallback(
    (test) => {
      return formValues[test.key] === test.value ? test.then : test.otherwise;
    },
    [formValues],
  );

  const getConditionResults = useCallback(
    (condition) => {
      const conditionResults = {};

      if (condition)
        Object.keys(condition).forEach((key) => {
          const tests = Array.isArray(condition[key]) ? condition[key] : [condition[key]];

          const executedTests = tests.map((test) => getConditionTest(test));

          conditionResults[key] = executedTests.reduce(
            (previousValue, currentValue) => previousValue && currentValue,
            true,
          );
        });

      return conditionResults;
    },
    [getConditionTest],
  );

  const conditionResults = useMemo(() => getConditionResults(condition), [condition, getConditionResults]);
  const conditionStyleResults = useMemo(
    () => getConditionResults(conditionStyle),
    [conditionStyle, getConditionResults],
  );

  return useMemo(
    () => ({
      style: Object.assign({}, conditionStyleResults, style),
      ...conditionResults,
    }),
    [conditionResults, conditionStyleResults, style],
  );
}
