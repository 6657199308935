import {axios} from './api';

export default class ContentServiceApi {
  static baseUrl = '/contents';

  static async getAll(config) {
    return axios.get(`${this.baseUrl}`, {
      ...config,
      params: {
        ContentKeyLike: '%',
      },
    });
  }
}
