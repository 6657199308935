import {usePDFPage} from 'hooks/print';
import {withProducts} from 'components/products';
import {useProductsContext} from 'contexts/products-context';
import {isLoaded} from 'adapter/loading.adapter';
import {useProductRequestData} from 'query/products';
import {useAddIllustration} from 'hooks/illustrator';
import {IllustratorHybridPage} from 'pages/illustrator-hybrid-page/IllustratorHybridPage';

function IllustratorHybridPDFPage(props) {
  const {loading} = useProductsContext();

  const {getResponse} = useProductRequestData();
  const {products = []} = useProductsContext();
  const {addIllustration} = useAddIllustration();

  const {loading: loadingPDF} = usePDFPage({
    timeout: 5000,
    withHistory: false,
    // url: pdfPage,
    enabled: isLoaded({loading}),
    onSuccess(data) {
      const ApiRoute = products.find((product) => product.ProductId === data.ProductId)?.ApiRoute;
      const illustration = getResponse({ApiRoute}, data);
      addIllustration(illustration);
    },
  });

  if (isLoaded({loading: loadingPDF})) return <IllustratorHybridPage {...props} />;

  return null;
}

export default withProducts(IllustratorHybridPDFPage);
