import React, {useMemo} from 'react';
import {noop} from 'lodash';
import {useFormContext} from 'react-hook-form';

function DisableElement(props) {
  const {isDisabled = noop, children} = props;

  const {watch} = useFormContext();

  const values = watch();

  const disabled = useMemo(() => {
    return isDisabled(values) || false;
  }, [isDisabled, values]);

  return React.cloneElement(children, {disabled});
}

export default DisableElement;
