import React, {useCallback, useState} from 'react';

export const defaultContext = {
  contentClassName: '',
  setContentClassName: (val) => val,
  resetContentClassName: () => undefined,
};
export const StyleContentContext = React.createContext(defaultContext);

export function useStyleContentContext() {
  return React.useContext(StyleContentContext);
}

function StyleContentProvider({children} = {}) {
  const [contentClassName, setContentClassName] = useState('');

  const resetContentClassName = useCallback(() => {
    setContentClassName('');
  }, [setContentClassName]);

  return (
    <StyleContentContext.Provider value={{contentClassName, setContentClassName, resetContentClassName}}>
      {children}
    </StyleContentContext.Provider>
  );
}

export default StyleContentProvider;
