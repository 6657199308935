import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    modalFullScreenFormScreenshot: {
      '& .MuiDialog-container': {
        '& .MuiDialog-paperWidthSm': {
          width: '100%',
          maxWidth: '100%',
        },
        '& .MuiDialog-paper': {
          height: '100%',
          border: '3px solid ' + theme.palette.white.light,
        },
      },
    },
    modalFullScreenContent: {
      [isMobileAppQuery(theme)]: {
        padding: theme.spacing(1),
      },
    },
    closeButton: {
      margin: 'auto',
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
    closeButtonExtended: {
      left: 0,
      right: 0,
    },
    closeButtonText: {
      color: theme.palette.white.light,
      maxWidth: 100,
    },
    closeButtonIcon: {
      color: theme.palette.white.light,
      padding: 0,
      paddingRight: theme.spacing(1),
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    itemContainer: {
      width: '100%',
      margin: 'auto',
      '& .MuiFilledInput-root': {
        backgroundColor: 'unset',
      },
      '& .MuiInputLabel-root': {
        color: theme.palette.primary.dark,
        fontWeight: '600 !important',
      },
    },
    fullWidth: {
      padding: '2px',
      border: '1px solid ' + theme.palette.grey.light,
      width: '100%',
      display: 'flex',
      margin: theme.spacing(0, 'auto'),
    },
  }),
  {index: 1},
);

export default useStyles;
