import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    textContainer: {
      padding: theme.spacing(2),
    },
    noMarginTop: {
      marginTop: 0,
      textAlign: 'center',
    },
    disclaimerContainer: {
      fontSize: `${theme.spacing(1.75)}px !important`,
    },
  }),
  {index: 1},
);

export default useStyles;
