import {nanoid} from '@reduxjs/toolkit';
import {forwardRef, useMemo} from 'react';

/**
 *
 * @param size {number?}
 * @returns {string}
 */
export function generateId(size) {
  return nanoid(size);
}

export function withGeneratedId(Component) {
  return forwardRef(function WithGeneratedIdRender(props, ref) {
    const generatedId = useMemo(() => generateId(), []);
    const {id = generatedId, ...rest} = props;

    return <Component id={id} {...rest} ref={ref} />;
  });
}
