import React from 'react';

import {List} from '@material-ui/core';
import {generatePath} from 'lib/react-router-dom';
import {ROUTES} from 'constants/routes';

import useStyles from './NavListStyles';
import clsx from 'clsx';
import NavListItem from './components/nav-list-item/NavListItem';
import {useLogout} from 'components/app-header/components/logout-button/LogoutButton';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {withProducts} from 'components/products';
import {useAddIllustration} from 'hooks/illustrator';
import {useIllustratorBorrowerNavList} from 'hooks/borrower';
import {useLogoutNavList} from 'hooks/logout';

function NavList(props) {
  const {className, navigationList, handleDrawerOpen, handleDrawerClose, drawerOpen, isSublist, ...rest} = props;
  const classes = useStyles();

  return (
    <List className={clsx(className, classes.listContainer)} {...rest}>
      {navigationList.map((item) => {
        return (
          <NavListItem
            key={item.label}
            item={item}
            drawerOpen={drawerOpen}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            isSublist={isSublist}
            NavList={NavList}
          />
        );
      })}
    </List>
  );
}

NavList.defaultProps = {
  navigationList: [
    {
      label: 'HOME_PAGE',
      icon: ['fas', 'home'],
      href: generatePath(ROUTES.XCELERATE_HOME_PAGE),
      link: ROUTES.HOME_PAGE,
      target: '_self',
    },
    {
      label: 'ILLUSTRATOR_ROOT_PAGE',
      icon: ['fas', 'chart-bar'],
      link: generatePath(ROUTES.ILLUSTRATOR_ROOT_PAGE),
      sublist: [
        {
          label: 'ILLUSTRATOR_START_PAGE',
          link: generatePath(ROUTES.ILLUSTRATOR_ROOT_PAGE),
          useHook: useAddIllustration,
          onClick: ({addIllustration}) => addIllustration(),
        },
        {
          label: 'SAVED_ILLUSTRATOR_PAGE',
          link: generatePath(ROUTES.SAVED_ILLUSTRATOR_PAGE),
        },
        {
          label: 'LOAN_PIPELINE_PAGE',
          link: generatePath(ROUTES.LOAN_PIPELINE_PAGE),
          useHook: useIllustratorBorrowerNavList,
          displayCondition: ({isProductFeatureKeyEnabled}) =>
            isProductFeatureKeyEnabled(PRODUCT_FEATURE_KEY.LOAN_PIPELINE_NAV),
        },
        {
          label: 'START_EQUITY_AVAIL_APPLICATION',
          link: generatePath(ROUTES.ILLUSTRATOR_BORROWER_ROOT_PAGE),
          classname: 'lightBlue',
          useHook: useIllustratorBorrowerNavList,
          onClick: ({addBorrowerIllustration}) => addBorrowerIllustration(),
          displayCondition: ({isProductFeatureKeyEnabled}) =>
            isProductFeatureKeyEnabled(PRODUCT_FEATURE_KEY.BORROW_INFO_SIDEBAR_MENU),
        },
      ],
    },
    {
      label: 'TRAINING_PAGE',
      icon: ['fas', 'chalkboard-teacher'],
      href: generatePath(ROUTES.TRAINING_PAGE),
      sublist: [
        {label: 'FINANCE_OF_AMERICA_UNIVERSITY_PAGE', href: generatePath(ROUTES.FINANCE_OF_AMERICA_UNIVERSITY_PAGE)},
        {label: 'TRAINING_TEAM_PAGE', href: generatePath(ROUTES.TRAINING_TEAM_PAGE)},
        {label: 'TRAINING_DOCUMENTS_PAGE', href: generatePath(ROUTES.TRAINING_DOCUMENTS_PAGE)},
        {label: 'TEAM_PAGE', href: generatePath(ROUTES.TEAM_PAGE)},
        {label: 'TRAINING_CALENDAR_PAGE', href: generatePath(ROUTES.TRAINING_CALENDAR_PAGE)},
        {label: 'TRAINING_VIDEOS_PAGE', href: generatePath(ROUTES.TRAINING_VIDEOS_PAGE)},
      ],
    },
    {
      label: 'RESOURCES_PAGE',
      icon: ['fas', 'book-open'],
      href: generatePath(ROUTES.CAPACITY),
      link: ROUTES.CAPACITY,
      target: '_blank',
    },
    {
      label: 'PRODUCTS_PAGE',
      icon: ['fas', 'cubes'],
      link: generatePath(ROUTES.PRODUCTS_PAGE),
      sublist: [
        {
          label: 'PRODUCTS_DETAILS_PAGE',
          href: generatePath(ROUTES.PRODUCTS_DETAILS_PAGE),
        },
        {
          label: 'PRODUCTS_AVAILABILITY_PAGE',
          href: generatePath(ROUTES.PRODUCTS_AVAILABILITY_PAGE),
        },
      ],
    },
    {
      label: 'LOGOUT',
      icon: ['far', 'user'],
      useHook: useLogoutNavList,
      onClick: ({doLogout}) => doLogout(),
      href: '#',
      displayCondition: ({isMobileApp}) => isMobileApp,
    },
  ],
};

NavList.propTypes = {};

export default withProducts(NavList, {renderProducts: false});
