import React, {useCallback, useMemo, useState} from 'react';
import {Cell, Label, Legend, Pie, PieChart as RPieChart} from 'recharts';
import RenderActiveShape from 'pages/illustrator-hybrid-page/components/consumer-cash-up-front-section/components/render-active-shape/RenderActiveShape';
import {useTheme} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RenderLabel from 'pages/illustrator-hybrid-page/components/consumer-cash-up-front-section/components/render-label/RenderLabel';
import {useTranslation} from 'lib/i18n';
import {useScrollSpeed} from 'hooks/parallax';
import {ToNumber} from 'lib/utils';
import {usePrintContext} from 'contexts/print-context';
import {useMobileApp} from 'lib/app-theme';

PieChart.propTypes = {};

function PieChart(props) {
  const {SimplifiedCashToBorrower, width, height, margin} = props;
  const theme = useTheme();
  const isMobileApp = useMobileApp();
  const [activeIndex, setActiveIndex] = useState(undefined);
  const {t} = useTranslation();

  const data = useMemo(
    () =>
      !isNaN(SimplifiedCashToBorrower)
        ? [
            {
              name: ToNumber(SimplifiedCashToBorrower / 4),
              label: t('pie chart section.chart label.travel'),
              value: 20,
              color: theme.palette.secondary.main,
              icon: <FontAwesomeIcon icon={['fas', 'plane-departure']} />,
            },
            {
              name: ToNumber(SimplifiedCashToBorrower / 4),
              label: t('pie chart section.chart label.family time'),
              value: 20,
              color: theme.palette.neutral.light,
              icon: <FontAwesomeIcon icon={['fas', 'users']} />,
            },
            {
              name: ToNumber(SimplifiedCashToBorrower / 8),
              label: t('pie chart section.chart label.emergency saving'),
              value: 10,
              color: theme.palette.primary.main,
              icon: <FontAwesomeIcon icon={['fas', 'money-bill']} />,
            },
            {
              name: ToNumber(SimplifiedCashToBorrower / 8),
              label: t('pie chart section.chart label.new hobby'),
              value: 10,
              color: theme.palette.recommended.main,
              icon: <FontAwesomeIcon icon={['fas', 'cut']} />,
            },
            {
              name: ToNumber(SimplifiedCashToBorrower / 4),
              label: t('pie chart section.chart label.remodel'),
              value: 20,
              color: theme.palette.neutral.main,
              icon: <FontAwesomeIcon icon={['fas', 'home']} />,
            },
          ]
        : [],
    [SimplifiedCashToBorrower, theme, t],
  );

  const onPieEnter = useCallback((data, index) => setActiveIndex(index), [setActiveIndex]);
  const onPieExit = useCallback(() => setActiveIndex(undefined), [setActiveIndex]);

  const label = useCallback(
    (props) => (
      <RenderLabel
        {...props}
        onMouseLeave={onPieExit}
        onMouseEnter={onPieEnter}
        activeIndex={activeIndex}
        maxX={width}
        showLabel={!isMobileApp}
      />
    ),
    [activeIndex, width, isMobileApp, onPieExit, onPieEnter],
  );

  const {animation: isAnimationActive} = usePrintContext({animation: true});
  const {duration} = useScrollSpeed();

  return (
    <RPieChart onMouseLeave={onPieExit} width={width} height={height} margin={margin}>
      <Pie
        isAnimationActive={isAnimationActive}
        data={data}
        dataKey="value"
        nameKey="name"
        startAngle={180}
        endAngle={-180}
        innerRadius="60%"
        activeIndex={activeIndex}
        activeShape={<RenderActiveShape />}
        labelLine={false}
        label={label}
        onMouseEnter={onPieEnter}
        onMouseLeave={onPieExit}
        animationDuration={duration}
      >
        {activeIndex ?? (
          <Label
            position="center"
            content={({viewBox}) => <RenderActiveShape payload={{name: SimplifiedCashToBorrower}} {...viewBox} />}
          />
        )}
        {data.map((entry, index) => (
          <Cell key={index} fill={entry.color} />
        ))}
      </Pie>
      {isMobileApp && (
        <Legend payload={data.map((e, index) => ({value: e.label, color: e.color}))} verticalAlign="bottom" />
      )}
    </RPieChart>
  );
}

export default PieChart;
