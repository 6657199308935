import ValidationsApi from 'api/validations-api';
import {useMutation, useQuery} from 'react-query';
import {getProductStateDobValidationMutationKey, getProductStateDobValidationQueryKey} from 'constants/query';
import moment from 'lib/moment';
import {useMemo} from 'react';

export function useGetDateOfBirthValidationQuery(props = {}) {
  const {ApiRoute: moduleApiRoute, Zip: moduleZip, enabled: moduleEnabled = true} = props;

  const ApiRoute = useMemo(() => moduleApiRoute || undefined, [moduleApiRoute]);
  const Zip = useMemo(() => moduleZip || undefined, [moduleZip]);
  const enabled = useMemo(() => moduleEnabled, [moduleEnabled]);

  return useQuery(
    [getProductStateDobValidationQueryKey, {ApiRoute, Zip}],
    async () => {
      const response = await ValidationsApi.getDateOfBirthValidation({ApiRoute, Zip});

      response.data.ApiRoute = ApiRoute;
      Object.keys(response.data).forEach((key) => {
        response.data[key] = moment().subtract(response.data[key], 'years');
      });

      return response;
    },
    {
      enabled,
    },
  );
}

export function useDateOfBirthValidationMutation() {
  return useMutation([getProductStateDobValidationMutationKey, {}], async ({Zip, DateOfBirth, ApiRoute}) => {
    return ValidationsApi.postDateOfBirthValidation({ApiRoute, Zip, DateOfBirth});
  });
}
