import React, {useCallback, useMemo} from 'react';
import Item from 'components/common/layout/item/Item';
import {useTranslation} from 'lib/i18n';
import useStyles from './BrokerTransactionsStyles';
import Container from 'components/common/layout/container/Container';
import Button from 'components/common/button/Button';
import Form from 'components/common/form/Form';
import yup from 'lib/validation';
import {useDispatch, useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower, updateSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import {useStepWizardContext} from 'contexts/step-wizard';
import StepElement from 'pages/illustrator-borrower/components/step-element/StepElement';
import BrokerTransactionsQuestionOne from './components/BrokerTransactionsQuestionOne';
import BrokerTransactionsQuestionTwo from 'pages/illustrator-borrower/broker-transactions/components/BrokerTransactionsQuestionTwo';
import BrokerTransactionsQuestionThree from './components/BrokerTransactionsQuestionThree';

const MIN_VAL = 0.001;
const MAX_VAL = 999999.999;

function BrokerTransactions(props) {
  const {nextStep} = useStepWizardContext();

  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedIllustratorBorrower = useSelector(getSelectedIllustratorBorrower);

  /**
   *
   * On submit, the "total fees points" values, must be >= 0.01 and <= 9999.99
   *
   */

  const validationSchema = useMemo(
    () =>
      yup.object({
        // section #1 / Question 1
        totalInitialRate1: yup.number().min(MIN_VAL).max(MAX_VAL).required(), // % percent
        totalFeesPoints1: yup.number().min(MIN_VAL).max(MAX_VAL).required(), // $ dollar
        // section #2 / Question 2
        totalInitialRate2: yup.number().min(MIN_VAL).max(MAX_VAL).required(), // % percent
        totalFeesPoints2: yup.number().min(MIN_VAL).max(MAX_VAL).required(), // $ dollar
        // section #3 / Question 3
        totalInitialRate3: yup.number().min(MIN_VAL).max(MAX_VAL).required(), // % percent
        totalFeesPoints3: yup.number().min(MIN_VAL).max(MAX_VAL).required(), // $ dollar
      }),
    [],
  );

  const defaultValues = useMemo(() => {
    return {
      ...selectedIllustratorBorrower?.brokerTransactions,
    };
  }, [selectedIllustratorBorrower?.brokerTransactions]);

  // the submit method
  const onSubmitFormHandler = useCallback(
    async (data) => {
      await dispatch(
        updateSelectedIllustratorBorrower({
          brokerTransactions: data,
        }),
      );

      /**
       *
       * the next step should be : interview & demographic info
       *
       */

      return nextStep();
    },
    [nextStep, dispatch],
  );

  return (
    <StepElement
      id="brokerTransactions"
      title={t('illustratorTranslation:illustrator borrower broker transactions.title')}
      data-testid="broker-transactions"
    >
      <Container>
        <Item xs={0} md={1} lg={2} />

        <Item xs={12} md={10} lg={8}>
          <Container
            component={Form}
            onSubmit={onSubmitFormHandler}
            defaultValues={defaultValues}
            validationSchema={validationSchema}
          >
            <BrokerTransactionsQuestionOne />

            <Item xs={12} />

            <BrokerTransactionsQuestionTwo />

            <Item xs={12} />

            <BrokerTransactionsQuestionThree />

            <Item xs={12} />

            <Item xs={12}>
              <Container className={classes.buttonContainer}>
                <Item xs={12} sm={6}>
                  <Button
                    btnStyle="bgElectric"
                    buttonStyling={{lg: true}}
                    type="submit"
                    data-testid="goToNextOriginBorrowerInfo"
                  >
                    {t('translation:common.next')}
                  </Button>
                </Item>
              </Container>
            </Item>
          </Container>
        </Item>

        <Item xs={0} md={1} lg={2} />
      </Container>
    </StepElement>
  );
}

BrokerTransactions.propTypes = {};

BrokerTransactions.defaultValues = {};

export default BrokerTransactions;
