import {useQuery} from 'react-query';
import {getApiKeyValidationQueryKey} from 'constants/query';
import ValidationsApi from 'api/validations-api';
import {useMemo} from 'react';

export function useWidgetApiKeyValidationQuery(props) {
  const {apiKey, enabled: moduleEnabled = true} = props;

  const enabled = useMemo(() => !!(moduleEnabled && apiKey), [moduleEnabled, apiKey]);

  return useQuery({
    enabled,
    queryKey: [getApiKeyValidationQueryKey, apiKey],
    queryFn() {
      return ValidationsApi.validateApiKey({apiKey});
    },
  });
}
