import yup from 'lib/validation';
import {BORROWER_PREFIX} from 'constants/borrower';

/**
 *
 * `Sex` section validation schema
 *
 */

export function SexValidation(props = {}) {
  const {prefix = BORROWER_PREFIX, demographicInformationSource, enabled = true} = props;

  if (!enabled) return {};

  const sexSchema = {
    [`${prefix}SexFemale`]: yup.bool().nullable(),
    [`${prefix}SexMale`]: yup.bool().nullable(),
  };
  const wishNotToProvideKey = `${prefix}SexWishNotToProvide`;

  return {
    ...sexSchema,
    [wishNotToProvideKey]: yup
      .bool()
      .nullable()
      .when('demographicInformationSource', {
        is: demographicInformationSource,
        then: (s) => s.wishNotToProvide({optionKeys: Object.keys(sexSchema)}),
      })
      .oneOptionRequired({optionKeys: Object.keys(sexSchema).concat(wishNotToProvideKey)}),
  };
}

export default SexValidation;
