import React from 'react';
import {NumericFormat as NumberFormat} from 'react-number-format';
import {percentToNumber} from 'lib/currency';
import {useOnChangeInterceptor} from 'components/common/form/input/components/OnChangeInterceptor';

export const percentSymbol = '%';

function Percent(props) {
  const {inputRef, onChange, decimalScale = 2, ...other} = props;

  const {doChange} = useOnChangeInterceptor({onChange, getValue: (event) => percentToNumber(event.target.value)});

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onChange={doChange}
      thousandSeparator
      valueIsNumericString
      decimalScale={decimalScale}
      fixedDecimalScale
      suffix={percentSymbol}
    />
  );
}

Percent.propTypes = {};

export default Percent;
