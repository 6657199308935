import React, {useMemo} from 'react';
import Item from 'components/common/layout/item/Item';
import Text from 'components/common/typeography/text/Text';
import Container from 'components/common/layout/container/Container';
import {ElementContextProvider} from 'contexts/element-context';
import {List, ListItem, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import useStyles from './DetailsStyles';
import clsx from 'clsx';

/**
 *
 * The "Details" component, part of the "More about PRODUCT" component.
 *
 *******************************************************
 * text goes here * Improve Cash Flow * A              *
 *                * Still Have Equity * g              *
 *                * Lump SUm Payout   * e              *
 *******************************************************
 *
 */

function Details(props = {}) {
  const classes = useStyles();
  const {
    benefits: benefitsTitle,
    considerations: considerationsTitle,
    tab: {title, description, benefits = [], considerations = []} = {},
  } = props;

  const data = useMemo(() => {
    const considerationsArray = Array.isArray(considerations) ? considerations : [considerations];
    const benefitsArray = Array.isArray(benefits) ? benefits : [benefits];

    const combinedArray = [];
    for (let i = 0; i < Math.max(considerationsArray.length, benefitsArray.length); i++) {
      combinedArray.push({
        benefit: benefitsArray[i],
        consideration: considerationsArray[i],
      });
    }

    return combinedArray;
  }, [benefits, considerations]);

  return (
    <Container className={classes.detailsContainer}>
      <Item xs={12} md={6}>
        <Container>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.detailsTableCel}>
                  <Text className="strong">{title}</Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={clsx(classes.detailsTableCel, classes.detailsDescriptionCel)}>
                  <Text>{description}</Text>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Container>
      </Item>
      <Item xs={12} md={6}>
        <Container>
          <ElementContextProvider>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.detailsTableCel}>
                    <Text className="strong">{benefitsTitle}</Text>
                  </TableCell>
                  <TableCell className={classes.detailsTableCel}>
                    <Text className="strong">{considerationsTitle}</Text>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((element, index) => (
                  <List key={index} component={TableRow} className={classes.detailsList}>
                    {element.benefit && (
                      <TableCell className={clsx(classes.detailsTableCel, classes.detailsListTableCel)}>
                        <ListItem>
                          <Text className={classes.detailsListElement}>{element.benefit}</Text>
                        </ListItem>
                      </TableCell>
                    )}
                    {element.consideration && (
                      <TableCell className={clsx(classes.detailsTableCel, classes.detailsListTableCel)}>
                        <ListItem>
                          <Text className={classes.detailsListElement}>{element.consideration}</Text>
                        </ListItem>
                      </TableCell>
                    )}
                  </List>
                ))}
              </TableBody>
            </Table>
          </ElementContextProvider>
        </Container>
      </Item>
    </Container>
  );
}

Details.propTypes = {};

export default Details;
