import yup from 'lib/validation';
import {BORROWER_PREFIX} from 'constants/borrower';

/**
 *
 * 'Ethnicity` section validation schema for the illustrator-borrower flow
 *
 */

function EthnicityValidation(props = {}) {
  const {prefix = BORROWER_PREFIX, demographicInformationSource, enabled = true} = props;

  if (!enabled) return {};

  const ethnicitySchema = {
    [`${prefix}EthnicityHispanicLatino`]: yup.bool().nullable(),
    [`${prefix}EthnicityMexican`]: yup.bool().nullable(),
    [`${prefix}EthnicityPuertoRican`]: yup.bool().nullable(),
    [`${prefix}EthnicityCuban`]: yup.bool().nullable(),
    [`${prefix}EthnicityOtherHispanicLatino`]: yup.bool().nullable(),
    [`${prefix}EthnicityNotHispanicLatino`]: yup.bool().nullable(),
  };
  const wishNotToProvideKey = `${prefix}EthnicityWishNotToProvide`;

  return {
    ...ethnicitySchema,
    [`${prefix}EthnicityOtherHispanicLatinoText`]: yup
      .string()
      .nullable()
      .when(`${prefix}EthnicityOtherHispanicLatino`, {
        is: true,
        otherwise: (s) => s.strict(),
      }),
    [wishNotToProvideKey]: yup
      .bool()
      .when('demographicInformationSource', {
        is: demographicInformationSource,
        then: (s) => s.wishNotToProvide({optionKeys: Object.keys(ethnicitySchema)}),
      })
      .oneOptionRequired({optionKeys: Object.keys(ethnicitySchema).concat(wishNotToProvideKey)}),
  };
}

export default EthnicityValidation;
