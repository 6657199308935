import {useFieldArray, useFormContext} from 'react-hook-form';
import {AFFILIATED_BUSINESS_ARRANGEMENTS_FORM_LIST} from 'constants/link-pages';

function useAffiliatedBusinessArrangementsFormTable() {
  const {control, triggerSubmit} = useFormContext();

  const name = AFFILIATED_BUSINESS_ARRANGEMENTS_FORM_LIST;

  const {fields, append, remove} = useFieldArray({control, name});

  return {
    fields,
    append,
    remove,
    triggerSubmit,
  };
}

export default useAffiliatedBusinessArrangementsFormTable;
