import yup from 'lib/validation';
import {BORROWER_PREFIX} from 'constants/borrower';

/**
 *
 * `Race` section validation schema
 *
 */

export function RaceValidation(props = {}) {
  const {prefix = BORROWER_PREFIX, demographicInformationSource, enabled = true} = props;

  if (!enabled) return {};

  const raceSchema = {
    [`${prefix}RaceAmericanIndianAlaskaNative`]: yup.bool().nullable(),
    [`${prefix}RaceAsian`]: yup.bool().nullable(),
    [`${prefix}RaceAsianIndian`]: yup.bool().nullable(),
    [`${prefix}RaceChinese`]: yup.bool().nullable(),
    [`${prefix}RaceFilipino`]: yup.bool().nullable(),
    [`${prefix}RaceJapanese`]: yup.bool().nullable(),
    [`${prefix}RaceKorean`]: yup.bool().nullable(),
    [`${prefix}RaceVietnamese`]: yup.bool().nullable(),
    [`${prefix}RaceOtherAsian`]: yup.bool().nullable(),
    [`${prefix}RaceBlackAfricanAmerican`]: yup.bool().nullable(),
    [`${prefix}RaceHawaiianPacificIslander`]: yup.bool().nullable(),
    [`${prefix}RaceNativeHawaiian`]: yup.bool().nullable(),
    [`${prefix}RaceGuamanianChamorro`]: yup.bool().nullable(),
    [`${prefix}RaceSamoan`]: yup.bool().nullable(),
    [`${prefix}RaceOtherHawaiianPacific`]: yup.bool().nullable(),
    [`${prefix}RaceWhite`]: yup.bool().nullable(),
  };
  const wishNotToProvideKey = `${prefix}RaceWishNotToProvide`;

  return {
    ...raceSchema,
    [`${prefix}RaceAmericanIndianAlaskaNativeText`]: yup
      .string()
      .nullable()
      .when(`${prefix}RaceAmericanIndianAlaskaNative`, {
        is: true,
        otherwise: (s) => s.strict(),
      }),
    [`${prefix}RaceOtherAsianText`]: yup
      .string()
      .nullable()
      .when(`${prefix}RaceOtherAsian`, {
        is: true,
        otherwise: (s) => s.strict(),
      }),
    [`${prefix}RaceOtherHawaiianPacificText`]: yup
      .string()
      .nullable()
      .when(`${prefix}RaceOtherHawaiianPacific`, {
        is: true,
        otherwise: (s) => s.strict(),
      }),

    [wishNotToProvideKey]: yup
      .bool()
      .nullable()
      .when('demographicInformationSource', {
        is: demographicInformationSource,
        then: (s) => s.wishNotToProvide({optionKeys: Object.keys(raceSchema)}),
      })
      .oneOptionRequired({optionKeys: Object.keys(raceSchema).concat(wishNotToProvideKey)}),
  };
}

export default RaceValidation;
