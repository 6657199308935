import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      textAlign: 'left',
      width: '100%',
      margin: theme.spacing(2, 'auto'),
    },
    p2: {
      padding: theme.spacing(2, 3),
    },
    m2: {
      margin: theme.spacing(2),
    },
    p0p3: {
      padding: theme.spacing(0, 3),
    },
    paper: {
      padding: theme.spacing(2),
    },
    hidden: {
      display: 'none',
    },
    widthMarginTop: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    noWrap: {
      flexWrap: 'nowrap',
    },
    paymentBreakdown: {
      padding: theme.spacing(2, 2),
    },
    avatarContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    avatarIcon: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    text2left: {
      textAlign: 'left',
    },
    text2right: {
      textAlign: 'right',
    },
    hSpacing: {
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
    },
    brokerNotesDescription: {
      margin: '30px',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '22px',
      lineHeight: '34px',
      alignItems: 'center',
      color: theme.palette.borrower.dark,
    },
    borderBottom: {
      borderBottom: '1px solid #eee',
    },
    outer: {
      position: 'relative',
      height: '100%',
    },
    inner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
    },
    rotate: {
      transform: 'translateX(-50%) translateY(-50%) rotate(-90deg)',
    },
    printContainer: {
      display: theme.forPrint('block'),
      width: '100%',
      margin: 'auto',
    },
  }),
  {index: 1},
);

export default useStyles;
