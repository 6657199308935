import React from 'react';
import {Tab} from '@material-ui/core';
import useStyles from './IllustrationTabStyles';
import clsx from 'clsx';
import {withLoginModal} from 'components/app-header/components/login-modal/LoginModal';

const IllustrationTab = React.forwardRef(function IllustrationTabRender(props, ref) {
  const {rounded, classes: componentClasses, ...rest} = props;
  const classes = useStyles();

  return (
    <Tab
      classes={{
        ...componentClasses,
        root: clsx(classes.root, {[classes.roundedButton]: rounded}, componentClasses?.root),
        wrapper: clsx(classes.wrapper, componentClasses?.wrapper),
        selected: classes.selected,
      }}
      disableRipple
      {...rest}
      ref={ref}
    />
  );
});

export default withLoginModal(IllustrationTab);
