import {useSelector} from 'react-redux';
import {getBreakDownViewType} from 'reducers/settings.reducer';
import {useIllustrationContext} from 'contexts/illustration-context';
import {useProductContext} from 'contexts/product-context';
import {useIllustrationV2FormContextValues} from 'pages/illustrator/components/illustration/components/illustration-form/illustration-form-context';
import {isLoaded} from 'adapter/loading.adapter';
import FormContextProvider from 'contexts/form-context';
import {API_ROUTES} from 'constants/illustrator';
import IllustrationForm from 'pages/illustrator/components/illustration/components/illustration-form/IllustrationForm';
import IllustratorBreakDownTabs from 'pages/illustrator/components/illustration/components/illustrator-brakedown/components/illustrator-breakdown-tabs/IllustratorBreakDownTabs';
import React from 'react';

function IllustratorBreakdownComp(props = {}) {
  const breakDownTabsViewType = useSelector(getBreakDownViewType);
  const {BorrowerProfile: {Zip} = {}, ApiRoute} = useIllustrationContext();

  const {productDetails: {additionalStepValidation} = {}, loadingProduct} = useProductContext();

  const {defaultState, extraArguments} = useIllustrationV2FormContextValues({
    Zip,
    ApiRoute,
    enabled: isLoaded({loading: loadingProduct}),
    fields: additionalStepValidation?.fields,
  });

  return (
    <FormContextProvider
      defaultState={defaultState}
      extraArguments={extraArguments}
      reset={ApiRoute === API_ROUTES.HYBRID}
    >
      <IllustrationForm>
        <IllustratorBreakDownTabs defaultViewType={breakDownTabsViewType} setHasSubmitted={props.setHasSubmitted} />
      </IllustrationForm>
    </FormContextProvider>
  );
}

export default IllustratorBreakdownComp;
