import {ToNumber, ToNumberOrDefault, ToNumberWithPrecision} from 'lib/utils';
import {useCallback} from 'react';
import {useProductValidation} from 'hooks/validator';
import {defaultsDeep, groupBy, map} from 'lodash';
import {DEFAULT_HYBRID_METRIC, DEFAULT_HYBRID_RESULT} from 'reducers/illustrator.reducer';
import {find} from '@okta/okta-auth-js';

const MAX_MONTHLY_PAYMENT_YEAR = 10;

export function useCalculateHybridQuery(props) {
  const addAdditionalData = useCallback((data) => {
    return {};
  }, []);

  const addAdditionalResults = useCallback((data) => {
    const advancedOptions = map(
      groupBy(data.HybridResult?.MonthlyHybridAmortizations, 'Year'),
      (hybridAmortization) => {
        const hybridStartResults = find(hybridAmortization, {Month: 1});
        const hybridResults = find(hybridAmortization, {Month: 12});

        Object.keys(hybridResults).forEach((key) => {
          if (/start/gim.test(key)) {
            hybridResults[key] = hybridStartResults[key];
          } else if (/netEquity/gim.test(key)) {
            hybridResults.netEquityStartValue = hybridStartResults[key];
          }
        });

        return hybridResults;
      },
    );

    const NewMortgage = ToNumber(data.HybridResult?.NewMonthlyPayment, 0);

    return {
      LifetimePaid: ToNumber(data.HybridResult?.LifetimePaid),
      ExistingMortgageRemainingBalance: data.ExistingMortgage?.ExistingMortgageRemainingBalance,
      SimplifiedCashToBorrower: data.SimplifiedCashToBorrower,
      startLoanValue: ToNumber(data.InitialLoanBalance, 0),

      TotalInitialRate: ToNumberWithPrecision(data.HybridResult?.CouponRate * 100, 4),
      RemainingEquity: ToNumber(data.RemainingEquity),
      showLearnMore: true,
      hideModelImpactButton: false,
      HybridMetric: defaultsDeep(
        {
          CouponRateReverse: ToNumberWithPrecision(data.HybridMetric?.CouponRateReverse * 100, 4),
          CouponRateSecond: ToNumberWithPrecision(data.HybridMetric?.CouponRateSecond * 100, 4),
          PaymentTermYears: ToNumber(data.HybridMetric?.PaymentTermYears),
        },
        {...data?.HybridMetric},
        {...DEFAULT_HYBRID_METRIC},
      ),
      HybridResult: defaultsDeep(
        {
          CouponRate: ToNumber(data.HybridResult?.CouponRate, 0),
          MonthlySaved: ToNumber(data.HybridResult?.MonthlySaved, 0),
          LifetimeSaved: ToNumber(data.HybridResult?.LifetimeSaved, 0),
          NewMonthlyPayment: NewMortgage,
        },
        {...data?.HybridResult},
        {...DEFAULT_HYBRID_RESULT},
      ),
      PrincipalLimit: ToNumber(data?.PrincipalLimit, 0),

      InitialLoanBalance: ToNumber(data.InitialLoanBalance, 0),
      advancedOptions: advancedOptions?.map((monthlyHybridAmortizations, index) => {
        const year = ToNumber(monthlyHybridAmortizations.Year);
        const minMonthlyPayment = year <= MAX_MONTHLY_PAYMENT_YEAR ? -NewMortgage : 0;

        return {
          name: year,
          year: year,
          age: ToNumber(monthlyHybridAmortizations.BorrowerAge),
          homeValueYearStart: ToNumber(monthlyHybridAmortizations.StartHomeValue),
          homeValueYearEnd: ToNumber(monthlyHybridAmortizations.EndHomeValue),
          homeAppreciation: ToNumber(data.HomeAppreciationRates[index].AppreciationRate),
          loanBalanceValueYearStart: ToNumber(monthlyHybridAmortizations.StartLoanValue),
          loanBalanceValueYearEnd: ToNumber(monthlyHybridAmortizations.EndLoanValue),
          netEquityValue: ToNumber(monthlyHybridAmortizations.NetEquity),
          netEquityStartValue: ToNumber(monthlyHybridAmortizations.netEquityStartValue),
          minMonthlyPayment,
          monthlyPayment: ToNumberOrDefault(data.MonthlyPayments[index].PaymentValue, minMonthlyPayment),
          annualPayment: ToNumber(data.AnnualPayments[index].PaymentValue),
        };
      }),
    };
  }, []);

  const {addProductValidation} = useProductValidation({
    wrapProductData: (calculation, product) => ({
      State: calculation.State,
      productMetric: calculation.HybridMetric,
      productAvailableStates: product.ProductAvailableStates,
    }),
  });
  return {
    enabled: true,
    addAdditionalData,
    addAdditionalResults,
    addProductValidation,
  };
}
