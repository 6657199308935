import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {cloneDeep, merge} from 'lodash';
import {renderChildren} from 'lib/react';

const formContext = {};

const FormContext = React.createContext(formContext);

export function useOwnFormContext(props = {}) {
  return useContext(FormContext);
}

/**
 *
 * @param props {object}
 * @param props.extraArguments
 * @param props.defaultState
 * @param props.children {React.ReactNode}
 * @return {JSX.Element}
 * @constructor
 */
function FormContextProvider(props = {}) {
  const {children, defaultState = formContext, extraArguments, reset} = props;
  const [value, setValue] = useState();

  const setFormContext = useCallback((data) => setValue((value) => merge(cloneDeep(value), data)), [setValue]);
  const resetFormContext = useCallback(() => setValue(defaultState), [setValue, defaultState]);

  const context = useMemo(
    () => ({
      ...value,
      extraArguments,
      setFormContext,
      resetFormContext,
    }),
    [extraArguments, resetFormContext, setFormContext, value],
  );

  useEffect(() => {
    resetFormContext();
  }, [reset]);

  return <FormContext.Provider value={context}>{renderChildren(children, context)}</FormContext.Provider>;
}

export default React.memo(FormContextProvider);
