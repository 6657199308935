import React, {useCallback, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import Container from 'components/common/layout/container/Container';
import useStyles from './SavedIllustratorPageStyles';
import {DataGrid, GridOverlay} from '@mui/x-data-grid';
import {useHistory} from 'react-router-dom';
import {generatePath} from 'lib/react-router-dom';
import {DataGridLogger} from 'services/logger.service';
import {Box, useTheme} from '@material-ui/core';
import {ROUTES} from 'constants/routes';
import Title from 'components/common/typeography/title/Title';
import Button from 'components/common/button/Button';
import LinearLoader from 'components/common/loader/LinearLoader';
import {Trans, useTranslation} from 'lib/i18n';
import {isLoaded, isPending} from 'adapter/loading.adapter';
import {addOneIllustrationAndRemove, removeAllIllustrations} from 'reducers/illustrator.reducer';
import {LOADING_STATUS} from 'constants/loading';
import {useSavedIllustrationsDeleteMutation, useSavedIllustrationsQuery} from 'query/saved-illustrations';
import {useGetCalculationByIdMutation} from 'query/calculations/get-calculation';
import {withProducts} from 'components/products';
import {useProductsContext} from 'contexts/products-context';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS} from 'constants/mui-data-grid';
import {useDataGridFormat} from 'hooks/data-grid';

function useRowSelected() {
  const [selectedRows, setSelectedRows] = useState([]);
  const {mutate: deleteSavedIllustration, loading} = useSavedIllustrationsDeleteMutation();

  const handleRowsSelection = useCallback(
    (model) => {
      setSelectedRows(Object.values(model) || []);
    },
    [setSelectedRows],
  );

  const handleDelete = useCallback(
    (params) => {
      deleteSavedIllustration(selectedRows);
    },
    [deleteSavedIllustration, selectedRows],
  );

  const hasSelectedRows = useMemo(() => selectedRows.length > 0, [selectedRows]);

  return {
    selectedRows,
    setSelectedRows,
    handleRowsSelection,
    handleDelete,
    hasSelectedRows,
    deleteSavedIllustrationLoading: loading,
  };
}

/**
 *
 * The "Saved illustrations" main  component.
 *
 * route : /saved-illustrator
 *
 */

function SavedIllustratorPage(props) {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const {data: savedIllustrations, loading: savedIllustrationQueryLoading} = useSavedIllustrationsQuery();

  const {products} = useProductsContext();

  const goToNewIllustration = useCallback(() => {
    dispatch(addOneIllustrationAndRemove());

    return history.push(generatePath(ROUTES.ILLUSTRATOR_ROOT_PAGE));
  }, [dispatch, history]);

  const getCalculationByIdMutation = useGetCalculationByIdMutation();

  const {handleDelete, hasSelectedRows, handleRowsSelection, deleteSavedIllustrationLoading} = useRowSelected();

  const {formatCurrencyValue, formatApiRouteValue, formatDateValue} = useDataGridFormat();

  const goToIllustration = useCallback(
    async ({row, field}) => {
      if (field === '__check__') return;

      const {id: illustrationId} = row;

      await dispatch(removeAllIllustrations());

      getCalculationByIdMutation.mutate(illustrationId, {
        onSuccess() {
          history.push(ROUTES.ILLUSTRATOR_ROOT_PAGE);
        },
      });
    },
    [getCalculationByIdMutation, history, dispatch],
  );

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'dateCreated',
      sort: 'desc',
    },
  ]);

  const [pageSize, setPageSize] = React.useState(DEFAULT_PAGE_SIZE);

  const loading = useMemo(() => {
    if (isPending({loading: deleteSavedIllustrationLoading})) return true;
    else if (isLoaded({loading: savedIllustrationQueryLoading})) return false;
    return true;
  }, [savedIllustrationQueryLoading, deleteSavedIllustrationLoading]);

  return (
    <Container className={classes.root}>
      <Box className={classes.titleContainer}>
        <Title variant="h4" colorVariant="primaryDark">
          <Trans i18nKey="savedIllustrationTranslation:title" />
        </Title>

        <Box>
          {hasSelectedRows && (
            <Button
              buttonStyling={false}
              className={classes.deleteButton}
              btnStyle="bgError"
              onClick={handleDelete}
              data-testid="saved-illustrations-delete-illustration-button"
            >
              <Trans i18nKey="savedIllustrationTranslation:delete button" />
            </Button>
          )}

          <Button
            buttonStyling={false}
            btnStyle="bgElectric"
            onClick={goToNewIllustration}
            data-testid="saved-illustrations-new-illustration-button"
          >
            <Trans i18nKey="savedIllustrationTranslation:new button" />
          </Button>
        </Box>
      </Box>

      <Box className={classes.gridContainer} data-testid="saved-illustrations-table">
        <DataGrid
          columns={[
            {
              field: 'fullName',
              headerName: t('savedIllustrationTranslation:table.fullName'),
              width: theme.spacing(20),
              align: 'left',
              sortable: true,
            },
            {
              field: 'Address',
              headerName: t('savedIllustrationTranslation:table.Address'),
              width: theme.spacing(25),
              align: 'left',
              sortable: true,
            },
            {
              field: 'Zip',
              headerName: t('savedIllustrationTranslation:table.Zip'),
              width: theme.spacing(25),
              align: 'left',
              sortable: true,
            },
            {
              field: 'Age',
              headerName: t('savedBorrowerProfileTranslation:table.Age'),
              width: theme.spacing(20),
              align: 'left',
              sortable: true,
            },
            {
              type: 'date',
              field: 'DateOfBirth',
              headerName: t('savedIllustrationTranslation:table.DateOfBirth'),
              width: theme.spacing(20),
              align: 'left',
              valueGetter: (params) => new Date(params.value),
              sortable: true,
            },
            {
              field: 'ValueOfProperty',
              headerName: t('savedIllustrationTranslation:table.ValueOfProperty'),
              width: theme.spacing(30),
              align: 'left',
              valueFormatter: formatCurrencyValue,
              sortable: true,
            },
            {
              field: 'MortgageBalance',
              headerName: t('savedIllustrationTranslation:table.MortgageBalance'),
              width: theme.spacing(30),
              align: 'left',
              sortable: true,
              valueFormatter: formatCurrencyValue,
            },
            {
              field: 'ApiRoute',
              headerName: t('savedIllustrationTranslation:table.ApiRoute'),
              width: theme.spacing(20),
              align: 'left',
              valueGetter: formatApiRouteValue(products),
              sortable: true,
            },
            {
              field: 'dateCreated',
              headerName: t('savedIllustrationTranslation:table.dateSaved'),
              width: theme.spacing(20),
              align: 'left',
              valueFormatter: formatDateValue,
              sortable: true,
            },
          ]}
          rows={savedIllustrations}
          checkboxSelection={true}
          onSelectionModelChange={handleRowsSelection}
          onCellClick={goToIllustration}
          sortingOrder={['asc', 'desc', null]}
          loading={loading}
          columnBuffer={Number.MAX_SAFE_INTEGER}
          logger={DataGridLogger}
          rowHeight={theme.spacing(6)}
          autoHeight
          components={{
            LoadingOverlay: () => (
              <GridOverlay>
                <div style={{position: 'absolute', top: 0, width: '100%'}}>
                  <LinearLoader loading={LOADING_STATUS.PENDING} />
                </div>
              </GridOverlay>
            ),
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
          pagination
          sortModel={sortModel}
          onSortModelChange={(sortModel) => setSortModel(sortModel)}
        />
      </Box>
    </Container>
  );
}

SavedIllustratorPage.propTypes = {};

export default withProducts(SavedIllustratorPage, {productFeatureKey: PRODUCT_FEATURE_KEY.ILLUSTRATION_DDL});
