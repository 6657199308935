import React from 'react';
import {ProductContextProvider} from 'contexts/product-context';
import {useFixedHook} from 'components/products/fixed/FixedHook';
import {useCalculateFixedQuery} from 'query/products/hecm/fixed';
import {useFixedOverallResults} from 'components/products/fixed/FixedOverallResults';
import {API_ROUTES} from 'constants/illustrator';
import {useProductWithRef} from 'hooks/product-ref';

/**
 *
 * This is the "Fixed" product, aka "HECM Fixed", part of the "HECM" products.
 *
 */

const Fixed = React.forwardRef(function FixedRender(props, ref) {
  const {product, setRef, ...rest} = props;

  const fixedHook = useFixedHook({product});
  const fixedQuery = useCalculateFixedQuery({product});
  const fixedOverallResults = useFixedOverallResults({product});

  useProductWithRef({
    productDetails: fixedHook,
    productResults: fixedOverallResults,
    productQuery: fixedQuery,
    ref,
    ApiRoute: API_ROUTES.FIXED,
  });

  return (
    <ProductContextProvider
      productDetails={fixedHook}
      productResults={fixedOverallResults}
      productQuery={fixedQuery}
      product={product}
      {...rest}
    />
  );
});

export default Fixed;
