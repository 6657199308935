import userInfoService from 'api-okta/userinfo';
import {useQuery} from 'react-query';
import {oktaUserInfoQueryKey} from 'constants/query';
import {ToString} from 'lib/utils';
import {useAuthenticated} from 'hooks/authenticate';

export function useUserInfo() {
  const {isAuthenticated} = useAuthenticated();

  const queryResults = useQuery({
    queryKey: [oktaUserInfoQueryKey],
    enabled: isAuthenticated,
    select(response) {
      const {data} = response;
      const FirstName = ToString(data?.given_name);
      const LastName = ToString(data?.family_name);
      const Id = ToString(data?.sub);

      return {
        Email: ToString(data?.email),
        Phone: '',
        FirstName,
        LastName,
        FullName: `${FirstName} ${LastName}`.trim(),
        Id: Id,
      };
    },
    queryFn() {
      return userInfoService.get();
    },
  });

  return {...queryResults, userInfo: queryResults.data};
}
