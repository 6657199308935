import React, {useCallback, useState} from 'react';
import Input from 'components/common/form/input/Input';
import IconButton from 'components/common/button/IconButton';

SocialSecurityNumber.propTypes = {};

function SocialSecurityNumber(props) {
  const [visibility, setVisibility] = useState(false);

  const handleClickToggleVisibility = useCallback(() => {
    setVisibility(!visibility);
  }, [setVisibility, visibility]);

  const handleHideVisibility = useCallback(() => {
    setVisibility(false);
  }, [setVisibility]);

  const handleMouseDownVisibility = useCallback((event) => {
    event.preventDefault();
  }, []);

  return (
    <Input
      {...props}
      type="ssn"
      visibility={visibility}
      onMouseLeave={handleHideVisibility}
      InputProps={{
        endAdornment: (
          <IconButton
            size="sm"
            onClick={handleClickToggleVisibility}
            onMouseDown={handleMouseDownVisibility}
            icon={['fas', visibility ? 'eye' : 'eye-slash']}
            color="default"
          />
        ),
      }}
    />
  );
}

export default SocialSecurityNumber;
