import {ToNumber, ToNumberOrDefault, ToString} from 'lib/utils';
import {useCallback} from 'react';
import {useHecmValidation} from 'query/products/hecm/hecm-validation';
import {useHECMAvailableRatesQuery} from 'query/products/hecm/hecmAvailableRates';
import {API_ROUTES, FUND_ACCESS} from 'constants/illustrator';

export function useCalculateAnnualQuery(props) {
  const {data: annualRates, defaultRate} = useHECMAvailableRatesQuery();

  const addAdditionalData = useCallback(
    (data, illustration) => {
      const {TenureResult: {MaxMonthlyPayment, ConversionAge} = {}} = illustration;

      let TenureMonthlyPayments = illustration?.advancedOptions?.map((option) => option?.tenurePayment);

      if (ToNumber(TenureMonthlyPayments?.filter(Boolean).length) <= 0) TenureMonthlyPayments = null;

      return {
        CashAtClose: data.CashAtClose,
        Margin: ToNumberOrDefault(
          annualRates.find((rate) => rate.Margin === ToNumber(illustration.Margin))?.Margin,
          defaultRate?.Margin,
        ),
        FundAccess: ToString(data.FundAccess, FUND_ACCESS.LINE_OF_CREDIT),
        ConversionAge,
        MaxMonthlyPayment,
        TenureMonthlyPayments,
      };
    },
    [annualRates, defaultRate],
  );

  const addAdditionalResults = useCallback((data) => {
    return {
      HecmMetric: data.HecmMetric,
      TotalInitialRate: ToNumber(data.TotalInitialRate),
      Margin: ToNumberOrDefault(data.Margin),
      showLearnMore: false,
      hideModelImpactButton: false,
      FundAccess: ToString(data.FundAccess, FUND_ACCESS.LINE_OF_CREDIT),
      TenureResult: data?.TenureResult,
    };
  }, []);

  const {addProductValidation} = useHecmValidation();

  return {
    ApiRoute: API_ROUTES.ANNUAL,
    enabled: !!defaultRate,
    addAdditionalData,
    addAdditionalResults,
    addProductValidation,
  };
}
