import React, {useMemo, useState, useCallback, useEffect} from 'react';
import Switch from 'components/common/form/switch/Switch';
import {API_ROUTES, FUND_ACCESS, PRODUCT_FUND_ACCESS} from 'constants/illustrator';
import Input from 'components/common/form/input/Input';
import Text from 'components/common/typeography/text/Text';
import yup from 'lib/validation';
import {getAdvancedOptionsFieldDefaultValidation, getFundAccessValidation} from 'lib/validation/validation';
import {useTranslation} from 'lib/i18n';
import secondImg from 'assets/images/products/second.svg';
import SecondRatesSelect from 'components/products/second/SecondRatesSelect';
import FundAccessInput from 'components/common/inputs/FundAccessInput';
import {map} from 'lodash';
import {formatCurrency} from 'lib/currency';

const additionalStepValidation = yup.object({
  HomeSafeMetricId: yup.number(),
  LenderCredit: yup.bool(),
  CashAtClose: yup.number().nullable(),
  ClosingCost: yup.number().nullable(),
  OverrideClosingCosts: yup.bool(),
  FundAccess: getFundAccessValidation({values: map(PRODUCT_FUND_ACCESS[API_ROUTES.SECOND], 'value')}),
  advancedOptions: getAdvancedOptionsFieldDefaultValidation({
    ApiRoute: API_ROUTES.SECOND,
    monthlyPayment: yup.number().max(0),
  }),
});

export function useSecondHook(props = {}) {
  const {
    product: {
      calculation: {
        OverrideClosingCosts,
        OverrideClosingCostsKey,
        ClosingCost,
        SimplifiedCashToBorrower,
        MinCashAtClose
      } = {},
    } = {},
  } = props;

  const isShortToClose = useMemo(() => {
    return SimplifiedCashToBorrower < 0 ? true : false;
  }, [SimplifiedCashToBorrower]);

  const [maxCashAtClose, setMaxCashAtClose] = useState(SimplifiedCashToBorrower);

  useEffect(() => {
    setMaxCashAtClose(SimplifiedCashToBorrower);

  }, [SimplifiedCashToBorrower]);

  const {t} = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [maxClosingCost, setMaxClosingCost] = useState(ClosingCost);

  useEffect(() => {
    setIsDisabled(!OverrideClosingCosts);
    if(!OverrideClosingCosts){
      setMaxClosingCost(ClosingCost);
    }
  },[OverrideClosingCostsKey]);

  const toggleDisabled = useCallback(
    (event) => {
      setIsDisabled(!event);
    },
    [],
  );
  return {
    description: t('illustratorTranslation:illustration.products.second.description'),

    img: secondImg,

    lifetimeModelOptionHeader: useMemo(
      () => [
        {
          key: 'homeValueYearEnd',
          group: 1,
          formatter: (props) => formatCurrency(props.row.homeValueYearEnd),
          minWidth: 100,
          listData: 'homeValueYearStart',
        },
        {
          key: 'loanBalanceValueYearEnd',
          group: 2,
          formatter: (props) => formatCurrency(props.row.loanBalanceValueYearEnd),
          minWidth: 100,
          listData: 'loanBalanceValueYearStart',
        },
      ],
      [],
    ),
    hideAnnualPayments: true,
    advancedOptionsDisclaimer: t('illustratorTranslation:illustration.products.second.advanced options disclaimer'),
    additionalAdvancedOptionHeader: [],
    additionalStepTitle: t('illustratorTranslation:illustration.products.second.additional step title'),
    skipAdditionalStep: true,
    additionalStepValidation,
    additionalQuickFill: useMemo(() => [<div order = "6">{!isDisabled && <Text order = "6" variant="caption" colorVariant="grey">Max closing costs {formatCurrency(maxClosingCost)}</Text>}</div>, <Switch order="7" name="OverrideClosingCosts" defaultValue={isDisabled} onChange={toggleDisabled} />], [isDisabled]),
    additionalStep: useMemo(
      () => [
        <SecondRatesSelect order="1" name="HomeSafeMetricId" />,
        <Input order="3" name="CashAtClose" type="currency" defaultValue={0}  disabled ={isShortToClose}/>,
        <div order = "5">{(!isShortToClose && MinCashAtClose > 0) && <Text order = "5" variant="caption" colorVariant="grey">Requested cash cannot be lower than {formatCurrency(MinCashAtClose)}</Text>}</div>,
        <div order = "4">{!isShortToClose && <Text order = "4" variant="caption" colorVariant="grey">Cash cannot exceed {formatCurrency(maxCashAtClose)}</Text>}</div>,
        <Input order="6" name="ClosingCost" type="currency" defaultValue={0} disabled={isDisabled} />,
        <FundAccessInput order="8" type="hidden" defaultValue={FUND_ACCESS.LINE_OF_CREDIT} ApiRoute={API_ROUTES.SECOND} />,
      ],
      [isDisabled, isShortToClose, maxCashAtClose, MinCashAtClose],
    ),
    illustratorBorrowerConfig: {
      declaration: [
        {
          value: 'OutstandingJudgement', // 1009 A
          order: 1,
        },
        {
          value: 'DeclaredBankruptcy', // 1009 B
          order: 2,
        },
        {
          value: 'LawsuitParty', // 1009 C
          order: 3,
        },
        {
          value: 'LoanDelinquency', // 1009 D
          order: 4,
          options: [
            {
              name: 'LoanDelinquencyDate', // 1009 D.1
              type: 'datePicker',
              order: 1,
            },
            {
              name: 'LoanDelinquencyLenderName', // 1009 D.2
              type: 'input',
              order: 2,
            },
            {
              name: 'LoanDelinquencyLenderAddress', // 1009 D.3
              type: 'input',
              order: 3,
            },
            {
              name: 'LoanDelinquencyFHACaseNumber', // 1009 D.4
              type: 'input',
              order: 4,
            },
            {
              name: 'LoanDelinquencyReason', // 1009 D.5
              type: 'input',
              order: 5,
            },
          ],
        },
        {
          value: 'PropertyOccupation', // 1009 E
          order: 5,
        },
        {
          value: 'NoteEndorser', // 1009 F
          order: 6,
        },
        {
          value: 'UsCitizen', // 1009 G
          order: 7,
        },
        {
          value: 'ResidentAlien', // 1009 H
          order: 8,
        },
        {
          value: 'DownPaymentRequired', // 1009 I
          order: 9,
          options: [
            {
              name: 'DownPaymentBorrowed', // 1009 I.1
              type: 'switch',
              order: 1,
            },
          ],
        },
        {
          value: 'ReverseMortgageInvestment', // 1009 J
          order: 10,
          options: [
            {
              name: 'ReverseMortgageInvestmentProductName', // 1009 J.1
              type: 'input',
              order: 1,
            },
            {
              name: 'ReverseMortgageInvestmentProductCost', // 1009 J.2
              type: 'currency',
              order: 2,
            },
          ],
        },
        {
          value: 'HasExistingFHAInsuredLoan', // 1009 K
          order: 11,
          options: [
            {
              name: 'ExistingFHAInsuredLoanCreditorName', // 1009 K.1
              type: 'input',
              order: 1,
            },
            {
              name: 'ExistingFHAInsuredLoanStreet', // 1009 K.2
              type: 'input',
              order: 2,
            },
            {
              name: 'ExistingFHAInsuredLoanCity', // 1009 K.3
              type: 'input',
              order: 3,
            },
            {
              name: 'ExistingFHAInsuredLoanState', // 1009 K.4
              type: 'dropDown',
              dropDownSource: 'usStates',
              order: 4,
            },
            {
              name: 'ExistingFHAInsuredLoanZipCode', // 1009 K.5
              type: 'zip',
              order: 5,
            },
            {
              name: 'ExistingFHAInsuredLoanAccountNumber', // 1009 K.6
              type: 'input',
              order: 6,
            },
            {
              name: 'ExistingFHAInsuredLoanDispositionType', // 1009 K.7
              type: 'dropDown',
              dropDownSource: 'existingFHAInsuredLoanDispositionType',
              order: 7,
            },
            {
              name: 'ExistingFHAInsuredLoanMortgageAmount', // 1009 K.8
              type: 'currency',
              order: 8,
            },
            {
              name: 'ExistingFHAInsuredLoanUnpaidLoanBalance', // 1009 K.9
              type: 'currency',
              order: 9,
            },
          ],
        },
      ],
    },
    additionalBorrowerDetailsInputs: [],
    additionalBorrowerFlowPropertyPageInputs: useMemo(
      () => [<Input name="propertyFirstLien" type="currency" variant="filled" required order="8" />],
      [],
    ),
  };
}
