import React from 'react';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import clsx from 'clsx';
import {useFormErrors} from 'hooks/use-form-errors';
import {useTranslation} from 'lib/i18n';
import useStyles from './DemographicInfoErrorsStyles';

export const DEMOGRAPHIC_ERROR = 'demographic-error';

function DemographicInfoErrors(props) {
  const {prefix, validationSchema} = props;
  const {type, isVisible} = useFormErrors({prefix, validationSchema});

  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Container>
      <Item
        xs={12}
        className={clsx(classes.demographicInfoErrorItem, {
          [classes.demographicInfoErrorItemVisible]: isVisible,
          [DEMOGRAPHIC_ERROR]: isVisible,
        })}
      >
        {t(`illustratorTranslation:illustrator borrower interviewer page.${type}`)}
      </Item>
    </Container>
  );
}

export default DemographicInfoErrors;
