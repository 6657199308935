import React from 'react';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import Title from 'components/common/typeography/title/Title';
import Text from 'components/common/typeography/text/Text';
import useStyles from '../ContentSectionStyles';
import clsx from 'clsx';
import Disclaimer from 'components/common/disclaimer/Disclaimer';

const ContentContainer = React.forwardRef(function ContentContainerRender(props, ref) {
  const {title, description, input, component: Component = Container, className, disclaimer, ...rest} = props;
  const classes = useStyles();

  return (
    <Component {...rest} className={clsx(className, classes.fullPage)} ref={ref}>
      <Item xs={12} className={classes.flexContainer}>
        <Container className={classes.descriptionContainer}>
          {title && (
            <Item xs={12}>
              <Title variant="h4">{title}</Title>
            </Item>
          )}

          {description && (
            <Item xs={12}>
              <Text variant="h6" className={classes.descriptionText} dangerouslySetInnerHTML={{__html: description}} />
            </Item>
          )}

          {input ? (
            Array.isArray(input) ? (
              React.Children.map(input, (child, index) => (
                <Item key={index} xs={12}>
                  {child}
                </Item>
              ))
            ) : (
              <Item xs={12}>{input}</Item>
            )
          ) : null}

          {disclaimer && (
            <Disclaimer Component={Item} xs={12}>
              {disclaimer}
            </Disclaimer>
          )}
        </Container>
      </Item>
    </Component>
  );
});

ContentContainer.propTypes = {};

export default ContentContainer;
