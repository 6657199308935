import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    startValuesListContainer: {
      width: '100%',
      margin: 0,
    },
    startValuesListItem: {
      margin: 0,
    },
    startValuesListItemTitle: {
      marginRight: theme.spacing(1),
    },
    startValuesListInfo: {
      marginLeft: theme.forPrint(theme.spacing(3)),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
  {index: 1},
);

export default useStyles;
