import * as React from 'react';
import {useCallback, useMemo} from 'react';
import Input from 'components/common/form/input/Input';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import {omit} from 'lodash';
import {useFormContext} from 'react-hook-form';
import {useDeepMemo} from 'hooks/utils';
import {InputOptionsContextProvider, useInputOptionsContext} from 'contexts/input-options-context';
import {optionsToFlatMap} from 'lib/checkbox';

function OptionRender(props) {
  const {itemContainerProps, itemProps = {xs: 12}, option, ...rest} = props;

  const {getValues, setValue} = useFormContext();
  const {getOptions} = useInputOptionsContext();

  const optionsToWatch = useMemo(
    () =>
      optionsToFlatMap(option.options)
        .filter((child) => child.type === option.type)
        .map((child) => child.name),
    [option],
  );

  /**
   * The parent function will be called when an parent element is changed
   */
  const onParentChange = useCallback(
    (event, value) => {
      if (value === false) {
        getOptions(option.name, option.options ?? []).forEach((name) => {
          setValue(name, null);
        });
      }
    },
    [getOptions, option.name, option.options, setValue],
  );

  /**
   * The child function will be called when an child element is changed
   */
  const onChildChange = useCallback(
    (event, value) => {
      setValue(option.name, optionsToWatch.map((o) => getValues(o)).find(Boolean) ?? false);
    },
    [setValue, option.name, optionsToWatch, getValues],
  );

  const inputElement = useDeepMemo(
    () => (
      <Input
        onExternalChange={onParentChange}
        {...rest}
        {...omit(option, ['itemProps', 'inline'])}
        name={option.name}
      />
    ),
    [onParentChange, option, rest],
  );

  const optionsElement = useDeepMemo(
    () =>
      option.options?.map((childOption) => (
        <OptionRender
          {...rest}
          key={childOption.name}
          onExternalChange={onChildChange}
          option={childOption}
          itemContainerProps={itemContainerProps}
          itemProps={itemProps}
        />
      )) ?? null,
    [itemContainerProps, itemProps, onChildChange, option.options, option.name, rest],
  );

  const isInlineOption = useMemo(() => option.inline, [option]);

  return (
    <>
      <Item {...itemProps} {...option?.itemProps} noPadding>
        {inputElement}

        {isInlineOption ? null : optionsElement ? (
          <Container {...itemContainerProps}>{optionsElement}</Container>
        ) : null}
      </Item>

      {isInlineOption ? optionsElement : null}
    </>
  );
}

function InputOptions(props = {}) {
  const {containerProps = {}, options = [], ...rest} = props;

  return (
    <Container {...containerProps}>
      <InputOptionsContextProvider value={{options}}>
        {options.map((option) => (
          <OptionRender key={option.name} option={option} {...rest} />
        ))}
      </InputOptionsContextProvider>
    </Container>
  );
}

export default InputOptions;
