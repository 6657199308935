import React from 'react';
import {Alert} from '@material-ui/lab';
import Title from '../typeography/title/Title';
import {DialogTitle as MuiDialogTitle} from '@material-ui/core';
import useStyles from './DialogTitleStyles';
import {useTranslation} from 'lib/i18n';

function DialogTitle(props) {
  const {severity, ...rest} = props;
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <div>
      <Alert severity={severity} className={classes.modalAlertTitle}>
        <Title variant="h5">{t(`translation:common.${severity}`)}</Title>
      </Alert>
      <MuiDialogTitle {...rest} />
    </div>
  );
}

DialogTitle.propTypes = {};

export default DialogTitle;
