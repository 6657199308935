import {ToNumber, ToNumberOrDefault} from 'lib/utils';
import {useCallback} from 'react';
import {HOME_SAFE} from 'constants/products';
import {useHomeSafeValidation} from 'query/products/home-safe/homesafe-validation';
import {isLoaded} from 'adapter/loading.adapter';
import {useHomeSafeMetricsQuery} from 'query/products/home-safe/homesafe-metrics';

export function useCalculateSelectQuery(props) {
  const {
    data: tiers = [],
    defaultTier,
    loading,
  } = useHomeSafeMetricsQuery({
    ProductId: props?.product?.ProductId,
  });

  const addAdditionalData = useCallback(
    (data, illustration) => {
      const margin = tiers.find((Rate) => Rate.Rate === illustration.Margin);

      return {
        Margin: ToNumberOrDefault(margin?.Rate, defaultTier?.Rate),
        Type: HOME_SAFE,
        HomeSafeType: 'select',
        TotalUpfrontDraw: ToNumberOrDefault(illustration.TotalUpfrontDraw, null),
      };
    },
    [tiers, defaultTier],
  );

  const addAdditionalResults = useCallback(
    (data) => {
      return {
        TotalInitialRate: ToNumber(data.TotalInitialRate),
        showLearnMore: false,
        hideModelImpactButton: false,
        homeSafeSelectRates: tiers,
        TotalUpfrontDraw: ToNumberOrDefault(data.TotalUpfrontDraw, ''),
      };
    },
    [tiers],
  );

  const {addProductValidation} = useHomeSafeValidation();

  return {
    enabled: isLoaded({loading}) && !!defaultTier,
    addAdditionalData,
    addAdditionalResults,
    addProductValidation,
    tiers,
    defaultTier,
  };
}
