import React, {useCallback, useMemo, useState} from 'react';
import useStyles from './SavedBorrowerProfilesPageStyles';
import {LOADING_STATUS} from 'constants/loading';
import {Trans, useTranslation} from 'lib/i18n';
import {isLoaded, isPending} from 'adapter/loading.adapter';

import {useSavedBorrowerProfilesDeleteMutation, useSavedBorrowerProfilesQuery} from 'query/borrower/save-borrower-info';

import Container from 'components/common/layout/container/Container';
import {Box, useTheme} from '@material-ui/core';
import {DataGrid, GridOverlay} from '@mui/x-data-grid';
import {DataGridLogger} from 'services/logger.service';
import Title from 'components/common/typeography/title/Title';
import Button from 'components/common/button/Button';
import LinearLoader from 'components/common/loader/LinearLoader';
import SlideOutModal from 'pages/slide-out-modal-page/slide-out-modal/SlideOutModal';
import {useHistory} from 'react-router-dom';
import {addOneIllustration, removeAllIllustrations} from 'reducers/illustrator.reducer';
import {useDispatch} from 'react-redux';
import SlideOutModalSubmitButton from 'pages/slide-out-modal-page/slide-out-modal/components/SlideOutModalSubmitButton';
import {ROUTES} from 'constants/routes';
import {DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS} from 'constants/mui-data-grid';
import {useDataGridControl, useDataGridFormat} from 'hooks/data-grid';

function useRowSelected() {
  const [selectedRows, setSelectedRows] = useState([]);
  const {mutate: deleteSavedBorrowerProfile, loading: deleteSavedBorrowerProfileLoading} =
    useSavedBorrowerProfilesDeleteMutation();

  const {data: savedProfiles, loading: savedProfilesQueryLoading} = useSavedBorrowerProfilesQuery();

  const handleRowsSelection = useCallback(
    (model) => {
      setSelectedRows(Object.values(model) || []);
    },
    [setSelectedRows],
  );

  const handleDelete = useCallback(
    (params) => {
      deleteSavedBorrowerProfile(selectedRows);
    },
    [deleteSavedBorrowerProfile, selectedRows],
  );

  const defaultValues = useMemo(
    () => savedProfiles.find((profile) => profile.id === selectedRows[0]),
    [savedProfiles, selectedRows],
  );

  const hasSelectedRows = useMemo(() => selectedRows.length > 0, [selectedRows]);
  const selectedRowCount = useMemo(() => selectedRows.length, [selectedRows]);

  return {
    selectedRows,
    setSelectedRows,
    handleRowsSelection,
    handleDelete,
    hasSelectedRows,
    selectedRowCount,
    deleteSavedBorrowerProfileLoading,
    savedProfiles,
    savedProfilesQueryLoading,
    defaultValues,
  };
}

/**
 *
 * The "Saved Borrower Profiles" main component.
 *
 * route : /borrower-profiles
 *
 */

function SavedBorrowerProfilesPage(props) {
  const theme = useTheme();
  const classes = useStyles();
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {formatCurrencyValue, formatDateValue, formatName} = useDataGridFormat();
  const {disableSelectionOnClick} = useDataGridControl();

  const {handleDelete, hasSelectedRows, handleRowsSelection, savedProfiles, savedProfilesQueryLoading, defaultValues} =
    useRowSelected();

  const goToIllustration = useCallback(
    async ({row}) => {
      if (!disableSelectionOnClick) return;

      const {id: BorrowerProfileId} = row;

      await dispatch(removeAllIllustrations());
      await dispatch(
        addOneIllustration({
          BorrowerProfileId,
          BorrowerProfile: savedProfiles?.find((profile) => profile.id === BorrowerProfileId),
        }),
      );

      return history.push(ROUTES.ILLUSTRATOR_ROOT_PAGE);
    },
    [history, dispatch, savedProfiles, disableSelectionOnClick],
  );

  const [pageSize, setPageSize] = React.useState(DEFAULT_PAGE_SIZE);

  const loading = useMemo(() => {
    if (isLoaded({loading: savedProfilesQueryLoading})) return false;
    else if (isPending({loading: savedProfilesQueryLoading})) return true;
    return true;
  }, [savedProfilesQueryLoading]);

  return (
    <Container className={classes.root}>
      <Box className={classes.titleContainer}>
        <Title variant="h4" colorVariant="primaryDark">
          <Trans i18nKey="savedBorrowerProfileTranslation:title" />
        </Title>
        {
          // Only one row can be edited at a time. Since more than one row can be deleted this check is required
          // to only show the edit button when one row and one row only is selected.
          // If one row is selected both the delte and edit button will show
          // If more than one row is selected just the delete button will show
          //
          // NOTE: This is a temporary solution. An inline edit solution is in future development to replace
          //       the logic for the delete and edit buttons.
        }
        <Box>
          {hasSelectedRows && (
            <Button buttonStyling={false} className={classes.deleteButton} btnStyle="bgError" onClick={handleDelete}>
              <Trans i18nKey="savedBorrowerProfileTranslation:delete button" />
            </Button>
          )}

          <SlideOutModal
            isBorrowDetails={true}
            defaultValues={defaultValues}
            submitButtonLabel={t('illustratorTranslation:illustration.save illustration')}
          >
            {hasSelectedRows && (
              <SlideOutModalSubmitButton buttonStyling={false} className={classes.editButton} variant="text">
                <Trans i18nKey="savedBorrowerProfileTranslation:edit button" />
              </SlideOutModalSubmitButton>
            )}
          </SlideOutModal>
        </Box>
      </Box>

      <Box className={classes.gridContainer}>
        <DataGrid
          columns={[
            {
              field: 'Name',
              headerName: t('savedBorrowerProfileTranslation:table.Name'),
              width: theme.spacing(20),
              align: 'left',
              sortable: true,
              valueGetter: formatName,
            },
            {
              field: 'propertyAddress',
              headerName: t('savedBorrowerProfileTranslation:table.PropertyAddress'),
              width: theme.spacing(20),
              align: 'left',
              sortable: true,
            },
            {
              field: 'Zip',
              headerName: t('savedBorrowerProfileTranslation:table.Zip'),
              width: theme.spacing(25),
              align: 'left',
              sortable: true,
            },
            {
              field: 'Age',
              headerName: t('savedBorrowerProfileTranslation:table.Age'),
              width: theme.spacing(20),
              align: 'left',
              sortable: true,
            },
            {
              field: 'DateOfBirth',
              headerName: t('savedBorrowerProfileTranslation:table.DateOfBirth'),
              width: theme.spacing(20),
              align: 'left',
              sortable: true,
            },
            {
              field: 'ValueOfProperty',
              headerName: t('savedBorrowerProfileTranslation:table.PropertyValue'),
              width: theme.spacing(25),
              align: 'left',
              sortable: true,
              valueFormatter: formatCurrencyValue,
            },
            {
              field: 'Liens',
              headerName: t('savedBorrowerProfileTranslation:table.MortgageBalance'),
              width: theme.spacing(30),
              align: 'left',
              sortable: true,
              valueFormatter: formatCurrencyValue,
            },
            {
              field: 'DateSaved',
              headerName: t('savedBorrowerProfileTranslation:table.dateSaved'),
              width: theme.spacing(20),
              align: 'left',
              sortable: true,
              valueFormatter: formatDateValue,
            },
          ]}
          rows={savedProfiles}
          checkboxSelection={true}
          onSelectionModelChange={handleRowsSelection}
          onRowClick={goToIllustration}
          sortingOrder={['asc', 'desc', null]}
          loading={loading}
          disableSelectionOnClick={disableSelectionOnClick}
          columnBuffer={Number.MAX_SAFE_INTEGER}
          logger={DataGridLogger}
          rowHeight={theme.spacing(6)}
          autoHeight
          components={{
            LoadingOverlay: () => (
              <GridOverlay>
                <div style={{position: 'absolute', top: 0, width: '100%'}}>
                  <LinearLoader loading={LOADING_STATUS.PENDING} />
                </div>
              </GridOverlay>
            ),
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
          pagination
          sortModel={[
            {
              field: 'DateSaved',
              sort: 'desc',
            },
          ]}
        />
      </Box>
    </Container>
  );
}

SavedBorrowerProfilesPage.propTypes = {};

export default SavedBorrowerProfilesPage;
