import {useTranslation} from 'lib/i18n';
import {useMemo} from 'react';

export default function usePropertyVestedInATrustOptions() {
  const {t} = useTranslation();

  return useMemo(
    () => t('inputsTranslation:borrower info.property vested in a trust options', {returnObjects: true}),
    [t],
  );
}
