import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {oktaAuth} from 'services/auth.service';

const isAbsoluteUri = /^(?:[a-z+]+:)?\/\//i;

function LoginCallback() {
  const history = useHistory();

  useEffect(() => {
    const uri = oktaAuth.getOriginalUri();

    try {
      let path = uri || '/';
      if (uri && isAbsoluteUri.test(uri)) {
        path = new URL(uri).pathname;
      }

      history.push(decodeURIComponent(path));
    } catch (e) {
      history.push('/');
    }
  }, [history]);

  return null;
}

export default LoginCallback;
