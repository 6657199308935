import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    '@keyframes bounce': {
      '50%': {
        transform: 'translateY(-50%)',
      },
    },

    section: {
      textAlign: 'center',
      position: 'relative',
      width: '100%',

      '&::before': {
        content: "'╲╱'",
        animation: '$bounce 1s ease infinite',
        bottom: theme.spacing(-10),
        color: theme.palette.primary.dark,
        fontSize: theme.spacing(4),
        letterSpacing: theme.spacing(-0.55),
        marginLeft: theme.spacing(-3),
        opacity: 0.8,
        position: 'absolute',
        textAlign: 'center',
      },

      display: theme.forPrint('none'),
    },
  }),
  {index: 1},
);

export default useStyles;
