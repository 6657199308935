import {useTranslation} from 'lib/i18n';
import {PRODUCT_FUND_ACCESS} from 'constants/illustrator';
import {useMemo} from 'react';

export function useProductFundAccess(ApiRoute) {
  const {t} = useTranslation();

  const FundAccess = useMemo(() => PRODUCT_FUND_ACCESS[ApiRoute] || [], [ApiRoute]);

  return useMemo(
    () =>
      FundAccess.map((fa) => ({
        ...fa,
        label: t(fa.label),
      })),
    [FundAccess, t],
  );
}
