import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    content: {
      backgroundColor: theme.palette.white.light,
      display: theme.withPrint('flex', 'block'),
    },
  }),
  {index: 1},
);

export default useStyles;
