import React, {useCallback} from 'react';
import Input from 'components/common/form/input/Input';
import ContentContainer from '../content-section/components/ContentContainer';
import {useVisible} from 'hooks/utils';
import {useResponsiveText, useTranslation} from 'lib/i18n';
import {alpha, useTheme} from '@material-ui/core';
import {useSpace} from 'hooks/print';
import {useOwnFormContext} from 'contexts/form-context';

function CurrentMortgageSection(props) {
  const {setChartData, setChartDisclaimer} = props;

  const theme = useTheme();

  const {MortgagePayment, NewMonthlyPayment} = useOwnFormContext();
  const {t, i18n} = useTranslation();
  const {space} = useSpace();
  const mortgagePaymentLabel = useResponsiveText('current mortgage section.inputs.MortgagePayment.label');
  const yearsRemainingLabel = useResponsiveText('current mortgage section.inputs.YearsRemaining.label');

  const onEnterViewport = useCallback(() => {
    const chartData = [];

    if (MortgagePayment) {
      chartData.push(
        {
          name: t('mortgage chart section.chart label.x axis', {space}),
          y: MortgagePayment,
          fill: alpha(theme.palette.primary.dark, 0.6),
        },
        {
          name: t('mortgage chart section.chart label.NewMortgage'),
          y: NewMonthlyPayment,
          fill: alpha(theme.palette.primary.main, 0.6),
          visibility: 'hidden',
        },
      );
    }

    setChartData(chartData);
    setChartDisclaimer(null);
  }, [t, MortgagePayment, theme, NewMonthlyPayment, setChartData, setChartDisclaimer, space]);

  const {visibleNodeRef} = useVisible({onEnterViewport, onVisible: onEnterViewport, threshold: 0.75});

  return (
    <ContentContainer
      ref={visibleNodeRef}
      title={t('current mortgage section.title')}
      description={t('current mortgage section.description')}
      input={[
        <Input
          key="MortgagePayment"
          label={t(mortgagePaymentLabel)}
          name="BorrowerProfile.ExistingMortgage.MortgagePayment"
          type="currency"
          variant="standard"
          fullWidth
        />,
        <Input
          key="YearsRemaining"
          label={t(yearsRemainingLabel)}
          name="BorrowerProfile.ExistingMortgage.YearsRemaining"
          type="number"
          variant="standard"
          fullWidth
          autoSubmit
        />,
      ]}
    />
  );
}

CurrentMortgageSection.propTypes = {};

export default CurrentMortgageSection;
