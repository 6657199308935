import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {PersistGate as ReduxPersistProvider} from 'redux-persist/integration/react';
import {SnackbarProvider} from 'notistack';

import {persistor, store} from 'lib/store';
import StyleContentProvider from 'contexts/style-content-context';
import {PrintContextProvider} from 'contexts/print-context';
import BodyLockProvider from 'contexts/body-lock-context';
import QueryClientProvider from 'providers/QueryClientProvider';
import SecurityProvider from 'providers/SecurityProvider';

function Providers(props) {
  const {children} = props;
  return (
    <React.StrictMode>
      <QueryClientProvider>
        <ReduxProvider store={store}>
          <ReduxPersistProvider loading={null} persistor={persistor}>
            <SnackbarProvider maxSnack={3}>
              <SecurityProvider>
                <StyleContentProvider>
                  <PrintContextProvider>
                    <BodyLockProvider>{children}</BodyLockProvider>
                  </PrintContextProvider>
                </StyleContentProvider>
              </SecurityProvider>
            </SnackbarProvider>
          </ReduxPersistProvider>
        </ReduxProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

Providers.propTypes = {};

export default Providers;
