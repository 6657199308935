import React, {useCallback, useMemo, useState} from 'react';
import useStyles from './SlideOutModalStyles';
import {isFunction} from 'lodash';
import {useEnabledProductFeatures} from 'hooks/product-features';
import {useSaveBorrowerInfoMutation} from 'query/borrower/save-borrower-info';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {ToZipCode} from 'lib/utils';
import SlideOutModalForm from 'pages/slide-out-modal-page/slide-out-modal/components/SlideOutModalForm';
import {useTranslation} from 'lib/i18n';
import Title from 'components/common/typeography/title/Title';
import clsx from 'clsx';
import Dialog from 'components/common/dialog/Dialog';
import {useUpdateIllustrations} from 'hooks/illustrator-form';
import {useSnackbar} from 'notistack';
import {trackEvent} from 'services/analytics.service';
import {SCENARIO_ACTIONS, SCENARIO_CATEGORIES} from 'constants/analytic-events';

export function useSlideOutModalProps(props = {}) {
  const {enabled = true, handleClose, defaultValues: BorrowerProfile = {}, onSubmit, onOpen} = props;

  const defaultValues = useMemo(() => {
    /**
     *
     * mapping the "Borrower Profile" fields to the form fields
     *
     */

    return {
      BorrowerProfileId: BorrowerProfile.BorrowerProfileId || '',
      FirstName: BorrowerProfile.FirstName || '',
      LastName: BorrowerProfile.LastName || '',
      DateOfBirth: BorrowerProfile.DateOfBirth || '',
      Email: BorrowerProfile.Email || '',
      propertyAddress: BorrowerProfile.Address || '',
      ValueOfProperty: BorrowerProfile.ValueOfProperty || '',
      Zip: ToZipCode(BorrowerProfile.Zip) || '',
      city: BorrowerProfile.city || '',
      State: BorrowerProfile.State || '',
      county: BorrowerProfile.county || '',
      Liens: BorrowerProfile.Liens || '',
      IsSaved: BorrowerProfile.IsSaved || false,
    };
  }, [BorrowerProfile]);

  const {isEnabled: isSlideOutModalEnabled} = useEnabledProductFeatures(PRODUCT_FEATURE_KEY.BORROWER_ADVANCED_DETAILS);
  const [open, setOpen] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const {updateIllustrationsBorrowerProfile} = useUpdateIllustrations();

  const doOpen = useCallback(() => {
    if (enabled && isSlideOutModalEnabled) setOpen(true);
    if (isFunction(onOpen)) onOpen();
  }, [enabled, setOpen, isSlideOutModalEnabled, onOpen]);

  const doClose = useCallback(
    (event) => {
      if (isFunction(handleClose)) handleClose(event);
      setOpen(false);
    },
    [handleClose, setOpen],
  );

  const onSuccess = useCallback(
    (response, ...r) => {
      updateIllustrationsBorrowerProfile({
        old: defaultValues.BorrowerProfileId,
        new: response?.data,
        IsSaved: true,
      });
    },
    [updateIllustrationsBorrowerProfile, defaultValues],
  );

  const saveBorrowerInfoMutation = useSaveBorrowerInfoMutation({onSuccess});

  const doSubmit = useCallback(
    async (BorrowerProfile) => {
      if (isFunction(onSubmit)) await onSubmit(BorrowerProfile);

      return saveBorrowerInfoMutation.mutateAsync(
        {BorrowerProfile},
        {
          onSuccess(response) {
            enqueueSnackbar(t('illustratorTranslation:illustration.saved illustration'), {variant: 'success'});
          },
        },
      );
    },
    [onSubmit, saveBorrowerInfoMutation, enqueueSnackbar, t],
  );

  return {
    open,
    doOpen,
    doClose,
    doSubmit,
    defaultValues,
    enabled,
  };
}

function SlideOutModal(props) {
  const {submitButtonLabel, children, showProgress, isBorrowDetails} = props;
  const {open, doOpen, doClose, defaultValues, enabled, doSubmit} = useSlideOutModalProps(props);
  const classes = useStyles();

  const doCloseAndTrackSubmit = useCallback(() => {
    doClose();

    if (isBorrowDetails) {
      trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_SAVED_CLOSE_BORROWER_DETAILS);
    } else {
      trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_SAVED_CLOSE_PAYMENT_BREAKDOWN);
    }
  }, [doClose, isBorrowDetails]);

  const {t} = useTranslation();
  return (
    <React.Fragment key="SlideOutModal">
      <Dialog
        paperMargin={false}
        open={open}
        classes={{paper: classes.dialog}}
        onClose={doCloseAndTrackSubmit}
        dialogHeaderProps={{
          doClose: doCloseAndTrackSubmit,
          closeButtonClassName: classes.closeButtonIcon,
          className: classes.slideOutHeaderContainer,
          childrenContainerClassName: classes.slideOutHeaderChildrenContainer,
          dialogHeaderBoxButtonsClassName: classes.dialogHeaderBoxButtonsClassName,
          children: (
            <Title variant="h4" className={clsx('strong', classes.slideOutTitleElement)}>
              {t('borrowerDetailsModalTranslation:getting to know the borrower and their property')}
            </Title>
          ),
        }}
      >
        <SlideOutModalForm
          onSubmit={doSubmit}
          defaultValues={defaultValues}
          submitButtonLabel={submitButtonLabel}
          doClose={doClose}
          isBorrowDetails={props.isBorrowDetails}
        />
      </Dialog>

      {children && React.cloneElement(children, {doOpen, enabled, showProgress})}
    </React.Fragment>
  );
}

SlideOutModal.propTypes = {};

SlideOutModal.defaultValues = {};

export default SlideOutModal;
