import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../TypeographyStyles';

const Title = React.forwardRef(function TitleRender(props, ref) {
  const classes = useStyles();
  const {children, colorVariant, className, ...rest} = props;

  return (
    <Typography className={clsx(className, classes[colorVariant], 'title')} {...rest} ref={ref}>
      {children}
    </Typography>
  );
});

Title.defaultProps = {
  variant: 'h1',
  colorVariant: 'primaryDark',
};

Title.propTypes = {
  colorVariant: PropTypes.oneOf(['primaryDark', 'grey', 'secondary', 'whiteLight', 'black']),
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2']),
};

export default Title;
