import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {cloneDeep, merge} from 'lodash';
import {useProductFeature} from 'hooks/product-features';

const productCardContext = {
  description: {minHeight: 80},
};

const ProductCardContext = React.createContext(productCardContext);

export function useProductCardContext(props = {}) {
  const productCard = useContext(ProductCardContext);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current?.clientHeight > productCard.description.minHeight) {
      productCard.setProductCardContext({description: {minHeight: descriptionRef.current?.clientHeight}});
    }
  }, [descriptionRef, productCard]);

  return {...productCard, descriptionRef};
}

export function ProductCardContextProvider(props = {}) {
  const [value, setValue] = useState(productCardContext);
  const {isProductFeatureEnabled} = useProductFeature();

  const setProductCardContext = useCallback((data) => setValue(merge(cloneDeep(value), data)), [setValue, value]);
  return (
    <ProductCardContext.Provider value={{...value, setProductCardContext, isProductFeatureEnabled}}>
      {props.children}
    </ProductCardContext.Provider>
  );
}
