import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    scenarioDescription: {
      textAlign: 'left',
      fontWeight: 500,
    },
  }),
  {index: 1},
);

export default useStyles;
