import React, {useCallback} from 'react';
import {FormControl} from '@mui/material';
import {FormControlLabel, FormGroup} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './Switch3StatesElementStyles';
import {theme} from 'lib/mui-config';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {useAutoSubmit} from 'hooks/auto-submit';

const Switch3StatesElementValues = {
  TRUE: 'true',
  FALSE: 'false',
};

const Switch3StatesElement = React.forwardRef(function Switch3StatesElementRender(props, ref) {
  const {
    name,
    value = '',
    label,
    labels: {labelLeft, labelRight} = {labelLeft: 'Yes', labelRight: 'No'},
    hidden,
    color = theme.palette.primary.main,
    onChange,
    onBlur,
    autoSubmit,
    autoSubmitBlur,
    size = 'small',
  } = props;

  const classes = useStyles();

  const handleChange = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  const {onChange: onAutoSubmitHandle, onBlur: handleBlur} = useAutoSubmit({
    autoSubmitBlur,
    autoSubmit,
    onChange: handleChange,
    value,
    onBlur,
  });

  return (
    <FormControl>
      <FormGroup className={clsx({[classes.hide]: hidden})}>
        <FormControlLabel
          color={color}
          control={
            <ToggleButtonGroup
              name={name}
              value={value}
              orientation="horizontal"
              exclusive
              onBlur={handleBlur}
              onChange={onAutoSubmitHandle}
              aria-label="text alignment"
              ref={ref}
              color="primary"
              size={size}
              classes={{
                root: clsx(classes.root, {
                  [classes.leftPillActive]: value === Switch3StatesElementValues.TRUE,
                  [classes.rightPillActive]: value === Switch3StatesElementValues.FALSE,
                }),
              }}
              style={{'marginRight': label ? '10px' : 0}}
            >
              <ToggleButton
                value={Switch3StatesElementValues.TRUE}
                classes={{
                  root: clsx(
                    classes.pill,
                    {[classes.leftPillActive]: value === Switch3StatesElementValues.TRUE},
                    {[classes.leftPillInactive]: value === Switch3StatesElementValues.FALSE},
                  ),
                }}
                aria-label="left aligned"
              >
                {labelLeft}
              </ToggleButton>

              <ToggleButton
                value={Switch3StatesElementValues.FALSE}
                classes={{
                  root: clsx(
                    classes.pill,
                    {[classes.rightPillActive]: value === Switch3StatesElementValues.FALSE},
                    {[classes.rightPillInactive]: value === Switch3StatesElementValues.TRUE},
                  ),
                }}
                aria-label="right aligned"
              >
                {labelRight}
              </ToggleButton>
            </ToggleButtonGroup>
          }
          label={label}
          className={clsx(classes.marginLeftNone, classes.marginRightNone, classes.formControlLabelElement)}
        />
      </FormGroup>
    </FormControl>
  );
});

Switch3StatesElement.propTypes = {};

export default Switch3StatesElement;
