import React from 'react';
import Select from 'components/common/form/select/Select';
import {useSelectHookRangeValues} from 'hooks/input';

function InRangeSelect(props = {}) {
  const {forceOverride, ...rest} = props;

  useSelectHookRangeValues({
    name: rest.name,
    data: rest.options,
    defaultValue: rest.defaultValue,
    forceOverride,
  });

  return <Select {...rest} />;
}

export default React.memo(InRangeSelect);
