import React from 'react';
import {Divider} from '@material-ui/core';

function ExcludedDivider(props) {
  const {listItemExcluded, ...rest} = props;
  return <Divider {...rest} />;
}

ExcludedDivider.defaultProps = {
  listItemExcluded: true,
};

export default ExcludedDivider;
