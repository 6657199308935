import {ToNumber, ToString} from 'lib/utils';
import {useCallback} from 'react';
import {HOME_SAFE} from 'constants/products';
import {DEFAULT_HOME_SAFE_FLEX_TIER, DEFAULT_HOME_SAFE_FLEX_YEARS_OF_PAYMENT} from 'constants/illustrator';
import {useHomeSafeValidation} from 'query/products/home-safe/homesafe-validation';

export function useCalculateFlexQuery(props) {
  const addAdditionalData = useCallback((data) => {
    return {
      Type: HOME_SAFE,
      HomeSafeType: 'Flex',
      HomeSafeTier: ToString(data.HomeSafeTier, DEFAULT_HOME_SAFE_FLEX_TIER),
      YearsOfPayment: ToNumber(data.ExistingMortgage?.YearsRemaining, DEFAULT_HOME_SAFE_FLEX_YEARS_OF_PAYMENT),
    };
  }, []);

  const addAdditionalResults = useCallback((data) => {
    const LenderCredit = !!data.Rate;

    return {
      LenderCredit,
      TotalInitialRate: ToNumber(
        data.TotalInitialRate,
        // tiers?.find((tier) => tier.Name.toLowerCase() === data.HomeSafeTier.toLowerCase())?.Rate,
      ),
      HomeSafeTier: ToString(data.HomeSafeTier),
      showLearnMore: false,
      hideModelImpactButton: false,
    };
  }, []);

  const {addProductValidation} = useHomeSafeValidation();

  return {
    enabled: true,
    addAdditionalData,
    addAdditionalResults,
    addProductValidation,
  };
}
