import React, {useCallback, useState} from 'react';
import Container from 'components/common/layout/container/Container';
import ProductDetails from './ProductDetails';
import useStyles from './ProductDetailsModalStyles';
import Button from 'components/common/button/Button';
import clsx from 'clsx';
import Paper from 'components/common/paper/Paper';
import Item from 'components/common/layout/item/Item';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Trans} from 'lib/i18n';
import {Collapse} from '@material-ui/core';

function ProductDetailsModal(props = {}) {
  const {showProductDetailsModal = true} = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const openModal = useCallback(() => setOpen(true), [setOpen]);
  const closeModal = useCallback(() => setOpen(false), [setOpen]);

  if (!showProductDetailsModal) return null;

  return (
    <Container className={classes.productDetailsModalContainer}>
      <Item md={2} className={clsx(classes.productDetailsContainer)}>
        <Collapse in={open} timeout={500} mountOnEnter unmountOnExit>
          <Paper elevation={4}>
            <div className={classes.productDetailsCloseContainer}>
              <Button
                buttonStyling={false}
                type="button"
                onClick={closeModal}
                variant="text"
                startIcon={<FontAwesomeIcon icon={['fa', 'chevron-left']} />}
              >
                <Trans i18nKey="illustratorTranslation:illustration breakdown.product details.close edit button" />
              </Button>
            </div>

            <ProductDetails />
          </Paper>
        </Collapse>
      </Item>

      <Item md={1}>
        <Button
          type="button"
          onClick={openModal}
          variant="text"
          endIcon={<FontAwesomeIcon icon={['fa', 'chevron-right']} />}
        >
          <Trans i18nKey="illustratorTranslation:illustration breakdown.product details.edit button" />
        </Button>
      </Item>
    </Container>
  );
}

export default React.memo(ProductDetailsModal);
