import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery, isTabletAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: '100%',
      padding: theme.spacing(5),
      [isTabletAppQuery(theme)]: {padding: theme.spacing(2)},
      [isMobileAppQuery(theme)]: {padding: theme.spacing(2)},
    },
  }),
  {index: 1},
);

export default useStyles;
