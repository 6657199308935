import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    productWidgetContainer: {
      textAlign: 'center',
      margin: theme.spacing(2, 'auto'),
    },
    productPieChartTitle: {
      margin: 'auto',
    },
    productPieChartText: {
      textAlign: 'center',
    },
    smPieChartWidgetContainer: {
      width: 200,
      height: 200,
      margin: 'auto',
    },
  }),
  {index: 1},
);

export default useStyles;
