export const SCENARIO_ACTIONS = {
  SCENARIO_INITIAL: 'scenario_initial',
  SCENARIO_UPDATE_RESULTS: 'scenario_update_results',
  SCENARIO_UPDATE_PAY_BREAK_TOP: 'scenario_update_pay_break_top',
  SCENARIO_UPDATE_PAY_BREAK_MIDDLE: 'scenario_update_pay_break_middle',
  SCENARIO_ADDITION: 'scenario_addition',
  SCENARIO_SAVED_CLOSE_BORROWER_DETAILS: 'scenario_saved_close_borrower_details',
  SCENARIO_SAVED_CANCEL_BORROWER_DETAILS: 'scenario_saved_cancel_borrower_details',
  SCENARIO_SAVED_CLOSE_PAYMENT_BREAKDOWN: 'scenario_saved_close_payment_breakdown',
  SCENARIO_SAVED_CANCEL_PAYMENT_BREAKDOWN: 'scenario_saved_cancel_payment_breakdown',
  SCENARIO_SAVED_BORROWER_DETAILS: 'scenario_saved_borrower_details',
  SCENARIO_SAVED_PAYMENT_BREAKDOWN: 'scenario_saved_payment_breakdown',
  SCENARIO_DOWNLOAD_CANCEL: 'scenario_download_cancel',
  SCENARIO_DOWNLOAD_CLOSE: 'scenario_download_close',
  SCENARIO_DOWNLOAD_START: 'scenario_download_start',
  SCENARIO_DOWNLOAD_PROD_SELECT: 'scenario_download_prod_select',
  SCENARIO_DOWNLOAD_END: 'scenario_download_end',
  SCENARIO_SHARE_ILLUSTRATION_CANCEL: 'scenario_share_illustration_cancel',
  SCENARIO_SHARE_ILLUSTRATION_CLOSE: 'scenario_share_illustration_close',
  SCENARIO_SHARE_ILLUSTRATION_START: 'scenario_share_illustration_start',
  SCENARIO_SHARE_ILLUSTRATION_PROD_SELECT: 'scenario_share_illustration_prod_select',
  SCENARIO_SHARE_ILLUSTRATION_END: 'scenario_share_illustration_end',
  SCENARIO_EMAIL_TO_BORROWER_CANCEL: 'scenario_email_to_borrower_cancel',
  SCENARIO_EMAIL_TO_BORROWER_CLOSE: 'scenario_email_to_borrower_close',
  SCENARIO_EMAIL_TO_BORROWER_START: 'scenario_email_to_borrower_start',
  SCENARIO_EMAIL_TO_BORROWER_PROD_SELECT: 'scenario_email_to_borrower_prod_select',
  SCENARIO_EMAIL_TO_BORROWER_ADD_NOTES: 'scenario_email_to_borrower_add_notes',
  SCENARIO_EMAIL_TO_BORROWER_PREVIEW_PDF: 'scenario_email_to_borrower_preview_pdf',
  SCENARIO_EMAIL_TO_BORROWER_END: 'scenario_email_to_borrower_end',
};

export const SCENARIO_CATEGORIES = {
  DEFAULT: 'scenario',
};

export const PRODUCT_ACTIONS = {
  PROD_SELECT_RESULTS: 'prod_select_results',
  PROD_SELECT_PAYBREAK: 'prod_select_paybreak',
  PROD_SELECT_COMPARE: 'prod_select_compare',
};

// map product value to analytic event category
export const PRODUCT_CATEGORIES = {
  'annual': 'hecm_adjustable',
  'fixed': 'hecm_fixed',
  'select': 'homesafe_select',
  'tier': 'homesafe_standard',
  'hybrid': 'equityavail',
  'forwardrefi': 'forward_refi',
  'second': 'homesafe_second',
};
