import React from 'react';
import {useTranslation} from 'lib/i18n';
import ProductSelectionForm from 'pages/illustrator-borrower/product-selection/components/product-selection-form/ProductSelectionForm';
import StepElement from 'pages/illustrator-borrower/components/step-element/StepElement';

function ProductSelection(props) {
  const {headerComp, ...rest} = props;
  const {t} = useTranslation();

  return (
    <>
      {headerComp}
      <StepElement
        id="productSelection"
        data-testid="product-selection"
        title={t('illustratorTranslation:illustrator borrower product selection.title')}
      >
        <ProductSelectionForm {...rest} />
      </StepElement>
    </>
  );
}

ProductSelection.propTypes = {};

ProductSelection.defaultValues = {};

export default ProductSelection;
