import CalculateServiceApi from 'api/calculate-service-api';
import {useMutation, useQuery} from 'react-query';
import {addOneIllustration} from 'reducers/illustrator.reducer';
import {useDispatch} from 'react-redux';
import {useGetCalculationByIdMutationKey, useGetCalculationByIdQueryKey} from 'constants/query';
import {useCallback} from 'react';
import {isFunction} from 'lodash';
import {useProductRequestData} from 'query/products';
import {useProductsContext} from 'contexts/products-context';

export function useGetCalculationByIdMutation() {
  const {onGetCalculationSuccess} = useGetCalculationResults();

  return useMutation([useGetCalculationByIdMutationKey], (id) => CalculateServiceApi.getCalculationById(id), {
    onSuccess(response) {
      return onGetCalculationSuccess(response.data);
    },
  });
}

export function useGetCalculationByIdQuery(props = {}) {
  const {id, onSuccess, enabled, timeout = 2000} = props;

  return useQuery([useGetCalculationByIdQueryKey, id], () => CalculateServiceApi.getCalculationById(id), {
    enabled,
    onSuccess(response) {
      const e = new Event('print-start');

      setTimeout(() => window.dispatchEvent(e), timeout);

      return onSuccess(response);
    },
  });
}

function useGetCalculationResults(props = {}) {
  const {onSuccess} = props;
  const dispatch = useDispatch();

  const {getResponse} = useProductRequestData();
  const {products = []} = useProductsContext();

  const onGetCalculationSuccess = useCallback(
    async (calculation) => {
      const ApiRoute = products.find((product) => product.ProductId === calculation.ProductId)?.ApiRoute;

      const illustration = getResponse({ApiRoute}, calculation);
      await dispatch(addOneIllustration(illustration));

      if (isFunction(onSuccess)) onSuccess(calculation, {ApiRoute});
    },
    [getResponse, dispatch, onSuccess, products],
  );

  return {onGetCalculationSuccess};
}
