import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    illustrationExtraDetailsMenuItem: {
      padding: theme.spacing(2, 3),
    },
    illustrationExtraDetailsBox: {
      display: 'grid',
      width: '100%',
    },
    illustrationExtraDetailsWarningContainer: {
      whiteSpace: 'break-spaces',
    },
  }),
  {index: 1},
);

export default useStyles;
