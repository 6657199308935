import {useCallback} from 'react';
import {useSnackbar} from 'notistack';
import {useSaveCalculationMutation} from 'query/calculations/save-calculation';
import {useTranslation} from 'lib/i18n';
import {isFunction} from 'lodash';
import {useQueryClient} from 'react-query';
import {productsQueryKey, savedIllustrationsQueryKey} from 'constants/query';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';
import {useSelector} from 'react-redux';
import {useProductContext} from 'contexts/product-context';

export function useIllustrationSave(props = {}) {
  const {t} = useTranslation();
  const {product: {calculation: {CalculationId: selectedProductCalculationId} = {}} = {}, loading} =
    useProductContext();
  const saveCalculationMutation = useSaveCalculationMutation();

  const {ApiRoute: apiRoute} = useSelector(getSelectedIllustration);

  const {enqueueSnackbar} = useSnackbar();
  const queryClient = useQueryClient();

  const onSaveIllustration = useCallback(
    ({onSuccess, onError, CalculationId = selectedProductCalculationId, ApiRoute = apiRoute}) => {
      return saveCalculationMutation.mutateAsync(CalculationId, {
        onError,
        onSuccess(response) {
          enqueueSnackbar(t('illustratorTranslation:illustration.saved illustration'), {variant: 'success'});
          if (isFunction(onSuccess)) onSuccess(response);

          queryClient.setQueriesData([productsQueryKey], (queryResponse) => {
            if (Array.isArray(queryResponse?.data)) {
              queryResponse.data = queryResponse.data.map((product) => {
                if (product.ApiRoute === ApiRoute && product.calculation) {
                  product.calculation.CalculationId = response.data.CalculationId;
                  product.calculation.IsSaved = response.data.IsSaved;
                }
                return product;
              });
            }

            return queryResponse;
          });
          return queryClient.invalidateQueries(savedIllustrationsQueryKey);
        },
      });
    },
    [saveCalculationMutation, enqueueSnackbar, t, selectedProductCalculationId, queryClient, apiRoute],
  );

  return {onSaveIllustration, isLoading: saveCalculationMutation.isLoading, loading};
}
