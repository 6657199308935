import {useFieldArray, useFormContext} from 'react-hook-form';
import {INCOME_FORM_LIST} from 'constants/link-pages';

function useIncomeFormTable() {
  // props
  const {
    control,
    triggerSubmit,
    formState: {errors},
  } = useFormContext();

  const name = INCOME_FORM_LIST;

  const {fields, append, remove} = useFieldArray({control, name});

  return {
    fields,
    append,
    remove,
    errors,
    triggerSubmit,
  };
}

export default useIncomeFormTable;
