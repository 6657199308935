import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    mapChart: {
      '& path': {
        outline: 'none',
      },
    },
  }),
  {index: 1},
);

export default useStyles;
