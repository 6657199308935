import React, {useMemo} from 'react';
import Title from 'components/common/typeography/title/Title';
import {formatCurrency} from 'lib/currency';
import {Trans, useTranslation} from 'lib/i18n';
import {Box, useTheme} from '@material-ui/core';
import SmallPieChart from 'pages/illustrator/components/product-printout-page/components/payment-breakdown/components/small-pie-chart/SmallPieChart';
import SmallPieChartWithHorizontalLegend from 'pages/illustrator/components/product-printout-page/components/payment-breakdown/components/small-pie-chart-with-horizontal-legend/SmallPieChartWithHorizontalLegend';
import useStyles from './ProductWidgetStyles';
import Text from 'components/common/typeography/text/Text';

function ProductWidget(props) {
  const {
    product: {
      ProductName,
      calculation: {
        PrincipalLimit,
        CalculationId,
        BorrowerProfile: {Liens} = {},
        SimplifiedCashToBorrower,
        RemainingEquity,
        FirstLien,
        OtherLiens,
      } = {},
    } = {},
  } = props;

  const theme = useTheme();
  const {t} = useTranslation();
  const classes = useStyles();

  const data4pieChart = useMemo(() => {
    return [
      {
        label: t('product widget.mortgage balance'),
        value: Math.abs(Liens),
        tooltip: () => Liens,
        color: theme.palette.widget.dark,
      },
      {
        label: t('product widget.available proceeds'),
        value: Math.abs(SimplifiedCashToBorrower),
        tooltip: () => SimplifiedCashToBorrower,
        color: theme.palette.widget.main,
      },
      {
        label: t('product widget.remaining equity'),
        value: Math.abs(RemainingEquity),
        tooltip: () => RemainingEquity,
        color: theme.palette.widget.light,
      },
      {
        label: t('product widget.first lien'),
        value: Math.abs(FirstLien),
        tooltip: () => FirstLien,
        color: "red",
      },
      {
        label: t('product widget.other liens'),
        value: Math.abs(OtherLiens),
        tooltip: () => OtherLiens,
        color: "orange",
      },
    ];
  }, [Liens, SimplifiedCashToBorrower, RemainingEquity, FirstLien, OtherLiens, t, theme]);

  if (!CalculationId) return null;

  return (
    <div className={classes.productWidgetContainer}>
      <Title variant="h5">{ProductName}</Title>
      <Title variant="h6">
        <Trans i18nKey="product widget.total proceeds" />
      </Title>
      <Title variant="h4">{formatCurrency(PrincipalLimit)}</Title>

      <Box className={classes.smPieChartWidgetContainer}>
        <SmallPieChart key={CalculationId} data={data4pieChart} innerRadius={20} />
      </Box>

      {data4pieChart.map((data, index) => (
        <SmallPieChartWithHorizontalLegend
          key={index}
          title={
            <Title className={classes.productPieChartTitle} variant="h6">
              {data.label}
            </Title>
          }
          color={data.color}
          value={
            <Text className={classes.productPieChartText} variant="body1">
              {formatCurrency(data.tooltip())}
            </Text>
          }
        />
      ))}
    </div>
  );
}

ProductWidget.propTypes = {};

export default ProductWidget;
