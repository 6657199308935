import React, {useCallback, useImperativeHandle, useRef} from 'react';
import ReactStepWizard from 'react-step-wizard';
import StepWizardProvider from 'contexts/step-wizard';
import {isFunction} from 'lodash';
import {isTest} from 'constants/app';

/**
 * This var will be used to skip an step on the step wizard
 * @type {number}
 */
const SKIP_STEPS = 3;

const StepWizard = React.forwardRef(function StepWizardRender(props, ref) {
  const {children, instance, isLazyMount = true, override, ...rest} = props;

  const wizardRef = useRef();

  useImperativeHandle(ref, () => wizardRef.current, []);

  const setInstance = useCallback(
    (props) => {
      wizardRef.current = props;
      if (isFunction(instance)) instance(props);
    },
    [instance],
  );

  const skipNextStep = useCallback(() => {
    return (
      isFunction(wizardRef.current.goToStep) &&
      wizardRef.current.goToStep(wizardRef.current.state.activeStep + SKIP_STEPS)
    );
  }, []);

  return (
    <StepWizardProvider value={{...wizardRef?.current, skipNextStep, ...override}}>
      <ReactStepWizard instance={setInstance} isLazyMount={isTest ? false : isLazyMount} {...rest}>
        {children}
      </ReactStepWizard>
    </StepWizardProvider>
  );
});

export default StepWizard;
