import React, {useMemo} from 'react';

import Item from 'components/common/layout/item/Item';
import Title from 'components/common/typeography/title/Title';
import Container from 'components/common/layout/container/Container';
import {useTranslation} from 'lib/i18n';

/**
 *
 * page dedicated components
 *
 */
import useStyles from './DemographicInfoSexFormSectionStyles';
import InputOptions from 'components/common/form/input/InputOptions';
import DemographicInfoErrors from 'pages/illustrator-borrower/interview-and-demographic-info/components/DemographicInfoErrors';
import yup from 'lib/validation';
import {useDemographicInformationOptions} from 'hooks/link-application/borrower-info/demographic-information-source-options';
import RaceValidation from 'lib/validation/illustrator-borrower/race-validation';

/**
 *
 * ***** SECTION #3 *****
 *
 * DemographicInfo form "Race" section
 *
 * Options :
 *
 *    American Indian or Alaska Native
 *    Asian
 *      - Asian Indian
 *      - Chinese
 *      - Filipino
 *      - Japanese
 *      - Korean
 *      - Vietnamese
 *      - Other Asian
 *          - Please provide race
 *    Black African American
 *    Native Hawaiian or Other Pacific Islander
 *      - Native Hawaiian
 *      - Guamanian or Chamorro
 *      - Samoan
 *      - Other Pacific Islander
 *          - Please provide race
 *    White
 *    Do not wish to provide this information
 *
 */

function DemographicInfoRaceFormSection(props) {
  const {
    prefix = '', // !!! the prefix prop is required !!!
  } = props;

  const classes = useStyles();
  const {t} = useTranslation();

  const prefixForSectionHeader = useMemo(() => {
    return t(`translation:common.${prefix.toLowerCase()}`);
  }, [prefix, t]);

  const {defaultOption} = useDemographicInformationOptions();
  const raceValidationSchema = useMemo(
    () =>
      yup.object(
        RaceValidation({
          prefix,
          demographicInformationSource: defaultOption,
        }),
      ),
    [defaultOption, prefix],
  );

  return (
    <Container>
      <Item xs={12}>
        <Title variant="h3">
          {prefixForSectionHeader} {t('illustratorTranslation:demographic information.race')}
        </Title>
      </Item>

      <Item xs={1} />
      {/* contents / start */}
      <Item xs={10}>
        <DemographicInfoErrors prefix={`${prefix}Race`} validationSchema={raceValidationSchema} />

        <InputOptions
          options={t('illustratorBorrowerTranslation:race.options', {
            returnObjects: true,
            prefix: prefix,
          })}
          className={classes.labels}
          itemProps={{
            className: classes.checkboxItem,
          }}
          containerProps={{
            className: classes.checkboxContainer,
          }}
          itemContainerProps={{
            className: classes.checkboxItemContainer,
          }}
        />
      </Item>
      {/* contents / stop */}
      <Item xs={1} />
    </Container>
  );
}

DemographicInfoRaceFormSection.propTypes = {};

export default DemographicInfoRaceFormSection;
