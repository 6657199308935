import {CancelToken} from 'axios';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {defaultsDeep, isFunction, pickBy} from 'lodash';
import CalculateServiceApi from 'api/calculate-service-api';
import CalculationsComparisonManager from 'api/calculations-comparison-manager';
import {getComparisonResponse} from 'services/calculation.service';
import {useCallback} from 'react';
import {productCalculationMutationKey, productsQueryKey} from 'constants/query';
import {useProductFeature} from 'hooks/product-features';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {deepOmit} from 'lib/utils';
import {useProductsContext} from 'contexts/products-context';
import {useProductGetData} from 'query/products/product-get-data';
import {useSelector} from 'react-redux';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';

export function useProductsQuery(props = {}) {
  const {filterEnabledProduct} = useProductFeature();

  const {
    onSuccess,
    onSettled,
    onError,
    productFeatureKey = PRODUCT_FEATURE_KEY.LOAN_COMPARISON,
    select = (response) => response.data.filter(filterEnabledProduct(productFeatureKey)),
    enabled = true,
  } = props;

  return useQuery({
    queryKey: [productsQueryKey],
    queryFn() {
      return CalculateServiceApi.getProducts();
    },
    onSuccess(data) {
      if (isFunction(onSuccess)) onSuccess(data);
    },
    onSettled(data, error) {
      if (isFunction(onSettled)) onSettled(data, error);
    },
    onError(error) {
      if (isFunction(onError)) onError(error);
    },
    select,
    enabled,
  });
}

export function useSetProductResult(props = {}) {
  const queryClient = useQueryClient();

  const isSameProduct = useCallback((product, response) => {
    return product.ProductId === response.ProductId;
  }, []);

  const {getData, getResponse, getValidation} = useProductRequestData(props);

  const setProductResponse = useCallback(
    (response, {ApiRoute = undefined, productsFilter = () => true} = {}) => {
      let returnCalculation = [];
      if (response) {
        queryClient.setQueryData([productsQueryKey], (productsResponse) => {
          if (Array.isArray(productsResponse?.data))
            productsResponse.data = productsResponse.data.map((product) => {
              if (isFunction(productsFilter) && !productsFilter(product)) return product;

              const calculation = Array.isArray(response)
                ? response.find((data) => isSameProduct(product, data))
                : isSameProduct(product, response.data)
                ? response.data
                : undefined;

              if (ApiRoute ? product.ApiRoute !== ApiRoute : true) {
                product.calculation = getResponse(product, calculation);
                product.validation = getValidation(product, calculation);
              }

              if (calculation) {
                returnCalculation.push(product.calculation);
              }

              return product;
            });

          return productsResponse;
        });
      }

      return Array.isArray(response) ? returnCalculation : returnCalculation[0];
    },
    [queryClient, getResponse, getValidation, isSameProduct],
  );

  return {getData, setProductResponse, getResponse};
}

export function useProductCalculationMutation(props = {}) {
  const {onSuccess, formatData, formatResponse} = props;

  const {getData, setProductResponse} = useSetProductResult({
    formatData,
    formatResponse,
  });

  return useMutation({
    mutationKey: [productCalculationMutationKey],
    mutationFn: async ({product, config = {}} = {}) => {
      const ApiRoute = product?.ApiRoute;

      const data = getData({ApiRoute}, config);

      const source = CancelToken.source();

      const promise = CalculationsComparisonManager[ApiRoute]({...data}, {cancelToken: source.token});

      promise.cancel = () => {
        source.cancel('Query was cancelled by React Query');
      };

      return promise;
    },
    async onSuccess(response, {config}) {
      const calculation = await setProductResponse(response);
      if (isFunction(onSuccess)) await onSuccess(response, calculation, config);
    },
    onError(error, {product}) {
      if (error?.response?.data?.Message) {
        setProductResponse({
          data: {
            ...product,
            CalculationWarnings: [{WarningMessage: error.response.data.Message}],
          },
        });
      }
    },
  });
}

export function useProductRequestData(props = {}) {
  const {productQuery = {}} = useProductsContext();

  const {
    formatData = (data) => data,
    formatResponse = (calculation) => {
      const BorrowerProfileLookUp = pickBy(calculation, (value, key) =>
        calculation?.BorrowerProfile?.hasOwnProperty(key),
      );
      const BorrowerProfile = {
        ...calculation?.BorrowerProfile,
        ...BorrowerProfileLookUp,
      };

      return {
        ...calculation,
        BorrowerProfile,
      };
    },
  } = props;

  const illustration = useSelector(getSelectedIllustration);

  const {getData} = useProductGetData({formatData, illustration});

  const getResponse = useCallback(
    (product, responseData) => {
      const {[product?.ApiRoute]: {addAdditionalResults = () => ({}), advancedOptionsMap} = {}} = productQuery;

      if (!responseData) return undefined;

      return defaultsDeep(
        {...addAdditionalResults(formatResponse(responseData))},
        {...getComparisonResponse(product?.ApiRoute, formatResponse(responseData), {advancedOptionsMap})},
        {...deepOmit(product?.calculation, ['CalculationWarnings', 'Errors'])},
      );
    },
    [productQuery, formatResponse],
  );

  const getValidation = useCallback(
    (product, responseData) => {
      const {[product?.ApiRoute]: {addProductValidation = () => ({})} = {}} = productQuery;
      if (!responseData) return undefined;

      return addProductValidation(responseData, product);
    },
    [productQuery],
  );

  return {getData, getResponse, getValidation};
}
