import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: theme.spacing(2),
      display: theme.forPrint('none !important'),
    },
    linearProgressBarPrimary: {
      color: theme.palette.primary.main,
    },
    linearProgressContainer: {
      width: '100%',
      margin: 'auto',
      backgroundColor: theme.palette.white.main,
      border: `${theme.spacing(0.25)}px solid ${theme.palette.blue.light}`,
    },
    linearProgressBar: {
      borderRadius: 10,
    },

    rounded: {
      borderRadius: 10,
    },
  }),
  {index: 1},
);

export default useStyles;
