/**
 *
 * The user must be able to enter a number
 *
 *    MIN >= number <= MAX
 *
 * The user must be allowed to also enter 0.00
 *
 * Here we only set the MIN & MAX values
 *
 * Yup will validate the final data on submit.
 *
 */

const MIN_VAL = 0.0;
const MAX_VAL = 999999.999;

export function useBrokerTransactionQuestion() {
  const withValueLimit = ({value}) => {
    return value >= MIN_VAL && value <= MAX_VAL;
  };

  return {
    withValueLimit,
  };
}
