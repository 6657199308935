import React, {useCallback, useState} from 'react';

export const defaultContext = {
  headerProps: {title: '', backButton: ''},
  setHeaderProps: (val) => val,
  resetHeaderProps: () => undefined,
};
export const HeaderContext = React.createContext(defaultContext);

export function useHeaderContext() {
  return React.useContext(HeaderContext);
}

function HeaderProvider({defaultState = {title: '', backButton: ''}, children} = {}) {
  const [headerProps, setHeaderProps] = useState(defaultState);

  const resetHeaderProps = useCallback(() => {
    return setHeaderProps(defaultState);
  }, [setHeaderProps, defaultState]);

  return (
    <HeaderContext.Provider value={{headerProps, resetHeaderProps, setHeaderProps}}>{children}</HeaderContext.Provider>
  );
}

export default HeaderProvider;
