import React from 'react';
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@material-ui/core';

const CustomRadioGroup = React.forwardRef(function CustomRadioGroupRender(props, ref) {
  const {id, name, label, value, onChange, options = [], fullWidth, className, row, radioGroupClassName} = props;

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      {label && <FormLabel id={id}>{label}</FormLabel>}
      <RadioGroup
        aria-labelledby={id}
        name={name}
        value={value}
        onChange={onChange}
        row={row}
        className={radioGroupClassName}
      >
        {React.isValidElement(options?.[0])
          ? React.Children.map(options, (child) => React.cloneElement(child, props))
          : options.map((option) => (
              <FormControlLabel
                key={option.key ?? option.value}
                value={option.value}
                control={option.control ?? <Radio icon={null} checkedIcon={null} />}
                label={option.label}
              />
            ))}
      </RadioGroup>
    </FormControl>
  );
});

export default CustomRadioGroup;
