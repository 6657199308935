import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    rowContainer: {},
    cellContainer: {},
  }),
  {index: 1},
);

export default useStyles;
