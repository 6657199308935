import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    iconButtonContainer: {
      fontWeight: 'bold',
      textAlign: 'center',
      textDecoration: 'none',
      textTransform: 'uppercase',
      padding: 0,

      '& > .MuiIconButton-label': {
        flexDirection: 'column',
      },
    },
    sm: {
      '& $buttonIcon': {
        fontSize: '1.5rem',
      },
    },
    md: {
      width: '5rem',

      '& $buttonIcon': {
        fontSize: '1.5rem',
      },

      [isMobileAppQuery(theme)]: {
        maxWidth: '4.8rem',
      },
    },
    lg: {
      width: '5rem',
      '& $buttonIcon': {
        fontSize: '2rem',
      },
    },

    white: {
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'unset',
        '&>.MuiIconButton-label': {
          color: theme.palette.primary.main,
        },
      },
    },
    primaryDark: {
      color: theme.palette.primary.dark,
      '&:hover': {
        backgroundColor: 'unset',
        '&>.MuiIconButton-label': {
          color: theme.palette.primary.main,
        },
      },
    },

    primary: {
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: 'unset',
        '&>.MuiIconButton-label': {
          color: theme.palette.primary.dark,
        },
      },
    },

    buttonIcon: {
      paddingBottom: '0.25rem',
      margin: '0 auto',
      display: 'block',
    },
  }),
  {index: 1},
);

export default useStyles;
