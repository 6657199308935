import {ILLUSTRATOR_BORROWER_HASH} from 'constants/routes';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import Interviewer from 'pages/illustrator-borrower/interviewer/Interviewer';
import BorrowerInfo from 'pages/illustrator-borrower/borrower-info/BorrowerInfo';
import Property from 'pages/illustrator-borrower/property/Property';
import CreditAuthorization from 'pages/illustrator-borrower/credit-authorization/CreditAuthorization';
import Income from 'pages/illustrator-borrower/income/Income';
import AffiliatedBusinessArrangements from 'pages/illustrator-borrower/affiliated-business-arragements/AffiliatedBusinessArrangements';
import BrokerTransactionQuestion from 'pages/illustrator-borrower/broker-transaction-question/BrokerTransactionQuestion';
import BrokerTransactions from 'pages/illustrator-borrower/broker-transactions/BrokerTransactions';
import InterviewAndDemographicInfo from 'pages/illustrator-borrower/interview-and-demographic-info/InterviewAndDemographicInfo';
import Declarations from 'pages/illustrator-borrower/declarations/Declarations';
import {useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import {useTranslation} from 'lib/i18n';
import {useCallback, useMemo} from 'react';

const ILLUSTRATOR_BORROWER_WIZARD_STEPS = Object.freeze({
  [ILLUSTRATOR_BORROWER_HASH.INTERVIEWER_PAGE]: {
    component: Interviewer,
    ProductFeatureKey: PRODUCT_FEATURE_KEY.BORROW_INTERVIEWER_PAGE,
  },
  [ILLUSTRATOR_BORROWER_HASH.BORROWER_INFO]: {
    component: BorrowerInfo,
    ProductFeatureKey: PRODUCT_FEATURE_KEY.BORROW_INFO,
  },
  [ILLUSTRATOR_BORROWER_HASH.CO_BORROWER_INFO]: {
    prefix: 'coBorrower',
    component: BorrowerInfo,
    ProductFeatureKey: PRODUCT_FEATURE_KEY.CO_BORROW_INFO,
  },
  [ILLUSTRATOR_BORROWER_HASH.COMBINED_PROPERTY]: {
    component: Property,
    ProductFeatureKey: PRODUCT_FEATURE_KEY.BORROW_COMBINED_PROPERTIES,
  },
  [ILLUSTRATOR_BORROWER_HASH.CREDIT_AUTHORIZATION]: {
    component: CreditAuthorization,
    ProductFeatureKey: PRODUCT_FEATURE_KEY.BORROW_CREDIT_AUTHORIZATION,
  },
  [ILLUSTRATOR_BORROWER_HASH.INCOME]: {component: Income, ProductFeatureKey: PRODUCT_FEATURE_KEY.BORROW_INCOME},
  [ILLUSTRATOR_BORROWER_HASH.AFFILIATED_BUSINESS_ARRANGEMENTS]: {
    component: AffiliatedBusinessArrangements,
    ProductFeatureKey: PRODUCT_FEATURE_KEY.BORROW_ABA_INFO,
  },
  [ILLUSTRATOR_BORROWER_HASH.BROKER_TRANSACTION_QUESTION]: {
    component: BrokerTransactionQuestion,
    ProductFeatureKey: PRODUCT_FEATURE_KEY.BORROW_BROKER_TRANSACTION_QUESTION,
  },
  [ILLUSTRATOR_BORROWER_HASH.BROKER_TRANSACTIONS]: {
    component: BrokerTransactions,
    ProductFeatureKey: PRODUCT_FEATURE_KEY.BORROW_BROKER_TRANSACTIONS,
  },
  [ILLUSTRATOR_BORROWER_HASH.DEMOGRAPHIC_INFO_BORROWER]: {
    component: InterviewAndDemographicInfo,
    ProductFeatureKey: PRODUCT_FEATURE_KEY.BORROW_DEMOGRAPHIC_INFO_BORROWER_PAGE,
  },
  [ILLUSTRATOR_BORROWER_HASH.DECLARATIONS]: {
    component: Declarations,
    ProductFeatureKey: PRODUCT_FEATURE_KEY.BORROW_DECLARATIONS,
  },
});

export function useIllustratorBorrowerWizardConfig(props = {}) {
  const {t} = useTranslation();
  const getConfig = useCallback(
    (ApiRoute) =>
      t(`illustratorBorrowerConfigTranslation:${ApiRoute}`, {
        returnObjects: true,
      }) ||
      t('illustratorBorrowerConfigTranslation:initial-step', {
        returnObjects: true,
      }) ||
      [],
    [t],
  );

  return {getConfig};
}

export function useIllustratorBorrowerWizard(props = {}) {
  const {ApiRoute} = useSelector(getSelectedIllustratorBorrower);

  const {getConfig} = useIllustratorBorrowerWizardConfig();
  const steps = useMemo(() => {
    return getConfig(ApiRoute)?.steps?.map((key) => ({...ILLUSTRATOR_BORROWER_WIZARD_STEPS[key], hashKey: key}));
  }, [ApiRoute, getConfig]);

  return {
    steps,
  };
}
