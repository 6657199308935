import React from 'react';
import Text from 'components/common/typeography/text/Text';
import {usePrintContext} from 'contexts/print-context';

function PrintPageTitle(props) {
  const {printPageTitle} = usePrintContext();

  return (
    <Text {...props} variant="h5">
      {printPageTitle}
    </Text>
  );
}

PrintPageTitle.propTypes = {};

export default PrintPageTitle;
