import {useMemo} from 'react';
import {useProductContext} from 'contexts/product-context';
import {orderBy} from 'lodash';

/**
 *
 * Add only the question translation key.
 *
 * Make sure that the question is added in the translations file.
 *
 * todo ? : move this as a config file, just like for the interview & demographic page
 *
 * todo ? : can we can get rid of this component ?
 *
 */

export default function useQuestionsOptions() {
  /**
   *
   * Load extra data from the product context.
   *
   */

  const {productDetails: {illustratorBorrowerConfig: {declaration = []} = {}} = {}} = useProductContext();

  return useMemo(() => {
    return orderBy(declaration, 'order').filter(Boolean);
  }, [declaration]);
}
