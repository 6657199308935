import React from 'react';
import {useGetCalculationComparisonQuery} from 'query/calculations/get-comparison';
import {isLoaded, statusToLoading} from 'adapter/loading.adapter';
import {useProductCalculationMutation} from 'query/products';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {useProductsContext} from 'contexts/products-context';
import {isFunction} from 'lodash';
import {CALCULATION_CONTAINER} from 'constants/illustrator';

export function withProductComparison(Component) {
  return (props) => {
    const {mutateAsync, status, isLoading} = useProductCalculationMutation(props);

    return <Component {...props} mutateAsync={mutateAsync} loadingProduct={statusToLoading(status, isLoading)} />;
  };
}

export function useProductComparisonsList(props = {}) {
  const {
    onSuccess,
    shouldSetComparisonResponse,
    enabled = true,
    productFeatureKey = PRODUCT_FEATURE_KEY.LOAN_COMPARISON,
    ApiRoute,
    withBorrowerProfile,
  } = props;

  const {products, loading: productsLoading} = useProductsContext();

  const {data, status, isFetching, refetch} = useGetCalculationComparisonQuery({
    products,
    enabled: enabled && isLoaded({loading: productsLoading}),
    onSuccess,
    shouldSetComparisonResponse,
    productFeatureKey,
    ApiRoute,
    withBorrowerProfile,
  });

  const loading = statusToLoading(status, isFetching);

  return {data, products, loading, refetch};
}

export function withProductComparisonList(Component, {productFeatureKey} = {}) {
  return (props) => {
    const {data, products, refetch, loading} = useProductComparisonsList({
      ...props,
      productFeatureKey,
      async onSuccess(response, data) {
        const element = document.getElementById(CALCULATION_CONTAINER);

        if (element) element.scrollIntoView({behavior: 'smooth', block: 'nearest'});
        if (isFunction(props.onSuccess)) props.onSuccess(response, data);
      },
    });

    return <Component {...props} refetch={refetch} loading={loading} products={products} data={data} />;
  };
}
