import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    mainEl: {
      width: '100%',
      display: 'block',
      margin: theme.spacing(0, 0, 0, 0),
    },
    overflowHidden: {
      display: 'block',
      maxWidth: `calc(100vw)`,
      overflow: 'auto',
    },
    tableDataContainer: {
      '& .MuiTableCell-head': {
        lineHeight: '2rem',
      },
      '& .MuiTableCell-root': {
        fontSize: theme.forPrint('1.5rem'),
      },
      textAlign: theme.forPrint('center'),
      display: theme.forPrint('block'),
      overflow: theme.forPrint('visible !important'),
    },
    table: {
      minWidth: 650,
      border: '1px solid ' + theme.palette.grey.light,
      borderCollapse: 'collapse',
    },
    tableHead: {
      backgroundColor: theme.palette.grey.light,
    },
    selectTableCell: {
      width: 60,
    },
    tableCell: {
      width: 'auto',
      border: '1px solid ' + theme.palette.table.td.border,
    },
    input: {
      width: 'auto',
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(1, 1),
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid ' + theme.palette.white.light,
      },
    },
    formContainer: {
      margin: theme.spacing(0, 'auto'),
    },
    itemContainerWrapper: {
      display: 'block',
    },
    itemContainer: {
      '& table': {
        minWidth: 700,
      },
      '& .MuiFilledInput-root': {
        backgroundColor: 'unset',
      },
      '& .MuiInputLabel-root': {
        color: theme.palette.primary.dark,
        fontWeight: '600 !important',
      },
      '& .MuiButtonBase-root': {
        width: '100%',
      },
    },
    m_2: {
      margin: theme.spacing(2, 2, 2, 2),
    },
    m_0_1: {
      margin: theme.spacing(0, 1),
    },
    labels: {
      color: theme.palette.primary.dark,
      fontSize: '1rem',
      lineHeight: 1.43,
      fontWeight: '600 !important',
      fontFamily:
        'Montserrat,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    },
    textAlignLeft: {
      textAlign: 'left',
    },
    textAlignRight: {
      textAlign: 'right',
    },
    maxButtonWidth: {
      width: '100%',
      maxWidth: 273,
      [isMobileAppQuery(theme)]: {
        maxWidth: undefined,
      },
    },
    relativeEl: {
      position: 'relative',
    },
    absoluteEl: {
      position: 'absolute',
      right: -12,
      bottom: -5,
    },
    deleteButton: {
      color: theme.palette.error.main,
    },
  }),
  {index: 1},
);

export default useStyles;
