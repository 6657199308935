import React, {useMemo} from 'react';

import {Container, Grid} from '@material-ui/core';

import useStyles from 'pages/illustrator/components/product-printout-page/components/payment-breakdown/components/list-x-mainElements-y-subElements/ListXMainElementsStyles';
import ListYSubElementsOfXiMainElement from 'pages/illustrator/components/product-printout-page/components/payment-breakdown/components/list-x-mainElements-y-subElements/list-y-sub-elements-of-xi-main-element/ListSubElements';
import {ListItemText} from '@material-ui/core';
import {useTranslation} from 'lib/i18n';
import {useProductContext} from 'contexts/product-context';
import clsx from 'clsx';

import {SqlDateFormat} from 'lib/utils'; //TODO: Remove this after we move all the products with new breakdown design
import Item from 'components/common/layout/item/Item'; //TODO: Remove this after we move all the products with new breakdown design
import {formatCurrency} from 'lib/currency'; //TODO: Remove this after we move all the products with new breakdown design
/**
 *
 *  list X main elements
 *
 *  "Principle Limit Factor" list
 *  &
 *  "Details" list
 *
 */

function ListXMainElements(props) {
  const classes = useStyles();

  const {t} = useTranslation();

  const {
    product: {calculation: {SysCreateDate, Fee} = {}} = {},     /* TODO: "Product" should be removed after we move all the products with new breakdown design */
   
    productResults: { 
        availableCashSection = [], 
        initialLoanBalanceSection = [], 
        homeInformationSection = [], 
        youngestBorrowerSection = [], 
        loanDetailsSection = [],
        overallResults = [], /* TODO: "OverallResults" should be removed after we move all the products with new breakdown design  */
        overallResultsSecond: ors = [] /* TODO: "OverallResults" should be removed after we move all the products with new breakdown design  */
      } = {},
  } = useProductContext();

  /* TODO: "overallResultsSecond" and "data" should be removed after we move all the products with new breakdown design */
  const overallResultsSecond = useMemo(() => {
    const data = [...ors];

    data.push({
      label: t('illustratorTranslation:illustration.overall results.SysCreateDate'),
      order: 100,
      value: SqlDateFormat(SysCreateDate),
      id: 'created-date',
    });

    if (Fee) {
      data.push({
        label: t('equityavailPrintoutTranslation:fee disclosure'),
        order: 200,
        id: 'fee-disclosure-title',
        subSection: [
          {
            label: t('illustratorTranslation:illustration.overall results.monthly service fee'),
            value: formatCurrency(Fee),
            order: 201,
            id: 'monthly-fee',
          },
        ],
      });
    }

    return data;
  }, [ors, t, SysCreateDate, Fee]);

  const data = useMemo(
    () => [
      {
        name: t('equityavailPrintoutTranslation:loan proceeds breakdown'),
        data: overallResults,
      },
      {
        name: t('equityavailPrintoutTranslation:details'),
        data: overallResultsSecond,
      },
    ],
    [overallResultsSecond, overallResults, t],
  );

  /* *********************** */

  const leftSideData = useMemo(
    () => [
      {
        name: t('illustratorTranslation:illustration.payment breakdown.available cash'),
        data: availableCashSection,
      },
      {
        name: t('illustratorTranslation:illustration.payment breakdown.initial loan balance'),
        data: initialLoanBalanceSection,
      },
    ], [t, availableCashSection, initialLoanBalanceSection, ]
  );

  const rightSideData = useMemo(
    () => [
      {
        name: t('illustratorTranslation:illustration.payment breakdown.home information'),
        data: homeInformationSection,
      },
      {
        name: t('illustratorTranslation:illustration.payment breakdown.youngest borrower'),
        data: youngestBorrowerSection,
      },
      {
        name: t('illustratorTranslation:illustration.payment breakdown.loan details'),
        data: loanDetailsSection,
      }
    ], [t, homeInformationSection, youngestBorrowerSection, loanDetailsSection]
  );

  //TODO: Remove this condition parameter after we move all the products with new breakdown design
  const newBreakdownDesign = 
    availableCashSection && availableCashSection.length > 0 && 
    initialLoanBalanceSection && initialLoanBalanceSection.length > 0 &&
    homeInformationSection && homeInformationSection.length > 0 &&
    youngestBorrowerSection && youngestBorrowerSection.length > 0 &&
    loanDetailsSection && loanDetailsSection.length > 0; 

  return (
      <React.Fragment>
        {newBreakdownDesign ? (
          // New breakdown design
          <Container className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div className={classes.leftSide}>
                  {
                    leftSideData.map((value, index) => {
                      if (value.data.length === 0) return null;
                      return (
                        <React.Fragment key={index}>
                          <Container className={classes.listXMainElementContainer} maxWidth={false}>
                            {/* header */}
                            <ListItemText primary={value.name} className={clsx(classes.text2left, classes.listItemText)} />
                            {/* content */}
                            <ListYSubElementsOfXiMainElement data={value.data} columnIndex={index} />
                          </Container>
                        </React.Fragment>
                      );
                    })
                  }
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.rightSide}>
                {
                    rightSideData.map((value, index) => {
                      if (value.data.length === 0) return null;
                      return (
                        <React.Fragment key={index}>
                          <Container className={classes.listXMainElementContainer} maxWidth={false}>
                            {/* header */}
                            <ListItemText primary={value.name} className={clsx(classes.text2left, classes.listItemText)} />
                            {/* content */}
                            <ListYSubElementsOfXiMainElement data={value.data} columnIndex={index}/>
                          </Container>
                        </React.Fragment>
                      );
                    })
                  }
                </div>
              </Grid>
            </Grid>
          </Container>
        ) : (
          //TODO: Remove this old breakdown design after we move all the products with new breakdown design
          data.map((value, index) => {
            if (value.data.length === 0) return null;
  
            return (
              <React.Fragment key={index}>
                <Container className={classes.listXMainElementContainer} maxWidth={false}>
                  {/* header */}
                  <Item xs={12}>
                    <ListItemText primary={value.name} className={clsx(classes.text2left, classes.listItemText)} />
                  </Item>
                  {/* content */}
                  <ListYSubElementsOfXiMainElement data={value.data} columnIndex={index} />
                </Container>
              </React.Fragment>
            );
          })
        )  
      }
      </React.Fragment>
    );
}

export default React.memo(ListXMainElements);
