import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    closeButtonIcon: {
      position: 'sticky',
      top: theme.spacing(1),
      right: theme.spacing(-5),
      zIndex: 9999,
    },
  }),
  {index: 1},
);

export default useStyles;
