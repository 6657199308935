import React, {useCallback, useMemo, useEffect} from 'react';
import {Box} from '@material-ui/core';
import Form from 'components/common/form/Form';
import Text from 'components/common/typeography/text/Text';
import {useTranslation} from 'lib/i18n';
import IllustrationShareElement from 'components/illustrator/borrower-notes-modal/components/illustration-share-element/IllustrationShareElement';
import useStyles from 'components/illustrator/borrower-notes-modal/components/illustration-dialog-share/IllustrationDialogShareStyle';
import SelectAllIllustrationsButton from '../select-all-illustrations-button/SelectAllIllustrationsButton';
import Item from 'components/common/layout/item/Item';
import Container from 'components/common/layout/container/Container';
import {ALL_ILLUSTRATIONS} from 'constants/illustrator';
import {useShareIllustration} from 'hooks/borrower';
import {withDialogState} from 'hooks/dialog';
import {useSnackbar} from 'notistack';
import DialogStateElement from 'components/illustrator/borrower-notes-modal/components/illustration-share-element/components/dialog-state-element';
import { trackEvent } from '../../../../../services/analytics.service';
import { SCENARIO_CATEGORIES, SCENARIO_ACTIONS } from '../../../../../constants/analytic-events';

IllustrationDialogShare.propTypes = {};

function IllustrationDialogShare(props) {
  const {setState, nextStep, state} = props;

  useEffect(() => {
    if (props.isDownloadPdf){
      trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_DOWNLOAD_START);
    } else {
      trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_SHARE_ILLUSTRATION_START);
    }
  }, [props.isDownloadPdf]);

  const {enqueueSnackbar} = useSnackbar();
  const {illustrations} = useShareIllustration();
  const {t} = useTranslation();
  const classes = useStyles();

  const defaultValues = useMemo(() => {
    return illustrations.reduce((a, v) => ({...a, [v.id]: false}), {[ALL_ILLUSTRATIONS]: false});
  }, [illustrations]);

  const doIllustrationToShareFormSubmit = useCallback(
    async (data) => {
      let illustrationsToShareKey = Object.keys(data).filter((key) => key !== ALL_ILLUSTRATIONS);

      if (!data[ALL_ILLUSTRATIONS]) {
        illustrationsToShareKey = illustrationsToShareKey.filter((key) => data[key]);
      }

      if (illustrationsToShareKey.length) {
        setState((oldState) => ({
          ...oldState,
          illustrationsToShare: illustrations.filter((illustration) =>
            illustrationsToShareKey.includes(illustration.id),
          ),
        }));

        return nextStep();
      } else {
        enqueueSnackbar(t('illustrationShareModalTranslation:no item selected'), {variant: 'error'});
      }
    },
    [setState, nextStep, illustrations, enqueueSnackbar, t],
  );

  return (
    <Form id={state.formId} onSubmit={doIllustrationToShareFormSubmit} defaultValues={defaultValues}>
      <Box className={classes.illustrationShareInfoBox}>
        <Text className={classes.subTitle}>{t('illustrationShareModalTranslation:select info')}</Text>
        <SelectAllIllustrationsButton />
      </Box>

      <DialogStateElement setState={setState} />
      <Container className={classes.itemContainer}>
        {illustrations.map((illustration) => (
          <Item xs={12} key={illustration.id}>
            <IllustrationShareElement illustration={illustration} />
          </Item>
        ))}
      </Container>
    </Form>
  );
}

export default withDialogState(IllustrationDialogShare, {
  title: 'illustrationShareModalTranslation:title',
  formId: 'illustration-dialog-share',
  buttonReturn: '',
});
