import {useCallback} from 'react';
import {getComparisonData} from 'services/calculation.service';
import {defaultsDeep} from 'lodash';
import {useProductsContext} from 'contexts/products-context';

export function useProductGetData(props) {
  const {formatData = (data) => data, illustration} = props;
  const {productQuery = {}} = useProductsContext();

  const getData = useCallback(
    (product, config, selectedIllustration = illustration) => {
      const {[product?.ApiRoute]: {addAdditionalData = () => ({})} = {}} = productQuery;

      const illustrationData = getComparisonData(formatData(selectedIllustration), config);

      return defaultsDeep({...addAdditionalData(illustrationData, selectedIllustration)}, {...illustrationData});
    },
    [productQuery, formatData, illustration],
  );

  return {getData};
}
