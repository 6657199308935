import React, {useEffect, useMemo} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Logo from './components/logo/Logo';
import {Box, Typography} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './EquityAvailPdfHeaderStyles';
import {useTranslation} from 'lib/i18n';
import {formatCurrency} from 'lib/currency';
import {isLoaded} from 'adapter/loading.adapter';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import {useUserInfo} from 'hooks/user';
import logo from 'assets/images/foa-logo.svg';
import {ROUTES} from 'constants/routes';
import {useProductContext} from 'contexts/product-context';
import {API_ROUTES} from 'constants/illustrator';
import {withProduct} from 'components/products/product-hoc';
import {withProducts} from '../products';
import IllustrationContextProvider, {useIllustrationContext} from 'contexts/illustration-context';
import {useSelector} from 'react-redux';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';

/**
 *
 * V2 Equity Avail PDF header
 *
 */

export const EquityAvailPdfHeader = React.forwardRef(function EquityAvailPdfHeaderRender(props, ref) {
  const {loading} = useProductContext();

  const {BorrowerProfile: {LastName, FirstName, ValueOfProperty} = {}, RemainingEquity} = useIllustrationContext();

  const {className} = props;
  const classes = useStyles();
  const {t} = useTranslation();

  const productLoaded = useMemo(() => isLoaded({loading}), [loading]);

  const {userInfo} = useUserInfo();

  /**
   *
   * calculate the number of empty columns that should be rendered, for the broker section
   *
   */

  const [brokerNumberOfEmptyColumnsToFill, setBrokerNumberOfEmptyColumnsToFill] = React.useState(0);

  const brokerSectionColumnSize = 4;

  useEffect(() => {
    let n = 0;

    if (!(userInfo?.FirstName && userInfo?.LastName)) n += brokerSectionColumnSize;

    if (!userInfo?.Phone) n += brokerSectionColumnSize;

    if (!userInfo?.Email) n += brokerSectionColumnSize;

    setBrokerNumberOfEmptyColumnsToFill(n);
  }, [userInfo]);

  /**
   *
   * calculate the number of empty columns that should be rendered, for the borrower section
   *
   */

  const [borrowerNumberOfEmptyColumnsToFill, setBorrowerNumberOfEmptyColumnsToFill] = React.useState(0);

  const borrowerSectionColumnSize = 4;

  useEffect(() => {
    let n = 0;

    if (!(LastName && FirstName)) n += borrowerSectionColumnSize;

    if (!ValueOfProperty) n += borrowerSectionColumnSize;

    if (!RemainingEquity) n += borrowerSectionColumnSize;

    setBorrowerNumberOfEmptyColumnsToFill(n);
  }, [LastName, FirstName, ValueOfProperty, RemainingEquity]);

  if (!productLoaded) return null;

  return (
    <AppBar
      position="relative"
      color="inherit"
      elevation={1}
      className={clsx(classes.root, className)}
      data-testid="app-header-equity-avail"
      ref={ref}
    >
      {/* row #1 / start */}
      <Toolbar className={classes.toolBar}>
        <Container>
          {/* empty section / start */}
          <Item xs={6} />
          {/* empty section / stop */}

          {/* brokerData section / start */}
          <Item xs={5}>
            {(userInfo?.FirstName && userInfo?.LastName) || userInfo?.Phone || userInfo?.Email ? (
              <Container>
                {brokerNumberOfEmptyColumnsToFill > 0 ? <Item xs={brokerNumberOfEmptyColumnsToFill} /> : null}

                {/* broker data : first name & last name / start */}
                {userInfo?.FirstName && userInfo?.LastName ? (
                  <Item xs={brokerSectionColumnSize} className={classes.text2left}>
                    <Typography variant="h6" className={clsx(classes.text2left)}>
                      {userInfo.FirstName + ' ' + userInfo.LastName}
                    </Typography>
                  </Item>
                ) : null}
                {/* broker data : first name & last name / stop */}

                {/* broker data : phone / start */}
                {userInfo?.Phone ? (
                  <Item xs={brokerSectionColumnSize} className={classes.text2left}>
                    <Typography variant="h6" className={clsx(classes.text2left)}>
                      {userInfo.Phone}
                    </Typography>
                  </Item>
                ) : null}
                {/* broker data : phone / stop */}

                {/* broker data : email / start */}
                {userInfo?.Email ? (
                  <Item xs={brokerSectionColumnSize} className={classes.text2left}>
                    <Typography variant="h6" className={clsx(classes.text2left)}>
                      {userInfo.Email}
                    </Typography>
                  </Item>
                ) : null}
                {/* broker data : email / stop */}
              </Container>
            ) : null}
          </Item>
          {/* brokerData section / stop */}

          {/* empty section / start */}
          <Item xs={1} />
          {/* empty section / stop */}
        </Container>
      </Toolbar>
      {/* row #1 / stop */}

      {/* row #2 / start */}
      <Toolbar>
        <Container>
          {/* logo section / start */}
          <Item xs={6} className={classes.text2left}>
            <Box className={classes.m3}>
            </Box>
          </Item>
          {/* logo section / stop */}

          {/* borrower section / start */}
          <Item xs={5}>
            {(LastName && FirstName) || ValueOfProperty || RemainingEquity ? (
              <Container>
                {borrowerNumberOfEmptyColumnsToFill > 0 ? <Item xs={borrowerNumberOfEmptyColumnsToFill} /> : null}

                {/* last name & first name / start */}
                {LastName && FirstName ? (
                  <Item xs={borrowerSectionColumnSize}>
                    <Box className={classes.m3}>
                      <Typography variant="h6" className={clsx(classes.text2left, classes.borrower1stLine)}>
                        {t('equityavailPrintoutTranslation:borrower')}
                      </Typography>
                      <Typography variant="h6" className={clsx(classes.text2left, classes.borrower2ndLine)}>
                        {LastName + ' ' + FirstName}
                      </Typography>
                    </Box>
                  </Item>
                ) : null}
                {/* last name & first name / stop */}

                {/* value of property / stop */}
                {ValueOfProperty ? (
                  <Item xs={borrowerSectionColumnSize}>
                    <Box className={classes.m3}>
                      <Typography variant="h6" className={clsx(classes.text2left, classes.borrower1stLine)}>
                        {t('equityavailPrintoutTranslation:property value')}
                      </Typography>
                      <Typography variant="h6" className={clsx(classes.text2left, classes.borrower2ndLine)}>
                        {formatCurrency(ValueOfProperty)}
                      </Typography>
                    </Box>
                  </Item>
                ) : null}
                {/* value of property / stop */}

                {/* remaining equity / start */}
                {RemainingEquity ? (
                  <Item xs={borrowerSectionColumnSize}>
                    <Box className={classes.m3}>
                      <Typography variant="h6" className={clsx(classes.text2left, classes.borrower1stLine)}>
                        {t('equityavailPrintoutTranslation:remaining equity')}
                      </Typography>
                      <Typography variant="h6" className={clsx(classes.text2left, classes.borrower2ndLine)}>
                        {formatCurrency(RemainingEquity)}
                      </Typography>
                    </Box>
                  </Item>
                ) : null}
                {/* remaining equity / stop */}
              </Container>
            ) : null}
          </Item>
          {/* borrower section / stop */}

          {/* empty section / start */}
          <Item xs={1} />
          {/* empty section / stop */}
        </Container>
      </Toolbar>
      {/* row #2 / stop */}
    </AppBar>
  );
});

const EquityAvailPdfHeaderProduct = withProduct(EquityAvailPdfHeader);

const EquityAvailPdf = React.memo(function EquityAvailPdfRender(props) {
  const illustration = useSelector(getSelectedIllustration);

  return (
    <IllustrationContextProvider defaultState={illustration}>
      <EquityAvailPdfHeaderProduct {...props} isSelectedProduct={(product) => product.ApiRoute === API_ROUTES.HYBRID} />
    </IllustrationContextProvider>
  );
});

export default withProducts(EquityAvailPdf, {renderProducts: false});
