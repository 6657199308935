import useStyles from './AbaFormTableStyles';
import {useTranslation} from 'lib/i18n';
import React, {useEffect, useState} from 'react';
import Item from 'components/common/layout/item/Item';
import Input from 'components/common/form/input/Input';
import Button from 'components/common/button/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {TableCell} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import useAffiliatedBusinessArrangementsFormTable from './use-affiliated-business-arrangements-form-table';
import IconButton from 'components/common/button/IconButton';
import Container from 'components/common/layout/container/Container';

function AbaFormTable(props) {
  const {fields, append, remove} = useAffiliatedBusinessArrangementsFormTable();

  const classes = useStyles();
  const {t} = useTranslation();

  // max number of records allowed
  const maxRecords = 5;
  const [disabledAddOneRecord, setDisabledAddOneRecord] = useState(false);

  /**
   *
   * There is a max limit of records
   *
   * check & activate/deactivate the "add more ABAs" button, when the `state` updates
   *
   */
  useEffect(() => {
    if (fields.length < maxRecords) {
      setDisabledAddOneRecord(false);
    } else {
      setDisabledAddOneRecord(true);
    }
  }, [fields, setDisabledAddOneRecord, maxRecords]);

  const addOneRecord = () => {
    if (fields.length < maxRecords) {
      append({
        providerName: '',
        settlementService: '',
        estCost: '',
        natureOfRelation: '',
      });
    }
  };

  const removeOneRecord = (index) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  return (
    <>
      {/* content section / start */}
      <Item xs={12} sm={1} />
      <Item xs={12} sm={10}>
        <Table className={classes.table} aria-label="caption table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="left" className={classes.tableCell}>
                {t('abaFormTableTranslation:provider name')}
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                {t('abaFormTableTranslation:settlement service')}
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                {t('abaFormTableTranslation:est cost')}
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                {t('abaFormTableTranslation:nature of relationship')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={index}>
                <TableCell align="left" className={classes.tableCell}>
                  <Input
                    name={`abaFormList[${index}].providerName`}
                    variant="standard"
                    label=""
                    defaultValue={field?.providerName || ''}
                  />
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  <Input
                    name={`abaFormList[${index}].settlementService`}
                    variant="standard"
                    label=""
                    defaultValue={field?.settlementService || ''}
                  />
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  <Input
                    name={`abaFormList[${index}].estCost`}
                    type="currency"
                    variant="standard"
                    label=""
                    defaultValue={field?.estCost || ''}
                  />
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  <Input
                    name={`abaFormList[${index}].natureOfRelation`}
                    variant="standard"
                    label=""
                    defaultValue={field?.natureOfRelation || ''}
                    InputProps={{
                      endAdornment:
                        fields.length > 1 && index === fields.length - 1 ? (
                          <IconButton
                            size="sm"
                            onClick={() => removeOneRecord(index)}
                            icon={['fas', 'window-close']}
                            className={classes.deleteButton}
                          />
                        ) : null,
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Item>
      <Item xs={12} sm={1} />
      {/* content section / stop */}

      {/* buttons section / start */}
      <Item xs={12}>
        <Container className={classes.buttonContainer}>
          <Item xs={12} sm={5} md={4} xl={3}>
            <Button
              className={classes.buttonTransform}
              btnStyle="bgElectric"
              buttonStyling={{lg: true}}
              onClick={addOneRecord}
              disabled={disabledAddOneRecord}
              data-testid="aba-form-add-button"
            >
              {t('illustratorTranslation:add more aba')}
            </Button>
          </Item>
          <Item xs={12} sm={5} md={4} xl={3}>
            <Button
              btnStyle="bgElectric"
              buttonStyling={{lg: true}}
              type="submit"
              data-testid="goToNextOriginBorrowerInfo"
            >
              {t('translation:common.next')}
            </Button>
          </Item>
        </Container>
      </Item>

      {/* buttons section / stop */}
    </>
  );
}

AbaFormTable.propTypes = {};
AbaFormTable.defaultValues = {};

export default AbaFormTable;
