import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

/**
 *
 * REDUCERS :
 *
 *  illustrator
 *  version
 *  illustratorBorrower
 *
 */
import illustrationReducer from 'reducers/illustrator.reducer';
import versionReducer from 'reducers/version.reducer';
import illustratorBorrowerReducer from 'reducers/illustrator-borrower.reducer';
import settingsReducer from 'reducers/settings.reducer';
import {isDevelop} from 'constants/app';

/**
 *
 *  This is where the Redux Store State format gets set.
 *
 */

const appReducer = combineReducers({
  illustrator: illustrationReducer,
  version: versionReducer,
  illustratorBorrower: illustratorBorrowerReducer,
  settings: settingsReducer,
});

export const RESET_STORAGE_ACTION = {type: 'RESET_STORAGE'};

function rootReducer(state, action) {
  return appReducer(action.type === RESET_STORAGE_ACTION.type ? undefined : state, action);
}

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['illustrator', 'settings', 'version'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: isDevelop,
});

export const persistor = persistStore(store);
