import React, {useCallback, useEffect, useMemo} from 'react';
import {getAllIllustrations, updateManyIllustration, updateOneIllustration} from 'reducers/illustrator.reducer';
import {useDispatch, useSelector} from 'react-redux';
import {deepOmit} from 'lib/utils';
import {defaultsDeep, get, isEqual, isFunction, omit} from 'lodash';
import {useIllustrationContext} from 'contexts/illustration-context';
import {useSaveBorrowerInfoMutation} from 'query/borrower/save-borrower-info';
import {useProductCalculationQuery} from 'query/products/calculation-query';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'lib/i18n';
import {useActionsContext} from 'contexts/actions-context';
import {useIllustratorValidationCleanUp} from 'hooks/validation';
import {useMobileApp} from 'lib/app-theme';
import {DEFAULT_BORROWER_PROFILE} from 'reducers/illustrator.reducer';

export function ActionIcon(props) {
  const {fabExtended, title, icon} = props;

  return (
    <>
      <FontAwesomeIcon
        size="lg"
        icon={icon}
        title={title}
        style={fabExtended ? {marginRight: 10, marginBottom: 2} : undefined}
      />
      {fabExtended && title}
    </>
  );
}

export function useIllustratorActions(props = {}) {
  const {className, withSubmitButton = false, submitButtonDisabled} = props;

  const {t} = useTranslation();

  const illustration = useIllustrationContext();
  const isMobileApp = useMobileApp();

  const fabExtended = useMemo(() => !isMobileApp, [isMobileApp]);

  const actions = useMemo(() => {
    const data = [];

    if (withSubmitButton) {
      data.push({
        icon: (
          <ActionIcon
            fabExtended={fabExtended}
            icon={['fas', 'save']}
            title={t('illustratorTranslation:illustration.quick fill.submit button')}
          />
        ),
        name: t('illustratorTranslation:illustration.quick fill.submit button'),
        form: `illustration-${illustration.id}`,
        variant: fabExtended ? 'extended' : undefined,
        type: 'submit',
        disabled: submitButtonDisabled,
      });
    }

    return data;
  }, [fabExtended, illustration.id, t, withSubmitButton, submitButtonDisabled]);

  const {setActionsContext, resetActionsContext} = useActionsContext();

  useEffect(() => {
    setActionsContext({actions, className});

    return () => {
      resetActionsContext();
    };
  }, [className, actions, resetActionsContext, setActionsContext]);

  return {};
}

export function useUpdateIllustrations() {
  const dispatch = useDispatch();
  const illustrations = useSelector(getAllIllustrations);

  const updateIllustrations = useCallback(
    ({changes}) => {
      dispatch(
        updateManyIllustration(
          illustrations.map((illustration) => ({
            id: illustration.id,
            changes: {...(isFunction(changes) ? changes(illustration) : changes)},
          })),
        ),
      );
    },
    [dispatch, illustrations],
  );

  const updateIllustrationsBorrowerProfile = useCallback(
    ({new: BorrowerProfile, old: oldBorrowerProfileId, IsSaved = false}) => {
      dispatch(
        updateManyIllustration(
          illustrations
            .filter((illustration) => illustration?.BorrowerProfile.BorrowerProfileId === oldBorrowerProfileId)
            .map((illustration) => ({
              id: illustration.id,
              changes: {
                BorrowerProfileId: BorrowerProfile?.BorrowerProfileId,
                BorrowerProfile: defaultsDeep({...BorrowerProfile}, {...illustration.BorrowerProfile}),
                IsSaved,
              },
            })),
        ),
      );
    },
    [dispatch, illustrations],
  );

  return {updateIllustrationsBorrowerProfile, updateIllustrations};
}

export function useIllustrationCompare(props = {}) {
  const {illustration} = props;

  const compareIllustrationByKeys = useCallback(
    (data, ...keys) => {
      return keys.every((key) => isEqual(get(data, key), get(illustration, key)));
    },
    [illustration],
  );

  return {compareIllustrationByKeys};
}

export function useIllustratorForm(props = {}) {
  const illustration = useIllustrationContext();
  const dispatch = useDispatch();

  const {updateIllustrationsBorrowerProfile} = useUpdateIllustrations();

  const saveBorrowerInfoMutation = useSaveBorrowerInfoMutation();
  const {refetch} = useProductCalculationQuery({
    onSuccess(response, data) {
      data.BorrowerProfile.IsThere2nd = illustration.BorrowerProfile?.IsThere2nd || DEFAULT_BORROWER_PROFILE.IsThere2nd;
      dispatch(updateOneIllustration({id: illustration?.id, changes: data}));
    },
    onError(error, data) {
      dispatch(
        updateOneIllustration({
          id: illustration?.id,
          changes: deepOmit(data, ['BorrowerProfile', 'BorrowerProfileId']),
        }),
      );
    },
    illustration,
  });

  const {cleanUpData} = useIllustratorValidationCleanUp();
  const onSubmit = useCallback(
    async (data = {}) => {
      const {BorrowerProfileId, advancedOptions, CashAtClose, ClosingCost, OverrideClosingCosts} = data;

      data = cleanUpData(data, {
        defaultData: {
          advancedOptions: data.FundAccess === illustration.FundAccess ? advancedOptions : [],
          CashAtClose,
          ClosingCost,
          OverrideClosingCosts
        },
      });

      data.IsSaved = false;

      if (BorrowerProfileId) {
        await dispatch(updateOneIllustration({id: illustration?.id, changes: data}));

        return refetch({cancelRefetch: true});
      } else {
        await saveBorrowerInfoMutation.mutateAsync(
          {BorrowerProfile: data.BorrowerProfile},
          {
            onSuccess(response) {
              updateIllustrationsBorrowerProfile({old: data.BorrowerProfile.BorrowerProfileId, new: response.data});

              data.BorrowerProfile = response.data;
              data.BorrowerProfileId = response.data.BorrowerProfileId;

              dispatch(updateOneIllustration({id: illustration?.id, changes: omit(data, 'id')}));
            },
          },
        );
      }
    },
    [dispatch, illustration, saveBorrowerInfoMutation, updateIllustrationsBorrowerProfile, refetch, cleanUpData],
  );

  return {onSubmit, defaultValues: illustration};
}
