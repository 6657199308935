import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower, updateSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import yup from 'lib/validation';
import Form from 'components/common/form/Form';
import IncomeFormTable from './components/IncomeFormTable';
import {useTranslation} from 'lib/i18n';
import {useStepWizardContext} from 'contexts/step-wizard';
import useIncomeFrequencyOptions from 'hooks/link-application/borrower-info/income-frequency-options';
import useIncomeTypeOptions from 'hooks/link-application/borrower-info/income-type-options';
import StepElement from 'pages/illustrator-borrower/components/step-element/StepElement';
import Container from 'components/common/layout/container/Container';

/**
 *
 * the main component method
 *
 * -----------------------------------------------------------------------
 * | Income For | Income Type*                 | Amount* | Pay Frequency |
 * -----------------------------------------------------------------------
 * | drop down  | drop down                    | input   | drop down     |
 * -----------------------------------------------------------------------
 * | Borrower   | Social Security              |         | Annual        |
 * | Coborrower | Pension/Retirement Income    |         | Monthly       |
 * |            | Rental Income                |         |               |
 * |            | W2 Income                    |         |               |
 * |            | Self-Employment - Schedule C |         |               |
 * |            | Other Income                 |         |               |
 * -----------------------------------------------------------------------
 *
 * "collected by" a.k.a. "income for"
 *
 * "monthly amount" a.k.a. "amount"
 *
 * "income frequency" a.k.a. "pay frequency"
 *
 */

const validationSchema = yup.object().shape({
  incomeFormList: yup.array().of(
    yup.object().shape({
      incomeType: yup.string().required(),
      incomeFrequency: yup.string(),
      collectedBy: yup.string().required(),
      monthlyAmount: yup
        .number()
        .required()
        .moreThan(0.0) // 0.1 or > 1 ?
        .test(
          'no-value',
          '', // dummy text
          function (value) {
            return value > 0.0;
          },
        ),
    }),
  ),
});

function Income(props) {
  const {nextStep} = useStepWizardContext();

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const selectedIllustratorBorrower = useSelector(getSelectedIllustratorBorrower);

  const onSubmitFormHandler = useCallback(
    async (data) => {
      await dispatch(
        updateSelectedIllustratorBorrower({
          income: data,
        }),
      );

      /**
       *
       * goto next step
       *
       * PS:
       *
       *    - the next step should be : affiliated business arrangements
       *
       */

      return nextStep();
    },
    [nextStep, dispatch],
  );

  const isAvailableCoBorrowerInfo = useMemo(() => {
    return selectedIllustratorBorrower?.coBorrowerInfo?.coBorrowerFirstName !== '';
  }, [selectedIllustratorBorrower]);

  // collectedBy drop down options
  const collectedByOptions = useMemo(() => {
    let arr = [
      {
        value: 'Borrower',
        label: t('translation:common.borrower'),
        //selected: true, // it was requested to be removed
      },
    ];
    if (isAvailableCoBorrowerInfo) {
      arr.push({
        value: 'Coborrower',
        label: t('translation:common.coborrower'),
      });
    }
    return arr;
  }, [isAvailableCoBorrowerInfo, t]);

  // incomeType drop down options
  const incomeTypeOptions = useIncomeTypeOptions();

  // incomeFrequency drop down options
  const incomeFrequencyOptions = useIncomeFrequencyOptions();

  // get default value for a drop down element, if available
  const getDefaultValue = useCallback((options = []) => options.find((option) => option.selected)?.value, []);

  const defaultValues = useMemo(() => {
    let data = selectedIllustratorBorrower.income.incomeFormList;

    if (data.length === 1 && data[0].monthlyAmount === '') {
      /**
       *
       * with 1 row, the form data is pristine in REDUX
       *
       * "monthlyAmount" is a required  field
       *
       */

      return {
        incomeFormList: [
          {
            collectedBy: getDefaultValue(collectedByOptions), // income for
            incomeType: getDefaultValue(incomeTypeOptions), // income type
            monthlyAmount: '', // amount
            incomeFrequency: getDefaultValue(incomeFrequencyOptions), // pay frequency
          },
        ],
      };
    }

    /**
     *
     * with 1 or >1 rows, the form data is not pristine in REDUX
     *
     */

    return selectedIllustratorBorrower.income;
  }, [selectedIllustratorBorrower, collectedByOptions, getDefaultValue, incomeFrequencyOptions, incomeTypeOptions]);

  return (
    <StepElement id="income" data-testid="income" title={t('illustratorTranslation:illustrator borrower income.title')}>
      {/* form / start */}
      <Container
        component={Form}
        onSubmit={onSubmitFormHandler}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
      >
        <IncomeFormTable />
      </Container>
      {/* form / stop */}
    </StepElement>
  );
}

Income.propTypes = {};

Income.defaultValues = {};

export default Income;
