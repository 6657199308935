import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, DialogActions, DialogContent} from '@material-ui/core';
import {Trans, useTranslation} from 'lib/i18n';
import Text from 'components/common/typeography/text/Text';
import Button from 'components/common/button/Button';
import DialogTitle from 'components/common/dialog-title/DialogTitle';
import {isLoaded} from 'adapter/loading.adapter';
import {usePrintContext} from 'contexts/print-context';
import {useModalClose} from 'hooks/react-hook-form';
import {useOwnFormContext} from 'contexts/form-context';
import {usePrintModal} from 'hooks/print';

function NegativeCashUpFrontModal(props) {
  const {SimplifiedCashToBorrower} = props;
  const {t} = useTranslation();
  const [state, setState] = useState({open: false, SimplifiedCashToBorrower: 0});
  const {printing} = usePrintContext();
  const {extraArguments: {loadingProduct: loading} = {}} = useOwnFormContext();

  const handleClose = useCallback(() => {
    setState({open: false, SimplifiedCashToBorrower});
  }, [SimplifiedCashToBorrower, setState]);

  useEffect(() => {
    if (
      isLoaded({loading}) &&
      SimplifiedCashToBorrower < 0 &&
      SimplifiedCashToBorrower !== state.SimplifiedCashToBorrower
    ) {
      setState({
        open: true,
        SimplifiedCashToBorrower,
      });
    }
  }, [state.SimplifiedCashToBorrower, setState, SimplifiedCashToBorrower, loading]);

  usePrintModal({setState, handleClose, defaultValues: {SimplifiedCashToBorrower, open: true}});

  const disclaimerText = useMemo(
    () => t('consumer cash up front section.modal negative remaining cash.disclaimer'),
    [t],
  );

  const {onClose} = useModalClose({disableBackdropClick: true, onClose: handleClose});

  return (
    <Dialog
      open={state.open}
      onClose={onClose}
      aria-labelledby="negative-cash-up-front-title"
      aria-describedby="negative-cash-up-front-description"
    >
      <DialogTitle id="negative-cash-up-front-title" onClose={handleClose} severity="warning">
        <Trans i18nKey="consumer cash up front section.modal negative remaining cash.description" />
      </DialogTitle>

      {disclaimerText && (
        <DialogContent dividers>
          <Text id="negative-cash-up-front-description">{disclaimerText}</Text>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={handleClose} btnStyle="bgElectric">
          <Trans i18nKey="consumer cash up front section.modal negative remaining cash.button" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

NegativeCashUpFrontModal.propTypes = {};

export default NegativeCashUpFrontModal;
