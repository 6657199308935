import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    mainEl: {
      width: '100%',
      display: 'block',
      margin: theme.spacing(0, 0, 0, 0),
    },
    overflowHidden: {
      display: 'block',
      maxWidth: `calc(100vw)`,
      overflow: 'auto',
    },
    tableDataContainer: {
      '& .MuiTableCell-head': {
        lineHeight: '2rem',
      },
      '& .MuiTableCell-root': {
        fontSize: theme.forPrint('1.5rem'),
      },

      textAlign: theme.forPrint('center'),
      display: theme.forPrint('block'),
      overflow: theme.forPrint('visible !important'),
    },
    table: {
      minWidth: 650,
      border: 'none',
      borderCollapse: 'collapse',
      '& .MuiTableHead-root': {
        backgroundColor: theme.palette.grey.light,
      },
      '& .MuiTableBody-root .MuiInput-underline': {
        borderBottom: 'none !important',
      },
    },
    selectTableCell: {
      width: 60,
    },
    tableCell: {
      width: 'auto',
      height: 40,
      border: '1px solid ' + theme.palette.table.td.border,
    },
    tableCellNoBorder: {
      width: 'auto',
      height: 40,
      border: 'none !important',
    },
    tableCellBgColorFff: {
      backgroundColor: theme.palette.white.light,
    },
    itemContainerWrapper: {
      display: 'block',
    },

    p_2: {
      padding: theme.spacing(2),
    },
    m_2: {
      margin: theme.spacing(2, 2, 2, 2),
    },
    m_0_1: {
      margin: theme.spacing(0, 1),
    },
    labels: {
      color: theme.palette.primary.dark,
      fontSize: '1rem',
      lineHeight: 1.43,
      fontWeight: '600 !important',
      fontFamily:
        'Montserrat,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    },
    brokerTransactionItem: {
      textAlign: 'left',
      alignSelf: 'center',
    },
    m_0_0_2_0: {
      margin: theme.spacing(0, 0, 2, 0),
    },
    nStB: {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
    },
    buttonContainer: {
      justifyContent: 'center',
    },
    title: {
      textAlign: 'left',
    },
  }),
  {index: 1},
);

export default useStyles;
