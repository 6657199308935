import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    itemContainer: {
      width: '100%',
      margin: 'auto',
      '& .MuiFilledInput-root': {
        backgroundColor: 'unset',
      },
      '& .MuiInputLabel-root': {
        color: theme.palette.primary.dark,
        fontWeight: '600 !important',
      },
    },
    customSelectElement: {
      '& .MuiFormLabel-root': {
        fontSize: '0.875rem',
      },
      '& .MuiFormLabel-filled': {
        transform: 'translate(0, 24px) scale(1)',
      },
      '& .MuiFormLabel-filled.MuiInputLabel-shrink': {
        transform: 'translate(12px, 10px) scale(0.75)',
      },
      '& .MuiSelect-root': {
        paddingLeft: '12px',
        marginTop: '5px',
      },
    },
    itemContainerWrapper: {
      display: 'block',
    },
    padding3units: {
      padding: theme.spacing(3, 3, 3, 3),
    },

    buttonContainer: {
      justifyContent: 'center',
    },
  }),
  {index: 1},
);

export default useStyles;
