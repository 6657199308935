import React, {useCallback, useMemo, useState} from 'react';
import ModalError from 'components/modal/error/modal-error';
import {useTranslation} from 'lib/i18n';
import {useUpdateEffect} from 'react-use';

InterviewerModalError.propTypes = {};

function InterviewerModalError(props) {
  const {open, email, errorMessage} = props;

  const {t} = useTranslation();

  const defaultErrorMessage = useMemo(() => t('link-user-license-validation-on-error', {email}), [t, email]);

  const [state, setState] = useState({open: false, errorMessage: defaultErrorMessage});
  const updateState = useCallback((newState) => setState((oldState) => ({...oldState, ...newState})), [setState]);

  const handleClose = useCallback(() => updateState({open: false}), [updateState]);

  useUpdateEffect(() => {
    updateState({open, errorMessage: errorMessage || defaultErrorMessage});
  }, [open, errorMessage, defaultErrorMessage]);

  return <ModalError open={state.open} onClose={handleClose} errorMessage={state.errorMessage} />;
}

export default InterviewerModalError;
