import React, {useCallback} from 'react';
import FormContextProvider from 'contexts/form-context';
import {useDispatch} from 'react-redux';
import {useNewEmptyIllustration} from 'hooks/illustrator';
import {withProductComparisonList} from 'hooks/product-comparison';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {updateSelectedIllustration} from 'reducers/illustrator.reducer';
import {useCalculatorValidationSchema} from '../useCalculatorValidationSchema';
import CalculatorFormElement from '../calculator-form/CalculatorFormElement';
import Item from 'components/common/layout/item/Item';
import ProductsWidget from 'pages/widgets-page/calculator-page/components/products-widget/ProductsWidget';

CalculatorForm.propTypes = {};

function CalculatorForm(props) {
  const {data: {Calculations} = {}, loading} = props;

  const dispatch = useDispatch();
  const {defaultValues} = useNewEmptyIllustration({});

  const onSubmit = useCallback(
    async (data) => {
      dispatch(updateSelectedIllustration(data));
    },
    [dispatch],
  );

  const {validationSchema} = useCalculatorValidationSchema();

  return (
    <>
      <Item xs={12}>
        <FormContextProvider defaultState={{validationSchema}}>
          <CalculatorFormElement onSubmit={onSubmit} defaultValues={defaultValues} loading={loading} />
        </FormContextProvider>
      </Item>

      <ProductsWidget Calculations={Calculations} />
    </>
  );
}

export default withProductComparisonList(CalculatorForm, {productFeatureKey: PRODUCT_FEATURE_KEY.WIDGETS_CALCULATION});
