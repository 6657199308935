import {SecureRoute} from '@okta/okta-react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import React from 'react';
import FeaturesWrapper from 'components/common/features-wrapper/FeaturesWrapper';

/**
 *
 * @param props {RouteProps}
 * @param props.isSecure {boolean}
 * @param props.productFeatureKey {string}
 * @param props.productFeatureRedirectPath {string}
 * @return {JSX.Element}
 * @constructor
 */
function FeaturesRoute(props) {
  const {
    isSecure = false,
    productFeatureKey,
    Component = isSecure ? SecureRoute : Route,
    productFeatureRedirectPath,
    render,
    ...rest
  } = props;

  return (
    <Component
      {...rest}
      render={(props) => (
        <FeaturesWrapper
          ProductFeatureKey={productFeatureKey}
          Component={render}
          NotEnabledComponent={productFeatureRedirectPath ? Redirect : null}
          to={productFeatureRedirectPath}
          {...props}
        />
      )}
    />
  );
}

FeaturesRoute.propTypes = {};

export default FeaturesRoute;
