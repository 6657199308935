import React, {useCallback, useMemo} from 'react';
import Select from 'components/common/form/select/Select';
import {useDeepMemo} from 'hooks/utils';
import {useSelectHookRangeValues} from 'hooks/input';
import {useFormContext} from 'react-hook-form';
import {paymentTypeToXcelerate, xcelerateToPaymentType} from 'services/payment-type.service';

function PaymentTypesList(props) {
  const {paymentTypes = [], controlName, defaultValue, variant = 'outlined', enableOptionPrompt} = props;

  const options = useDeepMemo(() => {
    let paymentTypesData = paymentTypes.map(paymentTypeToXcelerate) ?? [];
    if (enableOptionPrompt) {
      paymentTypesData = [
        ...paymentTypesData,
        {
          PaymentTypeId: 0,
          PaymentTypeName: '',
          selected: true,
          disabled: true,
          hidden: true,
        },
      ];
    }
    return paymentTypesData;
  }, [paymentTypes]);

  const selectedOption = useMemo(
    () => options.find((option) => option.label === defaultValue),
    [defaultValue, options],
  );
  const selectedValue = useMemo(() => selectedOption?.value, [selectedOption]);

  const {setValue} = useFormContext();

  const onChange = useCallback(
    (event, data) => {
      setValue(controlName.replace('Id', ''), xcelerateToPaymentType(data), {shouldDirty: true, shouldTouch: true});
    },
    [controlName, setValue],
  );

  useSelectHookRangeValues({
    name: controlName,
    data: options,
    defaultValue: selectedValue,
  });

  return (
    <Select name={controlName} defaultValue={selectedValue} options={options} variant={variant} onChange={onChange} />
  );
}

PaymentTypesList.propTypes = {};

export default PaymentTypesList;
