import CalculationsApi from 'api/calculations-api';
import {useMutation} from 'react-query';
import {saveBorrowerNotesMutationKey} from 'constants/query';

export function useSaveBorrowerNotesMutation() {
  return useMutation([saveBorrowerNotesMutationKey], ({CalculationId, notes}) =>
    CalculationsApi.saveBorrowerNotes({
      CalculationId,
      Notes: notes,
    }),
  );
}
