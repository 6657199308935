import React from 'react';
import Input from '../form/input/Input';
import {useJumboRateWatch} from 'hooks/jumbo-rate';

function ZipCode(props = {}) {
  const {withApiRoute = true, name = 'BorrowerProfile.Zip', ...rest} = props;

  useJumboRateWatch({withApiRoute});

  return <Input id={name} type="zip" name={name} {...rest} />;
}

export default ZipCode;
