import {useCallback, useEffect, useMemo, useState} from 'react';
import {LOADING_STATUS} from 'constants/loading';
import {useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';
import {getIllustrationLabel} from 'lib/borrower';
import {useGetCalculationByIdQuery} from 'query/calculations/get-calculation';
import {statusToLoading} from 'adapter/loading.adapter';
import moment from 'lib/moment';
import {usePrintContext} from 'contexts/print-context';
import {useMobileApp} from 'lib/app-theme';
import {PRINT_HEIGHT, PRINT_WIDTH} from 'constants/print';
import {generateSafePath} from 'lib/react-router-dom';
import {ROUTES} from 'constants/routes';
import {isDevelop} from 'constants/app';
import html2pdf from 'html2pdf.js';
import {useProductsContext} from 'contexts/products-context';

/**
 *
 * @param {Object} props
 * @param {Function} props.setState
 * @param {Object} props.defaultValues
 * @param {Function} props.handleClose
 */
export function usePrintModal(props) {
  const {setState, defaultValues, handleClose} = props;

  const {printing} = usePrintContext();

  useEffect(() => {
    if (printing) handleClose();
    return () => {
      if (printing) setState(defaultValues);
    };
  }, [printing, setState, handleClose, defaultValues]);
}

export function useSpace(props = {}) {
  const {forceSpace, withMobileApp = true} = props;
  const {printing} = usePrintContext();
  const isMobileApp = useMobileApp();

  const space = useMemo(() => {
    if (forceSpace) return '\n';
    if (printing) return '\n';
    if (withMobileApp && isMobileApp) return '\n';

    return '';
  }, [printing, isMobileApp, forceSpace, withMobileApp]);

  return {space};
}

function formatFileName(CalculationId, illustration, ProductName) {
  return `Illustration - ${ProductName} - ${getIllustrationLabel(illustration, 'MM-DD-YYYY')} - ${moment(
    illustration?.SysCreateDate,
  ).format('MM-DD-YYYY hh-mm')}`;
}

export function usePDFMatch() {
  const location = useLocation();

  return {isExact: !!location.pathname.match(/pdf/i)};
}

export function useIllustrationPDFPrint() {
  const [loading, setLoading] = useState(LOADING_STATUS.IDLE);
  const selectedIllustration = useSelector(getSelectedIllustration);
  const {products} = useProductsContext();

  const defaultSrc = useMemo(
    () => generateSafePath(ROUTES.ILLUSTRATOR_PDF_PAGE, {CalculationId: selectedIllustration.CalculationId}),
    [selectedIllustration.CalculationId],
  );

  const handlePDF = useCallback(
    (args = {}) => {
      const {src = defaultSrc, illustration = selectedIllustration, handlePDF = (html2pdf) => html2pdf.save()} = args;
      setLoading(LOADING_STATUS.PENDING);

      return new Promise((resolve, reject) => {
        const iFrame = document.createElement('iframe');
        iFrame.setAttribute('src', src);
        iFrame.setAttribute(
          'style',
          `position: absolute; left: -100000px; width: ${PRINT_WIDTH}px; min-height: ${PRINT_HEIGHT}px`,
        );

        document.body.appendChild(iFrame);

        iFrame.contentWindow.addEventListener('print-start', () => {
          const filename = `${formatFileName(
            illustration.CalculationId,
            illustration,
            products?.find((product) => product.ApiRoute === illustration?.ApiRoute)?.ProductName,
          )}.pdf`;

          const html2PDF = html2pdf()
            .set({
              pagebreak: {
                mode: 'css',
              },
              margin: 0,
              filename,
              jsPDF: {
                unit: 'px',
                format: [PRINT_WIDTH, PRINT_HEIGHT],
                orientation: 'portrait',
                hotfixes: ['px_scaling'],
              },
              html2canvas: {
                logging: isDevelop,
                scale: 1,
                scrollX: 0,
                scrollY: 0,
              },
            })
            .from(iFrame.contentWindow.document.documentElement, 'element');

          handlePDF(html2PDF, filename)
            .then(() => {
              setLoading(LOADING_STATUS.LOADED);
              document.body.removeChild(iFrame);
              resolve();
            })
            .catch((e) => {
              setLoading(LOADING_STATUS.NOT_LOADED);
              reject();
            });
        });
      });
    },
    [defaultSrc, products, selectedIllustration],
  );

  return {handlePDF, loading, setLoading};
}

export function usePDFPage(props = {}) {
  const {CalculationId} = useParams();
  const {enabled = true, id = CalculationId, onSuccess, withHistory = true, timeout} = props;

  const history = useHistory();

  const {data, status, isLoading} = useGetCalculationByIdQuery({
    id,
    enabled: enabled && (withHistory ? !!history.location.pathname.match(/pdf/i) : true),
    onSuccess,
    timeout,
  });

  return {CalculationId: id, data, loading: statusToLoading(status, isLoading)};
}
