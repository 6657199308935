import React from 'react';
import {NumericFormat as NumberFormat} from 'react-number-format';
import {roundToNearest} from 'lib/integer';
import {useOnChangeInterceptor} from 'components/common/form/input/components/OnChangeInterceptor';

function Integer(props) {
  const {inputRef, onChange, ...other} = props;

  const {doChange} = useOnChangeInterceptor({onChange, getValue: (event) => roundToNearest(event.target.value)});

  return <NumberFormat {...other} getInputRef={inputRef} onChange={doChange} valueIsNumericString />;
}

Integer.propTypes = {};

export default Integer;
