import React from 'react';
import {useTranslation} from 'lib/i18n';
import IconButton from 'components/common/button/IconButton';
import {Typography} from '@material-ui/core';

function EmailButton(props) {
  const {t} = useTranslation();

  return (
    <IconButton
      colorVariant="white"
      component="a"
      href={t('appHeaderTranslation:mail')}
      icon={['fas', 'envelope']}
      data-testid="email-button"
    >
      <Typography variant="subtitle2">{t('appHeaderTranslation:email')}</Typography>
    </IconButton>
  );
}

export default EmailButton;
