import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {take} from 'lodash';
import {Button, List, ListItem, ListItemText} from '@material-ui/core';
import {fontWeight600, fontWeight700} from 'lib/mui-config';
import {useTranslation} from 'lib/i18n';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useStyles from './ProductResultStyles';
import Tooltip, {TOOLTIP_POSITION} from 'components/common/tooltip/Tooltip';
import Title from 'components/common/typeography/title/Title';
import Text from 'components/common/typeography/text/Text';

ProductResult.propTypes = {};

const TOGGLE_ALL = 'TOGGLE_ALL';

function ProductResult(props) {
  const {items = [], defaultItemSize = 4, enabled = true, className} = props;

  const {t} = useTranslation();
  const classes = useStyles();

  const [itemsSize, setItemsSize] = useState(defaultItemSize);

  const isDefaultSize = useMemo(() => itemsSize === defaultItemSize, [defaultItemSize, itemsSize]);
  const listItems = useMemo(() => take(items, itemsSize), [items, itemsSize]);

  const showButtonMore = useMemo(() => items?.length > defaultItemSize, [defaultItemSize, items]);

  const toggleItemList = useCallback(
    (event) => {
      if (!event?.noDispatch) window.dispatchEvent(new CustomEvent(TOGGLE_ALL));

      setItemsSize(isDefaultSize ? items.length : defaultItemSize);
    },
    [items.length, setItemsSize, isDefaultSize, defaultItemSize],
  );

  useEffect(() => {
    function listener() {
      toggleItemList({noDispatch: true});
    }
    window.addEventListener(TOGGLE_ALL, listener);

    return () => {
      window.removeEventListener(TOGGLE_ALL, listener);
    };
  }, [toggleItemList]);

  if (!enabled) return null;

  return (
    <List className={className}>
      {listItems.map((item) => (
        <ListItem
          key={item.key}
          className={classes.productResultListItem}
          data-testid={`product-result-item-${item.key}`}
        >
          <Tooltip
            placement="right"
            title={item.tooltip ?? ''}
            className={classes.productResultListItemTooltipContainer}
            tooltipIconClassName={classes.productResultListItemTooltipImg}
            position={TOOLTIP_POSITION.END}
          >
            <ListItemText
              disableTypography
              primary={
                <Text
                  data-testid={`product-result-item-${item.key}-primary`}
                  style={{fontWeight: fontWeight600}}
                  variant="body1"
                  dangerouslySetInnerHTML={{__html: item.primary}}
                />
              }
              secondary={
                <Text
                  data-testid={`product-result-item-${item.key}-secondary`}
                  style={{color: item.color, fontWeight: fontWeight700}}
                  variant="h6"
                  dangerouslySetInnerHTML={{__html: item.secondary}}
                />
              }
            />
          </Tooltip>
        </ListItem>
      ))}

      {showButtonMore && (
        <Button
          className={classes.productResultToggleButton}
          onClick={toggleItemList}
          variant="text"
          fullWidth
          data-testid="product-result-toggle-button"
        >
          <Title variant="h6">{isDefaultSize ? t('app.show more') : t('app.show less')}</Title>
          <FontAwesomeIcon
            className={classes.productResultToggleIcon}
            icon={['fas', isDefaultSize ? 'angle-down' : 'angle-up']}
          />
        </Button>
      )}
    </List>
  );
}

export default ProductResult;
