import React, {useMemo} from 'react';
import {Slider as MuiSlider, Typography} from '@material-ui/core';
import {useAutoSubmit} from 'hooks/auto-submit';
import {useOnChangeInterceptor} from 'components/common/form/input/components/OnChangeInterceptor';

const Slider = React.forwardRef(function SliderRender(props, ref) {
  const {
    label,
    name,
    onChange,
    onBlur,
    autoSubmit,
    autoSubmitBlur,
    fullWidth,
    InputProps,
    value: val = 0,
    error,
    helperText,
    min = 0,
    max = 0,
    InputLabelProps,
    ...rest
  } = props;

  const value = useMemo(() => {
    if (min && val < min) return min;
    else if (max && val > max) return max;

    return val;
  }, [val, min, max]);

  const {onChange: onAutoSubmitHandle, onBlur: handleBlur} = useAutoSubmit({
    autoSubmitBlur,
    autoSubmit,
    onChange,
    value,
    onBlur,
  });
  const {doChange} = useOnChangeInterceptor({onChange: onAutoSubmitHandle, getValue: (event, value) => value});

  return (
    <>
      <Typography gutterBottom>{label}</Typography>
      <MuiSlider
        {...rest}
        ref={ref}
        min={min}
        max={max}
        name={name}
        value={value}
        onChange={doChange}
        valueLabelDisplay="auto"
        aria-labelledby={`${name}-slider`}
        onBlur={handleBlur}
      />
    </>
  );
});

export default Slider;
