import React from 'react';
import useStyles from './IllustratorPageStyles';
import {useStyleContentEffect} from 'hooks/style-content';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import Illustration from 'pages/illustrator/components/illustration/Illustration';
import {useNewIllustration} from 'hooks/illustrator';
import {withThemeProvider} from 'providers/ThemeProviders';
import {themeV2} from 'lib/mui-config';
import {withProducts} from 'components/products';
import Container from 'components/common/layout/container/Container';
import clsx from 'clsx';
import PublicDisclaimer from 'components/common/public-disclaimer/PublicDisclaimer';

function IllustratorV2(props) {
  const classes = useStyles();

  useStyleContentEffect(classes.content);

  return (
    <Container className={clsx(classes.illustratorBorrowerInfoContainer, 'fullWidth')}>
      <PublicDisclaimer>
        <Illustration />
      </PublicDisclaimer>
    </Container>
  );
}

const IllustratorV2Products = withProducts(IllustratorV2, {productFeatureKey: PRODUCT_FEATURE_KEY.ILLUSTRATION_DDL});

/**
 *
 * The "Illustrator V2" main component.
 *
 */

const IllustratorV2Page = React.memo((props) => {
  useNewIllustration();

  return <IllustratorV2Products {...props} />;
});

export default withThemeProvider(IllustratorV2Page, {theme: themeV2});
