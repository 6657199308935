import React from 'react';
import {useThrottleFn} from 'react-use';
import {renderElement} from 'lib/react';

LazyLoadStep.propTypes = {};

export function LazyLoadStep({children, ...rest}) {
  const isMounted = useThrottleFn(() => true, 100, []);

  if (!isMounted) return null;

  return renderElement(children, rest);
}

export function withLazyLoad(Component) {
  return function WithLazyLoadRender(props) {
    return (
      <LazyLoadStep>
        <Component {...props} />
      </LazyLoadStep>
    );
  };
}
