import {useCallback} from 'react';
import {BACKGROUND_CLICK_REASON} from 'constants/react-hook-form';
import {isFunction} from 'lodash';

export function useModalClose(props) {
  const {onClose: onCloseModule, disableBackdropClick} = props;

  const onClose = useCallback(
    (event, reason) => {
      if (disableBackdropClick && reason === BACKGROUND_CLICK_REASON) {
        return null;
      }

      return isFunction(onCloseModule) && onCloseModule(event, reason);
    },
    [onCloseModule, disableBackdropClick],
  );

  return {onClose};
}
