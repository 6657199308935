import {makeStyles} from '@material-ui/core/styles';

const noBorder = {
  '&:not(.last)': {
    borderRight: 'unset',
  },
};

const useStyles = makeStyles(
  (theme) => ({
    table: {
      margin: '0 auto',
      border: '1px solid ' + theme.palette.grey.light,
      borderCollapse: 'collapse',
    },
    tableHead: {
      backgroundColor: theme.palette.grey.light,
    },
    tableCell: {
      border: '1px solid ' + theme.palette.table.td.border,
      padding: theme.spacing(3, 2),
    },
    buttonContainer: {
      justifyContent: 'center',
    },
    deleteButton: {
      color: theme.palette.error.main,
    },
  }),
  {index: 1},
);

export default useStyles;
