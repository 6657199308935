import React, {useCallback, useMemo} from 'react';
import Form from 'components/common/form/Form';
import Item from 'components/common/layout/item/Item';
import Container from 'components/common/layout/container/Container';
import Title from 'components/common/typeography/title/Title';
import PropertyAddressFormElement from 'pages/illustrator-borrower/property/components/PropertyAddressFormElement';
import Input from 'components/common/form/input/Input';
import Select from 'components/common/form/select/Select';
import PropertyDetailsFormElements from 'pages/illustrator-borrower/property/components/PropertyDetailsFormElements';
import PropertyTaxesFormElements from 'pages/illustrator-borrower/property/components/PropertyTaxesFormElements';
import MailAddressFormElements from 'pages/illustrator-borrower/property/components/MailAddressFormElements';
import AdditionalPropertiesFormTable from 'pages/illustrator-borrower/property/components/AdditionalPropertiesFormTable';
import Button from 'components/common/button/Button';
import {getSelectedIllustratorBorrower, updateSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import {useStepWizardContext} from 'contexts/step-wizard';
import {useProductContext} from 'contexts/product-context';
import {useTranslation} from 'lib/i18n';
import {useDispatch, useSelector} from 'react-redux';
import {useUSStates} from 'hooks/us-states';
import {useDateOfBirthValidationMutation} from 'query/validations/date-of-birth';
import {useSnackbar} from 'notistack';
import useYupValidationSchema from 'pages/illustrator-borrower/property/components/useYupValidationSchema';
import Switch3States from 'components/common/form/switch-3-states/Switch3States';
import Switch3StatesElementErrors from 'components/common/form/switch-3-states/components/Switch3StatesElementErrors';

function PropertyForm(props) {
  const {nextStep, skipNextStep} = useStepWizardContext();

  const {product, productDetails: {additionalBorrowerFlowPropertyPageInputs = []} = {}} = useProductContext();

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {data: usStates = []} = useUSStates();

  // load the illustrator borrower data from the redux store
  const illustratorBorrower = useSelector(getSelectedIllustratorBorrower);

  const dateOfBirthValidation = useDateOfBirthValidationMutation();
  const {enqueueSnackbar} = useSnackbar();

  const onSubmit = useCallback(
    async (data) => {
      /**
       *
       * mapping the "property" attributes
       *
       */

      let property = {
        propertyAddress: data.propertyAddress,
        propertyAddress2: data.propertyAddress2,
        propertyCity: data.propertyCity,
        propertyState: data.propertyState,
        propertyZipCode: data.propertyZipCode,
        propertyHomeValue: data.propertyHomeValue,
        propertyMortgagePayoff: data.propertyMortgagePayoff,
        flagMailAddress: data.flagMailAddress,
        // "first mortgage" is a HomeSafe Second specific attr
        propertyFirstLien: data?.propertyFirstLien,
      };
      
      /**
       *
       * mapping the "mailing address" attributes
       *
       */

      let mailingAddress;

      if (data.flagMailAddress === 'true') {
        mailingAddress = {
          mailingAddress: data.propertyAddress,
          mailingAddress2: data.propertyAddress2,
          mailingCity: data.propertyCity,
          mailingState: data.propertyState,
          mailingZipCode: data.propertyZipCode,
        };
      }
      else {
        mailingAddress = {
          mailingAddress: data.mailingAddress,
          mailingAddress2: data.mailingAddress2,
          mailingCity: data.mailingCity,
          mailingState: data.mailingState,
          mailingZipCode: data.mailingZipCode,
        };
      }

      /**
       *
       * mapping the "property taxes" attributes
       *
       */

      let propertyTaxes = {
        annualCountyTaxAmount: data.annualCountyTaxAmount,
        annualHazardInsuranceAmount: data.annualHazardInsuranceAmount,
        propertyType: data.propertyType,
        NumberOfUnits: data.NumberOfUnits,
        hoaMonthlyFees: data.hoaMonthlyFees || '',
      };

      /**
       *
       * mapping the "additional properties" attributes
       *
       */

      let additionalProperties = {
        apFormList: data.apFormList,
      };

      // store the "combined property" page data
      let data2submit = {
        property: property,
        mailingAddress: mailingAddress,
        propertyTaxes: propertyTaxes,
        additionalProperties: additionalProperties,
      };

      await dispatch(updateSelectedIllustratorBorrower(data2submit));

      /**
       *
       * the next step should be : credit authorization
       *
       * !!! make sure to double check this feature, at it can skip the "mailing address" page
       *
       */

      return dateOfBirthValidation.mutateAsync(
        {
          DateOfBirth: illustratorBorrower?.borrowerInfo?.borrowerDOB,
          Zip: data?.propertyZipCode,
          ApiRoute: product?.ApiRoute,
        },
        {
          /**
           *
           * go to "credit authorization" page
           *
           */
          onSuccess: nextStep,
          onError: (error) => {
            if (error?.response?.data?.Message) enqueueSnackbar(error.response.data.Message, {variant: 'error'});
          },
        },
      );
    },
    [dispatch, dateOfBirthValidation, illustratorBorrower, product?.ApiRoute, nextStep, enqueueSnackbar, skipNextStep],
  );

  const validationSchema = useYupValidationSchema();

  const isStateAvailableOnSelectedProduct = useCallback(
    (state) => {
      if (product) {
        return product.ProductAvailableStates.find((availableState) => state.Abbreviation === availableState.State);
      }
      return true;
    },
    [product],
  );

  const propertyStates = useMemo(() => {
    return usStates.filter(isStateAvailableOnSelectedProduct).map((State) => ({
      label: State.Name,
      value: State.Abbreviation,
    }));
  }, [usStates, isStateAvailableOnSelectedProduct]);

  const defaultValues = useMemo(() => {
    return {
      ...illustratorBorrower?.property,
      ...illustratorBorrower?.mailingAddress,
      ...illustratorBorrower?.propertyTaxes,
      ...illustratorBorrower.additionalProperties,
    };
  }, [illustratorBorrower]);

  return (
    <Container component={Form} onSubmit={onSubmit} validationSchema={validationSchema} defaultValues={defaultValues}>
      {/** "Property Info" section / start */}
      <Item xs={0} md={1} lg={2} />

      <Item xs={12} md={10} lg={8}>
        <Container>
          <Item xs={12}>
            <Title variant="h5" style={{textAlign: 'left'}}>
              {t('illustratorTranslation:illustrator borrower property info.section')}
            </Title>
          </Item>

          <Item xs={12} sm={6}>
            <PropertyAddressFormElement propertyStates={propertyStates} />
          </Item>
          <Item xs={12} sm={6}>
            <Input name="propertyAddress2" variant="filled" />
          </Item>

          <Item xs={12} sm={6}>
            <Input name="propertyCity" variant="filled" />
          </Item>
          <Item xs={12} sm={6}>
            <Select variant="filled" name="propertyState" options={propertyStates} />
          </Item>

          <Item xs={12} sm={6}>
            <Input name="propertyZipCode" variant="filled" type="number" />
          </Item>

          <Item xs={12} align='left'>
            <Switch3States name="flagMailAddress" label={t('justText.flagMailAddress')} />
            <Switch3StatesElementErrors name="flagMailAddress" />
          </Item>
        </Container>
      </Item>

      <Item xs={0} md={1} lg={2} />
      {/** "Property Info" section / stop */}

      {/** "Mailing Address" section / start */}
      <Item xs={0} md={1} lg={2} />

      <Item xs={12} md={10} lg={8}>
        <MailAddressFormElements />
      </Item>

      <Item xs={0} md={1} lg={2} />
      {/** "Mailing Address" section / stop */}

      
      {/** "Property details" section / start */}
      <Item xs={0} md={1} lg={2} />

      <Item xs={12} md={10} lg={8}>
        <PropertyDetailsFormElements />
      </Item>

      <Item xs={0} md={1} lg={2} />
      {/** "Property details" section / stop */}

      {/** "Property Taxes" section / start */}
      <Item xs={0} md={1} lg={2} />

      <Item xs={12} md={10} lg={8}>
        <Container>
          <Item xs={12}>
            <Title variant="h5" style={{textAlign: 'left'}}>
              {t('illustratorTranslation:illustrator borrower property taxes.section')}
            </Title>
          </Item>

          <PropertyTaxesFormElements />
        </Container>
      </Item>

      <Item xs={0} md={1} lg={2} />
      {/** "Property Taxes" section / stop */}

      {/** "Additional Properties" section / start */}
      <Item xs={0} lg={1} />

      <Item xs={12} lg={10}>
        <Container>
          <Item xs={12}>
            <Title variant="h5" style={{textAlign: 'left'}}>
              {t('illustratorTranslation:illustrator borrower additional properties.section')}
            </Title>
          </Item>

          <AdditionalPropertiesFormTable />

          <Item xs={0} sm={3} />

          <Item xs={12} sm={6}>
            <Button
              buttonStyling={{lg: true}}
              btnStyle="bgElectric"
              type="submit"
              data-testid="goToNextOriginBorrowerInfo"
            >
              {t('translation:common.next')}
            </Button>
          </Item>

          <Item xs={0} sm={3} />
        </Container>
      </Item>

      <Item xs={0} lg={1} />
      {/** "Additional Properties" section / stop */}
    </Container>
  );
}

PropertyForm.propTypes = {};

PropertyForm.defaultValues = {};

export default PropertyForm;
