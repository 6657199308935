export function paymentTypeToXcelerate(data) {
  return {
    value: data.PaymentTypeId,
    label: data.PaymentTypeName,
  };
}

export function xcelerateToPaymentType(data) {
  return {
    PaymentTypeId: data.value,
    PaymentTypeName: data.label,
  };
}
