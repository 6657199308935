import {createSelector, createSlice} from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    breakDownTabsViewType: undefined,
    scenarios: [],
  },
  reducers: {
    setBreakDownViewType: (state, action) => {
      state.breakDownTabsViewType = action.payload;
    },
    setScenarios: (state, action) => {
      state.scenarios = action.payload;
    },
    updateScenario: (state, action) => {
      state.scenarios = state.scenarios.map((scenario) => {
        if (scenario.Key === action.payload.Key) {
          return action.payload;
        }

        return scenario;
      });
    },
    removeScenarios: (state, action) => {
      state.scenarios = [];
    },
  },
});

function selectSelf(state) {
  return state.settings;
}

export const getBreakDownViewType = createSelector(selectSelf, (state) => state.breakDownTabsViewType);
export const getScenario = (scenarioKey) =>
  createSelector(selectSelf, (state) => state.scenarios.find((scenario) => scenario.Key === scenarioKey));

export const {setBreakDownViewType, setScenarios, updateScenario, removeScenarios} = settingsSlice.actions;

export default settingsSlice.reducer;
