import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    item: {},
    hide: {
      display: 'none',
    },
    paymentAtLaterAgeContainer: {
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      padding: theme.spacing(3),
    },
  }),
  {index: 1},
);

export default useStyles;
