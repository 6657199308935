import React, {useCallback} from 'react';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './CustomCheckboxStyles';
import {isFunction} from 'lodash';
import {useOnChangeInterceptor} from 'components/common/form/input/components/OnChangeInterceptor';
import Title from 'components/common/typeography/title/Title';

function CustomCheckbox(props) {
  const {
    className,
    onChange,
    InputProps,
    InputLabelProps,

    label,
    error,
    fullWidth,
    helperText,
    required,
    type,
    variant,

    onExternalChange,

    classes: moduleClasses,
    value,
    name,

    indeterminate,

    ...rest
  } = props;
  const classes = useStyles();

  const handleChange = useCallback(
    (event, value) => {
      if (isFunction(onExternalChange)) {
        onExternalChange(event, value);
      }
      return onChange(event, value);
    },
    [onChange, onExternalChange],
  );

  const {doChange} = useOnChangeInterceptor({onChange: handleChange, getValue: (event, value) => value ?? false});

  return (
    <FormControlLabel
      {...rest}
      {...InputLabelProps}
      classes={{...moduleClasses, label: clsx(moduleClasses?.label, classes.customCheckboxLabel)}}
      control={
        <Checkbox
          checked={!!value}
          name={name}
          color="primary"
          className={classes.customCheckBox}
          indeterminate={indeterminate}
        />
      }
      label={React.isValidElement(label) ? label : <Title variant="subtitle2">{label}</Title>}
      onChange={doChange}
      className={clsx(classes.item, className)}
    />
  );
}

export default CustomCheckbox;
