import React from 'react';
import {LinearProgress} from '@material-ui/core';
import useStyles from './ProgressBarStyles';
import clsx from 'clsx';

function ProgressBar(props) {
  // props
  const {rounded, value} = props;
  const classes = useStyles();

  if (value === undefined) return null;

  return (
    <LinearProgress
      className={clsx(classes.root, {[classes.rounded]: rounded})}
      classes={{
        bar: classes.linearProgressBar,
        barColorPrimary: classes.linearProgressBarPrimary,
        root: classes.linearProgressContainer,
      }}
      variant="determinate"
      value={value}
    />
  );
}

ProgressBar.propTypes = {};

export default ProgressBar;
