import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      textAlign: 'left',
      width: '100%',
      margin: theme.spacing(2, 'auto'),
      display: theme.forPrint('block'),
    },
    buttonContinue: {},
    p2: {
      padding: theme.spacing(2, 3),
    },
    m2: {
      margin: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0),
      width: '100%',
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    borrowerNotesContainer: {
      margin: theme.spacing(2),
    },
    textArea: {
      '& textarea': {
        fontWeight: 'normal',
      },
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    subTitle: {
      fontWeight: 500,
    },
  }),
  {index: 1},
);

export default useStyles;
