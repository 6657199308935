import {makeStyles} from '@material-ui/core/styles';
import {isLgSizeAppQuery, isTabletAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    illustratorBreakdownForm: {
      position: 'relative',
    },
    illustratorBreakdownTabPanel: {
      paddingRight: 0,

      [isLgSizeAppQuery(theme)]: {
        paddingLeft: theme.spacing(0),
      },
      [isTabletAppQuery(theme)]: {
        padding: theme.spacing(2),
      },
    },
    tabElement: {
      '& span': {
        fontWeight: 500,
      },
    },
  }),
  {index: 1},
);

export default useStyles;
