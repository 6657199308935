import React from 'react';
import {isLoaded} from 'adapter/loading.adapter';

/**
 *
 * @param props {Object}
 * @param props.DefaultComponent {React.Component}
 * @param props.Component {React.Component}
 * @param props.NotEnabledComponent {React.Component}
 * @param props.isEnabled {boolean}
 * @param props.loading {string}
 * @return {JSX.Element|null}
 * @constructor
 */
function FeaturesRender(props) {
  const {
    DefaultComponent = 'div',
    loading,
    isEnabled,
    children,
    Component = DefaultComponent,
    NotEnabledComponent,
    ...rest
  } = props;

  if (isLoaded({loading})) {
    if (isEnabled) {
      return <Component {...rest} children={children} />;
    } else if (NotEnabledComponent) {
      return <NotEnabledComponent {...rest} />;
    }
  }

  return <DefaultComponent />;
}

export default FeaturesRender;
