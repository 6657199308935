import React, {useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import FundAccessInput from 'components/common/inputs/FundAccessInput';
import {useMinDateOfBirth} from 'hooks/input';

function useFundAccess() {
  const {setValue, watch} = useFormContext();

  const [ConversionAge = 0] = watch(['TenureResult.ConversionAge']);
  const {minAge = 0} = useMinDateOfBirth();

  const toggleFundAccess = useCallback(
    ({value: FundAccess} = {}) => {
      const data = {FundAccess, 'TenureResult.ConversionAge': minAge};

      Object.keys(data).map((key) => setValue(key, data[key], {shouldDirty: false}));
    },
    [minAge, setValue, ConversionAge],
  );

  return {toggleFundAccess};
}

function FundAccessAnnual(props) {
  const {toggleFundAccess} = useFundAccess();
  
  return <FundAccessInput {...props} onChange={toggleFundAccess} type={"ToggleButton"}/>;
}

export default FundAccessAnnual;
