import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {SecureRoute} from '@okta/okta-react';

import {ROUTES} from 'constants/routes';
import AppSidebar from 'components/app-sidebar/AppSidebar';
import SavedIllustratorPage from 'pages/saved-illustrator-page/SavedIllustratorPage';
import SavedBorrowerProfilesPage from 'pages/saved-borrower-profiles-page/SavedBorrowerProfilesPage';
import ProductAvailabilityPage from 'pages/product-availability-page/ProductAvailabilityPage';
import AuthErrorPage from 'pages/auth-error-page/AuthErrorPage';

import './App.css';
import IllustratorV2Page from 'pages/illustrator/IllustratorPage';
import ProductPDFPrintoutPage from 'pages/illustrator/components/product-printout-page/ProductPDFPrintoutPage';
import IllustratorBorrower from 'pages/illustrator-borrower/IllustratorBorrower';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import FeaturesRoute from 'components/common/routes/FeaturesRoute';
import SubProviders from 'providers/SubProviders';
import LoanPipelinePage from 'pages/loan-pipeline-page/LoanPipelinePage';
import LoanPipelineDetailsPage from 'pages/loan-pipeline-details-page/LoanPipelineDetailsPage';
import {startTracking, setUserId} from 'services/analytics.service';
import {useUserInfo} from 'hooks/user';

function App() {
  startTracking();

  const {userInfo} = useUserInfo();
  useEffect(() => {
    setUserId(userInfo?.Id);
  }, [userInfo]);

  setUserId(userInfo?.Id);

  return (
    <SubProviders>
      <div className="App" data-testid="app">
        <AppSidebar>
          <Switch>
            <Route
              path={ROUTES.PRODUCTS_AVAILABILITY_PAGE}
              render={(props) => <ProductAvailabilityPage {...props} />}
            />

            {/* ILLUSTRATOR : Borrower Link Pages : 1st page */}
            <SecureRoute
              path={ROUTES.ILLUSTRATOR_BORROWER_ROOT_PAGE}
              render={(props) => <IllustratorBorrower {...props} />}
            />
            <Route path={ROUTES.ILLUSTRATOR_PDF_PAGE} render={(props) => <ProductPDFPrintoutPage {...props} />} />
            <SecureRoute path={ROUTES.SAVED_ILLUSTRATOR_PAGE} render={(props) => <SavedIllustratorPage {...props} />} />
            <FeaturesRoute
              isSecure
              productFeatureKey={PRODUCT_FEATURE_KEY.SAVED_BORROWERS_PAGE}
              productFeatureRedirectPath={ROUTES.HOME_PAGE}
              path={ROUTES.SAVED_BORROWER_PROFILE_PAGE}
              render={(props) => <SavedBorrowerProfilesPage {...props} />}
            />
            <FeaturesRoute
              isSecure
              productFeatureKey={PRODUCT_FEATURE_KEY.LOAN_PIPELINE_DETAILS_PAGE}
              productFeatureRedirectPath={ROUTES.HOME_PAGE}
              path={ROUTES.LOAN_PIPELINE_DETAILS_PAGE}
              render={(props) => <LoanPipelineDetailsPage {...props} />}
            />
            <FeaturesRoute
              isSecure
              productFeatureKey={PRODUCT_FEATURE_KEY.LOAN_PIPELINE_SUMMARY_PAGE}
              productFeatureRedirectPath={ROUTES.HOME_PAGE}
              path={ROUTES.LOAN_PIPELINE_PAGE}
              render={(props) => <LoanPipelinePage {...props} />}
            />
            {/*
            !!! ADD NEW ROUTES WITH CAUTION AFTER THIS MESSAGE !!!
            */}
            <Route path={ROUTES.ILLUSTRATOR_ROOT_PAGE} render={(props) => <IllustratorV2Page {...props} />} />
            <Route path={ROUTES.AUTH_ERROR_PAGE} render={(props) => <AuthErrorPage {...props} />} />
            <Route path={ROUTES.HOME_PAGE} render={(props) => <IllustratorV2Page {...props} />} />
          </Switch>
        </AppSidebar>
      </div>
    </SubProviders>
  );
}

export default React.memo(App);
