import {omit} from 'lodash';

export function optionsToFlatMap(options = []) {
  return options.flatMap((option) => {
    if (option.options) {
      return [omit(option, 'options'), ...optionsToFlatMap(option.options)];
    }
    return option;
  });
}
