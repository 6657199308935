export class LoggerService {
  static enableLogs = process.env.NODE_ENV === 'development';

  static _logMessage(level, message, ...args) {
    if (this.enableLogs) console[level](message, ...args);
  }

  static log(message, ...args) {
    return this._logMessage('log', message, ...args);
  }

  static error(message, ...args) {
    return this._logMessage('error', message, ...args);
  }

  static debug(message, ...args) {
    return this._logMessage('debug', message, ...args);
  }

  static info(message, ...args) {
    return this._logMessage('info', message, ...args);
  }

  static warn(message, ...args) {
    return this._logMessage('warn', message, ...args);
  }
}

export const DataGridLogger = {
  error(message, ...args) {
    return LoggerService.error(message, ...args);
  },
  debug(message, ...args) {
    return LoggerService.debug(message, ...args);
  },
  info(message, ...args) {
    return LoggerService.info(message, ...args);
  },
  warn(message, ...args) {
    return LoggerService.warn(message, ...args);
  },
};
