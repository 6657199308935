import {Trans, useTranslation} from 'lib/i18n';
import useStyles from 'pages/illustrator/components/borrower-details/BorrowerDetailsStyles';
import {useIllustrationV2FormContextValues} from 'pages/illustrator/components/illustration/components/illustration-form/illustration-form-context';
import Item from 'components/common/layout/item/Item';
import Paper from 'components/common/paper/Paper';
import Container from 'components/common/layout/container/Container';
import FormContextProvider from 'contexts/form-context';
import {API_ROUTES} from 'constants/illustrator';
import BorrowerDetailsForm from 'pages/illustrator/components/borrower-details/components/BorrowerDetailsForm';
import LoginModal from 'components/app-header/components/login-modal/LoginModal';
import clsx from 'clsx';
import SlideOutModal from 'pages/slide-out-modal-page/slide-out-modal/SlideOutModal';
import SlideOutModalSubmitButton from 'pages/slide-out-modal-page/slide-out-modal/components/SlideOutModalSubmitButton';
import React from 'react';
import useBorrowerDetails from 'hooks/borrower-details';

/**
 *
 * The "Borrower Details Comp" component.
 *
 */

function BorrowerDetailsComp(props) {
  const {loadingProduct, hasSubmitted, setHasSubmitted, ...rest} = props;
  const {t} = useTranslation();
  const classes = useStyles();

  const {BorrowerProfile, ApiRoute} = useBorrowerDetails();

  const {extraArguments, defaultState} = useIllustrationV2FormContextValues({
    ApiRoute,
    Zip: BorrowerProfile?.Zip,
  });

  return (
    <Item {...rest}>
      <Paper className={classes.borrowerDetailsPaper}>
        <Container className={classes.borrowerDetailsContainer}>
          <Item xs={12}>
            <FormContextProvider
              defaultState={defaultState}
              extraArguments={extraArguments}
              reset={ApiRoute !== API_ROUTES.HYBRID}
            >
              <BorrowerDetailsForm hasSubmitted={hasSubmitted} setHasSubmitted={setHasSubmitted} />
            </FormContextProvider>
          </Item>

          <LoginModal>
            <Item xs={12} className={clsx(classes.borrowerDetailsButtonContainer)}>
              <SlideOutModal
                isBorrowDetails={true}
                defaultValues={BorrowerProfile}
                enabled={!!BorrowerProfile.BorrowerProfileId}
                submitButtonLabel={t('illustratorTranslation:borrower details.slideOut modal submit')}
              >
                <SlideOutModalSubmitButton
                  variant="text"
                  buttonStyling={{lg: true}}
                  data-testid="borrower-details-save-results-button"
                >
                  <Trans i18nKey="illustratorTranslation:borrower details.button save results" />
                </SlideOutModalSubmitButton>
              </SlideOutModal>
            </Item>
          </LoginModal>
        </Container>
      </Paper>
    </Item>
  );
}

export default BorrowerDetailsComp;
