import React, {useMemo} from 'react';
import {Area, AreaChart as RAreaChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis} from 'recharts';
import CurrencyChartAxisTick from 'components/common/recharts/CurrencyChartAxisTick';
import {useTheme} from '@material-ui/core';
import {formatCurrency} from 'lib/currency';
import YearChartAxisTick from 'components/common/recharts/YearChartAxisTick';
import {useTranslation} from 'lib/i18n';
import {useScrollSpeed} from 'hooks/parallax';
import {usePrintContext} from 'contexts/print-context';
import {useIntervalHook} from 'hooks/utils';

function AreaChart(props) {
  const {chartInterval, isTabletApp, ...rest} = useIntervalHook(props);

  const {t} = useTranslation();
  const {duration} = useScrollSpeed();
  const {printing} = usePrintContext();

  const isAnimationActive = useMemo(() => !printing, [printing]);

  const theme = useTheme();

  const showHomeValueYearEnd = useMemo(() => !!rest.data?.find((d) => d.homeValueYearEnd > 0), [rest.data]);
  const showLoanBalanceValue = useMemo(() => !!rest.data?.find((d) => d.loanBalanceValueYearEnd > 0), [rest.data]);

  return (
    <RAreaChart {...rest}>
      <CartesianGrid />
      <XAxis dataKey="name" interval={chartInterval} tick={<YearChartAxisTick rotate={!chartInterval} />} />
      <YAxis tick={<CurrencyChartAxisTick formatShort={isTabletApp} />} tickMargin={10} />
      <Tooltip formatter={(x) => formatCurrency(x)} />

      <Legend />

      {showHomeValueYearEnd && (
        <Area
          type="monotone"
          name={t('area chart section.chart label.homeValueYearEnd')}
          dataKey="homeValueYearEnd"
          stroke={theme.palette.primary.main}
          fill={theme.palette.primary.main}
          fillOpacity={0.9}
          isAnimationActive={isAnimationActive}
          animationDuration={duration}
        />
      )}
      {showLoanBalanceValue && (
        <Area
          type="monotone"
          name={t('area chart section.chart label.loanBalanceValueYearEnd')}
          dataKey="loanBalanceValueYearEnd"
          stroke={theme.palette.grey.dark}
          fill={theme.palette.white.light}
          fillOpacity={0.9}
          isAnimationActive={isAnimationActive}
          animationDuration={duration}
        />
      )}
    </RAreaChart>
  );
}

AreaChart.propTypes = {};

export default AreaChart;
