import React from 'react';
import {Box, MenuItem} from '@material-ui/core';
import useStyles from './IllustrationExtraDetailsMenuItemStyles';
import clsx from 'clsx';
import Text from 'components/common/typeography/text/Text';

const IllustrationExtraDetailsMenuItem = React.forwardRef(function IllustrationExtraDetailsMenuItemRender(
  props = {},
  ref,
) {
  const {children, className, ...rest} = props;

  const classes = useStyles();

  return (
    <MenuItem
      ref={ref}
      className={clsx(className, classes.illustrationExtraDetailsMenuItem)}
      {...rest}
      data-testid="illustration-extra-details-menu-item"
    >
      <Box className={classes.illustrationExtraDetailsBox}>
        <Text>{children}</Text>

        {/* TODO: temporary remove the extra details list from new illustration modal */}
        {/*<CalculationWarnings*/}
        {/*  component="div"*/}
        {/*  componentProps={{className: classes.illustrationExtraDetailsWarningContainer}}*/}
        {/*  products={[product]}*/}
        {/*  exclude={EXCLUDE_ILLUSTRATOR_WARNING_MESSAGES}*/}
        {/*  ApiRoute={product?.ApiRoute}*/}
        {/*  loading={loading}*/}
        {/*>*/}
        {/*  <IllustrationExtraDetails product={product} loading={loading} />*/}
        {/*</CalculationWarnings>*/}
      </Box>
    </MenuItem>
  );
});
IllustrationExtraDetailsMenuItem.propTypes = {};

export default IllustrationExtraDetailsMenuItem;
