import React from 'react';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import useStyles from './IllustratorBorrowerStyles';
import clsx from 'clsx';
import {useStyleContentEffect} from 'hooks/style-content';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import HeaderProvider from 'contexts/header-context';
import {useTranslation} from 'lib/i18n';
import {withFeatureToggle} from 'components/common/features-wrapper/FeaturesWrapper';
import IllustratorBorrowerWizard from 'pages/illustrator-borrower/illustrator-borrower-wizard/IllustratorBorrowerWizard';
import {withThemeProvider} from 'providers/ThemeProviders';
import {themeIllustratorBorrower} from 'lib/mui-config';

/**
 *
 * The "illustrator borrower" main component.
 *
 * !! Make sure that there is a "illustrator borrower" store available !!
 *
 */

function IllustratorBorrower(props) {
  /**
   *
   * !!! README !!! RULES !!!
   *
   * There should always be at least 2 active steps !
   * There should always be a start step !
   * There should always be a final step !
   * Any component might be inactive => less steps within the Step Wizard
   *
   * Due to the isEnabled validation, not all the components are registered as steps within the Step Wizard.
   *
   * the "borrower info" and "co-borrower info" are a group
   * if one page is active, both pages are active
   * if one page is inactive, both pages are inactive, so they will both be skipped
   *
   * the "additional property question" and "additional properties" are a group
   * if one page is active, both pages are active
   * if one page is inactive, both pages are inactive, so they will both be skipped
   *
   */

  const classes = useStyles();
  useStyleContentEffect(classes.content);
  const {t} = useTranslation();

  return (
    <HeaderProvider defaultState={{backButton: t('illustratorTranslation:buttons.exit button')}}>
      <Container
        id="illustratorBorrower"
        className={clsx(classes.illustratorBorrowerContainer)}
        data-testid="illustrator-borrower"
      >
        <Item xs={12} id="illustratorBorrowerContentItem">
          <IllustratorBorrowerWizard />
        </Item>
      </Container>
    </HeaderProvider>
  );
}

IllustratorBorrower.propTypes = {};

export default withFeatureToggle(withThemeProvider(IllustratorBorrower, {theme: themeIllustratorBorrower}), {
  ProductFeatureKey: PRODUCT_FEATURE_KEY.BORROWER_MAIN_KEY,
});
