import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    buttonContainer: {
      justifyContent: 'center',
    },
    table: {
      margin: '0 auto',
      border: 'none ',
      borderCollapse: 'collapse',

      '& td, & th': {
        border: 'none',
      },
    },
  }),
  {index: 1},
);

export default useStyles;
