import React, {useCallback} from 'react';
import GoBackAsArrowButton from 'components/common/go-back-as-arrow-button/GoBackAsArrowButton';
import DialogCloseButton from 'components/common/dialog-close-button/DialogCloseButton';
import {Box} from '@material-ui/core';
import useStyles from 'components/common/dialog/components/dialog-header/DialogHeaderStyles';
import clsx from 'clsx';
import {isFunction} from 'lodash';

/**
 *
 * @param {Xcelerate.DialogHeaderProps} props
 * @returns {JSX.Element}
 */
function DialogHeader(props) {
  const {
    className,
    withPrevious = false,
    doClose,
    withClose = !!doClose,
    childrenContainerClassName,
    previousStep,
    children,
    childrenMargin = !!children,
    closeButtonClassName,
    dialogHeaderBoxButtonsClassName,
    backButtonClassName,
  } = props;

  const classes = useStyles();
  const handleGoBackAsArrowButton = useCallback(() => isFunction(previousStep) && previousStep(), [previousStep]);

  return (
    <Box className={clsx(className, classes.dialogHeaderBox)}>
      <Box
        className={clsx(classes.dialogHeaderBoxButtons, dialogHeaderBoxButtonsClassName, {
          [classes.dialogHeaderBoxButton]: !withPrevious,
          [classes.dialogHeaderMargin]: !withClose && childrenMargin,
        })}
      >
        {withPrevious && <GoBackAsArrowButton onClick={handleGoBackAsArrowButton} className={backButtonClassName} />}
        {withClose && <DialogCloseButton onClick={doClose} className={closeButtonClassName} />}
      </Box>
      <Box
        className={clsx(classes.dialogHeaderChildrenContainer, childrenContainerClassName, {
          [classes.dialogHeaderChildrenContainerNoMargin]: !childrenMargin,
        })}
      >
        {children}
      </Box>
    </Box>
  );
}

export default DialogHeader;
