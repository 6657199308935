import React, {useMemo} from 'react';
import {TextField as MuiTextField, Tooltip} from '@material-ui/core';
import {useAutoSubmit} from 'hooks/auto-submit';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

/**
 *
 * This is the default form "input" field of type "text" component.
 *
 * The "TextField" component accepts a lot of params and can be customized.
 *
 * For details, open the "SocialSecurityNumber" component.
 *
 */

const TextField = React.forwardRef(function TextFieldRender(props, ref) {
  const {
    autoSubmit,
    autoSubmitBlur,
    autoSubmitTimeout,
    onChange,
    onBlur,
    value,
    onCustomChange,
    InputProps,
    toolTipText,

    onExternalChange,

    ...rest
  } = props;

  const {onChange: handleChange, onBlur: handleBlur} = useAutoSubmit({
    autoSubmitBlur,
    onChange,
    onCustomChange,
    autoSubmit,
    value,
    autoSubmitTimeout,
    onBlur,
  });

  const endAdornment = useMemo(
    () =>
      toolTipText ? (
        <>
          {InputProps?.endAdornment}
          <Tooltip title={toolTipText}>
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </>
      ) : (
        InputProps?.endAdornment
      ),
    [InputProps?.endAdornment, toolTipText],
  );

  return (
    <MuiTextField
      ref={ref}
      {...rest}
      value={value ?? ''}
      defaultValue={undefined}
      onChange={handleChange}
      onBlur={handleBlur}
      InputProps={{
        ...InputProps,
        endAdornment,
      }}
    />
  );
});

export default TextField;
