import React from 'react';
import {useWidgetApiKeyValidationQuery} from 'query/validations/widget-api-key';
import {useParams} from 'react-router-dom';

export function withApiKeyValidation(Component) {
  return (props) => {
    const {apiKey} = useParams();
    const {isSuccess} = useWidgetApiKeyValidationQuery({apiKey});

    if (isSuccess) return <Component {...props} />;

    return null;
  };
}
