import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import TooltipLabel from 'components/common/tooltip/TooltipLabel';

/**
 *
 * @param {Object} props
 * @param {string} props.label
 * @param {?string} props.tooltip
 * @param {?string} props.className
 * @param {?string} props.id
 * @returns {JSX.Element}
 * @constructor
 */
function ListItemTextFormatted(props) {
  const {id, tooltip, label, className, ...rest} = props;

  return (
    <ListItemText
      id={id}
      primary={tooltip ? <TooltipLabel label={label} tooltip={tooltip} /> : label}
      className={className}
      {...rest}
    />
  );
}

export default ListItemTextFormatted;
