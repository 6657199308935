import {DateOfBirth} from 'lib/validation/validation';
import {useCallback, useMemo} from 'react';
import {getAgeRestriction, getCurrentState, toMomentDateValidation} from 'lib/utils';
import {MAX_BORROWER_AGE} from 'constants/borrower';
import {useGetDateOfBirthValidationQuery} from 'query/validations/date-of-birth';

export function useDateOfBirthValidation(props = {}) {
  const {data} = useGetDateOfBirthValidationQuery(props);

  return useMemo(() => DateOfBirth(data), [data]);
}

export function useProductValidation(props = {}) {
  const {wrapProductData = () => ({})} = props;

  const addProductValidation = useCallback(
    (calculation, product) => {
      const {State, productMetric, productAvailableStates} = wrapProductData(calculation, product);
      const currentState = getCurrentState(productAvailableStates, State);

      const minBorrowerAge = getAgeRestriction(productMetric, currentState, 'MinBorrowerAge');
      const maxBorrowerAge = getAgeRestriction(productMetric, currentState, 'MaxBorrowerAge') ?? MAX_BORROWER_AGE;

      let result = {
        minDate: toMomentDateValidation(maxBorrowerAge),
      };

      if (minBorrowerAge) {
        result.maxDate = toMomentDateValidation(minBorrowerAge);
      }

      return result;
    },
    [wrapProductData],
  );

  return {addProductValidation};
}
