import React from 'react';
import {useEnabledProductFeatures} from 'hooks/product-features';
import {isLoaded} from 'adapter/loading.adapter';
import {useDeepMemo} from 'hooks/utils';

/**
 *
 * The "FeaturesWrapper" method is used when we must use a "product feature key" in order to render a component.
 *
 * Given a "component" & "product feature key", if the "product feature key" is enabled, the "component" gets rendered.
 *
 * Also, if a "not enabled component" is provided, if the "product feature key" is disabled, the "not enabled component" will be rendered.
 *
 * @param props {Object}
 * @param props.Component {React.Component}
 * @param props.NotEnabledComponent {React.Component}
 * @param props.ProductFeatureKey {string}
 * @return {JSX.Element|null}
 * @constructor
 *
 */

function FeaturesWrapper(props) {
  const {Component = React.Fragment, ProductFeatureKey, NotEnabledComponent, children, ...rest} = props;
  const {isEnabled, loading} = useEnabledProductFeatures(ProductFeatureKey);

  const componentProps = useDeepMemo(() => {
    if (Component === React.Fragment) {
      return {};
    }

    return {
      isEnabled,
      ...rest,
    };
  }, [Component, isEnabled, rest]);
  /**
   * If feature toggle is enabled, render the component,
   * else if feature toggle is not enabled, and we provide an NotEnabledComponent, render NotEnabledComponent
   */
  if (isLoaded({loading})) {
    if (isEnabled) {
      return <Component {...componentProps} children={children} />;
    } else if (NotEnabledComponent) {
      return <NotEnabledComponent {...componentProps} />;
    }
  }

  return null;
}

export function excludeFeatureWrapperKeys(props = {}) {
  const {isEnabled, ...rest} = props;

  return {...rest};
}

/**
 *
 * @param Component {function(*)}
 * @param options {object}
 * @param options.ProductFeatureKey {string}
 * @return {function(*): function(*): *}
 */
export function withFeatureToggle(Component, {ProductFeatureKey} = {}) {
  /**
   *
   * @param props
   * @return {JSX.Element}
   */
  return function renderWithFeatureToggle(props) {
    return <FeaturesWrapper Component={Component} ProductFeatureKey={ProductFeatureKey} {...props} />;
  };
}

export default FeaturesWrapper;
