import {useQueryProducts} from 'hooks/query-products';
import React from 'react';
import Product from 'components/products/product';

export function withProduct(Component) {
  return function WithProductRender({isSelectedProduct, queryKey, fetching, ...props}) {
    const {product, loading, loadingProduct} = useQueryProducts({isSelectedProduct, queryKey, fetching});

    return (
      <Product product={product} loading={loading} loadingProduct={loadingProduct}>
        <Component {...props} product={product} loading={loading} loadingProduct={loadingProduct} />
      </Product>
    );
  };
}
