import React from 'react';
import {queryClient} from 'lib/query';
import {isDevelop} from 'constants/app';
import {ReactQueryDevtools} from 'react-query/devtools';
import {QueryClientProvider as RQueryClientProvider} from 'react-query';

function QueryClientProvider(props) {
  const {children} = props;

  return (
    <RQueryClientProvider client={queryClient}>
      {isDevelop && <ReactQueryDevtools />}

      {children}
    </RQueryClientProvider>
  );
}

QueryClientProvider.propTypes = {};

export default QueryClientProvider;
