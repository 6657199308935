import React from 'react';
import {Text} from 'recharts';
import {formatCurrency} from 'lib/currency';
import {useRotateYearChartAxis} from 'hooks/utils';

function CurrencyChartAxisTick(props) {
  const {x, y, textAnchor, verticalAnchor, payload, formatShort} = props;
  const {rotateX, rotateY, rotateAngle} = useRotateYearChartAxis(props);

  return (
    <Text x={x + rotateX} y={y + rotateY} angle={rotateAngle} textAnchor={textAnchor} verticalAnchor={verticalAnchor}>
      {formatCurrency(payload.value, formatShort)}
    </Text>
  );
}

CurrencyChartAxisTick.propTypes = {};

export default CurrencyChartAxisTick;
