import React, {useMemo} from 'react';

/**
 *
 * html custom components
 *
 */
import Item from 'components/common/layout/item/Item';
import Title from 'components/common/typeography/title/Title';
import Container from 'components/common/layout/container/Container';

/**
 *
 * page dedicated components
 *
 */
import useStyles from './DemographicInfoEthnicityFormSectionStyles';

/**
 *
 * miscellaneous
 *
 */
import {useTranslation} from 'lib/i18n';

/**
 *
 * custom hooks
 *
 */
import InputOptions from 'components/common/form/input/InputOptions';
import DemographicInfoErrors from 'pages/illustrator-borrower/interview-and-demographic-info/components/DemographicInfoErrors';
import yup from 'lib/validation';
import EthnicityValidation from 'lib/validation/illustrator-borrower/ethnicity-validation';
import {useDemographicInformationOptions} from 'hooks/link-application/borrower-info/demographic-information-source-options';

/**
 *
 * ***** SECTION #1 *****
 *
 * DemographicInfo form "Ethnicity" section
 *
 *  Options :
 *
 *    Hispanic or Latino
 *      - Mexican
 *      - Puerto Rican
 *      - Cuban
 *      - Other Hispanic or Latino
 *        - Please provide origin
 *    Not Hispanic or Latino
 *    Do not wish to provide this information
 *
 */

function DemographicInfoEthnicityFormSection(props) {
  const {
    prefix = '', // !!! the prefix prop is required !!!
  } = props;

  const classes = useStyles();
  const {t} = useTranslation();

  const prefixForSectionHeader = useMemo(() => {
    return t(`translation:common.${prefix.toLowerCase()}`);
  }, [t, prefix]);

  const {defaultOption} = useDemographicInformationOptions();
  const ethnicityValidationSchema = useMemo(
    () =>
      yup.object(
        EthnicityValidation({
          prefix,
          demographicInformationSource: defaultOption,
        }),
      ),
    [defaultOption, prefix],
  );

  return (
    <Container>
      {/* section title / start */}
      <Item xs={12}>
        <Title variant="h3">
          {prefixForSectionHeader} {t('illustratorTranslation:demographic information.ethnicity')}
        </Title>
      </Item>
      {/* section title / stop */}

      <Item xs={1} />
      {/* contents / start */}
      <Item xs={10}>
        <DemographicInfoErrors prefix={`${prefix}Ethnicity`} validationSchema={ethnicityValidationSchema} />

        <InputOptions
          options={t('illustratorBorrowerTranslation:ethnicity.options', {
            returnObjects: true,
            prefix: prefix,
          })}
          className={classes.labels}
          itemProps={{
            className: classes.checkboxItem,
          }}
          containerProps={{
            className: classes.checkboxContainer,
          }}
          itemContainerProps={{
            className: classes.checkboxItemContainer,
          }}
        />
      </Item>
      {/* contents / stop */}
      <Item xs={1} />
    </Container>
  );
}

DemographicInfoEthnicityFormSection.propTypes = {};

export default DemographicInfoEthnicityFormSection;
