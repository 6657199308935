import React, {useEffect, useMemo} from 'react';
import clsx from 'clsx';
import {LinearProgress} from '@material-ui/core';
import useStyles from './LinearLoaderStyles';
import {isPending} from 'adapter/loading.adapter';
import {useBodyLockContext} from 'contexts/body-lock-context';

function LinearLoader(props) {
  const {
    className,
    children,
    childrenContainerClassName,
    childrenRest = {},
    loading,
    fixedLoader,
    disableScroll,
    absoluteLoader,
    showLoader = true,
    ...rest
  } = props;
  const classes = useStyles();

  const open = useMemo(() => isPending({loading}), [loading]);

  const {onDisableBodyScroll, onEnableBodyScroll, childrenRef} = useBodyLockContext();

  useEffect(() => {
    if (showLoader && open && disableScroll) onDisableBodyScroll();

    return () => {
      if (showLoader && open && disableScroll) onEnableBodyScroll();
    };
  }, [open, disableScroll, onDisableBodyScroll, onEnableBodyScroll, loading, showLoader]);

  return (
    <>
      {showLoader && open && (
        <LinearProgress
          className={clsx(classes.loader, className, {
            [classes.fixedLoader]: fixedLoader,
            [classes.absoluteLoader]: absoluteLoader,
          })}
          {...rest}
        />
      )}

      {children && (
        <div
          ref={childrenRef}
          className={clsx(childrenContainerClassName, classes.childrenContainer, {
            [classes.childrenContainerLoading]: open && !disableScroll,
          })}
          {...childrenRest}
        >
          {children}
        </div>
      )}
    </>
  );
}

LinearLoader.propTypes = {};

export default LinearLoader;
