import {makeStyles} from '@material-ui/core/styles';
import {fontWeightMedium, fontWeightRegular} from 'lib/mui-config';

const useStyles = makeStyles(
  (theme) => ({
    productCard: {
      maxWidth: theme.spacing(40),
      height: '100%',
      overflow: 'visible',
    },
    productCardRecommendedChip: {
      position: 'absolute',
      top: theme.spacing(-2),
      backgroundColor: theme.palette.recommended.main,
      color: theme.palette.getContrastText(theme.palette.recommended.main),
      zIndex: 1,
    },
    productCardActionArea: {
      padding: theme.spacing(3, 2),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
    },
    productCardImageContainer: {
      height: '110px',
      width: '100%',
      textAlign: 'center'
    },
    productCardImageContainerNoText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    productCardImage: {
      minHeight: 85,
      backgroundSize: 'contain',
      width: '100%',
    },
    productCardName: {
      textTransform: 'uppercase',
      color: '#003B5C',
      fontSize: '16px'
    },
    productCardImageSmall: {
      minHeight: theme.spacing(5),
      marginBottom: theme.spacing(1),
    },
    productCardDescriptionRoot: {
      fontWeight: `${fontWeightRegular}`,
      textAlign: 'left',
    },
    productCardDescriptionContainer: {
      marginBottom: theme.spacing(1),
      textAlign: 'center',
      fontSize: `${theme.spacing(1.75)}px !important`,
      fontWeight: `${fontWeightMedium} !important`,
      lineHeight: `${theme.spacing(2)}px !important`,
    },
    productCardCardContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    productCardAlign: {
      textAlign: 'center',
    },
    productCardButtonRoot: {
      margin: theme.spacing(1, 0),
    },
    productCardButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 'auto',
    },
    productCardSmallResults: {
      width: '100%',
    },

    hide: {
      display: 'none',
    },
  }),
  {index: 1},
);

export default useStyles;
