import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'lib/i18n';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import useStyles from './InterviewAndDemographicInfoStyles';
import InterviewAndDemographicInfoComponents from './components/InterviewAndDemographicInfoComponents';
import {useDispatch, useSelector} from 'react-redux';
import {
  defaultDemographicInfoBorrowerForNewInterviewMethod,
  defaultDemographicInfoCoBorrowerForNewInterviewMethod,
  getSelectedIllustratorBorrower,
  updateSelectedIllustratorBorrower,
} from 'reducers/illustrator-borrower.reducer';
import yup from 'lib/validation';
import Form from 'components/common/form/Form';
import Select from 'components/common/form/select/Select';
import {useDemographicInformationOptions} from 'hooks/link-application/borrower-info/demographic-information-source-options';
import StepElement from 'pages/illustrator-borrower/components/step-element/StepElement';
import {useFormContext} from 'react-hook-form';
import {DEMOGRAPHIC_ERROR} from 'pages/illustrator-borrower/interview-and-demographic-info/components/DemographicInfoErrors';
import {useDebouncedCallback} from 'use-debounce';
import InterviewAndDemographicInfoFormSubmit from 'pages/illustrator-borrower/interview-and-demographic-info/components/InterviewAndDemographicInfoFormSubmit';
import {BORROWER_PREFIX, CO_BORROWER_PREFIX} from 'constants/borrower';

/**
 *
 * the validation components for each section
 *
 */
import EthnicityValidation from 'lib/validation/illustrator-borrower/ethnicity-validation';
import SexValidation from 'lib/validation/illustrator-borrower/sex-validation';
import RaceValidation from 'lib/validation/illustrator-borrower/race-validation';
import ObservationsValidation from 'lib/validation/illustrator-borrower/observations-validation';

function SelectDemographicInformationSource(props) {
  const {className, options} = props;

  const {resetForm} = useFormContext();

  const onChange = useCallback(
    (event, data) => {
      const {value: demographicInformationSource} = data;
      resetForm(
        {
          demographicInformationSource,
          ...defaultDemographicInfoBorrowerForNewInterviewMethod,
          ...defaultDemographicInfoCoBorrowerForNewInterviewMethod,
        },
        {
          keepDefaultValues: false,
          keepDirty: false,
          keepErrors: false,
          keepValues: false,
          keepIsSubmitted: false,
          keepIsValid: false,
          keepTouched: false,
        },
      );
    },
    [resetForm],
  );

  return (
    <Select
      name="demographicInformationSource"
      variant="standard"
      className={className}
      options={options}
      onChange={onChange}
    />
  );
}

/**
 *
 * Interview & Demographic info main component
 *
 */

function InterviewAndDemographicInfo(props) {
  const {nextStep} = props;

  const classes = useStyles();
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const selectedIllustratorBorrower = useSelector(getSelectedIllustratorBorrower);

  const isAvailableCoBorrowerInfo = useMemo(() => {
    return selectedIllustratorBorrower?.coBorrowerInfo?.coBorrowerFirstName !== '';
  }, [selectedIllustratorBorrower]);

  const {xs, sm, lg} = useMemo(() => {
    if (isAvailableCoBorrowerInfo) {
      return {xs: 12, sm: 12, lg: 6}; // DO NOT TOUCH THIS !!!
    }
    return {xs: 12, sm: 10, lg: 8};
  }, [isAvailableCoBorrowerInfo]);

  const {options: demographicInformationSourceOptions, defaultOption} = useDemographicInformationOptions();

  const onSubmit = useCallback(
    async (data) => {
      /**
       *
       * the borrower attributes
       *
       */

      let demographicInfoBorrower = {
        // ethnicity
        borrowerEthnicityHispanicLatino: data.borrowerEthnicityHispanicLatino,
        borrowerEthnicityMexican: data.borrowerEthnicityMexican,
        borrowerEthnicityPuertoRican: data.borrowerEthnicityPuertoRican,
        borrowerEthnicityCuban: data.borrowerEthnicityCuban,
        borrowerEthnicityOtherHispanicLatino: data.borrowerEthnicityOtherHispanicLatino,
        borrowerEthnicityOtherHispanicLatinoText: data.borrowerEthnicityOtherHispanicLatinoText,
        borrowerEthnicityNotHispanicLatino: data.borrowerEthnicityNotHispanicLatino,
        borrowerEthnicityWishNotToProvide: data.borrowerEthnicityWishNotToProvide,
        // sex
        borrowerSexFemale: data.borrowerSexFemale,
        borrowerSexMale: data.borrowerSexMale,
        borrowerSexWishNotToProvide: data.borrowerSexWishNotToProvide,
        // race
        borrowerRaceAmericanIndianAlaskaNative: data.borrowerRaceAmericanIndianAlaskaNative,
        borrowerRaceAmericanIndianAlaskaNativeText: data.borrowerRaceAmericanIndianAlaskaNativeText,
        borrowerRaceAsian: data.borrowerRaceAsian,
        borrowerRaceAsianIndian: data.borrowerRaceAsianIndian,
        borrowerRaceChinese: data.borrowerRaceChinese,
        borrowerRaceFilipino: data.borrowerRaceFilipino,
        borrowerRaceJapanese: data.borrowerRaceJapanese,
        borrowerRaceKorean: data.borrowerRaceKorean,
        borrowerRaceVietnamese: data.borrowerRaceVietnamese,
        borrowerRaceOtherAsian: data.borrowerRaceOtherAsian,
        borrowerRaceOtherAsianText: data.borrowerRaceOtherAsianText,
        borrowerRaceBlackAfricanAmerican: data.borrowerRaceBlackAfricanAmerican,
        borrowerRaceHawaiianPacificIslander: data.borrowerRaceHawaiianPacificIslander,
        borrowerRaceNativeHawaiian: data.borrowerRaceNativeHawaiian,
        borrowerRaceGuamanianChamorro: data.borrowerRaceGuamanianChamorro,
        borrowerRaceSamoan: data.borrowerRaceSamoan,
        borrowerRaceOtherHawaiianPacific: data.borrowerRaceOtherHawaiianPacific,
        borrowerRaceOtherHawaiianPacificText: data.borrowerRaceOtherHawaiianPacificText,
        borrowerRaceWhite: data.borrowerRaceWhite,
        borrowerRaceWishNotToProvide: data.borrowerRaceWishNotToProvide,
        // observations
        borrowerObservedEthnicity: data.borrowerObservedEthnicity,
        borrowerObservedSex: data.borrowerObservedSex,
        borrowerObservedRace: data.borrowerObservedRace,
      };

      /**
       *
       * the "co-borrower" attributes
       *
       */

      let demographicInfoCoBorrower = {
        // ethnicity
        coBorrowerEthnicityHispanicLatino: data.coBorrowerEthnicityHispanicLatino,
        coBorrowerEthnicityMexican: data.coBorrowerEthnicityMexican,
        coBorrowerEthnicityPuertoRican: data.coBorrowerEthnicityPuertoRican,
        coBorrowerEthnicityCuban: data.coBorrowerEthnicityCuban,
        coBorrowerEthnicityOtherHispanicLatino: data.coBorrowerEthnicityOtherHispanicLatino,
        coBorrowerEthnicityOtherHispanicLatinoText: data.coBorrowerEthnicityOtherHispanicLatinoText,
        coBorrowerEthnicityNotHispanicLatino: data.coBorrowerEthnicityNotHispanicLatino,
        coBorrowerEthnicityWishNotToProvide: data.coBorrowerEthnicityWishNotToProvide,
        // sex
        coBorrowerSexFemale: data.coBorrowerSexFemale,
        coBorrowerSexMale: data.coBorrowerSexMale,
        coBorrowerSexWishNotToProvide: data.coBorrowerSexWishNotToProvide,
        // race
        coBorrowerRaceAmericanIndianAlaskaNative: data.coBorrowerRaceAmericanIndianAlaskaNative,
        coBorrowerRaceAmericanIndianAlaskaNativeText: data.coBorrowerRaceAmericanIndianAlaskaNativeText,
        coBorrowerRaceAsian: data.coBorrowerRaceAsian,
        coBorrowerRaceAsianIndian: data.coBorrowerRaceAsianIndian,
        coBorrowerRaceChinese: data.coBorrowerRaceChinese,
        coBorrowerRaceFilipino: data.coBorrowerRaceFilipino,
        coBorrowerRaceJapanese: data.coBorrowerRaceJapanese,
        coBorrowerRaceKorean: data.coBorrowerRaceKorean,
        coBorrowerRaceVietnamese: data.coBorrowerRaceVietnamese,
        coBorrowerRaceOtherAsian: data.coBorrowerRaceOtherAsian,
        coBorrowerRaceOtherAsianText: data.coBorrowerRaceOtherAsianText,
        coBorrowerRaceBlackAfricanAmerican: data.coBorrowerRaceBlackAfricanAmerican,
        coBorrowerRaceHawaiianPacificIslander: data.coBorrowerRaceHawaiianPacificIslander,
        coBorrowerRaceNativeHawaiian: data.coBorrowerRaceNativeHawaiian,
        coBorrowerRaceGuamanianChamorro: data.coBorrowerRaceGuamanianChamorro,
        coBorrowerRaceSamoan: data.coBorrowerRaceSamoan,
        coBorrowerRaceOtherHawaiianPacific: data.coBorrowerRaceOtherHawaiianPacific,
        coBorrowerRaceOtherHawaiianPacificText: data.coBorrowerRaceOtherHawaiianPacificText,
        coBorrowerRaceWhite: data.coBorrowerRaceWhite,
        coBorrowerRaceWishNotToProvide: data.coBorrowerRaceWishNotToProvide,
        // observations
        coBorrowerObservedEthnicity: data.coBorrowerObservedEthnicity,
        coBorrowerObservedSex: data.coBorrowerObservedSex,
        coBorrowerObservedRace: data.coBorrowerObservedRace,
      };

      /**
       *
       * the "interview and demographics" attributes
       *
       */

      let interviewerAndDemographics = {
        demographicInformationSource: data.demographicInformationSource,
        submitted: true, // the form is not pristine anymore
      };

      await dispatch(
        updateSelectedIllustratorBorrower({
          interviewerAndDemographics: interviewerAndDemographics,
          demographicInfoBorrower: demographicInfoBorrower,
          demographicInfoCoBorrower: demographicInfoCoBorrower,
        }),
      );

      /**
       *
       * the next step should be : the "declarations" page
       *
       */

      return nextStep();
    },
    [dispatch, nextStep],
  );

  const onError = useDebouncedCallback(() => {
    document.getElementsByClassName(DEMOGRAPHIC_ERROR).item(0)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, 100);

  const validationSchema = useMemo(
    () =>
      yup.object({
        /**
         *
         * demographic information
         *
         */
        demographicInformationSource: yup.string().required(),
        /**
         *
         * the "borrower" attributes
         *
         */
        // ethnicity
        ...EthnicityValidation({
          prefix: BORROWER_PREFIX,
          demographicInformationSource: defaultOption,
        }),
        // sex
        ...SexValidation({
          prefix: BORROWER_PREFIX,
          demographicInformationSource: defaultOption,
        }),
        // race
        ...RaceValidation({
          prefix: BORROWER_PREFIX,
          demographicInformationSource: defaultOption,
        }),
        // observations
        ...ObservationsValidation({
          prefix: BORROWER_PREFIX,
          demographicInformationSource: defaultOption,
        }),
        /**
         *
         * the "co-borrower" attributes
         *
         */
        // ethnicity
        ...EthnicityValidation({
          prefix: CO_BORROWER_PREFIX,
          demographicInformationSource: defaultOption,
          enabled: isAvailableCoBorrowerInfo,
        }),
        // sex
        ...SexValidation({
          prefix: CO_BORROWER_PREFIX,
          demographicInformationSource: defaultOption,
          enabled: isAvailableCoBorrowerInfo,
        }),
        // race
        ...RaceValidation({
          prefix: CO_BORROWER_PREFIX,
          demographicInformationSource: defaultOption,
          enabled: isAvailableCoBorrowerInfo,
        }),
        // observations
        ...ObservationsValidation({
          prefix: CO_BORROWER_PREFIX,
          demographicInformationSource: defaultOption,
          enabled: isAvailableCoBorrowerInfo,
        }),
      }),
    [defaultOption, isAvailableCoBorrowerInfo],
  );

  const defaultValues = useMemo(() => {
    return {
      demographicInformationSource:
        selectedIllustratorBorrower?.interviewerAndDemographics?.demographicInformationSource,
      ...selectedIllustratorBorrower?.demographicInfoBorrower,
      ...selectedIllustratorBorrower?.demographicInfoCoBorrower,
    };
  }, [selectedIllustratorBorrower]);

  return (
    <StepElement
      id="interviewAndDemographicInfo"
      title={t('illustratorTranslation:borrower flow.interviewer and demographic info.title')}
      data-testid="interview-and-demographic-info"
    >
      <Container
        component={Form}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        defaultValues={defaultValues}
        onError={onError}
      >
        <Item xs={0} sm={1} lg={2} />
        <Item xs={12} sm={10} lg={8}>
          <SelectDemographicInformationSource
            className={classes.customSelectElement}
            options={demographicInformationSourceOptions?.map((option) => ({
              value: option.value,
              label: option.label,
              selected: option?.selected,
            }))}
          />
        </Item>
        <Item xs={0} sm={1} lg={2} />

        {!isAvailableCoBorrowerInfo ? <Item xs={0} sm={1} lg={2} /> : null}

        <Item xs={xs} sm={sm} lg={lg}>
          <InterviewAndDemographicInfoComponents prefix="borrower" />
        </Item>

        {isAvailableCoBorrowerInfo ? (
          <Item xs={xs} sm={sm} lg={lg}>
            <InterviewAndDemographicInfoComponents prefix="coBorrower" />
          </Item>
        ) : null}

        {!isAvailableCoBorrowerInfo ? <Item xs={0} sm={1} lg={2} /> : null}

        <Item xs={12}>
          <InterviewAndDemographicInfoFormSubmit className={classes.buttonContainer} />
        </Item>
      </Container>
    </StepElement>
  );
}

InterviewAndDemographicInfo.propTypes = {};

InterviewAndDemographicInfo.defaultValues = {};

export default InterviewAndDemographicInfo;
