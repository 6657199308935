import {makeStyles} from '@material-ui/core/styles';
import {isSmSizeAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    illustrationFormPromptPadding: {
      padding: theme.spacing(5, 10),
      [isSmSizeAppQuery(theme)]: {padding: theme.spacing(5, 10)},
    },
    illustrationFormPromptButtonsContainer: {
      padding: theme.spacing(5, 10),
      [isSmSizeAppQuery(theme)]: {padding: theme.spacing(5, 10)},
    },
    illustrationFormPromptInputContainer: {
      padding: theme.spacing(5, 0, 5),
      [isSmSizeAppQuery(theme)]: {padding: theme.spacing(5, 10)},
    },
    lineHeight46: {
      lineHeight: '46px !important',
    },
  }),
  {index: 1},
);

export default useStyles;
