import axios from 'axios';

/**
 *
 * This is the Service component for the Smarty 3rd party micro-service.
 *
 * https://www.smarty.com/products/apis/us-autocomplete-pro-api
 *
 * !!! The API calls need to use the default axios.
 *
 * !!! Make sure that the axios request will detached and free from any filters, interceptors, etc ...
 *
 */

export default class SmartStreetsServiceApi {
  /**
   *
   * Smarty autocomplete US street address validation API URL
   *
   */

  static smarty_streets_api_url = 'https://us-autocomplete-pro.api.smarty.com/lookup';

  static key = window.env.REACT_APP_SMARTY_KEY;

  /**
   *
   * Interrogate the Smarty micro-service
   *
   */

  static async get(data, config) {
    return axios.get(this.smarty_streets_api_url, {...config, params: {key: this.key, search: data.search}});
  }
}
