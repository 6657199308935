import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery, isTabletAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    fixedTab: {
      zIndex: theme.zIndex.speedDial - 1,
    },
    flexContainer: {
      [isTabletAppQuery(theme)]: {
        overflowX: 'scroll',
      },
      '&::-webkit-scrollbar': {
        width: theme.spacing(0.25),
        height: theme.spacing(0.25),
      },
    },
    tabsComponentHideScroll: {
      '& *::-webkit-scrollbar-track': {
        boxShadow: 'unset',
        webkitBoxShadow: 'unset',
      },
    },
    tabsIndicatorPropsHideScroll: {
      display: 'none',
    },
    tabIndicatorComponent: {
      [isMobileAppQuery(theme)]: {
        display: 'none',
      },
    },
    tabsComponent: {
      [isTabletAppQuery(theme)]: {
        padding: theme.spacing(1, 3),
      },
      [isMobileAppQuery(theme)]: {
        padding: theme.spacing(1, 0),
      },
    },
  }),
  {index: 1},
);

export default useStyles;
