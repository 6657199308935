import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    mainEl: {
      width: '100%',
      display: 'block',
      margin: theme.spacing(0, 0, 0, 0),
    },

    tableDataContainer: {
      '& .MuiTableCell-head': {
        lineHeight: '2rem',
      },
      '& .MuiTableCell-root': {
        fontSize: theme.forPrint('1.5rem'),
      },
      textAlign: theme.forPrint('center'),
      display: theme.forPrint('block'),
      overflow: theme.forPrint('visible !important'),
    },
    table: {
      margin: '0 auto',
      minWidth: 650,
      maxWidth: 1300,
      border: '1px solid ' + theme.palette.grey.light,
      borderCollapse: 'collapse',
    },
    tableHead: {
      backgroundColor: theme.palette.grey.light,
    },
    selectTableCell: {
      width: 60,
    },
    tableCell: {
      width: 'auto',
      height: 40,
      border: '1px solid ' + theme.palette.table.td.border,
    },
    buttonContainer: {
      justifyContent: 'center',
    },
    buttonTransform: {
      textTransform: 'unset',
    },
    deleteButton: {
      color: theme.palette.error.main,
    },
  }),
  {index: 1},
);

export default useStyles;
