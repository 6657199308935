import {createTheme} from '@material-ui/core';
import {lighten} from '@material-ui/core/styles';
import {PRINT_HEIGHT, PRINT_WIDTH} from 'constants/print';

export const fontWeightLight = 300;
export const fontWeightRegular = 400;
export const fontWeightMedium = 500;
export const fontWeight600 = 600;
export const fontWeight700 = 700;
export const fontWeightBold = 800;
export const fontFamily = [
  'Montserrat',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
  '"Noto Color Emoji"',
].join(',');

const palette = {
  primary: {
    dark: '#033C5B',
    main: '#00A7E1',
    contrastText: '#fff',
  },
  secondary: {
    main: '#00A47E',
    dark: '#00755a',
    lighter: '#23bf58',
    contrastText: '#fff',
  },
  grey: {
    dark: '#333333',
    main: '#676767',
    light: '#e6e6e6',
    lighter: '#818181',
    secondary: '#ededed',
  },
  neutral: {
    dark: '#446073',
    main: '#5B7E96',
    light: '#D0DD28',
  },
  recommended: {
    main: '#EB5A00',
    contrastText: '#fff',
  },
  white: {
    light: '#ffffff',
    main: '#f0f8ff',
    dark: '#eeeeee',
  },
  blue: {
    light: '#D8DDE6',
  },
  advancedOptions: {
    light: lighten('#F9F9F9', 1),
    main: lighten('#1F6CB0', 0.95),
    dark: lighten('#009CD3', 0.9),
  },
  error: {
    main: '#c30505',
  },
  table: {
    td: {
      border: '#B9B9B9',
    },
  },
  borrower: {
    dark: '#013D5B',
  },
};

export const theme = createTheme(
  {
    palette,

    typography: {
      fontFamily,
      fontWeightLight,
      fontWeightRegular,
      fontWeightMedium,
      fontWeightBold,
      h1: {fontWeight: fontWeightBold},
      h2: {fontWeight: fontWeightBold},
      h3: {fontSize: '2.5rem', fontWeight: fontWeightRegular},
      h4: {fontWeight: fontWeightMedium},
      h5: {fontWeight: fontWeightMedium},
      h6: {fontWeight: fontWeightRegular},
      body1: {fontWeight: fontWeightBold},
      body2: {fontWeight: fontWeightMedium},
    },

    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor: palette.white.light,

            '& .html2pdf__overlay,& .html2pdf__container': {
              position: 'absolute !important',
              left: '-100000px !important',
              right: 'unset !important',
            },

            '& .recharts-wrapper, & .recharts-surface': {
              overflow: 'visible !important',
            },
          },
          '*::-webkit-scrollbar': {
            width: '0.4em',
            '@media print': {
              width: '0',
            },
          },
          '*::-webkit-scrollbar-track': {
            boxShadow: `inset 0 0 6px ${palette.grey.light}`,
            webkitBoxShadow: `inset 0 0 6px ${palette.grey.light}`,
            '@media print': {
              boxShadow: 'unset',
              webkitBoxShadow: 'unset',
            },
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: `${palette.grey.main}`,
            outline: '1px solid',
            outlineColor: `${palette.grey.main}`,
            '@media print': {
              backgroundColor: 'unset',
              outline: 'unset',
              outlineColor: 'unset',
            },
          },

          '.strong': {
            fontWeight: `${fontWeight700} !important`,
          },

          '.adornment-end button': {
            color: lighten(palette.grey.main, 0.1),
          },

          '.fullWidth': {
            width: '100% !important',
            margin: 'unset !important',
          },
        },
      },
    },
  },
  {
    withPrint(normal, printing) {
      return this.printing ? printing : normal;
    },
    forPrint(printing) {
      if (!this.printing) return undefined;
      return printing;
    },
  },
);

export const themeV2 = createTheme({
  ...theme,
  typography: {
    fontFamily: ['Gotham A', 'Gotham B'],
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    h1: {
      fontWeight: fontWeightBold,
    },
    h2: {
      fontWeight: fontWeightBold,
    },
    h3: {
      fontWeight: fontWeightBold,
    },
    h4: {
      fontSize: theme.spacing(3.75),
      fontWeight: fontWeightMedium,
    },
    h5: {
      fontSize: theme.spacing(3),
      fontWeight: fontWeightMedium,
    },
    h6: {
      fontSize: theme.spacing(2),
      fontWeight: fontWeightMedium,
    },
    body1: {
      fontSize: theme.spacing(1.75),
      fontWeight: fontWeightBold,
    },
    body2: {
      fontSize: theme.spacing(1.5),
      fontWeight: fontWeightMedium,
    },
    subtitle1: {
      fontSize: theme.spacing(2),
    },
    subtitle2: {
      fontSize: theme.spacing(1.75),
    },
  },
  overrides: {
    ...theme.overrides,
    MuiFormLabel: {
      root: {
        fontSize: theme.spacing(1.75),
        fontWeight: `${fontWeightBold} !important`,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'unset',
      },
    },
    MuiTableCell: {
      root: {
        fontWeight: `${fontWeight700} !important`,
        fontSize: theme.spacing(2),
      },
    },
  },
});

export const themePDF = createTheme(
  {
    ...themeV2,
    typography: {
      ...themeV2.typography,
      h4: {
        ...themeV2.typography.h4,
        fontSize: theme.spacing(4.75),
      },
      h5: {
        ...themeV2.typography.h5,
        fontSize: theme.spacing(4),
      },
      h6: {
        ...themeV2.typography.h6,
        fontSize: theme.spacing(3),
      },
      body1: {
        ...themeV2.typography.body1,
        fontSize: theme.spacing(2.75),
      },
      body2: {
        ...themeV2.typography.body2,
        fontSize: theme.spacing(2.5),
      },
      subtitle1: {
        ...themeV2.typography.subtitle1,
        fontSize: theme.spacing(3),
      },
      subtitle2: {
        ...themeV2.typography.subtitle2,
        fontSize: theme.spacing(2.75),
      },
    },
    overrides: {
      ...themeV2.overrides,

      MuiTableCell: {
        ...themeV2.overrides.MuiTableCell,
        root: {
          ...themeV2.overrides.MuiTableCell.root,
          fontSize: theme.spacing(3),
        },
      },
      MuiCssBaseline: {
        ...themeV2.overrides.MuiCssBaseline,
        '@global': {
          ...themeV2.overrides.MuiCssBaseline['@global'],
          body: {
            ...themeV2.overrides.MuiCssBaseline['@global']['body'],

            minWidth: PRINT_WIDTH,
            minHeight: PRINT_HEIGHT,

            '& *::-webkit-scrollbar': {
              width: '0',
            },
          },
          '*::-webkit-scrollbar': {
            width: 'auto',
          },
          '*::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            webkitBoxShadow: 'unset',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'unset',
            outline: 'unset',
            outlineColor: 'unset',
          },
        },
      },
    },
  },
  {printing: true},
);

export const themeWidget = createTheme({
  ...themeV2,
  palette: {
    ...themeV2.palette,
    widget: {
      dark: '#06325A',
      main: '#5B7E96',
      light: '#00A7DB',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: palette.white.light,
        },
      },
    },
  },
});

export const themeIllustratorBorrower = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiInputLabel: {
      root: {
        color: theme.palette.primary.dark,
        fontWeight: fontWeight600,
      },
    },
    MuiSelect: {
      icon: {
        position: 'relative',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'unset !important',
      },
    },
    MuiTableCell: {
      root: {
        ...theme.overrides?.MuiTableCell?.root,
        fontSize: theme.spacing(1.75),
      },
    },
  },
  typography: {
    ...theme.typography,
    body1: {fontWeight: fontWeight600},
    body2: {fontWeight: fontWeightMedium},
  },
});
